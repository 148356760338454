/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { View } from '@react-pdf/renderer';

import { PdfText } from '@Documents/components';
import { exists } from '@Utils/CommonUtils';
import { stripHtml } from '@Utils/StringUtils';

import { ThemeProgress } from '../ThemeProgress/ThemeProgress';
import { ThemeBlockProps } from './ThemeBlock.props';
import { styles } from './ThemeBlock.styles';

export const ThemeBlock = ({
  domain,
  theme,
  intro,
  themeResult,
  description,
  style
}: ThemeBlockProps) => {
  return (
    <View style={{ ...styles.ThemeBlock, ...style }} wrap={false}>
      <PdfText.Small style={styles.Domain}>{domain}</PdfText.Small>

      <PdfText.H4 style={styles.Theme}>{theme}</PdfText.H4>

      <PdfText.Small style={styles.Intro}>{stripHtml(intro)}</PdfText.Small>

      <ThemeProgress themeResult={themeResult} style={styles.Result} />

      {exists(description) && <PdfText.Small>{stripHtml(description!)}</PdfText.Small>}
    </View>
  );
};
