import cn from 'classnames';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Modal } from '@Components/layout';
import { Anchor, Button, ButtonIcon, SpacingGroup, Text, Tooltip } from '@Components/ui';
import { TargetAlignment, useApiError, useBoolean, useHover } from '@Hooks/common';
import { useUserChallengeStop } from '@Hooks/userChallenge/useUserChallengeStop';
import { AppRoutes } from '@Navigation/Routes';

import styles from './ChallengeStopButton.module.scss';
import { ChallengeStopButtonProps } from './ChallengeStopButton.props';

export const ChallengeStopButton = ({ challenge, className }: ChallengeStopButtonProps) => {
  const buttonRef = useRef(null);

  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);

  const { handleError } = useApiError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isHovering, handlers } = useHover();
  const userChallengeStopMutation = useUserChallengeStop();

  const handleChallengeStop = useCallback(async () => {
    try {
      await userChallengeStopMutation.mutateAsync({
        id: challenge.id,
        title: challenge.title,
        theme: challenge.theme.title
      });
      navigate(AppRoutes.getInsightChallenges().to);
    } catch (e) {
      handleError(e);
    }
  }, [
    userChallengeStopMutation,
    handleError,
    challenge.id,
    challenge.title,
    challenge.theme,
    navigate
  ]);

  if (challenge.completed) {
    return null;
  }

  return (
    <>
      <ButtonIcon
        ref={buttonRef}
        className={cn(styles.ChallengeStopButton, className)}
        icon="times"
        onClick={setModalOpen}
        {...handlers}
      />

      <Tooltip
        targetRef={buttonRef}
        label={t('DOMAIN.CHALLENGE.STOP.TITLE')}
        alignment={TargetAlignment.RIGHT}
        isVisible={isHovering}
        hasOffset
      />

      <Modal isOpen={isModalOpen} title={t('COMMON.GLOBALS.ARE_YOU_SURE')} onClose={setModalClosed}>
        <Text.Large className={styles.Explanation}>
          {t('DOMAIN.CHALLENGE.STOP.DESCRIPTION')}
        </Text.Large>

        <SpacingGroup size="xl">
          <Button
            isLoading={userChallengeStopMutation.isLoading}
            onClick={handleChallengeStop}
            icon="long-arrow-right"
            color="dark"
          >
            {t('DOMAIN.CHALLENGE.STOP.CONFIRM')}
          </Button>

          <Anchor onClick={setModalClosed}>{t('COMMON.GLOBALS.CANCEL')}</Anchor>
        </SpacingGroup>
      </Modal>
    </>
  );
};
