import { createContext, MutableRefObject, useContext } from 'react';

import { Score, ScoreType } from '../ScoreCircle.props';

export interface ScoreCircleContextType {
  scores: Score[];
  total?: ScoreType;
  hoveringDomain: number | null;
  setHoveringDomain: (idx: number | null) => void;
  componentRef: MutableRefObject<HTMLDivElement | null>;
  isVisible: boolean;
  activeIdx?: number;
  handleHover: (idx: number | null) => () => void;
  handleClick?: (idx: number) => () => void;
  isDisabled?: boolean;
}

export const ScoreCircleContext = createContext<ScoreCircleContextType | null>(null);

export const useScoreCircleContext = () => {
  const contextValue = useContext(ScoreCircleContext);

  if (!contextValue) {
    throw new Error('Set a ScoreCircleProvider first.');
  }

  return contextValue;
};
