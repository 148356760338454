import cn from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import AnimateHeight from 'react-animate-height';

import Checkmark from '@Assets/icons/check.svg';
import { CallToAction } from '@Components/ui/CallToAction/CallToAction';
import { Text } from '@Components/ui/Text';
import { TargetDirection, useBoolean, usePlatform, useTimeouts } from '@Hooks/common';

import styles from './ProgressStepsItem.module.scss';
import { ProgressStepsItemProps } from './ProgressStepsItem.props';

export const ProgressStepsItem = ({
  title,
  description,
  action,
  isChecked,
  isOpen,
  onClick,
  children,
  className
}: ProgressStepsItemProps) => {
  const ref = useRef(null);
  const tourRef = useRef(null);

  const shouldAnimateHeight = useBoolean(false);
  const isReady = useBoolean(false);

  const { addTimeout } = useTimeouts();
  const { easing } = usePlatform();

  const classNames = cn(styles.ProgressStepsItem, className, {
    [styles.isOpen]: isOpen,
    [styles.isChecked]: isChecked
  });

  // Cleanup animation when open toggles false
  useEffect(() => {
    if (!isOpen) {
      isReady.setFalse();
      shouldAnimateHeight.setFalse();
    }
  }, [isReady, shouldAnimateHeight, isOpen]);

  // Creates timeout to fire AnimateHeight animation after CSS transitions are done
  useEffect(() => {
    if (isOpen) {
      addTimeout(() => {
        shouldAnimateHeight.setTrue();
      }, 300);
    } else {
      shouldAnimateHeight.setFalse();
    }
  }, [shouldAnimateHeight, isOpen, addTimeout]);

  const handleClick = useCallback(() => {
    if (!isChecked && !isOpen) {
      onClick();
    }
  }, [isChecked, isOpen, onClick]);

  return (
    <div className={classNames} ref={ref} onClick={handleClick}>
      {action && (
        <CallToAction
          label={action}
          targetRef={ref}
          isVisible={!!action && isReady.value}
          direction={TargetDirection.LEFT}
          isReversed
          updateOnShow
          isStatic
        />
      )}

      <div className={styles.Heading}>
        {isOpen ? <Text.H3>{title}</Text.H3> : <Text.H4>{title}</Text.H4>}

        {isChecked && <img className={styles.Icon} src={Checkmark} />}
      </div>

      {!isChecked && (
        <AnimateHeight
          easing={easing.inOut}
          height={shouldAnimateHeight.value ? 'auto' : 0}
          onAnimationEnd={isReady.setTrue}
          className={styles.Body}
        >
          {description && <Text.Large className={styles.Description}>{description}</Text.Large>}

          <span ref={tourRef}>{children}</span>
        </AnimateHeight>
      )}
    </div>
  );
};
