import { Box, Text } from '@Components/ui';
import { BlockTip } from '@Components/ui/BlockTip/BlockTip';

import styles from './PreprElementTip.module.scss';
import { PreprElementTipProps } from './PreprElementTip.props';

export const PreprElementTip = ({ element }: PreprElementTipProps) => {
  return (
    <Box mb={6} className={styles.PreprElementTip}>
      <BlockTip
        title={element.title}
        button={
          element.button && element.button.url && element.button.body
            ? {
                href: element.button.url,
                label: element.button.body
              }
            : undefined
        }
      >
        <Text.Large dangerousHtml={element.description} />
      </BlockTip>
    </Box>
  );
};
