import { useTabGroupContext } from '../Wrapper/context';
import styles from './Child.module.scss';
import TabGroupChildProps from './Child.props';
import { TabGroupChildContext } from './context';

export const Child = ({ children, index }: TabGroupChildProps) => {
  const { current } = useTabGroupContext();

  return (
    <TabGroupChildContext.Provider value={{ isActive: index === current }}>
      <div className={styles.Children}>{children}</div>
    </TabGroupChildContext.Provider>
  );
};
