import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChallengeStopButton } from '@Components/container';
import { SpacingGroup, Tag } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { useUserChallenge } from '@Hooks/userChallenge';
import { toLocaleString } from '@Utils/StringUtils';

import styles from './ChallengeItemTags.module.scss';

export const ChallengeItemTags = () => {
  const { slug } = useParams<{ slug: string }>();

  const { t } = useTranslation();
  const { color } = usePlatform();
  const { data: challenge } = useUserChallenge({ id: slug ?? '' });

  if (challenge) {
    return (
      <SpacingGroup size="md">
        <Tag size="lg" label={t('DOMAIN.CHALLENGE.SINGLE')} color={color.font.default} />

        <Tag
          size="lg"
          label={toLocaleString(challenge?.points)}
          icon="star"
          color={color.primary.default}
        />

        {challenge?.popular && (
          <Tag
            size="lg"
            label={t('COMMON.GLOBALS.POPULAR')}
            icon="users"
            color={color.font.default}
          />
        )}

        <ChallengeStopButton challenge={challenge} className={styles.ChallengeStopButton} />
      </SpacingGroup>
    );
  }

  return null;
};
