import { useTranslation } from 'react-i18next';

import { Anchor, Block, Box, Text } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';

import { SelfTestRegularOpenBlockProps } from './SelfTestRegularOpenBlock.props';

export const SelfTestRegularOpenBlock = ({ selfTest, ...props }: SelfTestRegularOpenBlockProps) => {
  const { t } = useTranslation();

  return (
    <Block theme="gray" {...props}>
      <Box mb={1}>
        <Text.H4>{t('DOMAIN.TODO.SELF_TEST.REGULAR.OPEN.TITLE')}</Text.H4>
      </Box>

      <Box mb={1.5}>
        <Text.Normal>{t('DOMAIN.TODO.SELF_TEST.REGULAR.OPEN.DESCRIPTION')}</Text.Normal>
      </Box>

      <Anchor to={AppRoutes.getSelfTest().to}>
        {selfTest.status === QuestionnaireSubmissionStatus.OPEN
          ? t('DOMAIN.TODO.SELF_TEST.REGULAR.OPEN.ANCHOR_START')
          : t('DOMAIN.TODO.SELF_TEST.REGULAR.OPEN.ANCHOR_PROCEED')}
      </Anchor>
    </Block>
  );
};
