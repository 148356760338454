import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { useUser } from '@Hooks/user';
import { preferenceService, PreferenceSubmitRequest } from '@Services/Preference';
import { userService } from '@Services/User';
import { trackMatomoOnboardingPreferenceStep } from '@Utils/MatomoUtils';

export const usePreferenceSubmit = () => {
  const trackEvent = useMatomoTrackEvent();
  const queryClient = useQueryClient();
  const { personaId } = useUser();

  return useMutation(
    (config: PreferenceSubmitRequest) => {
      return preferenceService.submit(config);
    },
    {
      onSuccess: (_, { key }) => {
        trackMatomoOnboardingPreferenceStep(trackEvent, key, !!personaId);

        queryClient.invalidateQueries([preferenceService.getEndpoint()]);
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
