import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ContactForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { Anchor, Box, Button, Dot, SpacingGroup, Text } from '@Components/ui';
import { useApiError, useBoolean } from '@Hooks/common';
import { useUser, useUserToggleTourdots } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';

import styles from './AppFaqHeader.module.scss';

export const AppFaqHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { value: isPopupOpen, setTrue: setPopupOpen, setFalse: setPopupClosed } = useBoolean();
  const { handleError } = useApiError();
  const userToggleTourdotsMutation = useUserToggleTourdots();
  const { email, phoneNumber } = useUser();

  const handleRestart = useCallback(async () => {
    try {
      await userToggleTourdotsMutation.mutateAsync({
        hide: false
      });

      toast.info(t('DOMAIN.FAQ.QUESTIONS.RESTART_TOUR_SUCCESS'));

      navigate(AppRoutes.getDashboard().to);
    } catch (e) {
      handleError(e);
    }
  }, [handleError, navigate, userToggleTourdotsMutation, t]);

  return (
    <div className={styles.AppFaqHeader}>
      <Text.H1 className={styles.Title}>{t('DOMAIN.FAQ.QUESTIONS.TITLE')}</Text.H1>

      <Text.Large className={styles.Description}>
        {t('DOMAIN.FAQ.QUESTIONS.DESCRIPTION')}
      </Text.Large>

      <SpacingGroup size="xxl">
        <Button icon={'arrow-right'} onClick={setPopupOpen}>
          {t('DOMAIN.FAQ.QUESTIONS.BUTTON_LABEL')}
        </Button>

        <Anchor onClick={handleRestart} isDisabled={userToggleTourdotsMutation.isLoading}>
          <Box ml={2} mr={2}>
            <Dot />
          </Box>

          {t('DOMAIN.FAQ.QUESTIONS.RESTART_TOUR')}
        </Anchor>
      </SpacingGroup>

      <Modal
        isOpen={isPopupOpen}
        onClose={setPopupClosed}
        title={t('DOMAIN.FAQ.QUESTIONS.BUTTON_LABEL')}
      >
        <ContactForm email={email} phone={phoneNumber} onSuccess={setPopupClosed} />
      </Modal>
    </div>
  );
};
