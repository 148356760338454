import cn from 'classnames';

import { Image, Text } from '@Components/ui';

import styles from './CardImage.module.scss';
import { CardImageProps } from './CardImage.props';

export const CardImage = ({
  image,
  title,
  children,
  onClick,
  isDisabled,
  className
}: CardImageProps) => {
  const classNames = cn(styles.CardImage, className, { [styles.isDisabled]: isDisabled });

  return (
    <div className={classNames} onClick={onClick}>
      <div className={styles.Background}>
        <Image src={image} ratio="8:5" />
      </div>

      <div className={styles.Content}>
        <Text.H4>{title}</Text.H4>

        {children && <div className={styles.Children}>{children}</div>}
      </div>
    </div>
  );
};
