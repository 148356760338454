import { formatRelative, isSameDay } from 'date-fns';
import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import dutchLocale from 'date-fns/locale/nl';
import { get } from 'lodash';

import { AppError, SortDirection } from '@Constants/enums';

import { i18n } from './TranslationUtils';

export interface DateGroup<T> {
  date: string;
  items: T[];
}

const formatRelativeLocale = {
  lastWeek: i18n.t('DOMAIN.RELATIVEDATE.LAST_WEEK'),
  yesterday: i18n.t('DOMAIN.RELATIVEDATE.YESTERDAY'),
  today: i18n.t('DOMAIN.RELATIVEDATE.TODAY'),
  tomorrow: i18n.t('DOMAIN.RELATIVEDATE.TOMORROW'),
  nextWeek: i18n.t('DOMAIN.RELATIVEDATE.NEXT_WEEK'),
  other: i18n.t('DOMAIN.RELATIVEDATE.OTHER')
};

export const dateFnsLocale = {
  ...nl,
  formatRelative: (token: 'yesterday' | 'today' | 'tomorrow' | 'nextWeek' | 'other') =>
    formatRelativeLocale[token]
};

export const getToday = () => {
  return new Date();
};

export const shortDate = (date: Date) => {
  return format(date, 'd MMM yyyy', {
    locale: dutchLocale
  });
};

export const shortDayMonth = (date: Date) => {
  return format(date, 'd MMM', {
    locale: dutchLocale
  });
};

export const getDate = (date: Date) => {
  return format(date, 'dd-MM-yyyy', {
    locale: dutchLocale
  });
};

export const apiDateTime = (date: Date) => {
  return format(date, 'yyyy-MM-dd H:ii:ss', {
    locale: dutchLocale
  });
};

export const writtenMonthYear = (date: Date) => {
  return format(date, 'MMMM yyyy', {
    locale: dutchLocale
  });
};

export const getRelativeDate = (date: Date) => {
  return formatRelative(date, getToday(), {
    locale: dateFnsLocale
  });
};

export const writtenMonth = (date: Date) => {
  return format(date, 'MMMM', {
    locale: dutchLocale
  });
};

export const timePassedToString = (duration: Duration) => {
  if (duration.years) {
    return i18n.t('COMMON.GLOBALS.TIME_PASSED.YEAR', { count: duration.years });
  }

  if (duration.months) {
    return i18n.t('COMMON.GLOBALS.TIME_PASSED.MONTH', { count: duration.months });
  }

  if (duration.weeks) {
    return i18n.t('COMMON.GLOBALS.TIME_PASSED.WEEK', { count: duration.weeks });
  }

  if (duration.days) {
    return i18n.t('COMMON.GLOBALS.TIME_PASSED.DAY', { count: duration.days });
  }

  if (duration.hours) {
    return i18n.t('COMMON.GLOBALS.TIME_PASSED.HOUR', { count: duration.hours });
  }

  return i18n.t('COMMON.GLOBALS.TIME_PASSED.MINUTE', { count: duration.minutes });
};

export const groupByDate = <T extends Record<string, unknown>>(
  input: T[],
  datePath: keyof T,
  direction = SortDirection.DESC
) => {
  const unsortedCollection = input.reduce<Array<DateGroup<T>>>((sortedCollection, item) => {
    const itemDateString = get(item, datePath);

    if (typeof itemDateString !== 'string') {
      throw new Error(AppError.NO_DATE_AT_PATH);
    }

    const itemDate = new Date(itemDateString);

    const dateIdx = sortedCollection.findIndex((sortedGroup) => {
      const sortedGroupDate = new Date(sortedGroup.date);

      return isSameDay(sortedGroupDate, itemDate);
    });

    const dateIsInCollection = dateIdx !== -1;

    if (dateIsInCollection) {
      sortedCollection[dateIdx].items.push(item);

      return sortedCollection;
    }

    sortedCollection.push({
      date: format(itemDate, 'yyyy-MM-dd'),
      items: [item]
    });

    return sortedCollection;
  }, []);

  return unsortedCollection.sort((a, b) => {
    const timestampA = new Date(a.date).getTime();
    const timestampB = new Date(b.date).getTime();

    if (direction === SortDirection.DESC) {
      return timestampB - timestampA;
    }

    return timestampA - timestampB;
  });
};
