import { forwardRef, useCallback, useMemo } from 'react';

import { Form } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useDataActivitySubjects } from '@Hooks/data';
import { usePreferenceList, usePreferenceSubmit } from '@Hooks/preference';
import { PreferenceKey } from '@Services/Preference';
import { getPreference } from '@Utils/PreferenceUtils';
import Yup from '@Utils/YupUtils';

import { activitySubjectsToInputOptions } from './internal/utils';
import {
  PreferencesActivitySubjectFormProps,
  PreferencesActivitySubjectFormValues
} from './PreferencesActivitySubjectForm.props';

export const PreferencesActivitySubjectForm = forwardRef(
  ({ onSuccess, children, className }: PreferencesActivitySubjectFormProps, ref: any) => {
    const preferencesQuery = usePreferenceList();
    const preferenceSubmitMutation = usePreferenceSubmit();
    const dataActivitySubjectsQuery = useDataActivitySubjects();
    const { handleError } = useApiError();

    const inputOptions = useMemo(() => {
      return activitySubjectsToInputOptions(dataActivitySubjectsQuery.data || []);
    }, [dataActivitySubjectsQuery.data]);

    const handleSubmit = useCallback(
      async (values: PreferencesActivitySubjectFormValues) => {
        try {
          await preferenceSubmitMutation.mutateAsync({
            key: PreferenceKey.ACTIVITY_SUBJECTS,
            values: values.activitySubjects
          });

          onSuccess();
        } catch (e) {
          handleError(e);
        }
      },
      [onSuccess, preferenceSubmitMutation, handleError]
    );

    const initialValues = {
      activitySubjects: getPreference(preferencesQuery.data || [], PreferenceKey.ACTIVITY_SUBJECTS)
    };

    const validationSchema = Yup.object().shape({
      activitySubjects: Yup.array(Yup.string().required()).min(1).required()
    });

    if (preferencesQuery.isFetched && dataActivitySubjectsQuery.isFetched) {
      return (
        <Form.Form<PreferencesActivitySubjectFormValues>
          ref={ref}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          className={className}
          data-testid="helpQuestionForm"
        >
          <Form.Input.Toggle
            name="activitySubjects"
            data-testid="subjectsInput"
            options={{
              options: inputOptions
            }}
          />

          {children}
        </Form.Form>
      );
    }

    return null;
  }
);
