import { Route, Routes } from 'react-router-dom';

import { useScrollToTop } from '@Hooks/common';
import { usePersistSearchParam } from '@Hooks/common/usePersistSearchParam';
import { useMatomoTrackPageView } from '@Hooks/matomo';
import { AuthStack } from '@Navigation/index';

export const PublicStack = () => {
  useScrollToTop();
  useMatomoTrackPageView();
  const { persistSearchParam } = usePersistSearchParam('token');
  persistSearchParam();

  return (
    <Routes>
      <Route path="/*" element={<AuthStack />} />
    </Routes>
  );
};
