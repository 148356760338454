import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Tag, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './Checkmark.module.scss';
import { CheckmarkProps } from './Checkmark.props';

export const Checkmark = ({ isLabeled = false, size = 'xs', label, className }: CheckmarkProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();

  return (
    <span className={cn([styles.Checkmark, className])}>
      <Tag icon="check" color={color.secondary.default} size="xs" className={styles.Tag} />

      {(isLabeled || !!label) && (
        <>
          {size === 'xs' && (
            <Text.Micro className={styles.Label}>
              {label || t('COMMON.GLOBALS.COMPLETED')}
            </Text.Micro>
          )}

          {size === 'sm' && (
            <Text.Small className={styles.Label}>
              {label || t('COMMON.GLOBALS.COMPLETED')}
            </Text.Small>
          )}
        </>
      )}
    </span>
  );
};
