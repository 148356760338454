import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, Form } from '@Components/ui';
import { MAX_FIRSTNAME_LENGTH, MAX_SURNAME_LENGTH } from '@Constants/configs';
import { useActivityInvite } from '@Hooks/activity/';
import { useApiError } from '@Hooks/common';
import { ActivityInviteRequest } from '@Services/Activity';
import Yup from '@Utils/YupUtils';

import { ActivityInviteFormProps, ActivityInviteFormValues } from './ActivityInviteForm.props';

export const ActivityInviteForm = ({ activity, onSuccess }: ActivityInviteFormProps) => {
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const activityInviteMutation = useActivityInvite();

  const handleSubmit = useCallback(
    async (values: ActivityInviteFormValues) => {
      try {
        const requestValues: ActivityInviteRequest = {
          id: activity.id,
          title: activity.title,
          type: activity.type[0]?.title,
          ...values
        };

        await activityInviteMutation.mutateAsync(requestValues);

        toast.success(t('DOMAIN.ACTIVITY.INVITE.SUCCES.LABEL'));

        onSuccess();
      } catch (e) {
        handleError(e);
      }
    },

    [handleError, onSuccess, activityInviteMutation, t, activity.id, activity.title, activity.type]
  );

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(MAX_FIRSTNAME_LENGTH).required(),
    lastName: Yup.string().max(MAX_SURNAME_LENGTH).required(),
    email: Yup.string().email().required()
  });

  return (
    <Form.Form<ActivityInviteFormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form.Input.Text
        label={t('DOMAIN.ACTIVITY.INVITE.FORM.FIELDS.FIRSTNAME.LABEL')}
        name="firstName"
        data-testid="firstNameInput"
      />

      <Form.Input.Text
        label={t('DOMAIN.ACTIVITY.INVITE.FORM.FIELDS.LASTNAME.LABEL')}
        name="lastName"
        data-testid="lastNameInput"
      />

      <Form.Input.Text
        label={t('DOMAIN.ACTIVITY.INVITE.FORM.FIELDS.EMAIL.LABEL')}
        name="email"
        data-testid="emailInput"
      />

      <Button type="submit" color="dark" data-testid="activityInviteSubmit">
        {t('DOMAIN.ACTIVITY.INVITE.FORM.SUBMIT_LABEL')}
      </Button>
    </Form.Form>
  );
};
