import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Modal } from '@Components/layout';
import { InputField, InputText } from '@Components/ui';
import { useBoolean } from '@Hooks/common';
import { UserVerifyEmailRequest } from '@Services/User';

import {
  UpdateEmailRequestSmsStep,
  UpdateEmailUpdateEmailStep,
  UpdateEmailUpdateEmailStepValues,
  UpdateEmailVerifyTwoFactorStep
} from './internal/components';
import { UpdateEmailVerifyNewEmailStep } from './internal/components';
import { ProfileUpdateEmailFormProps } from './ProfileUpdateEmailForm.props';

export const ProfileUpdateEmailForm = ({
  onSuccess,
  phoneNumber,
  email
}: ProfileUpdateEmailFormProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);
  const [newEmail, setNewEmail] = useState('');
  const [twoFactor, setTwoFactor] = useState({
    smsToken: '',
    twoFactorToken: ''
  });

  const handleCancel = useCallback(() => {
    setModalClosed();
    setCurrentStep(0);
  }, [setModalClosed]);

  const handleNextStep = useCallback(() => {
    setCurrentStep((oldStep) => oldStep + 1);
  }, []);

  const handleVerifySmsSucces = useCallback(
    (values: UserVerifyEmailRequest) => {
      setTwoFactor({ smsToken: values.smsToken, twoFactorToken: values.token });

      handleNextStep();
    },

    [handleNextStep]
  );

  const handleFinish = useCallback(() => {
    if (onSuccess) {
      onSuccess();
    }

    toast.success(t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_EMAIL.UPDATE_EMAIL.SUCCES'));

    handleCancel();
  }, [handleCancel, onSuccess, t]);

  const handleNewEmailStep = useCallback(
    (values: UpdateEmailUpdateEmailStepValues) => {
      setNewEmail(values.newEmail);

      handleNextStep();
    },

    [handleNextStep]
  );

  const stepComponents = [
    <UpdateEmailRequestSmsStep
      phoneNumber={phoneNumber}
      onSuccess={handleNextStep}
      onCancel={handleCancel}
    />,

    <UpdateEmailVerifyTwoFactorStep phoneNumber={phoneNumber} onSuccess={handleVerifySmsSucces} />,

    <UpdateEmailUpdateEmailStep
      token={twoFactor.twoFactorToken}
      smsToken={twoFactor.smsToken}
      currentEmail={email}
      onSuccess={handleNewEmailStep}
    />,

    <UpdateEmailVerifyNewEmailStep
      smsToken={twoFactor.smsToken}
      currentEmail={email}
      newEmail={newEmail}
      onSuccess={handleFinish}
    />
  ];

  const currentStepComponent = stepComponents[currentStep];

  return (
    <>
      <InputField
        name="email"
        data-testid="changeEmailInput"
        onEditClick={setModalOpen}
        label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.EMAIL.LABEL')}
      >
        <InputText value={email} disabled />
      </InputField>

      <Modal
        isOpen={isModalOpen}
        title={t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_EMAIL.TITLE')}
        onClose={handleCancel}
      >
        {currentStepComponent}
      </Modal>
    </>
  );
};
