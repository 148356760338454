import { keys } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { DOTS } from '@Locales/nl/components/TOURDOT.json';
import { PreferenceKey, preferenceService } from '@Services/Preference';
import { userService, UserToggleTourdotsRequest } from '@Services/User';

export const useUserToggleTourdots = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (config: UserToggleTourdotsRequest) => {
      return preferenceService.submit({
        key: PreferenceKey.TOURDOTS,
        values: config.hide ? keys(DOTS) : []
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
