import { useTranslation } from 'react-i18next';

import { Box, Text } from '@Components/ui';

import styles from './InsightProgressHead.module.scss';

export const InsightProgressHead = () => {
  const { t } = useTranslation();

  return (
    <Box mx={4} mb={5}>
      <Text.H1 className={styles.Title}>{t('DOMAIN.PROGRESS.TITLE')}</Text.H1>

      <Text.Large>{t('DOMAIN.PROGRESS.DESCRIPTION')}</Text.Large>
    </Box>
  );
};
