import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Loader, Text } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import {
  useOnboardingGetPersonaVerificationQuestion,
  useOnboardingSubmitPersonaVerificationQuestion
} from '@Hooks/onboarding';
import { objectToInputOptions } from '@Utils/FormUtils';
import Yup from '@Utils/YupUtils';

import styles from './VerificationQuestionForm.module.scss';
import {
  VerificationQuestionFormProps,
  VerificationQuestionFormValues
} from './VerificationQuestionForm.props';

export const VerificationQuestionForm = ({
  onSuccess,
  className
}: VerificationQuestionFormProps) => {
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const {
    data: verificationQuestion,
    isFetched,
    isError
  } = useOnboardingGetPersonaVerificationQuestion();

  const submitPersonaVerificationQuestionMutation =
    useOnboardingSubmitPersonaVerificationQuestion();

  const validationSchema = Yup.object().shape({
    personaId: Yup.string().required()
  });

  const handleSubmit = useCallback(
    async (values: VerificationQuestionFormValues) => {
      try {
        await submitPersonaVerificationQuestionMutation.mutateAsync({
          id: values.personaId
        });

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [submitPersonaVerificationQuestionMutation, onSuccess, handleError]
  );

  if (!isFetched) {
    return <Loader>{t('DOMAIN.PERSONA_QUESTIONNAIRE.VERIFICATION_QUESTION.IS_LOADING')}</Loader>;
  }

  if (isError) {
    return (
      <Text.Error>
        {t('DOMAIN.PERSONA_QUESTIONNAIRE.VERIFICATION_QUESTION.COULD_NOT_FETCH')}
      </Text.Error>
    );
  }

  if (verificationQuestion) {
    return (
      <Form.Form<VerificationQuestionFormValues>
        className={cn(styles.VerificationQuestionForm, className)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form.Input.Radio
          name="personaId"
          data-testid="personaId"
          options={{ options: objectToInputOptions(verificationQuestion, 'text'), isLarge: true }}
        />

        <div className={styles.SubmitContainer}>
          <Button type="submit" data-testid="verificationQuestionSubmit" icon="arrow-right">
            {t('DOMAIN.PERSONA_QUESTIONNAIRE.VERIFICATION_QUESTION.SUBMIT_LABEL')}
          </Button>
        </div>
      </Form.Form>
    );
  }

  return null;
};
