import { InputOption } from '@Constants/interfaces';
import { IActivityFilter } from '@Services/Activity';

export const filterToInputOptions = (filters: IActivityFilter[]): InputOption[] => {
  return filters.map((filter) => {
    return {
      label: filter.title,
      value: filter.preprId
    };
  });
};

export const filterToValues = (
  filter: IActivityFilter[],
  field: 'title' | 'preprId' = 'preprId'
): string[] => {
  return filter.reduce((values: string[], activityFilter: IActivityFilter) => {
    values.push(activityFilter[field]);

    return values;
  }, []);
};

export const filterToInitialValues = (filter: IActivityFilter[]): string[] => {
  return filter.reduce((initialValues: string[], activityFilter: IActivityFilter) => {
    if (activityFilter.selected) {
      initialValues.push(activityFilter.preprId);
    }

    return initialValues;
  }, []);
};

export const filterFromValues = (
  preprIds?: Array<string | undefined>,
  filters?: IActivityFilter[]
): IActivityFilter[] | undefined => {
  if (filters) {
    return filters?.reduce((values: IActivityFilter[], activityFilter: IActivityFilter) => {
      if (preprIds?.includes(activityFilter.preprId)) {
        values.push(activityFilter);
      }

      return values;
    }, []);
  }
};
