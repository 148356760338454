import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ProfileEditProfilePictureScreen,
  ProfilePreferenceScreen,
  ProfileProfileScreen
} from '@Screens/Profile';

import { AppRoutes } from './Routes';

export const ProfileStack = () => {
  return (
    <Routes>
      <Route path="" element={<ProfileProfileScreen />} />

      <Route
        path={AppRoutes.getMyProfilePreferences().path}
        element={<ProfilePreferenceScreen />}
      />

      <Route
        path={AppRoutes.getMyProfileEditProfilePicture().path}
        element={<ProfileEditProfilePictureScreen />}
      />

      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};
