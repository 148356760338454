import { createContext, useContext } from 'react';
import SwiperClass from 'swiper/types/swiper-class';

import { AppError } from '@Constants/enums';

export interface SliderFullPageContextType {
  current: number;
  total: number;
  slideNext: () => void;
  slidePrev: () => void;
  slideTo: (index: number) => void;
  swiper?: SwiperClass | null;
  isAnimating?: boolean;
}

export const SliderFullPageContext = createContext<SliderFullPageContextType | null>(null);

export const useSliderFullPageContext = () => {
  const contextValue = useContext(SliderFullPageContext);

  if (!contextValue) {
    throw new Error(AppError.NO_CONTEXT_SET);
  }

  return contextValue;
};
