import { useMemo } from 'react';

import { useChallengeOne } from '@Hooks/challenge';
import { useUserChallenge } from '@Hooks/userChallenge';

export const useNonStartedActivities = (slug: string) => {
  const { data: challenge } = useChallengeOne({ id: slug });
  const { data: userChallenge } = useUserChallenge({ id: slug });

  const getNonStartedActivities = useMemo(() => {
    return challenge?.activities.filter((activity) => {
      return !userChallenge?.activities.some((userActivity) => userActivity.id === activity.id);
    });
  }, [challenge, userChallenge]);

  return getNonStartedActivities;
};
