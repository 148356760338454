import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ProfilePictureForm } from '@Components/form';
import { ButtonCancel, Container } from '@Components/layout';
import { Box, Text } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

export const ProfileEditProfilePictureScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSuccess = useCallback(() => {
    navigate(AppRoutes.getMyProfile().to);
  }, [navigate]);

  return (
    <>
      <ButtonCancel to={AppRoutes.getMyProfile().to} />

      <Container cols={6}>
        <Box mb={1}>
          <Text.H3>{t('DOMAIN.PROFILE.PROFILE_PICTURE.TITLE')}</Text.H3>
        </Box>

        <Box mb={5}>
          <Text.Large>{t('DOMAIN.PROFILE.PROFILE_PICTURE.DESCRIPTION')}</Text.Large>
        </Box>

        <ProfilePictureForm onSuccess={handleSuccess} />
      </Container>
    </>
  );
};
