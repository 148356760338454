import { BaseService, dePortalenService } from '@Services/common';

import { QuoteCurrentResponse } from './QuoteService.props';

export class QuoteService extends BaseService {
  current = async () => {
    const response = await this.get<QuoteCurrentResponse>();

    return response.data;
  };
}

export const quoteService = new QuoteService(dePortalenService.getAxiosInstance(), '/quotes');
