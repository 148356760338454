import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { userService } from '@Services/User';
import { userPopupService, UserPopupSubmitRequest } from '@Services/UserPopup';
import { trackMatomoMotivatorSubmit } from '@Utils/MatomoUtils';

export const useUserPopupSubmit = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: UserPopupSubmitRequest) => {
      return userPopupService.submit(config);
    },
    {
      onSuccess: (_, { title, answer, answers }) => {
        trackMatomoMotivatorSubmit(trackEvent, title, answer, answers);

        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
