import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import {
  QuestionnaireSubmissionFinishRequest,
  questionnaireSubmissionService
} from '@Services/QuestionnaireSubmission';
import { todoService } from '@Services/Todo';
import { userService } from '@Services/User';
import { trackMatomoOnboardingQuestionnaireSelftestFinish } from '@Utils/MatomoUtils';

export const useQuestionnaireSubmissionFinish = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: QuestionnaireSubmissionFinishRequest) => {
      return questionnaireSubmissionService.finish(config);
    },
    {
      onSuccess: (_, selfTestQuestionnaire) => {
        trackMatomoOnboardingQuestionnaireSelftestFinish(trackEvent, selfTestQuestionnaire);

        queryClient.invalidateQueries([questionnaireSubmissionService.getEndpoint()]);
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
        queryClient.invalidateQueries([todoService.getEndpoint()]);
      }
    }
  );
};
