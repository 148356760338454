import { ListRequest, ListResponse } from '@Constants/interfaces';
import { IChallenge } from '@Services/Challenge';
import { dePortalenService, ResourceService } from '@Services/common';
import { toQueryString } from '@Utils/StringUtils';

import { UserChallengeStopRequest } from './UserChallengeService.props';

export class UserChallengeService extends ResourceService<IChallenge> {
  stop = async ({ id }: UserChallengeStopRequest) => {
    await this.post<void>(`/${id}/stop`);
  };

  getArchive = async (params: ListRequest = {}) => {
    const response = await this.get<ListResponse<IChallenge>>('/archive?' + toQueryString(params));

    return response.data;
  };
}

export const userChallengeService = new UserChallengeService(
  dePortalenService.getAxiosInstance(),
  '/userchallenges'
);
