import cn from 'classnames';

import { Icon, Text } from '@Components/ui';
import { Smiley } from '@Components/ui/Smiley/Smiley';

import styles from './InputSliderHandler.module.scss';
import { InputSliderHandlerProps } from './InputSliderHandler.props';

export const InputSliderHandler = ({
  hasTooltip,
  isSmiley,
  value,
  isValid,
  isError,
  isTouched,
  isDisabled,
  isSmall,
  hasMarks
}: InputSliderHandlerProps) => {
  const classNames = cn(styles.InputSliderHandler, {
    [styles.isError]: isError,
    [styles.isValid]: isValid,
    [styles.isTouched]: isTouched,
    [styles.isDisabled]: isDisabled,
    [styles.isSmall]: isSmall,
    [styles.hasMarks]: hasMarks
  });

  const style = {
    left: value + '%'
  };

  return (
    <div style={style} className={classNames}>
      <Icon icon={['fas', 'check']} />

      {isSmiley && (
        <Smiley
          className={cn(styles.Smiley, {
            [styles.isVisible]: isTouched
          })}
          happiness={value}
        />
      )}

      {hasTooltip && !isSmiley && (
        <Text.Bold
          className={cn(styles.Percentage, {
            [styles.isVisible]: isTouched
          })}
        >
          {String(value)}
        </Text.Bold>
      )}
    </div>
  );
};
