import { useMutation, useQueryClient } from 'react-query';

import { userService, UserUpdateMeRequest } from '@Services/User';
import { isBase64 } from '@Utils/StringUtils';

export const useUserUpdateMe = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (config: UserUpdateMeRequest) => {
      if (!isBase64(config?.avatar || '')) {
        delete config.avatar;
      }

      return userService.updateMe(config);
    },
    {
      onSuccess: () => {
        queryClient.cancelQueries([userService.getEndpoint(), 'me']);
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
