import cn from 'classnames';
import percentRound from 'percent-round';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { InputOptionRadio, InputRadio, SpacingGroup } from '@Components/ui';
import { Box, Image, Tag, Text } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { usePollVote } from '@Hooks/poll';
import { IPollAnswer } from '@Services/Poll';

import styles from './Poll.module.scss';
import { PollProps } from './Poll.props';

const pollAnswersToOptions = (answers: IPollAnswer[]): InputOptionRadio[] => {
  const percentages = percentRound(
    answers.map((answer) => answer.count),
    1
  );

  return answers.map((answer, idx) => {
    return {
      value: answer.id,
      label: answer.answer,
      percentage: percentages[idx],
      disabled: false
    };
  });
};

export const Poll = ({ hasImage, poll, onVoted, className }: PollProps) => {
  const { t } = useTranslation();
  const pollVoteMutation = usePollVote();
  const { handleError } = useApiError();

  const handleChange = useCallback(
    async (value: string) => {
      try {
        await pollVoteMutation.mutateAsync({
          answer: value,
          poll: poll
        });

        toast.success(t('COMPONENTS.POLL.SUCCESS'));

        if (onVoted) {
          onVoted();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, onVoted, poll, pollVoteMutation, t]
  );

  const pollAnswers = useMemo(() => {
    return pollAnswersToOptions(poll.answers);
  }, [poll.answers]);

  const totalVotes = useMemo(() => {
    return poll.answers.reduce((total, { count }) => total + count, 0);
  }, [poll.answers]);

  return (
    <div className={cn(styles.Poll, className)}>
      {hasImage && (
        <div className={styles.Image}>
          <Image src={poll.image} alt={poll.title} ratio="fill" />
        </div>
      )}

      <Box mt={3} mb={3} className={styles.Content}>
        <Text.H3>{poll.title}</Text.H3>

        <SpacingGroup className={styles.Tags}>
          <Tag icon="users" label={t('COMMON.GLOBALS.POPULAR')} className={styles.Popular} />

          {totalVotes > 0 && (
            <Text.Micro className={styles.Votes}>
              {t('COMPONENTS.POLL.VOTES', {
                count: totalVotes
              })}
            </Text.Micro>
          )}
        </SpacingGroup>

        <Box mt={3} data-testid="PollInput">
          <InputRadio
            name={poll.id}
            isPoll
            options={pollAnswers}
            onChange={handleChange}
            value={poll.answers.find((answer) => answer.selected)?.id}
          />
        </Box>
      </Box>
    </div>
  );
};
