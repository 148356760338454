import cn from 'classnames';
import { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import defaultMotivator from '@Assets/images/default-motivator.jpeg';
import { UserPopupForm, UserPopupFormValues } from '@Components/form';
import { Anchor, Button, PopupMessage, SpacingGroup } from '@Components/ui';
import { POPUP_CLOSE_DURATION } from '@Components/ui/PopupMessage/internal/constants';
import { useApiError, useBoolean } from '@Hooks/common';
import { useUser } from '@Hooks/user';
import { useUserPopupHide, useUserPopupSubmit } from '@Hooks/userPopup';
import { UserPopupType } from '@Services/UserPopup';
import { getMotivatorHref } from '@Utils/MotivatorUtils';
import { sleep } from '@Utils/TimeoutUtils';

import { OPEN_MESSAGE_AFTER } from './internal/constants';
import styles from './MotivatorPopup.module.scss';
import { MotivatorPopupProps } from './MotivatorPopup.props';

export const MotivatorPopup = ({ className }: MotivatorPopupProps) => {
  const { motivator, popup } = useUser();
  const userPopupHideMutation = useUserPopupHide();
  const userPopupSubmitMutation = useUserPopupSubmit();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError } = useApiError();

  const {
    value: isPopupOpen,
    setTrue: setPopupOpenTrue,
    setFalse: setPopupOpenFalse
  } = useBoolean(!!popup);

  const {
    value: isMessageOpen,
    setTrue: setMessageOpen,
    setFalse: setMessageClosed,
    toggle: togglePopup
  } = useBoolean(false);
  const { value: hasOpenedPopup, setTrue: setHasOpenedPopup } = useBoolean(false);

  const handleClose = useCallback(async () => {
    setMessageClosed();
    await sleep(POPUP_CLOSE_DURATION);
    setPopupOpenFalse();
    await sleep(POPUP_CLOSE_DURATION);
  }, [setPopupOpenFalse, setMessageClosed]);

  const handleCancel = useCallback(async () => {
    await handleClose();

    try {
      if (popup) {
        await userPopupHideMutation.mutateAsync({
          id: popup.id,
          title: popup.question,
          hide: true
        });
      }
    } catch (e) {
      handleError(e);
    }
  }, [handleClose, popup, userPopupHideMutation, handleError]);

  const handleLink = useCallback(async () => {
    await handleClose();

    try {
      if (popup) {
        await userPopupHideMutation.mutateAsync({
          id: popup.id,
          title: popup.question,
          hide: false
        });
      }
    } catch (e) {
      handleError(e);
    }

    if (!!popup?.buttonLink) {
      navigate(getMotivatorHref(popup.buttonLink));
    }
  }, [handleClose, handleError, popup, userPopupHideMutation, navigate]);

  const handleSubmit = useCallback(
    async (values: UserPopupFormValues) => {
      await handleClose();

      try {
        if (popup) {
          await userPopupSubmitMutation.mutateAsync({
            id: popup.id,
            title: popup.question,
            answers: popup.answers,
            ...values
          });

          toast.info(t('DOMAIN.USER.POPUP.SUCCESS'));
        }
      } catch (e) {
        handleError(e);
      }
    },
    [handleClose, handleError, popup, t, userPopupSubmitMutation]
  );

  const handleToggle = useCallback(() => {
    togglePopup();
    setHasOpenedPopup();
  }, [togglePopup, setHasOpenedPopup]);

  useEffect(() => {
    setPopupOpenTrue();

    if (!hasOpenedPopup) {
      const timeout = setTimeout(() => {
        setMessageOpen();
        setHasOpenedPopup();
      }, OPEN_MESSAGE_AFTER);

      return () => clearTimeout(timeout);
    }
  }, [popup, setPopupOpenTrue, setMessageOpen, isMessageOpen, hasOpenedPopup, setHasOpenedPopup]);

  if (popup) {
    return ReactDOM.createPortal(
      <div className={cn(styles.MotivatorPopup, { [styles.isOpen]: isPopupOpen }, className)}>
        <PopupMessage
          avatar={motivator?.avatar || defaultMotivator}
          name={motivator?.name || t('DOMAIN.USER.POPUP.MOTIVATOR')}
          message={popup.question}
          isOpen={isMessageOpen}
          onToggle={handleToggle}
          onClose={setMessageClosed}
        >
          {popup.type === UserPopupType.CONTENT ? (
            <SpacingGroup size="xl">
              {!!popup.buttonLink && !!popup.buttonText && (
                <Button onClick={handleLink} icon="arrow-right">
                  {popup.buttonText}
                </Button>
              )}

              <Anchor onClick={handleCancel}>{t('COMMON.GLOBALS.ACCEPT')}</Anchor>
            </SpacingGroup>
          ) : (
            <UserPopupForm popup={popup} onSubmit={handleSubmit} onCancel={handleCancel} />
          )}
        </PopupMessage>
      </div>,
      document.body
    );
  }

  return null;
};
