import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingRegisterForm } from '@Components/form';
import { SliderFullPage, useSliderFullPageItemContext } from '@Components/ui';

import { OnboardingRegisterSlideProps } from './OnboardingRegisterSlide.props';

export const OnboardingRegisterSlide = ({
  onSuccess,
  licenseCode
}: OnboardingRegisterSlideProps) => {
  const { slideNext } = useSliderFullPageItemContext();

  const { t } = useTranslation();

  const handleSuccess = useCallback(
    (userId: string, email: string, phoneNumber: string) => {
      onSuccess(userId, email, phoneNumber);
      slideNext();
    },
    [slideNext, onSuccess]
  );

  return (
    <SliderFullPage.Item showPagination>
      <SliderFullPage.Head
        title={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.HEADER.TITLE')}
        intro={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.HEADER.INTRO')}
        showPagination
      />

      <SliderFullPage.Block>
        <OnboardingRegisterForm onSuccess={handleSuccess} licenseCode={licenseCode} />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
