import cn from 'classnames';
import { useState } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchBlock, TabGroup } from '@Components/ui';

import styles from './Faq.module.scss';
import { FaqProps } from './Faq.props';
import { ArchiveTab, CategoryTab } from './internal/components';
import { getSearchResults } from './internal/utils';

export const Faq = ({ isSearchable, hasArchive, groups, className }: FaqProps) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');

  const results = useMemo(() => {
    if (!searchString) {
      return groups;
    }

    return getSearchResults(groups, searchString);
  }, [groups, searchString]);

  return (
    <div className={cn(styles.Faq, className)}>
      {isSearchable && (
        <SearchBlock
          title={t('DOMAIN.FAQ.SEARCH.TITLE')}
          buttonLabel={t('DOMAIN.FAQ.SEARCH.SHOW_RESULTS')}
          onSearch={setSearchString}
          placeholder={t('DOMAIN.FAQ.SEARCH.PLACEHOLDER')}
          className={styles.SearchBlock}
        />
      )}

      <TabGroup>
        {hasArchive && (
          <TabGroup.Child label={t('DOMAIN.FAQ.ALL')}>
            <ArchiveTab searchString={searchString} items={results} />
          </TabGroup.Child>
        )}

        {results.map((category) => {
          const hasItems = category.items.length > 0;

          return (
            <TabGroup.Child key={category.id} label={category.title} isDisabled={!hasItems}>
              <CategoryTab searchString={searchString} category={category} />
            </TabGroup.Child>
          );
        })}
      </TabGroup>
    </div>
  );
};
