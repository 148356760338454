import cn from 'classnames';

import { FormField, InputTextarea } from '@Components/ui';

import styles from './FormTextarea.module.scss';
import { FormTextareaProps } from './FormTextarea.props';

export const FormTextarea = ({ options, className, ...field }: FormTextareaProps) => {
  const classNames = cn(styles.FormTextarea, className);

  return (
    <FormField {...field} className={classNames}>
      <InputTextarea {...options} />
    </FormField>
  );
};
