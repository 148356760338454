import { MutableRefObject, useEffect } from 'react';

export const useResizeObserver = (
  callback: () => void,
  ref?: MutableRefObject<HTMLElement | null>
): void => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(callback);

    if (ref && ref.current) {
      resizeObserver.observe(ref.current);
    } else {
      resizeObserver.observe(document.body);
    }

    return () => resizeObserver.disconnect();
  }, [callback, ref]);
};
