import cn from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ActivityFinishModal } from '@Components/container';
import { ActivityFinishFormValues } from '@Components/form';
import { MODAL_ANIMATION_DURATION } from '@Components/layout/Modal/internals/constants';
import { Box, CallToAction, InputLike, TagAnimated, Text } from '@Components/ui';
import { useActivityFinish, useActivityFinishStep } from '@Hooks/activity';
import { useApiError, useBoolean } from '@Hooks/common';
import { isStartedAtPastDuration } from '@Utils/ActivityUtils';
import { sleep } from '@Utils/TimeoutUtils';

import styles from './ActivityFinish.module.scss';
import { ActivityFinishProps } from './ActivityFinish.props';

export const ActivityFinish = ({ activity, stepId, onFinish, className }: ActivityFinishProps) => {
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const [likeResult, setLikeResult] = useState<string>();
  const [isPastDuration, setIsPastDuration] = useState<boolean>();
  const activityFinishMutation = useActivityFinish();
  const activityFinishStepMutation = useActivityFinishStep();

  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);

  const handleClose = useCallback(async () => {
    setModalClosed();
    await sleep(MODAL_ANIMATION_DURATION);
    setLikeResult(undefined);
  }, [setModalClosed]);

  const handleSubmit = useCallback(
    async (values?: ActivityFinishFormValues, liked?: string) => {
      try {
        if (stepId) {
          await activityFinishStepMutation.mutateAsync({
            id: activity.id,
            slug: activity.slug,
            stepId
          });
        }

        const data = await activityFinishMutation.mutateAsync({
          id: activity.id,
          title: activity.title,
          type: activity.type[0].title,
          like: Boolean(Number(liked || likeResult)) || false,
          ...values
        });

        toast.success(t('DOMAIN.ACTIVITY.FINISH.SUCCESS'));

        if (onFinish) {
          if (isModalOpen) {
            await sleep(MODAL_ANIMATION_DURATION);
          }

          onFinish(data);
        }
      } catch (e) {
        handleError(e);
      } finally {
        handleClose();
      }
    },
    [
      activity.id,
      activity.slug,
      activity.title,
      activity.type,
      activityFinishMutation,
      activityFinishStepMutation,
      handleClose,
      handleError,
      onFinish,
      stepId,
      likeResult,
      isModalOpen,
      t
    ]
  );

  const handleChange = useCallback(
    async (_value: string) => {
      const pastDuration = isStartedAtPastDuration(activity);

      setLikeResult(_value);
      setIsPastDuration(pastDuration);

      if (!pastDuration || _value === '0') {
        setModalOpen();
      } else {
        await handleSubmit(undefined, _value);
      }
    },
    [activity, handleSubmit, setModalOpen]
  );

  return (
    <div className={cn(styles.ActivityFinish, className)}>
      <Box mb={2} className={styles.CallToAction}>
        <CallToAction isStatic isReversed isVisible label={activity.type[0].complete} />
      </Box>

      <InputLike value={likeResult} onChange={handleChange} data-testid="inputFinishActivity">
        <div className={styles.Body}>
          <Text.H3>{t('DOMAIN.ACTIVITY.FINISH.TITLE')}</Text.H3>

          <div className={styles.Claim}>
            <Text.Normal>{t('DOMAIN.ACTIVITY.FINISH.CLAIM_NOW')}</Text.Normal>

            <div className={styles.Points}>
              <TagAnimated size="lg" points={activity.points} shouldAnimate={false} />
            </div>
          </div>
        </div>
      </InputLike>

      <ActivityFinishModal
        isOpen={isModalOpen}
        onSubmit={handleSubmit}
        onClose={handleClose}
        isLiked={Boolean(Number(likeResult))}
        isStartedAtPastDuration={isPastDuration}
        isLoading={activityFinishStepMutation.isLoading || activityFinishMutation.isLoading}
        activity={activity}
      />
    </div>
  );
};
