import { IBaseModel } from '@Constants/interfaces';

export interface IPreference extends IBaseModel {
  key: PreferenceKey;
  values: string[];
}

export enum PreferenceKey {
  GGC_HEALTH = 'GGC_HEALTH',
  GGC_MOTIVATION = 'GGC_MOTIVATION',
  GGC_COMPETENCE = 'GGC_COMPETENCE',
  ACTIVITY_SUBJECTS = 'ACTIVITY_SUBJECTS',
  ACTIVITY_TYPES = 'ACTIVITY_TYPES',
  SUPPORT_TYPES = 'SUPPORT_TYPES',
  CONTACT_TYPES = 'CONTACT_TYPES',
  EDUCATION = 'EDUCATION',
  PROFESSION = 'PROFESSION',
  WIDGETS = 'WIDGETS',
  TOURDOTS = 'TOURDOTS'
}

export interface PreferenceSubmitRequest {
  key: PreferenceKey;
  values: string[];
}

export type PreferenceSubmitBatchRequest = {
  [T in PreferenceKey]?: string[];
};

export type PreferenceSubmitResponse = IPreference[];

export type PreferenceResponse = IPreference[];
