import cn from 'classnames';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { UserConfetti } from '@Components/container';
import { ProfilePicture, Text } from '@Components/ui';
import { useUser } from '@Hooks/user';
import { getImageUrl } from '@Utils/ImageUtils';

import styles from './Celebration.module.scss';
import { CelebrationProps } from './Celebration.props';

export const Celebration = ({ shouldExplode, className }: CelebrationProps) => {
  const avatarRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const { avatar } = useUser();

  const MemoizedConfetti = useMemo(() => {
    return <UserConfetti targetRef={avatarRef} isExploding={shouldExplode} />;
  }, [shouldExplode]);

  return (
    <div className={cn(styles.Celebration, className)}>
      {MemoizedConfetti}

      <div ref={avatarRef}>
        <ProfilePicture image={avatar && getImageUrl(avatar)} size={158} className={styles.Image} />
      </div>

      <Text.Handwritten className={styles.Text}>
        {t('DOMAIN.CHALLENGE.FINISH.CELEBRATION_TEXT')}
      </Text.Handwritten>
    </div>
  );
};
