import { getMonth, getYear, isSameMonth } from 'date-fns';
import { t } from 'i18next';
import { useState } from 'react';

import { Timeline } from '@Components/container';
import { Block, Box, DateSelector, Loader, Text } from '@Components/ui';
import { useInsightOverview, useInsightTimeline } from '@Hooks/insight';
import { useUserChallengeList } from '@Hooks/userChallenge';
import { getToday } from '@Utils/DateUtils';

import { InsightProgressOverview } from '../InsightProgressOverview/InsightProgressOverview';
import styles from './InsightProgressBody.module.scss';

export const InsightProgressBody = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(getToday());

  const month = getMonth(selectedDate) + 1;
  const year = getYear(selectedDate);

  const {
    data: insightOverview,
    isLoading: isOverviewLoading,
    isError: isOverviewError
  } = useInsightOverview({ month, year });

  const {
    data: timeline,
    isLoading: isTimelineLoading,
    isError: isTimelineError
  } = useInsightTimeline({ month, year });

  const {
    data: userChallenges,
    isLoading: isUserChallengesLoading,
    isError: isUserChallengesError
  } = useUserChallengeList();

  const isError = isOverviewError || isTimelineError || isUserChallengesError;
  const isLoading = isOverviewLoading || isTimelineLoading || isUserChallengesLoading;

  const hasChallenges = userChallenges?.results && userChallenges.results.length > 0;
  const isCurrentMonth = isSameMonth(getToday(), selectedDate);

  return (
    <Block>
      <DateSelector
        selectedDate={selectedDate}
        maxDate={getToday()}
        onChange={setSelectedDate}
        className={styles.DateSelector}
      />

      {(isLoading || isError) && (
        <Box my={8}>
          {isLoading && <Loader>{t('DOMAIN.PROGRESS.OVERVIEW.LOADING')}</Loader>}

          {isError && <Text.Error>{t('DOMAIN.PROGRESS.OVERVIEW.ERROR')}</Text.Error>}
        </Box>
      )}

      {insightOverview && timeline && (
        <>
          <InsightProgressOverview overview={insightOverview} className={styles.Overview} />

          <Timeline
            showChallengeStartWidget={!hasChallenges && isCurrentMonth}
            timeline={timeline}
          />
        </>
      )}
    </Block>
  );
};
