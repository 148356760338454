import { BaseService, dePortalenService } from '@Services/common';
import { toQueryString } from '@Utils/StringUtils';

import { StatisticGetAllRequest, StatisticGetAllResponse } from './StatisticService.props';

export class StatisticService extends BaseService {
  getAll = async (config?: StatisticGetAllRequest) => {
    const response = await this.get<StatisticGetAllResponse>(`?${toQueryString(config)}`);

    return response.data;
  };
}

export const statisticService = new StatisticService(
  dePortalenService.getAxiosInstance(),
  '/statistics'
);
