import { CardActivity, CardUserActivity } from '@Components/container';
import { isUserActivity } from '@Utils/ActivityUtils';

import { CardTodoProps } from './CardTodo.props';

export const CardTodo = ({ todo, className }: CardTodoProps) => {
  if (isUserActivity(todo)) {
    return <CardUserActivity userActivity={todo} className={className} />;
  }

  return <CardActivity activity={todo} className={className} />;
};
