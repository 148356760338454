import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { Questionnaire } from '@Components/container';
import { Loader, Text } from '@Components/ui';
import {
  useOnboardingGetPersonaQuestionnaire,
  useOnboardingPreloadPersonaQuestionnaire
} from '@Hooks/onboarding';
import { onboardingService } from '@Services/Onboarding';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';
import { userService } from '@Services/User';

import {
  PersonaCheckQuestions,
  QuestionnairePersonaIntroSlide,
  QuestionnairePersonaOutroSlide
} from './internal/components';
import { QuestionnairePersonaProps } from './QuestionnairePersona.props';

export const QuestionnairePersona = ({ className }: QuestionnairePersonaProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: personaQuestionnaire, isLoading, isError } = useOnboardingGetPersonaQuestionnaire();
  useOnboardingPreloadPersonaQuestionnaire();

  const totalSlides = (personaQuestionnaire?.totalSteps ?? 0) + 2; // Outro and intro slides included
  const currentSlideIndex = (personaQuestionnaire?.currentStep ?? 1) + 1; // Intro slide added

  const handleFinish = useCallback(() => {
    queryClient.invalidateQueries([onboardingService.getEndpoint(), 'persona', 'questionnaire']);
    queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
  }, [queryClient]);

  const handleStepFinish = useCallback(() => {
    queryClient.invalidateQueries([onboardingService.getEndpoint(), 'persona', 'questionnaire']);
  }, [queryClient]);

  if (isLoading) {
    return <Loader>{t('COMPONENTS.QUESTIONNAIRE.LOADING')}</Loader>;
  }

  if (isError) {
    return <Text.Error>{t('COMPONENTS.QUESTIONNAIRE.COULD_NOT_FETCH')}</Text.Error>;
  }

  if (personaQuestionnaire) {
    if (personaQuestionnaire.status === QuestionnaireSubmissionStatus.COMPLETED) {
      return <PersonaCheckQuestions />;
    }

    return (
      <>
        <Questionnaire
          className={className}
          initialSubmission={personaQuestionnaire}
          introSlide={
            <QuestionnairePersonaIntroSlide totalSlides={totalSlides} currentSlideIndex={1} />
          }
          outroSlide={
            <QuestionnairePersonaOutroSlide
              totalSlides={totalSlides}
              currentSlideIndex={currentSlideIndex + 1}
            />
          }
          totalSlides={totalSlides}
          currentSlideIndex={currentSlideIndex}
          onFinish={handleFinish}
          onStepFinish={handleStepFinish}
        />
      </>
    );
  }

  return null;
};
