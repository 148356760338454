import cn from 'classnames';
import { Controller } from 'react-hook-form';

import { FormField, InputCheckbox } from '@Components/ui';
import { getFieldStateValidation } from '@Utils/FormUtils';

import styles from './FormCheckbox.module.scss';
import { FormCheckboxProps } from './FormCheckbox.props';

export const FormCheckbox = ({ options, className, ...form }: FormCheckboxProps) => {
  const classNames = cn(styles.FormCheckbox, className);

  return (
    <FormField {...form} className={classNames} hasController>
      <Controller
        name={form.name}
        render={({ field, fieldState }) => {
          const { isValid, isError } = getFieldStateValidation(fieldState);

          return <InputCheckbox isValid={isValid} isError={isError} {...options} {...field} />;
        }}
      />
    </FormField>
  );
};
