import { Preferences, QuestionnairePersona } from '@Components/container';
import { Container } from '@Components/layout';
import { useUser } from '@Hooks/user';
import { ProfileStatusStep } from '@Services/User';

import { OnboardingPreferenceMotivator } from './internal/components';

export const OnboardingPreferenceScreen = () => {
  const { profileStatusSteps, motivator } = useUser();

  return (
    <Container cols={10}>
      {profileStatusSteps.includes(ProfileStatusStep.PREFERENCES) ? (
        <Preferences />
      ) : motivator ? (
        <OnboardingPreferenceMotivator />
      ) : (
        <QuestionnairePersona />
      )}
    </Container>
  );
};
