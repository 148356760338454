import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { QuestionnaireSelfTest } from '@Components/container';
import { Container } from '@Components/layout';
import { Loader } from '@Components/ui';
import { useOnboardingGetSelfTestQuestionnaire } from '@Hooks/onboarding';
import { AppRoutes } from '@Navigation/Routes';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';

import { SelfTestDomainSelection } from './internal/components';

export const SelfTestQuestionnaireScreen = () => {
  const navigate = useNavigate();
  const { data: selfTestQuestionnaire, isLoading: isSelfTestQuestionnaireLoading } =
    useOnboardingGetSelfTestQuestionnaire();

  const handleFinish = useCallback(() => {
    navigate(AppRoutes.getSelfTestResults().to);
  }, [navigate]);

  const shouldShowDomainSelection =
    selfTestQuestionnaire?.status === QuestionnaireSubmissionStatus.DOMAIN_SELECTION &&
    selfTestQuestionnaire.isAdaptive;

  if (isSelfTestQuestionnaireLoading) {
    return (
      <Container cols={10}>
        <Loader />
      </Container>
    );
  }

  return (
    <Container cols={10}>
      {shouldShowDomainSelection ? (
        <SelfTestDomainSelection />
      ) : (
        <QuestionnaireSelfTest onFinish={handleFinish} />
      )}
    </Container>
  );
};
