import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@Components/ui';
import { RewardListRequest } from '@Services/Reward';
import { removeEmptyOrUndefinedFromObject } from '@Utils/CommonUtils';
import { toLocaleString } from '@Utils/StringUtils';

import { RewardFilterSort, RewardFilterSortValues, RewardFilterType } from './internal/components';
import styles from './RewardFilter.module.scss';
import { RewardFilterProps } from './RewardFilter.props';

export const RewardFilter = ({ onChange, count }: RewardFilterProps) => {
  const { t } = useTranslation();

  const [type, setType] = useState<string>();
  const [sort, setSort] = useState<RewardFilterSortValues>();

  useEffect(() => {
    const params = removeEmptyOrUndefinedFromObject({
      sortBy: sort?.sortBy,
      sortDirection: sort?.sortDirection,
      typeId: type
    }) as RewardListRequest;

    if (!isEmpty(params)) {
      onChange(params);
    }
  }, [onChange, sort, type]);

  const handleSort = useCallback((values: RewardFilterSortValues) => {
    setSort(values);
  }, []);

  const handleType = useCallback((id?: string) => {
    setType(id);
  }, []);

  return (
    <div className={styles.RewardFilter}>
      <div className={styles.Type}>
        <RewardFilterType onChange={handleType} />
      </div>

      <div className={styles.Filters}>
        <Text.Small
          dangerousHtml={t('DOMAIN.REWARD.OVERVIEW.FILTER.ITEMS', {
            count: toLocaleString(count || 0)
          })}
        />

        <div className={styles.Sort}>
          <RewardFilterSort onChange={handleSort} />
        </div>
      </div>
    </div>
  );
};
