import { useTranslation } from 'react-i18next';

import { TimelineItemType } from '@Services/Insight';

export const useGroupTitle = (type: TimelineItemType, isFuture = false, isCompleted = false) => {
  const { t } = useTranslation();

  const title = t(
    `COMPONENTS.TIMELINE.GROUP_TITLE.PREFIX.${isFuture && !isCompleted ? 'UPCOMING' : 'DONE'}`
  );

  const statusTranslations = t(`COMPONENTS.TIMELINE.GROUP_TITLE.STATUS`, { returnObjects: true });

  let status: keyof typeof statusTranslations = 'STARTED';

  switch (type) {
    case TimelineItemType.CHALLENGE:
      if (isCompleted) {
        status = 'COMPLETED';
      } else if (isFuture) {
        status = 'WILL_COMPLETE';
      }
      break;
    case TimelineItemType.ACTIVITY:
      if (isCompleted) {
        status = 'FINISHED';
      } else if (isFuture) {
        status = 'WILL_FINISH';
      }
      break;
    case TimelineItemType.REWARD:
      status = 'REDEEMED';
      break;
    case TimelineItemType.MILESTONE:
      status = 'ACHIEVED';
  }

  return title + ' <strong>' + statusTranslations[status] + '</strong>';
};
