import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { ActivityFinishRequest, activityService } from '@Services/Activity';
import { todoService } from '@Services/Todo';
import { userService } from '@Services/User';
import { userActivityService } from '@Services/UserActivity';
import { userChallengeService } from '@Services/UserChallenge';
import { trackMatomoActivityFinish } from '@Utils/MatomoUtils';

export const useActivityFinish = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: ActivityFinishRequest) => {
      return activityService.finishActivity(config);
    },
    {
      onSuccess: (_, { title, like, isUserActivity, type }) => {
        trackMatomoActivityFinish(trackEvent, !!isUserActivity, like, title, type);

        queryClient.invalidateQueries([userActivityService.getEndpoint()]);
        queryClient.invalidateQueries([activityService.getEndpoint()]);
        queryClient.invalidateQueries([userChallengeService.getEndpoint()]);
        queryClient.invalidateQueries([todoService.getEndpoint()]);
        queryClient.invalidateQueries([userService.getEndpoint()]);
      }
    }
  );
};
