import { BrowserRouter } from 'react-router-dom';

import { useAuthStatus } from '@Hooks/auth';
import { PrivateStack, PublicStack } from '@Navigation/index';

export const Router = () => {
  const { isAuthenticated } = useAuthStatus();

  return <BrowserRouter>{isAuthenticated ? <PrivateStack /> : <PublicStack />}</BrowserRouter>;
};
