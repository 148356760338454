import { SliderFullPage } from '@Components/ui';

import { ForgotPasswordEmailSlide } from './internal/ForgotPasswordEmailSlide/ForgotPasswordEmailSlide';
import { ForgotPasswordSuccessSlide } from './internal/ForgotPasswordSuccessSlide/ForgotPasswordSuccessSlide';

export const AuthForgotPasswordScreen = () => {
  return (
    <SliderFullPage>
      <ForgotPasswordEmailSlide />

      <ForgotPasswordSuccessSlide />
    </SliderFullPage>
  );
};
