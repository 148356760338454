import cn from 'classnames';

import styles from './Bold.module.scss';
import { BoldProps } from './Bold.props';

export const Bold = ({
  className,
  isParagraph = true,
  children,
  dangerousHtml,
  color,
  ...props
}: BoldProps) => {
  if (!children && !dangerousHtml) {
    return null;
  }

  const classNames = cn(styles.Bold, className);

  const inlineStyle = {
    color
  };

  return isParagraph ? (
    <p
      className={classNames}
      dangerouslySetInnerHTML={!!dangerousHtml ? { __html: dangerousHtml } : undefined}
      style={inlineStyle}
      {...props}
    >
      {children}
    </p>
  ) : (
    <strong
      className={classNames}
      dangerouslySetInnerHTML={!!dangerousHtml ? { __html: dangerousHtml } : undefined}
      style={inlineStyle}
      {...props}
    >
      {children}
    </strong>
  );
};
