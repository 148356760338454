import { useMemo } from 'react';

import { Box, List, Text } from '@Components/ui';
import { PreprElementListType } from '@Constants/preprTypes';

import { PreprElementListProps } from './PreprElementList.props';
import { getListItemsFromHtml } from './utils';

export const PreprElementList = ({ element }: PreprElementListProps) => {
  const listItems = useMemo(() => {
    if (!element.list) {
      return [];
    }

    return getListItemsFromHtml(element.list).map((listItem, idx) => {
      return <Text.Large key={idx} dangerousHtml={listItem[0]} />;
    });
  }, [element.list]);

  return (
    <Box mb={6}>
      {element.type === PreprElementListType.ORDERED && <List.Ordered>{listItems}</List.Ordered>}

      {element.type === PreprElementListType.CHECKBOX && <List.Checked>{listItems}</List.Checked>}

      {(element.type === PreprElementListType.UNORDERED || !element.type) && (
        <List.Unordered>{listItems}</List.Unordered>
      )}
    </Box>
  );
};
