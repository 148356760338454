import { BaseService, dePortalenService } from '@Services/common';
import { toQueryString } from '@Utils/StringUtils';

import { UserThemeGetAllRequest, UserThemeGetAllResponse } from './UserThemeService.props';

export class UserThemeService extends BaseService {
  getAll = async (config?: UserThemeGetAllRequest) => {
    const response = await this.get<UserThemeGetAllResponse>(config && `?${toQueryString(config)}`);

    return response.data;
  };
}

export const userThemeService = new UserThemeService(
  dePortalenService.getAxiosInstance(),
  '/users/me/themes'
);
