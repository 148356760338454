import cn from 'classnames';

import { Text } from '@Components/ui';

import styles from './PageNumber.module.scss';
import { PageNumberProps } from './PageNumber.props';

export const PageNumber = ({ current, total, className, label }: PageNumberProps) => {
  return (
    <Text.Normal className={cn(styles.PageNumber, className)}>
      {label}
      {`${current}/${total}`}
    </Text.Normal>
  );
};
