import cn from 'classnames';

import { Normal } from '../Normal/Normal';
import styles from './Success.module.scss';
import { SuccessProps } from './Success.props';

export const Success = ({ className, color, ...props }: SuccessProps) => {
  const classNames = cn(styles.Success, className);

  return <Normal className={classNames} {...props} />;
};
