import ALERT from './ALERT.json';
import AUTHOR_ACTIVITY from './AUTHORACTIVITY.json';
import BACK_BUTTON from './BACKBUTTON.json';
import FOOTER from './FOOTER.json';
import HEADER from './HEADER.json';
import INACTIVE_LOGOUT_POPUP from './INACTIVE_LOGOUT_POPUP.json';
import INPUT_IMAGE from './INPUTIMAGE.json';
import INPUT_LIKE from './INPUTLIKE.json';
import INPUT_SELECT from './INPUTSELECT.json';
import INPUT_SLIDER from './INPUTSLIDER.json';
import LAZY_LIST from './LAZYLIST.json';
import MEDAL from './MEDAL.json';
import POLL from './POLL.json';
import PROFILE_STATUS from './PROFILESTATUS.json';
import PROGRESS_BLOCK from './PROGRESSBLOCK.json';
import PROGRESS_CHALLENGE from './PROGRESSCHALLENGE.json';
import PROGRESS_DATE from './PROGRESSDATE.json';
import QUESTIONNAIRE from './QUESTIONNAIRE.json';
import QUOTE from './QUOTE.json';
import READING_TIME from './READINGTIME.json';
import SCORE_CIRCLE from './SCORECIRCLE.json';
import SIDEBAR from './SIDEBAR.json';
import SORT from './SORT.json';
import TIMELINE from './TIMELINE.json';
import TIMELINE_CHALLENGE from './TIMELINECHALLENGE.json';
import TIMELINE_SMALL from './TIMELINESMALL.json';
import TOURDOT from './TOURDOT.json';

export default {
  INACTIVE_LOGOUT_POPUP,
  TIMELINE_SMALL,
  POLL,
  QUESTIONNAIRE,
  TIMELINE_CHALLENGE,
  BACK_BUTTON,
  AUTHOR_ACTIVITY,
  LAZY_LIST,
  PROFILE_STATUS,
  TOURDOT,
  TIMELINE,
  HEADER,
  QUOTE,
  SORT,
  INPUT_IMAGE,
  SCORE_CIRCLE,
  PROGRESS_CHALLENGE,
  PROGRESS_BLOCK,
  MEDAL,
  PROGRESS_DATE,
  INPUT_LIKE,
  INPUT_SLIDER,
  FOOTER,
  INPUT_SELECT,
  READING_TIME,
  SIDEBAR,
  ALERT
};
