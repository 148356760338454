import { useCallback, useMemo } from 'react';

import { Form } from '@Components/ui';
import { InputOption } from '@Constants/interfaces';
import { answersToOptions, selectedAnswerId } from '@Utils/QuestionnaireUtils';
import Yup from '@Utils/YupUtils';

import { QuestionnaireFormInputProps } from '../QuestionnaireFormInput.props';
import { QuestionnaireFormDropdownValues } from './QuestionnaireFormDropdown.props';

export const QuestionnaireFormDropdown = ({
  question,
  children,
  onSubmit
}: QuestionnaireFormInputProps) => {
  const handleSubmit = useCallback(
    async ({ answer }: QuestionnaireFormDropdownValues) => {
      const adaptedValues = {
        answers: [answer]
      };

      await onSubmit(adaptedValues);
    },
    [onSubmit]
  );

  const options: InputOption[] = useMemo(() => {
    return answersToOptions(question.answers ?? []);
  }, [question]);

  const optionsToValidate = useMemo(() => {
    return question.answers?.map((answer) => answer.id) ?? [];
  }, [question]);

  const validationSchema = Yup.object().shape({
    answer: Yup.mixed().oneOf(optionsToValidate).required()
  });

  return (
    <Form.Form<QuestionnaireFormDropdownValues>
      key={question.id}
      initialValues={{ answer: selectedAnswerId(question.answers ?? []) }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      data-testid="questionnaireFormDropdown"
    >
      <Form.Input.Select
        name="answer"
        data-testid="answerInput"
        options={{
          options,
          isDisabled: question.editable === false
        }}
      />

      {children}
    </Form.Form>
  );
};
