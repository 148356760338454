import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@Components/layout';
import { Anchor, Box, Button, SpacingGroup, Text } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useUser, useUserToggleTourdots, useUserUpdateMe } from '@Hooks/user';

import { DashboardWelcomeProps } from './DashboardWelcome.props';
import { DashboardWelcomeHead } from './internal/components';

export const DashboardWelcome = ({ className }: DashboardWelcomeProps) => {
  const { firstName, lastName, isFirstTime } = useUser();
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const userUpdateMeMutation = useUserUpdateMe();
  const userToggleTourdotsMutation = useUserToggleTourdots();

  const handleClick = useCallback(
    (start: boolean) => async () => {
      try {
        await userToggleTourdotsMutation.mutateAsync({
          hide: !start
        });

        await userUpdateMeMutation.mutateAsync({
          firstName: firstName,
          lastName: lastName,
          isFirstTime: false
        });
      } catch (e) {
        handleError(e);
      }
    },
    [firstName, lastName, handleError, userToggleTourdotsMutation, userUpdateMeMutation]
  );

  return (
    <Modal
      title={t('COMPONENTS.TOURDOT.WELCOME.TITLE')}
      isOpen={isFirstTime || false}
      onClose={handleClick(false)}
      className={className}
      head={<DashboardWelcomeHead />}
    >
      <Text.Large>{t('COMPONENTS.TOURDOT.WELCOME.DESCRIPTION')}</Text.Large>

      <Box mt={3}>
        <SpacingGroup size="xxl">
          <Button
            onClick={handleClick(true)}
            icon="arrow-right"
            isLoading={userUpdateMeMutation.isLoading}
          >
            {t('COMPONENTS.TOURDOT.WELCOME.BUTTON_START')}
          </Button>

          <Anchor onClick={handleClick(false)}>
            {t('COMPONENTS.TOURDOT.WELCOME.BUTTON_SKIP')}
          </Anchor>
        </SpacingGroup>
      </Box>
    </Modal>
  );
};
