import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListProgressTheme } from '@Components/list';
import { ProgressThemeLegend } from '@Components/list/ListProgressTheme/internal/components';
import {
  Block,
  Box,
  Loader,
  SliderFullPage,
  SliderNavigation,
  Spacer,
  Text,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';
import { SortDirection } from '@Constants/enums';
import { Result } from '@Constants/interfaces';
import { useScrollTo } from '@Hooks/common';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { useUser } from '@Hooks/user';
import { useUserThemeGetAll } from '@Hooks/userTheme';
import { IUserTheme } from '@Services/UserTheme';
import { trackMatomoChallengeThemeSelected } from '@Utils/MatomoUtils';

import { ChallengeAddHeading } from '../ChallengeAddHeading/ChallengeAddHeading';
import styles from './ChallengeAddThemeSlide.module.scss';
import { ChallengeAddThemeSlideProps } from './ChallengeAddThemeSlide.props';

export const ChallengeAddThemeSlide = ({
  selectedTheme,
  onSelectTheme
}: ChallengeAddThemeSlideProps) => {
  const navigationRef = useRef<HTMLElement | null>(null);

  const { t } = useTranslation();
  const { total } = useSliderFullPageContext();
  const { index, slideNext } = useSliderFullPageItemContext();
  const [activeTheme, setActiveTheme] = useState<IUserTheme>();
  const trackEvent = useMatomoTrackEvent();

  const { triggerScroll } = useScrollTo(navigationRef);

  const { data: userThemes, isFetched } = useUserThemeGetAll({
    includeRecommended: true,
    includeWithoutScore: true,
    sortDirection: SortDirection.DESC
  });

  const { hasPhasedSelfTest } = useUser();

  const recommendedThemes = useMemo(() => {
    return userThemes?.filter((userTheme) => userTheme.recommended);
  }, [userThemes]);

  const availableThemes = useMemo(() => {
    return userThemes?.filter(
      (userTheme) => userTheme.themeResult !== null && userTheme.themeResult !== Result.NONE
    );
  }, [userThemes]);

  const unscoredThemes = useMemo(() => {
    return userThemes?.filter((userTheme) => userTheme.themeResult === null);
  }, [userThemes]);

  const unavailableThemes = useMemo(() => {
    return userThemes?.filter((userTheme) => userTheme.themeResult === Result.NONE);
  }, [userThemes]);

  const handleNext = useCallback(() => {
    slideNext();

    trackMatomoChallengeThemeSelected(trackEvent, activeTheme);
  }, [activeTheme, slideNext, trackEvent]);

  const handleThemeSelect = useCallback(
    (themeId: string) => {
      const newlySelectedTheme = userThemes?.find((userTheme) => userTheme.theme.id === themeId);

      if (newlySelectedTheme) {
        setActiveTheme(newlySelectedTheme);
        onSelectTheme(newlySelectedTheme);

        triggerScroll();
      }
    },
    [onSelectTheme, userThemes, triggerScroll]
  );

  const hasAvailableThemes = !!availableThemes && availableThemes.length > 0;
  const hasUnscoredThemes = !!unscoredThemes && unscoredThemes.length > 0;
  const hasUnavailableThemes = !!unavailableThemes && unavailableThemes.length > 0;

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head isLarge>
        <ChallengeAddHeading
          title={t('DOMAIN.CHALLENGE.ADD.THEME.TITLE')}
          intro={t('DOMAIN.CHALLENGE.ADD.THEME.INTRO')}
        />
      </SliderFullPage.Head>

      <SliderFullPage.Block isLarge>
        {recommendedThemes && recommendedThemes.length > 0 && (
          <Box mb={3}>
            <Block theme="gray" padding="sm">
              <Text.H3 className={styles.RecommendedTitle}>
                {t('DOMAIN.CHALLENGE.ADD.THEME.RECOMMENDED')}
              </Text.H3>

              <ListProgressTheme
                items={recommendedThemes}
                checkedId={selectedTheme?.theme.id}
                onChange={handleThemeSelect}
              />
            </Block>
          </Box>
        )}

        {!isFetched && <Loader />}

        {!!hasAvailableThemes && (
          <Box mb={4}>
            <Text.Large className={styles.SelectThemeTitle}>
              {t('DOMAIN.CHALLENGE.ADD.THEME.OVERVIEW_POSSIBLE')}
            </Text.Large>

            <ListProgressTheme
              items={availableThemes}
              checkedId={selectedTheme?.theme.id}
              onChange={handleThemeSelect}
            />
          </Box>
        )}

        {hasUnscoredThemes && (
          <Box mb={4}>
            <Text.Large className={styles.SelectThemeTitle}>
              {hasPhasedSelfTest
                ? t('DOMAIN.CHALLENGE.ADD.THEME.OVERVIEW_COMING_UP')
                : t('DOMAIN.CHALLENGE.ADD.THEME.OVERVIEW_UNSCORED')}
            </Text.Large>

            <ListProgressTheme
              items={unscoredThemes}
              checkedId={selectedTheme?.theme.id}
              onChange={handleThemeSelect}
            />
          </Box>
        )}

        {hasUnavailableThemes && (
          <>
            <Text.Large className={styles.SelectThemeTitle}>
              {t('DOMAIN.CHALLENGE.ADD.THEME.OVERVIEW_IRRELEVANT')}
            </Text.Large>

            <ListProgressTheme
              items={unavailableThemes}
              checkedId={selectedTheme?.theme.id}
              onChange={handleThemeSelect}
            />
          </>
        )}

        <Box mb={3}>
          <Spacer top={2} bottom={2} />

          <ProgressThemeLegend themes={[]} />
        </Box>

        <SliderNavigation
          ref={navigationRef}
          current={index + 1}
          total={total}
          nextLabel={t('DOMAIN.CHALLENGE.ADD.THEME.NEXT_LABEL')}
          onNext={handleNext}
          isDisabled={!selectedTheme}
        />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
