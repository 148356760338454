import { useTranslation } from 'react-i18next';

import { ProgressLabeled } from '@Components/ui';

import styles from './DashboardStatisticTab.module.scss';
import { DashboardStatisticTabProps } from './DashboardStatisticTab.props';

export const DashboardStatisticTab = ({ configuration, statistic }: DashboardStatisticTabProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ProgressLabeled
        current={statistic.challengesCompleted}
        total={configuration.totalChallenges}
        label={t('DOMAIN.DASHBOARD.BLOCKS.STATISTIC.CONTENT.CHALLENGES')}
        className={styles.Progress}
      />

      <ProgressLabeled
        current={statistic.activitiesCompleted}
        total={configuration.totalKNIItems}
        label={t('DOMAIN.DASHBOARD.BLOCKS.STATISTIC.CONTENT.ACTIVITES')}
        className={styles.Progress}
      />

      <ProgressLabeled
        current={statistic.totalPoints}
        total={configuration.totalScore}
        label={t('DOMAIN.DASHBOARD.BLOCKS.STATISTIC.CONTENT.POINTS')}
        className={styles.Progress}
      />

      <ProgressLabeled
        current={statistic.milestonesCompleted}
        total={configuration.totalMilestones}
        label={t('DOMAIN.DASHBOARD.BLOCKS.STATISTIC.CONTENT.MILESTONES')}
        className={styles.Progress}
      />
    </>
  );
};
