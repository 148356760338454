import cn from 'classnames';
import { format, isFuture } from 'date-fns';
import { useRef } from 'react';

import { Text } from '@Components/ui';

import { TimelineCTA, TimelineGroup, TimelineTypeGroup } from './internal/components';
import { useCurrentMiddle } from './internal/hooks';
import { groupItemsByType } from './internal/utils/grouping';
import styles from './Timeline.module.scss';
import { TimelineProps } from './Timeline.props';

export const Timeline = ({ timeline, showChallengeStartWidget, className }: TimelineProps) => {
  const dayRefs = useRef<Array<HTMLDivElement | null>>([]);
  const { currentMiddle } = useCurrentMiddle();

  const isDayActive = (idx: number) => {
    const refElement = dayRefs.current[idx];

    if (refElement) {
      const locationY = refElement.offsetTop;

      return locationY < currentMiddle;
    }

    return false;
  };

  return (
    <div className={cn(styles.Timeline, className)}>
      {showChallengeStartWidget && (
        <div className={styles.Day}>
          <div className={styles.Date}></div>

          <div className={styles.Groups}>
            <TimelineGroup currentMiddle={currentMiddle} isFuture>
              <TimelineCTA />
            </TimelineGroup>
          </div>
        </div>
      )}

      {Object.entries(timeline).map(([dayKey, dayItems], dayIdx) => {
        const dayDate = new Date(dayKey);
        const isFutureDate = isFuture(dayDate);

        return (
          <div ref={(el) => (dayRefs.current[dayIdx] = el)} key={dayKey} className={styles.Day}>
            <div
              className={cn(styles.Date, {
                [styles.isActive]: isDayActive(dayIdx),
                [styles.isDisabled]: isFutureDate
              })}
            >
              <Text.H2 className={styles.Day}>{format(dayDate, 'dd')}</Text.H2>

              <Text.Micro className={styles.Month}>{format(dayDate, 'MMM')}.</Text.Micro>
            </div>

            <div className={styles.Groups}>
              {Object.values(groupItemsByType(dayItems)).map((typeGroup) => {
                return (
                  <TimelineTypeGroup
                    group={typeGroup}
                    day={dayDate}
                    currentMiddle={currentMiddle}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
