import { authService } from '@Services/AuthService';
import { brandInterceptor, noCacheInterceptor } from '@Utils/NetworkUtils';
import { getAccessTokens } from '@Utils/StorageUtils';

import { ApiClient } from '../ApiClient/ApiClient';

const getAccessToken = () => {
  const tokens = getAccessTokens();

  return tokens?.accessToken;
};

export const dePortalenService = new ApiClient(
  process.env.REACT_APP_API_URL as string,
  authService.logout,
  authService.refreshTokens,
  getAccessToken,
  [
    {
      onFulfilled: brandInterceptor
    },
    {
      onFulfilled: noCacheInterceptor
    }
  ]
);
