import { useTranslation } from 'react-i18next';

import {
  Box,
  SliderFullPage,
  SliderNavigation,
  Text,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';

export const PreferencesIntroSlide = () => {
  const { t } = useTranslation();
  const { total } = useSliderFullPageContext();
  const { index, slideNext } = useSliderFullPageItemContext();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        title={t('DOMAIN.PREFERENCES.INTRO.TITLE')}
        superTitle={t('DOMAIN.PREFERENCES.TITLE')}
      />

      <SliderFullPage.Block title={t('DOMAIN.PREFERENCES.INTRO.BODY.TITLE')}>
        <Text.Normal>{t('DOMAIN.PREFERENCES.INTRO.BODY.DESCRIPTION')}</Text.Normal>

        <Box mt={5}>
          <SliderNavigation onNext={slideNext} current={index + 1} total={total} />
        </Box>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
