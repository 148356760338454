import { forwardRef, PureComponent } from 'react';

import {
  Slider,
  SliderFullPageBlock,
  SliderFullPageBlockProps,
  SliderFullPageHead,
  SliderFullPageHeadProps,
  SliderFullPageItem,
  SliderFullPageItemProps
} from './internal/components';
import { SliderFullPageProps } from './SliderFullPage.props';

export * from './internal/context';
export * from './internal/components/SliderFullPageItem/context';
export class SliderFullPage extends PureComponent<SliderFullPageProps> {
  static Block = forwardRef((props: SliderFullPageBlockProps, ref: any) => (
    <SliderFullPageBlock {...props} ref={ref} />
  ));

  static Head = (props: SliderFullPageHeadProps) => <SliderFullPageHead {...props} />;

  static Item = (props: SliderFullPageItemProps) => <SliderFullPageItem {...props} />;

  render() {
    return <Slider {...this.props} />;
  }
}
