import { useMutation, useQueryClient } from 'react-query';

import { onboardingService, OnboardingSubmitSelfTestDomainsRequest } from '@Services/Onboarding';

export const useOnboardingSubmitSelfTestDomains = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: OnboardingSubmitSelfTestDomainsRequest) => {
      return onboardingService.submitSelfTestDomains(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([onboardingService.getEndpoint(), 'selftest']);
      }
    }
  );
};
