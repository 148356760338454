export const keepInView = (x: number, y: number, elementSize: DOMRect) => {
  const corrections = { x: 0, y: 0 };
  let correctedX = x;
  let correctedY = y;

  const documentSize = document.documentElement.getBoundingClientRect();

  // too far left
  if (correctedX < 0) {
    corrections.x = (correctedX - 16) * -1;
    correctedX = 16;
  }

  // too far up
  if (correctedY < 0) {
    corrections.y = (correctedY - 16) * -1;
    correctedY = 16;
  }

  // too far right
  if (correctedX + elementSize.width > documentSize.width) {
    const recalculatedX = documentSize.width - elementSize.width - 16;

    corrections.x = (correctedX - recalculatedX) * -1;
    correctedX = recalculatedX;
  }

  // too far down
  if (correctedY + elementSize.height > documentSize.height) {
    const recalculatedY = documentSize.height - elementSize.height - 16;

    corrections.y = (correctedY - recalculatedY) * -1;
    correctedY = recalculatedY;
  }

  return { x: correctedX, y: correctedY, offsetChanges: corrections };
};
