import { useMutation, useQueryClient } from 'react-query';

import {
  QuestionnaireSubmissionFinishRequest,
  questionnaireSubmissionService
} from '@Services/QuestionnaireSubmission';
import { todoService } from '@Services/Todo';
import { userService } from '@Services/User';

export const useQuestionnaireSubmissionFinishPhase = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (config: QuestionnaireSubmissionFinishRequest) => {
      return questionnaireSubmissionService.finishPhase(config);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaireSubmissionService.getEndpoint()]);
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
        queryClient.invalidateQueries([todoService.getEndpoint()]);
      }
    }
  );
};
