import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { OnboardingUpdatePhoneForm, OnboardingVerifyPhoneForm } from '@Components/form';
import { Anchor, Box, SliderFullPage, Text, useSliderFullPageItemContext } from '@Components/ui';
import { SMS_COUNTDOWN_SECONDS } from '@Constants/configs';
import { useApiError, useCountdown } from '@Hooks/common';
import { useOnboardingUpdatePhone } from '@Hooks/onboarding';

import { OnboardingVerifyPhoneSlideProps } from './OnboardingVerifyPhoneSlide.props';

export const OnboardingVerifyPhoneSlide = ({
  onUpdate,
  onSuccess,
  phoneNumber,
  userId
}: OnboardingVerifyPhoneSlideProps) => {
  const { t } = useTranslation();
  const countdown = useCountdown(SMS_COUNTDOWN_SECONDS);
  const { handleError } = useApiError();
  const { slideNext } = useSliderFullPageItemContext();
  const onboardingUpdatePhoneMutation = useOnboardingUpdatePhone();

  const handleSuccess = useCallback(() => {
    if (onSuccess) {
      onSuccess();
    }

    slideNext();
  }, [onSuccess, slideNext]);

  const handleResend = useCallback(async () => {
    countdown.start();

    try {
      await onboardingUpdatePhoneMutation.mutateAsync({
        userId: userId,
        phoneNumber: phoneNumber
      });

      toast.info(t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_PHONE.FORM.RESEND.SUCCESS'));
    } catch (e) {
      handleError(e);
    }
  }, [countdown, onboardingUpdatePhoneMutation, userId, phoneNumber, t, handleError]);

  return (
    <SliderFullPage.Item showPagination>
      <SliderFullPage.Head
        title={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_PHONE.HEADER.TITLE')}
        showPagination
      />

      <SliderFullPage.Block title={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_PHONE.FORM.TITLE')}>
        <OnboardingUpdatePhoneForm onSuccess={onUpdate} userId={userId} phoneNumber={phoneNumber} />

        <Box mt={-2} mb={3}>
          <Text.Normal>
            {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_PHONE.FORM.RESEND.LABEL')}

            <Anchor
              onClick={handleResend}
              isDisabled={countdown.count !== 0}
              data-testid="resendPhone"
            >
              {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_PHONE.FORM.RESEND.LINK')}

              {countdown.count > 0 &&
                t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_PHONE.FORM.RESEND.LINK_COUNT', {
                  count: countdown.count
                })}
            </Anchor>

            {'.'}
          </Text.Normal>
        </Box>

        <OnboardingVerifyPhoneForm
          onSuccess={handleSuccess}
          userId={userId}
          phoneNumber={phoneNumber}
        />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
