import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ListCardActivity } from '@Components/list';
import { Box, Loader, Text } from '@Components/ui';
import { useActivityList } from '@Hooks/activity';
import { AppRoutes } from '@Navigation/Routes';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import { DashboardActivitiesProps } from './DashboardActivities.props';

export const DashboardActivities = ({ className }: DashboardActivitiesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: activities,
    isSuccess: isActivitiesSuccess,
    isLoading: isActivitiesLoading,
    isError: isActivitiesError
  } = useActivityList({
    perPage: 2,
    random: true,
    excludeCompleted: true
  });

  const handleNavigate = useCallback(() => {
    navigate(AppRoutes.getActivities().to);
  }, [navigate]);

  const footerLink = {
    label: t('DOMAIN.DASHBOARD.BLOCKS.ACTIVITIES.READ_MORE'),
    onClick: handleNavigate
  };

  return (
    <>
      <DashboardBlock
        widgetKey="ACTIVITIES"
        footerLink={footerLink}
        className={className}
        data-tourdotid="ACTIVITIES"
      >
        {activities?.pages[0].results && isActivitiesSuccess && (
          <ListCardActivity activities={activities.pages[0].results} />
        )}

        {isActivitiesError && (
          <Text.Error>{t('DOMAIN.DASHBOARD.BLOCKS.ACTIVITIES.ERROR')}</Text.Error>
        )}

        {isActivitiesLoading && (
          <Box my={8}>
            <Loader>{t('DOMAIN.DASHBOARD.BLOCKS.ACTIVITIES.LOADING')}</Loader>
          </Box>
        )}
      </DashboardBlock>
    </>
  );
};
