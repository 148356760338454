export enum Profession {
  ECONOMICS = 'ECONOMICS',
  HEALTHCARE = 'HEALTHCARE',
  TECHNICS = 'TECHNICS',
  COMMERCIAL = 'COMMERCIAL',
  SERVICE = 'SERVICE',
  EDUCATION = 'EDUCATION',
  TRANSPORT = 'TRANSPORT',
  IT = 'IT',
  MANAGEMENT = 'MANAGEMENT',
  PUBLIC_ADMINISTRATION = 'PUBLIC_ADMINISTRATION',
  CREATIVE = 'CREATIVE',
  AGRICULTURE = 'AGRICULTURE',
  OTHER = 'OTHER',
  PRIVATE = 'PRIVATE'
}
