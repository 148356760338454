import cn from 'classnames';

import { Tag, Text } from '@Components/ui';

import styles from './TagText.module.scss';
import { TagTextProps } from './TagText.props';

export const TagText = ({ label, className, tagProps, isReversed, ...props }: TagTextProps) => {
  const classNames = cn(styles.TagText, className, {
    [styles.isReversed]: isReversed
  });

  return (
    <div className={classNames} {...props}>
      <Tag {...tagProps} className={styles.Tag} />

      <Text.Small className={styles.Label}>{label}</Text.Small>
    </div>
  );
};
