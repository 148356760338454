import { Anchor } from '@Components/ui';

import { formatInboxMessage } from '../../utils';
import { MessageItemTextProps } from './MessageItemText.props';

export const MessageItemText = ({ message, className }: MessageItemTextProps) => {
  return (
    <>
      {formatInboxMessage(message).map((_message, idx) => {
        if (typeof _message === 'string') {
          return _message;
        }

        return <Anchor key={`${idx}${_message.to}`} {..._message} className={className} />;
      })}
    </>
  );
};
