import { createContext, useContext } from 'react';

import { AppError } from '@Constants/enums';

export interface TabGroupContextType {
  current: number;
  total: number;
}

export const TabGroupContext = createContext<TabGroupContextType | null>(null);

export const useTabGroupContext = () => {
  const contextValue = useContext(TabGroupContext);

  if (!contextValue) {
    throw new Error(AppError.NO_CONTEXT_SET);
  }

  return contextValue;
};
