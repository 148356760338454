import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Block as WrapBlock, Text } from '@Components/ui';

import styles from './QuoteBlock.module.scss';
import { QuoteBlockProps } from './QuoteBlock.props';

export const QuoteBlock = ({
  author,
  children,
  isInverted = false,
  isRound = false,
  className
}: QuoteBlockProps) => {
  const { t } = useTranslation();

  return (
    <WrapBlock
      className={cn(styles.QuoteBlock, { [styles.isInverted]: isInverted })}
      innerClassName={className}
      isElevated={isRound}
    >
      <div className={styles.Content}>{children}</div>

      <div className={styles.Footer}>
        <Text.Normal className={styles.Author}>
          {t('COMPONENTS.QUOTE.QUOTE_BY') + ' '}

          {author}
        </Text.Normal>
      </div>
    </WrapBlock>
  );
};
