import { useTranslation } from 'react-i18next';

import { Faq } from '@Components/container/Faq/Faq';
import { Block, Loader, Text } from '@Components/ui';
import { useFaqOne } from '@Hooks/faq';

import { PREPR_SUPPORT_FAQ_SUPPORT_ID } from '../constants';
import styles from './AppSupport.module.scss';

export const AppSupport = () => {
  const { t } = useTranslation();
  const faq = useFaqOne(PREPR_SUPPORT_FAQ_SUPPORT_ID);

  return (
    <Block className={styles.FaqSupport}>
      {faq.data && <Faq isSearchable groups={faq.data.categories} hasArchive />}

      {faq.isLoading && <Loader />}

      {faq.isError && <Text.Error>{t('DOMAIN.FAQ.ERROR.LABEL')}</Text.Error>}
    </Block>
  );
};
