import axios from 'axios';

import { AppError } from '@Constants/enums';
import { BaseService } from '@Services/common/BaseService/BaseService';
import { getPlatform } from '@Utils/PlatformUtils';
import { getAccessTokens, removeAccessTokens, setAccessTokens } from '@Utils/StorageUtils';

import {
  ForgotPasswordRequest,
  LoginRequest,
  LoginResponse,
  RefreshTokenResponse,
  ResendTwoFactorRequest,
  ResetPasswordRequest,
  ValidateTwoFactorRequest,
  ValidateTwoFactorResponse
} from './AuthService.props';

export class AuthService extends BaseService {
  login = async (params: LoginRequest) => {
    const response = await this.post<LoginResponse>(`/login`, params);

    return response.data;
  };

  forgotPassword = async (params: ForgotPasswordRequest) => {
    await this.post<void>(`/forgot`, params);
  };

  resetPassword = async (params: ResetPasswordRequest) => {
    await this.post<void>(`/reset`, params);
  };

  validateTwoFactor = async (params: ValidateTwoFactorRequest) => {
    const response = await this.post<ValidateTwoFactorResponse>(`/token`, params);

    return response.data;
  };

  resendTwoFactor = async (params: ResendTwoFactorRequest) => {
    await this.post<void>(`/resend`, params);
  };

  logout = async () => {
    window._paq.push(['deleteCustomDimension', 1]);
    window._paq.push(['deleteCustomDimension', 2]);
    window._paq.push(['deleteCustomDimension', 3]);

    const tokens = getAccessTokens();

    if (!tokens) {
      return;
    }

    removeAccessTokens();

    const config = {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`
      }
    };

    await this.post<void>(`/logout`, null, config);
  };

  refreshTokens = async () => {
    const tokens = getAccessTokens();

    if (!tokens) {
      throw new Error(AppError.NO_ACCESS_TOKENS_FOUND);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`
      }
    };

    const params = {
      refreshToken: tokens.refreshToken
    };

    const response = await this.post<RefreshTokenResponse>(`/refresh`, params, config);

    setAccessTokens(response.data);
  };
}

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'X-Brand': getPlatform()
  }
});

export const authService = new AuthService(authInstance, '/auth');
