import { useTranslation } from 'react-i18next';

import { ButtonHeader } from '@Components/layout';

import { ButtonPauseProps } from './ButtonPause.props';

export const ButtonPause = ({ to, onClick }: ButtonPauseProps) => {
  const { t } = useTranslation();

  return (
    <ButtonHeader to={to} color="dark" onClick={onClick}>
      {t('COMMON.GLOBALS.PAUSE')}
    </ButtonHeader>
  );
};
