import { useTranslation } from 'react-i18next';

import { Anchor, Box, SliderFullPage, Text } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

export const ForgotPasswordSuccessSlide = () => {
  const { t } = useTranslation();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        title={t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.SUCCESS.HEADER.TITLE')}
      />

      <SliderFullPage.Block title={t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.SUCCESS.TITLE')}>
        <Text.Normal>{t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.SUCCESS.DESCRIPTION')}</Text.Normal>
      </SliderFullPage.Block>

      <Box ml={4}>
        <Anchor to={AppRoutes.getLogin().to}>
          {t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.SUCCESS.GO_BACK')}
        </Anchor>
      </Box>
    </SliderFullPage.Item>
  );
};
