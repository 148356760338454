import cn from 'classnames';
import { LottieOptions, useLottie } from 'lottie-react';

import quoteAnimation from '@Assets/lotties/quote.json';
import { Text } from '@Components/ui';
import { usePlayInView } from '@Hooks/common';

import { QuoteBlock } from './internal/components';
import styles from './Quote.module.scss';
import { QuoteProps } from './Quote.props';

export const Quote = ({
  quote,
  author,
  block = false,
  isInverted = false,
  isRound = false,
  className
}: QuoteProps) => {
  const lottieOptions: LottieOptions = {
    animationData: quoteAnimation,
    loop: false,
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: 'none'
    }
  };

  const { View, play } = useLottie(lottieOptions);
  const [inViewRef] = usePlayInView(play);

  const quoteText = (
    <div className={cn(styles.Quote, { [styles.isInverted]: isInverted }, className)}>
      <Text.Handwritten className={styles.QuoteText}>{quote}</Text.Handwritten>

      <div ref={inViewRef} className={styles.LottieWrapper}>
        {View}
      </div>
    </div>
  );

  if (block) {
    return (
      <QuoteBlock className={className} author={author} isInverted={isInverted} isRound={isRound}>
        {quoteText}
      </QuoteBlock>
    );
  }

  return quoteText;
};
