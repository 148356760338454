import { IBaseModel, ListRequest } from '@Constants/interfaces';

export enum InboxMessageLinkType {
  ACTIVITY = 'activity',
  CHALLENGE = 'challenge'
}

export interface IInboxItem extends IBaseModel, Record<string, unknown> {
  content: string;
  subject: string;
  readAt: string | null;
  createdAt: string;
}

export interface InboxReadMessageRequest {
  id: string;
}

export type InboxGetListRequest = ListRequest;

export type InboxReadMessageResponse = IInboxItem;
