import { MenuRouteType } from '@Constants/interfaces';
import { useUserMe } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';

export const useMenuItemsSidebar = (): MenuRouteType[] => {
  const { data: user } = useUserMe();

  return [
    {
      ...AppRoutes.getDashboard(),
      icon: 'chart-line',
      tourdot: 'MENU_MY_BOARD'
    },
    {
      ...AppRoutes.getTodo(),
      icon: 'thumbtack',
      count: user?.openTodoCount
    },
    {
      ...AppRoutes.getInsight(),
      icon: 'route',
      subItems: [
        AppRoutes.getInsightChallenges(),
        AppRoutes.getInsightProgress(),
        { ...AppRoutes.getInsightReport(), tourdot: 'MENU_INSIGHT_REPORT' }
      ]
    },
    {
      ...AppRoutes.getActivities(),
      icon: 'book'
    },
    {
      ...AppRoutes.getRewards(),
      icon: 'stars',
      tourdot: 'MENU_REWARD'
    },
    {
      ...AppRoutes.getFaq(),
      icon: 'question'
    }
  ];
};
