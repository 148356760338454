import { useMutation, useQueryClient } from 'react-query';

import { userService, UserUpdatePhoneRequest } from '@Services/User';

export const useUserUpdatePhone = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (config: UserUpdatePhoneRequest) => {
      return userService.updatePhone(config);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
