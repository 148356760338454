import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { challengeService, ChallengeStartRequest } from '@Services/Challenge';
import { userService } from '@Services/User';
import { trackMatomoChallengeStart } from '@Utils/MatomoUtils';

export const useChallengeStart = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: ChallengeStartRequest) => {
      return challengeService.start(config);
    },
    {
      onSuccess: (_, { title, theme }) => {
        trackMatomoChallengeStart(trackEvent, title, theme);

        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
