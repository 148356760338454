import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@Utils/PlatformUtils';
import { spacing } from '@Utils/ThemeUtils';

export const styles = StyleSheet.create({
  ThemeBlock: {
    paddingBottom: spacing(3),
    borderBottom: `1px solid ${getTheme().color.gray.light}`
  },
  Domain: {
    fontWeight: 'medium',
    marginBottom: spacing(0.5)
  },
  Theme: {
    marginBottom: spacing(1.5)
  },
  Intro: {
    marginBottom: spacing(2)
  },
  Result: {
    marginBottom: spacing(1.5)
  }
});
