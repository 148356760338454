import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PreprRenderer, Sideview } from '@Components/layout';
import { SelectActivityBlock, SelectActivityBlockReturnType } from '@Components/list';
import {
  Box,
  Button,
  CallToAction,
  IconText,
  Image,
  Loader,
  SpacingGroup,
  Tag,
  Text
} from '@Components/ui';
import { MAX_PENDING_CHALLENGES, REQUIRED_ACTIVITY_AMOUNT } from '@Constants/configs';
import { useChallengeOne, useChallengeStart } from '@Hooks/challenge';
import { TargetDirection, useApiError, useBoolean, usePlatform } from '@Hooks/common';
import { useUserChallengeList } from '@Hooks/userChallenge';
import { AppRoutes } from '@Navigation/Routes';
import { markupToHtml } from '@Utils/StringUtils';

import styles from './ChallengeAddSideview.module.scss';
import { ChallengeAddSideviewProps } from './ChallengeAddSideview.props';
import { mapSelectedActivitiesToStartType, validateSelectedActivities } from './internal/utils';

export const ChallengeAddSideview = ({ slug, isOpen, onClose }: ChallengeAddSideviewProps) => {
  const [selectedActivities, setSelectedActivities] = useState<SelectActivityBlockReturnType[]>([]);
  const { value: hasError, setValue: setHasError } = useBoolean(false);
  const { data: challenges } = useUserChallengeList();
  const { color } = usePlatform();
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const navigate = useNavigate();
  const challengeStartMutation = useChallengeStart();

  const {
    data: challenge,
    isLoading: isChallengeLoading,
    isError: isChallengeError
  } = useChallengeOne({ id: slug });

  const hasAvailableChallengeSpot = (challenges?.total ?? 0) < MAX_PENDING_CHALLENGES;

  const handleSubmit = useCallback(async () => {
    const isValid = validateSelectedActivities(selectedActivities);

    setHasError(!isValid);

    if (isValid && challenge) {
      try {
        await challengeStartMutation.mutateAsync({
          id: challenge.id,
          title: challenge.title,
          theme: challenge.theme.title,
          activities: mapSelectedActivitiesToStartType(selectedActivities)
        });

        navigate(AppRoutes.getInsightChallengesStart(challenge.slug).to);
      } catch (e) {
        handleError(e, 'CHALLENGE_START', true);
      }
    }
  }, [setHasError, selectedActivities, challenge, challengeStartMutation, handleError, navigate]);

  return (
    <Sideview
      title={challenge?.title || ''}
      isVisible={isOpen}
      onClose={onClose}
      className={styles.ChallengeAddSideview}
    >
      {isChallengeLoading && (
        <Box mt={3} mb={3}>
          <Loader>
            {t('COMMON.GLOBALS.LOADING_MODEL', {
              name: t('DOMAIN.CHALLENGE.SINGLE')
            })}
          </Loader>
        </Box>
      )}

      {isChallengeError && <Text.Error>{t('DOMAIN.CHALLENGE.ERROR')}</Text.Error>}

      {!!challenge && (
        <>
          <Box mb={3}>
            <SpacingGroup>
              {challenge.popular && (
                <Tag
                  icon="users"
                  label={t('COMMON.GLOBALS.POPULAR')}
                  color={color.font.default}
                  size="lg"
                />
              )}

              <Tag label={t('DOMAIN.CHALLENGE.SINGLE')} color={color.font.default} size="lg" />
            </SpacingGroup>
          </Box>

          <Box mb={3}>
            <Image src={challenge.thumbnail} className={styles.Image} />
          </Box>

          <Box mb={challenge.popular ? 3 : 7}>
            <PreprRenderer content={challenge.content} />
          </Box>

          {challenge.popular && (
            <Box mb={7}>
              <IconText icon="users" color={color.primary.default}>
                <Text.Large dangerousHtml={markupToHtml(t('DOMAIN.CHALLENGE.POPULAR'))} />
              </IconText>
            </Box>
          )}

          {!hasAvailableChallengeSpot && (
            <Box mb={2}>
              <Text.Error>{t('DOMAIN.CHALLENGE.ADD.CONFIGURE.FULL')}</Text.Error>
            </Box>
          )}

          {hasAvailableChallengeSpot && (
            <Box mb={3} ml="auto" className={styles.CallToAction}>
              <CallToAction
                direction={TargetDirection.DOWN}
                label={t('DOMAIN.CHALLENGE.ADD.CONFIGURE.SELECT_ACTIVITIES')}
                isVisible
                isReversed
              />
            </Box>
          )}

          <Box mb={6}>
            <SelectActivityBlock
              activities={challenge.activities}
              hasError={hasError}
              isDisabled={!hasAvailableChallengeSpot}
              onChange={setSelectedActivities}
            />
          </Box>

          <Button
            icon="arrow-right"
            isDisabled={selectedActivities?.length !== REQUIRED_ACTIVITY_AMOUNT || hasError}
            onClick={handleSubmit}
            className={styles.Submit}
            isLoading={challengeStartMutation.isLoading}
            data-testid="ChallengeAddSideviewSubmit"
          >
            {t('DOMAIN.CHALLENGE.ADD.CONFIGURE.START')}
          </Button>
        </>
      )}
    </Sideview>
  );
};
