import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@Components/ui';
import { MAX_FIRSTNAME_LENGTH, MAX_SURNAME_LENGTH } from '@Constants/configs';
import { useApiError } from '@Hooks/common';
import { useUserUpdateMe } from '@Hooks/user';
import Yup from '@Utils/YupUtils';

import { ChangeNameFormProps, ChangeNameFormValues } from './ChangeNameForm.props';

export const ChangeNameForm = ({ onSuccess, children, ...props }: ChangeNameFormProps) => {
  const userUpdateMeMutation = useUserUpdateMe();
  const { handleError } = useApiError();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: ChangeNameFormValues) => {
      try {
        await userUpdateMeMutation.mutateAsync(values);

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, userUpdateMeMutation, onSuccess]
  );

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(MAX_FIRSTNAME_LENGTH).required(),
    lastName: Yup.string().max(MAX_SURNAME_LENGTH).required()
  });

  return (
    <Form.Form<ChangeNameFormValues>
      {...props}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form.Input.Text
        label={t('DOMAIN.PROFILE.CHANGE_NAME.MODAL.FORM.FIELDS.FIRST_NAME.LABEL')}
        name="firstName"
        data-testid="firstNameInput"
      />

      <Form.Input.Text
        label={t('DOMAIN.PROFILE.CHANGE_NAME.MODAL.FORM.FIELDS.LAST_NAME.LABEL')}
        name="lastName"
        data-testid="lastNameInput"
      />

      {children}
    </Form.Form>
  );
};
