import { useCallback, useEffect, useState } from 'react';

export const useDynamicHeight = (ref: HTMLElement | null) => {
  const [height, setHeight] = useState(0);

  const updateHeight = useCallback(() => {
    if (ref) {
      setHeight(ref.scrollHeight);
    }
  }, [ref]);

  useEffect(() => {
    if (ref) {
      updateHeight();

      const resizeObserver = new ResizeObserver(updateHeight);

      resizeObserver.observe(ref);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [ref, updateHeight]);

  return height;
};
