import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Text } from '@Components/ui';
import { useBoolean, useTimeouts } from '@Hooks/common';

import { SLOW_DOWN_AMOUNT, SLOW_DOWN_STEP, TOP_SPEED } from '../constants';
import styles from './Digit.module.scss';
import { DigitProps } from './Digit.props';

export const Digit = ({ finalNumber, steps = 5 }: DigitProps) => {
  const [digit, setDigit] = useState(0);
  const [currentTimeout, setCurrentTimeout] = useState(1000);

  const { value: shouldSwap, toggle: toggleSwap } = useBoolean(false);
  const { value: isFinal, setTrue: setIsFinal } = useBoolean(false);
  const { addTimeout } = useTimeouts();

  /**
   * Determines what the shown digit should be based on
   * our current step.
   *
   * it does this by taking the finalNumber and adding the currentStep.
   * So for example: step 3 and finalNumber 2 would be 2 + 3 = 5. It will then cycle: 5 - 4 - 3 - 2 (so three steps)
   *
   * However, this way we can get a double-digit number, which we don't want.
   * So, to fix this, if we get a number above 9, for example, step 5 and finalNumber 9: 9 + 5 = 14.
   * We then need to calculate the digit in the next cycle. We do this by taking 9 away from the result. So 14 - 9 = 5.
   * We then start at -1 and add from there. We start at -1, because 0 also counts as a step. So -1 + 1 = 0.
   * In this example this will be -1 + 5 = 4.
   * The resulting cycle will then look like this: 4 - 3 - 2 - 1 - 0 - 9, which is 5 steps.
   *
   * This allows us to cycle through the digits multiple times.
   *
   * However, we can have more than 10 steps. This, however, will break the calculation.
   * As step 22 and finalNumber 5 will result in: 5 + 22 = 27. we will then return 27 - 9 = 18 as digit, which is incorrect.
   * We remove these extra steps by doing a modulo calculation. This ensures that we get a remainder that we can use to
   * calculate the correct digit.
   * As 22 % 10 = 2. If we then use 2 as currentStep with finalNumber still 5, we get: 5 + 2 = 7. The full cycles will then look like:
   * 7 - 6 - 5 - 4 - 3 - 2 - 1 - 0 - 9 - 8 - 7 - 6 - 5 - 4 - 3 - 2 - 1 - 0 - 9 - 8 - 7 - 6 - 5 (22 steps)
   *
   * @param {number} currentStep
   */
  const getNewDigit = useCallback(
    (currentStep: number) => {
      const newDigit = finalNumber + (currentStep % 10);

      if (newDigit > 9) {
        return -1 + (newDigit - 9);
      }

      return newDigit;
    },
    [finalNumber]
  );

  /**
   * We want to slow down at a certain point because otherwise the stop
   * would be far too sudden.
   * So when we are at our slowdown-step, we start slowing down and
   * for every consecutive step, we slow down more until we stop.
   */
  const getTimeout = useCallback((currentStep: number) => {
    if (currentStep < SLOW_DOWN_STEP) {
      return (5 - currentStep) * SLOW_DOWN_AMOUNT;
    }

    return TOP_SPEED;
  }, []);

  /**
   * iterates until final number has been reached.
   * Uses getTimeout() to determine timeout for next iteration.
   * Calls itself when timeout is reached.
   */
  const countUp = useCallback(() => {
    const loop = (currentStep: number) => {
      requestAnimationFrame(() => {
        const newDigit = getNewDigit(currentStep);
        const newTimeout = getTimeout(currentStep);

        setDigit(newDigit);
        setCurrentTimeout(newTimeout);
        toggleSwap();

        if (currentStep > 0) {
          addTimeout(() => {
            loop(currentStep - 1);
          }, newTimeout);
        } else {
          setIsFinal();
        }
      });
    };

    loop(steps);
  }, [steps, getNewDigit, addTimeout, getTimeout, toggleSwap, setIsFinal]);

  useEffect(() => {
    countUp();
  }, [countUp]);

  const getDigitClasses = useMemo(() => {
    return cn(styles.Digit, {
      [styles.isFinal]: isFinal
    });
  }, [isFinal]);

  const getDigit = useMemo(() => {
    const BLUR_SAMPLES = 20;

    return Array(BLUR_SAMPLES)
      .fill(null)
      .map((_, idx) => {
        return (
          <Text.H1
            key={idx}
            className={getDigitClasses}
            style={{
              animationDuration: `${currentTimeout}ms`,
              animationDelay: `${idx * 3}ms`
            }}
          >
            {digit.toString()}
          </Text.H1>
        );
      });
  }, [getDigitClasses, currentTimeout, digit]);

  return (
    <div key={shouldSwap ? 'key1' : 'key2'} className={styles.DigitWrapper}>
      {getDigit}
    </div>
  );
};
