import { isAfter } from 'date-fns';

import { StorageKey } from '@Constants/enums';
import { ValidateTwoFactorResponse } from '@Services/AuthService';

export const setItem = <T>(key: string, data: T) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getItem = <T>(key: string): T | undefined => {
  const item = localStorage.getItem(key);

  if (!item) {
    return;
  }

  return JSON.parse(item);
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

export const setAccessTokens = (accessTokens: ValidateTwoFactorResponse) => {
  setItem(StorageKey.ACCESS_TOKENS, accessTokens);
};

export const getAccessTokens = () => {
  return getItem<ValidateTwoFactorResponse>(StorageKey.ACCESS_TOKENS);
};

export const getIsRefreshStillValid = () => {
  const accessTokens = getAccessTokens();

  if (!accessTokens) {
    return false;
  }

  const refreshExpirationDate = new Date(accessTokens.refreshExpires);
  const today = new Date();

  return isAfter(refreshExpirationDate, today);
};

export const removeAccessTokens = () => {
  removeItem(StorageKey.ACCESS_TOKENS);
};
