import { Result } from '@Constants/interfaces';
import { IUserTheme } from '@Services/UserTheme';

type AggregatedThemeResults = {
  [key in Result]: IUserTheme[];
} & {
  recommended: IUserTheme[];
};

export const aggregateThemeResults = (themes: IUserTheme[]) => {
  const initialValues: AggregatedThemeResults = {
    [Result.RED]: [],
    [Result.ORANGE]: [],
    [Result.YELLOW]: [],
    [Result.GREEN]: [],
    [Result.NONE]: [],
    recommended: []
  };

  return themes.reduce<AggregatedThemeResults>((result, item) => {
    const themeResult = item.themeResult ?? Result.NONE;
    const resultItems = result[themeResult] || [];
    const recommendedItems = result.recommended || [];

    return {
      ...result,
      [themeResult]: [...resultItems, item],
      recommended: item.recommended ? [...recommendedItems, item] : recommendedItems
    };
  }, initialValues);
};
