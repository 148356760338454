import { SelfTestResults } from '@Components/container';
import { Container } from '@Components/layout';

export const SelfTestResultsScreen = () => {
  return (
    <Container cols={10}>
      <SelfTestResults />
    </Container>
  );
};
