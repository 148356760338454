import { useTranslation } from 'react-i18next';

import { ButtonHeader } from '@Components/layout';

import { ButtonCancelProps } from './ButtonCancel.props';

export const ButtonCancel = ({ to, onClick }: ButtonCancelProps) => {
  const { t } = useTranslation();

  return (
    <ButtonHeader to={to} color="dark" onClick={onClick}>
      {t('COMMON.GLOBALS.CANCEL')}
    </ButtonHeader>
  );
};
