import cn from 'classnames';
import { Children } from 'react';

import { Icon } from '@Components/ui';

import styles from './Unordered.module.scss';
import { UnorderedListProps } from './Unordered.props';

export const Unordered = ({ children, className }: UnorderedListProps) => {
  return (
    <ul className={cn(styles.Unordered, className)}>
      {Children.toArray(children).map((child, idx) => {
        return (
          <li key={idx} className={styles.ListItem}>
            <Icon icon={['fas', 'circle']} className={styles.Icon} />

            <div className={styles.Children}>{child}</div>
          </li>
        );
      })}
    </ul>
  );
};
