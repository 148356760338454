import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { faqService } from '@Services/Faq';
import { i18n } from '@Utils/TranslationUtils';

export const useFaqOne = (id: string) => {
  return useQuery(
    [faqService.getEndpoint(), id],
    () => {
      return faqService.one({ id });
    },
    {
      onError: () => {
        toast.error(
          i18n.t('COMMON.GLOBALS.ERRORS.COULD_NOT_FETCH.DESCRIPTION', {
            name: i18n.t('DOMAIN.ACTIVITY.SINGLE')
          })
        );
      }
    }
  );
};
