import cn from 'classnames';
import { isFuture } from 'date-fns';
import { useRef } from 'react';

import { Medal, MedalState, Text } from '@Components/ui';
import { TimelineItemType } from '@Services/Insight';
import { getMilestoneIcon } from '@Utils/MilestoneUtils';

import { useGroupTitle } from '../../hooks';
import { TimelineActivity, TimelineChallenge } from '..';
import { TimelineReward } from '../TimelineReward/TimelineReward';
import styles from './TimelineList.module.scss';
import { TimelineListProps } from './TimelineList.props';

export const TimelineList = ({ items, day, isCompleted, currentMiddle }: TimelineListProps) => {
  const refs = useRef<Array<any | null>>([]);

  const isFutureDate = isFuture(day);
  const title = useGroupTitle(items[0].type, isFutureDate, isCompleted);

  const isRefElementActive = (idx: number) => {
    const refElement = refs.current[idx];

    if (refElement) {
      const locationY = refElement.offsetTop;

      return locationY < currentMiddle;
    }

    return false;
  };

  const isFirstActive = () => {
    return isRefElementActive(0);
  };

  return (
    <div className={styles.TimelineList}>
      <Text.Normal
        dangerousHtml={title}
        className={cn(styles.Title, { [styles.isActive]: isFirstActive() })}
      />

      {items.map((item, idx) => {
        const isActiveItem = isRefElementActive(idx);

        return (
          <div
            key={item.preprId}
            ref={(el) => (refs.current[idx] = el)}
            className={cn(styles.ListItem, { [styles.isActive]: isActiveItem })}
          >
            {item.type === TimelineItemType.CHALLENGE && <TimelineChallenge challenge={item} />}

            {(item.type === TimelineItemType.ACTIVITY ||
              item.type === TimelineItemType.CUSTOM_ACTIVITY) && (
              <TimelineActivity activity={item} isActive={isActiveItem} />
            )}

            {item.type === TimelineItemType.REWARD && (
              <TimelineReward reward={item} className={styles.Reward} />
            )}

            {item.type === TimelineItemType.MILESTONE && (
              <Medal
                title={item.title}
                state={MedalState.CLAIMED}
                icon={getMilestoneIcon(item.key ?? '')}
                className={styles.Milestone}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
