import cn from 'classnames';
import { ChangeEvent, forwardRef, useCallback } from 'react';

import { Icon, Text } from '@Components/ui';

import styles from './InputCheckbox.module.scss';
import { InputCheckboxOption, InputCheckboxProps } from './InputCheckbox.props';

export const InputCheckbox = forwardRef(
  (
    {
      options,
      onBlur,
      onChange,
      onClick,
      name,
      value,
      className,
      isError,
      isValid,
      isDisabled,
      isSingle,
      ...props
    }: InputCheckboxProps,
    ref?: any
  ) => {
    const classNames = cn(styles.InputCheckbox, className, {
      [styles.isError]: isError,
      [styles.isValid]: isValid
    });

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        let newValues = [...(value ?? [])];
        const updatedValue = e.target.value;

        if (isSingle) {
          if (!newValues.includes(updatedValue)) {
            newValues = [updatedValue];
          } else {
            newValues = [];
          }
        } else {
          if (!newValues.includes(updatedValue)) {
            newValues.push(updatedValue);
          } else {
            newValues.splice(newValues.indexOf(updatedValue), 1);
          }
        }

        if (onChange) {
          onChange(newValues);
        }
      },
      [value, onChange, isSingle]
    );

    const handleClick = useCallback(
      (option: InputCheckboxOption, checked?: boolean) => () => {
        if (onClick) {
          onClick(option, checked);
        }
      },
      [onClick]
    );

    return (
      <div className={classNames} ref={ref}>
        {options.map((option, idx) => {
          const isOptionDisabled = isDisabled || option.disabled;

          return (
            <div key={option.value} className={styles.Item}>
              <label
                className={cn(styles.Input, {
                  [styles.isChecked]: value?.includes(option.value),
                  [styles.isDisabled]: isOptionDisabled
                })}
              >
                <input
                  id={`InputCheckbox-${name}-${idx}`}
                  className={styles.Checkbox}
                  checked={!option.disabled && value?.includes(option.value)}
                  type="checkbox"
                  disabled={isDisabled || option.disabled}
                  {...props}
                  onBlur={onBlur}
                  onChange={handleChange}
                  value={option.value}
                  onClick={handleClick(option, !value?.includes(option.value))}
                />

                <div className={styles.Icon}>
                  <Icon icon={['fas', 'check']} />
                </div>

                <Text.Large className={styles.Label}>{option.label}</Text.Large>
              </label>
            </div>
          );
        })}
      </div>
    );
  }
);
