import { IconName } from '@fortawesome/fontawesome-common-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import userActivityThumbnail from '@Assets/images/custom-activity-thumbnail.jpg';
import { ThumbnailTextRow } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';
import { isUserActivity } from '@Utils/ActivityUtils';
import { getImageSrc } from '@Utils/PreprUtils';

import styles from './TodoItem.module.scss';
import { TodoItemProps } from './TodoItem.props';

export const TodoItem = ({ hasImage, todo, className }: TodoItemProps) => {
  const { t } = useTranslation();

  if (isUserActivity(todo)) {
    return (
      <ThumbnailTextRow
        image={userActivityThumbnail}
        className={cn(styles.TodoItem, className)}
        title={t('DOMAIN.USER_ACTIVITY.TITLE', { title: todo.title })}
        hasPlaceholder={hasImage}
        to={AppRoutes.getTodoUserActivity(todo.id).to}
        isCompleted={todo.completed}
      />
    );
  }

  return (
    <ThumbnailTextRow
      className={cn(styles.TodoItem, className)}
      title={todo.title}
      image={getImageSrc(todo.thumbnail)}
      hasPlaceholder={hasImage}
      imageIcon={todo.type?.[0].icon as IconName}
      to={AppRoutes.getActivity(todo.slug).to}
      isCompleted={todo.completed}
    />
  );
};
