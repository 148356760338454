import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MotivatorTooltip } from '@Components/container';
import { BoardBlock } from '@Components/ui';
import { CLOSING_DURATION } from '@Components/ui/BoardBlock/internal/constants';
import { usePreferenceList, usePreferenceSubmit } from '@Hooks/preference';
import { useUserTooltipGetAll } from '@Hooks/userTooltip';
import { PreferenceKey } from '@Services/Preference';
import { getPreference } from '@Utils/PreferenceUtils';
import { sleep } from '@Utils/TimeoutUtils';

import { DashboardBlockProps } from './DashboardBlock.props';

export const DashboardBlock = ({
  widgetKey,
  hasTitle = true,
  children,
  className,
  innerClassName,
  ...props
}: DashboardBlockProps) => {
  const { t } = useTranslation();
  const {
    data: preferences,
    isSuccess: isPreferencesSuccess,
    isFetched: isPreferencesFetched
  } = usePreferenceList();
  const preferenceSubmitMutation = usePreferenceSubmit();
  const [isOpen, setOpen] = useState(false);
  const { data: tooltips } = useUserTooltipGetAll();

  useEffect(() => {
    (async () => {
      if (isPreferencesFetched) {
        await sleep(CLOSING_DURATION);
        setOpen(true);
      }
    })();
  }, [isPreferencesFetched]);

  const activeTooltip = useMemo(() => {
    return tooltips?.find((tooltip) => tooltip.widget === widgetKey);
  }, [tooltips, widgetKey]);

  const hiddenWidgets = useMemo(() => {
    if (preferences) {
      return getPreference(preferences, PreferenceKey.WIDGETS);
    }
  }, [preferences]);

  const handleClose = useCallback(async () => {
    await preferenceSubmitMutation.mutateAsync({
      key: PreferenceKey.WIDGETS,
      values: [...(hiddenWidgets || []), widgetKey]
    });
  }, [preferenceSubmitMutation, widgetKey, hiddenWidgets]);

  if (isPreferencesSuccess && !hiddenWidgets?.includes(widgetKey)) {
    return (
      <BoardBlock
        animateOnOpen={isOpen}
        className={className}
        innerClassName={innerClassName}
        onClose={handleClose}
        motivator={activeTooltip && <MotivatorTooltip tooltip={activeTooltip} />}
        {...props}
        title={hasTitle ? t(`DOMAIN.DASHBOARD.BLOCKS.${widgetKey}.TITLE`) : undefined}
      >
        {children}
      </BoardBlock>
    );
  }

  return null;
};
