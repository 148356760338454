import cn from 'classnames';

import { ActivityItem } from '@Components/container';

import styles from './ListActivityItem.module.scss';
import { ListActivityItemProps } from './ListActivityItem.props';

export const ListActivityItem = ({ activities, className }: ListActivityItemProps) => {
  return (
    <div className={cn(styles.ListActivityItem, className)}>
      {activities.map((item) => {
        return (
          <ActivityItem hasImage key={item.id} activity={item} className={styles.ActivityItem} />
        );
      })}
    </div>
  );
};
