import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { LICENSE_CODE_LENGTH } from '@Constants/configs';
import { useApiError } from '@Hooks/common';
import { usePersistSearchParam } from '@Hooks/common';
import { useOnboardingVerify } from '@Hooks/onboarding';
import { onboardingService } from '@Services/Onboarding';
import Yup, { AsyncValidationCall, getAsyncValidation } from '@Utils/YupUtils';

import {
  OnboardingVerifyLicenseCodeFormProps,
  OnboardingVerifyLicenseCodeFormValues
} from './OnboardingVerifyLicenseCodeForm.props';

export const OnboardingVerifyLicenseCodeForm = ({
  onSuccess,
  isAlternative
}: OnboardingVerifyLicenseCodeFormProps) => {
  const { t } = useTranslation();
  const { error, handleError } = useApiError();
  const onboardingVerifyMutation = useOnboardingVerify();
  const { getPersistedSearchParam } = usePersistSearchParam('token');
  const urlToken = getPersistedSearchParam();

  const handleVerifyLicenseCode = useCallback(
    async (value: string): Promise<AsyncValidationCall> => {
      const response = await onboardingService.verify({ licenseCode: value });

      return response;
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: OnboardingVerifyLicenseCodeFormValues) => {
      try {
        await onboardingVerifyMutation.mutateAsync(values);

        onSuccess(values.licenseCode);
      } catch (e) {
        handleError(e);
      }
    },
    [onSuccess, handleError, onboardingVerifyMutation]
  );

  const validationSchema = Yup.object().shape({
    licenseCode: getAsyncValidation(handleVerifyLicenseCode, {
      schema: Yup.string().length(LICENSE_CODE_LENGTH).required()
    })
  });

  const initialValues = {
    licenseCode: urlToken || ''
  };

  return (
    <Form.Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form.Input.Code
        name="licenseCode"
        data-testid="tokenInput"
        label={
          urlToken
            ? undefined
            : t(
                `DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.FORM.FIELDS.LICENSE_CODE.${
                  isAlternative ? 'LABEL_ALTERNATIVE' : 'LABEL'
                }`
              )
        }
        description={
          urlToken
            ? undefined
            : t(
                'DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.FORM.FIELDS.LICENSE_CODE.DESCRIPTION'
              )
        }
        options={{
          length: LICENSE_CODE_LENGTH,
          isError: !!error
        }}
      />

      <Button type="submit" icon="arrow-right" data-testid="tokenSubmit">
        {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.FORM.SUBMIT')}
      </Button>
    </Form.Form>
  );
};
