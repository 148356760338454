import { Box, Text } from '@Components/ui';

import { PreprElementH4Props } from './PreprElementH4.props';

export const PreprElementH4 = ({ element }: PreprElementH4Props) => {
  return (
    <Box mb={1.5}>
      <Text.H4>{element.title}</Text.H4>
    </Box>
  );
};
