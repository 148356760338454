import { dePortalenService, ResourceService } from '@Services/common';

import {
  ChallengeInviteRequest,
  ChallengesRecommendedRequest,
  ChallengeStartRequest,
  IChallenge
} from './ChallengeService.props';

export class ChallengeService extends ResourceService<IChallenge> {
  getRecommended = async (params?: ChallengesRecommendedRequest) => {
    const response = await this.get<IChallenge[]>(
      `/recommended${params?.themeId ? `/${params.themeId}` : ''}`
    );

    return response.data;
  };

  start = async ({ id, ...params }: ChallengeStartRequest) => {
    await this.post<void>(`/${id}/start`, params);
  };

  invite = async ({ id, ...params }: ChallengeInviteRequest) => {
    await this.post<void>(`/${id}/invite`, params);
  };
}

export const challengeService = new ChallengeService(
  dePortalenService.getAxiosInstance(),
  '/challenges'
);
