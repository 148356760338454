import { throttle } from 'lodash';
import { useCallback, useEffect } from 'react';

import { useBoolean } from './useBoolean';

export const useScrolling = () => {
  const { value: isScrolling, setValue: setIsScrolling } = useBoolean(false);

  const handleScroll = useCallback(
    () =>
      throttle(() => {
        setIsScrolling(window.scrollY > 0);
      }, 100),
    [setIsScrolling]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll());

    return () => window.removeEventListener('scroll', handleScroll());
  }, [handleScroll]);

  return { isScrolling };
};
