import { CardActivity } from '@Components/container';
import { CardList } from '@Components/list';

import { ListCardActivityProps } from './ListCardActivity.props';

export const ListCardActivity = ({ activities = [], className }: ListCardActivityProps) => {
  return (
    <CardList className={className}>
      {activities.map((item) => {
        return <CardActivity key={item.id} activity={item} />;
      })}
    </CardList>
  );
};
