export enum StorageKey {
  ACCESS_TOKENS = 'REACT_APP_ACCESS_TOKENS',
  PLATFORM = 'REACT_APP_PLATFORM',
  REDIRECT = 'REACT_APP_REDIRECT',
  SEARCH_PARAM = 'REACT_APP_SEARCH_PARAM'
}

export enum Platform {
  ARBONED = 'ARBONED',
  MENSELY = 'MENSELY',
  KOM_VERDER = 'KOM_VERDER',
  HUMAN_CAPITAL_CARE = 'HUMAN_CAPITAL_CARE'
}

export enum AppError {
  NO_CONTEXT_SET = 'Set a context provider first.',
  NO_SMS_TOKEN_PROVIDED = 'Please provide an sms token.',
  NO_EMAIL_PROVIDED = 'Please provide an email adress.',
  NO_ACCESS_TOKENS_FOUND = 'No access tokens were found in localStorage.',
  INCORRECT_PASSWORD_RESET_LINK = 'The provided password reset link is invalid.',
  NO_ACTIVITY_FOR_ROADMAP = 'You must provide an activity when rendering a roadmap element',
  NO_GGC_HEALTH_PREFERENCE = 'No preference was found for GGC_HEALTH',
  NO_GGC_MOTIVATION_PREFERENCE = 'No preference was found for GGC_MOTIVATION',
  NO_GGC_COMPETENCE_PREFERENCE = 'No preference was found for GGC_COMPETENCE',
  NO_ACTIVITY_SUBJECTS_PREFERENCE = 'No preference was found for ACTIVITY_SUBJECTS',
  NO_ACTIVITY_TYPES_PREFERENCE = 'No preference was found for ACTIVITY_TYPES',
  GGC_ITEM_NOT_FOUND = 'Could not find GGC item for given PreprID',
  NO_DATE_AT_PATH = 'There is no valid datestring at the given path',
  ACTIVITY_HAS_NO_ROADMAP = 'The provided activity does not have a roadmap element'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Breakpoints {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1400
}

export enum PinState {
  DISABLED = 'disabled',
  UNPINNED = 'unpinned',
  PINNED = 'pinned',
  ERROR = 'error'
}

export enum ApiErrorCode {
  INVALID_REQUEST = 'InvalidRequest',
  INVALID_CREDENTIALS = 'InvalidCredentials',
  INVALID_SMS_CODE = 'InvalidSmsCode',
  INVALID_REQUEST_TOKEN = 'InvalidRequestToken',
  INVALID_TOKEN = 'InvalidRequest'
}

export enum ContactType {
  GENERAL = 'GENERAL',
  CHALLENGE = 'CHALLENGE',
  TECHNICAL = 'TECHNICAL',
  REPORT = 'REPORT',
  COACHLINE = 'COACHLINE'
}
