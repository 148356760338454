import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PreprRenderer } from '@Components/layout';
import { IconText, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { useUserChallenge } from '@Hooks/userChallenge';
import { markupToHtml } from '@Utils/StringUtils';

import styles from './ChallengeItemInformation.module.scss';
import { ChallengeItemAllActivitiesBlock } from './internal/components';

export const ChallengeItemInformation = () => {
  const { slug } = useParams<{ slug: string }>();

  const { t } = useTranslation();
  const { color } = usePlatform();
  const { data: challenge } = useUserChallenge({ id: slug ?? '' });

  if (challenge) {
    return (
      <div className={styles.ChallengeItemInformation}>
        <PreprRenderer content={challenge.content} />

        {challenge.popular && (
          <IconText icon="users" color={color.primary.default} className={styles.Popular}>
            <Text.Large dangerousHtml={markupToHtml(t('DOMAIN.CHALLENGE.POPULAR'))} />
          </IconText>
        )}

        <ChallengeItemAllActivitiesBlock />
      </div>
    );
  }

  return null;
};
