import { useTranslation } from 'react-i18next';

import { useQuestionnaireContext } from '@Components/container/Questionnaire/internal/context';
import {
  Box,
  SliderFullPage,
  SliderNavigation,
  Text,
  useSliderFullPageContext
} from '@Components/ui';

import { QuestionnairePersonaOutroSlideProps } from './QuestionnairePersonaOutroSlide.props';

export const QuestionnairePersonaOutroSlide = ({
  currentSlideIndex,
  totalSlides
}: QuestionnairePersonaOutroSlideProps) => {
  const { t } = useTranslation();
  const { finish, isLoadingNext, isLoadingPrevious } = useQuestionnaireContext();
  const { isAnimating } = useSliderFullPageContext();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head title={t('DOMAIN.PERSONA_QUESTIONNAIRE.OUTRO_SLIDE.HEAD.TITLE')} />

      <SliderFullPage.Block>
        <Text.Normal>{t('DOMAIN.PERSONA_QUESTIONNAIRE.OUTRO_SLIDE.BODY.DESCRIPTION')}</Text.Normal>

        <Box mt={4}>
          <SliderNavigation
            nextLabel={t('COMPONENTS.QUESTIONNAIRE.FINISH_LABEL')}
            onNext={finish}
            isLoadingNext={isLoadingNext}
            isLoadingPrevious={isLoadingPrevious}
            isDisabled={isAnimating}
            current={currentSlideIndex}
            total={totalSlides}
          />
        </Box>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
