import { MutableRefObject, useCallback, useEffect } from 'react';

export const useClickOutside = (
  handler: () => void,
  canClick = true,
  ref?: MutableRefObject<HTMLElement | null>
) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if ((!ref || e.target !== ref.current) && canClick) {
        handler();
      }
    },
    [canClick, handler, ref]
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, [handleClick]);
};
