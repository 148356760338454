import {
  IQuestionnaireSubmission,
  QuestionnaireSubmissionStatus
} from '@Services/QuestionnaireSubmission';

import { ContentSlideType } from './constants';
import { QuestionnaireSliderData } from './types';

export const isDataSlide = (
  slide: QuestionnaireSliderData | null
): slide is IQuestionnaireSubmission => {
  return !!slide && typeof slide !== 'string';
};

export const getInitialSlide = (initialSubmission: IQuestionnaireSubmission) => {
  if (initialSubmission.status === QuestionnaireSubmissionStatus.OPEN) {
    return ContentSlideType.QuestionnaireIntro;
  }

  if (initialSubmission.showSectionIntro) {
    return ContentSlideType.SectionIntro;
  }

  if (initialSubmission.status === QuestionnaireSubmissionStatus.PENDING_COMPLETION) {
    return ContentSlideType.QuestionnaireOutro;
  }

  if (initialSubmission.status === QuestionnaireSubmissionStatus.PENDING_PHASE_COMPLETION) {
    return ContentSlideType.PhaseOutro;
  }

  return initialSubmission;
};
