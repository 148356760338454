import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { userService } from '@Services/User';
import { UserPopupHideRequest, userPopupService } from '@Services/UserPopup';
import { trackMatomoMotivatorHide } from '@Utils/MatomoUtils';

export const useUserPopupHide = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: UserPopupHideRequest) => {
      return userPopupService.hide(config);
    },
    {
      onSuccess: (_, { hide, title }) => {
        trackMatomoMotivatorHide(trackEvent, title, hide);

        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
