import { BaseService, dePortalenService } from '@Services/common';

import { ConfigurationGetAllResponse } from './ConfigurationService.props';

export class ConfigurationService extends BaseService {
  getAll = async () => {
    const response = await this.get<ConfigurationGetAllResponse>();

    return response.data;
  };
}

export const configurationService = new ConfigurationService(
  dePortalenService.getAxiosInstance(),
  '/configuration'
);
