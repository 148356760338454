import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Box, Button, Form, SpacingGroup, Text } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useUserUpdatePassword } from '@Hooks/user';
import Yup from '@Utils/YupUtils';

import {
  UpdatePasswordUpdateFormProps,
  UpdatePasswordUpdateFormValues
} from './UpdatePasswordUpdateForm.props';

export const UpdatePasswordUpdateForm = ({
  smsToken,
  token,
  onSuccess
}: UpdatePasswordUpdateFormProps) => {
  const { error, handleError } = useApiError();
  const { t } = useTranslation();
  const updatePasswordMutation = useUserUpdatePassword();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await updatePasswordMutation.mutateAsync({
          smsToken: smsToken,
          token: token,
          ...values
        });

        toast.success(t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PASSWORD.UPDATE_PASSWORD.SUCCES'));

        onSuccess();
      } catch (e) {
        handleError(e, 'PASSWORD');
      }
    },
    [updatePasswordMutation, smsToken, token, t, onSuccess, handleError]
  );

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(),
    newPassword: Yup.string().password().required(),
    newPasswordConfirmed: Yup.string().password('newPassword').required()
  });

  return (
    <Box mt={3}>
      <Form.Form<UpdatePasswordUpdateFormValues>
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Box mb={3}>
          <Text.Large>
            {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PASSWORD.UPDATE_PASSWORD.NOTICE')}
          </Text.Large>
        </Box>

        <Form.Input.Password
          name="currentPassword"
          data-testid="currentPassword"
          label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.OLD_PASSWORD.LABEL')}
        />

        <Form.Input.Password
          name="newPassword"
          data-testid="newPassword"
          label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.NEW_PASSWORD.LABEL')}
        />

        <Form.Input.Password
          name="newPasswordConfirmed"
          data-testid="newPasswordConfirmed"
          label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.REPEAT_PASSWORD.LABEL')}
        />

        <Form.Layout.Error error={error} />

        <SpacingGroup size="xxl">
          <Button type="submit" color="dark" icon="arrow-right" data-testid="submitButton">
            {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PASSWORD.UPDATE_PASSWORD.SUBMIT_LABEL')}
          </Button>
        </SpacingGroup>
      </Form.Form>
    </Box>
  );
};
