import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { useAuthResetPassword } from '@Hooks/auth';
import { useApiError } from '@Hooks/common';
import Yup from '@Utils/YupUtils';

import { AuthResetPasswordFormValues } from '..';
import { AuthResetPasswordFormProps } from './AuthResetPasswordForm.props';

export const AuthResetPasswordForm = ({
  onSuccess,
  token,
  email,
  className
}: AuthResetPasswordFormProps) => {
  const { t } = useTranslation();
  const { error, handleError } = useApiError();
  const authResetPasswordMutation = useAuthResetPassword();

  const handleSubmit = useCallback(
    async (values: AuthResetPasswordFormValues) => {
      try {
        const params = {
          ...values,
          token
        };

        await authResetPasswordMutation.mutateAsync(params);

        onSuccess();
      } catch (e) {
        handleError(e, 'AUTHENTICATION');
      }
    },
    [token, authResetPasswordMutation, onSuccess, handleError]
  );

  const validationSchema = Yup.object().shape({
    password: Yup.string().password().required(),
    passwordConfirmation: Yup.string().password('password').required()
  });

  const initialValues = {
    email: email || undefined
  };

  return (
    <Form.Form<AuthResetPasswordFormValues>
      onSubmit={handleSubmit}
      className={className}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      <Form.Input.Text
        name="email"
        label={t('DOMAIN.AUTHENTICATION.RESET_PASSWORD.PASSWORD.FORM.FIELDS.EMAIL.LABEL')}
        options={{
          disabled: true
        }}
      />

      <Form.Input.Password
        name="password"
        data-testid="passwordInput"
        label={t('DOMAIN.AUTHENTICATION.RESET_PASSWORD.PASSWORD.FORM.FIELDS.PASSWORD.LABEL')}
      />

      <Form.Input.Password
        name="passwordConfirmation"
        data-testid="passwordConfirmationInput"
        label={t('DOMAIN.AUTHENTICATION.RESET_PASSWORD.PASSWORD.FORM.FIELDS.PASSWORD_VERIFY.LABEL')}
      />

      <Form.Layout.Error error={error} />

      <Button type="submit" icon="arrow-right" data-testid="resetPasswordSubmit">
        {t('DOMAIN.AUTHENTICATION.RESET_PASSWORD.PASSWORD.FORM.SUBMIT')}
      </Button>
    </Form.Form>
  );
};
