import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';

import { Block, ButtonIcon, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { IActivityFilter } from '@Services/Activity';

import styles from './ActivityFilterTagsActive.module.scss';
import { ActivityFilterTagsActiveProps } from './ActivityFilterTagsActive.props';

export const ActivityFilterTagsActive = ({ filter, onReset }: ActivityFilterTagsActiveProps) => {
  const { t } = useTranslation();
  const { easing } = usePlatform();

  const getFilter = (
    name: 'SUBJECTS' | 'TYPES' | 'POINTS' | 'DURATIONS',
    items: string | IActivityFilter[]
  ) => {
    if (items) {
      const label = t(`DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.FORM.FIELDS.${name}.LABEL`);

      if ((Array.isArray(items) && items.length > 0) || typeof items === 'string') {
        return (
          <Text.Normal className={styles.Item}>
            {label}

            {': '}

            <strong>
              {Array.isArray(items) && items.map((item) => item.title).join(', ')}

              {typeof items === 'string' && `${items} ${label.toLowerCase()}`}
            </strong>
          </Text.Normal>
        );
      }
    }
  };

  return (
    <AnimateHeight height={Object.keys(filter || {}).length === 0 ? 0 : 'auto'} easing={easing.out}>
      <Block padding="xs" theme="gray" className={styles.ActivityFilterTagsActive}>
        <div className={styles.Header}>
          <Text.H5>{t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.ACTIVE.TITLE')}</Text.H5>

          <ButtonIcon
            icon="undo"
            data-testid="filterTagsReset"
            onClick={onReset}
            label={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.ACTIVE.RESET')}
          />
        </div>

        <div className={styles.Items}>
          {filter?.subjects && getFilter('SUBJECTS', filter.subjects)}

          {filter?.types && getFilter('TYPES', filter.types)}

          {filter?.points && getFilter('POINTS', filter.points)}

          {filter?.durations && getFilter('DURATIONS', filter.durations)}
        </div>
      </Block>
    </AnimateHeight>
  );
};
