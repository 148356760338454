import { View } from '@react-pdf/renderer';

import { PdfText } from '@Documents/components';

import { DomainBlockProps } from './DomainBlock.props';
import { styles } from './DomainBlock.styles';

export const DomainBlock = ({ title, description, style }: DomainBlockProps) => {
  return (
    <View style={{ ...styles.DomainBlock, ...style }} wrap={false} break>
      <PdfText.H4 style={styles.Title}>{title}</PdfText.H4>

      <PdfText.Normal>{description}</PdfText.Normal>
    </View>
  );
};
