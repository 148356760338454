import { InputOption } from '@Constants/interfaces';
import { DataGetActivityTypesResponse } from '@Services/Data';

export const activityTypesToInputOptions = (types: DataGetActivityTypesResponse): InputOption[] => {
  return types.map((item) => {
    return {
      value: item.preprId,
      label: item.description
    };
  });
};
