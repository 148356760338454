import { useEffect, useState } from 'react';

import { PATH_SPACING, PATH_SPACING_PATHS } from '../constants';
import { useScoreCircleContext } from '../context';
import { useScoreCircleDynamicHeight } from '.';

export const useScoreCircleDynamicSpacing = () => {
  const { scores, componentRef } = useScoreCircleContext();
  const [actualComponentHeight, baseComponentHeight] = useScoreCircleDynamicHeight(
    componentRef,
    scores
  );

  const [spacing, setSpacing] = useState(PATH_SPACING);

  useEffect(() => {
    if (actualComponentHeight < baseComponentHeight && scores) {
      if (scores.length >= PATH_SPACING_PATHS) {
        const multiplier = actualComponentHeight / baseComponentHeight;

        setSpacing(PATH_SPACING * multiplier);
      } else {
        setSpacing(PATH_SPACING);
      }
    }
  }, [scores, scores?.length, actualComponentHeight, baseComponentHeight]);

  return [spacing];
};
