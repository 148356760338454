import { Navigate, Route, Routes } from 'react-router-dom';

import { ChallengeStack } from '@Navigation/index';
import { InsightProgressScreen, InsightReportScreen } from '@Screens/Insight';

import { AppRoutes } from './Routes';

export const InsightStack = () => {
  return (
    <Routes>
      <Route path={AppRoutes.getInsightChallenges().path} element={<ChallengeStack />} />

      <Route path={AppRoutes.getInsightProgress().path} element={<InsightProgressScreen />} />

      <Route path={AppRoutes.getInsightReport().path} element={<InsightReportScreen />} />

      <Route path="*" element={<Navigate to={AppRoutes.getInsightChallenges().to} />} />
    </Routes>
  );
};
