import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { HighlightedChallenge } from '@Components/container';
import { Container } from '@Components/layout';
import { Anchor, Box, Button, CallToAction, Loader, SpacingGroup, Text } from '@Components/ui';
import { TargetDirection } from '@Hooks/common';
import { useUserChallenge } from '@Hooks/userChallenge';
import { AppRoutes } from '@Navigation/Routes';

import styles from './ChallengeStartScreen.module.scss';

/**
 * TODOS:
 * - fix link to challenge single, should be slug not id
 */
export const ChallengeStartScreen = () => {
  const { id } = useParams<{ id: string }>();

  const challengeRef = useRef(null);

  const { t } = useTranslation();
  const { data: challenge, isFetched } = useUserChallenge({ id: id ?? '' });

  if (!challenge) {
    if (isFetched) {
      return (
        <Container>
          <Text.H1>{t('DOMAIN.CHALLENGE.START.NOT_FOUND')}</Text.H1>
        </Container>
      );
    }

    return (
      <Container>
        <Loader>{t('DOMAIN.CHALLENGE.START.LOADING')}</Loader>
      </Container>
    );
  }

  return (
    <>
      <Container className={styles.ChallengeStartScreen}>
        <Box mb={1}>
          <Text.H1 className={styles.Title}>{t('DOMAIN.CHALLENGE.START.TITLE')}</Text.H1>
        </Box>

        <Box mb={3}>
          <Text.H2 className={styles.ChallengeTitle}>{challenge.title}</Text.H2>
        </Box>

        <Box mb={5}>
          <Text.Large className={styles.Intro}>{challenge.excerpt}</Text.Large>
        </Box>

        <Box mb={5}>
          <HighlightedChallenge ref={challengeRef} challenge={challenge} />
        </Box>

        <SpacingGroup size="xxl">
          <Button to={AppRoutes.getInsightChallenge(challenge.slug).to} icon="arrow-right">
            {t('DOMAIN.CHALLENGE.START.START')}
          </Button>

          <Anchor to={AppRoutes.getDashboard().to}>
            {t('DOMAIN.CHALLENGE.START.TO_DASHBOARD')}
          </Anchor>
        </SpacingGroup>
      </Container>

      <CallToAction
        label={t('DOMAIN.CHALLENGE.START.CTA')}
        targetRef={challengeRef}
        direction={TargetDirection.LEFT}
        isVisible={isFetched}
        isReversed
        isStatic
      />
    </>
  );
};
