import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { PageUnauthenticated } from '@Components/layout';
import {
  AuthForgotPasswordScreen,
  AuthLoginScreen,
  AuthRegistrationScreen,
  AuthResetPasswordScreen
} from '@Screens/Auth';

import { AppRoutes } from './Routes';

export const AuthStack = () => {
  const { pathname, search } = useLocation();

  return (
    <PageUnauthenticated>
      <Routes>
        <Route path={AppRoutes.getLogin().path} element={<AuthLoginScreen />} />

        <Route path={AppRoutes.getRegistration().path} element={<AuthRegistrationScreen />} />

        <Route
          path={AppRoutes.getRegistration('B').path}
          element={<AuthRegistrationScreen variant="B" />}
        />

        <Route path={AppRoutes.getForgotPassword().path} element={<AuthForgotPasswordScreen />} />

        <Route path={AppRoutes.getUpdatePassword().path} element={<AuthResetPasswordScreen />} />

        <Route
          path="*"
          element={<Navigate to={AppRoutes.getLogin().to} state={pathname + search} />}
        />
      </Routes>
    </PageUnauthenticated>
  );
};
