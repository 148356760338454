import { useEffect } from 'react';

import { useBoolean, useTimeouts } from '@Hooks/common';

import { useSliderFullPageItemContext } from '../../SliderFullPage';
import { SLIDER_FULLPAGE_SPEED } from '../constants';

export const useSliderFullPageItemActiveAfterAnimation = () => {
  const { value: isActiveAndAnimated, setValue: setIsActiveAndAnimated } = useBoolean(false);
  const { isActive } = useSliderFullPageItemContext();
  const { addTimeout } = useTimeouts();

  useEffect(() => {
    if (isActive) {
      addTimeout(() => {
        setIsActiveAndAnimated(true);
      }, SLIDER_FULLPAGE_SPEED);
    } else {
      setIsActiveAndAnimated(false);
    }
  }, [isActive, setIsActiveAndAnimated, addTimeout]);

  return {
    isActiveAndAnimated
  };
};
