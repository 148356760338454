import { useQuery } from 'react-query';

import { activityService, IActivity } from '@Services/Activity';

export const useActivityRelated = (activity: IActivity) => {
  return useQuery([activityService.getEndpoint(), 'related', activity.id], () => {
    return activityService.getRelated({
      id: activity.id,
      subjects: activity.subject.map((subject) => subject.id)
    });
  });
};
