import cn from 'classnames';
import { useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import { useController } from 'react-hook-form';

import { FormFieldProps } from '@Components/ui';
import { Icon, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './FormAccordionItem.module.scss';
import { FormAccordionItemProps } from './FormAccordionItem.props';

export const FormAccordionItem = ({
  option,
  isOpen,
  onClick,
  onChange
}: FormAccordionItemProps) => {
  const { easing } = usePlatform();
  const { fieldState, field } = useController({
    name: (option.render.props as FormFieldProps).name
  });

  const isValid = !!fieldState.error === false && fieldState.isTouched;
  const hasError = !!fieldState.error === true;

  const classNames = cn(styles.FormAccordionItem, {
    [styles.isOpen]: isOpen,
    [styles.isValid]: isValid,
    [styles.hasError]: hasError
  });

  const getIcon = () => {
    if (hasError) {
      return 'times';
    } else if (isValid) {
      return 'check';
    } else if (isOpen) {
      return 'chevron-down';
    }

    return 'chevron-right';
  };

  useEffect(() => {
    if (field.value) {
      onChange();
    }
  }, [field.value, onChange]);

  return (
    <div className={classNames}>
      <div className={styles.Heading} onClick={onClick}>
        <Text.H4>{option.label}</Text.H4>

        <Icon className={styles.Icon} icon={['fas', getIcon()]} />
      </div>

      <AnimateHeight easing={easing.inOut} height={isOpen ? 'auto' : 0}>
        <div className={styles.Body}>{option.render}</div>
      </AnimateHeight>
    </div>
  );
};
