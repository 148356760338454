import { usePathUtils, usePlatform } from '@Hooks/common';
import { exists } from '@Utils/CommonUtils';

import { ANIMATION_DELAY, PATH_SPACING, STROKE_WIDTH } from '../../constants';
import { useScoreCircleContext } from '../../context';
import {
  useScoreCircleDynamicHeight,
  useScoreCircleDynamicSpacing,
  useScoreCircleIsDoneAnimating
} from '../../hooks';
import { Path } from '..';
import styles from './Paths.module.scss';

export const Paths = () => {
  const { scores, componentRef, hoveringDomain, isVisible, activeIdx, handleHover, handleClick } =
    useScoreCircleContext();

  const { color } = usePlatform();
  const { getRatio, getPathRadius } = usePathUtils();
  const [isDoneAnimating] = useScoreCircleIsDoneAnimating();

  const [dynamicSpacing] = useScoreCircleDynamicSpacing();
  const [, baseComponentHeight] = useScoreCircleDynamicHeight(componentRef, scores);

  return (
    <>
      {scores?.map((domain, idx) => {
        const delay = ANIMATION_DELAY * (scores.length - idx);
        const zIndex = scores.length - idx;

        const actualSpacing = idx * dynamicSpacing;
        const viewbox = baseComponentHeight - idx * PATH_SPACING;

        const hasSelected = !exists<number>(activeIdx);
        const isSelected = idx === activeIdx;
        const isHovering = idx === hoveringDomain;

        return (
          <svg
            key={domain.name}
            className={styles.PathWrapper}
            viewBox={`0 0 ${viewbox} ${viewbox}`}
            style={{
              width: `calc(100% - ${actualSpacing}px)`,
              height: `calc(100% - ${actualSpacing}px)`,
              zIndex
            }}
          >
            <Path
              dashRatio={isVisible ? getRatio(domain.current, domain.total) : 0}
              strokeWidth={STROKE_WIDTH}
              pathRadius={getPathRadius(viewbox, STROKE_WIDTH)}
              color={color.gray.light}
              viewBoxWidth={viewbox}
              viewBoxHeight={viewbox}
              className={styles.Path}
              onMouseEnter={handleHover(idx)}
              onMouseLeave={handleHover(null)}
              style={{ transitionDelay: `${delay}ms`, zIndex }}
            />

            <Path
              dashRatio={
                (isSelected || isHovering || hasSelected) && isVisible
                  ? getRatio(domain.current, domain.total)
                  : 0
              }
              strokeWidth={STROKE_WIDTH}
              pathRadius={getPathRadius(viewbox, STROKE_WIDTH)}
              color={domain.color}
              viewBoxWidth={viewbox}
              viewBoxHeight={viewbox}
              className={styles.Path}
              onMouseEnter={handleHover(idx)}
              onMouseLeave={handleHover(null)}
              onClick={handleClick && handleClick(idx)}
              style={{ transitionDelay: `${isDoneAnimating ? 0 : delay}ms`, zIndex }}
            />
          </svg>
        );
      })}
    </>
  );
};
