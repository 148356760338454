import cn from 'classnames';
import { useEffect, useRef } from 'react';

import styles from './Smiley.module.scss';
import { SmileyPoint, SmileyProps } from './Smiley.props';

export const Smiley = ({ happiness, className }: SmileyProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const smileRef = useRef<SVGPathElement>(null);

  const smilePoints = (scale: number): SmileyPoint[] => {
    let factor = scale * 2 - 1;
    factor = factor < -1 ? -1 : factor;
    factor = factor > 1 ? 1 : factor;

    const p1 = { x: -5, y: -2.5 * factor };
    const c1 = { x: -5, y: 2.5 * factor };
    const p2 = { x: 5, y: -2.5 * factor };
    const c2 = { x: 5, y: 2.5 * factor };

    return [p1, c1, c2, p2];
  };

  const writeSmilePoints = (points: SmileyPoint[]) => {
    const p = (point: SmileyPoint) => `${point.x},${point.y}`;
    return `M${p(points[0])} C${p(points[1])} ${p(points[2])} ${p(points[3])}`;
  };

  useEffect(() => {
    if (svgRef.current && smileRef.current) {
      const scale = happiness / 100;
      const points = writeSmilePoints(smilePoints(scale));

      const animate = document.createElementNS(
        svgRef.current.namespaceURI,
        'animate'
      ) as SVGAnimateElement;

      animate.setAttribute('attributeName', 'd');
      animate.setAttribute('attributeType', 'XML');
      animate.setAttribute('to', points);
      animate.setAttribute('dur', '0.5s');
      animate.setAttribute('repeatCount', '1');
      animate.setAttribute('fill', 'freeze');

      smileRef.current.appendChild(animate);

      if (animate.beginElement) {
        animate.beginElement();
      }
    }
  }, [happiness]);

  return (
    <div className={cn(styles.Smiley, className)}>
      <svg ref={svgRef} viewBox="0 0 23 24">
        <circle fill="#ffffff" r={12} radius={23} cx="12" cy="12" />

        <path d="M21.435 12.27c-.023.81-.171 1.644-.266 2.451-.112.938-.337 1.891-.749 2.743-.677 1.401-1.941 2.523-3.16 3.452-2.263 1.726-5.241 2.121-7.968 1.505-2.374-.537-4.56-1.902-6.004-3.878-.882-1.207-1.42-2.627-1.758-4.075-.337-1.442-.48-2.987-.065-4.424.221-.764.561-1.492.858-2.228.278-.691.578-1.327 1.041-1.918.929-1.182 2.185-2.18 3.464-2.949.756-.454 1.523-.788 2.408-.869.826-.075 1.657.016 2.484-.072l.058-.005.32.023c.765.062 1.53.106 2.245.414.824.354 1.519.979 2.23 1.512 1.395 1.046 2.76 2.173 3.668 3.688a8.62 8.62 0 0 1 1.194 4.63m1.488-1.527a10.402 10.402 0 0 0-1.714-4.556c-1.009-1.475-2.41-2.573-3.83-3.62-.672-.494-1.326-1.04-2.077-1.41-.743-.365-1.557-.506-2.376-.566-.508-.037-1.018.02-1.527.016-.623-.004-1.244-.022-1.866.027-.428.034-.817.13-1.201.314a1.54 1.54 0 0 0-.237.144c-.378.1-.75.234-1.125.406-1.54.703-2.949 1.88-4.1 3.114C1.726 5.84 1.184 7.36.58 8.9c-1.204 3.071-.4 6.806 1.222 9.587 1.453 2.493 3.934 4.232 6.663 5.043 2.708.805 5.623.563 8.17-.645 1.337-.633 2.551-1.661 3.6-2.694 1.225-1.205 1.974-2.715 2.312-4.393.172-.855.204-1.728.348-2.585a8.45 8.45 0 0 0 .028-2.471" />

        <path d="M7.468 8.441h-.045a.822.822 0 0 0-.39.095c-.429.218-.429.92 0 1.139a.788.788 0 0 0 .378.095h.057c.36 0 .661-.303.661-.665 0-.362-.3-.664-.661-.664M14.264 8.37a.706.706 0 0 0-.605-.349.706.706 0 0 0-.605 1.06l.025.041a.677.677 0 0 0 1.16 0l.025-.042a.71.71 0 0 0 0-.71" />

        <g transform="translate(12, 16)">
          <path
            ref={smileRef}
            d="M-5,0 C-5,0 5,0 5,0"
            fill="none"
            stroke="#000000"
            strokeWidth={1}
            strokeLinecap="round"
          />
        </g>
      </svg>
    </div>
  );
};
