import { useUser } from '@Hooks/user';

import { DashboardActiveChallenges, DashboardProfileStatus } from './internal/components';

export const DashboardIntro = () => {
  const { profileStatusSteps } = useUser();
  const hasCompletedProfile = profileStatusSteps.length === 0;

  return hasCompletedProfile ? <DashboardActiveChallenges /> : <DashboardProfileStatus />;
};
