import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AUTOMATIC_LOGOUT_DURATION_MINUTES } from '@Constants/configs';

import { useThrottle } from './useThrottle';

export const useWindowActivityListener = () => {
  const [lastActivity, setLastActivity] = useState<Date>();
  const [isActive, setIsActive] = useState(true);

  const handleTimeout = useCallback(() => {
    setIsActive(false);
  }, []);

  const debouncedEventHandler = useMemo(
    () => debounce(handleTimeout, AUTOMATIC_LOGOUT_DURATION_MINUTES * 60000),
    [handleTimeout]
  );

  const handleActivity = useCallback(() => {
    setIsActive(true);
    setLastActivity(new Date());

    debouncedEventHandler();
  }, [debouncedEventHandler]);

  const handleActivityThrottled = useThrottle(handleActivity, 5000);

  useEffect(() => {
    handleActivityThrottled();

    window.addEventListener('mousemove', handleActivityThrottled);
    window.addEventListener('click', handleActivityThrottled);
    window.addEventListener('scroll', handleActivityThrottled);
    window.addEventListener('focus', handleActivityThrottled);

    return () => {
      window.removeEventListener('mousemove', handleActivityThrottled);
      window.removeEventListener('click', handleActivityThrottled);
      window.removeEventListener('scroll', handleActivityThrottled);
      window.removeEventListener('focus', handleActivityThrottled);
    };
  }, [handleActivityThrottled]);

  return {
    isActive: isActive,
    lastActivity: lastActivity
  };
};
