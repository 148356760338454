import { useQuery } from 'react-query';

import { OneRequest } from '@Constants/interfaces';
import { userChallengeService } from '@Services/UserChallenge';

export const useUserChallenge = (params: OneRequest) => {
  return useQuery([userChallengeService.getEndpoint(), params], () => {
    return userChallengeService.one(params);
  });
};
