import { useQuery, UseQueryOptions } from 'react-query';

import { IUser, userService } from '@Services/User';

export const useUserMe = (options?: Omit<UseQueryOptions<IUser>, 'queryKey' | 'queryFn'>) => {
  return useQuery(
    [userService.getEndpoint(), 'me'],
    () => {
      return userService.getMe();
    },
    options
  );
};
