import cn from 'classnames';

import styles from './H2.module.scss';
import { H2Props } from './H2.props';

export const H2 = ({ className, children, dangerousHtml, color, ...props }: H2Props) => {
  if (!children && !dangerousHtml) return null;

  const classNames = cn(styles.H2, className);

  const inlineStyle = {
    color
  };

  return (
    <h2
      className={classNames}
      dangerouslySetInnerHTML={!!dangerousHtml ? { __html: dangerousHtml } : undefined}
      style={inlineStyle}
      {...props}
    >
      {children}
    </h2>
  );
};
