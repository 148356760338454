import cn from 'classnames';
import { useRef } from 'react';

import { Icon, ProgressBar, Text, Tooltip } from '@Components/ui';
import { TargetAlignment, useBoolean, usePlatform, usePlayInView } from '@Hooks/common';
import { toLocaleString } from '@Utils/StringUtils';

import styles from './ProgressLabeled.module.scss';
import { ProgressLabeledProps } from './ProgressLabeled.props';

export const ProgressLabeled = ({
  label,
  count,
  hasTooltip = true,
  isLarge,
  className,
  total,
  current,
  ...progressBarProps
}: ProgressLabeledProps) => {
  const labelRef = useRef(null);

  const showLabel = useBoolean(false);
  const showToolTip = useBoolean(false);

  const { color } = usePlatform();
  const [inViewRef] = usePlayInView(showLabel.setTrue);

  const isPositive = count ? count >= 0 : true;

  const progressColor = color.success.default;
  const iconColor = isPositive ? color.secondary.default : color.red.default;

  return (
    <div
      ref={inViewRef}
      className={cn(styles.ProgressLabeled, className)}
      onMouseEnter={showToolTip.setTrue}
      onMouseLeave={showToolTip.setFalse}
    >
      <div ref={labelRef} className={cn(styles.Labels, { [styles.isVisible]: showLabel.value })}>
        {isLarge ? (
          <Text.Large className={styles.Label}>{label}</Text.Large>
        ) : (
          <Text.Small className={styles.Label}>{label}</Text.Small>
        )}

        {isLarge && <Text.Bold className={styles.Percentage}>{total - current}%</Text.Bold>}

        {!!count && !isLarge && (
          <Text.Micro className={styles.Count}>
            {isPositive && '+'}
            {count + ' '}
            <Icon
              icon={['far', isPositive ? 'long-arrow-up' : 'long-arrow-down']}
              color={iconColor}
            />
          </Text.Micro>
        )}
      </div>

      <ProgressBar {...progressBarProps} total={total} current={current} color={progressColor} />

      {hasTooltip && (
        <Tooltip
          targetRef={labelRef}
          label={toLocaleString(current ?? '')}
          alignment={TargetAlignment.LEFT}
          isVisible={showToolTip.value}
        />
      )}
    </div>
  );
};
