import { BaseService, dePortalenService } from '@Services/common';

import { PreferenceSubmitBatchRequest } from '.';
import {
  PreferenceResponse,
  PreferenceSubmitRequest,
  PreferenceSubmitResponse
} from './PreferenceService.props';

export class PreferenceService extends BaseService {
  all = async () => {
    const response = await this.get<PreferenceResponse>('');

    return response.data;
  };

  submit = async ({ key, values }: PreferenceSubmitRequest) => {
    const response = await this.post<PreferenceSubmitResponse>(`/${key}`, values);

    return response.data;
  };

  submitBatch = async (config: PreferenceSubmitBatchRequest) => {
    const response = await this.post<PreferenceSubmitResponse>('/batch', config);

    return response.data;
  };
}

export const preferenceService = new PreferenceService(
  dePortalenService.getAxiosInstance(),
  '/preferences'
);
