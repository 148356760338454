import { Text } from '@react-pdf/renderer';

import { PdfTextNormalProps } from './PdfTextNormal.props';
import { styles } from './PdfTextNormal.styles';

export const Normal = ({ children, style, ...props }: PdfTextNormalProps) => {
  return (
    <Text style={{ ...styles.Normal, ...style }} {...props}>
      {children}
    </Text>
  );
};
