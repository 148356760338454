import { ReactNode } from 'react';

export enum SelftestStatus {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export interface ProgressBlockProps {
  progress: {
    total: number;
    current: number;
    availableAt?: Date;
  };

  isDisabled?: boolean;

  disabledLabel?: string;

  children?: ReactNode | ReactNode[];

  isSmall?: boolean;

  className?: string;
}
