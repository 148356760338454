import cn from 'classnames';
import { forwardRef } from 'react';

import { InputText } from '@Components/ui';
import { Icon } from '@Components/ui';
import { useBoolean } from '@Hooks/common';

import styles from './InputPassword.module.scss';
import { InputPasswordProps } from './InputPassword.props';

export const InputPassword = forwardRef(
  ({ className, ...props }: InputPasswordProps, ref?: any) => {
    const isVisible = useBoolean(false);
    const classNames = cn(styles.InputPassword, className);

    return (
      <div className={classNames}>
        <InputText {...props} type={isVisible.value ? 'text' : 'password'} ref={ref} />

        <div
          className={cn(styles.Toggle, {
            [styles.isVisible]: isVisible.value
          })}
          onClick={isVisible.toggle}
        >
          <Icon icon={['fas', isVisible.value ? 'eye' : 'eye-slash']} size="lg" />
        </div>
      </div>
    );
  }
);
