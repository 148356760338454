import { ITimelineItem, TimelineItemType } from '@Services/Insight';

type TimelineGrouped = {
  [key in TimelineItemType]: ITimelineItem[];
};

export const groupItemsByType = (items: ITimelineItem[]) => {
  return items.reduce(
    (total: TimelineGrouped, current) => {
      total[current.type].push(current);

      return total;
    },
    {
      [TimelineItemType.CHALLENGE]: [],
      [TimelineItemType.ACTIVITY]: [],
      [TimelineItemType.CUSTOM_ACTIVITY]: [],
      [TimelineItemType.MILESTONE]: [],
      [TimelineItemType.REWARD]: []
    }
  );
};

export const groupItemsByCompletion = (items: ITimelineItem[]) => {
  return items.reduce(
    (total: ITimelineItem[][], current) => {
      const isCompleted = current.completedAt !== null;

      total[isCompleted ? 1 : 0].push(current);

      return total;
    },
    [[], []]
  );
};
