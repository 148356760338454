import cn from 'classnames';
import { useCallback } from 'react';
import AnimateHeight from 'react-animate-height';

import { ButtonIcon, Text } from '@Components/ui';
import { useBoolean, useHover, usePlatform } from '@Hooks/common';

import { MessageItemText } from './internal/components';
import styles from './MessageItem.module.scss';
import { MessageItemProps } from './MessageItem.props';

export const MessageItem = ({ title, message, isRead, onRead, className }: MessageItemProps) => {
  const isOpen = useBoolean(false);
  const shouldConcatenate = useBoolean(true);
  const { isHovering, handlers } = useHover();

  const { easing, font } = usePlatform();

  const lineHeight = Number(font.large.lineHeight) * Number(font.large.fontSize.replace('px', ''));

  const classNames = cn(styles.MessageItem, className, {
    [styles.isRead]: isRead,
    [styles.isOpen]: isOpen,
    [styles.isConcatenated]: shouldConcatenate.value
  });

  const handleRead = useCallback(() => {
    if (!isRead) {
      onRead();
    }
  }, [isRead, onRead]);

  const handleToggle = useCallback(() => {
    if (!isOpen.value) {
      handleRead();

      shouldConcatenate.setFalse();
    }

    isOpen.toggle();
  }, [isOpen, handleRead, shouldConcatenate]);

  const handleAnimationEnd = useCallback(() => {
    if (!isOpen.value) {
      shouldConcatenate.setTrue();
    }
  }, [isOpen, shouldConcatenate]);

  return (
    <div className={classNames} data-testid="container" onClick={handleToggle} {...handlers}>
      <div className={styles.UnreadIndicator} />

      <div className={styles.Body}>
        <Text.H4 className={styles.Title}>
          <MessageItemText message={title} className={styles.TitleMessage} />
        </Text.H4>

        <AnimateHeight
          easing={easing.inOut}
          height={isOpen.value ? 'auto' : lineHeight}
          onAnimationEnd={handleAnimationEnd}
          data-testid="height"
        >
          <Text.Large className={styles.Message} data-testid="message">
            <MessageItemText message={message} />
          </Text.Large>
        </AnimateHeight>
      </div>

      <ButtonIcon
        icon={isOpen.value ? 'minus' : 'plus'}
        className={styles.Icon}
        data-testid="toggle"
        isHovering={isHovering}
      />
    </div>
  );
};
