import { BaseService, dePortalenService } from '@Services/common';

import { MilestoneAllResponse, MilestoneClaimRequest } from './MilestoneService.props';

class MilestoneService extends BaseService {
  all = async () => {
    const response = await this.get<MilestoneAllResponse>('');

    return response.data;
  };

  claim = async ({ id }: MilestoneClaimRequest) => {
    await this.post<void>(`/claim`, { milestone: id });
  };
}

export const milestoneService = new MilestoneService(
  dePortalenService.getAxiosInstance(),
  '/milestones'
);
