import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Form, SliderFullPage } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useUserActivityCreate, useUserActivityUpdate } from '@Hooks/useractivity';
import { userActivityService } from '@Services/UserActivity';
import Yup from '@Utils/YupUtils';

import { UserActivityFormTextareaSlide } from './internal/components';
import { UserActivityFormProps, UserActivityFormValues } from './UserActivityForm.props';

export const UserActivityForm = ({
  id,
  initialValues,
  onSuccess,
  className
}: UserActivityFormProps) => {
  const { t } = useTranslation();
  const userActivityCreateMutation = useUserActivityCreate();
  const userActivityUpdateMutation = useUserActivityUpdate();
  const { handleError } = useApiError();
  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape({
    customTitle: Yup.string().required(),
    description: Yup.string().required(),
    expectation: Yup.string().required()
  });

  const handleSubmit = useCallback(
    async (values: UserActivityFormValues) => {
      try {
        let response = null;

        if (id) {
          response = await userActivityUpdateMutation.mutateAsync({
            id: id,
            ...values
          });

          queryClient.removeQueries([userActivityService.getEndpoint(), id]);
        } else {
          response = await userActivityCreateMutation.mutateAsync(values);
        }

        toast.success(t('DOMAIN.USER_ACTIVITY.FORM.SUCCESS'));

        if (onSuccess) {
          onSuccess(response.id);
        }
      } catch (e) {
        handleError(e);
      }
    },
    [
      id,
      t,
      onSuccess,
      userActivityUpdateMutation,
      queryClient,
      userActivityCreateMutation,
      handleError
    ]
  );

  return (
    <Form.Form<UserActivityFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      className={className}
      onSubmit={handleSubmit}
    >
      <SliderFullPage>
        {!id && (
          <UserActivityFormTextareaSlide fieldName="customTitle" translationName="CUSTOM_TITLE" />
        )}

        <UserActivityFormTextareaSlide fieldName="description" translationName="DESCRIPTION" />

        <UserActivityFormTextareaSlide
          hasSubmit
          fieldName="expectation"
          translationName="EXPECTATION"
        />
      </SliderFullPage>
    </Form.Form>
  );
};
