import cn from 'classnames';
import { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import 'swiper/swiper.min.css';

import { SliderPagination } from '@Components/ui';

import styles from './InputCarousel.module.scss';
import { InputCarouselProps } from './InputCarousel.props';
import { InputCarouselSlide } from './internal';

export const InputCarousel = forwardRef(
  (
    {
      options,
      className,
      isError,
      isValid,
      onChange,
      value,
      isDisabled,
      ...props
    }: InputCarouselProps,
    ref?: any
  ) => {
    const slideRefs = useRef<Array<HTMLDivElement | null>>([]);

    const activeIndex = options ? options.findIndex((option) => option.value === value) : 0;

    const [swiper, setSwiper] = useState<SwiperClass>();
    const [activeSlide, setActiveSlide] = useState(activeIndex > 0 ? activeIndex : 0);

    const classNames = cn(styles.InputCarousel, className, {
      [styles.isError]: isError,
      [styles.isValid]: isValid
    });

    const breakpoints = {
      0: {
        spaceBetween: -25,
        slidesPerView: 1.5
      },

      350: {
        spaceBetween: -25,
        slidesPerView: 1.75
      },

      450: {
        spaceBetween: -25,
        slidesPerView: 2.5
      }
    };

    const handleChange = useCallback(
      (index: number) => {
        setActiveSlide(index);
        swiper?.slideTo(activeSlide);

        if (onChange) {
          onChange(options[index]?.value);
        }
      },
      [activeSlide, onChange, options, swiper]
    );

    const handleSwipe = useCallback(
      (swiperInstance: SwiperClass) => {
        handleChange(swiperInstance.activeIndex);
      },
      [handleChange]
    );

    const handleSlideClick = useCallback(
      (idx: number) => () => {
        handleChange(idx);
      },
      [handleChange]
    );

    useLayoutEffect(() => {
      swiper?.update();
    }, [swiper]);

    useEffect(() => {
      handleChange(activeSlide);
    }, [activeSlide, handleChange, value]);

    return (
      <div className={classNames} {...props} ref={ref}>
        <div className={styles.Inner}>
          <Swiper
            onSwiper={setSwiper}
            centeredSlides
            breakpoints={breakpoints}
            onSlideChange={handleSwipe}
            className={styles.Swiper}
          >
            {options.map((option, idx) => {
              return (
                <SwiperSlide key={idx} className={styles.SwiperSlide}>
                  <div ref={(el) => (slideRefs.current[idx] = el)} onClick={handleSlideClick(idx)}>
                    <InputCarouselSlide label={option.label} isActive={activeSlide === idx} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <SliderPagination
          isDisabled={isDisabled}
          className={styles.Pagination}
          total={options.length}
          current={activeSlide}
          onChange={handleChange}
        />
      </div>
    );
  }
);
