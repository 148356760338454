import cn from 'classnames';
import { isAfter } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Block, ScoreCircle, Text } from '@Components/ui';
import { MAX_HAPPINESS_SCORE } from '@Constants/configs';
import { usePlatform } from '@Hooks/common';
import { useResultColorMap } from '@Hooks/userTheme';
import { exists } from '@Utils/CommonUtils';
import { getToday } from '@Utils/DateUtils';

import { domainScoresToScores } from './internal/utils';
import styles from './ReportScore.module.scss';
import { ReportScoreProps } from './ReportScore.props';

export const ReportScore = ({
  domainScores,
  happinessScore,
  happinessResult,
  nextPhaseAvailableAt,
  onScoreClick,
  activeIdx
}: ReportScoreProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();
  const { getColorForResult } = useResultColorMap();
  const hasResults = !!happinessScore;
  const isPhased = !!nextPhaseAvailableAt;
  const isNextPhaseAvailable = isPhased && isAfter(getToday(), new Date(nextPhaseAvailableAt));

  const activeCircleIdx = activeIdx;

  const handleScoreClick = useCallback(
    (idx) => {
      if (onScoreClick) {
        onScoreClick(idx);
      }
    },
    [onScoreClick]
  );

  const scores = useMemo(() => {
    return domainScoresToScores(domainScores, color.gray.light, getColorForResult);
  }, [domainScores, color.gray.light, getColorForResult]);

  const getDescriptionArray = () => {
    if (hasResults) {
      return t('DOMAIN.REPORT.AVAILABLE.BODY.DESCRIPTION', { returnObjects: true });
    }

    if (!isPhased) {
      return t('DOMAIN.REPORT.UNAVAILABLE.REGULAR.BODY.DESCRIPTION', { returnObjects: true });
    }

    if (isNextPhaseAvailable) {
      return t('DOMAIN.REPORT.UNAVAILABLE.PHASED.NEW_PHASE_AVAILABLE.BODY.DESCRIPTION', {
        returnObjects: true
      });
    }

    return t('DOMAIN.REPORT.UNAVAILABLE.PHASED.NEW_PHASE_UNAVAILABLE.BODY.DESCRIPTION', {
      returnObjects: true
    });
  };

  return (
    <Block
      className={styles.ReportScoreUnavailable}
      innerClassName={styles.ReportScoreUnavailableInner}
      theme="gray"
    >
      <div className={cn(styles.Col, styles.ScoreCircle)}>
        <ScoreCircle
          scores={scores}
          total={
            exists<number>(happinessScore)
              ? {
                  total: happinessScore && MAX_HAPPINESS_SCORE,
                  current: Math.round(happinessScore)
                }
              : undefined
          }
          isDisabled={!scores || scores.length === 0}
          activeIdx={activeCircleIdx}
          color={getColorForResult(happinessResult)}
          onClick={handleScoreClick}
        />
      </div>

      <div className={cn(styles.Col, styles.Description)}>
        <Text.Large>
          {getDescriptionArray().map((item, idx) => {
            return <p key={idx}>{item}</p>;
          })}
        </Text.Large>
      </div>
    </Block>
  );
};
