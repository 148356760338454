import { ProgressBar } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './ProgressFixed.module.scss';
import { ProgressFixedProps } from './ProgressFixed.props';

export const ProgressFixed = ({
  className,
  total,
  current,
  ...progressBarProps
}: ProgressFixedProps) => {
  const { color } = usePlatform();

  return (
    <div className={styles.ProgressFixed}>
      <ProgressBar
        className={styles.ProgressBar}
        {...progressBarProps}
        total={total}
        isFilled
        isRounded={false}
        current={current}
        color={color.secondary.default}
      />
    </div>
  );
};
