import { MutableRefObject, useCallback, useLayoutEffect, useState } from 'react';

import { HTMLMeasurements } from '@Constants/interfaces';
import { getElementMeasurements } from '@Utils/ElementUtils';

import { useResizeObserver } from './useResizeObserver';

export const useListMeasurements = (
  listItems: HTMLElement[],
  parentRef?: MutableRefObject<HTMLElement | null>
) => {
  const [measurements, setMeasurements] = useState<HTMLMeasurements[]>([]);

  const updateMeasurements = useCallback(() => {
    const updatedMeasurements: HTMLMeasurements[] = listItems.map((element) => {
      return getElementMeasurements(element);
    });

    setMeasurements(updatedMeasurements);
  }, [listItems]);

  useResizeObserver(updateMeasurements, parentRef);

  useLayoutEffect(() => {
    updateMeasurements();
  }, [updateMeasurements]);

  return measurements;
};
