import { useTranslation } from 'react-i18next';

import { MenuRouteType } from '@Constants/interfaces';
import { usePersistSearchParam, usePlatform } from '@Hooks/common';

export const useMenuItemsUnauthenticated = (): MenuRouteType[] => {
  const { t } = useTranslation();
  const { strings } = usePlatform();

  const { websiteUrl } = strings;
  const { getPersistedSearchParam } = usePersistSearchParam('token');
  const persistedToken = getPersistedSearchParam();

  return [
    {
      href: `${websiteUrl}${persistedToken ? '/homepage-v2' : ''}`,
      label: t('COMMON.NAVIGATION.INSPIRATION.WELCOME')
    },
    {
      href: `${websiteUrl}${persistedToken ? '/over-ons-v2' : '/over-ons'}`,
      label: t('COMMON.NAVIGATION.INSPIRATION.ABOUT_US')
    },
    {
      href: `${websiteUrl}${persistedToken ? '/contact-v2' : '/contact'}`,
      label: t('COMMON.NAVIGATION.INSPIRATION.ASK_US')
    }
  ];
};
