import { stringify } from 'qs';

import { BASE64 } from '@Constants/regexp';

import { i18n } from './TranslationUtils';

export const StripSlashes = (str: string) => str;

export const markupToHtml = (markup: string) => {
  let string = markup.replace(/\*{2}([^*]*)\*{2}/g, '<strong>$1</strong>');
  string = string.replace(/\\n/g, '<br />');

  return string;
};

export const getRegExpResults = (regExp: RegExp, string: string) => {
  const results: string[][] = [];

  let result;

  while ((result = regExp.exec(string)) !== null) {
    const removeFullMatch = result.slice(1);

    results.push(removeFullMatch);
  }

  return results;
};

export const toLocaleString = (input: any) => {
  return input.toLocaleString(i18n.language);
};

export const toQueryString = (params: any): string => {
  return stringify(params, { arrayFormat: 'repeat' });
};

export const stripHtml = (str: string) => {
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const isBase64 = (str: string) => {
  return new RegExp(BASE64).test(str);
};
