import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Form, Text } from '@Components/ui';
import { SMS_CODE_LENGTH } from '@Constants/configs';
import { useApiError } from '@Hooks/common';
import { useUserVerifyNewEmail } from '@Hooks/user';
import { markupToHtml } from '@Utils/StringUtils';
import Yup from '@Utils/YupUtils';

import {
  UpdateEmailVerifyNewEmailStepProps,
  UpdateEmailVerifyNewEmailStepValues
} from './UpdateEmailVerifyNewEmailStep.props';

export const UpdateEmailVerifyNewEmailStep = ({
  smsToken,
  currentEmail,
  newEmail,
  onSuccess
}: UpdateEmailVerifyNewEmailStepProps) => {
  const { t } = useTranslation();
  const { handleError, error } = useApiError();

  const userVerifyNewEmailMutation = useUserVerifyNewEmail();

  const handleSubmit = useCallback(
    async (values: UpdateEmailVerifyNewEmailStepValues) => {
      try {
        await userVerifyNewEmailMutation.mutateAsync({
          currentEmail,
          newEmail,
          newEmailConfirmed: newEmail,
          smsToken,
          ...values
        });

        onSuccess(values);
      } catch (e) {
        handleError(e, 'EMAIL');
      }
    },
    [userVerifyNewEmailMutation, currentEmail, handleError, newEmail, onSuccess, smsToken]
  );

  const validationSchema = Yup.object().shape({
    token: Yup.string().length(SMS_CODE_LENGTH).required()
  });

  return (
    <Box mt={3}>
      <Form.Form<UpdateEmailVerifyNewEmailStepValues>
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Text.Large
          dangerousHtml={markupToHtml(
            t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_EMAIL.UPDATE_EMAIL.DESCRIPTION', {
              email: newEmail
            })
          )}
        />

        <Box mt={3}>
          <Form.Input.Code
            name="token"
            data-testid="tokenInput"
            label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.TOKEN.LABEL')}
            description={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.TOKEN.DESCRIPTION_MAIL')}
            options={{ type: 'number', pattern: '[0-9]*', inputMode: 'numeric' }}
          />
        </Box>

        <Form.Layout.Error error={error} />

        <Button type="submit" color="dark" icon="arrow-right" data-testid="submitButton">
          {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_EMAIL.UPDATE_EMAIL.SUBMIT_LABEL')}
        </Button>
      </Form.Form>
    </Box>
  );
};
