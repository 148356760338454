import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { onboardingService } from '@Services/Onboarding';

export const useOnboardingPreloadPersonaQuestionnaire = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery([onboardingService.getEndpoint(), 'persona', 'questionnaire'], () => {
      return onboardingService.getPersonaQuestionnaire();
    });
  }, [queryClient]);
};
