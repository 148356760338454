import { Document, Image, Link, Page, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { PdfText } from '@Documents/components';
import { usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';

import { DomainList } from '../DomainList/DomainList';
import { ProgressLegend } from '../ProgressLegend/ProgressLegend';
import { ReportDocumentProps } from './ReportDocument.props';
import { styles } from './ReportDocument.styles';

export const ReportDocument = ({ domains }: ReportDocumentProps) => {
  const { t } = useTranslation();
  const { assets } = usePlatform();

  return (
    <Document>
      <Page size="A4" style={styles.Page}>
        <View>
          <Image src={assets.logoPng} style={styles.Logo} fixed />

          <PdfText.H2 style={styles.Title}>{t('DOCUMENTS.REPORT.TITLE')}</PdfText.H2>

          <PdfText.Normal style={styles.Intro}>{t('DOCUMENTS.REPORT.INTRO')}</PdfText.Normal>

          <PdfText.Small style={styles.Description}>
            {t('DOCUMENTS.REPORT.DESCRIPTION')}
          </PdfText.Small>

          <ProgressLegend style={styles.ProgressLegend} />

          <Link src={`${window.location.origin}${AppRoutes.getFaq().to}`}>
            <PdfText.Normal>{t('DOCUMENTS.REPORT.FAQ')}</PdfText.Normal>
          </Link>

          <DomainList domains={domains} />
        </View>
        <PdfText.Normal style={styles.PageNumber} render={({ pageNumber }) => pageNumber} fixed />
      </Page>
    </Document>
  );
};
