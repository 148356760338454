import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SelfTestOpenBlock } from '@Components/container';
import { ListTodo } from '@Components/list';
import { LazyList, Text } from '@Components/ui';
import { useInfiniteData } from '@Hooks/common';
import { useOnboardingGetSelfTestQuestionnaire } from '@Hooks/onboarding';
import { useTodoList } from '@Hooks/todo';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';

import { TodoListProps } from './TodoListOpen.props';

export const TodoListOpen = ({ filter, onTodoLoad, onSelfTestLoad }: TodoListProps) => {
  const { t } = useTranslation();

  const {
    data: todoPages,
    hasNextPage: hasNextTodosPage,
    fetchNextPage: fetchNextTodosPage,
    isLoading: isTodosLoading,
    isFetched: isTodosFetched
  } = useTodoList(filter);

  const {
    data: questionnaireSelfTest,
    isLoading: isQuestionnaireSelfTestLoading,
    isFetched: isQuestionnaireSelfTestFetched
  } = useOnboardingGetSelfTestQuestionnaire();

  const isLoading = isTodosLoading || isQuestionnaireSelfTestLoading;
  const isFetched = isTodosFetched && isQuestionnaireSelfTestFetched;

  const todos = useInfiniteData(todoPages);

  const isSelfTestDone =
    questionnaireSelfTest &&
    questionnaireSelfTest.status === QuestionnaireSubmissionStatus.COMPLETED;

  const isEmpty = isSelfTestDone && todos && todos.length === 0;

  useEffect(() => {
    if (todoPages) {
      onTodoLoad(todoPages);
    }
  }, [todoPages, onTodoLoad]);

  useEffect(() => {
    if (questionnaireSelfTest) {
      onSelfTestLoad(questionnaireSelfTest);
    }
  }, [questionnaireSelfTest, onSelfTestLoad]);

  const handleFetchNextPage = useCallback(() => {
    if (hasNextTodosPage) {
      fetchNextTodosPage();
    }
  }, [fetchNextTodosPage, hasNextTodosPage]);

  if (isFetched && isEmpty) {
    return <Text.Large>{t('DOMAIN.TODO.OPEN.EMPTY')}</Text.Large>;
  }

  const getTotalItemCount = () => {
    const total = todos.length;

    if (!isSelfTestDone) {
      if (questionnaireSelfTest?.isPhased) {
        return (
          total +
          (questionnaireSelfTest?.totalPhases ?? 0) -
          (questionnaireSelfTest?.completedPhases ?? 0)
        );
      }

      return total + 1;
    }

    return total;
  };

  return (
    <LazyList
      hasNextPage={hasNextTodosPage}
      fetchNextPage={handleFetchNextPage}
      isFetched={isFetched}
      isLoading={isLoading}
      totalItems={getTotalItemCount()}
      loadingText={t('COMMON.GLOBALS.LOADING_MODEL', {
        name: t('DOMAIN.TODO.PLURAL')
      })}
    >
      {questionnaireSelfTest && todos && (
        <ListTodo todos={todos}>
          <SelfTestOpenBlock selfTest={questionnaireSelfTest} />
        </ListTodo>
      )}
    </LazyList>
  );
};
