import { IDomain, Result } from '@Constants/interfaces';
import { UserGetDomainsResponse } from '@Services/User';
import { IUserTheme } from '@Services/UserTheme';
import { exists } from '@Utils/CommonUtils';

export interface DomainGroup {
  domain: IDomain;
  themes: IUserTheme[];
}

export type DomainGroups = Record<string, DomainGroup>;

export const groupByDomain = (themes: IUserTheme[]) => {
  return themes.reduce((groups: DomainGroups, theme) => {
    const domainId = theme.theme.domain.id;
    const group = groups[domainId];

    if (!exists(group)) {
      const newDomainGroup = {
        domain: theme.theme.domain,
        themes: [theme]
      };
      groups[domainId] = newDomainGroup;
    } else {
      groups[domainId].themes.push(theme);
    }

    return groups;
  }, {});
};

export const orderByDomain = (
  groupedDomains: DomainGroups,
  userDomains: UserGetDomainsResponse
) => {
  const domainOrder = userDomains.domainScores.reduce((order: string[], userDomain) => {
    order.push(userDomain.domain.id);

    return order;
  }, []);

  return domainOrder.map((domainId) => {
    return groupedDomains[domainId];
  });
};

export const removeDomainsWithoutScore = (domains: DomainGroup[]) => {
  return domains.filter((domain) =>
    domain.themes.some((theme) => theme.themeResult !== Result.NONE)
  );
};
