import { MutableRefObject, useEffect } from 'react';

export const useMutationObserver = (
  callback: () => void,
  ref?: MutableRefObject<HTMLElement | null>
): void => {
  useEffect(() => {
    const mutationObserver = new MutationObserver(callback);

    if (ref && ref.current) {
      mutationObserver.observe(ref.current, { attributes: true, childList: true, subtree: true });
    } else {
      mutationObserver.observe(document.body, { attributes: true, childList: true, subtree: true });
    }

    return () => mutationObserver.disconnect();
  }, [callback, ref]);
};
