import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Modal } from '@Components/layout';
import { Anchor, Box, Button, ButtonIcon, Form, SpacingGroup } from '@Components/ui';
import { useApiError, useBoolean } from '@Hooks/common';
import { useUserActivityUpdate } from '@Hooks/useractivity';
import Yup from '@Utils/YupUtils';

import {
  UserActivityUpdateFormProps,
  UserActivityUpdateFormValues
} from './UserActivityUpdateForm.props';

export const UserActivityUpdateForm = ({
  id,
  fieldName,
  translationName,
  initialValue,
  onSuccess,
  className
}: UserActivityUpdateFormProps) => {
  const { t } = useTranslation();
  const userActivityUpdateMutation = useUserActivityUpdate();
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);
  const { handleError } = useApiError();

  const TRANSLATION = t(`DOMAIN.USER_ACTIVITY.FORM.${translationName}`, {
    returnObjects: true
  });

  const validationSchema = Yup.object().shape({
    value: Yup.string().required()
  });

  const initialValues = {
    value: initialValue
  };

  const handleSubmit = useCallback(
    async ({ value }: UserActivityUpdateFormValues) => {
      try {
        await userActivityUpdateMutation.mutateAsync({
          id: id,
          [fieldName]: value
        });

        toast.success(t('DOMAIN.USER_ACTIVITY.FORM.UPDATED'));

        setModalClosed();

        if (onSuccess) {
          onSuccess(id);
        }
      } catch (e) {
        handleError(e);
      }
    },
    [userActivityUpdateMutation, id, fieldName, t, setModalClosed, onSuccess, handleError]
  );

  return (
    <>
      <ButtonIcon onClick={setModalOpen} icon="pen" data-testid="updateToggle" />

      <Modal isOpen={isModalOpen} onClose={setModalClosed} title={TRANSLATION.TITLE}>
        <Form.Form<UserActivityUpdateFormValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          className={className}
          onSubmit={handleSubmit}
        >
          <Form.Input.Textarea
            name="value"
            data-testid="valueInput"
            options={{
              placeholder: TRANSLATION.PLACEHOLDER
            }}
          />

          <Box mt={3}>
            <SpacingGroup size="xxl">
              <Button
                icon="arrow-right"
                color="dark"
                isLoading={userActivityUpdateMutation.isLoading}
                data-testid="updateSubmit"
              >
                {t('DOMAIN.USER_ACTIVITY.FORM.SUBMIT')}
              </Button>

              <Anchor onClick={setModalClosed}>{t('DOMAIN.USER_ACTIVITY.FORM.CANCEL')}</Anchor>
            </SpacingGroup>
          </Box>
        </Form.Form>
      </Modal>
    </>
  );
};
