import { IBaseModel } from '@Constants/interfaces';

export enum QuestionnaireQuestionType {
  CAROUSEL = 'CAROUSEL',
  DROPDOWN = 'DROPDOWN',
  SLIDER = 'SLIDER',
  SLIDER_STEP = 'SLIDER_STEP',
  SLIDER_EMOJI = 'SLIDER_EMOJI',
  RADIO = 'RADIO'
}

export interface QuestionnaireSection {
  title: string;
  description: string | null;
  image: string;
  displayIntroduction?: boolean;
}

export interface IQuestionnaireAnswer {
  id: string;
  text: string;
  description: string | null;
  selected: boolean;
}

export interface IQuestionnaire {
  title: string;
  description: string;
}

export interface IQuestionnaireQuestion extends IBaseModel {
  title: string;
  description: string | null;
  headerTitle: string;
  headerDescription: string | null;
  minimumAnswers?: number | null;
  maximumAnswers?: number | null;
  scaleMinDescription?: string | null;
  scaleMaxDescription?: string | null;
  type: QuestionnaireQuestionType;
  answers?: IQuestionnaireAnswer[];
  answerText?: string;
  isAdaptive: boolean;
  editable: boolean;
}

export enum QuestionnaireThemeStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  OPEN = 'OPEN'
}

export enum QuestionnaireSubmissionStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_COMPLETION = 'PENDING_COMPLETION',
  COMPLETED = 'COMPLETED',
  PENDING_PHASE_COMPLETION = 'PENDING_PHASE_COMPLETION',
  PAUSED = 'PAUSED',
  DOMAIN_SELECTION = 'DOMAIN_SELECTION'
}

export interface QuestionnaireTheme {
  title: string;
  status: QuestionnaireThemeStatus;
}

export interface IQuestionnaireSubmission extends IBaseModel {
  currentQuestion: IQuestionnaireQuestion;
  questionnaire: IQuestionnaire;
  status: QuestionnaireSubmissionStatus;
  hasPreviousQuestion: boolean;
  showSectionIntro: boolean;
  currentRootSection: QuestionnaireSection;
  currentStep?: number;
  totalSteps?: number;
  isPhased: boolean;
  isAdaptive?: boolean;
}

export type QuestionnaireSubmissionSubmitPersonaResponse = IQuestionnaireSubmission;

export interface QuestionnairePhase {
  phase: number;
  availableAt?: string;
}

export enum QuestionnaireSubmissionSubmitSelfTestSkipType {
  Paused = 'Paused',
  Skipped = 'Skipped'
}
export interface QuestionnaireSubmissionSubmitSelfTestResponse extends IQuestionnaireSubmission {
  themes: QuestionnaireTheme[];
  progress: number;
  isPhased: boolean;
  totalPhases?: number;
  completedPhases?: number;
  allowedPhases?: number;
  nextPhaseAt?: string;
  skipType?: QuestionnaireSubmissionSubmitSelfTestSkipType;
  futurePhases?: QuestionnairePhase[];
}

export type QuestionnaireSubmissionSubmitResponse =
  | QuestionnaireSubmissionSubmitPersonaResponse
  | QuestionnaireSubmissionSubmitSelfTestResponse;

export interface QuestionnaireSubmissionSubmitRequest {
  questionnaireSubmission: IQuestionnaireSubmission;
  answers?: string[];
  answerText?: string | number;
}

export interface QuestionnaireSubmissionGoToPreviousRequest {
  questionnaireSubmission: IQuestionnaireSubmission;
}

export type QuestionnaireSubmissionGoToPreviousResponse =
  | QuestionnaireSubmissionSubmitPersonaResponse
  | QuestionnaireSubmissionSubmitSelfTestResponse;

export type QuestionnaireSubmissionFinishRequest = IQuestionnaireSubmission;

export type QuestionnaireSubmissionFinishPhaseRequest = IQuestionnaireSubmission;

export type QuestionnaireSubmissionFinishResponse = Omit<
  IQuestionnaireSubmission,
  'currentQuestion'
>;
export type QuestionnaireSubmissionFinishPhaseResponse = Omit<
  IQuestionnaireSubmission,
  'currentQuestion'
>;
