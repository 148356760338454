import { IconName } from '@fortawesome/fontawesome-common-types';
import cn from 'classnames';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Confetti, Icon, Image, Text, Tooltip } from '@Components/ui';
import { useBoolean, useExplosion, useHover } from '@Hooks/common';
import { sleep } from '@Utils/TimeoutUtils';

import styles from './Medal.module.scss';
import { MedalProps, MedalState } from './Medal.props';

export const Medal = ({
  title,
  description,
  state,
  icon,
  onOpen,
  hasConfetti,
  className
}: MedalProps) => {
  const { t } = useTranslation();
  const {
    value: isOpened,
    setTrue: setIsOpenedTrue,
    setValue: setIsOpened
  } = useBoolean(state === MedalState.CLAIMED);
  const { value: isLoading, setValue: setIsLoading } = useBoolean();

  const buttonRef = useRef(null);
  const tooltipRef = useRef(null);
  const { isHovering: showToolTip, handlers } = useHover();

  const isEnabled = state === MedalState.AVAILABLE;

  const {
    shouldExplode,
    hasExploded,
    explode,
    setValues: setExplosionValues
  } = useExplosion(isEnabled, setIsOpenedTrue);

  const canOpen = isEnabled && !isOpened && !isLoading && !shouldExplode;

  useEffect(() => {
    setIsOpened(state === MedalState.CLAIMED);
  }, [state, setIsOpened]);

  const explodeWithoutConfetti = useCallback(async () => {
    setExplosionValues(true, true);

    await sleep(500);

    setIsOpenedTrue();
  }, [setExplosionValues, setIsOpenedTrue]);

  const handleOpening = useCallback(async () => {
    if (onOpen && canOpen) {
      setIsLoading(true);

      await onOpen();

      setIsLoading(false);
    }

    if (hasConfetti) {
      explode();
    } else {
      explodeWithoutConfetti();
    }
  }, [onOpen, canOpen, hasConfetti, setIsLoading, explode, explodeWithoutConfetti]);

  const componentClasses = cn(
    styles.Medal,
    {
      [styles.isEnabled]: state === MedalState.AVAILABLE,
      [styles.isDisabled]: state === MedalState.DISABLED
    },
    className
  );

  const buttonClasses = cn(styles.Button, {
    [styles.isEnabled]: state === MedalState.AVAILABLE,
    [styles.isLoading]: isLoading,
    [styles.isClicked]: shouldExplode,
    [styles.isFull]: hasExploded
  });

  const labelClasses = cn(styles.Label, { [styles.isEnabled]: state === MedalState.AVAILABLE });
  const nameClasses = cn(styles.Name, { [styles.isEnabled]: state !== MedalState.AVAILABLE });

  const MemoizedConfetti = useMemo(() => {
    if (state === MedalState.AVAILABLE && hasConfetti) {
      return <Confetti targetRef={buttonRef} isExploding={shouldExplode} />;
    }

    return null;
  }, [shouldExplode, state, hasConfetti]);

  const getIcon = (): IconName => {
    if (isLoading) {
      return 'spinner';
    }

    if (MedalState.DISABLED) {
      return 'lock';
    }

    return 'unlock';
  };

  return (
    <div className={componentClasses} ref={tooltipRef} onClick={handleOpening} {...handlers}>
      <div ref={buttonRef} className={buttonClasses}>
        <Icon icon={['fas', getIcon()]} size="lg" className={styles.Icon} />
      </div>

      <div className={styles.Labels}>
        <Text.Small className={labelClasses}>{t('COMPONENTS.MEDAL.SHOW_MEDAL')}</Text.Small>
        <Text.Small className={nameClasses}>{title}</Text.Small>
      </div>

      {MemoizedConfetti}

      <div className={cn(styles.Overlay, { [styles.isOpen]: isOpened })}>
        {icon && (
          <div className={styles.Icon}>
            <Image src={icon} ratio="fill" />
          </div>
        )}

        {title && <Text.Small className={styles.Label}>{title}</Text.Small>}
      </div>

      {description && state !== MedalState.DISABLED && (
        <Tooltip targetRef={tooltipRef} label={description} isVisible={showToolTip} />
      )}
    </div>
  );
};
