import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@Components/layout';
import { Anchor, Box, Button, SpacingGroup, Text } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useUserDeleteAvatar } from '@Hooks/user';

import { DeleteAvatarModalProps } from './DeleteAvatarModal.props';

export const DeleteAvatarModal = ({ isOpen, onClose }: DeleteAvatarModalProps) => {
  const { t } = useTranslation();

  const userDeleteAvatarMutation = useUserDeleteAvatar();
  const { handleError } = useApiError();

  const handleDeleteAvatar = useCallback(async () => {
    try {
      await userDeleteAvatarMutation.mutateAsync();

      onClose();
    } catch (e) {
      handleError(e);
    }
  }, [handleError, userDeleteAvatarMutation, onClose]);

  return (
    <Modal
      title={t('DOMAIN.PROFILE.PROFILE_PICTURE.DELETE.TITLE')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box mb={3}>
        <Text.Large>{t('DOMAIN.PROFILE.PROFILE_PICTURE.DELETE.DESCRIPTION')}</Text.Large>
      </Box>

      <SpacingGroup size="xxl">
        <Button icon="long-arrow-right" color="dark" onClick={handleDeleteAvatar}>
          {t('DOMAIN.PROFILE.PROFILE_PICTURE.DELETE.CONFIRM')}
        </Button>

        <Anchor onClick={onClose}>{t('COMMON.GLOBALS.CANCEL')}</Anchor>
      </SpacingGroup>
    </Modal>
  );
};
