import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, Form } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useUser, useUserUpdateMe } from '@Hooks/user';
import Yup from '@Utils/YupUtils';

import styles from './ProfilePictureForm.module.scss';
import { ProfilePictureFormProps, ProfilePictureFormValues } from './ProfilePictureForm.props';

export const ProfilePictureForm = ({ onSuccess }: ProfilePictureFormProps) => {
  const { t } = useTranslation();
  const { firstName, lastName } = useUser();
  const [avatar, setAvatar] = useState('');

  const userUpdateMeMutation = useUserUpdateMe();
  const { handleError } = useApiError();

  const validationSchema = Yup.object().shape({
    avatar: Yup.string().required()
  });

  const handleSubmit = useCallback(
    async ({ avatar: newAvatar }: ProfilePictureFormValues) => {
      try {
        await userUpdateMeMutation.mutateAsync({
          firstName,
          lastName,
          avatar: newAvatar
        });

        toast.success(t('DOMAIN.PROFILE.PROFILE_PICTURE.SUCCESS'));

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, firstName, lastName, userUpdateMeMutation, t, onSuccess]
  );

  return (
    <Form.Form<ProfilePictureFormValues>
      validationSchema={validationSchema}
      className={styles.Form}
      onSubmit={handleSubmit}
    >
      <Form.Input.Image name="avatar" onChange={setAvatar} />

      <Button icon="arrow-right" className={styles.SubmitButton} isDisabled={!avatar}>
        {t('DOMAIN.PROFILE.PROFILE_PICTURE.CONFIRM')}
      </Button>
    </Form.Form>
  );
};
