import { Result } from '@Constants/interfaces';

export const getPercentageForResult = (result?: Result | null) => {
  if (result === Result.GREEN) {
    return 100;
  }

  if (result === Result.YELLOW) {
    return 75;
  }

  if (result === Result.ORANGE) {
    return 50;
  }

  if (result === Result.RED) {
    return 25;
  }

  return 0;
};
