export const getMilestoneIcon = (id: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const icon = require(`@Assets/icons/milestones/${id}.svg`).default;

    return icon;
  } catch (e) {
    return null;
  }
};
