import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@Components/layout';
import { Form } from '@Components/ui';
import { Anchor, Button, ButtonSort } from '@Components/ui';
import { SortDirection } from '@Constants/enums';
import { InputOption } from '@Constants/interfaces';
import { useBoolean } from '@Hooks/common';

import { getFormattedOptions } from './internal/utils';
import styles from './Sort.module.scss';
import { SortFormValues, SortProps } from './Sort.props';

export const Sort = ({ options, onChange, className, id = '' }: SortProps) => {
  const { t } = useTranslation();
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);

  const formattedOptions = useMemo((): InputOption[] => {
    return getFormattedOptions(options);
  }, [options]);

  useEffect(() => {
    if (onChange) {
      onChange(options[0].value, options[0].direction[0]);
    }
  }, [onChange, options]);

  const [activeItem, setActiveItem] = useState<{
    label: string;
    direction: SortDirection;
    key: string;
  }>({
    label: formattedOptions[0].description || '',
    direction: formattedOptions[0].value.split('___')[1] as SortDirection,
    key: formattedOptions[0].value
  });

  const handleSubmit = useCallback(
    (values: SortFormValues) => {
      if (values && values.sort) {
        const item = formattedOptions.find((option) => option.value === values.sort);

        if (item) {
          const [key, direction] = item.value.split('___') as [string, SortDirection];

          setActiveItem({
            label:
              formattedOptions.find((option) => option.value === values.sort)?.description || '',
            direction: direction,
            key: item.value
          });

          if (onChange) {
            onChange(key, direction);
          }
        }

        setModalClosed();
      }
    },
    [formattedOptions, setModalClosed, onChange]
  );

  const handleCancel = useCallback(() => {
    setModalClosed();
  }, [setModalClosed]);

  return (
    <>
      <ButtonSort
        label={activeItem.label}
        direction={activeItem.direction}
        onClick={setModalOpen}
        className={className}
        data-testid="toggleSort"
      />

      <Modal title={t('COMPONENTS.SORT.SORT_BY')} isOpen={isModalOpen} onClose={handleCancel}>
        <Form.Form<SortFormValues>
          initialValues={{
            sort: activeItem.key
          }}
          onSubmit={handleSubmit}
        >
          <Form.Input.Radio
            name="sort"
            data-testid="sortInput"
            options={{
              options: formattedOptions,
              id
            }}
          />

          <div className={styles.Buttons}>
            <Button type="submit" color="dark" data-testid="sortSubmit" className={styles.Accept}>
              {t('COMPONENTS.SORT.ACCEPT')}
            </Button>

            <Anchor onClick={handleCancel} className={styles.Cancel}>
              {t('COMPONENTS.SORT.CANCEL')}
            </Anchor>
          </div>
        </Form.Form>
      </Modal>
    </>
  );
};
