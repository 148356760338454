import { AccordionItemBase } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import { AccordionItemProps } from './AccordionItem.props';

export const AccordionItem = ({
  title,
  children,
  isOpen,
  isSmall,
  onToggle,
  className
}: AccordionItemProps) => {
  const { color } = usePlatform();

  const smallIcon = isOpen ? 'chevron-up' : 'chevron-down';
  const bigIcon = isOpen ? 'minus' : 'plus';

  return (
    <AccordionItemBase
      iconName={isSmall ? smallIcon : bigIcon}
      iconColor={color.gray.dark}
      title={title}
      isSmall={isSmall}
      isOpen={isOpen}
      onToggle={onToggle}
      className={className}
    >
      {children}
    </AccordionItemBase>
  );
};
