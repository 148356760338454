import cn from 'classnames';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

import { Icon, Tag, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import { Submenu } from './internal/components';
import styles from './MenuItem.module.scss';
import { MenuItemProps } from './MenuItem.props';

export const MenuItem = ({
  label,
  count,
  icon,
  tourdot,
  to = '',
  href,
  onClick,
  subItems,
  isCollapsed = false,
  isOpaque = false,
  hasTag = true,
  className,
  'data-testid': dataTestId
}: MenuItemProps) => {
  const { color } = usePlatform();
  const resolved = useResolvedPath(to);
  const isActive = useMatch({ path: resolved.pathname, end: to.length <= 1 });

  const classNames = cn(styles.MenuItem, className, {
    [styles.isActive]: to ? isActive : false,
    [styles.isCollapsed]: isCollapsed,
    [styles.isOpaque]: isOpaque,
    [styles.hasChildren]: !!subItems && subItems.length > 0,
    [styles.hasCount]: !!count
  });

  if (onClick) {
    return (
      <div className={classNames}>
        <div onClick={onClick} className={styles.MainLink} data-testid={dataTestId}>
          <Text.H5 className={styles.Label}>{label}</Text.H5>
        </div>
      </div>
    );
  }

  const getInner = () => {
    return (
      <>
        {!!icon && <Icon size="lg" icon={['fas', icon]} className={styles.Icon} />}

        {hasTag && (
          <Tag
            className={styles.IconCount}
            size="xs"
            label={String(count)}
            color={color.tertiary.default}
          />
        )}

        <Text.H5 className={styles.Label}>{label}</Text.H5>

        {hasTag && (
          <Tag
            className={styles.Count}
            size="xs"
            label={!!count ? String(count) : ''}
            color={color.tertiary.default}
          />
        )}

        {!!tourdot && <div data-tourdotid={tourdot} />}
      </>
    );
  };

  const getLinkElement = () => {
    return (
      <Link to={to} className={styles.MainLink} data-testid={dataTestId}>
        {getInner()}
      </Link>
    );
  };

  const getHrefElement = () => {
    return (
      <a href={href} className={styles.MainLink} data-testid={dataTestId} target="_blank">
        {getInner()}
      </a>
    );
  };

  return (
    <>
      <div className={classNames}>
        {!href && getLinkElement()}

        {!!href && getHrefElement()}

        {!!subItems && (
          <Submenu items={subItems} isCollapsed={isCollapsed} className={styles.Submenu} />
        )}
      </div>
    </>
  );
};
