import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Anchor, Button, Text } from '@Components/ui';
import { useBoolean, useMeasurements, usePlatform } from '@Hooks/common';
import { useUser } from '@Hooks/user';
import { sleep } from '@Utils/TimeoutUtils';

import { CLOSING_DURATION } from './internal/constants';
import styles from './Motivator.module.scss';
import { MotivatorProps } from './Motivator.props';

export const Motivator = ({ message, link, onClose, className }: MotivatorProps) => {
  const { motivator } = useUser();
  const navigate = useNavigate();
  const { value: isMotivatorOpen, setFalse: setMotivatorClosed } = useBoolean(true);
  const ref = useRef<HTMLDivElement | null>(null);
  const element = useMeasurements(ref.current);
  const [isLarge, setIsLarge] = useState(false);

  useEffect(() => {
    setIsLarge(element.clientWidth > 300);
  }, [element.clientWidth]);

  const { t } = useTranslation();
  const { easing } = usePlatform();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    setMotivatorClosed();
  }, [onClose, setMotivatorClosed]);

  const handleLink = useCallback(async () => {
    if (link.onClick) {
      link.onClick();
    }

    await sleep(CLOSING_DURATION);

    setMotivatorClosed();

    navigate(link.href);
  }, [link, navigate, setMotivatorClosed]);

  const componentClasses = cn(
    styles.Motivator,
    { [styles.isVertical]: !isLarge, [styles.isOpen]: isMotivatorOpen },
    className
  );

  return (
    <AnimateHeight height={isMotivatorOpen ? 'auto' : 0} duration={500} easing={easing.out}>
      <div className={componentClasses} ref={ref}>
        {!!motivator && <img src={motivator.avatar} className={styles.Image} />}

        <div className={styles.Content}>
          {motivator && <Text.Small className={styles.Name}>{motivator.name}</Text.Small>}

          <Text.Normal className={styles.Message}>{message}</Text.Normal>

          <div className={styles.Buttons}>
            {!!link && (
              <Button onClick={handleLink} icon="arrow-right" className={styles.Button}>
                {link.label}
              </Button>
            )}

            <Anchor onClick={handleClose} className={styles.Close} data-testid="closeButton">
              {t('COMMON.GLOBALS.ACCEPT')}
            </Anchor>
          </div>
        </div>
      </div>
    </AnimateHeight>
  );
};
