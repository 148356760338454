import cn from 'classnames';

import { Button, ProfilePicture, Text } from '@Components/ui';

import styles from './Author.module.scss';
import { AuthorProps } from './Author.props';

export const Author = ({
  name,
  image,
  description,
  buttonLabel,
  onClick,
  to,
  className
}: AuthorProps) => {
  return (
    <div className={cn(styles.Author, className)}>
      <ProfilePicture image={image} size={90} />

      <div className={styles.TextWrapper}>
        {!!name && <Text.H4 className={styles.Name}>{name}</Text.H4>}

        {!!description && <Text.Normal>{description}</Text.Normal>}
      </div>

      {!!buttonLabel && (onClick || to) && (
        <Button onClick={onClick} to={to} icon="arrow-right" color="dark" className={styles.Button}>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};
