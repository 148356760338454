import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PreferencesGgcForm, PreferencesGgcFormValues } from '@Components/form';
import {
  Box,
  FormMethods,
  Highlight,
  SliderFullPage,
  SliderNavigation,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';

export const PreferencesGgcSlide = () => {
  const formRef = useRef<FormMethods<PreferencesGgcFormValues>>();

  const { total } = useSliderFullPageContext();
  const { index, slideNext, slidePrev } = useSliderFullPageItemContext();

  const { t } = useTranslation();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        title={
          <>
            {t('DOMAIN.PREFERENCES.GGC.TITLE.PRE_HIGHLIGHT')}{' '}
            <Highlight>{t('DOMAIN.PREFERENCES.GGC.TITLE.HIGHLIGHT')}</Highlight>{' '}
            {t('DOMAIN.PREFERENCES.GGC.TITLE.POST_HIGHLIGHT')}
          </>
        }
        intro={t('DOMAIN.PREFERENCES.GGC.DESCRIPTION')}
        superTitle={t('DOMAIN.PREFERENCES.TITLE')}
      />

      <SliderFullPage.Block>
        <PreferencesGgcForm ref={formRef} onSuccess={slideNext}>
          <Box mt={5}>
            <SliderNavigation
              nextLabel={t('DOMAIN.PREFERENCES.NEXT_BUTTON')}
              onPrevious={slidePrev}
              total={total}
              current={index + 1}
            />
          </Box>
        </PreferencesGgcForm>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
