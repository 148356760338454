import cn from 'classnames';
import { useRef } from 'react';

import { Tooltip } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import { IconCircle } from '../IconCircle/IconCircle';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import styles from './EditableAvatar.module.scss';
import { EditableAvatarProps } from './EditableAvatar.props';

export const EditableAvatar = ({
  onClick,
  imageUri,
  tooltipText,
  className
}: EditableAvatarProps) => {
  const { color } = usePlatform();
  const iconRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={cn(styles.EditableAvatar, className)} onClick={onClick}>
      <ProfilePicture size={120} image={imageUri} className={styles.Avatar} />

      <IconCircle
        color={color.gray.light}
        className={styles.Icon}
        icon={imageUri ? 'times' : 'edit'}
        ref={iconRef}
        size="md"
        iconColor={color.gray.dark}
      />

      {tooltipText && <Tooltip label={tooltipText} targetRef={iconRef} />}
    </div>
  );
};
