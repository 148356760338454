import cn from 'classnames';
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ButtonIcon, Text } from '@Components/ui';
import { useLockedBody } from '@Hooks/common';

import styles from './Sideview.module.scss';
import { SideviewProps } from './Sideview.props';

export const Sideview = ({ title, children, isVisible, onClose, className }: SideviewProps) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  useLockedBody(isVisible);

  const classNames = cn(styles.Sideview, {
    [styles.isVisible]: isVisible
  });

  return ReactDOM.createPortal(
    <div ref={ref} className={classNames}>
      <div className={styles.Overlay} onClick={onClose} />

      <div className={cn(styles.Body, className)}>
        {onClose && (
          <ButtonIcon
            className={styles.Close}
            label={t('COMMON.GLOBALS.HIDE')}
            icon="chevron-left"
            onClick={onClose}
          />
        )}

        <Text.H2 className={styles.Title}>{title}</Text.H2>

        {children}
      </div>
    </div>,
    document.body
  );
};
