import { BaseService, dePortalenService } from '@Services/common';

import { ContactRequest } from './ContactService.props';

export class ContactService extends BaseService {
  contact = async (params: ContactRequest) => {
    await this.post<void>('', params);
  };
}

export const contactService = new ContactService(dePortalenService.getAxiosInstance(), '/contact');
