import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ListTodoItem } from '@Components/list';
import { Box, Loader, Text } from '@Components/ui';
import { useTodoList } from '@Hooks/todo';
import { AppRoutes } from '@Navigation/Routes';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import { DashboardTodoProps } from './DashboardTodo.props';

export const DashboardTodo = ({ className }: DashboardTodoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: todos,
    isSuccess: isTodosSuccess,
    isLoading: isTodosLoading,
    isError: isTodosError
  } = useTodoList({
    perPage: 5
  });

  const handleNavigate = useCallback(() => {
    navigate(AppRoutes.getTodo().to);
  }, [navigate]);

  const footerLink = {
    label: t('DOMAIN.DASHBOARD.BLOCKS.TODO.READ_MORE'),
    onClick: handleNavigate
  };

  return (
    <>
      <DashboardBlock
        widgetKey="TODO"
        footerLink={footerLink}
        className={className}
        data-tourdotid="TODO"
      >
        {isTodosSuccess && (
          <>
            {todos?.pages && todos.pages[0].results && todos.pages[0].results.length > 0 ? (
              <ListTodoItem todos={todos.pages[0].results} />
            ) : (
              <Text.Normal>{t('DOMAIN.DASHBOARD.BLOCKS.TODO.NONE')}</Text.Normal>
            )}
          </>
        )}

        {isTodosError && <Text.Error>{t('DOMAIN.DASHBOARD.BLOCKS.TODO.ERROR')}</Text.Error>}

        {isTodosLoading && (
          <Box my={8}>
            <Loader>{t('DOMAIN.DASHBOARD.BLOCKS.TODO.LOADING')}</Loader>
          </Box>
        )}
      </DashboardBlock>
    </>
  );
};
