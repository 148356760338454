import { BackButton } from '@Components/container';
import { Block, Box, Image, Spacer, Text } from '@Components/ui';
import { getImageSrc } from '@Utils/PreprUtils';

import styles from './ContentPageHeader.module.scss';
import { ContentPageHeaderProps } from './ContentPageHeader.props';

export const ContentPageHeader = ({ contentPage }: ContentPageHeaderProps) => {
  return (
    <div className={styles.ContentPageHeader}>
      <div className={styles.Header}>
        <div className={styles.Navigation}>
          <BackButton />
        </div>

        <Box mb={0.5}>
          <Text.H1 className={styles.Title}>{contentPage.title}</Text.H1>
        </Box>

        <Spacer top={1.5} bottom={1.5} />
      </div>

      {!!contentPage.banner && (
        <Block padding="none" isElevated>
          <Image src={getImageSrc(contentPage.banner)} />
        </Block>
      )}
    </div>
  );
};
