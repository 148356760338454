import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CardImage, Checkmark, SpacingGroup, Tag, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { useUser } from '@Hooks/user';
import { toLocaleString } from '@Utils/StringUtils';

import styles from './CardReward.module.scss';
import { CardRewardProps } from './CardReward.props';

export const CardReward = ({ reward, onClick, className }: CardRewardProps) => {
  const { t } = useTranslation();
  const { points } = useUser();
  const { color } = usePlatform();

  const remainingPoints = points - reward.points;
  const isEnabled = remainingPoints >= 0;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(reward);
    }
  }, [onClick, reward]);

  return (
    <CardImage
      title={reward.title}
      image={reward.thumbnail[0].cdnFiles[0].url}
      onClick={handleClick}
      className={cn(styles.CardReward, className)}
    >
      <SpacingGroup>
        {!!reward.claimedAt ? (
          <Checkmark label={t('DOMAIN.REWARD.REDEEMED')} />
        ) : (
          <>
            <Tag
              label={toLocaleString(reward.points)}
              color={isEnabled ? color.primary.default : color.gray.default}
              icon="star"
            />

            <Text.Micro className={styles.Label}>
              {t(`DOMAIN.REWARD.${isEnabled ? 'POINTS_LEFT' : 'POINTS_TOO_FEW'}`, {
                count: Math.abs(remainingPoints),
                points: toLocaleString(Math.abs(remainingPoints))
              })}
            </Text.Micro>
          </>
        )}
      </SpacingGroup>
    </CardImage>
  );
};
