import cn from 'classnames';
import { useCallback } from 'react';

import { Milestone } from '@Components/container';

import styles from './ListMilestone.module.scss';
import { ListMilestoneProps } from './ListMilestone.props';

export const ListMilestone = ({ milestones = [], className, refs }: ListMilestoneProps) => {
  const setRef = useCallback(
    (el: HTMLDivElement, idx: number) => {
      if (refs) {
        refs.current[idx] = el;
      }
    },
    [refs]
  );

  return (
    <div className={cn(styles.ListMilestone, className)}>
      {milestones.map((item, idx) => {
        return (
          <Milestone ref={(el: HTMLDivElement) => setRef(el, idx)} key={item.id} milestone={item} />
        );
      })}
    </div>
  );
};
