import cn from 'classnames';
import { endOfMonth, intervalToDuration, isSameDay } from 'date-fns';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@Components/ui';
import { getToday, timePassedToString } from '@Utils/DateUtils';

import styles from './TimelineChallengeActionTitle.module.scss';
import { TimelineChallengeActionTitleProps } from './TimelineChallengeActionTitle.props';

export const TimelineChallengeActionTitle = ({
  title,
  date,
  isMonthBased = false,
  className
}: TimelineChallengeActionTitleProps) => {
  const now = useRef(getToday());

  const { t } = useTranslation();

  const dateToCompare = isMonthBased ? endOfMonth(date) : date;
  const timePassed = intervalToDuration({ start: dateToCompare, end: now.current });

  const isThisMonth = isSameDay(endOfMonth(date), endOfMonth(now.current));

  if (isMonthBased && timePassed.days) {
    timePassed.months = (timePassed.months || 0) + 1;
  }

  return (
    <div className={cn(styles.TimelineChallengeActionTitle, className)}>
      <Text.Normal dangerousHtml={title} />

      <Text.Micro className={styles.Duration}>
        {isThisMonth && isMonthBased
          ? t('COMMON.GLOBALS.TIME_PASSED.THIS_MONTH')
          : timePassedToString(timePassed)}
      </Text.Micro>
    </div>
  );
};
