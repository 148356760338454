import { IUserTheme } from '@Services/UserTheme';
import { getDate } from '@Utils/DateUtils';
import { i18n } from '@Utils/TranslationUtils';

export const getChallengeInfoText = (theme: IUserTheme) => {
  let shouldReturnCompletedText = false;
  let shouldReturnStartedText = false;

  if (theme.lastChallengeCompleted && theme.lastChallengeStarted) {
    if (theme.activeChallenges > theme.completedChallenges) {
      shouldReturnStartedText = true;
    } else {
      shouldReturnCompletedText = true;
    }
  } else if (theme.lastChallengeStarted) {
    shouldReturnStartedText = true;
  } else if (theme.lastChallengeCompleted) {
    shouldReturnCompletedText = true;
  }

  if (shouldReturnStartedText) {
    const formattedDate = getDate(new Date(theme.lastChallengeStarted));

    return i18n.t('DOMAIN.THEME.STARTED_ON', {
      date: formattedDate,
      count: theme.activeChallenges
    });
  }

  if (shouldReturnCompletedText) {
    const formattedDate = getDate(new Date(theme.lastChallengeCompleted));

    return i18n.t('DOMAIN.THEME.STARTED_ON', {
      date: formattedDate,
      count: theme.completedChallenges
    });
  }

  return null;
};
