import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';

import styles from './Spinner.module.scss';
import { SpinnerProps } from './Spinner.props';

export const Spinner = forwardRef(
  ({ color, size = 40, className, ...props }: SpinnerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const dimension = `${size}px`;

    return (
      <div className={cn([styles.Spinner, className])} {...props} ref={ref}>
        <div
          style={{ width: dimension, height: dimension, borderWidth: size / 6 }}
          className={cn(styles.Loader, {
            [styles.isWhite]: color === 'white',
            [styles.isSecondary]: color === 'secondary',
            [styles.isDark]: color === 'dark'
          })}
        />
      </div>
    );
  }
);
