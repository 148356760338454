import { Text } from '@react-pdf/renderer';

import { PdfTextH2Props } from './PdfTextH2.props';
import { styles } from './PdfTextH2.styles';

export const H2 = ({ children, style, ...props }: PdfTextH2Props) => {
  return (
    <Text style={{ ...styles.H2, ...style }} {...props}>
      {children}
    </Text>
  );
};
