import { Platform } from './enums';
import { ARBONED } from './themes/ARBONED';
import { HUMAN_CAPITAL_CARE } from './themes/HUMAN_CAPITAL_CARE';
import { KOM_VERDER } from './themes/KOM_VERDER';
import { MENSELY } from './themes/MENSELY';

export interface Theme {
  background: ThemeBackground;
  color: ThemeColor;
  shadow: ThemeShadow;
  font: ThemeFont;
  radius: ThemeRadius;
  assets: ThemeAssets;
  strings: ThemeStrings;
  easing: ThemeEasing;
  spacing: ThemeSpacing;
}

export interface ThemeBackground {
  fade?: {
    default: string;
  };
  highlight?: {
    default: string;
    fullscreen: string;
  };
}

export interface ThemeAssets {
  [key: string]: any;
}

export interface ThemeStrings {
  [key: string]: any;
}

export interface ThemeColor {
  primary: Color;
  secondary: Color;
  tertiary: Color;
  background: Color;
  font: Color;
  frame: Color;

  error: Color;
  warning: Color;
  success: Color;
  info: Color;

  red: Color;
  orange: Color;
  yellow: Color;
  green: Color;

  gray: Color;
  white: Color;
  black: Color;
}

export interface Color {
  default: string;
  [key: string]: string;
}

export interface ThemeShadow {
  light: string;
  small: string;
  medium: string;
  mediumCentered: string;
  mediumLeft: string;
  large: string;
}

export interface ThemeFont {
  family: {
    base: string;
    heading: string;
    handwritten: string;
  };

  h1: Font;
  h2: Font;
  h3: Font;
  h4: Font;
  h5: Font;

  intro: Font;
  large: Font;
  normal: Font;
  medium: Font;
  bold: Font;
  small: Font;
  micro: Font;
  handwritten: Font;
}

export interface Font {
  color: string;
  fontFamily: string;
  fontWeight: string;
  fontSize: string;
  lineHeight: string;
}

export interface ThemeRadius {
  tag: string;
  tagExtraSmall: string;
  buttonGroup: string;
  alert: string;
  buttonIcon: string;
  inputRadio: string;
  inputText: string;
  inputLike: string;
  button: string;
  countNotifier: string;
  avatar: string;
  avatarSmall: string;
  toolTip: string;
  accordion: string;
  inboxItem: string;
  medal: string;
  dropdown: string;
  modal: string;
  block: string;
  blockElevated: string;
  counter: string;
  card: string;
  progressTheme: string;
  thumbnailTextRow: string;
  motivator: string;
  imageResize: string;
  selectActivityBlock: string;
  sidebarCta: string;
}

export interface ThemeEasing {
  in: string;
  out: string;
  inOut: string;
}

export interface ThemeSpacing {
  base: number;
  tagTransformY: string;
}

export const themes: Record<Platform, Theme> = {
  [Platform.KOM_VERDER]: KOM_VERDER,
  [Platform.ARBONED]: ARBONED,
  [Platform.HUMAN_CAPITAL_CARE]: HUMAN_CAPITAL_CARE,
  [Platform.MENSELY]: MENSELY
};
