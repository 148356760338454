import { useTranslation } from 'react-i18next';

import { AuthForgotPasswordForm } from '@Components/form';
import { SliderFullPage, useSliderFullPageItemContext } from '@Components/ui';

export const ForgotPasswordEmailSlide = () => {
  const { slideNext } = useSliderFullPageItemContext();

  const { t } = useTranslation();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        title={t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.EMAIL.HEADER.TITLE')}
        intro={t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.EMAIL.HEADER.DESCRIPTION')}
      />

      <SliderFullPage.Block title={t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.EMAIL.FORM.TITLE')}>
        <AuthForgotPasswordForm onSuccess={slideNext} />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
