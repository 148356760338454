import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { challengeService } from '@Services/Challenge';
import { userService } from '@Services/User';
import { userChallengeService, UserChallengeStopRequest } from '@Services/UserChallenge';
import { userThemeService } from '@Services/UserTheme';
import { trackMatomoChallengeCancel } from '@Utils/MatomoUtils';

export const useUserChallengeStop = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: UserChallengeStopRequest) => {
      return userChallengeService.stop(config);
    },
    {
      onSuccess: (_, { id, title, theme }) => {
        trackMatomoChallengeCancel(trackEvent, title, theme);

        queryClient.invalidateQueries([userChallengeService.getEndpoint(), id]);
        queryClient.invalidateQueries([userThemeService.getEndpoint()]);
        queryClient.invalidateQueries([challengeService.getEndpoint()]);
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
