import { useTranslation } from 'react-i18next';

import { ListProgressTheme } from '@Components/list';
import { Box, ScoreCircle, Text } from '@Components/ui';
import { MAX_PERCENT } from '@Constants/configs';
import { useResultColorMap } from '@Hooks/userTheme';
import { getPercentageForResult } from '@Utils/ResultUtils';

import styles from './DomainScore.module.scss';
import { DomainScoreProps } from './DomainScore.props';

export const DomainScore = ({ domainScore, themes }: DomainScoreProps) => {
  const { t } = useTranslation();
  const { getColorForResult } = useResultColorMap();

  const getValues = () => {
    return [
      {
        name: domainScore.domain.title,
        total: MAX_PERCENT,
        current: getPercentageForResult(domainScore.result),
        color: getColorForResult(domainScore.result)
      }
    ];
  };

  return (
    <div className={styles.DomainScore}>
      <Box mb={3}>
        <Text.Large>{domainScore.domain.description}</Text.Large>
      </Box>

      <div className={styles.ScoreCircle}>
        <ScoreCircle
          innerLabel={t(`DOMAIN.REPORT.DOMAINS.RESULTS.${domainScore.result}.DESCRIPTION` as any)}
          scores={getValues()}
          color={getColorForResult(domainScore.result)}
          isDisabled
        />
      </div>

      {themes.length > 0 && (
        <Box mt={5}>
          <Box mb={1} mx={3}>
            <Text.H4>{t('DOMAIN.REPORT.DOMAINS.THEMES_TITLE')}</Text.H4>
          </Box>

          <ListProgressTheme className={styles.Themes} hasLegend items={themes} />
        </Box>
      )}
    </div>
  );
};
