import { Route, Routes } from 'react-router-dom';

import { TourDots } from '@Components/container';
import { Page, PageLoader } from '@Components/layout';
import { UserContext } from '@Context/user';
import { useScrollToTop } from '@Hooks/common';
import { useOnboardingSelfTest } from '@Hooks/onboarding';
import { useUserMe } from '@Hooks/user';
import { AppStack, OnboardingStack } from '@Navigation/index';
import { hasCompletedAPhase } from '@Utils/QuestionnaireUtils';

export const PrivateStack = () => {
  useScrollToTop();

  const { data: user, isFetched: isUserFetched } = useUserMe();

  const { data: onboardingSelfTest } = useOnboardingSelfTest();

  const hasCompletedSelfTest = user?.selfTestCompleted || hasCompletedAPhase(onboardingSelfTest);
  /**
   * 1. If the user skipped- or completed the onboarding we want to enable the AppStack.
   * 2. If the user did not skipper- or completed the onboarding we enable the OnboardingStack.
   */
  const isOnboarding = !user?.skippedSelfTest && !hasCompletedSelfTest;

  if (user && isUserFetched) {
    return (
      <UserContext.Provider value={user}>
        <Page>
          <Routes>
            {!isOnboarding ? (
              <Route path="/*" element={<AppStack />} />
            ) : (
              <Route path="/*" element={<OnboardingStack />} />
            )}
          </Routes>
        </Page>

        <TourDots />
      </UserContext.Provider>
    );
  }

  return <PageLoader />;
};
