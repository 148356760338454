import cn from 'classnames';

import styles from './Handwritten.module.scss';
import { HandwrittenProps } from './Handwritten.props';

export const Handwritten = ({
  className,
  children,
  dangerousHtml,
  color,
  ...props
}: HandwrittenProps) => {
  if (!children && !dangerousHtml) return null;

  const classNames = cn(styles.Handwritten, className);

  const inlineStyle = {
    color
  };

  return (
    <p
      className={classNames}
      dangerouslySetInnerHTML={!!dangerousHtml ? { __html: dangerousHtml } : undefined}
      style={inlineStyle}
      {...props}
    >
      {children}
    </p>
  );
};
