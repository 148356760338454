import { IPreference, PreferenceKey } from '@Services/Preference';

import { i18n } from './TranslationUtils';

export const getPreference = (preferences: IPreference[], key: PreferenceKey) => {
  return preferences?.find((preference) => {
    return preference.key === key;
  })?.values;
};

export const getInputOptions = (enumValues: string[], domain: string) => {
  return enumValues.map((item) => {
    return {
      value: item,
      label: i18n.t(`DOMAIN.PREFERENCES.${domain}.FORM.FIELDS.${item}.LABEL`),
      description: i18n.t(`DOMAIN.PREFERENCES.${domain}.FORM.FIELDS.${item}.DESCRIPTION`)
    };
  });
};
