import { Navigate, Route, Routes } from 'react-router-dom';

import { ActivityItemScreen, ActivityOverviewScreen } from '@Screens/Activity';

import { AppRoutes } from './Routes';

export const ActivityStack = () => {
  return (
    <Routes>
      <Route path="" element={<ActivityOverviewScreen />} />

      <Route path={AppRoutes.getActivity('').path} element={<ActivityItemScreen />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
