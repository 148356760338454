export enum MedalState {
  DISABLED = 'DISABLED',
  AVAILABLE = 'AVAILABLE',
  CLAIMED = 'CLAIMED'
}

export interface MedalProps {
  title: string;
  description?: string;
  state: MedalState;
  icon: string;
  onOpen?: () => Promise<void>;
  hasConfetti?: boolean;
  className?: string;
}
