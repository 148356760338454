import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ChangeNameForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { Anchor, Button, SpacingGroup } from '@Components/ui';

import { ChangeNameModalProps } from './ChangeNameModal.props';

export const ChangeNameModal = ({ initialValues, isOpen, onClose }: ChangeNameModalProps) => {
  const { t } = useTranslation();

  const handleSuccess = useCallback(() => {
    toast.success(t('DOMAIN.PROFILE.CHANGE_NAME.MODAL.FORM.SUCCESS'));
    onClose();
  }, [onClose, t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('DOMAIN.PROFILE.CHANGE_NAME.MODAL.TITLE')}>
      <ChangeNameForm onSuccess={handleSuccess} initialValues={initialValues}>
        <SpacingGroup size="xxl">
          <Button color="dark" type="submit">
            {t('DOMAIN.PROFILE.CHANGE_NAME.MODAL.FORM.SUBMIT_LABEL')}
          </Button>

          <Anchor onClick={onClose}>
            {t('DOMAIN.PROFILE.CHANGE_NAME.MODAL.FORM.CANCEL_LABEL')}
          </Anchor>
        </SpacingGroup>
      </ChangeNameForm>
    </Modal>
  );
};
