import { useTranslation } from 'react-i18next';

import { ContactForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { Anchor, Block, Box, Text } from '@Components/ui';
import { useBoolean, usePlatform } from '@Hooks/common';
import { useUser } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';

import styles from './AppSupportHeader.module.scss';

export const AppSupportHeader = () => {
  const { t } = useTranslation();
  const { value: isPopupOpen, setFalse: setPopupClosed, setTrue: setPopupOpen } = useBoolean();
  const { color } = usePlatform();
  const { phoneNumber } = useUser();

  return (
    <div className={styles.AppSupportHeader}>
      <div className={styles.Content}>
        <Text.H1 className={styles.Title}>{t('DOMAIN.FAQ.SUPPORT.TITLE')}</Text.H1>

        <Text.Large>{t('DOMAIN.FAQ.SUPPORT.DESCRIPTION_1')}</Text.Large>

        <Box mt={2}>
          <Text.Large>
            {t('DOMAIN.FAQ.SUPPORT.DESCRIPTION_3')}{' '}
            <Anchor to={AppRoutes.getFaq().to}>{t('DOMAIN.FAQ.SUPPORT.DESCRIPTION_LINK')}</Anchor>
            {'.'}
          </Text.Large>
        </Box>
      </div>

      <Block isElevated className={styles.Block} innerClassName={styles.BlockInner}>
        <Text.H4 className={styles.Title}>{t('DOMAIN.FAQ.SUPPORT.CONTACT.TITLE')}</Text.H4>

        <Anchor
          className={styles.Link}
          onClick={setPopupOpen}
          iconColor={color.primary.default}
          icon="comment-alt"
        >
          {t('DOMAIN.FAQ.SUPPORT.CONTACT.CALLBACK')}
        </Anchor>

        <Anchor
          className={styles.Link}
          to={AppRoutes.getContentPage(t('COMMON.NAVIGATION.MY_COACHLINE.SLUG')).to}
          iconColor={color.primary.default}
          icon="heart"
        >
          {t('DOMAIN.FAQ.SUPPORT.CONTACT.COACHLINE')}
        </Anchor>

        <Anchor
          className={styles.Link}
          href={`mailto:${t('COMMON.GLOBALS.SUPPORT_EMAIL')}`}
          iconColor={color.primary.default}
          icon="envelope"
        >
          {t('COMMON.GLOBALS.SUPPORT_EMAIL')}
        </Anchor>
      </Block>

      <Modal
        isOpen={isPopupOpen}
        onClose={setPopupClosed}
        title={t('DOMAIN.FAQ.QUESTIONS.BUTTON_LABEL')}
      >
        <ContactForm hasCallMeBack phone={phoneNumber} onSuccess={setPopupClosed} />
      </Modal>
    </div>
  );
};
