import cn from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Block, StepIndicator, Text, Tooltip } from '@Components/ui';
import { useHover } from '@Hooks/common';
import { shortDate } from '@Utils/DateUtils';

import styles from './ProgressBlock.module.scss';
import { ProgressBlockProps } from './ProgressBlock.props';

export const ProgressBlock = ({
  progress,
  isSmall,
  className,
  isDisabled,
  disabledLabel,
  children
}: ProgressBlockProps) => {
  const { t } = useTranslation();
  const blockRef = useRef(null);
  const { isHovering, handlers } = useHover();

  const isAvailable = !progress.availableAt;

  const classNames = cn(styles.ProgressBlock, className, {
    [styles.isDisabled]: isDisabled,
    [styles.isSmall]: isSmall
  });

  return (
    <Block theme="gray" className={classNames} ref={blockRef} {...handlers}>
      <StepIndicator
        className={styles.Progress}
        total={progress.total}
        current={progress.current}
        isDisabled={isDisabled}
      />

      <Text.H4 className={styles.Title}>
        {t('COMPONENTS.PROGRESS_BLOCK.PHASED.TITLE', progress)}
      </Text.H4>

      <Text.Normal className={styles.Description}>
        {
          t('COMPONENTS.PROGRESS_BLOCK.PHASED.DESCRIPTION', {
            returnObjects: true
          })[progress.current - 1]
        }
      </Text.Normal>

      {children}

      {!isAvailable && progress.availableAt && (
        <Text.Normal className={styles.Available}>
          {t('COMPONENTS.PROGRESS_BLOCK.PHASED.AVAILABLE', {
            date: shortDate(progress.availableAt)
          })}
        </Text.Normal>
      )}

      {isDisabled && !!disabledLabel && (
        <Tooltip label={disabledLabel} targetRef={blockRef} isVisible={isHovering} />
      )}
    </Block>
  );
};
