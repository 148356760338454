import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UserActivityForm } from '@Components/form';
import { ButtonCancel, Container } from '@Components/layout';
import { Loader } from '@Components/ui';
import { useUserActivityOne } from '@Hooks/useractivity';
import { AppRoutes } from '@Navigation/Routes';
import { isConfigured } from '@Utils/ActivityUtils';

export const TodoUpdateScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isSuccess, isLoading, isError } = useUserActivityOne(id || '');

  const handleSuccess = useCallback(
    (_id: string) => {
      navigate(AppRoutes.getTodoUserActivity(_id).to);
    },
    [navigate]
  );

  useEffect(() => {
    if (isError) {
      toast.error(t('DOMAIN.USER_ACTIVITY.ERROR'));

      navigate(AppRoutes.getTodo().to);
    }
  }, [isError, navigate, t]);

  return (
    <>
      <Container cols={10}>
        {isLoading && <Loader>{t('DOMAIN.USER_ACTIVITY.LOADING')}</Loader>}

        {isSuccess && data && (
          <UserActivityForm
            id={data.id}
            onSuccess={handleSuccess}
            initialValues={{
              customTitle: data.title
            }}
          />
        )}
      </Container>

      <ButtonCancel
        to={
          data && isConfigured(data)
            ? AppRoutes.getTodoUserActivity(data.id).to
            : AppRoutes.getTodo().to
        }
      />
    </>
  );
};
