import cn from 'classnames';
import { useCallback, useRef } from 'react';
import AnimateHeight from 'react-animate-height';

import { ButtonIcon, Text, Tooltip } from '@Components/ui';
import { TargetAlignment, useHover, usePlatform } from '@Hooks/common';

import styles from './AccordionItemBase.module.scss';
import { AccordionItemBaseProps } from './AccordionItemBase.props';

export const AccordionItemBase = ({
  title,
  children,
  isOpen,
  isSmall,
  onToggle,
  iconName,
  iconColor,
  iconBackgroundColor,
  backgroundColor,
  borderColor,
  tooltipText,
  className,
  ...props
}: AccordionItemBaseProps) => {
  const iconRef = useRef<HTMLElement | null>(null);
  const { color, easing } = usePlatform();
  const { isHovering, handlers } = useHover();

  const classNames = cn(styles.AccordionItemBase, className, {
    [styles.isOpen]: isOpen,
    [styles.isSmall]: isSmall
  });

  const handleToggle = useCallback(() => {
    if (onToggle) {
      onToggle();
    }
  }, [onToggle]);

  const containerStyle = {
    backgroundColor,
    borderColor
  };

  return (
    <div className={classNames} style={containerStyle} {...handlers} {...props}>
      <div className={styles.Header} onClick={handleToggle}>
        <Text.H4 className={styles.Title} dangerousHtml={title} />

        <ButtonIcon
          icon={iconName}
          data-testid="toggleButton"
          iconColor={isHovering ? color.frame.default : iconColor}
          ref={iconRef}
          isHovering={isHovering}
          iconBackgroundColor={iconBackgroundColor}
        />

        {tooltipText && (
          <Tooltip
            alignment={TargetAlignment.LEFT}
            isVisible={isHovering}
            hasOffset
            targetRef={iconRef}
            label={tooltipText}
          />
        )}
      </div>

      <AnimateHeight height={isOpen ? 'auto' : 0} easing={easing.out}>
        <div className={styles.Body}>{children}</div>
      </AnimateHeight>
    </div>
  );
};
