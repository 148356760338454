import { InboxMessageLinkType } from '@Services/Inbox';
import { getRegExpResults, toQueryString } from '@Utils/StringUtils';
import { i18n } from '@Utils/TranslationUtils';

const getLinksFromString = (str: string): string[][] => {
  const linkRegex = new RegExp(/\[link type="([^"]+)" slug="([^"]+)"]([^\[]+)\[\/link\]/g);

  return getRegExpResults(linkRegex, str);
};

const getLinkPropsFromMatches = (matches: string[][]) => {
  return matches.map((link) => {
    const type = link[0] as InboxMessageLinkType;

    switch (type) {
      case InboxMessageLinkType.ACTIVITY:
        return {
          to: `/${i18n.t('COMMON.NAVIGATION.ACTIVITIES.SLUG')}/${link[1]}`,
          children: link[2]
        };
      case InboxMessageLinkType.CHALLENGE:
        return {
          to: `/?${toQueryString({
            challenge: link[1]
          })}`,
          children: link[2]
        };
    }
  });
};

const replaceLinkMatchesWithIndices = (str: string) => {
  let index = 0;

  return str.replace(/\[link type="[^"]+" slug="[^"]+"][^\[]+\[\/link\]/g, () => {
    return `${index++}`;
  });
};

const splitAtIndices = (str: string) => {
  return str.split(/([0-9])/);
};

export const formatInboxMessage = (message: string) => {
  const links = getLinksFromString(message);

  if (links.length === 0) {
    return [message];
  }

  const linksProps = getLinkPropsFromMatches(links);

  const splitMessage = splitAtIndices(replaceLinkMatchesWithIndices(message));

  const formattedArray = splitMessage.map((part) => {
    const linkIndex = parseInt(part);

    if (!isNaN(linkIndex)) {
      return linksProps[linkIndex];
    }

    return part;
  });

  return formattedArray;
};
