import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ListActivityItemLarge } from '@Components/list';
import { Loader, Text } from '@Components/ui';
import { useActivityList } from '@Hooks/activity';

import styles from './RewardSideviewActivities.module.scss';
import { RewardSideviewActivitiesProps } from './RewardSideviewActivities.props';

export const RewardSideviewActivities = ({ reward, className }: RewardSideviewActivitiesProps) => {
  const { t } = useTranslation();

  const {
    data: activities,
    isLoading: isLoadingActivities,
    isError: isErrorActivities
  } = useActivityList({
    perPage: 2,
    key: reward.id,
    random: true,
    excludeCompleted: true
  });

  return (
    <div className={cn(styles.RewardSideviewActivities, className)}>
      <Text.H3 className={styles.Title}>{t('DOMAIN.REWARD.SIDEVIEW.ACTIVITIES.TITLE')}</Text.H3>

      <Text.Large className={styles.Description}>
        {t('DOMAIN.REWARD.SIDEVIEW.ACTIVITIES.DESCRIPTION')}
      </Text.Large>

      {activities?.pages[0].results && !isLoadingActivities && (
        <ListActivityItemLarge activities={activities?.pages[0].results} />
      )}

      {isErrorActivities && <Text.Error>{t('DOMAIN.REWARD.SIDEVIEW.ACTIVITIES.ERROR')}</Text.Error>}

      {isLoadingActivities && <Loader>{t('DOMAIN.REWARD.SIDEVIEW.ACTIVITIES.LOADING')}</Loader>}
    </div>
  );
};
