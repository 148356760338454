import { useTranslation } from 'react-i18next';

import { Box, Button, ProgressBlock } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';
import { exists } from '@Utils/CommonUtils';

import { SelfTestPhasedOpenBlockProps } from './SelfTestPhasedOpenBlock.props';

export const SelfTestPhasedOpenBlock = ({ selfTest }: SelfTestPhasedOpenBlockProps) => {
  const { t } = useTranslation();

  const { completedPhases, totalPhases } = selfTest;

  if (selfTest.futurePhases && exists<number>(completedPhases) && exists<number>(totalPhases)) {
    const getButtonLabel = (stepNumber: number, isFirstItem: boolean) => {
      if (
        !isFirstItem ||
        [QuestionnaireSubmissionStatus.OPEN, QuestionnaireSubmissionStatus.PAUSED].includes(
          selfTest.status
        )
      ) {
        return t('DOMAIN.TODO.SELF_TEST.PHASED.BUTTON_OPEN', {
          current: stepNumber
        });
      }

      return t('DOMAIN.TODO.SELF_TEST.PHASED.BUTTON_IN_PROGRESS', {
        current: stepNumber
      });
    };

    return (
      <>
        {selfTest.futurePhases.map((item, idx) => {
          const isFirstItem = idx === 0;

          return (
            <ProgressBlock
              isDisabled={!isFirstItem}
              disabledLabel={
                !!item.availableAt ? undefined : t('DOMAIN.TODO.SELF_TEST.PHASED.NOT_FIRST')
              }
              key={idx}
              progress={{
                total: totalPhases,
                current: item.phase,
                availableAt: item.availableAt ? new Date(item.availableAt) : undefined
              }}
            >
              <Box mt={1.5}>
                <Button
                  isDisabled={!!item.availableAt || !isFirstItem}
                  icon="arrow-right"
                  to={AppRoutes.getSelfTest().to}
                >
                  {getButtonLabel(item.phase, isFirstItem)}
                </Button>
              </Box>
            </ProgressBlock>
          );
        })}
      </>
    );
  }

  return null;
};
