import cn from 'classnames';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { Block, Image } from '@Components/ui';

import styles from './BlockImage.module.scss';
import { BlockImageProps } from './BlockImage.props';

export const BlockImage = forwardRef(
  ({ image, to, children, className }: BlockImageProps, ref: any) => {
    const getImage = () => {
      if (to) {
        return (
          <Link to={to} className={styles.Image}>
            <Image src={image} ratio="fill" />
          </Link>
        );
      }

      return (
        <div className={styles.Image}>
          <Image src={image} ratio="fill" />
        </div>
      );
    };

    return (
      <Block
        ref={ref}
        padding="none"
        isElevated
        className={cn(styles.BlockImage, className)}
        innerClassName={styles.Body}
      >
        {getImage()}
        <div className={styles.Content}>{children}</div>
      </Block>
    );
  }
);
