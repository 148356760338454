import cn from 'classnames';
import { ChangeEvent, forwardRef, useCallback } from 'react';

import { Icon, Text } from '@Components/ui';

import styles from './InputToggle.module.scss';
import { InputToggleProps } from './InputToggle.props';

export const InputToggle = forwardRef(
  (
    {
      options,
      parent,
      onChange,
      onBlur,
      name,
      value,
      isDisabled,
      className,
      isError,
      isValid
    }: InputToggleProps,
    ref?: any
  ) => {
    const classNames = cn(styles.InputToggle, className, {
      [styles.isError]: isError,
      [styles.isValid]: isValid
    });

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const newValues = [...(value ?? [])];
        const updatedValue = e.target.value;

        if (!newValues.includes(updatedValue)) {
          newValues.push(updatedValue);
        } else {
          newValues.splice(newValues.indexOf(updatedValue), 1);
        }

        if (onChange) {
          onChange(newValues);
        }

        if (onBlur) {
          onBlur();
        }
      },
      [value, onBlur, onChange]
    );

    return (
      <div className={classNames} ref={ref}>
        {options.map((option, idx) => {
          const isActive = value?.includes(option.value);
          return (
            <div
              key={option.value}
              className={cn(styles.Item, {
                [styles.isIndented]: !!parent
              })}
            >
              <label
                className={cn(styles.Input, {
                  [styles.isChecked]: isActive,
                  [styles.isDisabled]: isDisabled || option.disabled
                })}
              >
                <input
                  id={`InputToggle-${name}-${idx}`}
                  className={styles.Checkbox}
                  checked={!option.disabled && isActive}
                  type="checkbox"
                  disabled={isDisabled || option.disabled}
                  onBlur={onBlur}
                  onChange={handleChange}
                  value={option.value}
                />

                <div className={styles.Content}>
                  <Text.Large className={styles.Label}>{option.label}</Text.Large>

                  {option.description && (
                    <Text.Small className={styles.Label}>{option.description}</Text.Small>
                  )}
                </div>

                <div className={styles.Toggler}>
                  <div className={styles.TogglerIcon}>
                    <Icon size="sm" icon={['fas', isActive ? 'check' : 'times']} />
                  </div>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    );
  }
);
