import cn from 'classnames';

import { FormField, InputPassword } from '@Components/ui';

import styles from './FormPassword.module.scss';
import { FormPasswordProps } from './FormPassword.props';

export const FormPassword = ({ options, className, ...form }: FormPasswordProps) => {
  const classNames = cn(styles.FormPassword, className);

  return (
    <FormField {...form} className={classNames}>
      <InputPassword {...options} />
    </FormField>
  );
};
