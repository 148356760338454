import cn from 'classnames';

import { Icon } from '@Components/ui';

import styles from './InputSliderTrack.module.scss';
import { InputSliderTrackProps } from './InputSliderTrack.props';

export const InputSliderTrack = ({ marks, isCenter, isError, isValid }: InputSliderTrackProps) => {
  const classNames = cn(styles.InputSliderTrack, {
    [styles.isCenter]: isCenter,
    [styles.isError]: isError,
    [styles.isValid]: isValid
  });

  if (marks) {
    return (
      <div className={classNames}>
        {[...Array(marks?.length || 0)].map((_, idx) => (
          <div key={idx} className={styles.Checkmark} data-testid="checkMark">
            <Icon icon={['fas', 'check']} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={classNames}>
      {isCenter && (
        <div className={styles.Checkmark}>
          <Icon icon={['fas', 'check']} />
        </div>
      )}

      <div className={styles.Circle} />

      <div className={styles.Checkmark}>
        <Icon icon={['fas', 'check']} />
      </div>
    </div>
  );
};
