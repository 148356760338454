import cn from 'classnames';
import { useRef } from 'react';

import { ScrollbarState } from '../../enums';
import { useScrollbarCalculations } from '../../hooks';
import styles from './Scrollbar.module.scss';
import { ScrollbarProps } from './Scrollbar.props';

export const Scrollbar = ({ currentMiddle, isDisabled = false, className }: ScrollbarProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const dotSize = 16;

  const { currentState, componentHeight, scrollOffset } = useScrollbarCalculations(
    ref,
    currentMiddle
  );

  const getCurrentOffset = () => {
    if (currentState === ScrollbarState.ABOVE_MIDDLE) {
      return componentHeight;
    }

    if (currentState === ScrollbarState.CURRENT) {
      return scrollOffset;
    }

    return 0;
  };

  return (
    <div
      ref={ref}
      className={cn(
        styles.Scrollbar,
        { [styles.isDisabled]: isDisabled, [styles.isSelected]: getCurrentOffset() > 0 },
        className
      )}
    >
      <div className={styles.Rail} />

      <div className={styles.ProgressWrapper}>
        <div
          className={styles.Progress}
          style={{
            transform: `translate(-50%, -50%) scale(1, ${getCurrentOffset() / componentHeight})`
          }}
        />
      </div>

      <div
        className={styles.DotWrapper}
        style={{ transform: `translate(-50%, ${getCurrentOffset() - dotSize / 2}px)` }}
      >
        <div
          className={cn(styles.Dot, {
            [styles.isVisible]: currentState === ScrollbarState.CURRENT
          })}
        />
      </div>
    </div>
  );
};
