import cn from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@Components/layout';
import { SliderHighlightedChallenge } from '@Components/list';
import { Box, Loader, StartChallengeBlock, Text } from '@Components/ui';
import { REQUIRED_ACTIVITY_AMOUNT } from '@Constants/configs';
import { useUserChallengeList } from '@Hooks/userChallenge';
import { getCompletedActivitiesCount } from '@Utils/ChallengeUtils';

import { DashboardIntroText } from '../DashboardIntroText/DashboardIntroText';
import styles from './DashboardActiveChallenges.module.scss';
import { DashboardActiveChallengesProps } from './DashboardActiveChallenges.props';

export const DashboardActiveChallenges = ({ className }: DashboardActiveChallengesProps) => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const {
    data: userChallenges,
    isLoading: isUserChallengesLoading,
    isError: isUserChallengesError
  } = useUserChallengeList();

  const handleSlide = useCallback((_index: number) => {
    setIndex(_index);
  }, []);

  const activeChallenge = userChallenges?.results?.[index];

  const completedActivitiesCount = activeChallenge
    ? getCompletedActivitiesCount(activeChallenge)
    : 0;

  return (
    <Container cols={6} className={cn(styles.DashboardActiveChallenges, className)}>
      <DashboardIntroText
        title={t('DOMAIN.DASHBOARD.ACTIVE_CHALLENGES.TITLE')}
        subtitle={t('DOMAIN.DASHBOARD.ACTIVE_CHALLENGES.SUBTITLE')}
        total={REQUIRED_ACTIVITY_AMOUNT}
        current={completedActivitiesCount}
        hasCallToAction={!!userChallenges}
      />

      {userChallenges && userChallenges.results && userChallenges.results.length > 0 && (
        <SliderHighlightedChallenge
          onChange={handleSlide}
          challenges={userChallenges.results}
          className={styles.ChallengeSlider}
        />
      )}

      {userChallenges && userChallenges.results && userChallenges.results.length === 0 && (
        <StartChallengeBlock
          title={t('DOMAIN.DASHBOARD.ACTIVE_CHALLENGES.EMPTY.TITLE')}
          description={t('DOMAIN.DASHBOARD.ACTIVE_CHALLENGES.EMPTY.DESCRIPTION')}
          button={t('DOMAIN.DASHBOARD.ACTIVE_CHALLENGES.EMPTY.BUTTON')}
          className={styles.ChallengeStart}
        />
      )}

      {isUserChallengesError && (
        <Text.Error>{t('DOMAIN.DASHBOARD.ACTIVE_CHALLENGES.ERROR')}</Text.Error>
      )}

      {isUserChallengesLoading && (
        <Box my={4}>
          <Loader />
        </Box>
      )}
    </Container>
  );
};
