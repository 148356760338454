import { Font as PdfFont } from '@react-pdf/renderer';

import appleTouchIconKom from '@Assets/images/apple-touch-icon-kom.png';
import faviconKomIco from '@Assets/images/favicon-kom.ico';
import logoKomPng from '@Assets/images/logo-kom.png';
import logoKomFooter from '@Assets/images/logo-kom-footer.svg';
import { Theme } from '@Constants/themes';

export const KOM_VERDER: Theme = {
  background: {
    fade: {
      default: 'linear-gradient(to bottom, #fff, #fbf9f5)'
    },
    highlight: {
      default:
        'radial-gradient(circle at 16% 42%, #28c2a6, #f4ffad 39%, rgba(40, 194, 166, 0) 82%)',
      fullscreen:
        'radial-gradient(circle at 16% 42%, #28c2a6, #f4ffad 34%, rgba(40, 194, 166, 0) 72%)'
    }
  },

  color: {
    primary: {
      light: '#b298f2',
      default: '#6631e5'
    },
    secondary: {
      light: '#65ffb2',
      default: '#00cb66',
      dark: '#19a05d',
      opaque: '#00cb6630'
    },
    tertiary: {
      default: '#f3a71d',
      dark: '#e89a0c',
      color: '#fff',
      opaque: '#f3a71d30'
    },
    background: {
      default: '#fbf9f5',
      dark: '#f8f3e7'
    },
    font: {
      light: '#354264',
      default: '#21293e',
      transparant: 'rgba(33,41,62,.64)'
    },

    frame: { default: '#fff' },

    gray: {
      default: '#dcdcdc',
      dark: '#b5b8bc',
      light: '#f0f0f0',
      background: '#fafafa'
    },
    white: {
      default: '#fff',
      transparant: 'rgba(255, 255, 255, 0.24)'
    },
    black: {
      default: '#21293e'
    },

    red: { default: '#e61818' },
    orange: { default: '#ff9300' },
    yellow: { default: '#ffe066' },
    green: { default: '#47c869', light: '#7aec98' },

    error: { default: '#e61818', light: '#fef6f6', transparant: '#e61818bb' },
    warning: { default: '#ff9300' },
    success: { default: '#47c869', light: '#e9f8ed' },
    info: { default: '#0fb6dd' }
  },

  shadow: {
    light: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
    small: '0 2px 4px 0 rgba(0, 0, 0, 0.24)',
    medium: '10px 7px 16px 0 rgba(0, 0, 0, 0.19)',
    mediumCentered: '0 7px 16px 0 rgba(0, 0, 0, 0.19)',
    mediumLeft: '-10px 0 16px 0 rgba(0, 0, 0, 0.19)',
    large: '16px 32px 40px 0 rgba(0, 0, 0, 0.16)'
  },

  font: {
    family: {
      base: 'filson-pro',
      heading: 'filson-pro',
      handwritten: 'Caveat'
    },

    h1: {
      color: '#6631e5',
      fontFamily: 'filson-pro',
      fontWeight: '900',
      fontSize: '42px',
      lineHeight: '1.14'
    },

    h2: {
      color: '#6631e5',
      fontFamily: 'filson-pro',
      fontWeight: '900',
      fontSize: '28px',
      lineHeight: '1.14'
    },

    h3: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '1.33'
    },

    h4: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '1.19'
    },

    h5: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '1.33'
    },

    intro: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '1.71'
    },

    large: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '1.71'
    },

    normal: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    medium: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    bold: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    small: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '1.6'
    },

    micro: {
      color: '#21293e',
      fontFamily: 'filson-pro',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '1'
    },

    handwritten: {
      color: '#00cb66',
      fontFamily: 'Caveat',
      fontWeight: '600',
      fontSize: '25px',
      lineHeight: '0.96'
    }
  },

  radius: {
    tag: '100px',
    tagExtraSmall: '100px',
    buttonGroup: '100px',
    alert: '16px',
    buttonIcon: '100px',
    inputRadio: '24px',
    inputText: '2px',
    inputLike: '8px',
    counter: '4.6px',
    countNotifier: '100px',
    toolTip: '3px',
    button: '20px',
    avatar: '22px',
    avatarSmall: '14px',
    medal: '8px',
    dropdown: '8px',
    inboxItem: '8px',
    motivator: '8px',
    accordion: '8px',
    modal: '8px',
    block: '8px',
    blockElevated: '32px 32px 0 32px',
    card: '3px',
    progressTheme: '24px',
    imageResize: '8px',
    thumbnailTextRow: '3px',
    selectActivityBlock: '8px',
    sidebarCta: '4px'
  },

  assets: {
    logo: require('@Assets/images/logo-kom.svg'),
    logoPng: logoKomPng,
    logoFooter: logoKomFooter,
    faviconIco: faviconKomIco,
    appleTouchIcon: appleTouchIconKom,
    icon: require('@Assets/images/logo-small-kom.svg')
  },

  strings: {
    phone: '06 8349 3748',
    mail: 'support@kom-verder.nl',
    address: 'Science Park Eindhoven 5127',
    postcalCode: '5692 ED Son',
    websiteUrl: 'https://kom-verder.nl/',
    privacyUrl: 'https://kom-verder.nl/privacy-statement/',
    privacyUrlAlternative: 'https://kom-verder.nl/privacy-statement-v2/',
    personalUrl: 'https://kom-verder.nl/gegevensverwerking/',
    personalUrlAlternative: 'https://kom-verder.nl/gegevensverwerking-v2/',
    termsUrl: 'https://kom-verder.nl/gebruiksvoorwaarden/',
    termsUrlAlternative: 'https://kom-verder.nl/gebruiksvoorwaarden-v2/'
  },

  easing: {
    out: 'cubic-bezier(0.2, 0.4, 0, 1)',
    in: 'cubic-bezier(1, 0, 0.8, 0.6)',
    inOut: 'cubic-bezier(0.66, 0.07, 0.33, 0.94)'
  },

  spacing: {
    base: 8,
    tagTransformY: '1px'
  }
};

PdfFont.register({
  family: 'filson-pro',
  fonts: [
    {
      src: 'https://use.typekit.net/af/d2ca68/0000000000000000000157a5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3',
      fontWeight: 'black',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/c12453/0000000000000000000157bf/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/fcca1c/0000000000000000000157c8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3',
      fontWeight: 'medium',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/19c742/0000000000000000000157c9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      fontWeight: 'normal',
      fontStyle: 'normal'
    }
  ]
});
