import { useContext } from 'react';

import { AppError } from '@Constants/enums';
import { UserContext } from '@Context/user/UserContext';
import { setMatomoUser } from '@Utils/MatomoUtils';
import { setSentryUser } from '@Utils/SentryUtils';

export const useUser = () => {
  const contextValue = useContext(UserContext);

  if (!contextValue) {
    throw new Error(AppError.NO_CONTEXT_SET);
  }

  setMatomoUser(contextValue);
  setSentryUser(contextValue);

  return contextValue;
};
