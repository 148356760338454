import { IconName } from '@fortawesome/fontawesome-common-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ThumbnailTextRow } from '@Components/ui';
import { getActivityTo, isUserActivity } from '@Utils/ActivityUtils';
import { getImageSrc } from '@Utils/PreprUtils';

import styles from './ActivityItem.module.scss';
import { ActivityItemProps } from './ActivityItem.props';

export const ActivityItem = ({ hasImage, activity, className }: ActivityItemProps) => {
  const { t } = useTranslation();

  const title = isUserActivity(activity)
    ? t('DOMAIN.USER_ACTIVITY.TITLE', { title: activity.title })
    : activity.title;

  return (
    <ThumbnailTextRow
      className={cn(styles.ActivityItem, className)}
      title={title}
      image={hasImage ? getImageSrc(activity.thumbnail) : undefined}
      hasPlaceholder={hasImage}
      to={getActivityTo(activity)}
      imageIcon={activity.type?.[0].icon as IconName}
      isCompleted={activity.completed}
    />
  );
};
