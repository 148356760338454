import { BaseService, dePortalenService } from '@Services/common';

import { PollCurrentResponse, PollVoteRequest } from './PollService.props';

export class PollService extends BaseService {
  current = async () => {
    const response = await this.get<PollCurrentResponse>();

    return response.data;
  };

  vote = async (params: PollVoteRequest) => {
    const response = await this.post<void>('/submit', params);

    return response.data;
  };
}

export const pollService = new PollService(dePortalenService.getAxiosInstance(), '/polls');
