import { useEffect } from 'react';

import { trackView } from '@Utils/PreprUtils';

export const usePreprTrackView = (id?: string) => {
  useEffect(() => {
    if (id) {
      trackView(id);
    }
  }, [id]);
};
