import cn from 'classnames';
import { MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { PinState } from '@Constants/enums';
import { useHover, usePlatform } from '@Hooks/common';

import { ButtonPin, Icon, Tag, Text, Thumbnail } from '..';
import styles from './ThumbnailTextRow.module.scss';
import { ThumbnailTextRowProps } from './ThumbnailTextRow.props';

export const ThumbnailTextRow = ({
  title,
  image,
  imageIcon,
  description,
  children,
  pinState = PinState.UNPINNED,
  isCompleted,
  hasPlaceholder,
  isLarge = false,
  onPinClick,
  canClickToPin = false,
  to,
  className,
  ...props
}: ThumbnailTextRowProps) => {
  const { color } = usePlatform();

  const { isHovering, handlers } = useHover();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!!onPinClick) {
        onPinClick();
      }
    },
    [onPinClick]
  );

  const BodyColumn = () => {
    return (
      <div className={styles.Body}>
        {isLarge ? (
          <Text.H4 className={styles.Title}>{title}</Text.H4>
        ) : (
          <Text.H5 className={styles.Title}>{title}</Text.H5>
        )}

        {!!children && <div className={styles.Children}>{children}</div>}

        {!!description && <Text.Small>{description}</Text.Small>}
      </div>
    );
  };

  const IconColumn = () => {
    return (
      <div className={styles.Icons}>
        {!!onPinClick && !isCompleted && (
          <ButtonPin pinState={pinState} onClick={handleClick} className={styles.ButtonPin} />
        )}

        {!!to && (
          <>
            <Icon
              icon={['fas', 'chevron-right']}
              size="md"
              className={cn(styles.IconHref, styles.IconNormal)}
              color={color.gray.default}
            />

            <Icon
              icon={['far', 'arrow-right']}
              size="lg"
              className={cn(styles.IconHref, styles.IconHover)}
              color={color.gray.dark}
            />
          </>
        )}

        {!!isCompleted && (
          <Tag icon="check" size="xs" color={color.success.default} className={styles.Completed} />
        )}
      </div>
    );
  };

  const componentClasses = cn(
    styles.ThumbnailTextRow,
    styles.hasHover,
    {
      [styles.isLarge]: isLarge,
      [styles.isClickable]: canClickToPin
    },
    className
  );

  if (!!to) {
    return (
      <Link to={to} className={componentClasses} {...props} {...handlers}>
        <Thumbnail
          thumbnail={image}
          alt={title}
          icon={imageIcon}
          isHovering={isHovering}
          hasPlaceholder={hasPlaceholder}
        />

        <BodyColumn />

        <IconColumn />
      </Link>
    );
  }

  return (
    <div
      className={componentClasses}
      {...props}
      {...handlers}
      onClick={canClickToPin ? onPinClick : undefined}
    >
      <Thumbnail
        thumbnail={image}
        alt={title}
        icon={imageIcon}
        isHovering={isHovering}
        hasPlaceholder={hasPlaceholder}
      />

      <BodyColumn />

      <IconColumn />
    </div>
  );
};
