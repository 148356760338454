import cn from 'classnames';

import { getTheme } from '@Utils/PlatformUtils';

import { STROKE_WIDTH, VIEWBOX_CENTER_X, VIEWBOX_CENTER_Y, VIEWBOX_WIDTH } from '../constants';
import styles from './Path.module.scss';
import { PathProps } from './Path.props';

// SVG path description specifies how the path should be drawn
const getPathDescription = () => {
  const theme = getTheme();
  const borderRadius = parseInt(theme.radius.avatar.split('px')[0]);
  const width = VIEWBOX_WIDTH - borderRadius * 2 - STROKE_WIDTH;

  return `
    M ${VIEWBOX_CENTER_X},${VIEWBOX_CENTER_Y}
    m +${STROKE_WIDTH / 2},-${VIEWBOX_WIDTH / 2 - STROKE_WIDTH / 2}
    h${width / 2 - STROKE_WIDTH / 2} 
    a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius} 
    v${width} 
    a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius} 
    h-${width} 
    a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},-${borderRadius} 
    v-${width} 
    a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},-${borderRadius} 
    z
  `;
};

const getDashStyle = (counterClockwise: boolean, dashRatio: number) => {
  const diameter = 206;
  const gapLength = (1 - dashRatio) * diameter;

  return {
    // Have dash be full diameter, and gap be full diameter
    strokeDasharray: `${diameter}px ${diameter}px`,
    // Shift dash backward by gapLength, so gap starts appearing at correct distance
    strokeDashoffset: `${counterClockwise ? -gapLength : gapLength}px`
  };
};

export const Path = ({
  className,
  isCounterClockwise = false,
  dashRatio,
  color,
  style = {}
}: PathProps) => {
  return (
    <path
      className={cn(styles.Path, className)}
      style={{
        ...style,
        ...getDashStyle(isCounterClockwise, dashRatio)
      }}
      d={getPathDescription()}
      strokeWidth={STROKE_WIDTH}
      fillOpacity={0}
      stroke={color}
    />
  );
};
