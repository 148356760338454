import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { pollService, PollVoteRequest } from '@Services/Poll';
import { trackMatomoPollVote } from '@Utils/MatomoUtils';

export const usePollVote = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: PollVoteRequest) => {
      return pollService.vote(config);
    },
    {
      onSuccess: (_, { poll, answer }) => {
        trackMatomoPollVote(trackEvent, poll, answer);

        queryClient.invalidateQueries([pollService.getEndpoint()]);
      }
    }
  );
};
