import { faCamera } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import Croppie from 'croppie';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'croppie/croppie.css';

import { Button, Icon, Text } from '@Components/ui';

import styles from './InputImage.module.scss';
import { InputImageProps } from './InputImage.props';

export const InputImage = forwardRef(
  (
    {
      name,
      value,
      width = 200,
      height = 200,
      className,
      isError,
      onChange,
      onBlur
    }: InputImageProps,
    ref?: any
  ) => {
    const padding = 120;

    const { t } = useTranslation();
    const InputRef = useRef<HTMLInputElement>(null);
    const CroppieRef = useRef<HTMLDivElement>(null);
    const [image, setImage] = useState<string | undefined>(value);
    const [croppie, setCroppie] = useState<Croppie | null>(null);
    const classNames = cn(styles.ImageResize, className, {
      [styles.hasImage]: image,
      [styles.isError]: isError
    });

    const handleOpen = useCallback(() => {
      if (InputRef.current) {
        InputRef.current.click();
      }
    }, []);

    const handleUpload = useCallback((e) => {
      const files = e.target.files;

      if (files && files[0]) {
        const reader = new FileReader();

        reader.onload = (newImage: any) => {
          setImage(newImage.target.result);
        };

        reader.readAsDataURL(files[0]);
      }
    }, []);

    const handleRemove = useCallback(() => {
      setImage(undefined);

      if (onChange) {
        onChange('');
      }

      if (InputRef.current) {
        InputRef.current.value = '';
      }
    }, [onChange]);

    useEffect(() => {
      const MyCroppie = CroppieRef.current;
      let handleUpdate: () => void;

      if (MyCroppie && croppie === null) {
        const croppieInstance = new Croppie(MyCroppie, {
          enableExif: true,
          viewport: {
            height: height,
            width: width
          },
          boundary: {
            height: height + padding,
            width: 0
          }
        });

        handleUpdate = () => {
          croppieInstance
            .result({
              type: 'base64',
              size: {
                width: width,
                height: height
              }
            })
            .then((blob: string) => {
              if (onBlur) {
                onBlur();
              }

              if (onChange) {
                onChange(blob);
              }
            })
            .catch(() => {
              setImage(undefined);
            });
        };

        setCroppie(croppieInstance);

        MyCroppie.addEventListener('update', () => handleUpdate());
      }

      return () => {
        MyCroppie?.removeEventListener('update', handleUpdate);
      };
    }, [croppie, height, onBlur, onChange, width]);

    useEffect(() => {
      if (croppie && image) {
        croppie.bind({
          url: image
        });
      }
    }, [croppie, image]);

    return (
      <div className={classNames}>
        <input
          id={name}
          className={styles.Input}
          ref={InputRef}
          type="file"
          onChange={handleUpload}
          accept="image/*"
          {...ref}
        />

        <div className={styles.CroppiePlaceholder} style={{ height: `${height + padding}px` }}>
          <label
            htmlFor={name}
            className={styles.Viewport}
            style={{ width: `${width}px`, height: `${height}px` }}
          >
            <Icon className={styles.Icon} size="xl" icon={faCamera} />

            <Button type="button" onClick={handleOpen} icon="arrow-right">
              {t('COMPONENTS.INPUT_IMAGE.UPLOAD')}
            </Button>
          </label>
        </div>

        <div ref={CroppieRef} className={styles.Croppie} />

        <Text.Medium
          className={styles.Remove}
          onClick={handleRemove}
          style={{ top: `${height + padding / 2}px` }}
        >
          {t('COMPONENTS.INPUT_IMAGE.REMOVE')}
        </Text.Medium>
      </div>
    );
  }
);
