import { useTranslation } from 'react-i18next';

import { Box, List, Text } from '@Components/ui';

import { ProfilePreferencesSummaryQuestionProps } from './ProfilePreferencesSummaryQuestion.props';

export const ProfilePreferencesSummaryQuestion = ({
  question,
  answers
}: ProfilePreferencesSummaryQuestionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Text.Bold>
        {t('DOMAIN.PROFILE.PREFERENCES.SUMMARY.QUESTION', {
          questionTitle: question
        })}
      </Text.Bold>

      <Box mt={1}>
        <List.Unordered>
          {answers.map((answer) => {
            return <Text.Normal key={answer}>{answer}</Text.Normal>;
          })}
        </List.Unordered>
      </Box>
    </>
  );
};
