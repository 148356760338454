import cn from 'classnames';

import { Text } from '@Components/ui';

import { useSliderFullPageItemContext } from '../SliderFullPageItem/context';
import styles from './SliderFullPageHead.module.scss';
import { SliderFullPageHeadProps } from './SliderFullPageHead.props';

export const SliderFullPageHead = ({
  superTitle,
  title,
  intro,
  showPagination,
  isLarge,
  children
}: SliderFullPageHeadProps) => {
  const { isActive } = useSliderFullPageItemContext();

  return (
    <div
      className={cn(styles.Head, {
        [styles.isActive]: isActive,
        [styles.hasPagination]: showPagination,
        [styles.isLarge]: isLarge,
        [styles.hasTitle]: !!title,
        [styles.hasIntro]: !!intro,
        [styles.hasChildren]: !!children
      })}
    >
      {!!superTitle && <Text.Micro className={styles.SuperTitle}>{superTitle}</Text.Micro>}

      {!!title && <Text.H2 className={styles.Title}>{title}</Text.H2>}

      {!!intro && <Text.Large className={styles.Intro} dangerousHtml={intro} />}

      {children}
    </div>
  );
};
