import { useTranslation } from 'react-i18next';

import { Anchor, Box, Button, SpacingGroup, Text } from '@Components/ui';
import { markupToHtml } from '@Utils/StringUtils';

import { UpdatePasswordRequestSmsProps } from './UpdatePasswordRequestSmsStep.props';

export const UpdatePasswordRequestSmsStep = ({
  phoneNumber,
  onSuccess,
  onCancel
}: UpdatePasswordRequestSmsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Text.Large
        dangerousHtml={markupToHtml(
          t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PASSWORD.REQUEST_SMS.DESCRIPTION', {
            phoneNumber
          })
        )}
      />

      <Box mt={3}>
        <SpacingGroup size="xxl">
          <Button onClick={onSuccess} color="dark" icon="arrow-right" data-testid="succesButton">
            {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PASSWORD.REQUEST_SMS.START_LABEL')}
          </Button>

          <Anchor onClick={onCancel} data-testid="cancelButton">
            {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PASSWORD.REQUEST_SMS.CANCEL_LABEL')}
          </Anchor>
        </SpacingGroup>
      </Box>
    </>
  );
};
