import { ListResponse } from '@Constants/interfaces';
import { dePortalenService, ResourceService } from '@Services/common';
import { toQueryString } from '@Utils/StringUtils';

import {
  ActivityFilterResponse,
  ActivityFinishRequest,
  ActivityFinishResponse,
  ActivityFinishStepRequest,
  ActivityInviteRequest,
  ActivityListRequest,
  ActivityPinRequest,
  ActivityPopularRequest,
  ActivityRelatedRequest,
  IActivity
} from './ActivityService.props';

export class ActivityService extends ResourceService<IActivity> {
  getList = async (params: ActivityListRequest) => {
    const response = await this.get<ListResponse<IActivity>>(params && `?${toQueryString(params)}`);

    return response.data;
  };

  getRelated = async (params: ActivityRelatedRequest) => {
    const query = {
      subjects: params.subjects,
      exclude: [params.id],
      perPage: 6
    } as ActivityListRequest;

    const response = await this.get<ListResponse<IActivity>>(`?${toQueryString(query)}`);

    return response.data;
  };

  popular = async (params: ActivityPopularRequest) => {
    const response = await this.get<ListResponse<IActivity>>(`/popular?${toQueryString(params)}`);

    return response.data;
  };

  getFilters = async () => {
    const response = await this.get<ActivityFilterResponse>('/filters');

    return response.data;
  };

  pin = async ({ id }: ActivityPinRequest) => {
    await this.post<void>(`/${id}/pin`);
  };

  finishActivity = async (params: ActivityFinishRequest) => {
    const response = await this.post<ActivityFinishResponse>(`/${params.id}/finish`, params);

    return response.data;
  };

  finishStep = async (params: ActivityFinishStepRequest) => {
    await this.post<void>(`/${params.id}/finish/step/${params.stepId}`, params);
  };

  invite = async ({ id, ...params }: ActivityInviteRequest) => {
    await this.post<void>(`/${id}/invite`, params);
  };
}

export const activityService = new ActivityService(
  dePortalenService.getAxiosInstance(),
  '/activities'
);
