import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { ProgressStepsItem } from './internal';
import { getCheckedItemCount, getFirstUncheckedItemIdx } from './internal/utils';
import styles from './ProgressSteps.module.scss';
import { ProgressStepsProps } from './ProgressSteps.props';

export const ProgressSteps = ({ options, className, canOpenNext = true }: ProgressStepsProps) => {
  const firstUncheckedItem = (options && getFirstUncheckedItemIdx(options)) || 0;
  const [activeItem, setActiveItem] = useState<number>(firstUncheckedItem);

  const openStepIndex = canOpenNext ? activeItem : firstUncheckedItem;
  const checkedItemCount = getCheckedItemCount(options);

  const classNames = cn(styles.ProgressSteps, className, {
    [styles.isLastItemActive]: activeItem === options?.length - 1
  });

  const handleClick = useCallback(
    (idx: number) => () => {
      if (canOpenNext) {
        setActiveItem(idx);
      }
    },
    [canOpenNext]
  );

  useEffect(() => {
    const newFirstUncheckedItem = getFirstUncheckedItemIdx(options);

    setActiveItem(newFirstUncheckedItem);
  }, [checkedItemCount, options]);

  if (options) {
    return (
      <div className={classNames}>
        {options.map((option, idx: number) => {
          return (
            <ProgressStepsItem
              key={idx}
              isOpen={openStepIndex === idx}
              onClick={handleClick(idx)}
              {...option}
            />
          );
        })}
      </div>
    );
  }

  return null;
};
