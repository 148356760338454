import { get } from 'lodash';
import { ControllerFieldState } from 'react-hook-form';

import { IBaseModel, InputOption } from '@Constants/interfaces';

export const objectToInputOptions = <T extends IBaseModel>(
  models: T[] | undefined,
  value: keyof T
): InputOption[] => {
  if (models) {
    return models.map((model: T) => {
      return {
        value: model.id,
        label: get(model, String(value))
      };
    });
  }

  return [];
};

export const enumToInputOptions = (
  enumeration: any,
  translations: { [key: string]: string }
): InputOption[] => {
  return Object.values(enumeration).reduce<any>((total, item: any) => {
    return [...total, { value: item, label: translations[item] }];
  }, []);
};

export const getFieldStateValidation = (fieldState: ControllerFieldState) => {
  const isError = !!fieldState.error === true;
  const isValid = !!fieldState.error === false && fieldState.isTouched;

  return {
    isValid,
    isError
  };
};
