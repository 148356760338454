import { Form } from '@Components/ui';
import { MAX_PERCENT } from '@Constants/configs';
import Yup from '@Utils/YupUtils';

import { QuestionnaireFormInputProps } from '../QuestionnaireFormInput.props';
import { QuestionnaireFormSliderValues } from './QuestionnaireFormSlider.props';

export const QuestionnaireFormSlider = ({
  question,
  children,
  onSubmit
}: QuestionnaireFormInputProps) => {
  const validationSchema = Yup.object().shape({
    answerText: Yup.number().min(0).max(MAX_PERCENT).required()
  });

  return (
    <Form.Form<QuestionnaireFormSliderValues>
      key={question.id}
      initialValues={{ answerText: question.answerText ? Number(question.answerText) : undefined }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      data-testid="questionnaireFormSlider"
    >
      <Form.Input.Slider
        name="answerText"
        options={{
          leftLabel: question.scaleMinDescription ?? undefined,
          rightLabel: question.scaleMaxDescription ?? undefined,
          isDisabled: question.editable === false
        }}
      />

      {children}
    </Form.Form>
  );
};
