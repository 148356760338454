import { MutableRefObject, useEffect, useState } from 'react';

import { Score } from '../../ScoreCircle.props';
import { BASE_WIDTH, PATH_SPACING } from '../constants';

export const useScoreCircleDynamicHeight = (
  componentRef: MutableRefObject<HTMLDivElement | null>,
  scores: Score[]
) => {
  const baseComponentHeight = BASE_WIDTH + ((scores?.length || 1) - 1) * PATH_SPACING;

  const [componentHeight, setComponentHeight] = useState(baseComponentHeight);

  useEffect(() => {
    const element = componentRef?.current;

    if (window.innerWidth && element) {
      const elementWidth = element.scrollWidth;

      if (elementWidth < baseComponentHeight) {
        setComponentHeight(elementWidth);
      } else {
        setComponentHeight(baseComponentHeight);
      }
    }
    // it wants to remove window.innerWidth, while it's necessary for this to work
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, componentRef, setComponentHeight, baseComponentHeight]);

  return [componentHeight, baseComponentHeight];
};
