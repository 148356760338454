import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container } from '@Components/layout';
import { Block } from '@Components/ui';
import { useUserActivityOne } from '@Hooks/useractivity';
import { AppRoutes } from '@Navigation/Routes';
import { isConfigured, isUserActivity } from '@Utils/ActivityUtils';

import { UserActivityBody, UserActivityFooter, UserActivityHeader } from './internal/components';

export const TodoItemScreen = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    data: userActivity,
    isSuccess: isUserActivitySuccess,
    isError: isUserActivityError
  } = useUserActivityOne(id || '');

  useEffect(() => {
    if (isUserActivityError || (userActivity && !isUserActivity(userActivity))) {
      toast.error(t('DOMAIN.USER_ACTIVITY.ERROR'));

      navigate(AppRoutes.getTodo().to);
    }
  }, [userActivity, isUserActivityError, navigate, t]);

  useEffect(() => {
    if (userActivity) {
      if (!isConfigured(userActivity)) {
        navigate(AppRoutes.getTodoUserActivityUpdate(userActivity.id).to);
      }
    }
  }, [userActivity, navigate]);

  return (
    <Container>
      {isUserActivitySuccess && userActivity && (
        <>
          <UserActivityHeader activity={userActivity} />

          <Block>
            <UserActivityBody activity={userActivity} />

            <UserActivityFooter activity={userActivity} />
          </Block>
        </>
      )}
    </Container>
  );
};
