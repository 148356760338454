import cn from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarCta } from '@Components/container';
import { Anchor, ButtonIcon, Icon, MenuItem, Text } from '@Components/ui';
import { useBoolean, useLockedBody, usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';

import { ProfileMenu } from '..';
import { useMenuItemsSidebar } from './internal/hooks';
import styles from './Sidebar.module.scss';
import { SidebarProps } from './Sidebar.props';

export const Sidebar = ({
  isOpen = false,
  onClose,
  isFullscreen = false,
  className
}: SidebarProps) => {
  const { value: isCollapsed, toggle: toggleCollapsed, setValue: setCollapsed } = useBoolean(false);

  const { t } = useTranslation();
  const { assets } = usePlatform();
  const menuItems = useMenuItemsSidebar();
  useLockedBody(isOpen);

  useEffect(() => {
    setCollapsed(false);
  }, [isOpen, setCollapsed]);

  const componentClasses = cn(
    styles.Sidebar,
    {
      [styles.isCollapsed]: isCollapsed,
      [styles.isFullscreen]: isFullscreen,
      [styles.isOpen]: isOpen
    },
    className
  );

  return (
    <>
      <div className={cn(styles.Overlay, { [styles.isOpen]: isOpen })} onClick={onClose} />

      <div className={componentClasses}>
        <div className={styles.Inner}>
          <Anchor to={AppRoutes.getDashboard().to} className={styles.Logo}>
            <img src={assets.icon.default} className={styles.Small} />

            <img src={assets.logo.default} className={styles.Large} />
          </Anchor>

          <Text.H5 className={styles.Close} onClick={onClose}>
            {t('COMPONENTS.SIDEBAR.CLOSE_MENU')}

            <Icon icon="times" size="lg" className={styles.Icon} />
          </Text.H5>

          <ProfileMenu className={styles.Profile} isReversed isSmall />

          <ButtonIcon
            icon={isCollapsed ? 'plus' : 'minus'}
            onClick={toggleCollapsed}
            className={styles.Collapse}
          />

          <div className={styles.Menu}>
            {menuItems.map((menuItem, idx) => {
              return (
                <MenuItem
                  key={idx}
                  className={styles.Item}
                  isCollapsed={isCollapsed}
                  {...menuItem}
                />
              );
            })}
          </div>

          <SidebarCta isCollapsed={isCollapsed} className={styles.FAQ} />
        </div>
      </div>
    </>
  );
};
