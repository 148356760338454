import { useCallback } from 'react';

import { IS_PRODUCTION } from '@Constants/configs';

import { MatomoTrackEventProps } from './useMatomoTrackEvent.props';

export const useMatomoTrackEvent = () => {
  const handleTrackEvent = useCallback(({ category, action, name }: MatomoTrackEventProps) => {
    if (!!category && !!action && !!name && window._paq) {
      if (!IS_PRODUCTION) {
        console.log('Matomo', { category, action, name });
      }

      window._paq.push(['trackEvent', category, action, name, '']);
    }
  }, []);

  return handleTrackEvent;
};
