import { useTranslation } from 'react-i18next';

import { Accordion, Box, Button, Loader, Text } from '@Components/ui';
import { useDataActivitySubjects, useDataActivityTypes, useDataGgc } from '@Hooks/data';
import { useUser } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';
import { PreferenceKey } from '@Services/Preference';
import { getPreference } from '@Utils/PreferenceUtils';

import { ProfilePreferencesSummaryQuestion } from './internal/components';
import {
  getActivitySubjectsAnswer,
  getActivityTypesAnswer,
  getGgcAnswer,
  getPreferenceLabels
} from './internal/utils';

export const ProfilePreferencesSummary = () => {
  const { t } = useTranslation();
  const { preferences } = useUser();
  const { data: dataGgc, isLoading: isDataGgcLoading, isError: isDataGgcError } = useDataGgc();
  const {
    data: dataActivitySubjects,
    isLoading: isDataActivitySubjectsLoading,
    isError: isDataActivitySubjectsError
  } = useDataActivitySubjects();
  const {
    data: dataActivityTypes,
    isLoading: isDataActivityTypesLoading,
    isError: isDataActivityTypesError
  } = useDataActivityTypes();

  const isLoading = isDataActivityTypesLoading || isDataActivitySubjectsLoading || isDataGgcLoading;
  const isError = isDataActivityTypesError || isDataActivitySubjectsError || isDataGgcError;

  return (
    <div>
      {isError && (
        <Box mt={2}>
          <Text.Error>{t('DOMAIN.PROFILE.PREFERENCES.SUMMARY.COULD_NOT_FETCH')}</Text.Error>
        </Box>
      )}

      {isLoading ? (
        <Box my={4}>
          <Loader>{t('DOMAIN.PROFILE.PREFERENCES.SUMMARY.LOADING')}</Loader>
        </Box>
      ) : (
        <Box mb={3}>
          <Accordion
            isSmall
            items={[
              {
                id: t('DOMAIN.PREFERENCES.GGC.TITLE.REGULAR'),
                title: t('DOMAIN.PREFERENCES.GGC.TITLE.REGULAR'),
                body: (
                  <ProfilePreferencesSummaryQuestion
                    question={t('DOMAIN.PREFERENCES.GGC.TITLE.REGULAR')}
                    answers={getGgcAnswer(dataGgc || [], preferences) ?? ''}
                  />
                )
              },

              {
                id: t('DOMAIN.PREFERENCES.ACTIVITY_SUBJECTS.FORM.TITLE'),
                title: t('DOMAIN.PREFERENCES.ACTIVITY_SUBJECTS.FORM.TITLE'),
                body: (
                  <ProfilePreferencesSummaryQuestion
                    question={t('DOMAIN.PREFERENCES.ACTIVITY_SUBJECTS.FORM.TITLE')}
                    answers={
                      getActivitySubjectsAnswer(dataActivitySubjects || [], preferences) ?? ''
                    }
                  />
                )
              },

              {
                id: t('DOMAIN.PREFERENCES.ACTIVITY_TYPES.FORM.TITLE'),
                title: t('DOMAIN.PREFERENCES.ACTIVITY_TYPES.FORM.TITLE'),
                body: (
                  <ProfilePreferencesSummaryQuestion
                    question={t('DOMAIN.PREFERENCES.ACTIVITY_TYPES.FORM.TITLE')}
                    answers={getActivityTypesAnswer(dataActivityTypes || [], preferences) ?? ''}
                  />
                )
              },

              {
                id: t('DOMAIN.PREFERENCES.SUPPORT_TYPES.FORM.TITLE'),
                title: t('DOMAIN.PREFERENCES.SUPPORT_TYPES.FORM.TITLE'),
                body: (
                  <ProfilePreferencesSummaryQuestion
                    question={t('DOMAIN.PREFERENCES.SUPPORT_TYPES.FORM.TITLE')}
                    answers={getPreferenceLabels(
                      PreferenceKey.SUPPORT_TYPES,
                      getPreference(preferences, PreferenceKey.SUPPORT_TYPES) ?? []
                    )}
                  />
                )
              },

              {
                id: t('DOMAIN.PREFERENCES.EDUCATION.FORM.TITLE'),
                title: t('DOMAIN.PREFERENCES.EDUCATION.FORM.TITLE'),
                body: (
                  <ProfilePreferencesSummaryQuestion
                    question={t('DOMAIN.PREFERENCES.EDUCATION.FORM.TITLE')}
                    answers={getPreferenceLabels(
                      PreferenceKey.EDUCATION,
                      getPreference(preferences, PreferenceKey.EDUCATION) ?? []
                    )}
                  />
                )
              },

              {
                id: t('DOMAIN.PREFERENCES.PROFESSION.FORM.TITLE'),
                title: t('DOMAIN.PREFERENCES.PROFESSION.FORM.TITLE'),
                body: (
                  <ProfilePreferencesSummaryQuestion
                    question={t('DOMAIN.PREFERENCES.PROFESSION.FORM.TITLE')}
                    answers={getPreferenceLabels(
                      PreferenceKey.PROFESSION,
                      getPreference(preferences, PreferenceKey.PROFESSION) ?? []
                    )}
                  />
                )
              }
            ]}
          />
        </Box>
      )}

      <Button icon="arrow-right" to={AppRoutes.getMyProfilePreferences().to}>
        {t('DOMAIN.PROFILE.PREFERENCES.BUTTON_LABEL')}
      </Button>
    </div>
  );
};
