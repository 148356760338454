import { useCallback } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';

import {
  Box,
  SliderFullPage,
  SliderNavigation,
  Text,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';
import { SLIDER_FULLPAGE_SPEED } from '@Components/ui/SliderFullPage/internal/constants';
import { usePlatform } from '@Hooks/common';

import { useQuestionnaireContext } from '../../context';
import { QuestionnaireForm } from '../QuestionnaireForm/QuestionnaireForm';
import { QuestionnaireFormValues } from '../QuestionnaireForm/QuestionnaireForm.props';
import { QuestionnaireFormSlideProps } from './QuestionnaireFormSlide.props';

export const QuestionnaireFormSlide = ({
  questionnaireName,
  submission,
  totalSlides,
  currentSlideIndex,
  onInfoClick,
  isLoadingNext,
  isLoadingPrevious,
  paginationPrefix
}: QuestionnaireFormSlideProps) => {
  const { t } = useTranslation();
  const { easing } = usePlatform();
  const { submit, goToPrevious } = useQuestionnaireContext();
  const { isActive } = useSliderFullPageItemContext();
  const { isAnimating } = useSliderFullPageContext();

  const question = submission?.currentQuestion;
  const canGoPrevious = submission?.hasPreviousQuestion || submission?.showSectionIntro;

  const handleSubmit = useCallback(
    async (values: QuestionnaireFormValues) => {
      if (values) {
        await submit(values);
      }
    },
    [submit]
  );

  const getQuestionIntro = () => {
    let description = question?.headerDescription ?? '';

    if (question?.isAdaptive && submission?.isAdaptive) {
      if (description.length > 0) {
        description += '\n\n';
      }

      description += t('COMPONENTS.QUESTIONNAIRE.IS_ADAPTIVE');
    }

    return description;
  };

  return (
    <SliderFullPage.Item data-testid={`questionnaireFormSlide_${question?.id}`}>
      {question ? (
        <SliderFullPage.Head
          superTitle={questionnaireName}
          title={question?.headerTitle}
          intro={getQuestionIntro()}
        />
      ) : (
        <div style={{ height: 100 }} />
      )}

      <SliderFullPage.Block title={question?.title}>
        <AnimateHeight
          height={isActive ? 'auto' : 200}
          easing={easing.inOut}
          duration={SLIDER_FULLPAGE_SPEED}
        >
          {question?.description && <Text.Normal dangerousHtml={question.description} />}

          <Box pt={4}>
            {question && (
              <QuestionnaireForm question={question} onSubmit={handleSubmit}>
                <Box pt={6}>
                  <SliderNavigation
                    nextLabel={t('DOMAIN.PREFERENCES.NEXT_BUTTON')}
                    onPrevious={canGoPrevious ? goToPrevious : undefined}
                    onInfoClick={onInfoClick}
                    isLoadingNext={isLoadingNext}
                    isLoadingPrevious={isLoadingPrevious}
                    total={totalSlides}
                    current={currentSlideIndex}
                    isDisabled={isAnimating}
                    paginationPrefix={paginationPrefix}
                  />
                </Box>
              </QuestionnaireForm>
            )}
          </Box>
        </AnimateHeight>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
