import cn from 'classnames';
import {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { ButtonGroup } from '@Components/ui';
import { useBoolean, useScrollTo, useTimeouts } from '@Hooks/common';
import { exists } from '@Utils/CommonUtils';

import TabGroupChildProps from '../Child/Child.props';
import { TabGroupContext } from './context';
import { getTabIdx } from './internal/utils';
import styles from './Wrapper.module.scss';
import { TabGroupProps } from './Wrapper.props';

export const Wrapper = ({ children, className, activeIdx, onChange }: TabGroupProps) => {
  const groupRef = useRef<HTMLDivElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('active');
  const [activeTabIdxControlled, setActiveTabIdxControlled] = useState(
    activeTab ? getTabIdx(activeTab, children) : 0
  );
  const [activeTabHeight, setActiveTabHeight] = useState<number>(0);
  const activeTabIdx = activeIdx ?? activeTabIdxControlled;

  const { value: shouldIgnoreHeight, setValue: setIgnoreHeight } = useBoolean(true);

  const { addTimeout } = useTimeouts();

  const tabRefs = useRef<Array<HTMLDivElement | null>>([]);
  const { triggerScroll } = useScrollTo(groupRef);

  useEffect(() => {
    if (activeTab) {
      setActiveTabIdxControlled(getTabIdx(activeTab, children));
    }
  }, [activeTab, children]);

  useEffect(() => {
    if (activeTab) {
      triggerScroll();
    }
  }, [activeTab, triggerScroll]);

  const childrenFlattened: Array<ReactElement<TabGroupChildProps>> = useMemo(() => {
    return children.flat(Infinity).filter(Boolean);
  }, [children]);

  const childrenIndexed: Array<ReactElement<TabGroupChildProps>> = useMemo(() => {
    return childrenFlattened.map((child, index) => {
      return {
        ...child,
        props: {
          ...child.props,
          index: child.props.index ?? index
        }
      };
    });
  }, [childrenFlattened]);

  useLayoutEffect(() => {
    setIgnoreHeight(false);
    const currentTab = tabRefs.current[activeTabIdx];

    if (currentTab) {
      addTimeout(() => {
        setActiveTabHeight(currentTab.scrollHeight);

        addTimeout(() => {
          setIgnoreHeight(true);
        }, 500);
      }, 50);
    }
  }, [activeTabIdx, childrenFlattened, addTimeout, setIgnoreHeight]);

  const labels = useMemo(() => {
    return childrenFlattened?.map((child: ReactElement<TabGroupChildProps>) => ({
      label: child.props.label,
      isDisabled: child.props.isDisabled
    }));
  }, [childrenFlattened]);

  const tabs = useMemo(() => {
    return childrenIndexed?.map((tab, idx) => {
      const tabClasses = cn(styles.Tab, { [styles.isActive]: idx === activeTabIdx });

      return (
        <div key={idx} ref={(el) => (tabRefs.current[idx] = el)} className={tabClasses}>
          {tab}
        </div>
      );
    });
  }, [childrenIndexed, activeTabIdx]);

  const handleTabChange = useCallback(
    (newIdx: number) => {
      const tabKey = children[newIdx]?.props?.tabKey;

      if (onChange) {
        onChange(newIdx);
      }

      if (!exists(activeIdx)) {
        setActiveTabIdxControlled(newIdx);
      }

      if (tabKey) {
        setSearchParams({ active: tabKey });
      }
    },
    [activeIdx, children, onChange, setSearchParams]
  );

  return (
    <TabGroupContext.Provider value={{ current: activeTabIdx, total: tabs.length }}>
      <div ref={groupRef} className={cn([styles.TabGroup, className])}>
        {!!labels && (
          <ButtonGroup buttons={labels} activeIdx={activeTabIdx} onChange={handleTabChange} />
        )}

        <div
          className={styles.Tabs}
          style={{
            height: shouldIgnoreHeight ? undefined : activeTabHeight
          }}
        >
          {!!children && tabs}
        </div>
      </div>
    </TabGroupContext.Provider>
  );
};
