import styles from './AppDashboardScreen.module.scss';
import {
  DashboardActivities,
  DashboardChallenges,
  DashboardIntro,
  DashboardMilestones,
  DashboardPoints,
  DashboardPoll,
  DashboardPopularActivities,
  DashboardProgress,
  DashboardQuote,
  DashboardStatistic,
  DashboardTodo,
  DashboardWelcome,
  DashboardWidget
} from './internal/components';

export const AppDashboardScreen = () => {
  return (
    <>
      <DashboardWelcome />

      <DashboardIntro />

      <div className={styles.Content}>
        <DashboardActivities />

        <DashboardChallenges />

        <DashboardPoll />

        <DashboardPopularActivities />

        <DashboardQuote />
      </div>

      <div className={styles.Sidebar}>
        <DashboardStatistic />

        <DashboardMilestones />

        <DashboardTodo />

        <DashboardPoints />

        <DashboardProgress />
      </div>

      <div className={styles.Footer}>
        <DashboardWidget />
      </div>
    </>
  );
};
