import { MAX_PERCENT, NUMBER_OF_PROFILE_STATUS_STEPS } from '@Constants/configs';
import { ProfileStatusStep } from '@Services/User';

import { useUser } from './useUser';

export const useUserProfileProgress = () => {
  const { profileStatusSteps, selfTestCompleted } = useUser();

  const perStepPoints = MAX_PERCENT / NUMBER_OF_PROFILE_STATUS_STEPS;

  const hasCompletedAccount = true;
  const hasCompletedPreferences = profileStatusSteps.includes(ProfileStatusStep.PERSONA) === false;
  const hasCompletedSelfTest = selfTestCompleted;
  const hasCompletedChallenge = profileStatusSteps.length === 0;

  const profileSteps = [
    hasCompletedAccount,
    hasCompletedPreferences,
    hasCompletedSelfTest,
    hasCompletedChallenge
  ];

  const completedProfileSteps = profileSteps.filter(Boolean).length;

  return completedProfileSteps * perStepPoints;
};
