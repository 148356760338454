import cn from 'classnames';
import { forwardRef } from 'react';

import styles from './Block.module.scss';
import { BlockProps } from './Block.props';

export const Block = forwardRef(
  (
    {
      isElevated,
      children,
      footer,
      padding = 'md',
      theme = 'frame',
      className,
      innerClassName,
      ...props
    }: BlockProps,
    ref: any
  ) => {
    const classNames = cn(styles.Block, className, {
      [styles.isElevated]: isElevated,
      [styles.isThemeFrame]: theme === 'frame',
      [styles.isThemeSuccess]: theme === 'success',
      [styles.isThemeGray]: theme === 'gray',
      [styles.isThemeBackground]: theme === 'background'
    });

    const bodyClassNames = cn(styles.Body, innerClassName, {
      [styles.isPaddingXl]: padding === 'xl',
      [styles.isPaddingLg]: padding === 'lg',
      [styles.isPaddingMd]: padding === 'md',
      [styles.isPaddingSm]: padding === 'sm',
      [styles.isPaddingXs]: padding === 'xs'
    });

    return (
      <div className={classNames} ref={ref} {...props}>
        <div className={bodyClassNames}>{children}</div>

        {!!footer && footer}
      </div>
    );
  }
);
