import { SelectActivityBlockReturnType } from '@Components/list';
import { REQUIRED_ACTIVITY_AMOUNT } from '@Constants/configs';
import { ChallengeStartActivity } from '@Services/Challenge';

export const mapSelectedActivitiesToStartType = (
  activities: SelectActivityBlockReturnType[]
): ChallengeStartActivity[] => {
  return activities.map((activity) => {
    if (activity.isCustom) {
      return {
        customTitle: activity.value
      };
    }

    return {
      id: activity.value
    };
  });
};

export const validateSelectedActivities = (
  selectedActivities: SelectActivityBlockReturnType[]
): boolean => {
  if (selectedActivities.length !== REQUIRED_ACTIVITY_AMOUNT) {
    return false;
  }

  const customActivity = selectedActivities.find((activity) => activity.isCustom);

  if (customActivity && customActivity.value === '') {
    return false;
  }

  return true;
};
