import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { UserConfetti } from '@Components/container';
import { useQuestionnaireContext } from '@Components/container/Questionnaire/internal/context';
import {
  Box,
  SliderFullPage,
  SliderNavigation,
  Text,
  useSliderFullPageContext,
  useSliderFullPageItemActiveAfterAnimation
} from '@Components/ui';

import { QuestionnaireSelfTestPhaseOutroSlideProps } from './QuestionnaireSelfTestPhaseOutroSlide.props';

export const QuestionnaireSelfTestPhaseOutroSlide = ({
  totalSlides,
  currentSlideIndex,
  paginationPrefix,
  onInfoClick,
  hasFuturePhases
}: QuestionnaireSelfTestPhaseOutroSlideProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const { goToPrevious, finish, isLoadingNext, isLoadingPrevious } = useQuestionnaireContext();
  const { isAnimating } = useSliderFullPageContext();
  const { isActiveAndAnimated } = useSliderFullPageItemActiveAfterAnimation();

  return (
    <SliderFullPage.Item data-testid="selfTestPhaseOutroSlide">
      <SliderFullPage.Head
        title={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PHASE_OUTRO_SLIDE.HEAD.TITLE')}
        superTitle={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PHASE_OUTRO_SLIDE.HEAD.SUP_TITLE')}
      />

      <SliderFullPage.Block
        title={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PHASE_OUTRO_SLIDE.BODY.TITLE')}
        ref={ref}
      >
        <UserConfetti targetRef={ref} isExploding={isActiveAndAnimated} />

        <Text.Large>
          {hasFuturePhases
            ? t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PHASE_OUTRO_SLIDE.BODY.DESCRIPTION_NEXT_PHASE')
            : t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PHASE_OUTRO_SLIDE.BODY.DESCRIPTION')}
        </Text.Large>

        <Box mt={4}>
          <SliderNavigation
            nextLabel={t('COMPONENTS.QUESTIONNAIRE.FINISH_LABEL')}
            onPrevious={goToPrevious}
            onNext={finish}
            isLoadingNext={isLoadingNext}
            isLoadingPrevious={isLoadingPrevious}
            current={currentSlideIndex}
            total={totalSlides}
            onInfoClick={onInfoClick}
            isDisabled={isAnimating}
            paginationPrefix={paginationPrefix}
          />
        </Box>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
