import cn from 'classnames';

import { Icon } from '@Components/ui';

import styles from './IconText.module.scss';
import { IconTextProps } from './IconText.props';

export const IconText = ({ icon, color, children, className, isReversed }: IconTextProps) => {
  const classNames = cn(styles.IconText, className, {
    [styles.isReversed]: isReversed
  });

  return (
    <div className={classNames}>
      <Icon className={styles.Icon} icon={['fas', icon]} color={color} size="lg" />

      <span className={styles.Text}>{children}</span>
    </div>
  );
};
