import { useTranslation } from 'react-i18next';

import { Block, ProgressLabeled } from '@Components/ui';

import { mapOverviewDataToProps } from '../../utils';
import styles from './InsightProgressOverview.module.scss';
import { InsightProgressOverviewProps } from './InsightProgressOverview.props';

export const InsightProgressOverview = ({ overview, className }: InsightProgressOverviewProps) => {
  const { t } = useTranslation();

  return (
    <Block theme="gray" innerClassName={styles.InsightProgressOverview} className={className}>
      <ProgressLabeled
        {...mapOverviewDataToProps(overview.challenges)}
        label={t('DOMAIN.PROGRESS.OVERVIEW.CHALLENGES')}
        className={styles.ProgressLabeled}
      />

      <ProgressLabeled
        {...mapOverviewDataToProps(overview.activities)}
        label={t('DOMAIN.PROGRESS.OVERVIEW.ACTIVITIES')}
        className={styles.ProgressLabeled}
      />

      <ProgressLabeled
        {...mapOverviewDataToProps(overview.points)}
        label={t('DOMAIN.PROGRESS.OVERVIEW.POINTS')}
        className={styles.ProgressLabeled}
      />

      <ProgressLabeled
        {...mapOverviewDataToProps(overview.milestones)}
        label={t('DOMAIN.PROGRESS.OVERVIEW.MILESTONES')}
        className={styles.ProgressLabeled}
      />
    </Block>
  );
};
