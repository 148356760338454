import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { StorageKey } from '@Constants/enums';

import { useStorage } from './useStorage';

export const usePersistSearchParam = (targetSearchParam: string) => {
  const [searchParams] = useSearchParams();
  const { set, get, remove } = useStorage<string>(
    StorageKey.SEARCH_PARAM + `_${targetSearchParam}`
  );
  const foundSearchParam = searchParams.get(targetSearchParam);

  const persist = useCallback(() => {
    if (foundSearchParam) {
      set(foundSearchParam);
    }
  }, [foundSearchParam, set]);

  return {
    persistSearchParam: persist,
    getPersistedSearchParam: get,
    removePersistedSearchParam: remove
  };
};
