import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { EMAIL_CODE_LENGTH } from '@Constants/configs';
import { useApiError } from '@Hooks/common';
import { useOnboardingVerifyEmail } from '@Hooks/onboarding';
import Yup from '@Utils/YupUtils';

import {
  OnboardingVerifyEmailFormProps,
  OnboardingVerifyEmailFormValues
} from './OnboardingVerifyEmailForm.props';

export const OnboardingVerifyEmailForm = ({
  onSuccess,
  userId
}: OnboardingVerifyEmailFormProps) => {
  const { t } = useTranslation();
  const { error, handleError } = useApiError();
  const onboardingVerifyEmailMutation = useOnboardingVerifyEmail();

  const handleSubmit = useCallback(
    async (values: OnboardingVerifyEmailFormValues) => {
      try {
        await onboardingVerifyEmailMutation.mutateAsync({
          userId: userId,
          ...values
        });

        onSuccess();
      } catch (e) {
        handleError(e, 'ONBOARDING');
      }
    },
    [onSuccess, userId, onboardingVerifyEmailMutation, handleError]
  );

  const validationSchema = Yup.object().shape({
    token: Yup.string().length(EMAIL_CODE_LENGTH).required()
  });

  return (
    <Form.Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form.Input.Code
        name="token"
        data-testid="emailTokenInput"
        label={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_EMAIL.FORM.FIELDS.TOKEN.LABEL')}
        description={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_EMAIL.FORM.FIELDS.TOKEN.DESCRIPTION')}
        options={{
          length: EMAIL_CODE_LENGTH,
          isError: !!error,
          type: 'number',
          pattern: '[0-9]*',
          inputMode: 'numeric'
        }}
      />

      <Form.Layout.Error error={error} />

      <Button type="submit" icon="arrow-right" data-testid="verifyEmailSubmit">
        {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.FORM.SUBMIT')}
      </Button>
    </Form.Form>
  );
};
