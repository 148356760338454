import { useTranslation } from 'react-i18next';

import { Anchor, Block, Box, Text } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

import { SelfTestRegularClosedBlockProps } from './SelfTestRegularClosedBlock.props';

export const SelfTestRegularClosedBlock = ({
  selfTest,
  ...props
}: SelfTestRegularClosedBlockProps) => {
  const { t } = useTranslation();

  return (
    <Block theme="gray" {...props}>
      <Box mb={1}>
        <Text.H4>{t('DOMAIN.TODO.SELF_TEST.REGULAR.DONE.TITLE')}</Text.H4>
      </Box>

      <Box mb={1.5}>
        <Text.Normal>{t('DOMAIN.TODO.SELF_TEST.REGULAR.DONE.DESCRIPTION')}</Text.Normal>
      </Box>

      <Anchor to={AppRoutes.getInsightReport().to}>
        {t('DOMAIN.TODO.SELF_TEST.REGULAR.DONE.ANCHOR')}
      </Anchor>
    </Block>
  );
};
