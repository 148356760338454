import { t } from 'i18next';

import { Result } from '@Constants/interfaces';
import { MatomoAction, MatomoCategory, MatomoName, MatomoTrackEventProps } from '@Hooks/matomo';
import { IPoll } from '@Services/Poll';
import { PreferenceKey } from '@Services/Preference';
import {
  IQuestionnaireSubmission,
  QuestionnaireSubmissionSubmitSelfTestResponse
} from '@Services/QuestionnaireSubmission';
import { IUser } from '@Services/User';
import { IUserPopupAnswer } from '@Services/UserPopup';
import { IUserTheme } from '@Services/UserTheme';

import { QuestionnaireSelftestType, QuestionnaireType } from './MatomoUtils.props';

export type TrackEventHandler = ({ category, action, name }: MatomoTrackEventProps) => void;

export const setMatomoUser = (user: IUser) => {
  window._paq.push(['setCustomDimension', 1, user.companyId]);
  window._paq.push(['setCustomDimension', 2, user.id]);
  window._paq.push(['setCustomDimension', 3, user.personaId]);
};

export const trackMatomoFaqQuestion = (trackEvent: TrackEventHandler, question: string) => {
  trackEvent({
    category: MatomoCategory.FAQ,
    action: MatomoAction.FAQ_QUESTION,
    name: question
  });
};

export const trackMatomoFaqCategory = (trackEvent: TrackEventHandler, category: string) => {
  trackEvent({
    category: MatomoCategory.FAQ,
    action: MatomoAction.FAQ_CATEGORY,
    name: category
  });
};

export const trackMatomoOtherDownload = (trackEvent: TrackEventHandler) => {
  trackEvent({
    category: MatomoCategory.OTHER,
    action: MatomoAction.OTHER_DOWNLOAD,
    name: MatomoName.OTHER_DOWNLOAD_SCORES_AND_ADVICE
  });
};

export const trackMatomoOtherNegativeThemeResult = (
  trackEvent: TrackEventHandler,
  theme: IUserTheme
) => {
  if (theme.themeResult && [Result.RED].includes(theme.themeResult)) {
    trackEvent({
      category: MatomoCategory.OTHER,
      action: MatomoAction.OTHER_NEGATIVE_THEME_RESULT,
      name: theme.theme.title
    });
  }
};

export const trackMatomoAuthLogin = (trackEvent: TrackEventHandler, name: string) => {
  trackEvent({
    category: MatomoCategory.LOGIN,
    action: MatomoAction.LOGIN_STEP,
    name: name
  });
};

export const trackMatomoOnboardingAccountStep = (trackEvent: TrackEventHandler, name: string) => {
  trackEvent({
    category: MatomoCategory.ONBOARDING,
    action: MatomoAction.ONBOARDING_ACCOUNT_STEP,
    name: name
  });
};

export const trackMatomoOnboardingAccountTypeMail = (
  trackEvent: TrackEventHandler,
  mail: string
) => {
  trackEvent({
    category: MatomoCategory.ONBOARDING,
    action: MatomoAction.ONBOARDING_ACCOUNT_TYPE_MAIL,
    name: mail.split('@')[1]
  });
};

export const trackMatomoOnboardingPreferenceStep = (
  trackEvent: TrackEventHandler,
  key: PreferenceKey,
  isUpdate?: boolean
) => {
  if (
    [
      PreferenceKey.ACTIVITY_SUBJECTS,
      PreferenceKey.ACTIVITY_TYPES,
      PreferenceKey.EDUCATION,
      PreferenceKey.PROFESSION,
      PreferenceKey.SUPPORT_TYPES,
      PreferenceKey.GGC_COMPETENCE
    ].includes(key)
  ) {
    trackEvent({
      category: isUpdate ? MatomoCategory.PROFILE : MatomoCategory.ONBOARDING,
      action: isUpdate
        ? MatomoAction.PROFILE_PREFERENCE_STEP
        : MatomoAction.ONBOARDING_PREFERENCE_STEP,
      name: t(`DOMAIN.PREFERENCES.${key}.FORM.TITLE` as any)
    });
  }
};

export const trackMatomoOnboardingQuestionnaireStep = (
  trackEvent: TrackEventHandler,
  questionnaireSubmission: IQuestionnaireSubmission | QuestionnaireSubmissionSubmitSelfTestResponse
) => {
  if (questionnaireSubmission.questionnaire.title === QuestionnaireType.SELFTEST) {
    let action = QuestionnaireSelftestType.REGULAR;

    if (questionnaireSubmission.isAdaptive) {
      action = QuestionnaireSelftestType.ADAPTIVE;
    }

    if (questionnaireSubmission.isPhased) {
      action = QuestionnaireSelftestType.PHASED;
    }

    trackEvent({
      category: MatomoCategory.ONBOARDING_SELFTEST_STEP,
      action: action,
      name: questionnaireSubmission.currentQuestion.title
    });
  }

  if (questionnaireSubmission.questionnaire.title === QuestionnaireType.PERSONA) {
    trackEvent({
      category: MatomoCategory.ONBOARDING,
      action: MatomoAction.ONBOARDING_PERSONA_STEP,
      name: questionnaireSubmission.currentQuestion.title
    });
  }
};

export const trackMatomoOnboardingQuestionnaireStepPrevious = (
  trackEvent: TrackEventHandler,
  questionnaireSubmission: IQuestionnaireSubmission | QuestionnaireSubmissionSubmitSelfTestResponse
) => {
  if (questionnaireSubmission.questionnaire.title === QuestionnaireType.SELFTEST) {
    let action = QuestionnaireSelftestType.REGULAR;

    if (questionnaireSubmission.isAdaptive) {
      action = QuestionnaireSelftestType.ADAPTIVE;
    }

    if (questionnaireSubmission.isPhased) {
      action = QuestionnaireSelftestType.PHASED;
    }

    trackEvent({
      category: MatomoCategory.ONBOARDING_SELFTEST_STEP_PREVIOUS,
      action: action,
      name: questionnaireSubmission.currentQuestion.title
    });
  }

  if (questionnaireSubmission.questionnaire.title === QuestionnaireType.PERSONA) {
    trackEvent({
      category: MatomoCategory.ONBOARDING,
      action: MatomoAction.ONBOARDING_PERSONA_STEP_PREVIOUS,
      name: questionnaireSubmission.currentQuestion.title
    });
  }
};

export const trackMatomoOnboardingQuestionnaireSelftestPause = (
  trackEvent: TrackEventHandler,
  questionnaireSubmission: IQuestionnaireSubmission | QuestionnaireSubmissionSubmitSelfTestResponse,
  skipped: boolean
) => {
  let action = QuestionnaireSelftestType.REGULAR;

  if (questionnaireSubmission.isAdaptive) {
    action = QuestionnaireSelftestType.ADAPTIVE;
  }

  if (questionnaireSubmission.isPhased) {
    action = QuestionnaireSelftestType.PHASED;
  }

  trackEvent({
    category: skipped
      ? MatomoCategory.ONBOARDING_SELFTEST_PAUSED
      : MatomoCategory.DASHBOARD_SELFTEST_PAUSED,
    action: action,
    name: 'Gepauzeerd'
  });
};

export const trackMatomoOnboardingQuestionnaireSelftestFinish = (
  trackEvent: TrackEventHandler,
  questionnaireSubmission: IQuestionnaireSubmission | QuestionnaireSubmissionSubmitSelfTestResponse
) => {
  if (questionnaireSubmission.questionnaire.title === QuestionnaireType.SELFTEST) {
    let action = QuestionnaireSelftestType.REGULAR;

    if (questionnaireSubmission.isAdaptive) {
      action = QuestionnaireSelftestType.ADAPTIVE;
    }

    if (questionnaireSubmission.isPhased) {
      action = QuestionnaireSelftestType.PHASED;
    }

    trackEvent({
      category: MatomoCategory.ONBOARDING_SELFTEST_FINISHED,
      action: action,
      name: 'Afgerond'
    });
  }
};

export const trackMatomoOnboardingPersona = (trackEvent: TrackEventHandler) => {
  trackEvent({
    category: MatomoCategory.ONBOARDING,
    action: MatomoAction.ONBOARDING_PERSONA_STEP,
    name: 'Ik herken mijzelf het meest in de volgende uitspraak'
  });
};

export const trackMatomoChallengeStart = (
  trackEvent: TrackEventHandler,
  title: string,
  theme: string
) => {
  trackEvent({
    category: MatomoCategory.CHALLENGE,
    action: MatomoAction.CHALLENGE_START,
    name: title
  });

  trackEvent({
    category: MatomoCategory.CHALLENGE,
    action: MatomoAction.CHALLENGE_TYPE_START,
    name: theme
  });
};

export const trackMatomoChallengeCancel = (
  trackEvent: TrackEventHandler,
  title: string,
  theme: string
) => {
  trackEvent({
    category: MatomoCategory.CHALLENGE,
    action: MatomoAction.CHALLENGE_CANCEL,
    name: title
  });

  trackEvent({
    category: MatomoCategory.CHALLENGE,
    action: MatomoAction.CHALLENGE_TYPE_CANCEL,
    name: theme
  });
};

export const trackMatomoChallengeFinish = (
  trackEvent: TrackEventHandler,
  title: string,
  theme: string
) => {
  trackEvent({
    category: MatomoCategory.CHALLENGE,
    action: MatomoAction.CHALLENGE_FINISH,
    name: title
  });

  trackEvent({
    category: MatomoCategory.CHALLENGE,
    action: MatomoAction.CHALLENGE_TYPE_FINISH,
    name: theme
  });
};

export const trackMatomoChallengeThemeSelected = (
  trackEvent: TrackEventHandler,
  theme?: IUserTheme
) => {
  trackEvent({
    category: MatomoCategory.CHALLENGE,
    action: MatomoAction.CHALLENGE_THEME_SELECTED,
    name: theme?.theme.title
  });
};

export const trackMatomoContactCallback = (trackEvent: TrackEventHandler, type: string) => {
  trackEvent({
    category: MatomoCategory.CONTACT,
    action: MatomoAction.CONTACT_CALLBACK,
    name: type
  });
};

export const trackMatomoPollVote = (trackEvent: TrackEventHandler, poll: IPoll, answer: string) => {
  trackEvent({
    category: MatomoCategory.POLL,
    action: poll.title,
    name: poll.answers.find((a) => a.id === answer)?.answer || ''
  });
};

export const trackMatomoRewardOpen = (
  trackEvent: TrackEventHandler,
  title: string,
  category: string
) => {
  trackEvent({
    category: MatomoCategory.REWARD,
    action: MatomoAction.REWARD_OPEN,
    name: title
  });

  trackEvent({
    category: MatomoCategory.REWARD,
    action: MatomoAction.REWARD_OPEN_CATEGORY,
    name: category
  });
};

export const trackMatomoRewardClaim = (
  trackEvent: TrackEventHandler,
  title: string,
  category: string
) => {
  trackEvent({
    category: MatomoCategory.REWARD,
    action: MatomoAction.REWARD_CLAIM,
    name: title
  });

  trackEvent({
    category: MatomoCategory.REWARD,
    action: MatomoAction.REWARD_CLAIM_CATEGORY,
    name: category
  });
};

export const trackMatomoTodoCreate = (
  trackEvent: TrackEventHandler,
  customTitle?: string,
  description?: string,
  expectation?: string
) => {
  if (!!customTitle && !!description && !!expectation) {
    trackEvent({
      category: MatomoCategory.TODO,
      action: MatomoAction.TODO_CREATED,
      name: customTitle
    });
  }
};

export const trackMatomoMotivatorHide = (
  trackEvent: TrackEventHandler,
  title: string,
  hide?: boolean
) => {
  trackEvent({
    category: MatomoCategory.MOTIVATOR,
    action: hide ? MatomoAction.MOTIVATOR_CANCEL : title,
    name: hide ? title : 'Pagina geopend'
  });
};

export const trackMatomoMotivatorSubmit = (
  trackEvent: TrackEventHandler,
  title: string,
  answer: string,
  answers?: IUserPopupAnswer[]
) => {
  let name = String(answer);

  if (answers && answers.length > 0) {
    const matchedAnswer = answers.find((a) => a.id === answer);

    if (matchedAnswer) {
      name = matchedAnswer.text;
    }
  }

  trackEvent({
    category: MatomoCategory.MOTIVATOR,
    action: title,
    name: name
  });
};

export const trackMatomoActivityFinish = (
  trackEvent: TrackEventHandler,
  isUserActivity: boolean,
  like: boolean,
  title: string,
  type?: string
) => {
  if (isUserActivity) {
    trackEvent({
      category: MatomoCategory.TODO,
      action: like ? MatomoAction.TODO_LIKE : MatomoAction.TODO_DISLIKE,
      name: title
    });
  } else {
    trackEvent({
      category: MatomoCategory.ACTIVITY,
      action: like ? MatomoAction.ACTIVITY_LIKE : MatomoAction.ACTIVITY_DISLIKE,
      name: title
    });

    trackEvent({
      category: MatomoCategory.ACTIVITY,
      action: like ? MatomoAction.ACTIVITY_TYPE_LIKE : MatomoAction.ACTIVITY_TYPE_DISLIKE,
      name: type || ''
    });
  }
};

export const trackMatomoActivityShare = (
  trackEvent: TrackEventHandler,
  title: string,
  type: string
) => {
  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: MatomoAction.ACTIVITY_SHARE,
    name: title
  });

  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: MatomoAction.ACTIVITY_TYPE_SHARE,
    name: type
  });
};

export const trackMatomoActivityPin = (
  trackEvent: TrackEventHandler,
  title: string,
  pinned: boolean,
  type: string
) => {
  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: !pinned ? MatomoAction.ACTIVITY_PIN : MatomoAction.ACTIVITY_UNPIN,
    name: title
  });

  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: !pinned ? MatomoAction.ACTIVITY_TYPE_PIN : MatomoAction.ACTIVITY_TYPE_UNPIN,
    name: type
  });
};

export const trackMatomoActivityOpen = (
  trackEvent: TrackEventHandler,
  title: string,
  type: string
) => {
  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: MatomoAction.ACTIVITY_OPEN,
    name: title
  });

  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: MatomoAction.ACTIVITY_TYPE_OPEN,
    name: type
  });
};

export const trackMatomoActivityRead = (
  trackEvent: TrackEventHandler,
  title: string,
  type: string
) => {
  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: MatomoAction.ACTIVITY_READ,
    name: title
  });

  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: MatomoAction.ACTIVITY_TYPE_READ,
    name: type
  });
};

export const trackMatomoActivitySearch = (trackEvent: TrackEventHandler, search: string) => {
  trackEvent({
    category: MatomoCategory.ACTIVITY,
    action: MatomoAction.ACTIVITY_SEARCH,
    name: search
  });
};

export const trackMatomoActivityFilter = (
  trackEvent: TrackEventHandler,
  label: string,
  checked: boolean
) => {
  if (checked) {
    trackEvent({
      category: MatomoCategory.ACTIVITY,
      action: MatomoAction.ACTIVITY_FILTER,
      name: label
    });
  }
};
