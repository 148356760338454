import { QuestionnaireQuestionType } from '@Services/QuestionnaireSubmission';

import {
  QuestionnaireFormCarousel,
  QuestionnaireFormDropdown,
  QuestionnaireFormInputProps,
  QuestionnaireFormRadio,
  QuestionnaireFormSlider,
  QuestionnaireFormSliderStep
} from './internal/components';
import { QuestionnaireFormProps } from './QuestionnaireForm.props';

export const QuestionnaireForm = ({ question, ...inputProps }: QuestionnaireFormProps) => {
  const formInputProps = { question, ...inputProps } as QuestionnaireFormInputProps;

  switch (question?.type) {
    case QuestionnaireQuestionType.CAROUSEL:
      return <QuestionnaireFormCarousel {...formInputProps} />;

    case QuestionnaireQuestionType.DROPDOWN:
      return <QuestionnaireFormDropdown {...formInputProps} />;

    case QuestionnaireQuestionType.SLIDER:
      return <QuestionnaireFormSlider {...formInputProps} />;

    case QuestionnaireQuestionType.SLIDER_STEP:
    case QuestionnaireQuestionType.SLIDER_EMOJI:
      return <QuestionnaireFormSliderStep {...formInputProps} />;

    case QuestionnaireQuestionType.RADIO:
      return <QuestionnaireFormRadio {...formInputProps} />;

    default:
      return null;
  }
};
