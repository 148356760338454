import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ListProgressTheme } from '@Components/list';
import {
  Anchor,
  Box,
  Highlight,
  SliderFullPage,
  SliderNavigation,
  Text,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

import { SelfTestResultsNegativeSlideProps } from './SelfTestResultsNegativeSlide.props';

export const SelfTestResultsNegativeSlide = ({
  userThemes,
  isFinalSlide,
  hasPreviousSlide
}: SelfTestResultsNegativeSlideProps) => {
  const { total } = useSliderFullPageContext();
  const { index, slidePrev, slideNext } = useSliderFullPageItemContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNext = useCallback(() => {
    if (!isFinalSlide) {
      slideNext();
    } else {
      navigate(AppRoutes.getInsightChallengesAdd().to);
    }
  }, [isFinalSlide, slideNext, navigate]);

  const getNextLabel = () => {
    if (isFinalSlide) {
      return t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.NEGATIVE.PROCEED');
    }

    return t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.NEGATIVE.PROCEED_ALT');
  };

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        superTitle={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.NAME')}
        title={
          <>
            {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.NEGATIVE.TITLE.PRE_CIRCLE') + ' '}

            <Highlight>
              {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.NEGATIVE.TITLE.CIRCLED')}
            </Highlight>
          </>
        }
        intro={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.NEGATIVE.DESCRIPTION')}
        isLarge
      />

      <SliderFullPage.Block
        title={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.NEGATIVE.RESULT_TITLE')}
        isLarge
      >
        <Box mb={3}>
          <ListProgressTheme items={userThemes} hasLegend />
        </Box>

        {isFinalSlide && (
          <Box mb={3}>
            <Text.Normal>
              {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.NEGATIVE.RESULT_DESCRIPTION') + ' '}

              <Anchor to={AppRoutes.getDashboard().to}>
                {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.NEGATIVE.RESULT_DESCRIPTION_LINK')}
              </Anchor>
            </Text.Normal>
          </Box>
        )}

        <SliderNavigation
          current={index + 1}
          total={total}
          onPrevious={hasPreviousSlide ? slidePrev : undefined}
          onNext={handleNext}
          nextLabel={getNextLabel()}
        />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
