import { IconName } from '@fortawesome/fontawesome-common-types';
import cn from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TagAnimated, Text, Thumbnail } from '@Components/ui';
import { useHover } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';
import { TimelineItemType } from '@Services/Insight';

import styles from './TimelineActivity.module.scss';
import { TimelineActivityProps } from './TimelineActivity.props';

export const TimelineActivity = memo(
  ({ activity, isActive = false, className }: TimelineActivityProps) => {
    const { t } = useTranslation();
    const { id, thumbnail, title, slug, points, type, activityType, completedAt } = activity;

    const { isHovering, handlers } = useHover();

    const isCustom = type === TimelineItemType.CUSTOM_ACTIVITY;
    const isCompleted = completedAt !== null;
    const hasPoints = points !== null;

    const getTitle = () => {
      if (isCustom) {
        return t('DOMAIN.USER_ACTIVITY.TITLE', { title });
      }

      return title;
    };

    const getTo = () => {
      if (isCustom) {
        return AppRoutes.getTodoUserActivity(id).to;
      }

      return AppRoutes.getActivity(slug ?? '').to;
    };

    return (
      <Link to={getTo()} className={cn(styles.TimelineActivity, className)} {...handlers}>
        <Thumbnail
          thumbnail={thumbnail}
          alt={title}
          icon={activityType?.[0].icon as IconName | undefined}
          isHovering={isHovering}
        />

        <div className={styles.Content}>
          <Text.H5 className={styles.Title}>{getTitle()}</Text.H5>

          {hasPoints && isCompleted && <TagAnimated points={points} shouldAnimate={isActive} />}
        </div>
      </Link>
    );
  }
);
