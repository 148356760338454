import cn from 'classnames';
import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';

import { ButtonIcon, Icon, Text } from '@Components/ui';

import styles from './InputSelect.module.scss';
import { InputSelectProps } from './InputSelect.props';

const CustomOption = (props: any) => {
  const classNames = cn(styles.Option, {
    [styles.isSelected]: props.isSelected,
    [styles.isFocused]: props.isFocused,
    [styles.isDisabled]: props.isDisabled
  });

  const handleClick = useCallback(() => {
    props.selectOption(props.data);
  }, [props]);

  return (
    <>
      <div className={classNames} onClick={handleClick} data-testid={`value_${props.data.value}`}>
        <div className={styles.Checkmark}>
          <Icon icon={['fas', 'check']} />
        </div>

        <div className={styles.Label}>{props.children}</div>
      </div>

      <div className={styles.Seperator}></div>
    </>
  );
};

const CustomDropdownIndicator = () => {
  return <ButtonIcon icon="chevron-down" />;
};

const CustomNoOptionsMessage = () => {
  const { t } = useTranslation();

  return (
    <Text.Normal className={styles.NoOptions}>
      {t('COMPONENTS.INPUT_SELECT.NO_RESULTS_FOUND')}
    </Text.Normal>
  );
};

export const InputSelect = forwardRef(
  (
    {
      options,
      className,
      isError,
      isValid,
      onChange,
      onBlur,
      isDisabled,
      name,
      value,
      placeholder
    }: InputSelectProps,
    ref?: any
  ) => {
    const { t } = useTranslation();

    const hasScrollbar = options.length > 7;

    const classNames = cn(styles.InputSelect, className, {
      [styles.isError]: isError,
      [styles.isValid]: isValid,
      [styles.isDisabled]: isDisabled
    });

    const customStyles = {
      control: (_: any, { menuIsOpen, isFocused }: any) => ({
        borderColor:
          (menuIsOpen || isFocused) && `var(--color-${isError ? 'error' : 'success'}) !important`,
        outline:
          menuIsOpen || isFocused
            ? `1px solid var(--color-${isError ? 'error' : 'success'}-light) !important`
            : '1px solid transparent !important'
      }),
      menuList: (base: any) => {
        if (hasScrollbar) {
          return {
            ...base,
            paddingBottom: '25px'
          };
        }

        return base;
      },
      menu: (base: any) => {
        if (hasScrollbar) {
          return {
            ...base,
            position: 'relative',

            '::after': {
              position: 'absolute',
              content: '""',
              background: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%)',
              width: '100%',
              height: '50px',
              inset: 'auto 0 0 0',
              pointerEvents: 'none',
              textAlign: 'center'
            }
          };
        }

        return base;
      }
    };

    const handleChange = useCallback(
      (newValue: SingleValue<{ value: string; label: string }>) => {
        if (onChange && newValue) {
          onChange(newValue.value);
        }
      },
      [onChange]
    );

    return (
      <Select
        ref={ref}
        name={name}
        value={value}
        formatOptionLabel={(data) => <span dangerouslySetInnerHTML={{ __html: data.label }} />}
        options={options}
        className={classNames}
        styles={customStyles}
        classNamePrefix="rc-select"
        onChange={handleChange}
        isSearchable={false}
        onBlur={onBlur}
        isDisabled={isDisabled}
        menuPosition="fixed"
        menuPortalTarget={document.body}
        isOptionDisabled={(option) => !!option.disabled}
        placeholder={placeholder || t('COMPONENTS.INPUT_SELECT.PLACEHOLDER')}
        menuShouldScrollIntoView={true}
        menuShouldBlockScroll={true}
        components={{
          Option: CustomOption,
          NoOptionsMessage: CustomNoOptionsMessage,
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: null
        }}
      />
    );
  }
);
