import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Form,
  SliderFullPage,
  SliderNavigation,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';

import { UserActivityFormTextareaSlideProps } from './UserActivityFormTextareaSlide.props';

export const UserActivityFormTextareaSlide = ({
  hasSubmit,
  fieldName,
  translationName
}: UserActivityFormTextareaSlideProps) => {
  const { t } = useTranslation();
  const { total, current, slidePrev, slideNext } = useSliderFullPageContext();
  const { index } = useSliderFullPageItemContext();
  const { trigger } = useFormContext();

  const TRANSLATION = t(`DOMAIN.USER_ACTIVITY.FORM.${translationName}`, {
    returnObjects: true
  });

  const handleNext = useCallback(async () => {
    const isValid = await trigger(fieldName);

    if (isValid) {
      slideNext();
    }
  }, [trigger, fieldName, slideNext]);

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        title={TRANSLATION.TITLE}
        intro={TRANSLATION.INTRO}
        superTitle={t('DOMAIN.USER_ACTIVITY.FORM.SUPER_TITLE')}
      />

      <SliderFullPage.Block title={TRANSLATION.LABEL}>
        <Box mb={9}>
          <Form.Input.Textarea
            name={fieldName}
            data-testid={`${fieldName}Input`}
            options={{
              placeholder: TRANSLATION.PLACEHOLDER
            }}
          />
        </Box>

        <SliderNavigation
          current={current + 1}
          total={total}
          onPrevious={index > 0 ? slidePrev : undefined}
          onNext={hasSubmit ? undefined : handleNext}
          nextLabel={TRANSLATION.SUBMIT}
        />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
