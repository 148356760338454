import cn from 'classnames';
import { useMemo } from 'react';

import { Icon } from '@Components/ui';

import styles from './StepIndicator.module.scss';
import { StepIndicatorProps } from './StepIndicator.props';

export const StepIndicator = ({
  total,
  current,
  completed = 0,
  isDisabled,
  className
}: StepIndicatorProps) => {
  const steps = useMemo(() => {
    return new Array(total).fill(null).map((_, idx) => {
      return (
        <span
          key={idx}
          className={cn(styles.Step, {
            [styles.isDisabled]: isDisabled,
            [styles.isActive]: idx < current,
            [styles.wasActive]: idx < completed
          })}
        >
          <Icon icon={['fas', 'check']} size="sm" className={styles.Icon} />
        </span>
      );
    });
  }, [total, isDisabled, current, completed]);

  return <div className={cn(styles.StepIndicator, className)}>{steps}</div>;
};
