import { useTranslation } from 'react-i18next';

import { Box, Loader, Quote, Text } from '@Components/ui';
import { useQuoteCurrent } from '@Hooks/quote';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import styles from './DashboardQuote.module.scss';
import { DashboardQuoteProps } from './DashboardQuote.props';

export const DashboardQuote = ({ className }: DashboardQuoteProps) => {
  const { t } = useTranslation();

  const {
    data: quote,
    isSuccess: isQuoteSuccess,
    isLoading: isQuoteLoading,
    isError: isQuoteError
  } = useQuoteCurrent();

  return (
    <DashboardBlock widgetKey="QUOTE" hasTitle={false} className={className}>
      {quote && isQuoteSuccess && (
        <Quote className={styles.Quote} block author={quote.author} quote={quote.citation} />
      )}

      {isQuoteError && <Text.Error>{t('DOMAIN.DASHBOARD.BLOCKS.QUOTE.ERROR')}</Text.Error>}

      {isQuoteLoading && (
        <Box my={8}>
          <Loader>{t('DOMAIN.DASHBOARD.BLOCKS.QUOTE.LOADING')}</Loader>
        </Box>
      )}
    </DashboardBlock>
  );
};
