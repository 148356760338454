import { Font as PdfFont } from '@react-pdf/renderer';

import verdana from '@Assets/fonts/verdana/verdana.ttf';
import verdanaBold from '@Assets/fonts/verdana/verdana-bold.ttf';
import appleTouchIconArb from '@Assets/images/apple-touch-icon-arb.png';
import faviconArbIco from '@Assets/images/favicon-arb.ico';
import logoArbPng from '@Assets/images/logo-arb.png';
import logoArbFooter from '@Assets/images/logo-arb-footer.svg';
import { Theme } from '@Constants/themes';

export const ARBONED: Theme = {
  background: {
    fade: {
      default: 'none'
    },
    highlight: {
      default: 'none',
      fullscreen: 'none'
    }
  },

  color: {
    primary: {
      light: '#3eb9e5',
      default: '#34b6e4'
    },
    secondary: {
      light: '#0094c0',
      default: '#0088b0',
      dark: '#0081a7',
      opaque: '#0088b030'
    },
    tertiary: {
      default: '#ff9000',
      dark: '#f28800',
      color: '#fff',
      opaque: '#ff900030'
    },
    background: {
      default: '#f4fcff',
      dark: '#b5eeff'
    },
    font: {
      light: '#000000',
      default: '#000000',
      transparant: 'rgba(0,0,0,.64)'
    },

    frame: { default: '#fff' },

    gray: {
      default: '#dfdfdf',
      dark: '#A5A5A5',
      light: '#f5f5f5',
      background: '#f9f9f9'
    },
    white: {
      default: '#fff',
      transparant: 'rgba(255, 255, 255, 0.24)'
    },
    black: {
      default: '#000'
    },

    red: { default: '#e61818' },
    orange: { default: '#ff9300' },
    yellow: { default: '#ffe066' },
    green: { default: '#47c869', light: '#7aec98' },

    error: { default: '#e61818', light: '#fef6f6', transparant: '#e61818bb' },
    warning: { default: '#ff9300' },
    success: { default: '#34b6e4', light: '#f4fcff' },
    info: { default: '#fe4a98' }
  },

  shadow: {
    light: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
    small: '0 2px 4px 0 rgba(0, 0, 0, 0.24)',
    medium: '10px 7px 16px 0 rgba(0, 0, 0, 0.19)',
    mediumCentered: '0 7px 16px 0 rgba(0, 0, 0, 0.19)',
    mediumLeft: '-10px 0 16px 0 rgba(0, 0, 0, 0.19)',
    large: '16px 32px 40px 0 rgba(0, 0, 0, 0.16)'
  },

  font: {
    family: {
      base: 'Verdana',
      heading: 'Verdana',
      handwritten: 'Caveat'
    },

    h1: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '42px',
      lineHeight: '1.14'
    },

    h2: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '1.14'
    },

    h3: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '1.33'
    },

    h4: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '1.19'
    },

    h5: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '1.33'
    },

    intro: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '1.71'
    },

    large: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '1.71'
    },

    normal: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    medium: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    bold: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    small: {
      color: '#000000',
      fontFamily: 'Verdana',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '1.6'
    },

    micro: {
      color: '#bfbebe',
      fontFamily: 'Verdana',
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '1'
    },

    handwritten: {
      color: '#0088b0',
      fontFamily: 'Caveat',
      fontWeight: '600',
      fontSize: '25px',
      lineHeight: '0.96'
    }
  },

  radius: {
    tag: '0',
    tagExtraSmall: '0',
    buttonGroup: '0',
    alert: '16px',
    buttonIcon: '100px',
    inputRadio: '0',
    inputText: '2px',
    inputLike: '0',
    counter: '0',
    countNotifier: '100px',
    toolTip: '3px',
    button: '0',
    avatar: '22px',
    avatarSmall: '14px',
    medal: '8px',
    dropdown: '2px',
    inboxItem: '0',
    motivator: '0',
    accordion: '0',
    modal: '0',
    block: '0',
    blockElevated: '0',
    card: '0',
    progressTheme: '0',
    imageResize: '0',
    thumbnailTextRow: '0',
    selectActivityBlock: '0',
    sidebarCta: '0'
  },

  assets: {
    logo: require('@Assets/images/logo-arb.svg'),
    logoPng: logoArbPng,
    logoFooter: logoArbFooter,
    faviconIco: faviconArbIco,
    appleTouchIcon: appleTouchIconArb,
    icon: require('@Assets/images/logo-small-arb.svg')
  },

  strings: {
    phone: '06 8349 3748',
    mail: 'support-arboned@kom-verder.nl',
    address: 'Zwarte Woud 10',
    postcalCode: '3524 SJ Utrecht',
    websiteUrl: 'https://arboned.kom-verder.nl/',
    privacyUrl: 'https://arboned.kom-verder.nl/privacy-statement/',
    personalUrl: 'https://arboned.kom-verder.nl/gegevensverwerking/',
    termsUrl: 'https://arboned.kom-verder.nl/gebruiksvoorwaarden/'
  },

  easing: {
    out: 'cubic-bezier(0.2, 0.4, 0, 1)',
    in: 'cubic-bezier(1, 0, 0.8, 0.6)',
    inOut: 'cubic-bezier(0.66, 0.07, 0.33, 0.94)'
  },

  spacing: {
    base: 8,
    tagTransformY: '0'
  }
};

PdfFont.register({
  family: 'Verdana',
  fonts: [
    {
      src: verdana,
      fontWeight: 'normal',
      fontStyle: 'normal'
    },
    {
      src: verdanaBold,
      fontWeight: 'bold',
      fontStyle: 'normal'
    }
  ]
});
