import { IChallenge } from '@Services/Challenge';

export const countCompletedActivities = (challenge: IChallenge) => {
  return challenge.activities.reduce((activitiesCount, activity) => {
    if (activity.completed) {
      return activitiesCount + 1;
    }

    return activitiesCount;
  }, 0);
};
