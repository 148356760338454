import { Form as BaseForm } from './Form/Form';
import { FormAccordion } from './FormAccordion/FormAccordion';
import { FormCarousel } from './FormCarousel/FormCarousel';
import { FormCheckbox } from './FormCheckbox/FormCheckbox';
import { FormCode } from './FormCode/FormCode';
import { FormError } from './FormError/FormError';
import { FormField } from './FormField/FormField';
import { FormImage } from './FormImage/FormImage';
import { FormLike } from './FormLike/FormLike';
import { FormPassword } from './FormPassword/FormPassword';
import { FormRadio } from './FormRadio/FormRadio';
import { FormSelect } from './FormSelect/FormSelect';
import { FormSlider } from './FormSlider/FormSlider';
import { FormText } from './FormText/FormText';
import { FormTextarea } from './FormTextarea/FormTextarea';
import { FormToggle } from './FormToggle/FormToggle';

export * from './FormAccordion/FormAccordion';
export * from './FormAccordion/FormAccordion.props';

export * from './FormField/FormField';
export * from './FormField/FormField.props';

export * from './InputText/InputText';
export * from './InputText/InputText.props';

export * from './InputCode/InputCode';
export * from './InputCode/InputCode.props';

export * from './InputPassword/InputPassword';
export * from './InputPassword/InputPassword.props';

export * from './InputRadio/InputRadio';
export * from './InputRadio/InputRadio.props';

export * from './InputTextarea/InputTextarea';
export * from './InputTextarea/InputTextarea.props';

export * from './InputSelect/InputSelect';
export * from './InputSelect/InputSelect.props';

export * from './InputSlider/InputSlider';
export * from './InputSlider/InputSlider.props';

export * from './InputLike/InputLike';
export * from './InputLike/InputLike.props';

export * from './InputCheckbox/InputCheckbox';
export * from './InputCheckbox/InputCheckbox.props';

export * from './InputToggle/InputToggle';
export * from './InputToggle/InputToggle.props';

export * from './InputCarousel/InputCarousel';
export * from './InputCarousel/InputCarousel.props';

export * from './InputImage/InputImage';
export * from './InputImage/InputImage.props';

export * from './InputField/InputField';
export * from './InputField/InputField.props';

export * from './Form/Form.props';

export const Form = {
  Form: BaseForm,

  Layout: {
    Error: FormError,
    Field: FormField,
    Accordion: FormAccordion
  },

  Input: {
    Text: FormText,
    Textarea: FormTextarea,
    Password: FormPassword,
    Code: FormCode,
    Radio: FormRadio,
    Checkbox: FormCheckbox,
    Toggle: FormToggle,
    Select: FormSelect,
    Slider: FormSlider,
    Like: FormLike,
    Carousel: FormCarousel,
    Image: FormImage
  }
};
