import { isFuture } from 'date-fns';

import { groupItemsByCompletion } from '../../utils/grouping';
import { TimelineGroup } from '../TimelineGroup/TimelineGroup';
import { TimelineList } from '../TimelineList/TimelineList';
import { TimelineTypeGroupProps } from './TimelineTypeGroup.props';

export const TimelineTypeGroup = ({ group, currentMiddle, day }: TimelineTypeGroupProps) => {
  const isFutureDate = isFuture(day);

  return (
    <>
      {groupItemsByCompletion(group).map((completionGroup, idx) => {
        if (completionGroup.length > 0) {
          return (
            <TimelineGroup key={idx} isFuture={isFutureDate} currentMiddle={currentMiddle}>
              <TimelineList
                items={completionGroup}
                day={day}
                isCompleted={idx > 0}
                currentMiddle={currentMiddle}
              />
            </TimelineGroup>
          );
        }

        return null;
      })}
    </>
  );
};
