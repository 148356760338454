import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { setupTheme } from '@Utils/PlatformUtils';
import { setupSentry } from '@Utils/SentryUtils';
import { setupTranslation } from '@Utils/TranslationUtils';
import { setupYup } from '@Utils/YupUtils';

import { App } from './App';

setupSentry();
setupTranslation();
setupTheme();
setupYup();

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,

  document.getElementById('root')
);
