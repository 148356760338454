import cn from 'classnames';
import { useCallback, useEffect } from 'react';

import { useBoolean, usePlatform, usePlayInView } from '@Hooks/common';
import { toLocaleString } from '@Utils/StringUtils';

import { Icon, Tag } from '..';
import styles from './TagAnimated.module.scss';
import { TagAnimatedProps } from './TagAnimated.props';

export const TagAnimated = ({ points, shouldAnimate, size, className }: TagAnimatedProps) => {
  const { value: isAnimating, setValue: setAnimating } = useBoolean(shouldAnimate ?? false);

  const { color } = usePlatform();

  useEffect(() => {
    setAnimating(shouldAnimate ?? false);
  }, [shouldAnimate, setAnimating]);

  const handleTrigger = useCallback(() => {
    if (shouldAnimate === undefined) {
      setAnimating(true);
    }
  }, [setAnimating, shouldAnimate]);

  const [inViewRef] = usePlayInView(handleTrigger);

  return (
    <div
      ref={inViewRef}
      className={cn(
        styles.TagAnimated,
        {
          [styles.isActive]: isAnimating
        },
        className
      )}
    >
      <div className={styles.Arrows}>
        {Array(20)
          .fill(null)
          .map((_, idx) => {
            const leftOffset = 15 + Math.floor(Math.random() * 70);
            const delay = Math.floor(Math.random() * 500);

            return (
              <Icon
                key={idx}
                icon="long-arrow-up"
                color={color.success.default}
                className={styles.Arrow}
                style={{ left: `${leftOffset}%`, animationDelay: `${delay}ms` }}
              />
            );
          })}
      </div>

      <Tag
        label={toLocaleString(points ?? '')}
        color={isAnimating ? color.success.default : color.primary.default}
        icon="star"
        size={size}
        className={styles.Tag}
      />
    </div>
  );
};
