export const scrollTo = (top: number) => {
  // Ensure we are in a browser and not Jest
  if (window.scrollTo) {
    window.scrollTo({
      top,
      behavior: 'smooth'
    });
  }
};

export const scrollToTop = () => {
  scrollTo(0);
};
