import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CardImage, Checkmark } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

import { TimelineRewardProps } from './TimelineReward.props';

export const TimelineReward = ({ reward, className }: TimelineRewardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(AppRoutes.getRewards().to);
  }, [navigate]);

  return (
    <CardImage
      title={reward.title}
      image={reward.thumbnail}
      onClick={handleClick}
      className={className}
    >
      <Checkmark label={t('DOMAIN.REWARD.REDEEMED')} />
    </CardImage>
  );
};
