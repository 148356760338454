import cn from 'classnames';

import { Normal } from '../Normal/Normal';
import styles from './Error.module.scss';
import { ErrorProps } from './Error.props';

export const Error = ({ className, ...props }: ErrorProps) => {
  const classNames = cn(styles.Error, className);

  return <Normal className={classNames} {...props} />;
};
