import COMMON from './common';
import COMPONENTS from './components';
import DOCUMENTS from './documents';
import DOMAIN from './domain';

const data = {
  translation: {
    COMPONENTS,
    DOMAIN,
    COMMON,
    DOCUMENTS
  }
};

export default data;
