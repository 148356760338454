import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteData } from 'react-query';

import { Anchor, Block, Spacer, Text } from '@Components/ui';
import { ListResponse } from '@Constants/interfaces';
import { AppRoutes } from '@Navigation/Routes';
import { IReward, RewardListRequest } from '@Services/Reward';

import { RewardFilter } from '../RewardFilter/RewardFilter';
import { RewardList } from '../RewardList/RewardList';
import styles from './RewardBlock.module.scss';
import { RewardBlockProps } from './RewardBlock.props';

export const RewardBlock = ({ onClick, className }: RewardBlockProps) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<RewardListRequest>();
  const [data, setData] = useState<InfiniteData<ListResponse<IReward>>>();

  return (
    <Block className={cn(styles.RewardBlock, className)}>
      <Text.H3 className={styles.Title}>{t('DOMAIN.REWARD.OVERVIEW.TITLE')}</Text.H3>

      <Text.Large>
        {t('DOMAIN.REWARD.OVERVIEW.DESCRIPTION_1')}{' '}
        <Anchor to={AppRoutes.getInsightChallenges().to}>
          {AppRoutes.getInsightChallenges().label}
        </Anchor>{' '}
        {t('DOMAIN.REWARD.OVERVIEW.DESCRIPTION_2')}{' '}
        <Anchor to={AppRoutes.getActivities().to}>{AppRoutes.getActivities().label}</Anchor>{' '}
        {t('DOMAIN.REWARD.OVERVIEW.DESCRIPTION_3')}
      </Text.Large>

      <RewardFilter onChange={setFilter} count={data?.pages[0].total} />

      <Spacer top={2} bottom={3} />

      {!!filter && <RewardList onLoad={setData} onClick={onClick} filter={filter} />}
    </Block>
  );
};
