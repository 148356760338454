import { createContext, ReactNode, useContext } from 'react';

import { AppError } from '@Constants/enums';

const questionnaireContext = createContext<QuestionnaireContext | null>(null);

export const useQuestionnaireContext = () => {
  const contextValue = useContext(questionnaireContext);

  if (!contextValue) {
    throw new Error(AppError.NO_CONTEXT_SET);
  }

  return contextValue;
};

export const QuestionnaireProvider = ({ value, children }: QuestionnaireProviderProps) => {
  return <questionnaireContext.Provider value={value}>{children}</questionnaireContext.Provider>;
};

interface QuestionnaireContext {
  goToPrevious: () => void;
  submit: (answers?: any) => Promise<void>;
  finish: () => Promise<void>;
  isLoadingNext: boolean;
  isLoadingPrevious: boolean;
}

interface QuestionnaireProviderProps {
  value: QuestionnaireContext;
  children: ReactNode | ReactNode[];
}
