import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Anchor, Button, Form, Text } from '@Components/ui';
import { MAX_FIRSTNAME_LENGTH, MAX_SURNAME_LENGTH } from '@Constants/configs';
import { useApiError, usePersistSearchParam, usePlatform } from '@Hooks/common';
import { useOnboardingRegister } from '@Hooks/onboarding';
import { useSecurityQuestions } from '@Hooks/SecurityQuestion';
import { onboardingService } from '@Services/Onboarding';
import { ISecurityQuestion } from '@Services/SecurityQuestion';
import { objectToInputOptions } from '@Utils/FormUtils';
import Yup, { AsyncValidationCall, getAsyncValidation } from '@Utils/YupUtils';

import {
  OnboardingRegisterFormProps,
  OnboardingRegisterFormValues
} from './OnboardingRegisterForm.props';

export const OnboardingRegisterForm = ({ onSuccess, licenseCode }: OnboardingRegisterFormProps) => {
  const { t } = useTranslation();
  const { strings } = usePlatform();
  const { handleError } = useApiError();
  const onboardingRegisterMutation = useOnboardingRegister();
  const securityQuestions = useSecurityQuestions();
  const { getPersistedSearchParam } = usePersistSearchParam('token');
  const token = getPersistedSearchParam();

  const handleVerifyEmail = useCallback(
    async (value: string): Promise<AsyncValidationCall> => {
      const validateUnique = await onboardingService.validateEmail({
        licenseCode: licenseCode,
        email: value
      });

      return validateUnique;
    },
    [licenseCode]
  );

  const handleSubmit = useCallback(
    async (values: OnboardingRegisterFormValues) => {
      try {
        const response = await onboardingRegisterMutation.mutateAsync({
          licenseCode: licenseCode,
          ...values
        });

        onSuccess(response.userId, values.email, values.phoneNumber);
      } catch (e) {
        handleError(e);
      }
    },
    [licenseCode, handleError, onSuccess, onboardingRegisterMutation]
  );

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(MAX_FIRSTNAME_LENGTH).required(),
    lastName: Yup.string().max(MAX_SURNAME_LENGTH).required(),
    phoneNumber: Yup.string().phone().required(),
    email: getAsyncValidation(handleVerifyEmail, {
      schema: Yup.string().email().required()
    }),
    password: Yup.string().password().required(),
    passwordConfirmation: Yup.string().password('password').required(),
    securityQuestionId: Yup.string().required(),
    securityQuestionAnswer: Yup.string().required(),
    privacy: Yup.array().length(2).required()
  });

  const securityQuestionsOptions = useMemo(() => {
    return objectToInputOptions<ISecurityQuestion>(securityQuestions.data, 'question');
  }, [securityQuestions.data]);

  const privacyOptions = useMemo(() => {
    return [
      {
        label: (
          <Text.Normal>
            {t(
              'DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PRIVACY.PRIVACY_STATEMENT.TEXT_1'
            ) + ' '}
            <Anchor href={token ? strings.personalUrlAlternative : strings.personalUrl}>
              {t(
                'DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PRIVACY.PRIVACY_STATEMENT.TEXT_2'
              )}
            </Anchor>
            {t(
              'DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PRIVACY.PRIVACY_STATEMENT.TEXT_3'
            ) + ' '}
            <Anchor href={token ? strings.privacyUrlAlternative : strings.privacyUrl}>
              {t(
                'DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PRIVACY.PRIVACY_STATEMENT.TEXT_4'
              )}
            </Anchor>
          </Text.Normal>
        ),

        value: 'privacyStatement'
      },

      {
        label: (
          <Text.Normal>
            {t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PRIVACY.TERMS_OF_USE.TEXT_1') +
              ' '}

            <Anchor href={token ? strings.termsUrlAlternative : strings.termsUrl}>
              {t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PRIVACY.TERMS_OF_USE.TEXT_2')}
            </Anchor>
          </Text.Normal>
        ),

        value: 'termsOfUse'
      }
    ];
  }, [t, strings, token]);

  return (
    <Form.Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form.Input.Text
        name="firstName"
        data-testid="firstNameInput"
        label={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.FIRST_NAME.LABEL')}
      />

      <Form.Input.Text
        name="lastName"
        data-testid="lastNameInput"
        label={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.LAST_NAME.LABEL')}
      />

      <Form.Input.Text
        name="phoneNumber"
        data-testid="phoneNumberInput"
        label={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PHONE_NUMBER.LABEL')}
        description={t(
          'DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PHONE_NUMBER.DESCRIPTION'
        )}
        options={{
          type: 'tel'
        }}
      />

      <Form.Input.Text
        name="email"
        data-testid="emailInput"
        label={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.EMAIL.LABEL')}
        description={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.EMAIL.DESCRIPTION')}
        options={{
          type: 'email'
        }}
      />

      <Form.Input.Password
        name="password"
        data-testid="passwordInput"
        label={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PASSWORD.LABEL')}
        description={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PASSWORD.DESCRIPTION')}
      />

      <Form.Input.Password
        name="passwordConfirmation"
        data-testid="passwordConfirmationInput"
        label={t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.PASSWORD_CONFIRMATION.LABEL')}
      />

      {securityQuestions.isFetched && securityQuestions.data && (
        <>
          <Form.Input.Select
            name="securityQuestionId"
            data-testid="securityQuestionIdInput"
            label={t(
              'DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.SECURITY_QUESTION_ID.LABEL'
            )}
            options={{
              options: securityQuestionsOptions
            }}
          />

          <Form.Input.Text
            name="securityQuestionAnswer"
            data-testid="securityQuestionAnswerInput"
            label={t(
              'DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.FIELDS.SECURITY_QUESTION_ANSWER.LABEL'
            )}
          />
        </>
      )}

      <Form.Input.Checkbox
        name="privacy"
        data-testid="privacyInput"
        shouldHideError
        options={{
          options: privacyOptions
        }}
      />

      <Button type="submit" icon="arrow-right" data-testid="registerSubmit">
        {t('DOMAIN.ONBOARDING.REGISTRATION.REGISTER.FORM.SUBMIT')}
      </Button>
    </Form.Form>
  );
};
