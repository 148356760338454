import { DataGetGgcResponse, GgcType } from '@Services/Data';

import { GroupedGgcOptions } from '../PreferencesGgcForm.props';

export const groupGccOptions = (options?: DataGetGgcResponse) => {
  return options?.reduce<GroupedGgcOptions>(
    (_options, item) => {
      _options[item.type].push({
        value: item.preprId,
        label: item.title
      });

      return _options;
    },
    {
      [GgcType.HEALTH]: [],
      [GgcType.MOTIVATION]: [],
      [GgcType.COMPETENCE]: []
    }
  );
};
