import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ProfilePicture, Tag } from '@Components/ui';
import { useBoolean, usePathUtils, usePlatform, usePlayInView } from '@Hooks/common';
import { exists } from '@Utils/CommonUtils';

import styles from './Avatar.module.scss';
import { AvatarProps } from './Avatar.props';
import { Path } from './internal';
import { STROKE_WIDTH, VIEWBOX_HEIGHT, VIEWBOX_WIDTH } from './internal/constants';

export const Avatar = ({
  image,
  total = 0,
  current = 0,
  isSmall = false,
  level,
  className
}: AvatarProps) => {
  const { t } = useTranslation();
  const shouldShow = useBoolean(false);
  const { color } = usePlatform();
  const { getRatio } = usePathUtils();

  const [inViewRef] = usePlayInView(shouldShow.setTrue);

  return (
    <div ref={inViewRef} className={cn(styles.Avatar, { [styles.isSmall]: isSmall }, className)}>
      <ProfilePicture image={image} size={isSmall ? 38 : 64} isSmall={isSmall} />

      {exists(level) && (
        <Tag
          label={t('COMPONENTS.HEADER.LEVEL', {
            level: level
          })}
          color={color.primary.default}
          className={styles.Level}
        />
      )}

      <svg viewBox={`0 0 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`} className={styles.Progress}>
        <Path
          key={current}
          strokeWidth={STROKE_WIDTH}
          color={color.success.default}
          dashRatio={shouldShow.value ? getRatio(current, total) : 0}
        />
      </svg>
    </div>
  );
};
