import { useTranslation } from 'react-i18next';

import { StartChallengeBlock } from '@Components/ui';

export const StartChallenge = () => {
  const { t } = useTranslation();

  return (
    <StartChallengeBlock
      title={t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.START_NEW_CHALLENGE.TITLE')}
      button={t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.START_NEW_CHALLENGE.BUTTON_LABEL')}
    />
  );
};
