import { useCallback, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { FormMethods } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import { filterToValues } from '../../../../../utils';
import styles from './ActivityFilterTags.module.scss';
import { ActivityFilterTagsProps, ActivityFilterTagsValues } from './ActivityFilterTags.props';
import {
  ActivityFilterTagsActive,
  ActivityFilterTagsForm,
  ActivityFilterTagsFormValues,
  ActivityFilterTagsFormValuesWithLabels
} from './internal/components';

export const ActivityFilterTags = ({ isVisible, onChange }: ActivityFilterTagsProps) => {
  const { easing } = usePlatform();
  const formRef = useRef<FormMethods<ActivityFilterTagsFormValues> | undefined>();
  const [filter, setFilter] = useState<ActivityFilterTagsFormValuesWithLabels>();

  const handleChange = useCallback(
    (_filter: ActivityFilterTagsFormValuesWithLabels) => {
      setFilter(_filter);

      if (onChange) {
        const splitPoints = _filter.points?.split('-');

        onChange({
          subjects: _filter.subjects && filterToValues(_filter.subjects),
          types: _filter.types && filterToValues(_filter.types),
          durations: _filter.durations && filterToValues(_filter.durations, 'title'),
          minPoints: splitPoints ? Number(splitPoints[0]) : undefined,
          maxPoints: splitPoints ? Number(splitPoints[1] || 9999) : undefined
        } as ActivityFilterTagsValues);
      }
    },
    [onChange]
  );

  const handleReset = useCallback(() => {
    if (formRef.current?.reset) {
      formRef.current?.reset();
    }
  }, []);

  return (
    <AnimateHeight height={isVisible ? 'auto' : 0} easing={easing.out}>
      <div className={styles.ActivityFilterTags}>
        <ActivityFilterTagsActive onReset={handleReset} filter={filter} />

        <ActivityFilterTagsForm ref={formRef} onChange={handleChange} />
      </div>
    </AnimateHeight>
  );
};
