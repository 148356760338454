import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ListActivityItemLarge } from '@Components/list';
import { Block, Text } from '@Components/ui';
import { useUserChallenge } from '@Hooks/userChallenge';

import styles from './ChallengeItemActivitiesBlock.module.scss';
import { countCompletedActivities } from './internal/utils';

export const ChallengeItemActivitiesBlock = () => {
  const { slug } = useParams<{ slug: string }>();

  const { t } = useTranslation();
  const { data: challenge } = useUserChallenge({ id: slug ?? '' });

  if (challenge) {
    const completedActivitiesCount = countCompletedActivities(challenge);

    return (
      <Block
        theme={challenge.completed ? 'success' : 'gray'}
        className={styles.ChallengeItemActivitiesBlock}
      >
        <Text.H4 className={styles.Title}>
          {t('DOMAIN.CHALLENGE.DETAIL.X_OF_Y_ACTIVITIES_FINISHED', {
            part: completedActivitiesCount,
            total: challenge.activities.length
          })}
        </Text.H4>

        <ListActivityItemLarge activities={challenge?.activities} canPin />
      </Block>
    );
  }

  return null;
};
