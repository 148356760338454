import { BaseService, dePortalenService } from '@Services/common';

import { UserPopupHideRequest, UserPopupSubmitRequest } from './UserPopupService.props';

export class UserPopupService extends BaseService {
  hide = async ({ id }: UserPopupHideRequest) => {
    await this.post<void>(`/${id}/hide`);
  };

  submit = async ({ id, ...config }: UserPopupSubmitRequest) => {
    await this.post<void>(`/${id}/submit`, config);
  };
}

export const userPopupService = new UserPopupService(
  dePortalenService.getAxiosInstance(),
  '/userpopups'
);
