import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Block, Button, Text } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

import styles from './StartChallengeBlock.module.scss';
import { StartChallengeBlockProps } from './StartChallengeBlock.props';

export const StartChallengeBlock = ({
  title,
  description,
  button,
  className
}: StartChallengeBlockProps) => {
  const { t } = useTranslation();

  return (
    <Block
      innerClassName={styles.Body}
      className={cn(styles.StartChallengeBlock, className)}
      isElevated
      padding="xl"
    >
      <Text.H4 className={styles.Title}>{title}</Text.H4>

      {!!description && <Text.Normal className={styles.Description}>{description}</Text.Normal>}

      <Button
        to={AppRoutes.getInsightChallengesAdd().to}
        icon="arrow-right"
        className={styles.Button}
      >
        {button}
      </Button>

      <Text.Small className={styles.Extra}>
        {t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.START_NEW_CHALLENGE.EXTRA_PRE_LINK') + ' '}

        <Link to={AppRoutes.getActivities().to}>
          {t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.START_NEW_CHALLENGE.EXTRA_LINK_LABEL')}
        </Link>
      </Text.Small>
    </Block>
  );
};
