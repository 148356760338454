import { SortOption } from '@Components/container';
import { SortDirection } from '@Constants/enums';
import { i18n } from '@Utils/TranslationUtils';

export const sortOptions: SortOption[] = [
  {
    label: i18n.t('DOMAIN.TODO.FILTER.SORT.ADDED_DATE'),
    value: 'pinnedAt',
    direction: [SortDirection.DESC, SortDirection.ASC]
  },

  {
    label: i18n.t('DOMAIN.TODO.FILTER.SORT.SCORE'),
    value: 'points',
    direction: [SortDirection.DESC, SortDirection.ASC]
  }
];
