import { useTranslation } from 'react-i18next';

import { Box, Loader, TabGroup, Text } from '@Components/ui';
import { useConfigurationAll } from '@Hooks/configuration';
import { useStatisticAll } from '@Hooks/statistic';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import styles from './DashboardStatistic.module.scss';
import { DashboardStatisticProps } from './DashboardStatistic.props';
import { DashboardStatisticTab } from './internal/components';

export const DashboardStatistic = ({ className }: DashboardStatisticProps) => {
  const { t } = useTranslation();

  const {
    data: statistic,
    isSuccess: isStatisticSuccess,
    isLoading: isStatisticLoading,
    isError: isStatisticError
  } = useStatisticAll();

  const {
    data: statisticAll,
    isSuccess: isStatisticAllSuccess,
    isLoading: isStatisticAllLoading,
    isError: isStatisticAllError
  } = useStatisticAll({
    includeOtherUsers: true
  });

  const {
    data: configuration,
    isSuccess: isConfigurationSuccess,
    isLoading: isConfigurationLoading,
    isError: isConfigurationError
  } = useConfigurationAll();

  const isLoading = isStatisticLoading || isStatisticAllLoading || isConfigurationLoading;

  const isError = isStatisticError || isStatisticAllError || isConfigurationError;

  const isSuccess =
    statistic &&
    isStatisticSuccess &&
    statisticAll &&
    isStatisticAllSuccess &&
    configuration &&
    isConfigurationSuccess;

  return (
    <DashboardBlock
      widgetKey="STATISTIC"
      className={className}
      innerClassName={styles.DashboardStatistic}
      data-tourdotid="STATISTIC"
    >
      {isSuccess && (
        <TabGroup>
          <TabGroup.Child label={t('DOMAIN.DASHBOARD.BLOCKS.STATISTIC.CONTENT.MY_SCORE')}>
            <Box pr={4}>
              <DashboardStatisticTab statistic={statistic} configuration={configuration} />
            </Box>
          </TabGroup.Child>

          <TabGroup.Child label={t('DOMAIN.DASHBOARD.BLOCKS.STATISTIC.CONTENT.MY_COLLEAGUES')}>
            <Box pr={4}>
              <DashboardStatisticTab statistic={statisticAll} configuration={configuration} />
            </Box>
          </TabGroup.Child>
        </TabGroup>
      )}

      {isError && <Text.Error>{t('DOMAIN.DASHBOARD.BLOCKS.STATISTIC.ERROR')}</Text.Error>}

      {isLoading && <Loader>{t('DOMAIN.DASHBOARD.BLOCKS.STATISTIC.LOADING')}</Loader>}
    </DashboardBlock>
  );
};
