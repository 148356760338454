import { useCallback } from 'react';

import { Sort } from '@Components/container';
import { SortDirection } from '@Constants/enums';

import { sortOptions } from '../../../../../constants';
import { TodoFilterSortProps } from './TodoFilterSort.props';

export const TodoFilterSort = ({ onChange, id = '' }: TodoFilterSortProps) => {
  const handleSort = useCallback(
    (value: string, direction: SortDirection) => {
      onChange({
        sortBy: value,
        sortDirection: direction
      });
    },
    [onChange]
  );

  return <Sort options={sortOptions} onChange={handleSort} id={id} />;
};
