import { Component } from 'react';

import { Child, Wrapper } from './internal';
import TabGroupChildProps from './internal/Child/Child.props';
import { TabGroupProps } from './internal/Wrapper/Wrapper.props';

export * from './internal/Child/context';
export * from './internal/Wrapper/context';

export class TabGroup extends Component<TabGroupProps> {
  static Child = (props: TabGroupChildProps) => <Child {...props} />;

  render() {
    return <Wrapper {...this.props} />;
  }
}
