import { Font as PdfFont } from '@react-pdf/renderer';

import appleTouchIconMen from '@Assets/images/apple-touch-icon-men.png';
import faviconMenIco from '@Assets/images/favicon-men.ico';
import logoMenPng from '@Assets/images/logo-men.png';
import logoMenFooter from '@Assets/images/logo-men-footer.svg';
import { Theme } from '@Constants/themes';

export const MENSELY: Theme = {
  background: {
    fade: {
      default: 'none'
    },
    highlight: {
      default:
        'radial-gradient(circle at 16% 42%, #dbe1f5, #dbf5e5 39%, rgba(40, 194, 166, 0) 82%)',
      fullscreen:
        'radial-gradient(circle at 16% 42%, #dbe1f5, #dbf5e5 34%, rgba(40, 194, 166, 0) 72%)'
    }
  },

  color: {
    primary: {
      light: '#b298f2',
      default: '#3e5580'
    },
    secondary: {
      light: '#64bdad',
      default: '#5cbaa9',
      dark: '#52b5a3',
      opaque: '#5cbaa930'
    },
    tertiary: {
      default: '#fed385',
      dark: '#fdcc71',
      color: '#373657',
      opaque: '#fed38530'
    },
    background: {
      default: '#f9fbfd',
      dark: '#e9f0fd'
    },
    font: {
      light: '#354264',
      default: '#373657',
      transparant: 'rgba(33,41,62,.64)'
    },

    frame: { default: '#fff' },

    gray: {
      default: '#dbe1f5',
      dark: '#889DCC',
      light: '#f4f5fb',
      background: '#fbfbff'
    },
    white: {
      default: '#fff',
      transparant: 'rgba(255, 255, 255, 0.24)'
    },
    black: {
      default: '#000'
    },

    red: { default: '#e61818' },
    orange: { default: '#ff9300' },
    yellow: { default: '#ffe066' },
    green: { default: '#47c869', light: '#7aec98' },

    error: { default: '#e61818', light: '#fef6f6', transparant: '#e61818bb' },
    warning: { default: '#ffcb6b' },
    success: { default: '#5cbaa9', light: '#e6f9f5' },
    info: { default: '#0fb6dd' }
  },

  shadow: {
    light: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
    small: '0 2px 4px 0 rgba(0, 0, 0, 0.24)',
    medium: '10px 7px 16px 0 rgba(0, 0, 0, 0.19)',
    mediumCentered: '0 7px 16px 0 rgba(0, 0, 0, 0.19)',
    mediumLeft: '-10px 0 16px 0 rgba(0, 0, 0, 0.19)',
    large: '16px 32px 40px 0 rgba(0, 0, 0, 0.16)'
  },

  font: {
    family: {
      base: 'azo-sans-web',
      heading: 'azo-sans-web',
      handwritten: 'Caveat'
    },

    h1: {
      color: '#3e5580',
      fontFamily: 'azo-sans-web',
      fontWeight: '900',
      fontSize: '42px',
      lineHeight: '1.14'
    },

    h2: {
      color: '#3e5580',
      fontFamily: 'azo-sans-web',
      fontWeight: '900',
      fontSize: '28px',
      lineHeight: '1.14'
    },

    h3: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '1.33'
    },

    h4: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '1.19'
    },

    h5: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '1.33'
    },

    intro: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '1.71'
    },

    large: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '1.71'
    },

    normal: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    medium: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    bold: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '1.58'
    },

    small: {
      color: '#373657',
      fontFamily: 'azo-sans-web',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '1.6'
    },

    micro: {
      color: '#aabadd',
      fontFamily: 'azo-sans-web',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '1'
    },

    handwritten: {
      color: '#5cbaa9',
      fontFamily: 'Caveat',
      fontWeight: '600',
      fontSize: '25px',
      lineHeight: '0.96'
    }
  },

  radius: {
    tag: '0',
    tagExtraSmall: '0',
    buttonGroup: '6px',
    alert: '16px',
    buttonIcon: '100px',
    inputRadio: '6px',
    inputText: '2px',
    inputLike: '6px',
    counter: '0',
    countNotifier: '6px',
    toolTip: '3px',
    button: '6px',
    avatar: '22px',
    avatarSmall: '14px',
    medal: '0',
    dropdown: '2px',
    inboxItem: '0',
    motivator: '0',
    accordion: '0',
    modal: '0',
    block: '0',
    blockElevated: '0',
    card: '0',
    progressTheme: '6px',
    imageResize: '0',
    thumbnailTextRow: '0',
    selectActivityBlock: '0',
    sidebarCta: '0'
  },

  assets: {
    logo: require('@Assets/images/logo-men.png'),
    logoPng: logoMenPng,
    logoFooter: logoMenFooter,
    faviconIco: faviconMenIco,
    appleTouchIcon: appleTouchIconMen,
    icon: require('@Assets/images/logo-small-men.svg')
  },

  strings: {
    phone: '06 8349 3748',
    mail: 'support-mensely@kom-verder.nl',
    address: 'Zwarte Woud 10',
    postcalCode: '3524 SJ Utrecht',
    websiteUrl: 'https://mensely.kom-verder.nl/',
    privacyUrl: 'https://mensely.kom-verder.nl/privacy-statement/',
    personalUrl: 'https://mensely.kom-verder.nl/gegevensverwerking/',
    termsUrl: 'https://mensely.kom-verder.nl/gebruiksvoorwaarden/'
  },

  easing: {
    out: 'cubic-bezier(0.2, 0.4, 0, 1)',
    in: 'cubic-bezier(1, 0, 0.8, 0.6)',
    inOut: 'cubic-bezier(0.66, 0.07, 0.33, 0.94)'
  },

  spacing: {
    base: 8,
    tagTransformY: '0'
  }
};

PdfFont.register({
  family: 'azo-sans-web',
  fonts: [
    {
      src: 'https://use.typekit.net/af/2dbc12/00000000000000007735a2d5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3',
      fontWeight: 'black',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/9aa4fe/000000000000000000013f4e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/0240fd/00000000000000007735a2df/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3',
      fontWeight: 'medium',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/9dc3b1/000000000000000000013f8f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      fontWeight: 'normal',
      fontStyle: 'normal'
    }
  ]
});
