import { useTranslation } from 'react-i18next';

import { Modal } from '@Components/layout';
import { Box, Button, ProgressBar, Text } from '@Components/ui';
import { useResultColorMap } from '@Hooks/userTheme';
import { getPercentageForResult } from '@Utils/ResultUtils';

import styles from './ProgressThemeModal.module.scss';
import { ProgressThemeModalProps } from './ProgressThemeModal.props';

export const ProgressThemeModal = ({ theme, isOpen, onClose }: ProgressThemeModalProps) => {
  const { t } = useTranslation();
  const { getColorForResult } = useResultColorMap();

  return (
    <Modal
      isOpen={isOpen}
      title={theme.theme.title}
      onClose={onClose}
      className={styles.ProgressThemeModal}
    >
      <Box mt={1} mb={3}>
        <ProgressBar
          total={100}
          current={getPercentageForResult(theme.themeResult)}
          color={getColorForResult(theme.themeResult)}
        />
      </Box>

      <Box mb={2}>
        <Text.Large dangerousHtml={theme.theme.description} />
      </Box>

      <Box mb={2}>
        <Text.Large dangerousHtml={theme.feedback} />
      </Box>

      <Box mt={3}>
        <Button onClick={onClose} color="dark">
          {t('COMMON.GLOBALS.CLOSE')}
        </Button>
      </Box>
    </Modal>
  );
};
