import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingVerifyLicenseCodeForm } from '@Components/form';
import {
  CallToAction,
  SliderFullPage,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';
import { TargetDirection } from '@Hooks/common';

import { OnboardingVerifyLicenseCodeSlideProps } from './OnboardingVerifyLicenseCodeSlide.props';

export const OnboardingVerifyLicenseCodeSlide = ({
  onSuccess
}: OnboardingVerifyLicenseCodeSlideProps) => {
  const { t } = useTranslation();
  const { current } = useSliderFullPageContext();
  const { slideNext } = useSliderFullPageItemContext();
  const ref = useRef<HTMLElement | null>(null);

  const handleSuccess = useCallback(
    (licenseCode: string) => {
      onSuccess(licenseCode);
      slideNext();
    },
    [slideNext, onSuccess]
  );

  return (
    <SliderFullPage.Item showPagination>
      <SliderFullPage.Head
        title={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.HEADER.TITLE')}
        intro={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.HEADER.DESCRIPTION')}
        showPagination
      />
      <SliderFullPage.Block
        title={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.FORM.TITLE')}
        ref={ref}
      >
        <OnboardingVerifyLicenseCodeForm onSuccess={handleSuccess} />

        <CallToAction
          targetRef={ref}
          isVisible={current === 0}
          direction={TargetDirection.LEFT}
          isReversed
          isStatic
          label={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.FORM.CTA')}
        />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
