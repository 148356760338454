import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { useUser } from '@Hooks/user';
import { onboardingService } from '@Services/Onboarding';
import { QuestionnaireSubmissionSubmitSelfTestResponse } from '@Services/QuestionnaireSubmission';
import { userService } from '@Services/User';
import { trackMatomoOnboardingQuestionnaireSelftestPause } from '@Utils/MatomoUtils';

export const useOnboardingSkipSelfTest = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    async (config: QuestionnaireSubmissionSubmitSelfTestResponse) => {
      if (user.skippedSelfTest) {
        return false;
      }

      if (config.skipType) {
        return onboardingService.skipSelfTest(config.skipType);
      }

      return false;
    },
    {
      onSuccess: async (skipped, questionnaire) => {
        trackMatomoOnboardingQuestionnaireSelftestPause(trackEvent, questionnaire, skipped);

        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
