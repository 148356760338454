import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Form } from '@Components/ui';
import { Button } from '@Components/ui';
import { useAuthLogin } from '@Hooks/auth';
import { useApiError } from '@Hooks/common';

import { AuthLoginFormProps, AuthLoginFormValues } from './AuthLoginForm.props';

export const AuthLoginForm = ({ onSuccess }: AuthLoginFormProps) => {
  const { t } = useTranslation();
  const authLoginMutation = useAuthLogin();
  const { error, handleError } = useApiError();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required()
  });

  const handleSubmit = useCallback(
    async (values: AuthLoginFormValues) => {
      try {
        const response = await authLoginMutation.mutateAsync(values);

        onSuccess(response);
      } catch (e) {
        handleError(e, 'AUTHENTICATION');
      }
    },
    [authLoginMutation, handleError, onSuccess]
  );

  return (
    <Form.Form
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      data-testid="credentialsForm"
    >
      <Form.Input.Text
        name="email"
        data-testid="emailInput"
        label={t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.FORM.LABELS.EMAIL')}
        options={{
          type: 'email'
        }}
      />
      <Form.Input.Password
        name="password"
        data-testid="passwordInput"
        label={t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.FORM.LABELS.PASSWORD')}
      />
      <Form.Layout.Error error={error} />
      <Button type="submit" icon="arrow-right" data-testid="credentialsSubmit">
        {t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.FORM.LABELS.SUBMIT')}
      </Button>
    </Form.Form>
  );
};
