import { useMutation } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { authService, LoginRequest } from '@Services/AuthService';
import { trackMatomoAuthLogin } from '@Utils/MatomoUtils';

export const useAuthLogin = () => {
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: LoginRequest) => {
      return authService.login(config);
    },
    {
      onSuccess: () => {
        trackMatomoAuthLogin(trackEvent, 'E-mailadres en wachtwoord');
      }
    }
  );
};
