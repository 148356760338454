import { Bold } from './Bold/Bold';
import { Error } from './Error/Error';
import { H1 } from './H1/H1';
import { H2 } from './H2/H2';
import { H3 } from './H3/H3';
import { H4 } from './H4/H4';
import { H5 } from './H5/H5';
import { Handwritten } from './Handwritten/Handwritten';
import { Intro } from './Intro/Intro';
import { Large } from './Large/Large';
import { Medium } from './Medium/Medium';
import { Micro } from './Micro/Micro';
import { Normal } from './Normal/Normal';
import { Small } from './Small/Small';
import { Success } from './Success/Success';

export const Text = {
  H1: H1,
  H2: H2,
  H3: H3,
  H4: H4,
  H5: H5,

  Intro: Intro,
  Error: Error,
  Large: Large,
  Normal: Normal,
  Medium: Medium,
  Bold: Bold,
  Small: Small,
  Micro: Micro,
  Handwritten: Handwritten,
  Success: Success
};
