import cn from 'classnames';
import { forwardRef } from 'react';

import styles from './BlockCta.module.scss';
import { BlockCtaProps } from './BlockCta.props';

export const BlockCta = forwardRef(
  ({ children, className, innerClassName, ...props }: BlockCtaProps, ref: any) => {
    return (
      <div className={cn(styles.BlockCta, className)} ref={ref} {...props}>
        <div className={cn(styles.Body, innerClassName)}>{children}</div>
      </div>
    );
  }
);
