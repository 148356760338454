import { useCallback } from 'react';

import { Form } from '@Components/ui';
import { QuestionnaireQuestionType } from '@Services/QuestionnaireSubmission';
import {
  answersToMarks,
  percentageToSelectedAnswer,
  selectedAnswerToPercentage
} from '@Utils/QuestionnaireUtils';
import Yup from '@Utils/YupUtils';

import { QuestionnaireFormInputProps } from '../QuestionnaireFormInput.props';
import { QuestionnaireFormSliderStepValues } from './QuestionnaireFormSliderStep.props';

export const QuestionnaireFormSliderStep = ({
  question,
  children,
  onSubmit
}: QuestionnaireFormInputProps) => {
  const handleSubmit = useCallback(
    async ({ answer }: QuestionnaireFormSliderStepValues) => {
      const selected = percentageToSelectedAnswer(question.answers || [], answer);

      const adaptedValues = {
        answers: [selected.id]
      };

      await onSubmit(adaptedValues);
    },
    [onSubmit, question.answers]
  );

  const validationSchema = Yup.object().shape({
    answer: Yup.number().required()
  });

  return (
    <Form.Form<QuestionnaireFormSliderStepValues>
      key={question.id}
      initialValues={{ answer: selectedAnswerToPercentage(question.answers || []) }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      data-testid="questionnaireFormSliderStep"
    >
      <Form.Input.Slider
        name="answer"
        data-testid="sliderStepInput"
        options={{
          isSmiley: question.type === QuestionnaireQuestionType.SLIDER_EMOJI,
          marks: answersToMarks(question.answers ?? []),
          leftLabel: question.scaleMinDescription ?? undefined,
          rightLabel: question.scaleMaxDescription ?? undefined,
          isDisabled: question.editable === false
        }}
      />

      {children}
    </Form.Form>
  );
};
