import { ListResponse } from '@Constants/interfaces';
import { dePortalenService, ResourceService } from '@Services/common';
import { toQueryString } from '@Utils/StringUtils';

import {
  IReward,
  RewardClaimRequest,
  RewardFilterResponse,
  RewardListRequest,
  RewardResendRequest
} from './RewardService.props';

export class RewardService extends ResourceService<IReward> {
  getList = async (params: RewardListRequest) => {
    const response = await this.get<ListResponse<IReward>>(params && `?${toQueryString(params)}`);

    return response.data;
  };

  getFilters = async () => {
    const response = await this.get<RewardFilterResponse>('/filters');

    return response.data;
  };

  claim = async ({ id, ...params }: RewardClaimRequest) => {
    await this.post<void>(`/${id}/claim`, params);
  };

  resend = async ({ id }: RewardResendRequest) => {
    await this.post<void>(`/${id}/resend`);
  };
}

export const rewardService = new RewardService(dePortalenService.getAxiosInstance(), '/rewards');
