import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Modal } from '@Components/layout';
import { InputField, InputText } from '@Components/ui';
import { useBoolean } from '@Hooks/common';

import {
  EditPhoneNewPhoneForm,
  EditPhoneRequestSmsStep,
  EditPhoneUpdateStep,
  EditPhoneVerifyTwoFactorStep
} from './internal/components';
import { EditPhoneNewPhoneFormValues } from './internal/components/';
import { ProfileUpdatePhoneFormProps } from './ProfileUpdatePhoneForm.props';

export const ProfileUpdatePhoneForm = ({ onSuccess, phoneNumber }: ProfileUpdatePhoneFormProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');

  const handleCancel = useCallback(() => {
    setModalClosed();
    setCurrentStep(0);
  }, [setModalClosed]);

  const handleNextStep = useCallback(() => {
    setCurrentStep((oldStep) => oldStep + 1);
  }, []);

  const handleFinish = useCallback(() => {
    if (onSuccess) {
      onSuccess();
    }

    toast.success(t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.UPDATE_PHONE.SUCCES'));

    handleCancel();
  }, [handleCancel, onSuccess, t]);

  const handleInitiateNewSms = useCallback(
    (values: EditPhoneNewPhoneFormValues) => {
      setNewPhoneNumber(values.newPhoneNumber);

      handleNextStep();
    },

    [handleNextStep]
  );

  const stepComponents = [
    <EditPhoneRequestSmsStep
      phoneNumber={phoneNumber}
      onSuccess={handleNextStep}
      onCancel={handleCancel}
    />,

    <EditPhoneVerifyTwoFactorStep phoneNumber={phoneNumber} onSuccess={handleNextStep} />,

    <EditPhoneNewPhoneForm onSuccess={handleInitiateNewSms} />,

    <EditPhoneUpdateStep newPhoneNumber={newPhoneNumber} onSuccess={handleFinish} />
  ];

  const currentStepComponent = stepComponents[currentStep];

  return (
    <>
      <InputField
        name="phone"
        data-testid="changePhoneInput"
        onEditClick={setModalOpen}
        label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.PHONE.LABEL')}
      >
        <InputText value={phoneNumber} disabled />
      </InputField>

      <Modal
        isOpen={isModalOpen}
        title={t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.TITLE')}
        onClose={handleCancel}
      >
        {currentStepComponent}
      </Modal>
    </>
  );
};
