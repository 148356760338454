import { useTranslation } from 'react-i18next';

import { SliderCardActivity } from '@Components/list';
import { SpacingGroup, Tag, Text } from '@Components/ui';
import { useActivityRelated } from '@Hooks/activity';
import { usePlatform } from '@Hooks/common';

import styles from './ActivityRelated.module.scss';
import { ActivityRelatedProps } from './ActivityRelated.props';

export const ActivityRelated = ({ activity }: ActivityRelatedProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();
  const { data, isFetched } = useActivityRelated(activity);

  if (isFetched && data?.results && data.results.length > 0) {
    return (
      <div className={styles.ActivityRelated}>
        <Text.H3 className={styles.Title}>{t('DOMAIN.ACTIVITY.RELATED.TITLE')}</Text.H3>

        <Text.Large className={styles.Subtitle}>{t('DOMAIN.ACTIVITY.RELATED.TAGS')}</Text.Large>

        <SpacingGroup className={styles.Tags} size="xl">
          {activity.subject.map((subject) => (
            <Tag
              key={subject.id}
              size="lg"
              icon="tag"
              color={color.font.default}
              label={subject.title}
            />
          ))}
        </SpacingGroup>

        {data && <SliderCardActivity activities={data.results} />}
      </div>
    );
  }

  return null;
};
