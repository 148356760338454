import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Icon, Spinner } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { formatInternalLink, isInternalLink } from '@Utils/LinkUtils';

import styles from './Anchor.module.scss';
import { AnchorProps } from './Anchor.props';

export const Anchor = ({
  to,
  onClick,
  children,
  className,
  isDisabled,
  icon,
  iconColor,
  href,
  isLoading = false,
  target,
  ...props
}: AnchorProps) => {
  const { color } = usePlatform();

  const classNames = cn(styles.Anchor, className, {
    [styles.isDisabled]: isDisabled,
    [styles.isLoading]: isLoading
  });

  const getInner = () => {
    return (
      <>
        {icon && (
          <Icon
            size="lg"
            className={styles.Icon}
            icon={['fas', icon]}
            color={iconColor ?? color.font.default}
          />
        )}

        {children}

        <Spinner size={12} color="dark" className={styles.Spinner} />
      </>
    );
  };

  const getButtonElement = () => {
    return (
      <button
        disabled={isDisabled}
        onClick={onClick}
        type="button"
        className={classNames}
        tabIndex={isDisabled ? -1 : undefined}
        {...props}
      >
        {getInner()}
      </button>
    );
  };

  const getLinkElement = (_to: string) => {
    return (
      <Link
        onClick={onClick}
        to={_to}
        className={classNames}
        tabIndex={isDisabled ? -1 : undefined}
        target={target}
        {...props}
      >
        {getInner()}
      </Link>
    );
  };

  const getAnchorElement = (_href: string) => {
    return (
      <a
        onClick={onClick}
        href={_href}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames}
        tabIndex={isDisabled ? -1 : undefined}
        {...props}
      >
        {getInner()}
      </a>
    );
  };

  if (href) {
    if (isInternalLink(href)) {
      return getLinkElement(formatInternalLink(href));
    }

    return getAnchorElement(href);
  }

  if (to) {
    return getLinkElement(to);
  }

  return getButtonElement();
};
