import { PDFDownloadLink } from '@react-pdf/renderer';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@Components/ui';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { useUserGetDomains } from '@Hooks/user';
import { useUserThemeGetAll } from '@Hooks/userTheme';
import { trackMatomoOtherDownload } from '@Utils/MatomoUtils';

import { ReportDocument } from './internal/components';
import { groupByDomain, orderByDomain, removeDomainsWithoutScore } from './internal/utils';
import { ReportProps } from './ReportDownloadButton.props';

export const ReportDownloadButton = ({ className }: ReportProps) => {
  const { t } = useTranslation();
  const trackEvent = useMatomoTrackEvent();

  const {
    data: themes,
    isLoading: isThemesLoading,
    isError: isThemesError
  } = useUserThemeGetAll({ includeWithoutScore: false });

  const {
    data: domains,
    isLoading: isDomainsLoading,
    isError: isDomainsError
  } = useUserGetDomains();

  const handleDownload = useCallback(() => {
    trackMatomoOtherDownload(trackEvent);
  }, [trackEvent]);

  if (isThemesLoading || isDomainsLoading) {
    return <Button isLoading className={className} />;
  }

  if (isThemesError || isDomainsError) {
    return <Text.Error className={className}>{t('DOCUMENTS.REPORT.ERROR_LOADING')}</Text.Error>;
  }

  const hasThemes = !!themes && themes.length > 0;
  const hasDomains = !!domains;

  if (hasThemes && hasDomains) {
    const groupedDomains = groupByDomain(themes);
    const orderedDomains = orderByDomain(groupedDomains, domains);
    const filteredDomains = removeDomainsWithoutScore(orderedDomains);

    return (
      <div className={className}>
        <PDFDownloadLink
          fileName={t('DOCUMENTS.REPORT.FILE_NAME')}
          document={<ReportDocument domains={filteredDomains} />}
        >
          <Button onClick={handleDownload} icon="arrow-right">
            {t('DOCUMENTS.REPORT.DOWNLOAD')}
          </Button>
        </PDFDownloadLink>
      </div>
    );
  }

  return null;
};
