import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { AuthenticationContext } from 'src/context/auth/AuthenticationContext.props';

import { HTTP_CODE_UNAUTHORIZED } from '@Constants/configs';
import { removeAccessTokens } from '@Utils/StorageUtils';

import { LogoutHandler, RefreshHandler, Subscriber, TokenExtractor } from './ApiClient.props';

interface AxiosInterceptor {
  onFulfilled?: (value: any) => any | Promise<any>;
  onRejected?: (error: any) => any;
}
export class ApiClient {
  private axios: AxiosInstance;

  private subscribers: Subscriber[] = [];

  private isAlreadyFetchingAccessToken = false;

  private logout: LogoutHandler;

  private refresh: RefreshHandler;

  private tokenExtractor: TokenExtractor;

  private authenticationContext?: AuthenticationContext;

  private requestInterceptors?: Array<AxiosInterceptor>;

  private responseInterceptors?: Array<AxiosInterceptor>;

  constructor(
    baseUrl: string,
    logout: LogoutHandler,
    refresh: RefreshHandler,
    tokenExtractor: TokenExtractor,
    requestInterceptors?: Array<AxiosInterceptor>,
    responseInterceptors?: Array<AxiosInterceptor>
  ) {
    this.logout = logout;
    this.refresh = refresh;
    this.tokenExtractor = tokenExtractor;
    this.requestInterceptors = requestInterceptors;
    this.responseInterceptors = responseInterceptors;

    this.axios = axios.create({
      baseURL: baseUrl
    });

    this.setupInterceptors();
  }

  setAuthenticationContext = (authenticationContext: AuthenticationContext) => {
    this.authenticationContext = authenticationContext;
  };

  setupInterceptors = () => {
    this.setupAccessTokenInterceptor();
    this.setupRefreshInterceptor();
    this.setupProvidedInterceptors();
  };

  setupProvidedInterceptors = () => {
    this.requestInterceptors?.forEach((interceptor) => {
      this.axios.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected);
    });

    this.responseInterceptors?.forEach((interceptor) => {
      this.axios.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected);
    });
  };

  setupAccessTokenInterceptor = () => {
    this.axios.interceptors.request.use(async (config) => {
      const token = this.tokenExtractor();

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };

      return config;
    });
  };

  setupRefreshInterceptor = () => {
    this.axios.interceptors.response.use(
      (response) => response,

      async (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === HTTP_CODE_UNAUTHORIZED) {
          return this.refreshAndRetry(originalRequest, error);
        }

        return Promise.reject(error);
      }
    );
  };

  onRefreshSuccess = () => {
    this.subscribers.forEach((subscriber) => subscriber.onSuccess());
    this.subscribers = [];
  };

  onRefreshFailed = () => {
    this.subscribers.forEach((subscriber) => subscriber.onFailure());
    this.subscribers = [];
    this.logout();
    this.authenticationContext?.setIsAuthenticated(false);
  };

  refreshAndRetry = async (originalRequest: AxiosRequestConfig, error: AxiosError) => {
    const retry = new Promise((resolve, reject) => {
      this.subscribers.push({
        onSuccess: () => {
          return resolve(this.axios(originalRequest));
        },
        onFailure: () => {
          return reject(error);
        }
      });
    });

    if (!this.isAlreadyFetchingAccessToken) {
      this.isAlreadyFetchingAccessToken = true;

      try {
        await this.refresh();
        this.onRefreshSuccess();
      } catch (e) {
        this.authenticationContext?.setIsAuthenticated(false);
        removeAccessTokens();
        this.onRefreshFailed();
      } finally {
        this.isAlreadyFetchingAccessToken = false;
      }
    }

    return retry;
  };

  getAxiosInstance = () => {
    return this.axios;
  };
}
