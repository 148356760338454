import { QuestionnaireTheme, QuestionnaireThemeStatus } from '@Services/QuestionnaireSubmission';

export const getThemeList = (themes: QuestionnaireTheme[]) => {
  return themes.map((theme) => {
    return {
      label: theme.title,
      isChecked: theme.status === QuestionnaireThemeStatus.COMPLETE
    };
  });
};

export const getPaginationNumbers = (themes: QuestionnaireTheme[]) => {
  return themes.reduce(
    (paginationNumbers, theme, idx) => {
      if (theme.status === QuestionnaireThemeStatus.COMPLETE) {
        paginationNumbers.currentSlideIndex = idx + 2;
      }

      return paginationNumbers;
    },
    { currentSlideIndex: 1, totalSlides: themes.length }
  );
};
