import cn from 'classnames';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BlockImage, Spacer, SpacingGroup, Tag, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';

import { ActivityItem } from '../ActivityItem/ActivityItem';
import styles from './HighlightedChallenge.module.scss';
import { HighlightedChallengeProps } from './HighlightedChallenge.props';

export const HighlightedChallenge = forwardRef(
  ({ challenge, className }: HighlightedChallengeProps, ref: any) => {
    const { t } = useTranslation();
    const { color } = usePlatform();

    return (
      <BlockImage
        ref={ref}
        to={AppRoutes.getInsightChallenge(challenge.slug).to}
        image={challenge.thumbnail}
        className={cn(styles.HighlightedChallenge, className)}
      >
        <Link to={AppRoutes.getInsightChallenge(challenge.slug).to} className={styles.Title}>
          <Text.H3>{challenge.title}</Text.H3>
        </Link>

        <SpacingGroup size="md">
          <Tag size="md" label={t('DOMAIN.CHALLENGE.SINGLE')} color={color.font.default} />

          <Tag
            size="md"
            label={String(challenge.points)}
            icon="star"
            color={color.primary.default}
          />
        </SpacingGroup>

        {challenge.activities && challenge.activities.length > 0 && (
          <>
            <Spacer top={3} bottom={3} />

            {challenge.activities.map((activity) => {
              return (
                <ActivityItem key={activity.id} activity={activity} className={styles.Activity} />
              );
            })}
          </>
        )}
      </BlockImage>
    );
  }
);
