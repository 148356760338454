import cn from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CallToAction } from '@Components/ui';
import { TargetDirection, useBoolean, usePlayInView } from '@Hooks/common';

import { Bar, Dots } from './internal/components';
import styles from './ProgressChallenge.module.scss';
import { ProgressChallengeProps } from './ProgressChallenge.props';

export const ProgressChallenge = ({
  total,
  current,
  hasCallToAction,
  className
}: ProgressChallengeProps) => {
  const { t } = useTranslation();
  const isVisible = useBoolean(false);
  const isEmpty = current === 0;

  const selectedDotRef = useRef<HTMLElement | null>(null);
  const [inViewRef] = usePlayInView(isVisible.setTrue);

  const getLabel = () => {
    if (isEmpty) {
      return t('COMPONENTS.PROGRESS_CHALLENGE.PROCEED');
    }

    return t('COMPONENTS.PROGRESS_CHALLENGE.YOU_ARE_HERE');
  };

  return (
    <div ref={inViewRef} className={cn(styles.ProgressChallenge, className)}>
      <Dots ref={selectedDotRef} total={total} current={current} isVisible={isVisible.value} />

      <Bar total={total} current={current} isVisible={isVisible.value} />

      {hasCallToAction && (
        <CallToAction
          targetRef={selectedDotRef}
          direction={TargetDirection.UP}
          label={getLabel()}
          shouldPointAway={isEmpty}
        />
      )}
    </div>
  );
};
