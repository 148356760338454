import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Icon, Spinner, Text } from '@Components/ui';
import { formatInternalLink, isInternalLink } from '@Utils/LinkUtils';

import styles from './Button.module.scss';
import { ButtonProps } from './Button.props';

export const Button = ({
  className,
  to,
  onClick,
  color = 'default',
  icon,
  children,
  isLoading,
  isDisabled,
  isReversed,
  href,
  type,
  ...props
}: ButtonProps) => {
  const controls = useFormContext();
  const isSubmitting = !!controls?.formState?.isSubmitting && type === 'submit';
  const isLoadingOrSubmitting = isSubmitting || isLoading;

  const classNames = cn(styles.Button, className, {
    [styles.isReversed]: isReversed,
    [styles.isDefault]: color === 'default',
    [styles.isDark]: color === 'dark',
    [styles.isDarkReversed]: color === 'dark-reversed',
    [styles.isLight]: color === 'light',
    [styles.isLoading]: isLoadingOrSubmitting,
    [styles.withIcon]: !!icon && !!children,
    [styles.withLabel]: !!children,
    [styles.isAnchor]: !!to
  });

  const getInner = () => {
    return (
      <>
        <Spinner size={20} color="white" className={styles.Spinner} />

        <div className={styles.Inner}>
          <Text.H5 className={styles.Label}>
            {children}

            {icon && <Icon className={styles.Icon} icon={['far', icon]} />}
          </Text.H5>
        </div>
      </>
    );
  };

  const getButtonElement = () => {
    return (
      <button
        disabled={isDisabled || isLoadingOrSubmitting}
        onClick={!isLoadingOrSubmitting ? onClick : undefined}
        className={classNames}
        type={type}
        {...props}
      >
        {getInner()}
      </button>
    );
  };

  const getLinkElement = (_to: string) => {
    return (
      <Link to={_to} className={classNames} {...props}>
        {getInner()}
      </Link>
    );
  };

  const getHrefElement = (_href: string) => {
    return (
      <a href={_href} target="_blank" className={classNames} {...props}>
        {getInner()}
      </a>
    );
  };

  if (href && !isDisabled) {
    if (isInternalLink(href)) {
      return getLinkElement(formatInternalLink(href));
    }

    return getHrefElement(href);
  }

  if (to && !isDisabled) {
    return getLinkElement(to);
  }

  return getButtonElement();
};
