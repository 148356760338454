import { BaseService, dePortalenService } from '@Services/common';

import {
  QuestionnaireSubmissionFinishPhaseRequest,
  QuestionnaireSubmissionFinishPhaseResponse
} from '.';
import {
  QuestionnaireSubmissionFinishRequest,
  QuestionnaireSubmissionFinishResponse,
  QuestionnaireSubmissionGoToPreviousRequest,
  QuestionnaireSubmissionGoToPreviousResponse,
  QuestionnaireSubmissionSubmitRequest,
  QuestionnaireSubmissionSubmitResponse
} from './QuestionnaireSubmissionService.props';

export class QuestionnaireSubmissionService extends BaseService {
  submit = async (config: QuestionnaireSubmissionSubmitRequest) => {
    const response = await this.post<QuestionnaireSubmissionSubmitResponse>(
      `/${config.questionnaireSubmission.id}`,
      config
    );

    return response.data;
  };

  goToPrevious = async ({
    questionnaireSubmission
  }: QuestionnaireSubmissionGoToPreviousRequest) => {
    const response = await this.post<QuestionnaireSubmissionGoToPreviousResponse>(
      `/${questionnaireSubmission.id}/previous`
    );

    return response.data;
  };

  finish = async ({ id }: QuestionnaireSubmissionFinishRequest) => {
    const response = await this.post<QuestionnaireSubmissionFinishResponse>(`/${id}/finish`);

    return response.data;
  };

  finishPhase = async ({ id }: QuestionnaireSubmissionFinishPhaseRequest) => {
    const response = await this.post<QuestionnaireSubmissionFinishPhaseResponse>(
      `/${id}/finish/phase`
    );

    return response.data;
  };
}

export const questionnaireSubmissionService = new QuestionnaireSubmissionService(
  dePortalenService.getAxiosInstance(),
  '/questionnairesubmissions'
);
