import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { Box } from '@Components/ui';

import { AccordionStepsItem } from '../AccordionStepsItem/AccordionStepsItem';
import { AccordionStepsItemState } from '../AccordionStepsItem/AccordionStepsItem.props';
import { AccordionStepsProps } from './AccordionSteps.props';

export const AccordionSteps = ({ items, className }: AccordionStepsProps) => {
  const [openId, setOpenId] = useState<string | null>();

  useEffect(() => {
    const activeItem = items.find((item) => item.state === AccordionStepsItemState.DEFAULT);

    if (activeItem) {
      setOpenId(activeItem.id);
    }
  }, [items]);

  const handleItemToggle = useCallback(
    (id: string) => () => {
      setOpenId((oldId) => {
        if (oldId === id) {
          return null;
        }

        return id;
      });
    },
    []
  );

  return (
    <div className={cn(className)}>
      {items.map((item, idx) => {
        return (
          <Box key={item.id} mb={1.5}>
            <AccordionStepsItem
              onToggle={handleItemToggle(item.id)}
              isOpen={openId === item.id}
              title={item.title}
              state={item.state}
              key={item.id}
              tooltipText={item.tooltipText}
              popup={item.popup}
              data-testid={`accordionStepsItem_${idx}`}
            >
              {item.body}
            </AccordionStepsItem>
          </Box>
        );
      })}
    </div>
  );
};
