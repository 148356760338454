import { useTranslation } from 'react-i18next';

import userActivityThumbnail from '@Assets/images/custom-activity-thumbnail.jpg';
import { BackButton } from '@Components/container';
import {
  Anchor,
  Block,
  Box,
  Image,
  Spacer,
  SpacingGroup,
  Tag,
  TagText,
  Text
} from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';

import styles from './UserActivityHeader.module.scss';
import { UserActivityHeaderProps } from './UserActivityHeader.props';

/**
 * TODO: Fix quote when conclusion...
 */

export const UserActivityHeader = ({ activity }: UserActivityHeaderProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();

  return (
    <div className={styles.UserActivityHeader}>
      <div className={styles.Header}>
        <div className={styles.Navigation}>
          <BackButton to={AppRoutes.getTodo().to} />
        </div>

        <div className={styles.Activity}>
          <Box mb={0.5}>
            <Text.H1>
              {t('DOMAIN.USER_ACTIVITY.TITLE', {
                title: activity.title
              })}
            </Text.H1>
          </Box>

          {activity.challenge && (
            <Text.Normal>
              {t('DOMAIN.USER_ACTIVITY.DETAIL.HEADER.PART_OF_CHALLENGE') + ': "'}

              <Anchor to={AppRoutes.getInsightChallenge(activity.challenge.slug).to}>
                {activity.challenge.title}
              </Anchor>
              {'"'}
            </Text.Normal>
          )}
        </div>

        <Spacer top={1.5} bottom={1.5} />

        <div className={styles.Meta}>
          <SpacingGroup size="md">
            <Tag
              size="lg"
              color={color.black.default}
              label={t('DOMAIN.USER_ACTIVITY.DETAIL.HEADER.OWN_ACTIVITY')}
            />
          </SpacingGroup>

          {activity.completedAt && (
            <div className={styles.Duration}>
              <TagText
                tagProps={{
                  size: 'xs',
                  icon: 'check',
                  color: color.success.default
                }}
                label={t('DOMAIN.ACTIVITY.FINISHED')}
              />
            </div>
          )}
        </div>
      </div>

      <Block padding="none" isElevated>
        <Image src={userActivityThumbnail} ratio="2:1" />
      </Block>
    </div>
  );
};
