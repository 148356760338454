import { ListResponse } from '@Constants/interfaces';
import { BaseService, dePortalenService } from '@Services/common';
import { toQueryString } from '@Utils/StringUtils';

import { ITodo, TodoListRequest } from './TodoService.props';

export class TodoService extends BaseService {
  getList = async (params: TodoListRequest) => {
    const response = await this.get<ListResponse<ITodo>>(params && `?${toQueryString(params)}`);

    return response.data;
  };

  getArchiveList = async (params: TodoListRequest) => {
    const response = await this.get<ListResponse<ITodo>>(
      `/archive${params ? `?${toQueryString(params)}` : ''}`
    );

    return response.data;
  };
}

export const todoService = new TodoService(dePortalenService.getAxiosInstance(), '/todo');
