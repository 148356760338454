import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Text } from '@Components/ui';
import { markupToHtml } from '@Utils/StringUtils';

import styles from './NoResultsFound.module.scss';
import { NoResultsFoundProps } from './NoResultsFound.props';

export const NoResultsFound = ({ className }: NoResultsFoundProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.NoResultsFound, className)}>
      <Text.Normal className={styles.Count}>
        {t('DOMAIN.FAQ.SEARCH.NO_RESULTS_FOUND.COUNT')}
      </Text.Normal>

      <Text.Normal
        className={styles.Description}
        dangerousHtml={markupToHtml(t('DOMAIN.FAQ.SEARCH.NO_RESULTS_FOUND.DESCRIPTION'))}
      />
    </div>
  );
};
