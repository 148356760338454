import cn from 'classnames';
import { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@Components/ui';

import styles from './InputLike.module.scss';
import { InputLikeProps } from './InputLike.props';

export const InputLike = forwardRef(
  (
    {
      name,
      value,
      onBlur,
      onChange,

      likeLabel,
      dislikeLabel,

      isError,
      isValid,
      isDisabled,
      children,
      className,
      ...props
    }: InputLikeProps,
    ref?: any
  ) => {
    const { t } = useTranslation();
    const [currentValue, setCurrentValue] = useState(value);

    const classNames = cn(styles.InputLike, className, {
      [styles.isError]: isError,
      [styles.isValid]: isValid
    });

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value);

        if (onChange) {
          onChange(e.target.value);
        }
      },
      [onChange]
    );

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    return (
      <div className={classNames} ref={ref} {...props}>
        {children && <div className={styles.Children}>{children}</div>}

        <div className={styles.Inputs}>
          <label
            className={cn(styles.Input, styles.isLike, {
              [styles.isChecked]: currentValue === '1',
              [styles.isDisabled]: isDisabled
            })}
          >
            <input
              id={`InputLike-${name}-Like`}
              className={styles.Checkbox}
              checked={currentValue === '1'}
              type="radio"
              disabled={isDisabled}
              onBlur={onBlur}
              onChange={handleChange}
              data-testid="value-true"
              value="1"
            />

            <Text.Large className={styles.Label}>
              <Icon className={styles.Icon} size="lg" icon={['fas', 'thumbs-up']} />

              {likeLabel || t('COMPONENTS.INPUT_LIKE.LIKE')}
            </Text.Large>
          </label>

          <label
            className={cn(styles.Input, styles.isDislike, {
              [styles.isChecked]: currentValue === '0',
              [styles.isDisabled]: isDisabled
            })}
          >
            <input
              id={`InputLike-${name}-Dislike`}
              className={styles.Checkbox}
              checked={currentValue === '0'}
              type="radio"
              disabled={isDisabled}
              onBlur={onBlur}
              onChange={handleChange}
              data-testid="value-false"
              value="0"
            />

            <Text.Large className={styles.Label}>
              {dislikeLabel || t('COMPONENTS.INPUT_LIKE.DISLIKE')}

              <Icon className={styles.Icon} size="lg" icon={['fas', 'thumbs-down']} />
            </Text.Large>
          </label>
        </div>
      </div>
    );
  }
);
