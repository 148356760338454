import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { SMS_CODE_LENGTH } from '@Constants/configs';
import { useApiError } from '@Hooks/common';
import { useOnboardingVerifyPhone } from '@Hooks/onboarding';
import Yup from '@Utils/YupUtils';

import {
  OnboardingVerifyPhoneFormProps,
  OnboardingVerifyPhoneFormValues
} from './OnboardingVerifyPhoneForm.props';

export const OnboardingVerifyPhoneForm = ({
  onSuccess,
  userId,
  phoneNumber
}: OnboardingVerifyPhoneFormProps) => {
  const { t } = useTranslation();
  const { error, handleError } = useApiError();
  const onboardingVerifyPhoneMutation = useOnboardingVerifyPhone();

  const handleSubmit = useCallback(
    async (values: OnboardingVerifyPhoneFormValues) => {
      try {
        await onboardingVerifyPhoneMutation.mutateAsync({
          userId: userId,
          phoneNumber: phoneNumber,
          ...values
        });

        onSuccess();
      } catch (e) {
        handleError(e, 'ONBOARDING');
      }
    },
    [onboardingVerifyPhoneMutation, userId, phoneNumber, onSuccess, handleError]
  );

  const validationSchema = Yup.object().shape({
    token: Yup.string().length(SMS_CODE_LENGTH).required()
  });

  return (
    <Form.Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form.Input.Code
        name="token"
        data-testid="smsTokenInput"
        label={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_PHONE.FORM.FIELDS.TOKEN.LABEL')}
        description={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_PHONE.FORM.FIELDS.TOKEN.DESCRIPTION')}
        options={{
          length: SMS_CODE_LENGTH,
          isError: !!error,
          type: 'number',
          pattern: '[0-9]*',
          inputMode: 'numeric'
        }}
      />

      <Form.Layout.Error error={error} />

      <Button type="submit" icon="arrow-right" data-testid="verifySmsSubmit">
        {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.FORM.SUBMIT')}
      </Button>
    </Form.Form>
  );
};
