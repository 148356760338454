import { useTranslation } from 'react-i18next';

import { Anchor, Box, ProgressBlock, Tag } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';
import { exists } from '@Utils/CommonUtils';

import styles from './SelfTestPhasedClosedBlock.module.scss';
import { SelfTestPhasedClosedBlockProps } from './SelfTestPhasedClosedBlock.props';

export const SelfTestPhasedClosedBlock = ({ selfTest }: SelfTestPhasedClosedBlockProps) => {
  const { t } = useTranslation();
  const { completedPhases, totalPhases } = selfTest;

  const { color } = usePlatform();

  if (exists<number>(completedPhases) && exists<number>(totalPhases)) {
    return (
      <>
        {[...Array(completedPhases)].map((_, idx) => {
          const phaseNumber = idx + 1;

          return (
            <div className={styles.SelfTestPhasedClosedBlock}>
              <Tag icon="check" size="sm" color={color.success.default} className={styles.Check} />
              <ProgressBlock
                className={styles.Block}
                progress={{
                  total: totalPhases,
                  current: phaseNumber
                }}
              >
                <Box mt={1.5}>
                  <Anchor to={AppRoutes.getInsightReport().to}>
                    {t('DOMAIN.TODO.SELF_TEST.REGULAR.DONE.ANCHOR')}
                  </Anchor>
                </Box>
              </ProgressBlock>
            </div>
          );
        })}
      </>
    );
  }

  return null;
};
