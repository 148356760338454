import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { RewardClaimRequest, rewardService } from '@Services/Reward';
import { userService } from '@Services/User';
import { trackMatomoRewardClaim } from '@Utils/MatomoUtils';

export const useRewardClaim = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: RewardClaimRequest) => {
      return rewardService.claim(config);
    },
    {
      onSuccess: (_, { title, category }) => {
        trackMatomoRewardClaim(trackEvent, title, category);

        queryClient.invalidateQueries([rewardService.getEndpoint()]);
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
