import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityFinishForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { Anchor, Box, Button, SpacingGroup, Text } from '@Components/ui';
import { USER_ACTIVITY_DURATION_MINUTES } from '@Constants/configs';
import { isUserActivity } from '@Utils/ActivityUtils';

import { ActivityFinishModalProps } from './ActivityFinishModal.props';

export const ActivityFinishModal = ({
  activity,
  isOpen,
  isLoading,
  isLiked,
  isStartedAtPastDuration,
  onSubmit,
  onClose
}: ActivityFinishModalProps) => {
  const { t } = useTranslation();
  const isActivity = !isUserActivity(activity);

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Modal
      title={
        isActivity && isStartedAtPastDuration
          ? t('DOMAIN.ACTIVITY.FINISH.FINISH_MODAL.DISLIKE')
          : t('DOMAIN.ACTIVITY.FINISH.FINISH_MODAL.TITLE')
      }
      isOpen={isOpen}
      onClose={onClose}
      data-testid="activityFinishModal"
    >
      <Box mb={3}>
        {isStartedAtPastDuration === false && (
          <Text.Large>
            {t('DOMAIN.ACTIVITY.FINISH.FINISH_MODAL.DESCRIPTION', {
              minutes: isActivity ? activity.type[0].duration : USER_ACTIVITY_DURATION_MINUTES
            })}

            {isActivity && isLiked === false && (
              <Box mt={2}>
                {!isStartedAtPastDuration && t('DOMAIN.ACTIVITY.FINISH.FINISH_MODAL.DISLIKE')}
              </Box>
            )}
          </Text.Large>
        )}
      </Box>

      {isActivity && isLiked === false && (
        <ActivityFinishForm isLoading={isLoading} onSuccess={onSubmit} onClose={onClose} />
      )}

      {(!isActivity || isLiked === true) && (
        <SpacingGroup size="xxl">
          <Button
            icon="arrow-right"
            isLoading={isLoading}
            onClick={handleSubmit}
            color="dark"
            data-testid="submitButton"
          >
            {t('DOMAIN.ACTIVITY.FINISH.FINISH_MODAL.SUBMIT')}
          </Button>

          {onClose && <Anchor onClick={onClose}>{t('COMMON.GLOBALS.CANCEL')}</Anchor>}
        </SpacingGroup>
      )}
    </Modal>
  );
};
