import { Legend } from '@Components/ui';

import { useLegendItems } from './internal/hooks';
import { ProgressThemeLegendProps } from './ProgressThemeLegend.props';

export const ProgressThemeLegend = ({ themes, className }: ProgressThemeLegendProps) => {
  const legendItems = useLegendItems(themes);

  return <Legend items={legendItems} className={className} />;
};
