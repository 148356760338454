import { useTranslation } from 'react-i18next';

import { ProgressTheme } from '@Components/container';
import { ListCardChallenge } from '@Components/list';
import {
  Anchor,
  Box,
  Loader,
  SliderFullPage,
  SliderNavigation,
  SpacingGroup,
  Text,
  useSliderFullPageContext,
  useSliderFullPageItemActiveAfterAnimation,
  useSliderFullPageItemContext
} from '@Components/ui';
import { useChallengeRecommended } from '@Hooks/challenge';

import { ChallengeAddHeading } from '../ChallengeAddHeading/ChallengeAddHeading';
import { ChallengeAddChallengeSlideProps } from './ChallengeAddChallengeSlide.props';

export const ChallengeAddChallengeSlide = ({
  selectedTheme,
  onSelectChallenge
}: ChallengeAddChallengeSlideProps) => {
  const { t } = useTranslation();
  const { total } = useSliderFullPageContext();
  const { index, slidePrev } = useSliderFullPageItemContext();
  const { isActiveAndAnimated } = useSliderFullPageItemActiveAfterAnimation();
  const {
    data: recommendedChallenges,
    isLoading: isRecommendedChallengesLoading,
    isError: isRecommendedChallengesError
  } = useChallengeRecommended({
    themeId: selectedTheme?.theme.id ?? ''
  });

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head isLarge>
        <ChallengeAddHeading
          title={t('DOMAIN.CHALLENGE.ADD.CHALLENGE.TITLE')}
          intro={t('DOMAIN.CHALLENGE.ADD.CHALLENGE.INTRO')}
        />

        <Box mt={2}>
          <SpacingGroup size="xxl">
            {selectedTheme && <ProgressTheme theme={selectedTheme} isSmall />}

            <Anchor onClick={slidePrev}>{t('COMMON.GLOBALS.EDIT')}</Anchor>
          </SpacingGroup>
        </Box>
      </SliderFullPage.Head>

      <SliderFullPage.Block isLarge>
        <Box mb={4}>
          <Text.H3>{t('DOMAIN.CHALLENGE.ADD.CHALLENGE.OVERVIEW_POSSIBLE')}</Text.H3>
        </Box>

        <Box mb={5}>
          {isRecommendedChallengesLoading && (
            <Box my={8}>
              <Loader>{t('DOMAIN.CHALLENGE.ADD.CHALLENGE.LOADING')}</Loader>
            </Box>
          )}

          {recommendedChallenges && recommendedChallenges.length > 0 ? (
            <ListCardChallenge
              challenges={recommendedChallenges}
              onChallengeClick={onSelectChallenge}
              shouldShowRecommendedCta={isActiveAndAnimated}
            />
          ) : (
            <Box mb={5}>
              <Text.Large>{t('DOMAIN.CHALLENGE.ADD.CHALLENGE.NO_CHALLENGES')}</Text.Large>
            </Box>
          )}
        </Box>

        {isRecommendedChallengesError && (
          <Box mb={5}>
            <Text.Error>{t('DOMAIN.CHALLENGE.ADD.CHALLENGE.ERROR')}</Text.Error>
          </Box>
        )}

        <SliderNavigation current={index + 1} total={total} onPrevious={slidePrev} />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
