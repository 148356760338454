import { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { useMutationObserver } from '@Hooks/common/useMutationObserver';

import { useMatomoTrackEvent } from './useMatomoTrackEvent';
import { MatomoAction, MatomoCategory } from './useMatomoTrackEvent.props';

export const useMatomoTrackOutbound = () => {
  const trackEvent = useMatomoTrackEvent();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const handleClick = useCallback(
    (element: Event) => {
      const href = (element.target as HTMLAnchorElement).getAttribute('href') || '';

      trackEvent({
        category: MatomoCategory.CONTACT,
        action: href.includes('mailto:') ? MatomoAction.CONTACT_MAIL : MatomoAction.CONTACT_TEL,
        name: href
      });
    },
    [trackEvent]
  );

  useEffect(() => {
    return () => {
      const elements = document.querySelectorAll("a[href^='tel:'],a[href^='mailto:']");

      elements.forEach((element) => {
        element.removeEventListener('click', handleClick);
      });
    };
  }, [handleClick, pathname, queryClient]);

  useMutationObserver(() => {
    const elements = document.querySelectorAll("a[href^='tel:'],a[href^='mailto:']");

    elements.forEach((element) => {
      element.addEventListener('click', handleClick);
    });
  });
};
