import { ReactNode } from 'react';

export enum AccordionStepsItemState {
  DISABLED = 'DISABLED',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT'
}

export interface AccordionStepsItemProps {
  state: AccordionStepsItemState;

  title: string;
  children: ReactNode | ReactNode[];

  tooltipText?: string;

  popup?: {
    title: string;
    body: string;
  };

  onToggle?: () => void;
  isOpen?: boolean;
}
