import cn from 'classnames';
import { forwardRef } from 'react';

import { Tooltip } from '@Components/ui';
import { TargetAlignment, useHover } from '@Hooks/common';

import styles from './Dot.module.scss';
import { DotProps } from './Dot.props';

export const Dot = forwardRef(({ tooltip, className, ...props }: DotProps, ref: any) => {
  const { isHovering, handlers } = useHover();

  return (
    <>
      {tooltip && (
        <Tooltip
          targetRef={ref}
          label={tooltip}
          alignment={TargetAlignment.LEFT}
          isVisible={isHovering}
          hasOffset
        />
      )}

      <div {...props} ref={ref} className={cn(styles.Dot, className)} {...handlers} />
    </>
  );
});
