import { MutableRefObject, useLayoutEffect, useState } from 'react';

import { ScrollbarState } from '../enums';

export const useScrollbarCalculations = (
  ref: MutableRefObject<HTMLElement | null>,
  currentMiddle: number
) => {
  const [currentState, setCurrentState] = useState(ScrollbarState.BELOW_MIDDLE);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [componentHeight, setComponentHeight] = useState(0);

  useLayoutEffect(() => {
    const refElement = ref.current;

    if (refElement) {
      const locationY = refElement.offsetTop;
      const containerHeight = refElement.clientHeight;

      if (locationY + containerHeight < currentMiddle) {
        setCurrentState(ScrollbarState.ABOVE_MIDDLE);
      }

      if (locationY > currentMiddle) {
        setCurrentState(ScrollbarState.BELOW_MIDDLE);
      }

      if (locationY <= currentMiddle && locationY + containerHeight >= currentMiddle) {
        setCurrentState(ScrollbarState.CURRENT);
      }

      setComponentHeight((oldHeight) => (oldHeight === 0 ? containerHeight : oldHeight));
      setScrollOffset(currentMiddle - locationY);
    }
  }, [currentMiddle, ref]);

  return {
    currentState,
    scrollOffset,
    componentHeight
  };
};
