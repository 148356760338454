import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ProfileStatus } from '@Components/container';
import { Container } from '@Components/layout';
import { Box, Text } from '@Components/ui';
import { REQUIRED_ACTIVITY_AMOUNT } from '@Constants/configs';
import { useUser } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';

import { DashboardIntroText } from '../DashboardIntroText/DashboardIntroText';

export const DashboardProfileStatus = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profileStatusSteps, firstName } = useUser();

  const handleSelfTestClick = useCallback(() => {
    navigate(AppRoutes.getSelfTest().to);
  }, [navigate]);

  if (profileStatusSteps.length === 0) {
    return null;
  }

  return (
    <>
      <Container cols={10}>
        <Box pb={4}>
          <DashboardIntroText
            title={t('DOMAIN.DASHBOARD.PROFILE_STATUS.TITLE', { name: firstName })}
            subtitle={t('DOMAIN.DASHBOARD.PROFILE_STATUS.SUBTITLE')}
            total={REQUIRED_ACTIVITY_AMOUNT}
            current={0}
          />
        </Box>
      </Container>

      <Container cols={4}>
        <Box pb={10}>
          <Box mx={4} mb={6}>
            <Text.Large>{t('DOMAIN.DASHBOARD.PROFILE_STATUS.INTRO')}</Text.Large>
          </Box>

          <ProfileStatus showTourdots canOpenNext onSelfTestClick={handleSelfTestClick} />
        </Box>
      </Container>
    </>
  );
};
