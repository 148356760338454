import { useTranslation } from 'react-i18next';

import { Anchor, Box, Button, SpacingGroup, Text } from '@Components/ui';
import { markupToHtml } from '@Utils/StringUtils';

import { EditPhoneRequestSmsStepProps } from './EditPhoneRequestSmsStep.props';

export const EditPhoneRequestSmsStep = ({
  phoneNumber,
  onSuccess,
  onCancel
}: EditPhoneRequestSmsStepProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Text.Large
        dangerousHtml={markupToHtml(
          t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.REQUEST_SMS.DESCRIPTION', {
            phoneNumber
          })
        )}
      />

      <Box mt={3}>
        <SpacingGroup size="xxl">
          <Button onClick={onSuccess} color="dark" icon="arrow-right" data-testid="confirmButton">
            {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.REQUEST_SMS.START_LABEL')}
          </Button>

          <Anchor onClick={onCancel}>
            {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.REQUEST_SMS.CANCEL_LABEL')}
          </Anchor>
        </SpacingGroup>
      </Box>
    </>
  );
};
