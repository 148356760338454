import { forwardRef, useCallback, useMemo } from 'react';

import { Form } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { usePreferenceList, usePreferenceSubmit } from '@Hooks/preference';
import { PreferenceKey } from '@Services/Preference';
import { getInputOptions, getPreference } from '@Utils/PreferenceUtils';
import Yup from '@Utils/YupUtils';

import { Profession } from './internal/constants';
import {
  PreferencesProfessionFormProps,
  PreferencesProfessionFormValues
} from './PreferencesProfessionForm.props';

export const PreferencesProfessionForm = forwardRef(
  ({ onSuccess, children, className }: PreferencesProfessionFormProps, ref: any) => {
    const preferencesQuery = usePreferenceList();
    const preferenceSubmitMutation = usePreferenceSubmit();
    const { handleError } = useApiError();

    const inputOptions = useMemo(() => {
      return getInputOptions(Object.values(Profession), PreferenceKey.PROFESSION);
    }, []);

    const handleSubmit = useCallback(
      async (values: PreferencesProfessionFormValues) => {
        try {
          await preferenceSubmitMutation.mutateAsync({
            key: PreferenceKey.PROFESSION,
            values: [values.profession]
          });

          onSuccess();
        } catch (e) {
          handleError(e);
        }
      },
      [onSuccess, preferenceSubmitMutation, handleError]
    );

    const initialValues = {
      profession: getPreference(preferencesQuery.data || [], PreferenceKey.PROFESSION)?.[0]
    };

    const validationSchema = Yup.object().shape({
      profession: Yup.string().required()
    });

    if (preferencesQuery.isFetched) {
      return (
        <Form.Form<PreferencesProfessionFormValues>
          ref={ref}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          className={className}
          data-testid="professionForm"
        >
          <Form.Input.Select
            name="profession"
            data-testid="professionInput"
            options={{
              options: inputOptions
            }}
          />

          {children}
        </Form.Form>
      );
    }

    return null;
  }
);
