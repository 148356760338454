import { getLink, getLinkLabel } from '@Utils/RouteUtils';

export enum Slugs {
  DASHBOARD = 'DASHBOARD',
  TODO = 'TODO',
  INSIGHT = 'INSIGHT',
  CHALLENGES = 'CHALLENGES',
  ADD = 'ADD',
  PROGRESS = 'PROGRESS',
  REPORT = 'REPORT',
  ACTIVITIES = 'ACTIVITIES',
  CONTENT_PAGES = 'CONTENT_PAGES',
  REWARDS = 'REWARDS',
  FAQ = 'FAQ',
  MY_PROFILE = 'MY_PROFILE',
  PREFERENCES = 'PREFERENCES',
  INBOX = 'INBOX',
  ONBOARDING = 'ONBOARDING',
  TEST_YOURSELF = 'TEST_YOURSELF',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  REGISTER_ALTERNATIVE = 'REGISTER_ALTERNATIVE',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  EDIT_PASSWORD = 'EDIT_PASSWORD',
  START = 'START',
  SUPPORT = 'SUPPORT',
  RESULTS = 'RESULTS',
  EDIT_PROFILE_PICTURE = 'EDIT_PROFILE_PICTURE'
}

export interface RouteType {
  path: string;
  label: string;
  to: string;
  isFullscreen: boolean;
}

export const OnboardingRoutes = {
  getOnboarding: (): RouteType => ({
    path: getLink('*'),
    label: getLinkLabel(Slugs.ONBOARDING),
    to: getLink(Slugs.ONBOARDING),
    isFullscreen: true
  }),
  getOnboardingPreferences: (): RouteType => ({
    path: getLink(Slugs.PREFERENCES),
    label: getLinkLabel(Slugs.PREFERENCES),
    to: getLink(Slugs.ONBOARDING, Slugs.PREFERENCES),
    isFullscreen: true
  }),
  getOnboardingTestYourself: (): RouteType => ({
    path: getLink(Slugs.TEST_YOURSELF, '*'),
    label: getLinkLabel(Slugs.TEST_YOURSELF),
    to: getLink(Slugs.ONBOARDING, Slugs.TEST_YOURSELF),
    isFullscreen: true
  })
};

export const AppRoutes = {
  getDashboard: (): RouteType => ({
    path: getLink(Slugs.DASHBOARD),
    label: getLinkLabel(Slugs.DASHBOARD),
    to: getLink(Slugs.DASHBOARD),
    isFullscreen: false
  }),
  getTodo: (): RouteType => ({
    path: getLink(Slugs.TODO, '*'),
    label: getLinkLabel(Slugs.TODO),
    to: getLink(Slugs.TODO),
    isFullscreen: false
  }),
  getTodoUserActivityAdd: (): RouteType => ({
    path: getLink(Slugs.ADD),
    label: getLinkLabel(Slugs.ADD),
    to: getLink(Slugs.TODO, Slugs.ADD),
    isFullscreen: true
  }),
  getTodoUserActivityUpdate: (id: string): RouteType => ({
    path: getLink(Slugs.ADD, ':id'),
    label: getLinkLabel(Slugs.ADD),
    to: getLink(Slugs.TODO, Slugs.ADD, id),
    isFullscreen: true
  }),
  getTodoUserActivity: (id: string): RouteType => ({
    path: getLink(':id'),
    label: getLinkLabel(Slugs.TODO),
    to: getLink(Slugs.TODO, id),
    isFullscreen: false
  }),
  getInsight: (): RouteType => ({
    path: getLink(Slugs.INSIGHT, '*'),
    label: getLinkLabel(Slugs.INSIGHT),
    to: getLink(Slugs.INSIGHT),
    isFullscreen: false
  }),
  getInsightChallenges: (): RouteType => ({
    path: getLink(Slugs.CHALLENGES, '*'),
    label: getLinkLabel(Slugs.CHALLENGES),
    to: getLink(Slugs.INSIGHT, Slugs.CHALLENGES),
    isFullscreen: false
  }),
  getInsightChallengesAdd: (): RouteType => ({
    path: getLink(Slugs.ADD),
    label: getLinkLabel(Slugs.ADD),
    to: getLink(Slugs.INSIGHT, Slugs.CHALLENGES, Slugs.ADD),
    isFullscreen: true
  }),
  getInsightChallengesStart: (id: string): RouteType => ({
    path: getLink(Slugs.START, ':id'),
    label: getLinkLabel(Slugs.START),
    to: getLink(Slugs.INSIGHT, Slugs.CHALLENGES, Slugs.START, id),
    isFullscreen: true
  }),
  getInsightChallenge: (slug: string): RouteType => ({
    path: getLink(':slug'),
    label: getLinkLabel(Slugs.CHALLENGES),
    to: getLink(Slugs.INSIGHT, Slugs.CHALLENGES, slug),
    isFullscreen: false
  }),
  getInsightProgress: (): RouteType => ({
    path: getLink(Slugs.PROGRESS),
    label: getLinkLabel(Slugs.PROGRESS),
    to: getLink(Slugs.INSIGHT, Slugs.PROGRESS),
    isFullscreen: false
  }),
  getInsightReport: (): RouteType => ({
    path: getLink(Slugs.REPORT),
    label: getLinkLabel(Slugs.REPORT),
    to: getLink(Slugs.INSIGHT, Slugs.REPORT),
    isFullscreen: false
  }),
  getActivities: (): RouteType => ({
    path: getLink(Slugs.ACTIVITIES, '*'),
    label: getLinkLabel(Slugs.ACTIVITIES),
    to: getLink(Slugs.ACTIVITIES),
    isFullscreen: false
  }),
  getActivity: (slug: string): RouteType => ({
    path: getLink(':slug'),
    label: getLinkLabel(Slugs.ACTIVITIES),
    to: getLink(Slugs.ACTIVITIES, slug),
    isFullscreen: false
  }),
  getRewards: (): RouteType => ({
    path: getLink(Slugs.REWARDS),
    label: getLinkLabel(Slugs.REWARDS),
    to: getLink(Slugs.REWARDS),
    isFullscreen: false
  }),
  getMyProfile: (params = ''): RouteType => ({
    path: getLink(Slugs.MY_PROFILE, '*'),
    label: getLinkLabel(Slugs.MY_PROFILE),
    to: getLink(Slugs.MY_PROFILE) + params,
    isFullscreen: false
  }),
  getMyProfilePreferences: (): RouteType => ({
    path: getLink(Slugs.PREFERENCES),
    label: getLinkLabel(Slugs.PREFERENCES),
    to: getLink(Slugs.MY_PROFILE, Slugs.PREFERENCES),
    isFullscreen: true
  }),
  getMyProfileInbox: (): RouteType => ({
    path: getLink(Slugs.INBOX),
    label: getLinkLabel(Slugs.INBOX),
    to: getLink(Slugs.MY_PROFILE, Slugs.INBOX),
    isFullscreen: false
  }),
  getMyProfileEditProfilePicture: (): RouteType => ({
    path: getLink(Slugs.EDIT_PROFILE_PICTURE),
    label: getLinkLabel(Slugs.EDIT_PROFILE_PICTURE),
    to: getLink(Slugs.MY_PROFILE, Slugs.EDIT_PROFILE_PICTURE),
    isFullscreen: true
  }),
  getLogin: (): RouteType => ({
    path: getLink(Slugs.LOGIN),
    label: getLinkLabel(Slugs.LOGIN),
    to: getLink(Slugs.LOGIN),
    isFullscreen: false
  }),
  getRegistration: (variant = 'A'): RouteType => ({
    path: variant === 'A' ? getLink(Slugs.REGISTER) : getLink(Slugs.REGISTER_ALTERNATIVE),
    label: getLinkLabel(Slugs.REGISTER),
    to: variant === 'A' ? getLink(Slugs.REGISTER) : getLink(Slugs.REGISTER_ALTERNATIVE),
    isFullscreen: false
  }),
  getForgotPassword: (): RouteType => ({
    path: getLink(Slugs.FORGOT_PASSWORD),
    label: getLinkLabel(Slugs.FORGOT_PASSWORD),
    to: getLink(Slugs.FORGOT_PASSWORD),
    isFullscreen: false
  }),
  getUpdatePassword: (): RouteType => ({
    path: getLink(Slugs.EDIT_PASSWORD),
    label: getLinkLabel(Slugs.EDIT_PASSWORD),
    to: getLink(Slugs.EDIT_PASSWORD),
    isFullscreen: false
  }),
  getFaq: (): RouteType => ({
    path: getLink(Slugs.FAQ),
    label: getLinkLabel(Slugs.FAQ),
    to: getLink(Slugs.FAQ),
    isFullscreen: false
  }),
  getSupport: (): RouteType => ({
    path: getLink(Slugs.SUPPORT),
    label: getLinkLabel(Slugs.SUPPORT),
    to: getLink(Slugs.SUPPORT),
    isFullscreen: false
  }),
  getSelfTest: (): RouteType => ({
    path: getLink(Slugs.TEST_YOURSELF, '*'),
    label: getLinkLabel(Slugs.TEST_YOURSELF),
    to: getLink(Slugs.TEST_YOURSELF),
    isFullscreen: true
  }),
  getSelfTestResults: (): RouteType => ({
    path: getLink(Slugs.RESULTS),
    label: getLinkLabel(Slugs.RESULTS),
    to: getLink(Slugs.TEST_YOURSELF, Slugs.RESULTS),
    isFullscreen: true
  }),
  getContentPage: (slug: string): RouteType => ({
    path: getLink(':slug'),
    label: getLinkLabel(Slugs.CONTENT_PAGES),
    to: getLink(slug),
    isFullscreen: false
  })
};
