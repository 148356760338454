import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { todoService } from '@Services/Todo';
import { userActivityService, UserActivityUpdateRequest } from '@Services/UserActivity';
import { trackMatomoTodoCreate } from '@Utils/MatomoUtils';

export const useUserActivityUpdate = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: UserActivityUpdateRequest) => {
      return userActivityService.update(config);
    },
    {
      onSuccess: ({ id }, { customTitle, description, expectation }) => {
        trackMatomoTodoCreate(trackEvent, customTitle, description, expectation);

        queryClient.invalidateQueries([todoService.getEndpoint()]);
        queryClient.invalidateQueries([userActivityService.getEndpoint(), id]);
      }
    }
  );
};
