import { IconName } from '@fortawesome/fontawesome-common-types';
import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PinActivity } from '@Components/container';
import { Card, CardTag, Tag, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { getActivityTo } from '@Utils/ActivityUtils';

import styles from './CardActivity.module.scss';
import { CardActivityProps } from './CardActivity.props';

export const CardActivity = ({ activity, className }: CardActivityProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();

  const getTags = useCallback(() => {
    const tags = [] as CardTag[];

    tags.push({
      icon: activity.type[0].icon as IconName
    });

    tags.push({
      icon: 'star',
      label: String(activity.points)
    });

    if (activity.popular) {
      tags.push({
        icon: 'users',
        label: t('COMMON.GLOBALS.POPULAR')
      });
    }

    return tags;
  }, [activity, t]);

  return (
    <Card
      to={getActivityTo(activity)}
      image={activity.thumbnail[0]?.cdnFiles[0]?.url}
      tags={getTags()}
      className={cn(styles.CardActivity, className, {
        [styles.isPinned]: activity.pinned,
        [styles.isCompleted]: activity.completed
      })}
    >
      <div className={styles.Title}>
        <Text.H4>{activity.title}</Text.H4>

        {activity.completed ? (
          <Tag
            className={cn(styles.Pin, styles.isCompleted)}
            icon="check"
            size="xs"
            color={color.success.default}
          />
        ) : (
          <PinActivity className={styles.Pin} activity={activity} />
        )}
      </div>

      <Text.Micro className={styles.ReadingTime}>{activity.duration[0].body || 0}</Text.Micro>

      <Text.Small className={styles.Excerpt}>{activity.excerpt}</Text.Small>
    </Card>
  );
};
