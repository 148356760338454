import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form, InputCheckboxOption } from '@Components/ui';
import { useActivityFilters } from '@Hooks/activity';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { removeEmptyOrUndefinedFromObject } from '@Utils/CommonUtils';
import { enumToInputOptions } from '@Utils/FormUtils';
import { trackMatomoActivityFilter } from '@Utils/MatomoUtils';
import Yup from '@Utils/YupUtils';

import {
  filterFromValues,
  filterToInitialValues,
  filterToInputOptions
} from '../../../../../../../../utils';
import styles from './ActivityFilterTagsForm.module.scss';
import {
  ActivityFilterTagsFormProps,
  ActivityFilterTagsFormValues,
  ActivityFilterTagsFormValuesWithLabels,
  FilterTagsPoints
} from './ActivityFilterTagsForm.props';

export const ActivityFilterTagsForm = forwardRef(
  ({ onChange }: ActivityFilterTagsFormProps, ref: any) => {
    const { t } = useTranslation();
    const trackEvent = useMatomoTrackEvent();
    const { data } = useActivityFilters();

    const initialValues = useMemo(() => {
      if (data) {
        return {
          subjects: filterToInitialValues(data.subjects),
          durations: filterToInitialValues(data.durations),
          types: filterToInitialValues(data.types)
        };
      }
    }, [data]);

    const validationSchema = Yup.object().shape({
      points: Yup.array().min(0).max(1)
    });

    useEffect(() => {
      if (ref.current?.submit) {
        ref.current?.submit();
      }
    }, [ref, data]);

    const handleChange = useCallback(
      ({ points, ...tags }: UnpackNestedValue<DeepPartial<ActivityFilterTagsFormValues>>) => {
        const params = {
          subjects: filterFromValues(tags?.subjects, data?.subjects),
          types: filterFromValues(tags?.types, data?.types),
          durations: filterFromValues(tags?.durations, data?.durations),
          points: points?.[0]
        } as ActivityFilterTagsFormValuesWithLabels;

        const cleanedParams = removeEmptyOrUndefinedFromObject(params);

        onChange(cleanedParams);
      },
      [data, onChange]
    );

    const handleMatomo = useCallback(
      (option: InputCheckboxOption, checked?: boolean) => {
        trackMatomoActivityFilter(trackEvent, String(option.label), checked || false);
      },
      [trackEvent]
    );

    if (data) {
      return (
        <Form.Form<ActivityFilterTagsFormValues>
          ref={ref}
          onSubmit={handleChange}
          onChange={handleChange}
          initialValues={initialValues}
          validationSchema={validationSchema}
          className={styles.ActivityFilterTagsForm}
        >
          <Form.Input.Checkbox
            name="subjects"
            data-testid="subjectsInput"
            className={styles.Group}
            label={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.FORM.FIELDS.SUBJECTS.LABEL')}
            options={{
              onClick: handleMatomo,
              options: filterToInputOptions(data.subjects)
            }}
          />

          <Form.Input.Checkbox
            name="types"
            data-testid="typesInput"
            className={styles.Group}
            label={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.FORM.FIELDS.TYPES.LABEL')}
            options={{
              onClick: handleMatomo,
              options: filterToInputOptions(data.types)
            }}
          />

          <Form.Input.Checkbox
            name="points"
            data-testid="pointsInput"
            className={styles.Group}
            label={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.FORM.FIELDS.POINTS.LABEL')}
            options={{
              isSingle: true,
              onClick: handleMatomo,
              options: enumToInputOptions(
                FilterTagsPoints,
                t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.FORM.FIELDS.POINTS.OPTIONS', {
                  returnObjects: true
                })
              )
            }}
          />

          <Form.Input.Checkbox
            name="durations"
            data-testid="durationsInput"
            className={styles.Group}
            label={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.FORM.FIELDS.DURATIONS.LABEL')}
            options={{
              onClick: handleMatomo,
              options: filterToInputOptions(data.durations)
            }}
          />
        </Form.Form>
      );
    }

    return null;
  }
);
