import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Modal } from '@Components/layout';
import {
  Anchor,
  Box,
  Button,
  Confetti,
  Loader,
  SliderFullPage,
  Text,
  useSliderFullPageItemActiveAfterAnimation
} from '@Components/ui';
import { useBoolean, useTimeouts } from '@Hooks/common';

import { OnboardingSuccessSlideProps } from './OnboardingSuccessSlide.props';

export const OnboardingSuccessSlide = ({ onSuccess }: OnboardingSuccessSlideProps) => {
  const { value: isModalOpen, setFalse: setModalClosed } = useBoolean(true);
  const ref = useRef<HTMLDivElement | null>(null);
  const { isActiveAndAnimated } = useSliderFullPageItemActiveAfterAnimation();

  const { t } = useTranslation();
  const { addTimeout } = useTimeouts();

  useEffect(() => {
    if (!isModalOpen) {
      addTimeout(() => {
        toast.success(t('DOMAIN.ONBOARDING.REGISTRATION.SUCCESS.SUCCESS'));

        onSuccess();
      }, 2000);
    }
  }, [addTimeout, isModalOpen, onSuccess, t]);

  return (
    <SliderFullPage.Item>
      <Modal
        title={t('DOMAIN.ONBOARDING.REGISTRATION.SUCCESS.MODAL.TITLE')}
        isOpen={isActiveAndAnimated && isModalOpen}
        onClose={setModalClosed}
      >
        <div ref={ref}>
          <Confetti targetRef={ref} isExploding={isModalOpen && isActiveAndAnimated} />

          <Text.Normal>{t('DOMAIN.ONBOARDING.REGISTRATION.SUCCESS.MODAL.BODY')}</Text.Normal>

          <Box mt={2}>
            <Button onClick={setModalClosed} icon="arrow-right">
              {t('DOMAIN.ONBOARDING.REGISTRATION.SUCCESS.MODAL.PROCEED')}
            </Button>
          </Box>
        </div>
      </Modal>

      <Box mt="25vh">
        <Loader>
          <Text.Success>
            {t('DOMAIN.ONBOARDING.REGISTRATION.SUCCESS.MESSAGE')}

            <Anchor onClick={onSuccess} data-testid="skipAnchor">
              {t('DOMAIN.ONBOARDING.REGISTRATION.SUCCESS.REDIRECT_PAGE')}
            </Anchor>
          </Text.Success>
        </Loader>
      </Box>
    </SliderFullPage.Item>
  );
};
