import { BaseService, dePortalenService } from '@Services/common';
import { QuestionnaireSubmissionSubmitSelfTestSkipType } from '@Services/QuestionnaireSubmission';

import {
  OnboardingGetPersonaQuestionnaireResponse,
  OnboardingGetPersonaVerificationQuestionResponse,
  OnboardingGetSelfTestDomainsResponse,
  OnboardingGetSelfTestQuestionnaireResponse,
  OnboardingRegisterRequest,
  OnboardingRegisterResponse,
  OnboardingResendEmailRequest,
  OnboardingSubmitPersonaVerificationQuestionRequest,
  OnboardingSubmitPersonaVerificationQuestionResponse,
  OnboardingSubmitSelfTestDomainsRequest,
  OnboardingUpdateEmailRequest,
  OnboardingUpdatePhoneRequest,
  OnboardingValidateEmailRequest,
  OnboardingValidateEmailResponse,
  OnboardingVerifyEmailRequest,
  OnboardingVerifyPhoneRequest,
  OnboardingVerifyRequest,
  OnboardingVerifyResponse
} from './OnboardingService.props';

export class OnboardingService extends BaseService {
  verify = async (params: OnboardingVerifyRequest) => {
    const response = await this.post<OnboardingVerifyResponse>('/verify', params);

    return response.data;
  };

  register = async (params: OnboardingRegisterRequest) => {
    const response = await this.post<OnboardingRegisterResponse>('/register', params);

    return response.data;
  };

  validateEmail = async (params: OnboardingValidateEmailRequest) => {
    const response = await this.post<OnboardingValidateEmailResponse>('/validate/email', params);

    return response.data;
  };

  updateEmail = async (params: OnboardingUpdateEmailRequest) => {
    await this.post<void>('/update/email', params);
  };

  resendEmail = async (params: OnboardingResendEmailRequest) => {
    const response = await this.post('/resend/email', params);

    return response.data;
  };

  verifyEmail = async (params: OnboardingVerifyEmailRequest) => {
    await this.post<void>('/verify/email', params);
  };

  updatePhone = async (params: OnboardingUpdatePhoneRequest) => {
    await this.post<void>('/update/phonenumber', params);
  };

  verifyPhone = async (params: OnboardingVerifyPhoneRequest) => {
    await this.post<void>('/verify/phonenumber', params);
  };

  getPersonaQuestionnaire = async () => {
    const response = await this.get<OnboardingGetPersonaQuestionnaireResponse>(
      '/persona/questionnaire'
    );

    return response.data;
  };

  getSelfTestQuestionnaire = async () => {
    const response = await this.get<OnboardingGetSelfTestQuestionnaireResponse>(
      '/selftest/questionnaire'
    );

    return response.data;
  };

  getPersonaVerificationQuestion = async () => {
    const response = await this.get<OnboardingGetPersonaVerificationQuestionResponse>(
      '/persona/verification'
    );

    return response.data;
  };

  submitPersonaVerificationQuestion = async (
    config: OnboardingSubmitPersonaVerificationQuestionRequest
  ) => {
    const response = await this.post<OnboardingSubmitPersonaVerificationQuestionResponse>(
      '/persona/verification',
      config
    );

    return response.data;
  };

  skipSelfTest = async (type: QuestionnaireSubmissionSubmitSelfTestSkipType) => {
    const response = await this.post<void>('/selftest/skip', {
      type: type
    });

    return response.status === 204;
  };

  getSelfTestDomains = async () => {
    const response = await this.get<OnboardingGetSelfTestDomainsResponse>('/selftest/domains');

    return response.data;
  };

  submitSelfTestDomains = async ({ domains }: OnboardingSubmitSelfTestDomainsRequest) => {
    await this.post<void>('/selftest/domains', domains);
  };
}

export const onboardingService = new OnboardingService(
  dePortalenService.getAxiosInstance(),
  '/onboarding'
);
