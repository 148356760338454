import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@Utils/PlatformUtils';

export const styles = StyleSheet.create({
  Medium: {
    color: getTheme().color.font.default,
    fontFamily: getTheme().font.family.base,
    fontSize: getTheme().font.medium.fontSize,
    fontWeight: 'medium'
  }
});
