import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ListActivityItemLarge } from '@Components/list';
import { Block, Box, Button, CallToAction, Text, useTabGroupChildContext } from '@Components/ui';
import { useChallengeOne } from '@Hooks/challenge';
import { TargetAlignment, TargetDirection } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';
import { challengeService } from '@Services/Challenge';

import { useNonStartedActivities } from '../../../../../hooks';
import styles from './ChallengeItemRecommendedActivitiesBlock.module.scss';

export const ChallengeItemRecommendedActivitiesBlock = () => {
  const ref = useRef(null);
  const { slug } = useParams<{ slug: string }>();
  const queryClient = useQueryClient();

  const { isActive } = useTabGroupChildContext();

  const { t } = useTranslation();
  const { data: challenge } = useChallengeOne({ id: slug ?? '' });
  const activities = useNonStartedActivities(slug ?? '');

  const handleChallengeInvalidationOnPin = useCallback(() => {
    queryClient.invalidateQueries([challengeService.getEndpoint(), { id: slug }]);
  }, [queryClient, slug]);

  if (challenge && challenge.completed) {
    return (
      <>
        <Block ref={ref} theme="gray" className={styles.ChallengeItemRecommendedActivitiesBlock}>
          <Text.H4 className={styles.Title}>
            {t('DOMAIN.CHALLENGE.DETAIL.RECOMMENDED_TITLE')}
          </Text.H4>

          <ListActivityItemLarge
            activities={activities ?? []}
            canPin
            onPinSuccess={handleChallengeInvalidationOnPin}
          />

          <Box mt={3}>
            <Button to={AppRoutes.getActivities().to} icon="arrow-right">
              {t('DOMAIN.CHALLENGE.DETAIL.ALL_CONTENT')}
            </Button>
          </Box>
        </Block>

        <CallToAction
          targetRef={ref}
          label={t('DOMAIN.CHALLENGE.DETAIL.GO_BEYOND')}
          direction={TargetDirection.LEFT}
          alignment={TargetAlignment.TOP}
          isReversed
          isStatic
          isVisible={isActive}
        />
      </>
    );
  }

  return null;
};
