export const VIEWBOX_WIDTH = 100;
export const VIEWBOX_HEIGHT = 100;
export const VIEWBOX_HEIGHT_HALF = 50;
export const VIEWBOX_CENTER_X = 50;
export const VIEWBOX_CENTER_Y = 50;
export const STROKE_WIDTH = 8;
export const MAX_FILL_RATIO = 0.85;

export const ANIMATION_DELAY = 500;
export const BASE_WIDTH = 174;
export const PATH_SPACING = 44;
export const PATH_SPACING_PATHS = 4;
