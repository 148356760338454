import { MutableRefObject, useCallback } from 'react';

import { getElementMeasurements } from '@Utils/ElementUtils';
import { scrollTo } from '@Utils/WindowUtils';

export const useScrollTo = (ref: MutableRefObject<HTMLElement | null>) => {
  const triggerScroll = useCallback(() => {
    const { location } = getElementMeasurements(ref.current);

    scrollTo(location.y);
  }, [ref]);

  return { triggerScroll };
};
