import { useTranslation } from 'react-i18next';

import {
  Box,
  Image,
  SliderFullPage,
  SliderNavigation,
  Text,
  useSliderFullPageContext
} from '@Components/ui';
import { getImageUrl } from '@Utils/ImageUtils';

import { useQuestionnaireContext } from '../../context';
import { QuestionnaireSectionIntroSlideProps } from './QuestionnaireSectionIntroSlide.props';

export const QuestionnaireSectionIntroSlide = ({
  submission,
  totalSlides,
  currentSlideIndex,
  title,
  isLoadingNext,
  isLoadingPrevious,
  onInfoClick,
  paginationPrefix
}: QuestionnaireSectionIntroSlideProps) => {
  const { t } = useTranslation();
  const { submit, goToPrevious } = useQuestionnaireContext();
  const { isAnimating } = useSliderFullPageContext();

  return (
    <SliderFullPage.Item data-testid="questionnaireSectionIntroSlide">
      <SliderFullPage.Head superTitle={title} title={submission.currentRootSection.title} />

      <SliderFullPage.Block>
        <Box mb={3}>
          <Image src={getImageUrl(submission.currentRootSection.image)} ratio="2:1" />
        </Box>

        <Text.Large dangerousHtml={submission.currentRootSection.description} />

        <Box mt={4}>
          <SliderNavigation
            onPrevious={submission.hasPreviousQuestion ? goToPrevious : undefined}
            nextLabel={t('COMPONENTS.QUESTIONNAIRE.NEXT_LABEL')}
            onNext={submit}
            isLoadingNext={isLoadingNext}
            onInfoClick={onInfoClick}
            isLoadingPrevious={isLoadingPrevious}
            current={currentSlideIndex}
            total={totalSlides}
            isDisabled={isAnimating}
            paginationPrefix={paginationPrefix}
          />
        </Box>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
