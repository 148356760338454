import { useCallback } from 'react';

import { ListInboxItem } from '@Components/list';
import { LazyList } from '@Components/ui';
import { useInfiniteData } from '@Hooks/common';
import { useInboxList } from '@Hooks/inbox';
import { handleApiError } from '@Utils/ErrorUtils';

import { InboxListProps } from './InboxList.props';

export const InboxList = ({ className }: InboxListProps) => {
  const {
    data: inboxItems,
    hasNextPage: hasNextInboxPage,
    fetchNextPage: fetchNextInboxPage,
    isLoading: isLoadingMessages,
    isFetched: hasFetchedMessages
  } = useInboxList();

  const messages = useInfiniteData(inboxItems);

  const handleFetchNextPage = useCallback(async () => {
    if (hasNextInboxPage) {
      try {
        await fetchNextInboxPage();
      } catch (e) {
        handleApiError(e);
      }
    }
  }, [fetchNextInboxPage, hasNextInboxPage]);

  return (
    <LazyList
      className={className}
      hasNextPage={hasNextInboxPage}
      fetchNextPage={handleFetchNextPage}
      isFetched={hasFetchedMessages}
      isLoading={isLoadingMessages}
      totalItems={inboxItems?.pages[0].total}
    >
      {messages && messages.length > 0 && <ListInboxItem messages={messages} />}
    </LazyList>
  );
};
