import { useCallback } from 'react';

import { useLocationEffect } from '@Hooks/common';

export const useMatomoTrackPageView = () => {
  const handleTrackEvent = useCallback(() => {
    if (window._paq) {
      window._paq.push(['setCustomUrl', window.location.href]);
      window._paq.push(['setDocumentTitle', window.location.href]);
      window._paq.push(['trackPageView']);
    }
  }, []);

  useLocationEffect(handleTrackEvent);
};
