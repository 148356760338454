import { useMutation, useQueryClient } from 'react-query';

import { InboxReadMessageRequest, inboxService } from '@Services/Inbox';
import { userService } from '@Services/User';

export const useInboxReadMessage = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [inboxService.getEndpoint()],
    (params: InboxReadMessageRequest) => {
      return inboxService.readMessage(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([inboxService.getEndpoint()]);
        queryClient.invalidateQueries([userService.getEndpoint()]);
      }
    }
  );
};
