import { useCallback, useEffect, useMemo, useState } from 'react';

import { HTMLMeasurements } from '@Constants/interfaces';

import { MILESTONES_ADDITIONAL_ROWS, MILESTONES_INITIAL_ROWS } from './constants';

export const useMilestoneListData = (measurements: HTMLMeasurements[]) => {
  const firstRowY = measurements[0]?.location.y;

  const elementsPerRow = useMemo(() => {
    return measurements.reduce((elementsInRow: number, currentElement: HTMLMeasurements) => {
      if (currentElement.location.y === firstRowY) {
        return elementsInRow + 1;
      }

      return elementsInRow;
    }, 0);
  }, [firstRowY, measurements]);

  const totalRows = Math.ceil(measurements.length / elementsPerRow);

  return {
    totalRows,
    elementsPerRow
  };
};

export const useRowHandler = (totalRows: number) => {
  const [currentRow, setCurrentRow] = useState(MILESTONES_INITIAL_ROWS);

  useEffect(() => {
    if (currentRow > totalRows) {
      setCurrentRow(totalRows);
    }
  }, [currentRow, totalRows]);

  const handleLoadMore = useCallback(() => {
    if (currentRow >= totalRows) {
      return setCurrentRow(MILESTONES_INITIAL_ROWS);
    }

    if (currentRow < totalRows) {
      setCurrentRow((prev) => prev + MILESTONES_ADDITIONAL_ROWS);
    }
  }, [currentRow, totalRows]);

  return {
    currentRow,
    loadMore: handleLoadMore
  };
};
