import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container, PreprRenderer } from '@Components/layout';
import { Block, Box, Loader } from '@Components/ui';
import { useActivity } from '@Hooks/activity';
import { usePlayInView } from '@Hooks/common';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { usePreprTrackView } from '@Hooks/prepr';
import { AppRoutes } from '@Navigation/Routes';
import { trackMatomoActivityOpen, trackMatomoActivityRead } from '@Utils/MatomoUtils';

import {
  ActivityChallenge,
  ActivityFooter,
  ActivityHeader,
  ActivityRelated
} from './internal/components';

export const ActivityItemScreen = () => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  const {
    data: activity,
    isLoading: isActivityLoading,
    isError: isActivityError
  } = useActivity(slug || '');

  const trackEvent = useMatomoTrackEvent();
  usePreprTrackView(activity?.id);

  useEffect(() => {
    if (isActivityError) {
      toast.error(t('DOMAIN.ACTIVITY.ERROR'));

      navigate(AppRoutes.getActivities().to);
    }
  }, [isActivityError, navigate, t]);

  const handleTrack = useCallback(() => {
    trackMatomoActivityRead(trackEvent, activity?.title || '', activity?.type[0].title || '');
  }, [trackEvent, activity?.title, activity?.type]);

  const [ref, reset] = usePlayInView(handleTrack);

  useEffect(() => {
    reset();

    trackMatomoActivityOpen(trackEvent, activity?.title || '', activity?.type[0].title || '');
  }, [activity?.title, activity?.type, trackEvent, reset]);

  return (
    <Container>
      {isActivityLoading && (
        <Box my={4}>
          <Loader>
            {t('COMMON.GLOBALS.LOADING_MODEL', { name: t('DOMAIN.ACTIVITY.SINGLE') })}
          </Loader>
        </Box>
      )}

      {activity && (
        <>
          <ActivityHeader activity={activity} />

          <Block>
            <PreprRenderer activity={activity} content={activity.content} />

            <div ref={ref}>
              <ActivityFooter activity={activity} />
            </div>
          </Block>

          {activity.challenge && <ActivityChallenge challenge={activity.challenge} />}

          <ActivityRelated activity={activity} />
        </>
      )}
    </Container>
  );
};
