import cn from 'classnames';

import styles from './Spacer.module.scss';
import { SpacerProps } from './Spacer.props';

export const Spacer = ({ top, bottom, className }: SpacerProps) => {
  const style = {
    marginTop: top && top * 8 + 'px',
    marginBottom: bottom && bottom * 8 + 'px'
  };

  return <div className={cn([styles.Spacer, className])} style={style} />;
};
