import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@Components/ui';
import { MAX_FIRSTNAME_LENGTH, MAX_SURNAME_LENGTH } from '@Constants/configs';
import { useChallengeInvite } from '@Hooks/challenge';
import { useApiError } from '@Hooks/common';
import Yup from '@Utils/YupUtils';

import { ChallengeShareFormProps, ChallengeShareFormValues } from './ChallengeShareForm.props';

export const ChallengeShareForm = ({
  children,
  challengeId,
  onSuccess
}: ChallengeShareFormProps) => {
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const challengeInviteMutation = useChallengeInvite();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(MAX_FIRSTNAME_LENGTH).required(),
    lastName: Yup.string().max(MAX_SURNAME_LENGTH).required(),
    email: Yup.string().email().required()
  });

  const handleSubmit = useCallback(
    async (values: ChallengeShareFormValues) => {
      try {
        await challengeInviteMutation.mutateAsync({
          id: challengeId,
          ...values
        });

        onSuccess();
      } catch (e) {
        handleError(e);
      }
    },
    [challengeId, challengeInviteMutation, onSuccess, handleError]
  );

  return (
    <Form.Form validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form.Input.Text
        label={t('DOMAIN.CHALLENGE.SHARE.FORM.FIRST_NAME.LABEL')}
        name="firstName"
        options={{ 'data-testid': 'firstNameInput' }}
      />

      <Form.Input.Text
        label={t('DOMAIN.CHALLENGE.SHARE.FORM.LAST_NAME.LABEL')}
        name="lastName"
        options={{ 'data-testid': 'lastNameInput' }}
      />

      <Form.Input.Text
        label={t('DOMAIN.CHALLENGE.SHARE.FORM.EMAIL.LABEL')}
        name="email"
        options={{ type: 'email', 'data-testid': 'emailInput' }}
      />

      {children}
    </Form.Form>
  );
};
