import { View } from '@react-pdf/renderer';

import { DomainBlock } from '../DomainBlock/DomainBlock';
import { ThemeList } from '../ThemeList/ThemeList';
import { DomainListProps } from './DomainList.props';
import { styles } from './DomainList.styles';

export const DomainList = ({ domains, style }: DomainListProps) => {
  return (
    <>
      {domains.map(({ domain, themes }) => {
        return (
          <View key={domain.id} style={style}>
            <DomainBlock
              title={domain.title}
              description={domain.description ?? ''}
              style={styles.DomainBlock}
            />

            <ThemeList domain={domain} themes={themes} />
          </View>
        );
      })}
    </>
  );
};
