import { IconName } from '@fortawesome/fontawesome-common-types';
import { useTranslation } from 'react-i18next';

import { Result } from '@Constants/interfaces';
import { usePlatform } from '@Hooks/common';
import { useResultColorMap } from '@Hooks/userTheme';
import { IUserTheme } from '@Services/UserTheme';

import { aggregateThemeResults } from './utils';

export const useLegendItems = (themes: IUserTheme[]) => {
  const { resultColorMap } = useResultColorMap();
  const { color } = usePlatform();
  const { t } = useTranslation();

  const aggregatedThemeResults = aggregateThemeResults(themes);

  const legendItems = [];

  if (aggregatedThemeResults[Result.GREEN].length > 0) {
    legendItems.push({
      color: resultColorMap[Result.GREEN],
      label: t('DOMAIN.THEME.RESULTS.GREEN')
    });
  }

  if (aggregatedThemeResults[Result.YELLOW].length > 0) {
    legendItems.push({
      color: resultColorMap[Result.YELLOW],
      label: t('DOMAIN.THEME.RESULTS.YELLOW')
    });
  }

  if (aggregatedThemeResults[Result.ORANGE].length > 0) {
    legendItems.push({
      color: resultColorMap[Result.ORANGE],
      label: t('DOMAIN.THEME.RESULTS.ORANGE')
    });
  }

  if (aggregatedThemeResults[Result.RED].length > 0) {
    legendItems.push({
      color: resultColorMap[Result.RED],
      label: t('DOMAIN.THEME.RESULTS.RED')
    });
  }

  if (aggregatedThemeResults.recommended.length > 0) {
    legendItems.push({
      color: color.primary.default,
      label: t('DOMAIN.THEME.RECOMMENDED'),
      icon: 'flag' as IconName
    });
  }

  return legendItems;
};
