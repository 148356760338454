import { useCallback, useState } from 'react';

import { Container, Sideview } from '@Components/layout';
import { useBoolean } from '@Hooks/common';
import { IReward } from '@Services/Reward';

import { RewardBlock, RewardPoints, RewardSideview } from './internal/components';

export const AppRewardScreen = () => {
  const [reward, setReward] = useState<IReward>();
  const { value: isVisible, setTrue: setOpen, setFalse: setClosed } = useBoolean(false);

  const handleClick = useCallback(
    (_reward: IReward) => {
      setReward(_reward);
      setOpen();
    },
    [setOpen]
  );

  return (
    <Container>
      <RewardPoints />

      <Sideview title={reward?.title || ''} isVisible={isVisible} onClose={setClosed}>
        {reward && <RewardSideview onClose={setClosed} reward={reward} />}
      </Sideview>

      <RewardBlock onClick={handleClick} />
    </Container>
  );
};
