import { useCallback, useEffect } from 'react';

import { ListCardReward } from '@Components/list';
import { LazyList } from '@Components/ui';
import { useInfiniteData } from '@Hooks/common';
import { useRewardList } from '@Hooks/reward';

import { RewardListProps } from './RewardList.props';

export const RewardList = ({ filter, onLoad, onClick }: RewardListProps) => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetched } = useRewardList(filter);

  const rewards = useInfiniteData(data);

  useEffect(() => {
    if (data) {
      onLoad(data);
    }
  }, [data, onLoad]);

  const handleFetchNextPage = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  return (
    <LazyList
      hasNextPage={hasNextPage}
      fetchNextPage={handleFetchNextPage}
      isFetched={isFetched}
      isLoading={isLoading}
      totalItems={data?.pages[0].total}
    >
      {rewards && rewards.length > 0 && <ListCardReward rewards={rewards} onClick={onClick} />}
    </LazyList>
  );
};
