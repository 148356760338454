import cn from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Cta } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

import styles from './SidebarCta.module.scss';
import { SidebarCtaProps } from './SidebarCta.props';

export const SidebarCta = ({ isCollapsed = false, className }: SidebarCtaProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div className={cn(styles.SidebarCta, className)} ref={ref} data-tourdotid="FAQ">
        <Cta
          title={t('COMPONENTS.SIDEBAR.HAS_QUESTION')}
          subtitle={t('COMPONENTS.SIDEBAR.FAQ_SUBTITLE')}
          to={AppRoutes.getSupport().to}
          collapsedIcon="question"
          isCollapsed={isCollapsed}
        />
      </div>
    </>
  );
};
