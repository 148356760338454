import { IBaseModel, IDomain, IMotivator, Result } from '@Constants/interfaces';
import { IPreference } from '@Services/Preference';
import { IUserPopup } from '@Services/UserPopup';

export enum ProfileStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export enum ProfileStatusStep {
  'PREFERENCES' = 'PREFERENCES',
  'PERSONA' = 'PERSONA',
  'SELFTEST' = 'SELFTEST',
  'CHALLENGE' = 'CHALLENGE'
}

export enum ProfileReminder {
  FUNCTIONAL = 'receiveFunctional',
  REMINDERS = 'receiveReminders'
}

export type ProfileStatusSteps = ProfileStatusStep[];

export interface IUser extends IBaseModel {
  firstName: string;
  lastName: string;
  email: string;
  level: number;
  avatar?: string;
  phoneNumber: string;
  receiveReminders: boolean;
  hasPhasedSelfTest: boolean;
  points: number;
  selfTestCompleted: boolean;
  allTimePoints: number;
  popup?: IUserPopup;
  motivator?: IMotivator;
  isFirstTime: boolean;
  openTodoCount: number;
  unreadMessageCount: number;
  profileStatus: ProfileStatus;
  profileStatusSteps: ProfileStatusSteps;
  likesCelebration: boolean;
  preferences: IPreference[];
  skippedSelfTest: boolean;
  companyId: string;
  personaId?: string;
}

export interface UserUpdateMeRequest {
  firstName: string;
  lastName: string;
  isFirstTime?: boolean;
  hasCompletedOnboarding?: boolean;
  avatar?: string | null;
  receiveReminders?: boolean;
}

export interface UserCloseTourdotRequest {
  id: string;
}

export interface UserToggleTourdotsRequest {
  hide: boolean;
}

export interface UserInitiateNewPhoneResponse {
  smsToken: string;
}

export interface UserVerifyPhoneRequest {
  token: string;
  smsToken: string;
}

export interface UserInitiateNewPhoneRequest {
  newPhoneNumber: string;
  newPhoneNumberConfirmed: string;
}

export interface UserUpdatePhoneRequest {
  newPhoneNumber: string;
  newPhoneNumberConfirmed: string;
  token: string;
  smsToken: string;
}
export interface UserVerifyUpdatePasswordRequest {
  token: string;
  smsToken: string;
}

export interface UserUpdatePasswordRequest {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmed: string;
  token: string;
  smsToken: string;
}

export interface UserInitiateUpdatePasswordResponse {
  smsToken: string;
}

export interface UserInitiateUpdateEmailResponse {
  smsToken: string;
}

export interface UserVerifyEmailRequest {
  token: string;
  smsToken: string;
}

export interface UserUpdateEmailRequest {
  currentEmail: string;
  newEmail: string;
  newEmailConfirmed: string;
  token: string;
  smsToken: string;
}

export interface UserVerifyNewEmailRequest {
  currentEmail: string;
  newEmail: string;
  newEmailConfirmed: string;
  token: string;
  smsToken: string;
}

export interface DomainScore {
  domain: IDomain;
  result?: Result;
}

export interface UserGetDomainsResponse {
  domainScores: DomainScore[];
  happinessScore?: number;
  happinessResult?: Result;
}
