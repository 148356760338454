/* eslint-disable prettier/prettier */
import { useTranslation } from 'react-i18next';

import { Form } from '@Components/ui';
import { Button } from '@Components/ui';
import { AppError } from '@Constants/enums';
import { useAuthStatus, useAuthValidateTwoFactor } from '@Hooks/auth';
import { useApiError } from '@Hooks/common';
import { setAccessTokens } from '@Utils/StorageUtils';
import Yup from '@Utils/YupUtils';

import { AuthTokenFormProps, AuthTokenFormValues } from './AuthTokenForm.props';

export const AuthTokenForm = ({ onSuccess, smsToken, email }: AuthTokenFormProps) => {
  const { t } = useTranslation();
  const authValidateTwoFactorMutation = useAuthValidateTwoFactor();
  const { error, handleError } = useApiError();
  const { setIsAuthenticated } = useAuthStatus();

  const validationSchema = Yup.object().shape({
    token: Yup.string().length(6).required()
  });

  const handleSubmit = async (values: AuthTokenFormValues) => {
    if (!smsToken) {
      throw new Error(AppError.NO_SMS_TOKEN_PROVIDED);
    }

    if (!email) {
      throw new Error(AppError.NO_EMAIL_PROVIDED);
    }

    try {
      const params = {
        ...values,
        smsToken,
        email
      };

      const response = await authValidateTwoFactorMutation.mutateAsync(params);

      setAccessTokens(response);
      setIsAuthenticated(true);

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      handleError(e, 'PHONE');
    }
  };

  return (
    <Form.Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form.Input.Code
        name="token"
        data-testid="tokenInput"
        options={{ isError: !!error, type: 'number', pattern: '[0-9]*', inputMode: 'numeric' }}
      />

      <Form.Layout.Error error={error} />

      <Button type="submit" icon="arrow-right" data-testid="smsCodeSubmit">
        {t('DOMAIN.AUTHENTICATION.LOGIN.TWO_FACTOR.FORM.LABELS.SUBMIT')}
      </Button>
    </Form.Form>
  );
};
