export const isInternalLink = (link: string) => {
  if (link.startsWith('http')) return false;

  if (link.startsWith('//')) return false;

  return true;
};

export const isExternalLink = (link: string) => {
  return !isInternalLink(link);
};

export const formatInternalLink = (link: string) => {
  if (link.startsWith('/')) return link;

  return `/${link}`;
};
