import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@Utils/PlatformUtils';
import { spacing } from '@Utils/ThemeUtils';

export const styles = StyleSheet.create({
  Page: {
    padding: spacing(4)
  },
  Logo: {
    width: 200,
    marginBottom: spacing(4)
  },
  PageNumber: {
    position: 'absolute',
    right: spacing(4),
    bottom: spacing(2),

    color: getTheme().color.font.default,
    fontFamily: getTheme().font.family.base,
    fontSize: getTheme().font.normal.fontSize,
    fontWeight: 'normal'
  },
  Title: {
    marginBottom: spacing(2)
  },
  Intro: {
    marginBottom: spacing(2)
  },
  Description: {
    marginBottom: spacing(2)
  },
  ProgressLegend: {
    marginBottom: spacing(5)
  }
});
