import cn from 'classnames';
import ReactDOM from 'react-dom';

import { Button, ButtonProps } from '@Components/ui';

import headerStyles from '../Header/internal/components/HeaderFullscreen/HeaderFullscreen.module.scss';
import styles from './ButtonHeader.module.scss';

export const ButtonHeader = ({ className, ...props }: ButtonProps) => {
  const header = document.querySelector(`.${headerStyles.HeaderFullscreen}`);

  if (header) {
    return ReactDOM.createPortal(
      <Button {...props} className={cn(styles.ButtonHeader, className)} />,
      header
    );
  }

  return null;
};
