import cn from 'classnames';

import { Image } from '@Components/ui';

import styles from './Thumbnail.module.scss';
import { ThumbnailProps } from './Thumbnail.props';

export const Thumbnail = ({
  thumbnail,
  alt,
  isHovering = false,
  hasPlaceholder = false,
  className
}: ThumbnailProps) => {
  if (!!thumbnail || hasPlaceholder) {
    return (
      <div className={cn(styles.Thumbnail, { [styles.isHovering]: isHovering }, className)}>
        {!!thumbnail && <Image src={thumbnail} alt={alt} ratio="2:1" />}
      </div>
    );
  }
  return null;
};
