import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonIcon } from '@Components/ui';

import { BackButtonProps } from './BackButton.props';

export const BackButton = ({ to, className }: BackButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  }, [navigate, to]);

  return (
    <ButtonIcon
      onClick={handleClick}
      label={t('COMPONENTS.BACK_BUTTON.LABEL')}
      className={className}
      icon={'chevron-left'}
    />
  );
};
