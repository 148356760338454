export enum QuestionnaireType {
  SELFTEST = 'Test jezelf',
  PERSONA = 'Vragenlijst persoonskenmerken'
}

export enum QuestionnaireSelftestType {
  REGULAR = 'Algemeen',
  ADAPTIVE = 'Adaptief',
  PHASED = 'Gefaseerd'
}
