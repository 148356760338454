import cn from 'classnames';

import { TodoItem } from '@Components/container';

import styles from './ListTodoItem.module.scss';
import { ListTodoItemProps } from './ListTodoItem.props';

export const ListTodoItem = ({ todos, className }: ListTodoItemProps) => {
  return (
    <div className={cn(styles.ListTodoItem, className)}>
      {todos.map((todo) => {
        return <TodoItem key={todo.id} todo={todo} hasImage className={styles.TodoItem} />;
      })}
    </div>
  );
};
