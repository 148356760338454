import { Container } from '@Components/layout';

import { AppFaq, AppFaqHeader } from './internal';

export const AppFaqScreen = () => {
  return (
    <Container>
      <AppFaqHeader />

      <AppFaq />
    </Container>
  );
};
