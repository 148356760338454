import { BaseService, dePortalenService } from '@Services/common';

import {
  ActivitySubjectsResponse,
  DataGetActivityTypesResponse,
  DataGetGgcResponse
} from './DataService.props';

class DataService extends BaseService {
  getGgc = async () => {
    const response = await this.get<DataGetGgcResponse>('/ggc');

    return response.data;
  };

  getActivityTypes = async () => {
    const response = await this.get<DataGetActivityTypesResponse>('/activityTypes');

    return response.data;
  };

  getActivitySubjects = async () => {
    const response = await this.get<ActivitySubjectsResponse>('/activitySubjects');

    return response.data;
  };
}

export const dataService = new DataService(dePortalenService.getAxiosInstance(), '/data');
