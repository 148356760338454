import { ProgressBar, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './InputRadioPoll.module.scss';
import { InputRadioPollProps } from './InputRadioPoll.props';

export const InputRadioPoll = ({ percentage }: InputRadioPollProps) => {
  const { color } = usePlatform();

  return (
    <div className={styles.InputRadioPoll}>
      <Text.Bold className={styles.Percentage}>{percentage}%</Text.Bold>

      <ProgressBar
        className={styles.Progress}
        current={percentage || 0}
        total={100}
        color={color.success.default}
      />
    </div>
  );
};
