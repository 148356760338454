import cn from 'classnames';

import { ProgressTheme } from '@Components/container';
import { Spacer } from '@Components/ui';

import { ProgressThemeLegend } from './internal/components';
import styles from './ListProgressTheme.module.scss';
import { ListProgressThemeProps } from './ListProgressTheme.props';

export const ListProgressTheme = ({
  items,
  hasLegend,
  checkedId,
  onChange,
  className
}: ListProgressThemeProps) => {
  return (
    <div
      className={cn(styles.ListProgressTheme, className, {
        [styles.hasLegend]: hasLegend
      })}
    >
      <div className={styles.List} data-testid="ListProgressThemeList">
        {items.map((item) => {
          return (
            <ProgressTheme
              onClick={onChange}
              theme={item}
              className={styles.Item}
              isChecked={checkedId === item.theme.id}
              key={item.id}
            />
          );
        })}
      </div>

      {hasLegend && (
        <>
          <Spacer top={2} bottom={2} />

          <ProgressThemeLegend themes={items} className={styles.Legend} />
        </>
      )}
    </div>
  );
};
