import cn from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InactiveLogoutPopup, MotivatorPopup } from '@Components/container';
import { Header, Sidebar } from '@Components/layout';
import { Loader } from '@Components/ui';
import { MOTIVATOR_TIMEOUT } from '@Constants/configs';
import {
  useBoolean,
  useIsFullscreen,
  useLocationEffect,
  usePlatform,
  useTimeouts
} from '@Hooks/common';
import { useMatomoTrackOutbound, useMatomoTrackPageView } from '@Hooks/matomo';
import { useUser } from '@Hooks/user';

import styles from './Page.module.scss';
import { PageProps } from './Page.props';

export const Page = ({ isLoading, children, className }: PageProps) => {
  useMatomoTrackPageView();
  useMatomoTrackOutbound();

  const { value: isMotivatorVisible, setTrue: setIsMotivatorVisible } = useBoolean(false);

  const {
    value: isSideMenuOpen,
    setTrue: setSideMenuOpen,
    setFalse: setSideMenuClosed
  } = useBoolean(false);

  const { background } = usePlatform();
  const { t } = useTranslation();
  const { popup } = useUser();
  const { addTimeout } = useTimeouts();
  const isFullScreen = useIsFullscreen() || isLoading;

  useLocationEffect(setSideMenuClosed);

  useEffect(() => {
    addTimeout(setIsMotivatorVisible, MOTIVATOR_TIMEOUT);
  }, [addTimeout, setIsMotivatorVisible]);

  return (
    <div
      className={cn(styles.Page, className, {
        [styles.isFullScreen]: isFullScreen
      })}
    >
      {isLoading && <Loader className={styles.Loader}>{t('COMMON.GLOBALS.PLEASE_WAIT')}</Loader>}

      {background.highlight && (
        <div
          className={styles.Highlight}
          style={{
            background: isFullScreen
              ? background.highlight.fullscreen
              : background.highlight.default
          }}
        />
      )}

      {background.fade && (
        <div className={styles.Fade} style={{ background: background.fade.default }} />
      )}

      <Sidebar
        className={styles.Sidebar}
        isOpen={isSideMenuOpen}
        isFullscreen={isFullScreen}
        onClose={setSideMenuClosed}
      />

      <Header
        onMenuToggleClick={setSideMenuOpen}
        className={styles.Header}
        isFullscreen={isFullScreen}
      />

      <div className={styles.Content}>{children}</div>

      {!!popup && isMotivatorVisible && <MotivatorPopup />}

      <InactiveLogoutPopup />
    </div>
  );
};
