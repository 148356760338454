import { useMutation } from 'react-query';

import { useAuthLogout } from '@Hooks/auth';
import { userService, UserUpdatePasswordRequest } from '@Services/User';

export const useUserUpdatePassword = () => {
  const logoutMutation = useAuthLogout();

  return useMutation(
    (config: UserUpdatePasswordRequest) => {
      return userService.updatePassword(config);
    },
    {
      onSuccess: async () => {
        await logoutMutation.mutateAsync();
      }
    }
  );
};
