import cn from 'classnames';
import { forwardRef } from 'react';

import styles from './InputTextarea.module.scss';
import { InputTextareaProps } from './InputTextarea.props';

export const InputTextarea = forwardRef(
  ({ className, isError, isValid, ...props }: InputTextareaProps, ref?: any) => {
    const classNames = cn(styles.InputTextarea, className, {
      [styles.isError]: isError,
      [styles.isValid]: isValid
    });

    return <textarea className={classNames} {...props} ref={ref} />;
  }
);
