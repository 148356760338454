import { Navigate, Route, Routes } from 'react-router-dom';

import { OnboardingOverviewScreen, OnboardingPreferenceScreen } from '@Screens/Onboarding';

import { OnboardingRoutes } from './Routes';
import { SelfTestStack } from './SelfTestStack';

export const OnboardingStack = () => {
  return (
    <Routes>
      <Route path="" element={<OnboardingOverviewScreen />} />

      <Route
        path={OnboardingRoutes.getOnboardingPreferences().path}
        element={<OnboardingPreferenceScreen />}
      />

      <Route path={OnboardingRoutes.getOnboardingTestYourself().path} element={<SelfTestStack />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
