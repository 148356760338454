import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Form } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useUserUpdateEmail } from '@Hooks/user';
import Yup from '@Utils/YupUtils';

import {
  UpdateEmailUpdateEmailStepProps,
  UpdateEmailUpdateEmailStepValues
} from './UpdateEmailUpdateEmailStep.props';

export const UpdateEmailUpdateEmailStep = ({
  smsToken,
  currentEmail,
  token,
  onSuccess
}: UpdateEmailUpdateEmailStepProps) => {
  const { t } = useTranslation();
  const { handleError, error } = useApiError();

  const userUpdateEmailMutation = useUserUpdateEmail();

  const handleSubmit = useCallback(
    async (values: UpdateEmailUpdateEmailStepValues) => {
      try {
        await userUpdateEmailMutation.mutateAsync({
          currentEmail,
          smsToken,
          token,
          ...values
        });
        onSuccess(values);
      } catch (e) {
        handleError(e, 'EMAIL');
      }
    },
    [userUpdateEmailMutation, currentEmail, handleError, onSuccess, smsToken, token]
  );

  const validationSchema = Yup.object().shape({
    newEmail: Yup.string().email().required(),
    newEmailConfirmed: Yup.string()
      .email()
      .oneOf(
        [Yup.ref('newEmail')],
        t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.NEW_EMAIL_CONFIRMED.NO_MATCH')
      )
      .required()
  });

  return (
    <Box mt={3}>
      <Form.Form<UpdateEmailUpdateEmailStepValues>
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form.Input.Text
          name="newEmail"
          data-testid="newEmail"
          label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.NEW_EMAIL.LABEL')}
        />

        <Form.Input.Text
          name="newEmailConfirmed"
          data-testid="newEmailConfirmed"
          label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.NEW_EMAIL_CONFIRMED.LABEL')}
        />

        <Form.Layout.Error error={error} />

        <Button type="submit" color="dark" icon="arrow-right" data-testid="submitButton">
          {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_EMAIL.UPDATE_EMAIL.SUBMIT_LABEL')}
        </Button>
      </Form.Form>
    </Box>
  );
};
