import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { onboardingService } from '@Services/Onboarding';
import {
  questionnaireSubmissionService,
  QuestionnaireSubmissionSubmitRequest
} from '@Services/QuestionnaireSubmission';
import { trackMatomoOnboardingQuestionnaireStep } from '@Utils/MatomoUtils';

export const useQuestionnaireSubmissionSubmit = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: QuestionnaireSubmissionSubmitRequest) => {
      return questionnaireSubmissionService.submit(config);
    },
    {
      onSuccess: (_, { questionnaireSubmission }) => {
        trackMatomoOnboardingQuestionnaireStep(trackEvent, questionnaireSubmission);

        queryClient.invalidateQueries([questionnaireSubmissionService.getEndpoint()]);
        queryClient.invalidateQueries([onboardingService.getEndpoint()]);
      }
    }
  );
};
