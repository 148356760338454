import { forwardRef, useCallback, useMemo, useRef } from 'react';

import { Confetti, Form } from '@Components/ui';
import { useApiError, useExplosion } from '@Hooks/common';
import { usePreferenceList, usePreferenceSubmit } from '@Hooks/preference';
import { PreferenceKey } from '@Services/Preference';
import { getInputOptions, getPreference } from '@Utils/PreferenceUtils';
import Yup from '@Utils/YupUtils';

import { SupportType } from './internal/constants';
import {
  PreferencesSupportTypeFormProps,
  PreferencesSupportTypeFormValues
} from './PreferencesSupportTypeForm.props';

export const PreferencesSupportTypeForm = forwardRef(
  ({ onSuccess, children, className }: PreferencesSupportTypeFormProps, ref: any) => {
    const confettiTargetRef = useRef(null);
    const previousValues = useRef<any>([]);
    const preferencesQuery = usePreferenceList();
    const preferenceSubmitMutation = usePreferenceSubmit();
    const { handleError } = useApiError();
    const { shouldExplode, explode, reset: resetConfetti } = useExplosion();

    const inputOptions = useMemo(() => {
      return getInputOptions(Object.values(SupportType), 'SUPPORT_TYPES');
    }, []);

    const handleSubmit = useCallback(
      async (values: PreferencesSupportTypeFormValues) => {
        try {
          await preferenceSubmitMutation.mutateAsync({
            key: PreferenceKey.SUPPORT_TYPES,
            values: values.supportTypes
          });

          onSuccess();
        } catch (e) {
          handleError(e);
        }
      },
      [onSuccess, preferenceSubmitMutation, handleError]
    );

    const handleChange = useCallback(
      async (values: string[]) => {
        if (
          values &&
          values.includes(SupportType.CELEBRATION) &&
          !previousValues.current?.includes(SupportType.CELEBRATION)
        ) {
          explode();
        } else {
          resetConfetti();
        }

        previousValues.current = values;
      },
      [explode, resetConfetti]
    );

    const initialValues = {
      supportTypes: getPreference(preferencesQuery.data || [], PreferenceKey.SUPPORT_TYPES)
    };

    const validationSchema = Yup.object().shape({
      supportTypes: Yup.array(Yup.string().required()).min(1).required()
    });

    if (preferencesQuery.isFetched) {
      return (
        <>
          <div ref={confettiTargetRef}>
            <Form.Form<PreferencesSupportTypeFormValues>
              ref={ref}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
              className={className}
              data-testid="supportTypeForm"
            >
              <Form.Input.Toggle
                name="supportTypes"
                data-testid="supportTypesInput"
                onChange={handleChange}
                options={{
                  options: inputOptions
                }}
              />

              {children}
            </Form.Form>
          </div>

          <Confetti targetRef={confettiTargetRef} isExploding={shouldExplode} />
        </>
      );
    }

    return null;
  }
);
