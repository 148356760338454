import { IBaseModel } from '@Constants/interfaces';
import { UserMotivatorButtonLink } from '@Services/UserTooltip';

export interface IUserPopup extends IBaseModel {
  answers: IUserPopupAnswer[];
  question: string;
  buttonText?: string;
  buttonLink?: UserMotivatorButtonLink;
  scaleMaxDescription?: string;
  scaleMinDescription?: string;
  type: UserPopupType;
}

export interface IUserPopupAnswer extends IBaseModel {
  text: string;
}

export interface UserPopupHideRequest {
  id: string;
  title: string;
  hide?: boolean;
}
export interface UserPopupSubmitRequest {
  id: string;
  title: string;
  answers?: IUserPopupAnswer[];
  answer: string;
}

export enum UserPopupType {
  SLIDER = 'SLIDER',
  RADIO = 'RADIO',
  SLIDER_STEP = 'SLIDER_STEP',
  CONTENT = 'CONTENT'
}
