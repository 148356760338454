import { AppRoutes } from '@Navigation/Routes';
import { UserMotivatorButtonLink } from '@Services/UserTooltip';

export const getMotivatorHref = (buttonLink: UserMotivatorButtonLink) => {
  switch (buttonLink) {
    case UserMotivatorButtonLink.ACTIVITIES:
      return AppRoutes.getActivities().to;
    case UserMotivatorButtonLink.PROGRESS:
      return AppRoutes.getInsightChallenges().to;
    case UserMotivatorButtonLink.START_CHALLENGE:
      return AppRoutes.getInsightChallengesAdd().to;
    case UserMotivatorButtonLink.TODO:
      return AppRoutes.getTodo().to;
    case UserMotivatorButtonLink.REWARDS:
      return AppRoutes.getRewards().to;
    case UserMotivatorButtonLink.DIGITAL_TOUR:
      return AppRoutes.getFaq().to;
  }
};
