import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@Utils/PlatformUtils';

export const styles = StyleSheet.create({
  H2: {
    color: getTheme().color.primary.default,
    fontFamily: getTheme().font.family.heading,
    fontSize: getTheme().font.h2.fontSize,
    fontWeight: 'black'
  }
});
