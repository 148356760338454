import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { DeepPartial, UnpackNestedValue } from 'react-hook-form';

import { Form } from '@Components/ui';
import { Block, Button, Text } from '@Components/ui';
import { useDebounce } from '@Hooks/common';

import styles from './SearchBlock.module.scss';
import { SearchBlockForm, SearchBlockProps } from './SearchBlock.props';

export const SearchBlock = ({
  title,
  buttonLabel,
  placeholder,
  onSearch,
  initialValue,
  className
}: SearchBlockProps) => {
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch, onSearch]);

  const handleFormChange = useCallback(
    (values: UnpackNestedValue<DeepPartial<SearchBlockForm>> | null) => {
      setSearch(values?.search || '');
    },
    []
  );

  return (
    <Block theme="gray" padding="sm" className={cn(styles.SearchBlock, className)}>
      {!!title && <Text.H3 className={styles.Title}>{title}</Text.H3>}

      <Form.Form<SearchBlockForm>
        onChange={handleFormChange}
        className={styles.Form}
        disableSubmitOnEnter
        initialValues={{
          search: initialValue
        }}
      >
        <Form.Input.Text
          name="search"
          className={styles.Input}
          options={{
            onKeyDown: (event: any) => event.keyCode !== 13,
            placeholder: placeholder
          }}
        />

        <Button className={styles.Button} icon="search" type="button">
          {buttonLabel && buttonLabel}
        </Button>
      </Form.Form>
    </Block>
  );
};
