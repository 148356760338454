import { MutableRefObject, useLayoutEffect, useState } from 'react';

export const useSlidingSelector = (
  refs: MutableRefObject<(HTMLElement | null)[]>,
  activeIdx: number
): [{ x: number; y: number }, number, number, number, number] => {
  const [activeOffset, setActiveOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [activeHeight, setActiveHeight] = useState(0);
  const [activeWidth, setActiveWidth] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);

  useLayoutEffect(() => {
    const item = refs.current[activeIdx];

    if (item) {
      setActiveOffset({ x: item.offsetLeft, y: item.offsetTop });
      setActiveHeight(item.scrollHeight);
      setActiveWidth(item.scrollWidth);
      setTotalWidth(item.parentElement?.scrollWidth || 0);
      setTotalHeight(item.parentElement?.scrollHeight || 0);
    }
  }, [activeIdx, refs]);

  return [activeOffset, activeHeight, activeWidth, totalWidth, totalHeight];
};
