import { useCallback } from 'react';

import { getItem, removeItem, setItem } from '@Utils/StorageUtils';

export const useStorage = <T>(key: string) => {
  const set = useCallback((data: T) => setItem<T>(key, data), [key]);
  const get = useCallback(() => getItem<T>(key), [key]);
  const remove = useCallback(() => removeItem(key), [key]);

  return {
    set,
    get,
    remove
  };
};
