import cn from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityFinish, AuthorActivity } from '@Components/container';
import { TagText } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { ActivityFinishResponse } from '@Services/Activity';
import { getActivityRoadmap, isRoadmapActivity, isRoadmapFinalStep } from '@Utils/ActivityUtils';
import { shortDate } from '@Utils/DateUtils';

import styles from './ActivityFooter.module.scss';
import { ActivityFooterProps } from './ActivityFooter.props';
import { ChallengeFinishedModal } from './internal/components';

export const ActivityFooter = ({ activity }: ActivityFooterProps) => {
  const [finishedChallengeSlug, setFinishedChallengeSlug] = useState<string | undefined>();

  const { t } = useTranslation();
  const { color } = usePlatform();

  const handleCloseChallengeFinishedModal = () => {
    setFinishedChallengeSlug(undefined);
  };

  const handleFinish = useCallback((response: ActivityFinishResponse) => {
    if (response.isChallengeFinished) {
      setFinishedChallengeSlug(response.challengeSlug);
    }
  }, []);

  const shouldShowFinish = useMemo(() => {
    if (isRoadmapActivity(activity)) {
      return isRoadmapFinalStep(activity);
    }

    return true;
  }, [activity]);

  const lastStepId = useMemo(() => {
    if (isRoadmapActivity(activity)) {
      const roadmap = getActivityRoadmap(activity);

      return [...roadmap].splice(-1)[0].id;
    }
  }, [activity]);

  return (
    <div
      className={cn(styles.ActivityFooter, {
        [styles.hasAuthor]: !!activity.author?.[0],
        [styles.hasFinish]: shouldShowFinish
      })}
    >
      <div className={styles.Author}>
        <AuthorActivity activity={activity} />
      </div>

      {shouldShowFinish && (
        <div
          className={cn(styles.Completion, {
            [styles.isCompleted]: activity.completedAt
          })}
        >
          {!!activity.completedAt ? (
            <TagText
              tagProps={{
                color: color.success.default,
                icon: 'check',
                size: 'xs'
              }}
              label={t('DOMAIN.ACTIVITY.FINISHED_AT', {
                finishDate: shortDate(new Date(activity.completedAt))
              })}
            />
          ) : (
            <div>
              <ActivityFinish
                activity={activity}
                onFinish={handleFinish}
                className={styles.Finish}
                stepId={lastStepId}
              />
            </div>
          )}
        </div>
      )}

      <ChallengeFinishedModal
        slug={finishedChallengeSlug}
        onClose={handleCloseChallengeFinishedModal}
        isOpen={!!finishedChallengeSlug}
      />
    </div>
  );
};
