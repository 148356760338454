import cn from 'classnames';
import { useMemo } from 'react';

import { Text } from '@Components/ui';
import { useBoolean, usePathUtils, usePlatform, usePlayInView } from '@Hooks/common';

import { STROKE_WIDTH, VIEWBOX_HEIGHT, VIEWBOX_WIDTH } from './internal/constants';
import { Path } from './internal/index';
import styles from './ProgressCircle.module.scss';
import { ProgressCircleProps } from './ProgressCircle.props';

export const ProgressCircle = ({
  total,
  values,
  label,
  subLabel,
  className
}: ProgressCircleProps) => {
  const animationDelay = 500;

  const show = useBoolean(false);
  const platform = usePlatform();
  const { getRatio, getPathRadius } = usePathUtils();

  const [inViewRef] = usePlayInView(show.setTrue);

  const paths = useMemo(() => {
    return values?.map(({ value, color }, idx) => {
      const delay = animationDelay * (idx + 1);

      return (
        <Path
          key={value}
          dashRatio={show.value ? getRatio(value, total) : 0}
          strokeWidth={STROKE_WIDTH}
          pathRadius={getPathRadius(VIEWBOX_HEIGHT, STROKE_WIDTH)}
          color={color}
          style={{ transitionDelay: `${delay}ms` }}
        />
      );
    });
  }, [values, getRatio, show, total, getPathRadius]);

  return (
    <div className={cn(styles.ProgressCircleWrapper, className)}>
      <svg
        ref={inViewRef}
        className={cn(styles.ProgressCircle)}
        viewBox={`0 0 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`}
      >
        <Path
          className={styles.Trail}
          dashRatio={show.value ? 1 : 0}
          strokeWidth={2}
          pathRadius={getPathRadius(VIEWBOX_HEIGHT, STROKE_WIDTH)}
          color={platform.color.gray.light}
        />

        {paths}
      </svg>

      {(!!label || !!subLabel) && (
        <div
          className={cn(styles.LabelWrapper, {
            [styles.isVisible]: show.value
          })}
          style={{ transitionDelay: `${animationDelay * (values.length + 1)}ms` }}
        >
          {!!label && <Text.H3 className={styles.Label}>{label}</Text.H3>}

          {!!subLabel && <Text.Micro className={styles.SubLabel}>{`/${subLabel}`}</Text.Micro>}
        </div>
      )}
    </div>
  );
};
