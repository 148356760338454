import { useCallback, useMemo, useRef } from 'react';

import { Result } from '@Constants/interfaces';
import { usePlatform } from '@Hooks/common';

export const useResultColorMap = () => {
  const { color } = usePlatform();

  const resultColorMap = useRef({
    [Result.RED]: color.red.default,
    [Result.ORANGE]: color.orange.default,
    [Result.YELLOW]: color.yellow.default,
    [Result.GREEN]: color.green.default,
    [Result.NONE]: color.gray.default
  });

  const getColorForResult = useCallback(
    (result?: Result | null) => {
      if (!result) {
        return resultColorMap.current.NONE;
      }

      return resultColorMap.current[result];
    },
    [resultColorMap]
  );

  return useMemo(() => {
    return { getColorForResult, resultColorMap: resultColorMap.current };
  }, [getColorForResult]);
};
