import cn from 'classnames';
import { CSSProperties } from 'react';

import styles from './Image.module.scss';
import { ImageProps } from './Image.props';

export const Image = ({
  ratio = '16:9',
  position = 'center top',
  objectFit = 'cover',
  isElevated,
  style,
  className,
  ...props
}: ImageProps) => {
  if (ratio === 'none') {
    return <img {...props} />;
  }

  const classNames = cn(styles.Image, className, {
    [styles.hasRatio]: ratio !== 'fill',
    [styles.isRatio1x1]: ratio === '1:1',
    [styles.isRatio16x9]: ratio === '16:9',
    [styles.isRatio4x3]: ratio === '4:3',
    [styles.isRatio3x2]: ratio === '3:2',
    [styles.isRatio8x5]: ratio === '8:5',
    [styles.isRatio2x1]: ratio === '2:1',
    [styles.isFill]: ratio === 'fill',
    [styles.isElevated]: isElevated
  });

  const imgStyle: CSSProperties = {
    objectPosition: position,
    objectFit
  };

  return (
    <div className={classNames}>
      <img {...props} style={{ ...style, ...imgStyle }} />
    </div>
  );
};
