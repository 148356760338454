import cn from 'classnames';

import { useBoolean, usePlayInView } from '@Hooks/common';

import styles from './ProgressBar.module.scss';
import { ProgressBarProps } from './ProgressBar.props';

export const ProgressBar = ({
  total,
  current,
  color,
  isRounded = true,
  isFilled = false,
  width,
  className
}: ProgressBarProps) => {
  const { value: shouldShowProgress, setTrue: setShowProgress } = useBoolean(false);
  const progress = current / total;
  let percentage = '0%';

  if (!isNaN(progress) && shouldShowProgress) {
    percentage = `${(progress > 1 ? 1 : progress) * 100}%`;
  }

  const [inViewRef] = usePlayInView(setShowProgress);

  return (
    <div
      ref={inViewRef}
      className={cn(styles.ProgressBar, className, {
        [styles.isNotRounded]: !isRounded
      })}
      style={{ width }}
    >
      <div className={styles.Progress} style={{ width: percentage, background: color }} />

      <div className={cn(styles.Track, { [styles.isFilled]: isFilled })} />
    </div>
  );
};
