import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Block, ButtonIcon, Image, Spacer, Text } from '@Components/ui';
import { markupToHtml } from '@Utils/StringUtils';

import styles from './PopupMessage.module.scss';
import { PopupMessageProps } from './PopupMessage.props';

export const PopupMessage = ({
  avatar,
  name,
  children,
  message,
  isOpen = false,
  onClose,
  onToggle,
  className
}: PopupMessageProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(styles.PopupMessage, className, {
        [styles.isOpen]: isOpen
      })}
    >
      <Block isElevated className={styles.Block}>
        <ButtonIcon onClick={onClose} icon="minus" label={t('COMMON.GLOBALS.MINIMIZE')} />

        <Spacer top={1} bottom={2} />

        <Text.Small className={styles.Name}>{name}</Text.Small>

        <Text.Normal dangerousHtml={markupToHtml(message)} />

        <div className={styles.Children}>{children}</div>
      </Block>

      <div className={styles.ImageContainer}>
        <Image onClick={onToggle} src={avatar} alt={name} ratio="1:1" className={styles.Image} />

        <div className={styles.Notification} />
      </div>
    </div>
  );
};
