import cn from 'classnames';

import styles from './Intro.module.scss';
import { IntroProps } from './Intro.props';

export const Intro = ({ className, children, dangerousHtml, color, ...props }: IntroProps) => {
  if (!children && !dangerousHtml) return null;

  const classNames = cn(styles.Intro, className);

  const inlineStyle = {
    color
  };

  return (
    <p
      className={classNames}
      dangerouslySetInnerHTML={!!dangerousHtml ? { __html: dangerousHtml } : undefined}
      style={inlineStyle}
      {...props}
    >
      {children}
    </p>
  );
};
