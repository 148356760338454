import { ListFaqItem } from '@Components/list';

import { NoResultsFound } from '../NoResultsFound';
import { CategoryTabProps } from './CategoryTab.props';

export const CategoryTab = ({ searchString, category }: CategoryTabProps) => {
  const hasItems = category.items.length > 0;

  return (
    <>
      {hasItems ? (
        <ListFaqItem searchString={searchString} category={category} />
      ) : (
        <NoResultsFound />
      )}
    </>
  );
};
