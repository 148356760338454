import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Anchor, Button, Form, SpacingGroup } from '@Components/ui';
import { useAuthForgotPassword } from '@Hooks/auth';
import { useApiError } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';
import Yup from '@Utils/YupUtils';

import {
  AuthForgotPasswordFormProps,
  AuthForgotPasswordFormValues
} from './AuthForgotPasswordForm.props';

export const AuthForgotPasswordForm = ({ onSuccess, className }: AuthForgotPasswordFormProps) => {
  const { t } = useTranslation();
  const authForgotPasswordMutation = useAuthForgotPassword();
  const { handleError } = useApiError();

  const handleSubmit = useCallback(
    async (values: AuthForgotPasswordFormValues) => {
      try {
        await authForgotPasswordMutation.mutateAsync(values);

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        handleError(e, 'AUTHENTICATION');
      }
    },
    [authForgotPasswordMutation, handleError, onSuccess]
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required()
  });

  return (
    <Form.Form onSubmit={handleSubmit} className={className} validationSchema={validationSchema}>
      <Form.Input.Text
        name="email"
        data-testid="emailInput"
        label={t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.EMAIL.FORM.LABELS.EMAIL')}
      />

      <SpacingGroup size="xxl">
        <Button icon="arrow-right" type="submit" data-testid="forgotPasswordSubmit">
          {t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.EMAIL.FORM.SUBMIT')}
        </Button>

        <Anchor to={AppRoutes.getLogin().to}>
          {t('DOMAIN.AUTHENTICATION.FORGOT_PASSWORD.EMAIL.FORM.CANCEL')}
        </Anchor>
      </SpacingGroup>
    </Form.Form>
  );
};
