import { View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { Result } from '@Constants/interfaces';
import { PdfText } from '@Documents/components';

import { ThemeProgress } from '../ThemeProgress/ThemeProgress';
import { ProgressLegendProps } from './ProgressLegend.props';
import { styles } from './ProgressLegend.styles';

export const ProgressLegend = ({ style }: ProgressLegendProps) => {
  const { t } = useTranslation();

  return (
    <View style={style}>
      <View style={styles.LegendItem}>
        <ThemeProgress themeResult={Result.GREEN} />

        <PdfText.Small style={styles.LegendLabel}>{t('DOMAIN.THEME.RESULTS.GREEN')}</PdfText.Small>
      </View>

      <View style={styles.LegendItem}>
        <ThemeProgress themeResult={Result.YELLOW} />

        <PdfText.Small style={styles.LegendLabel}>{t('DOMAIN.THEME.RESULTS.YELLOW')}</PdfText.Small>
      </View>

      <View style={styles.LegendItem}>
        <ThemeProgress themeResult={Result.ORANGE} />

        <PdfText.Small style={styles.LegendLabel}>{t('DOMAIN.THEME.RESULTS.ORANGE')}</PdfText.Small>
      </View>

      <View style={styles.LegendItem}>
        <ThemeProgress themeResult={Result.RED} />

        <PdfText.Small style={styles.LegendLabel}>{t('DOMAIN.THEME.RESULTS.RED')}</PdfText.Small>
      </View>
    </View>
  );
};
