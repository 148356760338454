import cn from 'classnames';

import { PreprRenderer } from '@Components/layout';
import { AccordionItem } from '@Components/ui';

import styles from './FaqItem.module.scss';
import { FaqItemProps } from './FaqItem.props';
import { getHighlightSelectedText } from './internal/utils';

export const FaqItem = ({ item, highlightedText, className, ...props }: FaqItemProps) => {
  return (
    <AccordionItem
      title={highlightedText ? getHighlightSelectedText(item.title, highlightedText) : item.title}
      {...props}
      className={cn(styles.FaqItem, className)}
    >
      <PreprRenderer content={item.content} />

      {
        // TODO: REMOVED UNTIL WE START HIGHLIGHTING ON CONTENT
        /* {highlightedText ? (
        <div
          dangerouslySetInnerHTML={{
            __html: getHighlightSelectedText(item.description, highlightedText)
          }}
        />
      ) : (
        <PreprRenderer content={item.content} />
      )} */
      }
    </AccordionItem>
  );
};
