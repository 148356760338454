import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Questionnaire } from '@Components/container';
import { ButtonPause, Modal } from '@Components/layout';
import { ListCheckItem } from '@Components/list';
import { Box, Button, Loader, ProgressFixed, Text } from '@Components/ui';
import { MAX_PERCENT } from '@Constants/configs';
import { useBoolean } from '@Hooks/common';
import {
  useOnboardingGetSelfTestQuestionnaire,
  useOnboardingSkipSelfTest
} from '@Hooks/onboarding';
import { AppRoutes } from '@Navigation/Routes';
import { onboardingService } from '@Services/Onboarding';
import { QuestionnaireSubmissionSubmitSelfTestSkipType } from '@Services/QuestionnaireSubmission';

import {
  QuestionnaireSelfTestOutroSlide,
  QuestionnaireSelfTestPhasedIntroSlide,
  QuestionnaireSelfTestPhaseOutroSlide,
  QuestionnaireSelfTestRegularIntroSlide
} from './internal/components';
import { getPaginationNumbers, getThemeList } from './internal/utils';
import { QuestionnaireSelfTestProps } from './QuestionnaireSelfTest.props';

export const QuestionnaireSelfTest = ({ className, onFinish }: QuestionnaireSelfTestProps) => {
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const paginationPrefix = t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PAGINATION_PREFIX');
  const useOnboardingSkipSelfTestMutation = useOnboardingSkipSelfTest();

  const {
    data: selfTestQuestionnaire,
    isLoading: isLoadingSelfTestQuestionnaire,
    isError: isErrorSelfTestQuestionnaire
  } = useOnboardingGetSelfTestQuestionnaire();

  const handlePause = useCallback(async () => {
    if (selfTestQuestionnaire) {
      selfTestQuestionnaire.skipType = QuestionnaireSubmissionSubmitSelfTestSkipType.Paused;
      await useOnboardingSkipSelfTestMutation.mutateAsync(selfTestQuestionnaire);
    }

    navigate(AppRoutes.getDashboard().to);
  }, [useOnboardingSkipSelfTestMutation, selfTestQuestionnaire, navigate]);

  const themeList = useMemo(() => {
    return getThemeList(selfTestQuestionnaire?.themes ?? []);
  }, [selfTestQuestionnaire]);

  const { totalSlides, currentSlideIndex } = useMemo(() => {
    return getPaginationNumbers(selfTestQuestionnaire?.themes ?? []);
  }, [selfTestQuestionnaire]);

  const handleStepFinish = useCallback(() => {
    queryClient.invalidateQueries([onboardingService.getEndpoint(), 'selftest', 'questionnaire']);
  }, [queryClient]);

  if (isLoadingSelfTestQuestionnaire) {
    return <Loader>{t('DOMAIN.SELF_TEST_QUESTIONNAIRE.IS_LOADING')}</Loader>;
  }

  if (isErrorSelfTestQuestionnaire) {
    return <Text.Error>{t('DOMAIN.SELF_TEST_QUESTIONNAIRE.COULD_NOT_FETCH')}</Text.Error>;
  }

  if (selfTestQuestionnaire) {
    const hasFuturePhases =
      !!selfTestQuestionnaire.futurePhases && selfTestQuestionnaire.futurePhases.length > 0;

    return (
      <>
        <ButtonPause onClick={handlePause} />

        <ProgressFixed current={selfTestQuestionnaire.progress} total={MAX_PERCENT} />

        <Questionnaire
          className={className}
          initialSubmission={selfTestQuestionnaire}
          introSlide={
            selfTestQuestionnaire.isPhased ? (
              <QuestionnaireSelfTestPhasedIntroSlide
                totalSlides={totalSlides}
                currentSlideIndex={currentSlideIndex}
                onInfoClick={setModalOpen}
                paginationPrefix={paginationPrefix}
              />
            ) : (
              <QuestionnaireSelfTestRegularIntroSlide
                totalSlides={totalSlides}
                currentSlideIndex={currentSlideIndex}
                onInfoClick={setModalOpen}
                paginationPrefix={paginationPrefix}
              />
            )
          }
          outroSlide={
            <QuestionnaireSelfTestOutroSlide
              totalSlides={totalSlides}
              currentSlideIndex={currentSlideIndex}
              onInfoClick={setModalOpen}
              paginationPrefix={paginationPrefix}
            />
          }
          phaseOutroSlide={
            <QuestionnaireSelfTestPhaseOutroSlide
              totalSlides={totalSlides}
              currentSlideIndex={currentSlideIndex}
              onInfoClick={setModalOpen}
              paginationPrefix={paginationPrefix}
              hasFuturePhases={hasFuturePhases}
            />
          }
          onInfoClick={setModalOpen}
          onStepFinish={handleStepFinish}
          onFinish={onFinish}
          totalSlides={totalSlides}
          currentSlideIndex={currentSlideIndex}
          paginationPrefix={paginationPrefix}
        />

        <Modal
          title={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PROGRESS_MODAL.TITLE')}
          isOpen={isModalOpen}
          onClose={setModalClosed}
        >
          <Text.Large>{t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PROGRESS_MODAL.DESCRIPTION')}</Text.Large>

          <Box mt={3} mb={3}>
            <ListCheckItem items={themeList} />
          </Box>

          <Button onClick={setModalClosed} color="dark">
            {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.PROGRESS_MODAL.CONTINUE')}
          </Button>
        </Modal>
      </>
    );
  }

  return null;
};
