import { MAX_PERCENT } from '@Constants/configs';
import { Result } from '@Constants/interfaces';
import { DomainScore } from '@Services/User';
import { getPercentageForResult } from '@Utils/ResultUtils';

export const domainScoresToScores = (
  domainScores: DomainScore[],
  noScoreColor: string,
  getColorForResult: (result: Result) => string
) => {
  return domainScores.map((score) => {
    return {
      name: score.domain.title,
      color: (score.result && getColorForResult(score.result)) || noScoreColor,
      total: MAX_PERCENT,
      current: getPercentageForResult(score.result)
    };
  });
};
