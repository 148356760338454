import cn from 'classnames';

import { usePathUtils } from '@Hooks/common';

import { VIEWBOX_CENTER_X, VIEWBOX_CENTER_Y, VIEWBOX_HEIGHT } from '../constants';
import styles from './Path.module.scss';
import { PathProps } from './Path.props';

// SVG path description specifies how the path should be drawn
const getPathDescription = (pathRadius: number, counterClockwise: boolean) => {
  const radius = pathRadius;
  const rotation = counterClockwise ? 1 : 0;

  // Move to center of canvas
  // Relative move to top canvas
  // Relative arc to bottom of canvas
  // Relative arc to top of canvas
  return `
      M ${VIEWBOX_CENTER_X},${VIEWBOX_CENTER_Y}
      m 0,-${radius}
      a ${radius},${radius} ${rotation} 1 1 0,${2 * radius}
      a ${radius},${radius} ${rotation} 1 1 0,-${2 * radius}
    `;
};

const getDashStyle = (counterClockwise: boolean, dashRatio: number, pathRadius: number) => {
  const diameter = Math.PI * 2 * pathRadius;
  const gapLength = (1 - dashRatio) * diameter;

  return {
    // Have dash be full diameter, and gap be full diameter
    strokeDasharray: `${diameter}px ${diameter}px`,
    // Shift dash backward by gapLength, so gap starts appearing at correct distance
    strokeDashoffset: `${counterClockwise ? -gapLength : gapLength}px`
  };
};

export const Path = ({
  className,
  isCounterClockwise = false,
  dashRatio,
  strokeWidth,
  pathRadius,
  color,
  style = {}
}: PathProps) => {
  const { getPathRadius } = usePathUtils();

  const correctPathRadius = pathRadius ?? getPathRadius(VIEWBOX_HEIGHT, strokeWidth);

  return (
    <path
      className={cn(styles.Path, className)}
      style={{
        ...style,
        ...getDashStyle(isCounterClockwise, dashRatio, correctPathRadius)
      }}
      d={getPathDescription(correctPathRadius, isCounterClockwise)}
      strokeWidth={strokeWidth}
      fillOpacity={0}
      stroke={color}
    />
  );
};
