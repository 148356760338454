import cn from 'classnames';

import { ProgressChallenge, Text } from '@Components/ui';

import styles from './DashboardIntroText.module.scss';
import { DashboardIntroTextProps } from './DashboardIntroText.props';

export const DashboardIntroText = ({
  title,
  subtitle,
  total,
  current,
  hasCallToAction,
  className
}: DashboardIntroTextProps) => {
  return (
    <div
      className={cn(styles.DashboardIntroText, className, {
        [styles.hasCallToAction]: hasCallToAction
      })}
    >
      <Text.H1 className={styles.Title}>{title}</Text.H1>

      <Text.H2 className={styles.Subtitle}>{subtitle}</Text.H2>

      <ProgressChallenge
        current={current}
        total={total}
        hasCallToAction={hasCallToAction}
        className={styles.Progressbar}
      />
    </div>
  );
};
