import cn from 'classnames';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';

import userActivityThumbnail from '@Assets/images/custom-activity-thumbnail.jpg';
import { Anchor, Box, Card, Tag, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';
import { isConfigured } from '@Utils/ActivityUtils';

import styles from './CardUserActivity.module.scss';
import { CardUserActivityProps } from './CardUserActivity.props';

export const CardUserActivity = ({ userActivity, className }: CardUserActivityProps) => {
  const { color } = usePlatform();
  const { t } = useTranslation();

  return (
    <Card
      image={userActivityThumbnail}
      className={cn(styles.CardUserActivity, className)}
      to={AppRoutes.getTodoUserActivity(userActivity.id).to}
    >
      <Box mb={1}>
        <Text.H4>{t('DOMAIN.USER_ACTIVITY.TITLE', { title: userActivity.title })}</Text.H4>
      </Box>

      <Tag label={t('DOMAIN.USER_ACTIVITY.SINGULAR')} color={color.black.default} />

      {isConfigured(userActivity) ? (
        <>
          <Box mt={1}>
            <Text.Normal>{truncate(userActivity.description, { length: 120 })}</Text.Normal>
          </Box>

          <Box mt={1.5}>
            <Anchor to={AppRoutes.getTodoUserActivity(userActivity.id).to}>
              {t('DOMAIN.USER_ACTIVITY.CARD.SHOW_ACTIVITY')}
            </Anchor>
          </Box>
        </>
      ) : (
        <Box mt={1.5}>
          <Anchor to={AppRoutes.getTodoUserActivityUpdate(userActivity.id).to}>
            {t('DOMAIN.USER_ACTIVITY.CARD.FINISH_ACTIVITY')}
          </Anchor>
        </Box>
      )}
    </Card>
  );
};
