import { MutableRefObject, useEffect } from 'react';

export const useAutoScroll = (scrollRef: MutableRefObject<HTMLElement | null>, offset: number) => {
  useEffect(() => {
    const buttonGroup = scrollRef.current;

    if (buttonGroup && buttonGroup.scroll) {
      buttonGroup.scroll({
        left: offset,
        behavior: 'smooth'
      });
    }
  }, [offset, scrollRef]);
};
