import { useRef } from 'react';

import { getPlatform } from '@Utils/PlatformUtils';
import { getTheme } from '@Utils/PlatformUtils';

export const usePlatform = () => {
  const theme = useRef(getTheme());
  const platform = useRef(getPlatform());

  return {
    ...theme.current,
    platform: platform.current
  };
};
