import { HTMLMeasurements } from '@Constants/interfaces';

import { MILESTONES_INITIAL_HEIGHT } from './constants';

export const getCurrentHeight = (
  measurements: HTMLMeasurements[],
  elementsPerRow: number,
  currentRow: number
) => {
  const firstElementInCurrentRow = measurements[elementsPerRow * (currentRow - 1)];
  const zeroPoint = measurements[0]?.location.y;

  if (firstElementInCurrentRow) {
    const height =
      firstElementInCurrentRow.location.y + firstElementInCurrentRow.scrollHeight - zeroPoint;

    return height > MILESTONES_INITIAL_HEIGHT ? height : MILESTONES_INITIAL_HEIGHT;
  }

  return MILESTONES_INITIAL_HEIGHT;
};
