import { IBaseModel } from '@Constants/interfaces';
import { BLOCKS } from '@Locales/nl/domain/DASHBOARD.json';

export interface IUserTooltip extends IBaseModel {
  id: string;
  widget: keyof typeof BLOCKS;
  content: string;
  buttonText: string;
  buttonLink: UserMotivatorButtonLink;
}

export type UserTooltipGetAllResponse = IUserTooltip[];

export interface UserTooltipHideRequest {
  id: string;
  title: string;
  opened: boolean;
}

export enum UserMotivatorButtonLink {
  ACTIVITIES = 'ACTIVITIES',
  PROGRESS = 'PROGRESS',
  START_CHALLENGE = 'START_CHALLENGE',
  TODO = 'TODO',
  REWARDS = 'REWARDS',
  DIGITAL_TOUR = 'DIGITAL_TOUR'
}
