import { DOTS } from '@Locales/nl/components/TOURDOT.json';

export interface TourDotConfig {
  alignment: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
}

export const TourDotsConfig: { [key in keyof typeof DOTS]: TourDotConfig } = {
  FAQ_TOURDOT_ID: {
    alignment: {}
  },
  ACTIVITIES: {
    alignment: {
      right: -24
    }
  },
  CHALLENGES: {
    alignment: {
      right: -24
    }
  },
  STATISTIC: {
    alignment: {
      right: -24
    }
  },
  MILESTONES: {
    alignment: {
      right: -24
    }
  },
  TODO: {
    alignment: {
      right: -24
    }
  },
  POINTS: {
    alignment: {
      right: -24
    }
  },
  PROGRESS: {
    alignment: {
      right: -24
    }
  },
  FAQ: {
    alignment: {
      right: 36,
      top: 'calc(50% - 16px)'
    }
  },
  PROFILE: {
    alignment: {
      left: -24,
      top: 1
    }
  },
  MENU_MY_BOARD: {
    alignment: {
      right: -8,
      top: 0
    }
  },
  MENU_REWARD: {
    alignment: {
      right: -8,
      top: 0
    }
  },
  MENU_INSIGHT_REPORT: {
    alignment: {
      right: -32,
      top: -18
    }
  },
  PROFILE_STATUS_REGULAR: {
    alignment: {
      right: -24,
      top: 0
    }
  }
};
