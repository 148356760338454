import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Modal } from '@Components/layout';
import { Box, Button, Form, InputField, InputText, Text } from '@Components/ui';
import { useApiError, useBoolean } from '@Hooks/common';
import { useOnboardingUpdateEmail } from '@Hooks/onboarding';
import { onboardingService } from '@Services/Onboarding';
import Yup, { AsyncValidationCall, getAsyncValidation } from '@Utils/YupUtils';

import {
  OnboardingUpdateEmailFormProps,
  OnboardingUpdateEmailFormValues
} from './OnboardingUpdateEmailForm.props';

export const OnboardingUpdateEmailForm = ({
  onSuccess,
  licenseCode,
  userId,
  email
}: OnboardingUpdateEmailFormProps) => {
  const { t } = useTranslation();
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);
  const { handleError } = useApiError();
  const onboardingUpdateEmailMutation = useOnboardingUpdateEmail();

  const handleVerifyEmail = useCallback(
    async (value: string): Promise<AsyncValidationCall> => {
      if (value === email) {
        return { isValid: true };
      }

      const response = await onboardingService.validateEmail({
        licenseCode: licenseCode,
        email: value
      });

      return response;
    },
    [licenseCode, email]
  );

  const handleSubmit = useCallback(
    async (values: OnboardingUpdateEmailFormValues) => {
      try {
        if (values.email !== email) {
          await onboardingUpdateEmailMutation.mutateAsync({
            userId: userId,
            ...values
          });
        }

        toast.success(t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_EMAIL.FORM.SUCCESS'));

        onSuccess(values.email);
        setModalClosed();
      } catch (e) {
        handleError(e);
      }
    },
    [email, t, onSuccess, handleError, setModalClosed, onboardingUpdateEmailMutation, userId]
  );

  const validationSchema = Yup.object().shape({
    email: getAsyncValidation(handleVerifyEmail, {
      schema: Yup.string().email().required()
    })
  });

  return (
    <>
      <InputField
        name="placeholder"
        onEditClick={setModalOpen}
        label={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_EMAIL.FORM.FIELDS.EMAIL.LABEL')}
        data-testid="changeEmailInput"
      >
        <InputText value={email} disabled />
      </InputField>

      <Modal
        isOpen={isModalOpen}
        title={t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_EMAIL.POPUP.TITLE')}
        onClose={setModalClosed}
      >
        <Text.Large
          dangerousHtml={t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_EMAIL.POPUP.DESCRIPTION', {
            email: email
          })}
        />

        <Box mt={3}>
          <Form.Form onSubmit={handleSubmit} validationSchema={validationSchema}>
            <Form.Input.Text
              name="email"
              data-testid="updateEmailInput"
              label={t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_EMAIL.FORM.FIELDS.EMAIL.LABEL')}
            />

            <Button type="submit" color="dark" data-testid="updateEmailSubmit">
              {t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_EMAIL.FORM.SUBMIT')}
            </Button>
          </Form.Form>
        </Box>
      </Modal>
    </>
  );
};
