import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container, PreprRenderer } from '@Components/layout';
import { Block, Box, Loader } from '@Components/ui';
import { useContentGetPage } from '@Hooks/content';
import { AppRoutes } from '@Navigation/Routes';

import { ContentPageHeader } from './internal/components';

export const AppContentPageScreen = () => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  const {
    data: contentPage,
    isLoading: isContentPageLoading,
    isError: isContentPageError
  } = useContentGetPage(slug || '');

  useEffect(() => {
    if (isContentPageError) {
      toast.error(t('DOMAIN.CONTENTPAGE.ERROR'));

      navigate(AppRoutes.getDashboard().to);
    }
  }, [isContentPageError, navigate, t]);

  return (
    <Container>
      {isContentPageLoading && (
        <Box my={4}>
          <Loader>
            {t('COMMON.GLOBALS.LOADING_MODEL', { name: t('DOMAIN.CONTENTPAGE.SINGLE') })}
          </Loader>
        </Box>
      )}

      {contentPage && (
        <>
          <ContentPageHeader contentPage={contentPage} />

          <Block>
            <PreprRenderer content={contentPage.content} />
          </Block>
        </>
      )}
    </Container>
  );
};
