import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ChallengeAddScreen,
  ChallengeItemScreen,
  ChallengeOverviewScreen,
  ChallengeStartScreen
} from '@Screens/Challenge';

import { AppRoutes } from './Routes';

export const ChallengeStack = () => {
  return (
    <Routes>
      <Route path="" element={<ChallengeOverviewScreen />} />

      <Route path={AppRoutes.getInsightChallengesAdd().path} element={<ChallengeAddScreen />} />

      <Route
        path={AppRoutes.getInsightChallengesStart('').path}
        element={<ChallengeStartScreen />}
      />

      <Route path={AppRoutes.getInsightChallenge('').path} element={<ChallengeItemScreen />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
