import { BaseService, dePortalenService } from '@Services/common';

import { UserTooltipGetAllResponse, UserTooltipHideRequest } from './UserTooltipService.props';

export class UserTooltipService extends BaseService {
  getAll = async () => {
    const response = await this.get<UserTooltipGetAllResponse>();

    return response.data;
  };

  hide = async ({ id, ...config }: UserTooltipHideRequest) => {
    await this.post<void>(`/${id}/hide`, config);
  };
}

export const userTooltipService = new UserTooltipService(
  dePortalenService.getAxiosInstance(),
  '/usertooltips'
);
