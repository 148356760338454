import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PreferencesSupportTypeForm, PreferencesSupportTypeFormValues } from '@Components/form';
import {
  Box,
  FormMethods,
  SliderFullPage,
  SliderNavigation,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';

export const PreferencesSupportTypesSlide = () => {
  const formRef = useRef<FormMethods<PreferencesSupportTypeFormValues>>();
  const { index } = useSliderFullPageItemContext();

  const { total } = useSliderFullPageContext();
  const { slideNext, slidePrev } = useSliderFullPageItemContext();

  const { t } = useTranslation();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        title={t('DOMAIN.PREFERENCES.SUPPORT_TYPES.TITLE')}
        intro={t('DOMAIN.PREFERENCES.SUPPORT_TYPES.DESCRIPTION')}
        superTitle={t('DOMAIN.PREFERENCES.TITLE')}
      />

      <SliderFullPage.Block title={t('DOMAIN.PREFERENCES.SUPPORT_TYPES.FORM.TITLE')}>
        <Box pt={2.5}>
          <PreferencesSupportTypeForm ref={formRef} onSuccess={slideNext}>
            <Box mt={11}>
              <SliderNavigation
                nextLabel={t('DOMAIN.PREFERENCES.NEXT_BUTTON')}
                onPrevious={slidePrev}
                total={total}
                current={index + 1}
              />
            </Box>
          </PreferencesSupportTypeForm>
        </Box>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
