import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@Components/layout';
import { AccordionItemBase, Box, Button, Text } from '@Components/ui';
import { useBoolean, usePlatform } from '@Hooks/common';

import { AccordionStepsItemProps, AccordionStepsItemState } from './AccordionStepsItem.props';

export const AccordionStepsItem = ({
  children,
  state,
  title,
  onToggle,
  isOpen,
  popup,
  tooltipText,
  ...props
}: AccordionStepsItemProps) => {
  const { color } = usePlatform();
  const isPopupVisible = useBoolean(false);
  const { t } = useTranslation();

  const getBackgroundColor = () => {
    const backgroundColor = {
      [AccordionStepsItemState.DEFAULT]: undefined,
      [AccordionStepsItemState.DISABLED]: undefined,
      [AccordionStepsItemState.SUCCESS]: color.success.light
    }[state];

    return backgroundColor;
  };

  const getBorderColor = () => {
    const borderColor = {
      [AccordionStepsItemState.DEFAULT]: undefined,
      [AccordionStepsItemState.DISABLED]: undefined,
      [AccordionStepsItemState.SUCCESS]: color.success.default
    }[state];

    return borderColor;
  };

  const getIconName = () => {
    if (state === AccordionStepsItemState.DISABLED) {
      return 'lock';
    }

    if (state === AccordionStepsItemState.SUCCESS) {
      return 'check';
    }

    return isOpen ? 'minus' : 'plus';
  };

  const getIconBackgroundColor = () => {
    const iconBackgroundColor = {
      [AccordionStepsItemState.DEFAULT]: undefined,
      [AccordionStepsItemState.DISABLED]: undefined,
      [AccordionStepsItemState.SUCCESS]: color.success.default
    }[state];

    return iconBackgroundColor;
  };

  const getIconColor = () => {
    const iconBackgroundColor = {
      [AccordionStepsItemState.DEFAULT]: undefined,
      [AccordionStepsItemState.DISABLED]: undefined,
      [AccordionStepsItemState.SUCCESS]: color.frame.default
    }[state];

    return iconBackgroundColor;
  };

  const isDisabled = state === AccordionStepsItemState.DISABLED;

  const handleToggle = useCallback(() => {
    if (state === AccordionStepsItemState.DISABLED && popup) {
      isPopupVisible.setTrue();
    }

    if (!isDisabled && onToggle) {
      onToggle();
    }
  }, [onToggle, isDisabled, isPopupVisible, state, popup]);

  return (
    <AccordionItemBase
      title={title}
      onToggle={handleToggle}
      backgroundColor={getBackgroundColor()}
      borderColor={getBorderColor()}
      isOpen={isOpen}
      tooltipText={tooltipText}
      iconName={getIconName()}
      iconBackgroundColor={getIconBackgroundColor()}
      iconColor={getIconColor()}
      {...props}
    >
      {children}

      {popup && (
        <Modal
          isOpen={isPopupVisible.value}
          onClose={isPopupVisible.setFalse}
          title={popup.title}
          data-testid="accordionStepsItemModal"
        >
          <Box mb={3}>
            <Text.Normal>{popup.body}</Text.Normal>
          </Box>

          <Button color="dark" onClick={isPopupVisible.toggle}>
            {t('DOMAIN.ACTIVITY.ROADMAP.PREVIEW_MODAL_BUTTON')}
          </Button>
        </Modal>
      )}
    </AccordionItemBase>
  );
};
