import cn from 'classnames';
import { forwardRef } from 'react';

import { Block, Text } from '@Components/ui';

import { useSliderFullPageItemContext } from '../SliderFullPageItem/context';
import styles from './SliderFullPageBlock.module.scss';
import { SliderFullPageBlockProps } from './SliderFullPageBlock.props';

export const SliderFullPageBlock = forwardRef(
  ({ title, isLarge, children }: SliderFullPageBlockProps, ref: any) => {
    const { isActive } = useSliderFullPageItemContext();

    return (
      <Block
        isElevated
        className={cn(styles.Block, { [styles.isActive]: isActive, [styles.isLarge]: isLarge })}
        ref={ref}
      >
        {title && <Text.Large className={styles.Title}>{title}</Text.Large>}

        {children}
      </Block>
    );
  }
);
