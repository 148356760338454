import cn from 'classnames';
import { forwardRef } from 'react';

import { Button, ButtonIcon, PageNumber } from '@Components/ui';
import { exists } from '@Utils/CommonUtils';

import styles from './SliderNavigation.module.scss';
import { SliderNavigationProps } from './SliderNavigation.props';

export const SliderNavigation = forwardRef(
  (
    {
      current,
      total,
      onPrevious,
      onNext,
      onInfoClick,
      isDisabled,
      nextLabel,
      isLoadingNext,
      isLoadingPrevious,
      className,
      paginationPrefix
    }: SliderNavigationProps,
    ref: any
  ) => {
    return (
      <div ref={ref} className={cn(styles.SliderNavigation, className)}>
        <Button
          isReversed
          isDisabled={isDisabled || !onPrevious || isLoadingNext}
          onClick={onPrevious}
          color="light"
          type="button"
          icon="arrow-left"
          isLoading={isLoadingPrevious}
          data-testid="prevButton"
        />

        {exists<number>(total) && exists<number>(current) && (
          <PageNumber
            className={styles.PageNumber}
            current={current}
            total={total}
            label={paginationPrefix}
          />
        )}

        {!!onInfoClick && (
          <ButtonIcon icon="info" onClick={onInfoClick} className={styles.InfoButton} />
        )}

        {(!!nextLabel || !!onNext) && (
          <Button
            type={!!onNext ? 'button' : 'submit'}
            isDisabled={isDisabled || isLoadingPrevious}
            onClick={onNext}
            icon="arrow-right"
            isLoading={isLoadingNext}
            data-testid="nextButton"
            className={styles.NextButton}
          >
            {nextLabel}
          </Button>
        )}
      </div>
    );
  }
);
