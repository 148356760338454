import { useParams } from 'react-router-dom';

import { BackButton } from '@Components/container';
import { Block, Box, Image, Spacer, SpacingGroup, Text } from '@Components/ui';
import { useUserChallenge } from '@Hooks/userChallenge';

import { ChallengeItemShareButton } from '../ChallengeItemShareButton/ChallengeItemShareButton';
import { ChallengeItemTags } from '../ChallengeItemTags/ChallengeItemTags';
import styles from './ChallengeItemHead.module.scss';

export const ChallengeItemHead = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: challenge } = useUserChallenge({ id: slug ?? '' });

  return (
    <>
      <Box ml={3} mr={3}>
        <Box mb={5}>
          <BackButton />
        </Box>

        <Text.H1 className={styles.Title}>{challenge?.title}</Text.H1>

        <Spacer top={3} bottom={2} />

        <Box mb={3}>
          <ChallengeItemTags />
        </Box>
      </Box>

      <Box mb={3}>
        <Block padding="none" isElevated>
          <Image src={challenge?.banner} objectFit="cover" />
        </Block>
      </Box>

      <Box mb={5} ml={4} mr={4}>
        <SpacingGroup size="xl">
          <ChallengeItemShareButton />
        </SpacingGroup>
      </Box>
    </>
  );
};
