import cn from 'classnames';
import { useCallback } from 'react';

import { Tag } from '@Components/ui';
import { PinState } from '@Constants/enums';

import styles from './ButtonPin.module.scss';
import { ButtonPinProps } from './ButtonPin.props';

export const ButtonPin = ({ pinState, onClick, size, className, ...props }: ButtonPinProps) => {
  const classNames = cn([
    styles.ButtonPin,
    { [styles.isPinned]: pinState === PinState.PINNED },
    { [styles.hasError]: pinState === PinState.ERROR },
    { [styles.isDisabled]: pinState === PinState.DISABLED },
    className
  ]);

  const handleClick = useCallback(
    (e) => {
      if (pinState !== PinState.DISABLED && onClick) {
        onClick(e);
      }
    },
    [onClick, pinState]
  );

  return (
    <button className={classNames} onClick={handleClick} {...props}>
      <Tag icon="thumbtack" size={size} className={styles.Tag} />
    </button>
  );
};
