import cn from 'classnames';
import { LottieOptions, useLottie } from 'lottie-react';
import { CSSProperties, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';

import downArrowAnimation from '@Assets/lotties/arrow-down.json';
import leftArrowAnimation from '@Assets/lotties/arrow-left.json';
import { Text } from '@Components/ui';
import {
  TargetAlignment,
  TargetDirection,
  useBoolean,
  usePlayInView,
  useTargetedLocation
} from '@Hooks/common';
import { markupToHtml } from '@Utils/StringUtils';

import styles from './CallToAction.module.scss';
import { CallToActionProps } from './CallToAction.props';

export const CallToAction = ({
  targetRef,
  label,
  direction = TargetDirection.DOWN,
  alignment = TargetAlignment.CENTER,
  margin = 16,
  isReversed = false,
  isVisible = true,
  updateOnShow = false,
  updateLocation = false,
  isStatic = false,
  shouldPointAway = false,
  className,
  maxWidth
}: CallToActionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const forceUpdateBool = useBoolean(false);
  const hasAbsolutePosition = !!targetRef;

  useEffect(() => {
    if (updateOnShow) {
      forceUpdateBool.setValue(isVisible);
    }
  }, [isVisible, updateOnShow, forceUpdateBool]);

  useEffect(() => {
    if (updateLocation) {
      forceUpdateBool.setValue(updateLocation);
    }
  }, [updateLocation, forceUpdateBool]);

  const getOffset = () => {
    let offset = 8;

    if (direction === TargetDirection.UP || direction === TargetDirection.DOWN) {
      offset = 32;
    }

    return isReversed ? offset * -1 : offset;
  };

  const { location, hasCalculatedLocation } = useTargetedLocation(
    ref.current,
    targetRef?.current,
    direction,
    alignment,
    margin,
    getOffset(),
    false,
    forceUpdateBool.value
  );

  const getAnimationArrow = useMemo(() => {
    if (direction === TargetDirection.UP || direction === TargetDirection.DOWN) {
      return downArrowAnimation;
    }

    return leftArrowAnimation;
  }, [direction]);

  const lottieOptions: LottieOptions = {
    animationData: getAnimationArrow,
    loop: false,
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: 'none'
    }
  };

  const { View, play, stop } = useLottie(lottieOptions);
  const [inViewRef, reset] = usePlayInView(play);

  useEffect(() => {
    reset();
  }, [direction, reset, isVisible]);

  useEffect(() => {
    if (!isVisible) {
      stop();
    }
  }, [isVisible, stop]);

  const getComponentClasses = () => {
    return cn(
      styles.CallToAction,
      {
        [styles.isReversed]: isReversed,
        [styles.isHorizontal]:
          direction === TargetDirection.UP || direction === TargetDirection.DOWN,
        [styles.isVertical]: direction !== TargetDirection.UP && direction !== TargetDirection.DOWN,
        [styles.isVisible]: isVisible && (!targetRef || hasCalculatedLocation),
        [styles.isStatic]: isStatic,
        [styles.isAbsolute]: hasAbsolutePosition
      },
      className
    );
  };

  const getArrowClasses = () => {
    const classes = [styles.LottieWrapper];

    if (direction === TargetDirection.DOWN) {
      if (shouldPointAway) {
        classes.push(styles.isFlippedY);
      }

      if (isReversed) {
        classes.push(styles.isFlippedX);
      }
    }

    if (direction === TargetDirection.UP) {
      if (!shouldPointAway) {
        classes.push(styles.isFlippedY);
      }

      if (isReversed) {
        classes.push(styles.isFlippedX);
      }
    }

    if (direction === TargetDirection.LEFT) {
      if (shouldPointAway) {
        classes.push(styles.isFlippedX);
      }

      if (!isReversed) {
        classes.push(styles.isFlippedY);
      }
    }

    if (direction === 'right') {
      if (!shouldPointAway) {
        classes.push(styles.isFlippedX);
      }

      if (!isReversed) {
        classes.push(styles.isFlippedY);
      }
    }

    return cn(classes);
  };

  const getTextClasses = () => {
    let classes = [styles.isRotatedLeft];

    if (direction === TargetDirection.UP && isReversed) {
      classes = [styles.isRotatedRight];
    }

    if (direction === TargetDirection.DOWN && !isReversed) {
      classes = [styles.isRotatedRight];
    }

    if (direction === TargetDirection.LEFT && isReversed) {
      classes = [styles.isRotatedRight];
    }

    if (direction === TargetDirection.RIGHT && !isReversed) {
      classes = [styles.isRotatedRight];
    }

    classes.push(styles.Label);

    return cn(classes);
  };

  const getComponentStyle = (): CSSProperties | undefined => {
    if (hasAbsolutePosition) {
      return { left: location.x, top: location.y };
    }
  };

  const inner = () => {
    return (
      <div ref={ref} className={getComponentClasses()} style={getComponentStyle()}>
        <div ref={inViewRef} className={getArrowClasses()}>
          {View}
        </div>

        <Text.Handwritten
          style={{
            maxWidth: maxWidth && `${maxWidth}px`
          }}
          className={getTextClasses()}
          dangerousHtml={markupToHtml(label || '')}
        />
      </div>
    );
  };

  if (!targetRef) {
    return inner();
  }

  return ReactDOM.createPortal(inner(), document.body);
};
