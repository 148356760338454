import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingVerifyLicenseCodeForm } from '@Components/form';
import {
  CallToAction,
  SliderFullPage,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';
import { SLIDER_FULLPAGE_SPEED } from '@Components/ui/SliderFullPage/internal/constants';
import { TargetDirection, useBoolean, useTimeouts } from '@Hooks/common';

import { LoginVerifyLicenseCodeSlideProps } from './LoginVerifyLicenseCodeSlide.props';

export const LoginVerifyLicenseCodeSlide = ({ onSuccess }: LoginVerifyLicenseCodeSlideProps) => {
  const ref = useRef<HTMLElement | null>(null);

  const { value: isCtaVisible, setValue: setIsCtaVisible } = useBoolean(false);

  const { current } = useSliderFullPageContext();
  const { slideNext } = useSliderFullPageItemContext();

  const { t } = useTranslation();
  const { addTimeout } = useTimeouts();

  const handleSuccess = useCallback(
    (licenseCode: string) => {
      onSuccess(licenseCode);
      slideNext();
    },
    [slideNext, onSuccess]
  );

  useEffect(() => {
    if (current === 1) {
      addTimeout(() => {
        setIsCtaVisible(true);
      }, SLIDER_FULLPAGE_SPEED);
    } else {
      setIsCtaVisible(false);
    }
  }, [current, setIsCtaVisible, addTimeout]);

  return (
    <SliderFullPage.Item showPagination>
      <SliderFullPage.Head
        title={t('DOMAIN.AUTHENTICATION.LOGIN.VERIFICATION.LICENSE_CODE.HEADER.TITLE')}
        intro={t('DOMAIN.AUTHENTICATION.LOGIN.VERIFICATION.LICENSE_CODE.HEADER.DESCRIPTION')}
        showPagination
      />

      <SliderFullPage.Block
        title={t('DOMAIN.AUTHENTICATION.LOGIN.VERIFICATION.LICENSE_CODE.FORM.TITLE')}
        ref={ref}
      >
        <OnboardingVerifyLicenseCodeForm onSuccess={handleSuccess} />

        <CallToAction
          targetRef={ref}
          isVisible={isCtaVisible}
          direction={TargetDirection.LEFT}
          isReversed
          isStatic
          updateOnShow
          label={t('DOMAIN.AUTHENTICATION.LOGIN.VERIFICATION.LICENSE_CODE.FORM.CTA')}
        />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
