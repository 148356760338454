import cn from 'classnames';
import { useCallback, useState } from 'react';

import { AccordionItem } from '../AccordionItem/AccordionItem';
import { Box } from '../Box/Box';
import { AccordionProps } from './Accordion.props';

export const Accordion = ({ items, isSmall, className }: AccordionProps) => {
  const [openId, setOpenId] = useState<string | null>(null);

  const handleItemToggle = useCallback(
    (id: string) => () => {
      if (openId === id) {
        setOpenId(null);
      } else {
        setOpenId(id);
      }
    },
    [openId]
  );

  return (
    <div className={cn(className)}>
      {items.map((item) => {
        return (
          <Box key={item.id} mb={1.5}>
            <AccordionItem
              onToggle={handleItemToggle(item.id)}
              isOpen={openId === item.id}
              isSmall={isSmall}
              title={item.title}
            >
              {item.body}
            </AccordionItem>
          </Box>
        );
      })}
    </div>
  );
};
