import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from '@Components/ui';
import { Text } from '@Components/ui';
import { SortDirection } from '@Constants/enums';

import styles from './ButtonSort.module.scss';
import { ButtonSortProps } from './ButtonSort.props';

export const ButtonSort = ({
  label,
  onClick,
  direction = SortDirection.ASC,
  className,
  ...props
}: ButtonSortProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.ButtonSort, className)} onClick={onClick} {...props}>
      <div>
        <Text.Small className={styles.Label}>{label}</Text.Small>

        <div className={styles.DirectionLabelWrapper}>
          <Text.Micro className={styles.PlaceholderLabel}>placeholder</Text.Micro>

          <Text.Micro
            className={cn(
              styles.DirectionLabel,
              { [styles.isShown]: direction === SortDirection.ASC },
              { [styles.isReversed]: direction === SortDirection.ASC },
              { [styles.isDelayed]: direction === SortDirection.ASC }
            )}
          >
            {t('COMMON.GLOBALS.ASCENDING')}
          </Text.Micro>

          <Text.Micro
            className={cn(
              styles.DirectionLabel,
              { [styles.isShown]: direction === SortDirection.DESC },
              { [styles.isDelayed]: direction === SortDirection.DESC }
            )}
          >
            {t('COMMON.GLOBALS.DESCENDING')}
          </Text.Micro>
        </div>
      </div>

      <div className={styles.IconWrapper}>
        <Icon icon={['far', 'sort-amount-up']} className={styles.PlaceholderIcon} />

        <Icon
          icon={['far', 'sort-amount-up']}
          className={cn(
            styles.Icon,
            { [styles.isShown]: direction === SortDirection.ASC },
            { [styles.isReversed]: direction === SortDirection.DESC }
          )}
        />

        <Icon
          icon={['far', 'sort-amount-down']}
          className={cn(
            styles.Icon,
            { [styles.isShown]: direction === SortDirection.DESC },
            { [styles.isReversed]: direction === SortDirection.DESC }
          )}
        />
      </div>
    </div>
  );
};
