import cn from 'classnames';

import { HeaderUnauthenticated } from '@Components/layout';
import { usePlatform } from '@Hooks/common';

import { Container } from '../Container/Container';
import styles from './PageUnauthenticated.module.scss';
import { PageUnauthenticatedProps } from './PageUnauthenticated.props';

export const PageUnauthenticated = ({ children, className }: PageUnauthenticatedProps) => {
  const { background } = usePlatform();

  return (
    <div className={cn(styles.PageUnauthenticated, className)}>
      {background.highlight && (
        <div
          className={styles.Highlight}
          style={{
            background: background.highlight.default
          }}
        />
      )}

      {background.fade && (
        <div className={styles.Fade} style={{ background: background.fade.default }} />
      )}

      <HeaderUnauthenticated className={styles.Header} />

      <div className={styles.Content}>
        <Container cols={10}>{children}</Container>
      </div>
    </div>
  );
};
