import { useTranslation } from 'react-i18next';

import { useQuestionnaireContext } from '@Components/container';
import {
  Box,
  List,
  SliderFullPage,
  SliderNavigation,
  Text,
  useSliderFullPageContext
} from '@Components/ui';

import { QuestionnaireSelfTestRegularIntroSlideProps } from './QuestionnaireSelfTestRegularIntroSlide.props';

export const QuestionnaireSelfTestRegularIntroSlide = ({
  currentSlideIndex,
  totalSlides,
  onInfoClick,
  paginationPrefix,
  ...props
}: QuestionnaireSelfTestRegularIntroSlideProps) => {
  const { t } = useTranslation();
  const { submit } = useQuestionnaireContext();
  const { isAnimating } = useSliderFullPageContext();

  return (
    <SliderFullPage.Item {...props}>
      <SliderFullPage.Head
        title={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.INTRO_SLIDE.REGULAR.HEAD.TITLE')}
      />

      <SliderFullPage.Block
        title={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.INTRO_SLIDE.REGULAR.BODY.TITLE')}
      >
        <Text.Normal>
          {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.INTRO_SLIDE.REGULAR.BODY.DESCRIPTION')}
        </Text.Normal>

        <Box mt={2}>
          <List.Unordered>
            {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.INTRO_SLIDE.REGULAR.BODY.LIST', {
              returnObjects: true
            }).map((item, idx) => {
              return <Text.Normal key={idx}>{item}</Text.Normal>;
            })}
          </List.Unordered>
        </Box>

        <Box mt={4}>
          <SliderNavigation
            nextLabel={t('COMPONENTS.QUESTIONNAIRE.NEXT_LABEL')}
            onNext={submit}
            current={currentSlideIndex}
            total={totalSlides}
            onInfoClick={onInfoClick}
            isDisabled={isAnimating}
            paginationPrefix={paginationPrefix}
          />
        </Box>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
