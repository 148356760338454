import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteData } from 'react-query';

import { Container } from '@Components/layout';
import { TodoListArchive, TodoListOpen } from '@Components/list';
import { Block, Box, Button, Spacer, TabGroup, Text } from '@Components/ui';
import { ListResponse } from '@Constants/interfaces';
import { AppRoutes } from '@Navigation/Routes';
import { OnboardingGetSelfTestQuestionnaireResponse } from '@Services/Onboarding';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';
import { ITodo, TodoListRequest } from '@Services/Todo';
import { exists } from '@Utils/CommonUtils';

import { TodoFilter } from './internal/components';

export const TodoOverviewScreen = () => {
  const { t } = useTranslation();
  const [totalSelfTestOpenCount, setTotalSelfTestOpenCount] = useState<number>(0);
  const [totalSelfTestArchiveCount, setTotalSelfTestArchiveCount] = useState<number>(0);
  const [totalTodoOpenCount, setTotalTodoOpenCount] = useState<number>(0);
  const [totalTodoArchiveCount, setTotalTodoArchiveCount] = useState<number>(0);
  const [todoOpenFilter, setTodoOpenFilter] = useState<TodoListRequest | undefined>(undefined);
  const [todoArchiveFilter, setTodoArchiveFilter] = useState<TodoListRequest | undefined>(
    undefined
  );

  const totalOpenCount = totalTodoOpenCount + totalSelfTestOpenCount;
  const totalArchiveCount = totalTodoArchiveCount + totalSelfTestArchiveCount;

  const handleTodoOpenLoad = useCallback((response: InfiniteData<ListResponse<ITodo>>) => {
    setTotalTodoOpenCount(response.pages[0]?.total ?? 0);
  }, []);

  const handleTodoArchiveLoad = useCallback((response: InfiniteData<ListResponse<ITodo>>) => {
    setTotalTodoArchiveCount(response.pages[0]?.total ?? 0);
  }, []);

  const handleSelfTestLoad = useCallback((response: OnboardingGetSelfTestQuestionnaireResponse) => {
    if (
      response.isPhased &&
      exists<number>(response.futurePhases) &&
      exists<number>(response.totalPhases)
    ) {
      setTotalSelfTestOpenCount(response.futurePhases.length);
      setTotalSelfTestArchiveCount(response.totalPhases - response.futurePhases.length);
    } else {
      const isSelfTestOpen = response.status !== QuestionnaireSubmissionStatus.COMPLETED;

      setTotalSelfTestArchiveCount(isSelfTestOpen ? 0 : 1);
      setTotalSelfTestOpenCount(isSelfTestOpen ? 1 : 0);
    }
  }, []);

  return (
    <Container>
      <Box mb={5}>
        <Box mb={2}>
          <Text.H1>{t('DOMAIN.TODO.TITLE')}</Text.H1>
        </Box>

        <Box mb={2}>
          <Text.Large>{t('DOMAIN.TODO.INTRO')}</Text.Large>
        </Box>

        <Button to={AppRoutes.getTodoUserActivityAdd().to} icon="plus">
          {t('DOMAIN.USER_ACTIVITY.CREATE')}
        </Button>
      </Box>

      <Block>
        <Box mb={1.5}>
          <Text.H3>{t('DOMAIN.TODO.BLOCK_TITLE')}</Text.H3>
        </Box>

        <TabGroup>
          <TabGroup.Child
            label={t('DOMAIN.TODO.OPEN.LABEL', {
              count: totalOpenCount
            })}
          >
            <TodoFilter count={totalOpenCount} onChange={setTodoOpenFilter} />

            <Spacer top={2} bottom={3} />

            {!!todoArchiveFilter && (
              <TodoListOpen
                onSelfTestLoad={handleSelfTestLoad}
                onTodoLoad={handleTodoOpenLoad}
                filter={todoOpenFilter}
              />
            )}
          </TabGroup.Child>

          <TabGroup.Child
            label={t('DOMAIN.TODO.ARCHIVE.LABEL', {
              count: totalArchiveCount
            })}
          >
            <TodoFilter id="archive" count={totalArchiveCount} onChange={setTodoArchiveFilter} />

            <Spacer top={2} bottom={3} />

            {!!todoArchiveFilter && (
              <TodoListArchive
                onSelfTestLoad={handleSelfTestLoad}
                onTodoLoad={handleTodoArchiveLoad}
                filter={todoArchiveFilter}
              />
            )}
          </TabGroup.Child>
        </TabGroup>
      </Block>
    </Container>
  );
};
