import cn from 'classnames';
import range from 'lodash/range';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import {
  TargetDirection,
  useBoolean,
  useMeasurements,
  usePlatform,
  useTargetedLocation,
  useTimeouts
} from '@Hooks/common';

import styles from './Confetti.module.scss';
import { ConfettiProps } from './Confetti.props';
import {
  DURATION,
  FLOOR_HEIGHT,
  FLOOR_WIDTH,
  FORCE,
  PARTICLE_COUNT,
  SIZE
} from './internal/constants';
import { Particle } from './internal/Particle/Particle';
import { ParticleProps } from './internal/Particle/Particle.props';
import useStyles from './internal/styles';

const createParticles = (count: number, colors: string[]): ParticleProps[] => {
  const increment = 360 / count;
  return range(count).map((index) => ({
    color: colors[index % colors.length],
    degree: increment * index,
    index
  }));
};

export const Confetti = ({ targetRef, isExploding = true, className, ...props }: ConfettiProps) => {
  const confettiRef = useRef(null);

  const {
    value: isCompleted,
    setTrue: setCompletedTrue,
    setFalse: setCompletedFalse
  } = useBoolean(false);

  const { addTimeout } = useTimeouts();
  const { color } = usePlatform();
  const { location } = useTargetedLocation(
    confettiRef.current,
    targetRef?.current,
    TargetDirection.MIDDLE,
    undefined,
    undefined,
    undefined,
    false,
    isExploding
  );
  const { scrollHeight: bodyHeight } = useMeasurements(document.body);

  let locationY = location.y;

  if (!targetRef) {
    locationY = SIZE + SIZE * FORCE;
  }

  let floorHeight = bodyHeight;

  if (floorHeight < FLOOR_HEIGHT) {
    floorHeight = FLOOR_HEIGHT;
  }

  const changeRatio = floorHeight / FLOOR_HEIGHT;
  const durationRatio = Math.sqrt(Math.sqrt(changeRatio));

  const force = FORCE / changeRatio;
  const duration = DURATION * durationRatio;

  const colors = [
    color.yellow.default,
    color.yellow.default,
    color.yellow.default,
    color.green.default,
    color.orange.default,
    color.red.default,
    color.info.default
  ];

  const particles = createParticles(PARTICLE_COUNT, colors);

  const classes = useStyles({
    particles,
    duration,
    particleSize: SIZE,
    force,
    floorWidth: FLOOR_WIDTH,
    floorHeight
  })();

  useEffect(() => {
    if (isExploding) {
      addTimeout(() => {
        setCompletedTrue();
      }, duration);
    } else {
      setCompletedFalse();
    }
  }, [isExploding, setCompletedTrue, setCompletedFalse, duration, addTimeout]);

  return ReactDOM.createPortal(
    <div className={styles.ConfettiWrapper} {...props}>
      <div
        ref={confettiRef}
        className={cn(styles.Confetti, className)}
        style={{ left: location.x, top: locationY }}
      >
        {isExploding &&
          !isCompleted &&
          particles.map((particle) => {
            return <Particle key={particle.index} {...particle} className={classes.particle} />;
          })}
      </div>
    </div>,
    document.body
  );
};
