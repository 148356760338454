import cn from 'classnames';

import { IconCircle, Text } from '@Components/ui';

import styles from './Legend.module.scss';
import { LegendProps } from './Legend.props';

export const Legend = ({ items, className }: LegendProps) => {
  return (
    <div className={cn(styles.Legend, className)}>
      {items.map((item) => {
        return (
          <div className={styles.Item} key={item.label}>
            <IconCircle icon={item.icon} color={item.color} />

            <Text.Small className={styles.Label}>{item.label}</Text.Small>
          </div>
        );
      })}
    </div>
  );
};
