import { ReportIntroAvailable } from '../ReportIntroAvailable/ReportIntroAvailable';
import { ReportIntroUnavailablePhased } from '../ReportIntroUnavailablePhased/ReportIntroUnavailablePhased';
import { ReportIntroUnavailableRegular } from '../ReportIntroUnavailableRegular/ReportIntroUnavailableRegular';
import { ReportIntroProps } from './ReportIntro.props';

export const ReportIntro = ({
  hasResults,
  hasPhasedSelfTest,
  nextPhaseAvailableAt
}: ReportIntroProps) => {
  if (hasResults) {
    return <ReportIntroAvailable />;
  }

  if (hasPhasedSelfTest && !!nextPhaseAvailableAt) {
    return <ReportIntroUnavailablePhased nextPhaseAvailableAt={nextPhaseAvailableAt} />;
  }

  return <ReportIntroUnavailableRegular />;
};
