import { useRef } from 'react';

import { Scrollbar } from '..';
import styles from './TimelineGroup.module.scss';
import { TimelineGroupProps } from './TimelineGroup.props';

export const TimelineGroup = ({
  children,
  isFuture = false,
  currentMiddle
}: TimelineGroupProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={ref} className={styles.TimelineGroup}>
      <div>
        <Scrollbar
          currentMiddle={currentMiddle}
          isDisabled={isFuture}
          className={styles.Scrollbar}
        />
      </div>

      {children}
    </div>
  );
};
