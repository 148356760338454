import { H1 } from './H1/PdfTextH1';
import { H2 } from './H2/PdfTextH2';
import { H3 } from './H3/PdfTextH3';
import { H4 } from './H4/PdfTextH4';
import { H5 } from './H5/PdfTextH5';
import { Intro } from './Intro/PdfTextIntro';
import { Large } from './Large/PdfTextLarge';
import { Medium } from './Medium/PdfTextMedium';
import { Micro } from './Micro/PdfTextMicro';
import { Normal } from './Normal/PdfTextNormal';
import { Small } from './Small/PdfTextSmall';

export const PdfText = {
  H1,
  H2,
  H3,
  H4,
  H5,
  Intro,
  Large,
  Medium,
  Micro,
  Normal,
  Small
};
