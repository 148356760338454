import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { ApiErrorDomain, HandledError } from '@Constants/interfaces';
import { getApiErrorMessage, handleApiError } from '@Utils/ErrorUtils';

export const useApiError = () => {
  const [error, setError] = useState<HandledError | undefined>();

  const handleError = useCallback(
    (_error: any, domain: ApiErrorDomain = 'DEFAULT', shouldToast = false) => {
      const errorKey = handleApiError(_error, domain);
      setError(errorKey);

      if (shouldToast && errorKey) {
        toast.error(getApiErrorMessage(errorKey));
      }
    },
    []
  );

  return {
    error,
    handleError
  };
};
