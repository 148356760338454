import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';

import { SearchBlock } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './ActivityFilterSearch.module.scss';
import { ActivityFilterSearchProps } from './ActivityFilterSearch.props';

export const ActivityFilterSearch = ({ isVisible, onChange }: ActivityFilterSearchProps) => {
  const { t } = useTranslation();
  const { easing } = usePlatform();

  return (
    <AnimateHeight
      className={styles.ActivityFilterSearch}
      height={isVisible ? 'auto' : 0}
      duration={400}
      easing={easing.out}
    >
      <SearchBlock
        title={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.SEARCH.TITLE')}
        placeholder={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.SEARCH.PLACEHOLDER')}
        buttonLabel={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.SEARCH.BUTTON')}
        onSearch={onChange}
        className={styles.Search}
      />
    </AnimateHeight>
  );
};
