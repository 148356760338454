import cn from 'classnames';
import { Children } from 'react';

import { Text } from '@Components/ui';

import styles from './Stepped.module.scss';
import { SteppedListProps } from './Stepped.props';

export const Stepped = ({ children, className }: SteppedListProps) => {
  return (
    <ol className={cn(styles.Stepped, className)}>
      {Children.toArray(children).map((child, idx) => {
        return (
          <li key={idx} className={styles.ListItem}>
            <Text.Bold className={styles.Number}>{idx + 1}</Text.Bold>

            <div className={styles.Children}>{child}</div>
          </li>
        );
      })}
    </ol>
  );
};
