import cn from 'classnames';
import { Children } from 'react';

import { Text } from '@Components/ui';

import styles from './Ordered.module.scss';
import { OrderedListProps } from './Ordered.props';

export const Ordered = ({ children, className }: OrderedListProps) => {
  return (
    <ol className={cn(styles.Ordered, className)}>
      {Children.toArray(children).map((child, idx) => {
        return (
          <li key={idx} className={styles.ListItem}>
            <Text.Bold className={styles.Number}>{idx + 1}.</Text.Bold>

            <div className={styles.Children}>{child}</div>
          </li>
        );
      })}
    </ol>
  );
};
