import cn from 'classnames';
import { useCallback, useState } from 'react';

import styles from './FormAccordion.module.scss';
import { FormAccordionProps } from './FormAccordion.props';
import { FormAccordionItem } from './internal';

export const FormAccordion = ({ className, options }: FormAccordionProps) => {
  const [activeItem, setActiveItem] = useState(0);

  const handleClick = useCallback((idx: number) => () => setActiveItem(idx), []);

  const handleChange = useCallback(() => {
    setActiveItem((oldActiveItem) => {
      if (oldActiveItem + 1 < options.length) {
        return oldActiveItem + 1;
      }

      return oldActiveItem;
    });
  }, [options.length]);

  return (
    <div className={cn([styles.FormAccordion, className])}>
      {options.map((option, idx: number) => {
        return (
          <FormAccordionItem
            key={idx}
            option={option}
            onClick={handleClick(idx)}
            onChange={handleChange}
            isOpen={activeItem === idx}
          />
        );
      })}
    </div>
  );
};
