import cn from 'classnames';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import {
  useAutoScroll,
  useBoolean,
  useHover,
  useMeasurements,
  useSlidingSelector
} from '@Hooks/common';

import styles from './ButtonGroup.module.scss';
import { ButtonGroupProps } from './ButtonGroup.props';

export const ButtonGroup = ({
  buttons,
  onChange,
  onHover,
  activeIdx,
  className
}: ButtonGroupProps) => {
  const [hoverIdx, setHoverIdx] = useState(0);

  const { value: isTooWide, setValue: setIsTooWide } = useBoolean(false);
  const { isHovering, handlers } = useHover();

  const buttonGroupRef = useRef<HTMLDivElement | null>(null);
  const buttonWrapperRef = useRef<HTMLDivElement | null>(null);
  const buttonRefs = useRef<Array<HTMLDivElement | null>>([]);

  const [activeOffset, , activeWidth, totalWidth, totalHeight] = useSlidingSelector(
    buttonRefs,
    activeIdx
  );
  const [hoverOffset, , hoverWidth] = useSlidingSelector(buttonRefs, hoverIdx);
  const { offsetWidth: groupWidth } = useMeasurements(buttonGroupRef.current);

  useAutoScroll(buttonWrapperRef, activeOffset.x - activeWidth);

  useLayoutEffect(() => {
    setIsTooWide(() => groupWidth < totalWidth);
  }, [setIsTooWide, groupWidth, totalWidth]);

  const handleHover = useCallback(
    (idx: number | undefined) => () => {
      if (idx !== undefined) {
        setHoverIdx(idx);
      }

      if (onHover) {
        onHover(idx);
      }
    },
    [onHover]
  );

  const handleChange = useCallback(
    (idx: number, isChecked: boolean, isDisabled: boolean) => () => {
      if (!isChecked && !isDisabled) {
        onChange(idx);
      }
    },
    [onChange]
  );

  if (!buttons) return null;

  const isFirstElementSelected = activeIdx > 0;
  const isLastElementSelected = activeIdx === buttons.length - 1;

  const componentClasses = cn([
    styles.ButtonGroup,
    {
      [styles.isTooWide]: isTooWide,
      [styles.shouldTouchLeft]: isFirstElementSelected,
      [styles.isAtEnd]: isLastElementSelected
    },
    className
  ]);

  return (
    <div
      ref={buttonGroupRef}
      className={componentClasses}
      style={{ height: isTooWide ? totalHeight : 'auto' }}
      {...handlers}
    >
      <div className={styles.Inner}>
        <div ref={buttonWrapperRef} className={styles.ButtonWrapper}>
          {buttons.map((button, idx) => {
            const isChecked = activeIdx === idx;

            const buttonClassNames = cn(styles.Button, {
              [styles.isChecked]: isChecked,
              [styles.isDisabled]: button.isDisabled
            });

            return (
              <div
                ref={(el) => (buttonRefs.current[idx] = el)}
                key={button.label}
                className={buttonClassNames}
                onClick={handleChange(idx, isChecked, !!button.isDisabled)}
                onMouseEnter={handleHover(idx)}
                onMouseLeave={handleHover(undefined)}
                data-testid="ButtonGroup"
              >
                {button.label}
              </div>
            );
          })}

          <div className={styles.Selector} style={{ left: activeOffset.x, width: activeWidth }} />

          <div
            className={cn(styles.Selector, styles.HoverSelector, {
              [styles.isHovering]: isHovering
            })}
            style={{ left: hoverOffset.x, width: hoverWidth }}
          />
        </div>
      </div>
    </div>
  );
};
