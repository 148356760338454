import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@Components/ui';
import { RewardListRequest } from '@Services/Reward';
import { removeEmptyOrUndefinedFromObject } from '@Utils/CommonUtils';
import { toLocaleString } from '@Utils/StringUtils';

import { TodoFilterSort, TodoFilterSortValues } from './internal/components';
import styles from './TodoFilter.module.scss';
import { TodoFilterProps } from './TodoFilter.props';

export const TodoFilter = ({ onChange, count, id = '' }: TodoFilterProps) => {
  const { t } = useTranslation();

  const [sort, setSort] = useState<TodoFilterSortValues>();

  useEffect(() => {
    const params = removeEmptyOrUndefinedFromObject({
      sortBy: sort?.sortBy,
      sortDirection: sort?.sortDirection
    }) as RewardListRequest;

    if (!isEmpty(params)) {
      onChange(params);
    }
  }, [onChange, sort]);

  const handleSort = useCallback((values: TodoFilterSortValues) => {
    setSort(values);
  }, []);

  return (
    <div className={styles.TodoFilter}>
      <div className={styles.Filters}>
        <Text.Small
          dangerousHtml={t('DOMAIN.REWARD.OVERVIEW.FILTER.ITEMS', {
            count: toLocaleString(count || 0)
          })}
        />

        <div className={styles.Sort}>
          <TodoFilterSort id={id} onChange={handleSort} />
        </div>
      </div>
    </div>
  );
};
