import cn from 'classnames';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { Icon, Image, Tag } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './Card.module.scss';
import { CardProps } from './Card.props';

export const Card = forwardRef(
  ({ to, tags, image, isRecommended, children, onClick, className }: CardProps, ref: any) => {
    const { color } = usePlatform();

    const classNames = cn(styles.Card, className, {
      [styles.hasClick]: !!onClick || !!to
    });

    return (
      <Link ref={ref} to={to ?? ''} onClick={onClick} className={classNames}>
        <div className={styles.Thumbnail}>
          {!!image && <Image className={styles.Image} src={image} ratio="2:1" />}

          {!!tags && tags.length > 0 && (
            <div className={styles.Tags}>
              {tags?.map((tag, idx: number) => {
                return (
                  <Tag
                    key={idx}
                    className={styles.Tag}
                    size="sm"
                    color={color.primary.default}
                    icon={tag.icon}
                    label={tag.label}
                  />
                );
              })}
            </div>
          )}

          {isRecommended && (
            <div className={styles.Recommended}>
              <Icon size="lg" icon={['fas', 'flag']} />
            </div>
          )}
        </div>

        <div className={styles.Body}>{children}</div>
      </Link>
    );
  }
);
