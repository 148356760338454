import cn from 'classnames';

import styles from './Bar.module.scss';
import { BarProps } from './Bar.props';

export const Bar = ({ total, current, isVisible }: BarProps) => {
  const getProgressPercentage = () => {
    if (!isVisible) {
      return 0;
    }

    if (current >= total) {
      return 100;
    }

    const offsetPercentage = (current / (total + 1)) * 100;

    return offsetPercentage;
  };

  return (
    <div className={styles.Bar}>
      <div className={styles.Track} />

      <div className={styles.Progress} style={{ width: `${getProgressPercentage()}%` }} />

      <div className={cn(styles.Fade, { [styles.isVisible]: current === 0 })} />
    </div>
  );
};
