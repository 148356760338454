import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { SMS_CODE_LENGTH } from '@Constants/configs';
import { useApiError } from '@Hooks/common';
import { useUserVerifyUpdatePhone } from '@Hooks/user';
import Yup from '@Utils/YupUtils';

import {
  EditPhoneVerifyTwoFactorFormProps,
  EditPhoneVerifyTwoFactorFormValues
} from './EditPhoneVerifyTwoFactorForm.props';

export const EditPhoneVerifyTwoFactorForm = ({
  onSuccess,
  smsToken
}: EditPhoneVerifyTwoFactorFormProps) => {
  const { t } = useTranslation();
  const { error, handleError } = useApiError();

  const verifyUpdatePhoneMutation = useUserVerifyUpdatePhone();

  const handleSubmit = useCallback(
    async (values: EditPhoneVerifyTwoFactorFormValues) => {
      try {
        const params = {
          smsToken,
          token: values.token
        };

        await verifyUpdatePhoneMutation.mutateAsync(params);

        onSuccess(params);
      } catch (e) {
        handleError(e, 'PHONE');
      }
    },
    [verifyUpdatePhoneMutation, smsToken, onSuccess, handleError]
  );

  const validationSchema = Yup.object().shape({
    token: Yup.string().length(SMS_CODE_LENGTH).required()
  });

  return (
    <Form.Form<EditPhoneVerifyTwoFactorFormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form.Input.Code
        name="token"
        data-testid="tokenInput"
        label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.TOKEN.LABEL')}
        description={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.TOKEN.DESCRIPTION')}
        options={{
          isError: !!error,
          type: 'number',
          pattern: '[0-9]*',
          inputMode: 'numeric'
        }}
      />

      <Form.Layout.Error error={error} />

      <Button type="submit" color="dark" icon="arrow-right" data-testid="submitButton">
        {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.VERIFY_SMS.SUBMIT_LABEL')}
      </Button>
    </Form.Form>
  );
};
