export interface MatomoTrackEventProps {
  category: MatomoCategory;
  action: MatomoAction | string;
  name?: string;
}

export enum MatomoCategory {
  LOGIN = 'Inloggen',
  ONBOARDING = 'Onboarding',
  DASHBOARD_SELFTEST_PAUSED = 'Test jezelf - gepauzeerd (dashboard)',
  ONBOARDING_SELFTEST_PAUSED = 'Test jezelf - gepauzeerd (onboarding)',
  ONBOARDING_SELFTEST_FINISHED = 'Test jezelf - afgerond',
  ONBOARDING_SELFTEST_STEP = 'Test jezelf - flow',
  ONBOARDING_SELFTEST_STEP_PREVIOUS = 'Test jezelf - stap terug',
  TODO = 'To Do',
  CHALLENGE = 'Uitdaging',
  ACTIVITY = 'Activiteit',
  REWARD = 'Beloning',
  CONTACT = 'Contact',
  FAQ = 'FAQ',
  OTHER = 'Overig',
  MOTIVATOR = 'Motivator',
  POLL = 'Poll',
  PROFILE = 'Profiel'
}

export enum MatomoAction {
  LOGIN_STEP = 'Inloggen - flow',
  ONBOARDING_ACCOUNT_STEP = 'Mijn account vragenlijst - flow',
  ONBOARDING_ACCOUNT_TYPE_MAIL = 'Mijn Account vragenlijst - type email',
  ONBOARDING_PREFERENCE_STEP = 'Voorkeuren - flow',
  ONBOARDING_PERSONA_STEP = 'Persona - flow',
  ONBOARDING_PERSONA_STEP_PREVIOUS = 'Persona - stap terug',
  TODO_CREATED = 'Eigen activiteit aangemaakt',
  TODO_LIKE = 'Eigen activiteit positief beoordeeld',
  TODO_DISLIKE = 'Eigen activiteit negatief beoordeeld',
  CHALLENGE_START = 'Uitdaging gestart',
  CHALLENGE_CANCEL = 'Uitdaging geannuleerd',
  CHALLENGE_FINISH = 'Uitdaging afgerond',
  CHALLENGE_TYPE_START = 'Type uitdaging gestart',
  CHALLENGE_TYPE_CANCEL = 'Type uitdaging geannuleerd',
  CHALLENGE_TYPE_FINISH = 'Type uitdaging afgerond',
  CHALLENGE_THEME_SELECTED = 'Thema uitdaging geselecteerd',
  ACTIVITY_SEARCH = 'Kennis & inspiratie - zoeken',
  ACTIVITY_FILTER = 'Kennis & inspiratie - filter',
  ACTIVITY_OPEN = 'Artikel geopend',
  ACTIVITY_READ = 'Artikel gelezen',
  ACTIVITY_LIKE = 'Artikel positief beoordeeld',
  ACTIVITY_DISLIKE = 'Artikel negatief beoordeeld',
  ACTIVITY_SHARE = 'Artikel gedeeld',
  ACTIVITY_PIN = 'Artikel pinned',
  ACTIVITY_UNPIN = 'Artikel unpinned',
  ACTIVITY_TYPE_OPEN = 'Type artikel geopend',
  ACTIVITY_TYPE_READ = 'Type artikel gelezen',
  ACTIVITY_TYPE_LIKE = 'Type artikel positief beoordeeld',
  ACTIVITY_TYPE_DISLIKE = 'Type artikel negatief beoordeeld',
  ACTIVITY_TYPE_SHARE = 'Type artikel gedeeld',
  ACTIVITY_TYPE_PIN = 'Type artikel pinned',
  ACTIVITY_TYPE_UNPIN = 'Type artikel unpinned',
  REWARD_OPEN = 'Beloning geopend',
  REWARD_OPEN_CATEGORY = 'Meest geopende categorie',
  REWARD_CLAIM = 'Beloning verzilverd',
  REWARD_CLAIM_CATEGORY = 'Meest verzilverde categorie',
  CONTACT_CALLBACK = 'Terugbelverzoek ingevuld',
  CONTACT_TEL = 'Telefoon link klik',
  CONTACT_MAIL = 'Mail link klik',
  FAQ_QUESTION = 'Meest bekeken FAQ vraag',
  FAQ_CATEGORY = 'Meest bekeken FAQ categorie',
  OTHER_DOWNLOAD = 'Downloads',
  OTHER_NEGATIVE_THEME_RESULT = 'Thema negatief resultaat - extra informatie',
  MOTIVATOR_CANCEL = 'Popup vragenlijst gesloten',
  PROFILE_PREFERENCE_STEP = 'Voorkeuren - flow'
}

export enum MatomoName {
  OTHER_DOWNLOAD_SCORES_AND_ADVICE = 'Rapportage Scores & advies gedownload'
}

export enum MatomoABTest {
  SIGNUP = 'Aanmeldpagina_'
}
