import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import {
  onboardingService,
  OnboardingSubmitPersonaVerificationQuestionRequest
} from '@Services/Onboarding';
import { userService } from '@Services/User';
import { trackMatomoOnboardingPersona } from '@Utils/MatomoUtils';

export const useOnboardingSubmitPersonaVerificationQuestion = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: OnboardingSubmitPersonaVerificationQuestionRequest) => {
      return onboardingService.submitPersonaVerificationQuestion(config);
    },
    {
      onSuccess: () => {
        trackMatomoOnboardingPersona(trackEvent);

        queryClient.invalidateQueries([userService.getEndpoint()]);
      }
    }
  );
};
