import cn from 'classnames';
import { ChangeEvent, forwardRef, useCallback } from 'react';

import { Icon, Text } from '@Components/ui';

import styles from './InputRadio.module.scss';
import { InputRadioProps } from './InputRadio.props';
import { InputRadioPoll } from './internal/InputRadioPoll';

export const InputRadio = forwardRef(
  (
    {
      options,
      isLarge,
      isSmall,
      id = '',
      isPoll,
      onBlur,
      onChange,
      name,
      value,
      className,
      isDisabled,
      isError,
      isValid
    }: InputRadioProps,
    ref?: any
  ) => {
    const hasVoted = isPoll && !!value;
    const classNames = cn(className, {
      [styles.InputRadio]: !isLarge && !isPoll,
      [styles.InputRadioLarge]: isLarge || isPoll,
      [styles.isSmall]: isSmall,
      [styles.hasVoted]: hasVoted,
      [styles.isError]: isError,
      [styles.isValid]: isValid
    });

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e.target.value);
        }
      },
      [onChange]
    );

    return (
      <div className={classNames} ref={ref}>
        {options.map((option, idx) => {
          const isOptionDisabled = hasVoted || isDisabled || option.disabled;

          return (
            <div key={option.value} className={styles.Item}>
              <label
                className={cn(styles.Input, {
                  [styles.isChecked]: value === option.value,
                  [styles.isDisabled]: isOptionDisabled
                })}
              >
                <input
                  id={`InputRadio-${name}-${idx}${id ? `-${id}` : ''}`}
                  className={styles.Radio}
                  checked={value === option.value}
                  type="radio"
                  disabled={isOptionDisabled}
                  onClick={onBlur}
                  onBlur={onBlur}
                  onChange={handleChange}
                  value={option.value}
                />

                <div className={styles.Icon}>
                  <Icon icon={['fas', 'check']} />
                </div>

                <div className={styles.Content}>
                  {!isSmall && <Text.Large className={styles.Label}>{option.label}</Text.Large>}
                  {isSmall && <Text.Small className={styles.Label}>{option.label}</Text.Small>}

                  {hasVoted && <InputRadioPoll percentage={option.percentage || 0} />}
                </div>
              </label>
            </div>
          );
        })}
      </div>
    );
  }
);
