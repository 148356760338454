import cn from 'classnames';
import { truncate } from 'lodash';
import { MouseEvent, useCallback, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Modal } from '@Components/layout';
import { Anchor, Box, Button, Dot, List, SpacingGroup, Text } from '@Components/ui';
import { TourDotsConfig } from '@Constants/tourDotsConfig';
import { useApiError, useBoolean, useIsFullscreen } from '@Hooks/common';
import { useUser, useUserCloseTourdot, useUserToggleTourdots } from '@Hooks/user';
import { PreferenceKey } from '@Services/Preference';

import styles from './TourDot.module.scss';
import { TourDotProps } from './TourDot.props';

export const TourDot = ({ id, target, className }: TourDotProps) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const userCloseTourdotMutation = useUserCloseTourdot();
  const userToggleTourdotsMutation = useUserToggleTourdots();
  const { handleError } = useApiError();
  const isFullScreen = useIsFullscreen();
  const { preferences } = useUser();
  const { alignment } = TourDotsConfig[id];

  const isOpen = useMemo(() => {
    const closedTourdots = preferences.find(
      (preference) => preference.key === PreferenceKey.TOURDOTS
    );

    if (closedTourdots && closedTourdots.values) {
      return !closedTourdots.values.includes(id);
    }

    return !!closedTourdots;
  }, [preferences, id]);

  const {
    value: isSkipModalOpen,
    setFalse: closeSkipModal,
    setTrue: openSkipModal
  } = useBoolean(false);
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);

  const translations = t(`COMPONENTS.TOURDOT.DOTS.${id}`, { returnObjects: true }) as any;

  const handleAccept = useCallback(async () => {
    try {
      await userCloseTourdotMutation.mutateAsync({
        id: id
      });

      closeSkipModal();
      closeModal();
    } catch (e) {
      handleError(e);
    }
  }, [userCloseTourdotMutation, id, handleError, closeSkipModal, closeModal]);

  const handleStop = useCallback(async () => {
    try {
      await userToggleTourdotsMutation.mutateAsync({
        hide: true
      });

      toast.info(t('COMPONENTS.TOURDOT.WELCOME.CLOSE.SUCCESS_STOP'));

      closeSkipModal();
      closeModal();
    } catch (e) {
      handleError(e);
    }
  }, [userToggleTourdotsMutation, handleError, closeModal, closeSkipModal, t]);

  const handleOpen = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      openModal();
    },
    [openModal]
  );

  if (isOpen && !isFullScreen) {
    return ReactDOM.createPortal(
      <>
        <Dot
          ref={ref}
          tooltip={truncate(translations.BODY, {
            length: 32
          })}
          className={cn(styles.TourDot, className)}
          onClick={handleOpen}
          style={{ ...alignment }}
        />

        <Modal
          title={translations.TITLE}
          isOpen={isModalOpen}
          onClose={closeModal}
          className={styles.Modal}
        >
          <Text.Large className={styles.Body}>{translations.BODY}</Text.Large>

          {!!translations.LIST && translations.LIST.length > 0 && (
            <List.Unordered>
              {translations.LIST.map((item: string) => {
                return <Text.Large>{item}</Text.Large>;
              })}
            </List.Unordered>
          )}

          <Box mt={3}>
            <SpacingGroup size="xxl">
              <Button
                onClick={handleAccept}
                isLoading={userCloseTourdotMutation.isLoading}
                color="dark"
              >
                {t('COMPONENTS.TOURDOT.ACCEPT')}
              </Button>

              <Anchor onClick={openSkipModal}>{t('COMPONENTS.TOURDOT.HIDE_ALL')}</Anchor>
            </SpacingGroup>
          </Box>
        </Modal>

        <Modal
          title={t('COMPONENTS.TOURDOT.WELCOME.CLOSE.TITLE')}
          isOpen={isSkipModalOpen}
          onClose={closeSkipModal}
        >
          <Text.Large>{t('COMPONENTS.TOURDOT.WELCOME.CLOSE.DESCRIPTION')}</Text.Large>

          <Box mt={3}>
            <SpacingGroup size="xxl">
              <Button
                color="dark"
                onClick={handleStop}
                isLoading={userToggleTourdotsMutation.isLoading}
              >
                {t('COMPONENTS.TOURDOT.WELCOME.CLOSE.BUTTON_STOP')}
              </Button>

              <Anchor onClick={handleAccept}>
                {t('COMPONENTS.TOURDOT.WELCOME.CLOSE.BUTTON_PROCEED')}
              </Anchor>
            </SpacingGroup>
          </Box>
        </Modal>
      </>,
      target
    );
  }

  return null;
};
