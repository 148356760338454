import { useTranslation } from 'react-i18next';

import userActivityThumbnail from '@Assets/images/custom-activity-thumbnail.jpg';
import { Text, ThumbnailTextRow } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';
import { ITimelineItem, TimelineItemType } from '@Services/Insight';
import { shortDayMonth } from '@Utils/DateUtils';
import { markupToHtml } from '@Utils/StringUtils';

import styles from './TimelineSmallGroup.module.scss';
import { TimelineSmallGroupProps } from './TimelineSmallGroup.props';

export const TimelineSmallGroup = ({ date, items }: TimelineSmallGroupProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();

  const getTo = (item: ITimelineItem) => {
    if (item.type === TimelineItemType.ACTIVITY && item.slug) {
      return AppRoutes.getActivity(item.slug).to;
    }

    if (item.type === TimelineItemType.CHALLENGE && item.slug) {
      return AppRoutes.getInsightChallenge(item.slug).to;
    }

    if (item.type === TimelineItemType.CUSTOM_ACTIVITY) {
      return AppRoutes.getTodoUserActivity(item.id).to;
    }

    return '';
  };

  const getTimelineItem = (item: ITimelineItem) => {
    const isUserActivity = item.type === TimelineItemType.CUSTOM_ACTIVITY;

    return (
      <ThumbnailTextRow
        key={item.preprId}
        to={getTo(item)}
        image={isUserActivity ? userActivityThumbnail : item.thumbnail}
        title={isUserActivity ? t('DOMAIN.USER_ACTIVITY.TITLE', { title: item.title }) : item.title}
        className={styles.Item}
        isCompleted
      />
    );
  };

  return (
    <div className={styles.TimelineSmallGroup}>
      <div className={styles.Header}>
        <Text.Normal
          className={styles.Title}
          dangerousHtml={markupToHtml(t('COMPONENTS.TIMELINE_SMALL.HEADER'))}
        />

        <Text.Micro color={color.gray.dark}>{shortDayMonth(date)}</Text.Micro>
      </div>

      {items.map(getTimelineItem)}
    </div>
  );
};
