import { useEffect } from 'react';

import { useBoolean, useTimeouts } from '@Hooks/common';

import { ANIMATION_DELAY } from '../constants';
import { useScoreCircleContext } from '../context';

export const useScoreCircleIsDoneAnimating = () => {
  const isDoneAnimating = useBoolean();

  const { addTimeout } = useTimeouts();
  const { scores, isVisible } = useScoreCircleContext();

  useEffect(() => {
    if (isVisible) {
      addTimeout(() => {
        isDoneAnimating.setTrue();
      }, ANIMATION_DELAY * scores?.length);
    }
  }, [isVisible, scores, isDoneAnimating, addTimeout]);

  return [isDoneAnimating.value];
};
