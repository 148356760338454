import { useCallback } from 'react';

import { useLocationEffect } from './useLocationEffect';

export const useScrollToTop = () => {
  const handleScroll = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  useLocationEffect(handleScroll);
};
