import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ButtonPin } from '@Components/ui';
import { PinState } from '@Constants/enums';
import { useActivityPin } from '@Hooks/activity';
import { useApiError } from '@Hooks/common';

import { useUpdateActivityData } from './internal/hooks';
import { PinActivityProps } from './PinActivity.props';

export const PinActivity = ({ activity, size, className, ...props }: PinActivityProps) => {
  const { handleError } = useApiError();
  const activityPinMutation = useActivityPin();
  const { t } = useTranslation();
  const updateActivityData = useUpdateActivityData();

  const handlePin = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (activity.isPartOfChallenge) {
        toast.info(t('DOMAIN.ACTIVITY.PIN.IS_PART_OF_CHALLENGE'));
        return;
      }

      try {
        await activityPinMutation.mutateAsync(activity);
        updateActivityData(activity, { pinned: !activity.pinned });
      } catch (e) {
        handleError(e);
      }
    },
    [activity, t, activityPinMutation, updateActivityData, handleError]
  );

  const getPinState = () => {
    if (activity.pinned) {
      return PinState.PINNED;
    }

    return PinState.UNPINNED;
  };

  return (
    <ButtonPin
      size={size}
      pinState={getPinState()}
      onClick={handlePin}
      className={className}
      {...props}
    />
  );
};
