import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ListCardActivity } from '@Components/list';
import { Box, Loader, Text } from '@Components/ui';
import { useActivityPopular } from '@Hooks/activity';
import { AppRoutes } from '@Navigation/Routes';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import { DashboardPopularActivitiesProps } from './DashboardPopularActivities.props';

export const DashboardPopularActivities = ({ className }: DashboardPopularActivitiesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: activities,
    isSuccess: isActivitiesSuccess,
    isLoading: isActivitiesLoading,
    isError: isActivitiesError
  } = useActivityPopular({
    perPage: 2
  });

  const handleNavigate = useCallback(() => {
    navigate(AppRoutes.getActivities().to);
  }, [navigate]);

  const footerLink = {
    label: t('DOMAIN.DASHBOARD.BLOCKS.ACTIVITIES_POPULAR.READ_MORE'),
    onClick: handleNavigate
  };

  return (
    <DashboardBlock widgetKey="ACTIVITIES_POPULAR" footerLink={footerLink} className={className}>
      {activities?.pages[0].results && isActivitiesSuccess && (
        <ListCardActivity activities={activities.pages[0].results} />
      )}

      {isActivitiesError && (
        <Text.Error>{t('DOMAIN.DASHBOARD.BLOCKS.ACTIVITIES_POPULAR.ERROR')}</Text.Error>
      )}

      {isActivitiesLoading && (
        <Box my={8}>
          <Loader>{t('DOMAIN.DASHBOARD.BLOCKS.ACTIVITIES_POPULAR.LOADING')}</Loader>
        </Box>
      )}
    </DashboardBlock>
  );
};
