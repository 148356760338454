import { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Form, Loader } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useDataGgc } from '@Hooks/data';
import { usePreferenceList, usePreferenceSubmitBatch } from '@Hooks/preference';
import { GgcType } from '@Services/Data';
import { PreferenceKey } from '@Services/Preference';
import { getPreference } from '@Utils/PreferenceUtils';
import Yup from '@Utils/YupUtils';

import { groupGccOptions } from './internal/utils';
import { PreferencesGgcFormProps, PreferencesGgcFormValues } from './PreferencesGgcForm.props';

export const PreferencesGgcForm = forwardRef(
  ({ onSuccess, className, children }: PreferencesGgcFormProps, ref: any) => {
    const { t } = useTranslation();
    const { data: dataGgc, isLoading: isDataGgcLoading } = useDataGgc();
    const { data: preferences, isLoading: isPreferencesLoading } = usePreferenceList();
    const preferenceSubmitBatchMutation = usePreferenceSubmitBatch();
    const { handleError } = useApiError();

    const groupedOptions = useMemo(() => {
      return groupGccOptions(dataGgc);
    }, [dataGgc]);

    const handleSubmit = useCallback(
      async (values: PreferencesGgcFormValues) => {
        try {
          const params = {
            [PreferenceKey.GGC_COMPETENCE]: [values.competence],
            [PreferenceKey.GGC_HEALTH]: [values.health],
            [PreferenceKey.GGC_MOTIVATION]: [values.motivation]
          };

          await preferenceSubmitBatchMutation.mutateAsync(params);

          onSuccess();
        } catch (e) {
          handleError(e);
        }
      },
      [preferenceSubmitBatchMutation, onSuccess, handleError]
    );

    const inputOptions = useMemo(() => {
      return [
        {
          label: t('DOMAIN.PREFERENCES.GGC.FORM.HEALTH'),
          render: (
            <Form.Input.Radio
              name="health"
              data-testid="healthInput"
              options={{ options: groupedOptions?.[GgcType.HEALTH] || [], isLarge: true }}
            />
          )
        },
        {
          label: t('DOMAIN.PREFERENCES.GGC.FORM.MOTIVATION'),
          render: (
            <Form.Input.Radio
              name="motivation"
              data-testid="motivationInput"
              options={{ options: groupedOptions?.[GgcType.MOTIVATION] || [], isLarge: true }}
            />
          )
        },
        {
          label: t('DOMAIN.PREFERENCES.GGC.FORM.COMPETENCE'),
          render: (
            <Form.Input.Radio
              name="competence"
              data-testid="competenceInput"
              options={{ options: groupedOptions?.[GgcType.COMPETENCE] || [], isLarge: true }}
            />
          )
        }
      ];
    }, [groupedOptions, t]);

    const initialValues = {
      health: getPreference(preferences || [], PreferenceKey.GGC_HEALTH)?.[0],
      motivation: getPreference(preferences || [], PreferenceKey.GGC_MOTIVATION)?.[0],
      competence: getPreference(preferences || [], PreferenceKey.GGC_COMPETENCE)?.[0]
    };

    const validationSchema = Yup.object().shape({
      health: Yup.string().required(),
      motivation: Yup.string().required(),
      competence: Yup.string().required()
    });

    if (isPreferencesLoading || isDataGgcLoading) {
      return (
        <Box my={8}>
          <Loader />;
        </Box>
      );
    }

    return (
      <Form.Form<PreferencesGgcFormValues>
        ref={ref}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        className={className}
      >
        <Form.Layout.Accordion options={inputOptions} />

        {children}
      </Form.Form>
    );
  }
);
