import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ChallengeAddSideview } from '@Components/container';
import { ListCardChallenge } from '@Components/list';
import { Box, Loader, Text } from '@Components/ui';
import { useChallengeRecommended } from '@Hooks/challenge';
import { useBoolean } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';
import { IChallenge } from '@Services/Challenge';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import { DashboardChallengesProps } from './DashboardChallenges.props';

export const DashboardChallenges = ({ className }: DashboardChallengesProps) => {
  const [selectedChallenge, setSelectedChallenge] = useState<IChallenge>();
  const [searchParams] = useSearchParams();
  const sharedChallenge = searchParams.get('challenge');

  const {
    value: isSideviewOpen,
    setTrue: setSideviewOpen,
    setFalse: setSideviewClosed
  } = useBoolean(!!sharedChallenge);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: challenges,
    isSuccess: isChallengesSuccess,
    isLoading: isChallengesLoading,
    isError: isChallengesError
  } = useChallengeRecommended();

  const handleNavigate = useCallback(() => {
    navigate(AppRoutes.getInsightChallenges().to);
  }, [navigate]);

  const handleSelectChallenge = useCallback(
    (_challenge: IChallenge) => {
      setSelectedChallenge(_challenge);
      setSideviewOpen();
    },
    [setSideviewOpen]
  );

  const footerLink = {
    label: t('DOMAIN.DASHBOARD.BLOCKS.CHALLENGES.READ_MORE'),
    onClick: handleNavigate
  };

  return (
    <>
      <DashboardBlock
        widgetKey="CHALLENGES"
        footerLink={footerLink}
        className={className}
        data-tourdotid="CHALLENGES"
      >
        {challenges && isChallengesSuccess && (
          <ListCardChallenge challenges={challenges} onChallengeClick={handleSelectChallenge} />
        )}

        {isChallengesError && (
          <Text.Error>{t('DOMAIN.DASHBOARD.BLOCKS.CHALLENGES.ERROR')}</Text.Error>
        )}

        {isChallengesLoading && (
          <Box my={8}>
            <Loader>{t('DOMAIN.DASHBOARD.BLOCKS.CHALLENGES.LOADING')}</Loader>
          </Box>
        )}
      </DashboardBlock>

      <ChallengeAddSideview
        isOpen={isSideviewOpen}
        slug={selectedChallenge?.slug || sharedChallenge || ''}
        onClose={setSideviewClosed}
      />
    </>
  );
};
