import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Anchor, Box, Button, Form, SpacingGroup } from '@Components/ui';

import { ActivityFinishFormProps, ActivityFinishFormValues } from './ActivityFinishForm.props';

export const ActivityFinishForm = ({ isLoading, onSuccess, onClose }: ActivityFinishFormProps) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: ActivityFinishFormValues) => {
      onSuccess(values);
    },
    [onSuccess]
  );

  return (
    <Form.Form<ActivityFinishFormValues> onSubmit={handleSubmit}>
      <Box mb={3}>
        <Form.Input.Textarea
          label={t('DOMAIN.ACTIVITY.FINISH.DISLIKE_MODAL.FIELDS.REVIEW.LABEL')}
          data-testid="reviewInput"
          options={{
            placeholder: t('DOMAIN.ACTIVITY.FINISH.DISLIKE_MODAL.FIELDS.REVIEW.PLACEHOLDER')
          }}
          name="review"
        />
      </Box>

      <SpacingGroup size="xxl">
        <Button
          icon="arrow-right"
          isLoading={isLoading}
          type="submit"
          color="dark"
          data-testid="submitButton"
        >
          {t('DOMAIN.ACTIVITY.FINISH.FINISH_MODAL.SUBMIT')}
        </Button>

        {onClose && <Anchor onClick={onClose}>{t('COMMON.GLOBALS.CANCEL')}</Anchor>}
      </SpacingGroup>
    </Form.Form>
  );
};
