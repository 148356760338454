import cn from 'classnames';
import Slider from 'rc-slider';
import { CSSProperties, forwardRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'rc-slider/assets/index.css';

import { Icon, Text } from '@Components/ui';
import { useBoolean } from '@Hooks/common';

import styles from './InputSlider.module.scss';
import { InputSliderProps } from './InputSlider.props';
import { InputSliderHandler, InputSliderLabels, InputSliderTrack } from './internal';

export const InputSlider = forwardRef(
  (
    {
      onChange,
      onBlur,
      className,
      isError,
      isValid,
      leftLabel,
      rightLabel,
      isDisabled,
      marks,
      isCenter,
      isSmiley,
      isSmall,
      ...props
    }: InputSliderProps,
    ref?: any
  ) => {
    const [percentage, setPercentage] = useState(props.value);
    const isTouched = useBoolean(!!percentage);
    const { t } = useTranslation();
    const isStepped = !!marks;

    const classNames = cn(styles.InputSlider, className, {
      [styles.isError]: isError,
      [styles.isValid]: isValid,
      [styles.isDisabled]: isDisabled,
      [styles.isCenter]: isCenter,
      [styles.isCenterLeft]: isCenter && Number(percentage) < 50,
      [styles.isCenterRight]: isCenter && Number(percentage) > 50,
      [styles.isDefault]: !isCenter && !isStepped,
      [styles.isStepped]: isStepped,
      [styles.isSmiley]: isSmiley,
      [styles.isSmall]: isSmall
    });

    const handleChange = useCallback((value: number) => {
      setPercentage(value);
    }, []);

    const handleAfterChange = useCallback(() => {
      if (onChange) {
        onChange(percentage ?? 0);
      }

      if (onBlur) {
        onBlur();
      }
    }, [onBlur, onChange, percentage]);

    const handleBeforeChange = useCallback(() => {
      isTouched.setTrue();
    }, [isTouched]);

    const formattedMarks = useMemo(() => {
      if (marks) {
        return marks.reduce(
          (
            total: {
              [key: string]: {
                label: string;
                style?: CSSProperties;
              };
            },
            current,
            idx
          ) => {
            const percentPerItem = 100 / (marks.length - 1);
            const currentPercentage = percentPerItem * idx;

            total[currentPercentage] = {
              label: current,
              style: {
                opacity: currentPercentage === percentage ? '1' : '0',
                marginBottom: '-20px'
              }
            };

            return total;
          },
          {}
        );
      }

      return {};
    }, [marks, percentage]);

    return (
      <div className={classNames}>
        <div
          className={cn(styles.Placeholder, {
            [styles.isVisible]: !isTouched.value
          })}
        >
          {isCenter && <Icon icon={['fas', 'arrow-left']} size="sm" />}

          <Text.Small>{t('COMPONENTS.INPUT_SLIDER.DRAG')}</Text.Small>

          <Icon icon={['fas', 'arrow-right']} size="sm" />
        </div>

        <div className={styles.Slider}>
          <Slider
            value={percentage}
            min={0}
            marks={formattedMarks}
            step={marks && null}
            max={100}
            disabled={isDisabled}
            defaultValue={isCenter ? 50 : 0}
            startPoint={isCenter ? 50 : 0}
            onAfterChange={handleAfterChange}
            handle={({ value, dragging }) => (
              <InputSliderHandler
                value={value}
                isDragging={dragging}
                isSmiley={isSmiley}
                isValid={isValid}
                isError={isError}
                isDisabled={isDisabled}
                isSmall={isSmall}
                isTouched={isTouched.value}
                hasMarks={!!marks}
              />
            )}
            onChange={handleChange}
            onBeforeChange={handleBeforeChange}
            ref={ref}
          />

          <InputSliderTrack marks={marks} isCenter={isCenter} isValid={isValid} isError={isError} />
        </div>

        <InputSliderLabels
          leftLabel={leftLabel}
          rightLabel={rightLabel}
          marks={marks}
          isError={isError}
          isValid={isValid}
          isDisabled={isDisabled}
          isSmall={isSmall}
        />
      </div>
    );
  }
);
