import { useInfiniteQuery } from 'react-query';

import { PAGE_SIZE } from '@Constants/configs';
import { SortDirection } from '@Constants/enums';
import { TodoListRequest, todoService } from '@Services/Todo';

export const useTodoList = (params?: TodoListRequest) => {
  return useInfiniteQuery(
    [todoService.getEndpoint(), params],
    ({ pageParam = 1 }) => {
      return todoService.getList({
        ...params,

        page: pageParam,
        perPage: params?.perPage || PAGE_SIZE,
        sortBy: params?.sortBy ?? 'pinnedAt', // API has incorrect default sorting
        sortDirection: params?.sortDirection ?? SortDirection.DESC
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page + 1 <= lastPage.totalPages) {
          return lastPage.page + 1;
        }
      }
    }
  );
};
