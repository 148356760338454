import cn from 'classnames';

import styles from './H1.module.scss';
import { H1Props } from './H1.props';

export const H1 = ({ className, children, dangerousHtml, color, ...props }: H1Props) => {
  if (!children && !dangerousHtml) return null;

  const classNames = cn(styles.H1, className);

  const inlineStyle = {
    color
  };

  return (
    <h1
      className={classNames}
      dangerouslySetInnerHTML={!!dangerousHtml ? { __html: dangerousHtml } : undefined}
      style={inlineStyle}
      {...props}
    >
      {children}
    </h1>
  );
};
