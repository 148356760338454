import cn from 'classnames';

import styles from './SpacingGroup.module.scss';
import { SpacingGroupProps } from './SpacingGroup.props';

export const SpacingGroup = ({ children, size = 'sm', className }: SpacingGroupProps) => {
  const classNames = cn(styles.SpacingGroup, className, {
    [styles.isSizeSm]: size === 'sm',
    [styles.isSizeMd]: size === 'md',
    [styles.isSizeLg]: size === 'lg',
    [styles.isSizeXl]: size === 'xl',
    [styles.isSizeXxl]: size === 'xxl'
  });

  return <div className={classNames}>{children}</div>;
};
