import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SliderFullPage } from '@Components/ui';
import { useAuthRedirect } from '@Hooks/auth';
import { useApiError, useBoolean } from '@Hooks/common';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { LoginResponse } from '@Services/AuthService';
import { onboardingService } from '@Services/Onboarding';
import { trackMatomoAuthLogin, trackMatomoOnboardingAccountStep } from '@Utils/MatomoUtils';

import {
  LoginCredentialsSlide,
  LoginSmsCodeSlide,
  LoginVerifyEmailSlide,
  LoginVerifyLicenseCodeSlide,
  LoginVerifyPhoneSlide
} from './internal/components';

export const AuthLoginScreen = () => {
  const trackEvent = useMatomoTrackEvent();
  const [licenseCode, setLicenseCode] = useState<string | undefined>();
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [smsToken, setSmsToken] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  const { value: isEmailConfirmed, setValue: setEmailConfirmed } = useBoolean(false);
  const { value: isPhoneConfirmed, setValue: setPhoneConfirmed } = useBoolean(false);

  // Filled when redirected to login page when visiting authenticated page... So after login redirect user to this url
  const { state } = useLocation();
  const { redirect, hasRedirect } = useAuthRedirect(state ? String(state) : undefined);
  const { handleError } = useApiError();
  const navigate = useNavigate();

  const handleLoginSuccess = useCallback(
    (response: LoginResponse) => {
      setSmsToken(response.smsToken);
      setEmail(response.email);
      setPhone(response.phoneNumber);
      setUserId(response.userId);

      setEmailConfirmed(response.emailConfirmed);
      setPhoneConfirmed(response.phoneConfirmed);
    },
    [setEmailConfirmed, setPhoneConfirmed]
  );

  const handleEmailSuccess = useCallback(async () => {
    if (phone && userId) {
      try {
        await onboardingService.updatePhone({
          phoneNumber: phone,
          userId: userId
        });

        trackMatomoOnboardingAccountStep(trackEvent, 'Verifieer jouw e-mail');
      } catch (e) {
        handleError(e);
      }
    }
  }, [phone, userId, handleError, trackEvent]);

  const handlePhoneSuccess = useCallback(() => {
    trackMatomoOnboardingAccountStep(trackEvent, 'Verifieer jouw nummer');
  }, [trackEvent]);

  const handleSmsCodeSuccess = useCallback(() => {
    trackMatomoAuthLogin(trackEvent, 'Twee-factor-authenticatie');

    if (hasRedirect()) {
      redirect();
    } else {
      navigate('/');
    }
  }, [trackEvent, hasRedirect, redirect, navigate]);

  const handleVerifySuccess = useCallback((_licenseCode: string) => {
    setLicenseCode(_licenseCode);
  }, []);

  const handleUpdateEmailSuccess = useCallback((_email: string) => {
    setEmail(_email);
  }, []);

  const handleUpdatePhoneSuccess = useCallback((_phoneNumber: string) => {
    setPhone(_phoneNumber);
  }, []);

  return (
    <SliderFullPage data-testid="AuthLoginScreen">
      <LoginCredentialsSlide onSuccess={handleLoginSuccess} index={0} />

      {!isEmailConfirmed && <LoginVerifyLicenseCodeSlide onSuccess={handleVerifySuccess} />}

      {!isEmailConfirmed && !!userId && !!email && !!licenseCode && (
        <LoginVerifyEmailSlide
          email={email}
          licenseCode={licenseCode}
          userId={userId}
          onSuccess={handleEmailSuccess}
          onUpdate={handleUpdateEmailSuccess}
        />
      )}

      {!isPhoneConfirmed && !!userId && !!phone && (
        <LoginVerifyPhoneSlide
          userId={userId}
          phoneNumber={phone}
          onUpdate={handleUpdatePhoneSuccess}
          onSuccess={handlePhoneSuccess}
        />
      )}

      <LoginSmsCodeSlide
        onSuccess={handleSmsCodeSuccess}
        smsToken={smsToken}
        email={email}
        phoneSuffix={phone?.slice(-3)}
      />
    </SliderFullPage>
  );
};
