import cn from 'classnames';
import { forwardRef, useCallback } from 'react';

import { Medal } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useMilestoneClaim } from '@Hooks/milestone';
import { useUser } from '@Hooks/user';
import { getMilestoneIcon } from '@Utils/MilestoneUtils';

import styles from './Milestone.module.scss';
import { MilestoneProps } from './Milestone.props';

export const Milestone = forwardRef(({ milestone, className }: MilestoneProps, ref: any) => {
  const milestoneClaimMutation = useMilestoneClaim();
  const { handleError } = useApiError();
  const { likesCelebration } = useUser();

  const handleOpening = useCallback(async () => {
    try {
      await milestoneClaimMutation.mutateAsync({ id: milestone.id });
    } catch (e) {
      handleError(e);
    }
  }, [milestone.id, milestoneClaimMutation, handleError]);

  return (
    <div ref={ref} className={cn(styles.Milestone, className)}>
      <Medal
        title={milestone.name}
        description={milestone.description}
        icon={getMilestoneIcon(milestone.key)}
        state={milestone.state as any}
        onOpen={handleOpening}
        hasConfetti={likesCelebration}
      />
    </div>
  );
});
