import cn from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonIcon, Text } from '@Components/ui';
import { usePreferenceList, usePreferenceSubmit } from '@Hooks/preference';
import { PreferenceKey } from '@Services/Preference';
import { getPreference } from '@Utils/PreferenceUtils';

import styles from './DashboardWidget.module.scss';
import { DashboardWidgetProps } from './DashboardWidget.props';

export const DashboardWidget = ({ className }: DashboardWidgetProps) => {
  const { t } = useTranslation();
  const { data: preferences, isSuccess: isPreferencesSuccess } = usePreferenceList();
  const preferenceSubmitMutation = usePreferenceSubmit();

  const hiddenWidgets = useMemo(() => {
    if (preferences) {
      return getPreference(preferences, PreferenceKey.WIDGETS);
    }

    return [];
  }, [preferences]);

  const handleWidgetClick = useCallback(
    (widgetKey: string) => async () => {
      if (hiddenWidgets && hiddenWidgets.length > 0) {
        await preferenceSubmitMutation.mutateAsync({
          key: PreferenceKey.WIDGETS,
          values: hiddenWidgets?.filter((widget) => widget !== widgetKey)
        });
      }
    },
    [preferenceSubmitMutation, hiddenWidgets]
  );

  const hasWidgets = isPreferencesSuccess && hiddenWidgets && hiddenWidgets.length > 0;

  return (
    <div className={cn(styles.DashboardWidget, { [styles.hasWidgets]: hasWidgets }, className)}>
      <Text.H4 className={styles.Title}>{t('DOMAIN.DASHBOARD.WIDGET.TITLE')}</Text.H4>

      {!!hiddenWidgets &&
        hiddenWidgets.map((widget) => {
          return (
            <ButtonIcon
              key={widget}
              isDisabled={preferenceSubmitMutation.isLoading}
              className={styles.Widget}
              onClick={handleWidgetClick(widget)}
              icon="plus"
              label={t(`DOMAIN.DASHBOARD.BLOCKS.${widget}.TITLE` as any)}
            />
          );
        })}
    </div>
  );
};
