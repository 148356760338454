import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';

import { SelfTestPhasedOpenBlock, SelfTestRegularOpenBlock } from './internal/components';
import { SelfTestOpenBlockProps } from './SelfTestOpenBlock.props';

export const SelfTestOpenBlock = ({ selfTest, className }: SelfTestOpenBlockProps) => {
  if (selfTest.isPhased) {
    return <SelfTestPhasedOpenBlock className={className} selfTest={selfTest} />;
  }

  if (selfTest.status !== QuestionnaireSubmissionStatus.COMPLETED) {
    return <SelfTestRegularOpenBlock className={className} selfTest={selfTest} />;
  }

  return null;
};
