import { useTranslation } from 'react-i18next';

import { UserActivityUpdateForm } from '@Components/form';
import { Box, CallToAction, List, Text } from '@Components/ui';
import { TargetDirection } from '@Hooks/common';

import styles from './UserActivityBody.module.scss';
import { UserActivityBodyProps } from './UserActivityBody.props';

export const UserActivityBody = ({ activity }: UserActivityBodyProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.UserActivityBody}>
      <Box mb={4}>
        <Text.H3 className={styles.Title}>{t('DOMAIN.USER_ACTIVITY.DETAIL.BODY.TITLE')}</Text.H3>
        <Text.Large>{t('DOMAIN.USER_ACTIVITY.DETAIL.BODY.INTRO')}</Text.Large>
      </Box>

      <div className={styles.Fields}>
        <div>
          <Box mb={4}>
            <div className={styles.Title}>
              {!activity.completed && (
                <UserActivityUpdateForm
                  id={activity.id}
                  initialValue={activity.description}
                  fieldName="description"
                  translationName="DESCRIPTION"
                />
              )}

              <Text.H4>{t('DOMAIN.USER_ACTIVITY.DETAIL.BODY.DESCRIPTION')}</Text.H4>
            </div>

            <Text.Large>{activity.description}</Text.Large>
          </Box>

          <Box mb={4}>
            <div className={styles.Title}>
              {!activity.completed && (
                <UserActivityUpdateForm
                  id={activity.id}
                  initialValue={activity.expectation}
                  fieldName="expectation"
                  translationName="EXPECTATION"
                />
              )}

              <Text.H4>{t('DOMAIN.USER_ACTIVITY.DETAIL.BODY.EXPECTATION')}</Text.H4>
            </div>

            <Text.Large>{activity.expectation}</Text.Large>
          </Box>
        </div>

        <CallToAction
          isVisible
          isReversed
          isStatic
          direction={TargetDirection.LEFT}
          className={styles.CTA}
          label={t('DOMAIN.USER_ACTIVITY.DETAIL.BODY.CTA')}
        />
      </div>

      <Box>
        <Text.H4 className={styles.Title}>
          {t('DOMAIN.USER_ACTIVITY.DETAIL.BODY.APPROACH.TITLE')}
        </Text.H4>

        <Text.Large>{t('DOMAIN.USER_ACTIVITY.DETAIL.BODY.APPROACH.INTRO')}</Text.Large>

        <List.Unordered className={styles.List}>
          {t('DOMAIN.USER_ACTIVITY.DETAIL.BODY.APPROACH.LIST', {
            returnObjects: true
          }).map((item, idx) => {
            return <Text.Large key={idx}>{item}</Text.Large>;
          })}
        </List.Unordered>
      </Box>
    </div>
  );
};
