import { InputOption } from '@Constants/interfaces';
import {
  IQuestionnaireAnswer,
  QuestionnaireSubmissionSubmitPersonaResponse,
  QuestionnaireSubmissionSubmitResponse,
  QuestionnaireSubmissionSubmitSelfTestResponse
} from '@Services/QuestionnaireSubmission';

export const isSelfTestResponse = (
  response: QuestionnaireSubmissionSubmitResponse
): response is QuestionnaireSubmissionSubmitSelfTestResponse => {
  return 'themes' in response === true;
};

export const isPersonaResponse = (
  response: QuestionnaireSubmissionSubmitResponse
): response is QuestionnaireSubmissionSubmitPersonaResponse => {
  return 'themes' in response === false;
};

export const answersToMarks = (answers: IQuestionnaireAnswer[]) => {
  return answers.map((answer) => {
    return answer.text;
  });
};

export const answersToOptions = (answers: IQuestionnaireAnswer[]): InputOption[] => {
  return answers.map((answer) => {
    return {
      value: answer.id,
      label: answer.text,
      description: answer.description ?? undefined
    };
  });
};

export const selectedAnswerId = (answers: IQuestionnaireAnswer[]) => {
  return answers.find((answer) => answer.selected)?.id;
};

export const selectedAnswersIds = (answers: IQuestionnaireAnswer[]) => {
  return answers
    .filter((answer) => {
      return answer.selected;
    })
    .map((answer) => {
      return answer.id;
    });
};

export const selectedAnswerToPercentage = (answers: IQuestionnaireAnswer[]) => {
  const percentagePerStep = 100 / (answers.length - 1);
  const selectedAnswerIdx = answers.findIndex((answer) => answer.selected);

  if (selectedAnswerIdx !== -1) {
    return Math.round(percentagePerStep * selectedAnswerIdx);
  }
};

export const percentageToSelectedAnswer = (answers: any[], slideValue: number) => {
  const percentagePerStep = 100 / (answers.length - 1);

  const selectedAnswer = slideValue > 0 ? Math.round(slideValue / percentagePerStep) : 0;

  return answers[selectedAnswer];
};

export const hasCompletedAPhase = (selftest?: QuestionnaireSubmissionSubmitSelfTestResponse) => {
  return selftest?.isPhased && (selftest?.completedPhases ?? 0) > 0;
};
