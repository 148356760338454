import { Fragment } from 'react';

import { ListFaqItem } from '@Components/list';
import { Text } from '@Components/ui';

import { getTotalResultCount } from '../../utils';
import { NoResultsFound } from '../NoResultsFound';
import styles from './ArchiveTab.module.scss';
import { ArchiveTabProps } from './ArchiveTab.props';

export const ArchiveTab = ({ searchString, items }: ArchiveTabProps) => {
  const resultCount = getTotalResultCount(items);

  return (
    <>
      {resultCount > 0 ? (
        items.map((category) => {
          if (category.items.length > 0) {
            return (
              <Fragment key={category.id}>
                <Text.H3 className={styles.CategoryName}>{category.title}</Text.H3>

                <ListFaqItem category={category} searchString={searchString} />
              </Fragment>
            );
          }
        })
      ) : (
        <NoResultsFound />
      )}
    </>
  );
};
