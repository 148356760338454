import cn from 'classnames';

import { HighlightedChallenge } from '@Components/container';

import styles from './ListHighlightedChallenge.module.scss';
import { ListHighlightedChallengeProps } from './ListHighlightedChallenge.props';

export const ListHighlightedChallenge = ({
  challenges,
  className
}: ListHighlightedChallengeProps) => {
  return (
    <div className={cn(styles.ListHighlightedChallenge, className)}>
      {challenges.map((challenge) => {
        return (
          <HighlightedChallenge
            className={styles.Challenge}
            key={challenge.id}
            challenge={challenge}
          />
        );
      })}
    </div>
  );
};
