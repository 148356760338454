import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Text } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

import styles from './TimelineCTA.module.scss';
import { TimelineCTAProps } from './TimelineCTA.props';

export const TimelineCTA = ({ className }: TimelineCTAProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.TimelineCTA, className)}>
      <Text.Bold className={styles.Title}>{t('COMPONENTS.TIMELINE.CTA.TITLE')}</Text.Bold>

      <Text.Normal className={styles.Description}>
        {t('COMPONENTS.TIMELINE.CTA.DESCRIPTION')}
      </Text.Normal>

      <Button
        to={AppRoutes.getInsightChallengesAdd().to}
        icon="arrow-right"
        className={styles.Button}
      >
        {t('COMPONENTS.TIMELINE.CTA.BUTTON')}
      </Button>

      <Text.Small className={styles.SubText}>
        {t('COMPONENTS.TIMELINE.CTA.SUBTEXT') + ' '}

        <Link to={AppRoutes.getActivities().to}>{t('COMPONENTS.TIMELINE.CTA.SUBLINK')}</Link>
      </Text.Small>
    </div>
  );
};
