import { isAfter } from 'date-fns';
import { t } from 'i18next';

import { Box, Text } from '@Components/ui';
import { getToday, shortDate } from '@Utils/DateUtils';

import { ReportIntroUnavailablePhasedProps } from './ReportIntroUnavailablePhased.props';

export const ReportIntroUnavailablePhased = ({
  nextPhaseAvailableAt
}: ReportIntroUnavailablePhasedProps) => {
  const isNextPhaseAvailable = isAfter(getToday(), new Date(nextPhaseAvailableAt));

  return (
    <>
      <Box mb={2}>
        <Text.Large>
          {isNextPhaseAvailable
            ? t('DOMAIN.REPORT.UNAVAILABLE.PHASED.NEW_PHASE_AVAILABLE.INTRO')
            : t('DOMAIN.REPORT.UNAVAILABLE.PHASED.NEW_PHASE_UNAVAILABLE.INTRO')}
        </Text.Large>
      </Box>

      {isNextPhaseAvailable ? (
        <Text.Large>
          {t('DOMAIN.REPORT.UNAVAILABLE.PHASED.NEW_PHASE_AVAILABLE.DESCRIPTION')}
        </Text.Large>
      ) : (
        <>
          <Text.Large>
            {t('DOMAIN.REPORT.UNAVAILABLE.PHASED.NEW_PHASE_UNAVAILABLE.DESCRIPTION', {
              date: shortDate(new Date(nextPhaseAvailableAt))
            })}
          </Text.Large>
        </>
      )}
    </>
  );
};
