import { useCallback } from 'react';

import { Motivator } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useUserTooltipHide } from '@Hooks/userTooltip';
import { getMotivatorHref } from '@Utils/MotivatorUtils';

import { MotivatorTooltipProps } from './MotivatorTooltip.props';

export const MotivatorTooltip = ({ tooltip, className }: MotivatorTooltipProps) => {
  const userTooltipHideMutation = useUserTooltipHide();
  const { handleError } = useApiError();

  const handleClick = useCallback(
    (opened: boolean) => async () => {
      try {
        await userTooltipHideMutation.mutateAsync({
          id: tooltip.id,
          title: tooltip.content,
          opened
        });
      } catch (e) {
        handleError(e);
      }
    },
    [userTooltipHideMutation, tooltip.id, tooltip.content, handleError]
  );

  return (
    <Motivator
      message={tooltip.content}
      link={{
        onClick: handleClick(true),
        href: getMotivatorHref(tooltip.buttonLink),
        label: tooltip.buttonText
      }}
      onClose={handleClick(false)}
      className={className}
    />
  );
};
