import { useTranslation } from 'react-i18next';

import { HighlightedChallenge } from '@Components/container';
import { Loader, Text } from '@Components/ui';
import { useUserChallenge } from '@Hooks/userChallenge';

import styles from './ActivityChallenge.module.scss';
import { ActivityChallengeProps } from './ActivityChallenge.props';

export const ActivityChallenge = ({ challenge }: ActivityChallengeProps) => {
  const { t } = useTranslation();
  const { data, isLoading, isSuccess, isError } = useUserChallenge({
    id: challenge.slug
  });

  return (
    <div className={styles.ActivityChallenge}>
      <Text.H3 className={styles.Title}>
        {t('DOMAIN.ACTIVITY.DETAIL.PART_OF_ONGOING_CHALLENGE')}
      </Text.H3>

      {isLoading && <Loader>{t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.LOADING')}</Loader>}

      {isError && <Loader>{t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.ERROR')}</Loader>}

      {isSuccess && data && <HighlightedChallenge challenge={data} className={styles.Challenge} />}
    </div>
  );
};
