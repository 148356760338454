import { Poll } from '@Components/container';
import { usePollCurrent } from '@Hooks/poll';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import { DashboardPollProps } from './DashboardPoll.props';

export const DashboardPoll = ({ className }: DashboardPollProps) => {
  const { data: poll, isSuccess: isPollSuccess } = usePollCurrent();

  return (
    <DashboardBlock widgetKey="POLL" hasTitle={false} className={className}>
      {!!poll && isPollSuccess && <Poll poll={poll} hasImage />}
    </DashboardBlock>
  );
};
