import { useQuery, useQueryClient } from 'react-query';

import { userService } from '@Services/User';
import { userChallengeService } from '@Services/UserChallenge';

export const useUserChallengeArchive = () => {
  const queryClient = useQueryClient();

  return useQuery(
    [userChallengeService.getEndpoint(), 'archive'],
    () => {
      return userChallengeService.getArchive();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([userService.getEndpoint()]);
      }
    }
  );
};
