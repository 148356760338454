import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import logoArbFooter from '@Assets/images/logo-arb-footer.svg';
import logoFocusFooter from '@Assets/images/logo-focus-footer.png';
import logoHccFooter from '@Assets/images/logo-hcc-footer.svg';
import logoMenFooter from '@Assets/images/logo-men-footer.svg';
import { Box, SpacingGroup, Text } from '@Components/ui';
import { Platform } from '@Constants/enums';
import { usePlatform } from '@Hooks/common';

import styles from './Footer.module.scss';
import { FooterProps } from './Footer.props';

export const Footer = ({ className }: FooterProps) => {
  const { strings, assets, platform } = usePlatform();
  const { t } = useTranslation();

  return (
    <div className={cn(styles.Footer, className)}>
      <Box mb={4} className={styles.Logo}>
        <img src={assets.logoFooter} />
      </Box>

      <Box className={styles.Contact} mb={1.5}>
        <Text.H3>
          <a href={`tel:${strings.phone}`} target="_blank">
            {strings.phone}
          </a>
        </Text.H3>

        <Text.H3>
          <a href={`mailto:${strings.mail}`} target="_blank">
            {strings.mail}
          </a>
        </Text.H3>
      </Box>

      <Box mb={6}>
        <Text.Normal>{strings.address}</Text.Normal>
        <Text.Normal>{strings.postcalCode}</Text.Normal>
      </Box>

      {platform === Platform.KOM_VERDER && (
        <Box mb={4}>
          <SpacingGroup className={styles.Logos} size="xxl">
            <Text.Normal>{t('COMPONENTS.FOOTER.ELEMENT_OF')}</Text.Normal>

            <img src={logoArbFooter} />
            <img src={logoHccFooter} />
            <img src={logoMenFooter} />
            <img src={logoFocusFooter} />
          </SpacingGroup>
        </Box>
      )}

      <SpacingGroup className={styles.Privacy} size="lg">
        <a href={strings.privacyUrl} target="_blank">
          {t('COMPONENTS.FOOTER.PRIVACY_STATEMENT')}
        </a>

        <a href={strings.termsUrl} target="_blank">
          {t('COMPONENTS.FOOTER.USER_TERMS')}
        </a>
      </SpacingGroup>
    </div>
  );
};
