import { IconName } from '@fortawesome/fontawesome-svg-core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { SpacingGroup, Tag, Text, ThumbnailTextRow } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { getActivityThumbnailSrc, getActivityTo, isUserActivity } from '@Utils/ActivityUtils';
import { toLocaleString } from '@Utils/StringUtils';

import styles from './ActivityItemLarge.module.scss';
import { ActivityItemLargeProps } from './ActivityItemLarge.props';

export const ActivityItemLarge = ({
  activity,
  pinState,
  onPinClick,
  withAnchor = true,
  canClickToPin = false,
  className,
  ...props
}: ActivityItemLargeProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();

  const title = isUserActivity(activity)
    ? t('DOMAIN.USER_ACTIVITY.TITLE', { title: activity.title })
    : activity.title;

  return (
    <ThumbnailTextRow
      className={cn(styles.ActivityItemLarge, className)}
      title={title}
      description={activity.excerpt}
      image={getActivityThumbnailSrc(activity)}
      hasPlaceholder={true}
      to={withAnchor ? getActivityTo(activity) : undefined}
      isLarge
      imageIcon={activity.type?.[0].icon as IconName}
      isCompleted={activity.completed}
      onPinClick={onPinClick}
      pinState={pinState}
      canClickToPin={canClickToPin}
      {...props}
    >
      <SpacingGroup size="md" className={styles.ChildRow}>
        {isUserActivity(activity) && (
          <Tag label={t('DOMAIN.USER_ACTIVITY.SINGULAR')} color={color.black.default} />
        )}

        <Tag icon="star" label={toLocaleString(activity.points)} color={color.primary.default} />

        {!!activity.duration && (
          <Text.Micro className={styles.Duration}>{activity.duration[0].body}</Text.Micro>
        )}
      </SpacingGroup>
    </ThumbnailTextRow>
  );
};
