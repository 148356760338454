import cn from 'classnames';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonMobileMenu } from '@Components/layout';
import { Anchor, Button, Icon, MenuItem, Text } from '@Components/ui';
import {
  useBoolean,
  useLocationEffect,
  useLockedBody,
  usePersistSearchParam,
  usePlatform,
  useScrolling
} from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';

import styles from './HeaderUnauthenticated.module.scss';
import { HeaderUnauthenticatedProps } from './HeaderUnauthenticated.props';
import { useMenuItemsUnauthenticated } from './internal/hooks';

export const HeaderUnauthenticated = forwardRef(
  ({ className }: HeaderUnauthenticatedProps, ref: any) => {
    const { t } = useTranslation();
    const { assets } = usePlatform();

    const {
      value: isMobileMenuOpen,
      setTrue: setMobileMenuOpen,
      setFalse: setMobileMenuClosed
    } = useBoolean(false);

    const { getPersistedSearchParam } = usePersistSearchParam('token');
    const token = getPersistedSearchParam();

    useLockedBody(isMobileMenuOpen);
    useLocationEffect(setMobileMenuClosed);

    const { isScrolling } = useScrolling();
    const menuItems = useMenuItemsUnauthenticated();

    return (
      <>
        <div
          className={cn(styles.Overlay, { [styles.isOpen]: isMobileMenuOpen })}
          onClick={setMobileMenuClosed}
        />

        <div
          ref={ref}
          className={cn(
            styles.HeaderUnauthenticated,
            { [styles.isScrolling]: isScrolling },
            className
          )}
        >
          <Anchor to={AppRoutes.getDashboard().to} className={styles.Logo}>
            <img src={assets.logo.default} alt={AppRoutes.getDashboard().label} />
          </Anchor>

          <ButtonMobileMenu onClick={setMobileMenuOpen} className={styles.ButtonMobileMenu} />

          <div className={cn(styles.Menu, { [styles.isOpen]: isMobileMenuOpen })}>
            <Text.H5 className={styles.Close} onClick={setMobileMenuClosed}>
              {t('COMPONENTS.SIDEBAR.CLOSE_MENU')}

              <Icon icon="times" size="lg" className={styles.Icon} />
            </Text.H5>

            <div className={styles.MenuItems}>
              {menuItems.map((menuItem) => {
                return (
                  <MenuItem
                    key={`${menuItem.label}${menuItem.to}`}
                    {...menuItem}
                    className={styles.MenuItem}
                    isOpaque
                    hasTag={false}
                  />
                );
              })}
            </div>

            <div className={styles.AuthSection}>
              <MenuItem {...AppRoutes.getLogin()} className={styles.MenuItem} isOpaque />

              <Button to={AppRoutes.getRegistration(token ? 'B' : 'A').to} icon="arrow-right">
                {AppRoutes.getRegistration().label}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
);
