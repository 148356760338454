import { useTranslation } from 'react-i18next';

import image from '@Assets/images/Evernote.png';
import { Text } from '@Components/ui';
import { BlockTip } from '@Components/ui/BlockTip/BlockTip';

import { PreprElementEvernoteProps } from './PreprElementEvernote.props';

export const PreprElementEvernote = ({ element }: PreprElementEvernoteProps) => {
  const { t } = useTranslation();

  return (
    <BlockTip
      title={t('DOMAIN.ACTIVITY.EVERNOTE.TITLE')}
      imageUri={image}
      button={
        element.button?.url && element.button?.body
          ? {
              href: element.button.url,
              label: element.button.body
            }
          : undefined
      }
    >
      <Text.Large>
        {t('DOMAIN.ACTIVITY.EVERNOTE.BODY', {
          returnObjects: true
        }).map((item, idx) => {
          return <p key={idx}>{item}</p>;
        })}
      </Text.Large>
    </BlockTip>
  );
};
