import { useEffect } from 'react';

import { useBoolean } from './useBoolean';
import { useTimeouts } from './useTimeout';

export const useAnimatedToggle = (shouldShow: boolean, timeoutDuration: number) => {
  const { value: isShowing, setTrue: setShowing, setFalse: setHidden } = useBoolean();

  const { addTimeout } = useTimeouts();

  useEffect(() => {
    if (!shouldShow) {
      const timeout = setTimeout(() => {
        setHidden();
      }, timeoutDuration);

      return () => clearTimeout(timeout);
    } else {
      setShowing();
    }
  }, [shouldShow, setHidden, setShowing, addTimeout, timeoutDuration]);

  return { isShowing };
};
