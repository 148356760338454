import { View } from '@react-pdf/renderer';

import { useResultColorMap } from '@Hooks/userTheme';
import { getPercentageForResult } from '@Utils/ResultUtils';

import { ThemeProgressProps } from './ThemeProgress.props';
import { styles } from './ThemeProgress.styles';

export const ThemeProgress = ({ themeResult, style }: ThemeProgressProps) => {
  const { getColorForResult } = useResultColorMap();

  const percentage = getPercentageForResult(themeResult);
  const progressColor = getColorForResult(themeResult);

  return (
    <View style={{ ...styles.ThemeProgress, ...style }}>
      <View style={styles.Track} />
      <View
        style={{
          ...styles.Progress,
          width: `${percentage}%`,
          backgroundColor: progressColor
        }}
      />
    </View>
  );
};
