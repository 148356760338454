import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { HTMLMeasurements } from '@Constants/interfaces';
import { getElementMeasurements } from '@Utils/ElementUtils';

import { useResizeObserver } from './useResizeObserver';

export const useMeasurements = (element: HTMLElement | null) => {
  const [measurements, setMeasurements] = useState<HTMLMeasurements>(
    getElementMeasurements(element)
  );
  const elementRef = useRef<HTMLElement | null>(element);

  const updateMeasurements = useCallback(() => {
    setMeasurements(getElementMeasurements(element));
  }, [element]);

  useResizeObserver(updateMeasurements, elementRef);

  useLayoutEffect(() => {
    updateMeasurements();
  }, [updateMeasurements]);

  return measurements;
};
