import { useCallback, useEffect, useState } from 'react';

import { useBoolean } from './useBoolean';

export const useCountdown = (countdown: number) => {
  const [count, setCount] = useState(0);
  const { value: hasStarted, setTrue: startCountdown, setFalse: reset } = useBoolean(false);

  const start = useCallback(() => {
    setCount(countdown);
    startCountdown();
  }, [countdown, startCountdown]);

  useEffect(() => {
    if (hasStarted) {
      const interval = setInterval(() => {
        setCount((oldCount) => {
          if (oldCount === 1) {
            reset();
            clearInterval(interval);
          }

          return oldCount - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [hasStarted, reset, count]);

  return {
    start,
    reset,
    count
  };
};
