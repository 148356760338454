import cn from 'classnames';

import { Anchor, CountNotifier, Icon } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './ButtonNotification.module.scss';
import { ButtonNotificationProps } from './ButtonNotification.props';

export const ButtonNotification = ({ count, to }: ButtonNotificationProps) => {
  const { color } = usePlatform();

  return (
    <CountNotifier count={count} className={styles.CountNotifier}>
      <Anchor to={to} className={cn(styles.ButtonNotification, { [styles.isEmpty]: count === 0 })}>
        <Icon
          icon={count === 0 ? 'envelope-open' : 'envelope'}
          size="lg"
          color={color.black.default}
        />
      </Anchor>
    </CountNotifier>
  );
};
