import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@Components/layout';
import { Anchor, Box, Button, SpacingGroup, Text } from '@Components/ui';
import { AUTOMATIC_LOGOUT_COUNTDOWN_MINUTES } from '@Constants/configs';
import { useAuthLogout } from '@Hooks/auth';
import { useBoolean, useCountdown, useWindowActivityListener } from '@Hooks/common';

import { InactiveLogoutPopupProps } from './InactiveLogoutPopup.props';

export const InactiveLogoutPopup = ({ className }: InactiveLogoutPopupProps) => {
  const { t } = useTranslation();
  const { isActive } = useWindowActivityListener();
  const authLogoutMutation = useAuthLogout();
  const { count, start, reset } = useCountdown(AUTOMATIC_LOGOUT_COUNTDOWN_MINUTES * 60);
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);

  const handleLogout = useCallback(async () => {
    setModalClosed();
    reset();

    await authLogoutMutation.mutateAsync();
  }, [authLogoutMutation, reset, setModalClosed]);

  useEffect(() => {
    if (!isActive) {
      setModalOpen();
      start();
    }
  }, [isActive, setModalOpen, start]);

  useEffect(() => {
    if (isModalOpen && count === 0) {
      handleLogout();
    }
  }, [count, isModalOpen, t, handleLogout]);

  const handleSubmit = useCallback(() => {
    setModalClosed();
    reset();
  }, [setModalClosed, reset]);

  return (
    <Modal
      title={t('COMPONENTS.INACTIVE_LOGOUT_POPUP.TITLE')}
      isOpen={isModalOpen}
      onClose={handleSubmit}
      className={className}
    >
      <Box mb={3}>
        <Text.Large>
          {t('COMPONENTS.INACTIVE_LOGOUT_POPUP.DESCRIPTION', {
            seconds: count
          })}
        </Text.Large>
      </Box>

      <SpacingGroup size="xxl">
        <Button isLoading={authLogoutMutation.isLoading} onClick={handleSubmit} color="dark">
          {t('COMPONENTS.INACTIVE_LOGOUT_POPUP.SUBMIT')}
        </Button>

        <Anchor onClick={handleLogout} isDisabled={authLogoutMutation.isLoading}>
          {t('COMPONENTS.INACTIVE_LOGOUT_POPUP.LOGOUT')}
        </Anchor>
      </SpacingGroup>
    </Modal>
  );
};
