import { IS_PRODUCTION } from '@Constants/configs';
import { IPreprAudio, IPreprImage } from '@Constants/preprTypes';

export const getImageSrc = (image: IPreprImage[]) => {
  return image?.[0].cdnFiles?.[0].url;
};

export const getAudioSrc = (audio: IPreprAudio[]) => {
  return audio?.[0].cdn_files?.[0].url;
};

export const trackView = (id: string) => {
  if (IS_PRODUCTION) {
    opix('event', 'View', {
      publication: id
    });
  }
};
