import { useQuery, useQueryClient } from 'react-query';

import { onboardingService } from '@Services/Onboarding';
import { userService } from '@Services/User';

export const useOnboardingGetPersonaQuestionnaire = () => {
  const queryClient = useQueryClient();

  return useQuery(
    [onboardingService.getEndpoint(), 'persona', 'questionnaire'],
    () => {
      return onboardingService.getPersonaQuestionnaire();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
