import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CountNotifier, Icon, Text } from '@Components/ui';
import { useBoolean } from '@Hooks/common';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { ActivityListRequest } from '@Services/Activity';
import { removeEmptyOrUndefinedFromObject } from '@Utils/CommonUtils';
import { trackMatomoActivitySearch } from '@Utils/MatomoUtils';
import { toLocaleString } from '@Utils/StringUtils';

import styles from './ActivityFilter.module.scss';
import { ActivityFilterProps } from './ActivityFilter.props';
import {
  ActivityFilterSearch,
  ActivityFilterSort,
  ActivityFilterSortValues,
  ActivityFilterTags,
  ActivityFilterTagsValues
} from './internal/components';

export const ActivityFilter = ({ onChange, count }: ActivityFilterProps) => {
  const { t } = useTranslation();
  const trackEvent = useMatomoTrackEvent();

  const { value: isSearch, toggle: handleToggleSearch } = useBoolean(false);
  const { value: isFilter, toggle: handleToggleFilter } = useBoolean(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [tags, setTags] = useState<ActivityFilterTagsValues>();
  const [search, setSearch] = useState<string>();
  const [sort, setSort] = useState<ActivityFilterSortValues>();

  const filterAmount = Object.values(tags || {}).reduce((total: number, current: string[]) => {
    if (Array.isArray(current)) {
      total += current.length;
    }
    return total;
  }, 0);

  useEffect(() => {
    if (isLoaded) {
      const params = removeEmptyOrUndefinedFromObject({
        ...tags,
        searchPhrase: isSearch && search,
        sortBy: sort?.sortBy,
        sortDirection: sort?.sortDirection
      }) as ActivityListRequest;

      if (!isEmpty(params)) {
        onChange(params);
      }
    }
  }, [onChange, search, isSearch, sort, tags, isLoaded]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);

      trackMatomoActivitySearch(trackEvent, value);
    },
    [trackEvent]
  );

  const handleSort = useCallback((values: ActivityFilterSortValues) => {
    setSort(values);
  }, []);

  const handleTags = useCallback((value: ActivityFilterTagsValues) => {
    if (value) {
      setTags(value);
      setIsLoaded(true);
    }
  }, []);

  return (
    <div className={styles.ActivityFilter}>
      <ActivityFilterSearch isVisible={isSearch} onChange={handleSearch} />

      <div className={styles.Controls}>
        <div className={styles.Filters}>
          <div className={styles.Tags}>
            <CountNotifier count={filterAmount}>
              <Button color="dark" onClick={handleToggleFilter} data-testid="toggleFilter">
                {isFilter
                  ? t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.HIDE')
                  : t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.SHOW')}
              </Button>
            </CountNotifier>
          </div>

          <Text.Small
            className={styles.Count}
            dangerousHtml={t('DOMAIN.ACTIVITY.OVERVIEW.FILTER.TAGS.ITEMS', {
              count: toLocaleString(count || 0)
            })}
          />
        </div>

        <div className={styles.SortSearch}>
          <div className={styles.Sort}>
            <ActivityFilterSort onChange={handleSort} />
          </div>

          <div className={styles.Search}>
            <Button color="dark-reversed" onClick={handleToggleSearch} data-testid="toggleSearch">
              <Icon icon={['fas', isSearch ? 'times' : 'search']} size="lg" />
            </Button>
          </div>
        </div>
      </div>

      <ActivityFilterTags isVisible={isFilter} onChange={handleTags} />
    </div>
  );
};
