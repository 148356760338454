import cn from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Dot, ProgressLabeled } from '@Components/ui';

import styles from './DashboardWelcomeHead.module.scss';
import { DashboardWelcomeHeadProps } from './DashboardWelcomeHead.props';

export const DashboardWelcomeHead = ({ className }: DashboardWelcomeHeadProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  return (
    <div className={cn(styles.DashboardWelcomeHead, className)}>
      <div className={styles.Inner}>
        <Dot className={styles.Dot} />

        <div ref={ref}>
          <ProgressLabeled
            label={t('COMPONENTS.TOURDOT.WELCOME.POINTS')}
            current={25}
            hasTooltip={false}
            total={100}
          />
        </div>
      </div>
    </div>
  );
};
