import { BaseService, dePortalenService } from '@Services/common';

import { SecurityQuestionResponse } from './SecurityQuestionService.props';

export class SecurityQuestionService extends BaseService {
  all = async () => {
    const response = await this.get<SecurityQuestionResponse>('');

    return response.data;
  };
}

export const securityQuestionService = new SecurityQuestionService(
  dePortalenService.getAxiosInstance(),
  '/securityquestions'
);
