import cn from 'classnames';
import { useCallback } from 'react';
import { ToastContainerProps, useToastContainer } from 'react-toastify';
import { Toast } from 'react-toastify/dist/types';

import { Alert } from '@Components/ui';

import styles from './ToastContainer.module.scss';

export const ToastContainer = (toastContainerProps: ToastContainerProps) => {
  const { getToastToRender, containerRef } = useToastContainer(toastContainerProps);

  const toasts = useCallback((toastList: Toast[]) => {
    return toastList.map(({ content, props }) => <Alert {...props}>{content}</Alert>);
  }, []);

  return (
    <div ref={containerRef} className={cn([styles.ToastContainer])}>
      {getToastToRender((_, toastList) => {
        return toasts(toastList);
      })}
    </div>
  );
};
