import cn from 'classnames';

import { AppError } from '@Constants/enums';
import { IPreprElement } from '@Constants/preprTypes';

import {
  PreprElementAccordion,
  PreprElementButton,
  PreprElementContent,
  PreprElementEvernote,
  PreprElementH3,
  PreprElementH4,
  PreprElementImage,
  PreprElementLineBreak,
  PreprElementList,
  PreprElementPodcast,
  PreprElementRoadmap,
  PreprElementTip
} from './internal/components';
import styles from './PreprRenderer.module.scss';
import { PreprRendererProps } from './PreprRenderer.props';

export const PreprRenderer = ({ activity, content, className }: PreprRendererProps) => {
  const getPreprElement = (element: IPreprElement, index: number) => {
    if (element.typeName === 'Accordion') {
      return <PreprElementAccordion key={element.id + index} element={element} />;
    }

    if (element.typeName === 'Button') {
      return <PreprElementButton key={element.id + index} element={element} />;
    }

    if (element.typeName === 'Content') {
      return <PreprElementContent key={element.id + index} element={element} />;
    }

    if (element.typeName === 'H3') {
      return <PreprElementH3 key={element.id + index} element={element} />;
    }

    if (element.typeName === 'H4') {
      return <PreprElementH4 key={element.id + index} element={element} />;
    }

    if (element.typeName === 'LineBreak') {
      return <PreprElementLineBreak key={element.id + index} />;
    }

    if (element.typeName === 'Image') {
      return <PreprElementImage key={element.id + index} element={element} />;
    }

    if (element.typeName === 'List') {
      return <PreprElementList key={element.id + index} element={element} />;
    }

    if (element.typeName === 'Podcast') {
      return <PreprElementPodcast key={element.id + index} element={element} />;
    }

    if (element.typeName === 'Roadmap') {
      if (!activity) {
        throw new Error(AppError.NO_ACTIVITY_FOR_ROADMAP);
      }

      return <PreprElementRoadmap activity={activity} key={element.id + index} element={element} />;
    }

    if (element.typeName === 'Tip') {
      return <PreprElementTip key={element.id + index} element={element} />;
    }

    if (element.typeName === 'Evernote') {
      return <PreprElementEvernote key={element.id + index} element={element} />;
    }

    return null;
  };

  return (
    <div className={cn(styles.PreprRenderer, className)}>
      {content?.map((item, idx) => {
        return getPreprElement(item, idx);
      })}
    </div>
  );
};
