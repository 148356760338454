import cn from 'classnames';

import { Icon, Text } from '@Components/ui';

import styles from './InputCarouselSlide.module.scss';
import { InputCarouselSlideProps } from './InputCarouselSlide.props';

export const InputCarouselSlide = ({ label, isActive, className }: InputCarouselSlideProps) => {
  const classNames = cn(styles.InputCarouselSlide, className, {
    [styles.isActive]: isActive
  });

  return (
    <div className={classNames}>
      <div className={styles.Card}>
        <Text.Large className={styles.Label}>{label}</Text.Large>

        <div className={styles.Icon}>
          <Icon icon={['fas', 'check']} size="md" />
        </div>
      </div>
    </div>
  );
};
