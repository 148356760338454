import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@Utils/PlatformUtils';

export const styles = StyleSheet.create({
  Large: {
    color: getTheme().color.font.default,
    fontFamily: getTheme().font.family.base,
    fontSize: getTheme().font.large.fontSize,
    fontWeight: 'normal'
  }
});
