import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Anchor } from '@Components/ui';

import styles from './InputField.module.scss';
import { InputFieldProps } from './InputField.props';

export const InputField = ({
  className,
  children,
  label,
  name,
  description,
  onEditClick,
  'data-testid': dataTestId
}: InputFieldProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn([styles.InputField, className])} data-testid={dataTestId}>
      {label && (
        <label className={styles.Head} htmlFor={name}>
          <div className={styles.Content}>
            {label}

            {description && <div className={styles.Description}>{description}</div>}
          </div>

          {!!onEditClick && (
            <Anchor onClick={onEditClick} className={styles.Anchor} data-testid="InputFieldEdit">
              {t('COMMON.GLOBALS.EDIT')}
            </Anchor>
          )}
        </label>
      )}

      {children}
    </div>
  );
};
