import { useCallback, useLayoutEffect, useState } from 'react';

import { useMeasurements } from '@Hooks/common';

export const useCurrentMiddle = () => {
  const [currentMiddle, setCurrentMiddle] = useState(0);
  const { scrollHeight: bodyHeight } = useMeasurements(document.body);

  const onScroll = useCallback(() => {
    const windowScrollOffset = window.scrollY;
    const windowMiddle = window.innerHeight / 2;

    if (window.innerHeight + windowScrollOffset >= bodyHeight) {
      setCurrentMiddle(bodyHeight);
    } else {
      setCurrentMiddle(windowMiddle + windowScrollOffset);
    }
  }, [setCurrentMiddle, bodyHeight]);

  useLayoutEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return {
    currentMiddle
  };
};
