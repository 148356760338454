import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TimelineChallenge } from '@Components/list';
import { Box, Text } from '@Components/ui';
import { useUserChallenge } from '@Hooks/userChallenge';

import styles from './ChallengeItemProgress.module.scss';
import {
  ChallengeItemActivitiesBlock,
  ChallengeItemRecommendedActivitiesBlock
} from './internal/components';

export const ChallengeItemProgress = () => {
  const { slug } = useParams<{ slug: string }>();

  const { t } = useTranslation();
  const { data: challenge } = useUserChallenge({ id: slug ?? '' });

  return (
    <div className={styles.ChallengeItemProgress}>
      <Text.H3 className={styles.Title}>
        {t('DOMAIN.CHALLENGE.DETAIL.CHALLENGE_ACCEPTED.TITLE')}
      </Text.H3>

      <Text.Large className={styles.Intro}>
        {t('DOMAIN.CHALLENGE.DETAIL.CHALLENGE_ACCEPTED.DESCRIPTION')}
      </Text.Large>

      <Box mb={6}>
        <ChallengeItemActivitiesBlock />

        <ChallengeItemRecommendedActivitiesBlock />
      </Box>

      <Text.H3 className={styles.Title}>{t('DOMAIN.CHALLENGE.DETAIL.TIMELINE.TITLE')}</Text.H3>

      {challenge && <TimelineChallenge challenge={challenge} />}
    </div>
  );
};
