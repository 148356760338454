import { t } from 'i18next';

import { TabGroup } from '@Components/ui';

import {
  ProfileEditLoginDataTab,
  ProfileEditPreferencesDataTab,
  ProfileMessagesTab
} from './internal/components';
import { Tabs } from './ProfileTabs.props';

export const ProfileTabs = () => {
  return (
    <TabGroup>
      <TabGroup.Child label={t('DOMAIN.PROFILE.MESSAGES.LABEL')} tabKey={Tabs.MESSAGES}>
        <ProfileMessagesTab />
      </TabGroup.Child>

      <TabGroup.Child label={t('DOMAIN.PROFILE.SIGNIN.LABEL')} tabKey={Tabs.LOGIN_DATA}>
        <ProfileEditLoginDataTab />
      </TabGroup.Child>

      <TabGroup.Child label={t('DOMAIN.PROFILE.PREFERENCES.LABEL')} tabKey={Tabs.PREFERENCES}>
        <ProfileEditPreferencesDataTab />
      </TabGroup.Child>
    </TabGroup>
  );
};
