import { useLocation } from 'react-router-dom';

import { useOnboardingSelfTest } from '@Hooks/onboarding';
import { useUser } from '@Hooks/user';
import { AppRoutes, OnboardingRoutes } from '@Navigation/Routes';
import { hasCompletedAPhase } from '@Utils/QuestionnaireUtils';

export const useIsFullscreen = () => {
  const user = useUser();

  const location = useLocation();
  const { data: onboardingSelfTest } = useOnboardingSelfTest();

  const hasCompletedOnboarding = user?.selfTestCompleted || hasCompletedAPhase(onboardingSelfTest);
  const isOnboarding = !user?.skippedSelfTest && !hasCompletedOnboarding;
  const currentRoutes = isOnboarding ? OnboardingRoutes : AppRoutes;

  let isFullscreen = false;

  const currentAppRoute = Object.values(currentRoutes).find((route) => {
    const slug = location.pathname.split('/');

    return route(slug[slug.length - 1]).to === location.pathname;
  });

  if (currentAppRoute) {
    isFullscreen = currentAppRoute('').isFullscreen;
  }

  return isFullscreen;
};
