import { AxiosRequestConfig } from 'axios';

import { getPlatform } from './PlatformUtils';

export const noCacheInterceptor = (req: AxiosRequestConfig) => {
  const headers = req.headers || {};

  headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
  headers.Pragma = 'no-cache';
  headers.Expires = '0';

  req.headers = headers;

  return req;
};

export const brandInterceptor = (req: AxiosRequestConfig) => {
  const headers = req.headers || {};

  headers['X-Brand'] = getPlatform();

  req.headers = headers;

  return req;
};
