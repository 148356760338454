import { useMutation, useQueryClient } from 'react-query';

import { authService } from '@Services/AuthService';

import { useAuthStatus } from './useAuthStatus';

export const useAuthLogout = () => {
  const queryClient = useQueryClient();
  const { setIsAuthenticated } = useAuthStatus();

  return useMutation(
    () => {
      return authService.logout();
    },
    {
      onSettled: () => {
        queryClient.clear();

        setIsAuthenticated(false);
      }
    }
  );
};
