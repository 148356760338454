import cn from 'classnames';
import { useMemo } from 'react';

import styles from './Counter.module.scss';
import { CounterProps } from './Counter.props';
import { Digit } from './internal';

export const Counter = ({ count = 0, className }: CounterProps) => {
  const digitElements = useMemo(() => {
    const countLength = Math.floor(Math.log10(count) + 1);
    const digits = count < 10000 ? 5 : countLength;

    return Array(digits)
      .fill(null)
      .map((_, idx) => {
        // we need to correct our idx based on how many digits
        // are in our number. This fixes issue where zeros would
        // be placed at the end instead of the start
        // 99900 -> 00999
        let correctIdx = idx;

        if (countLength < digits) {
          correctIdx = idx - (digits - countLength);
        }

        const digit = parseInt(count.toString()[correctIdx]) || 0;
        const steps = (digits - idx) * 3;

        return <Digit key={`${idx}${digit}`} finalNumber={digit || 0} steps={steps} />;
      });
  }, [count]);

  return <div className={cn(styles.Counter, className)}>{digitElements}</div>;
};
