import cn from 'classnames';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityItemLarge } from '@Components/container';
import { Spacer, Text } from '@Components/ui/index';
import { REQUIRED_ACTIVITY_AMOUNT } from '@Constants/configs';
import { PinState } from '@Constants/enums';
import { useBoolean } from '@Hooks/common';
import { IActivity } from '@Services/Activity';

import { CustomActivity } from './internal/components/CustomActivity';
import styles from './SelectActivityBlock.module.scss';
import {
  SelectActivityBlockProps,
  SelectActivityBlockReturnType
} from './SelectActivityBlock.props';

export const SelectActivityBlock = ({
  hasError,
  activities,
  isDisabled,
  onChange,
  className
}: SelectActivityBlockProps) => {
  const [pinnedActivityIds, setPinnedActivityIds] = useState<Array<string>>([]);
  const [customActivity, setCustomActivity] = useState('');
  const hasCustomActivity = useBoolean(false);

  const { t } = useTranslation();

  const handlePinClick = useCallback(
    (id: string) => () => {
      setPinnedActivityIds((previousSelection) => {
        const alreadyPinned = previousSelection.includes(id);

        if (alreadyPinned) {
          return previousSelection.filter((item) => item !== id);
        }

        return [id, ...previousSelection];
      });
    },
    []
  );

  const onDataChange = useCallback(() => {
    const mappedToReturnType = pinnedActivityIds.map((item): SelectActivityBlockReturnType => {
      return { value: item, isCustom: false };
    });

    onChange(
      hasCustomActivity.value
        ? [{ value: customActivity, isCustom: true }, ...mappedToReturnType]
        : mappedToReturnType
    );
  }, [customActivity, hasCustomActivity.value, onChange, pinnedActivityIds]);

  useEffect(() => {
    onDataChange();
  }, [pinnedActivityIds, customActivity, hasCustomActivity.value, onDataChange]);

  const handleFormChange = (value: string) => {
    setCustomActivity(value);
  };

  const numActivities = pinnedActivityIds.length + (hasCustomActivity.value ? 1 : 0);

  const isAtActivityLimit = numActivities >= REQUIRED_ACTIVITY_AMOUNT;

  const getPinState = (activity: IActivity) => {
    if (activity.isPartOfChallenge) {
      return PinState.DISABLED;
    }

    if (pinnedActivityIds.includes(activity.id)) {
      return PinState.PINNED;
    }

    if (hasError) {
      return PinState.ERROR;
    }

    if (isAtActivityLimit) {
      return PinState.DISABLED;
    }

    return PinState.UNPINNED;
  };

  return (
    <div className={cn(styles.SelectActivityBlock, { [styles.hasError]: hasError }, className)}>
      <div className={styles.Header}>
        <Text.H3>{t('DOMAIN.ACTIVITY.ACCEPT_CHALLENGE')}</Text.H3>

        {!isDisabled && (
          <Text.Micro
            className={styles.ActivityNumber}
          >{`${numActivities}/${REQUIRED_ACTIVITY_AMOUNT} ${t(
            'DOMAIN.ACTIVITY.PLURAL'
          )}`}</Text.Micro>
        )}
      </div>

      <div>
        {activities.map((activity) => {
          const pinState = getPinState(activity);

          return (
            <Fragment key={activity.id}>
              <ActivityItemLarge
                onPinClick={!isDisabled ? handlePinClick(activity.id) : undefined}
                pinState={pinState}
                activity={activity}
                className={styles.ActivityItem}
                withAnchor={isDisabled}
                canClickToPin={pinState !== PinState.DISABLED}
                data-testid="SelectActivityBlockActivity"
              />

              {!isDisabled && <Spacer className={styles.Spacer} />}
            </Fragment>
          );
        })}
      </div>

      {!isDisabled && (
        <CustomActivity
          isCustomActivityOpen={hasCustomActivity.value}
          onToggleCustomActivity={hasCustomActivity.toggle}
          isDisabled={hasCustomActivity.value ? false : isAtActivityLimit}
          hasError={hasError}
          onChange={handleFormChange}
        />
      )}
    </div>
  );
};
