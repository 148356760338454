import { useCallback, useState } from 'react';

import { TourDot } from '@Components/container';
import { useMutationObserver } from '@Hooks/common/useMutationObserver';

export const TourDots = () => {
  const [tourdotTargets, setTourdotTargets] = useState<NodeListOf<HTMLElement>>();

  const updateTargets = useCallback(() => {
    setTourdotTargets(document.querySelectorAll('[data-tourdotid]'));
  }, []);

  useMutationObserver(updateTargets);

  if (tourdotTargets) {
    return (
      <>
        {Array.from(tourdotTargets).map((target, idx) => {
          const { tourdotid } = target.dataset;

          return <TourDot key={`${tourdotid}${idx}`} target={target} id={tourdotid as any} />;
        })}
      </>
    );
  }

  return null;
};
