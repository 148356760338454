import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from '@Constants/configs';
import locales from '@Locales/index';

i18next.use(initReactI18next).init({
  resources: locales,
  lng: DEFAULT_LANGUAGE,
  debug: false,
  interpolation: {
    escapeValue: false
  }
});

export const setupTranslation = () => {
  return i18next;
};

export const i18n = i18next;
