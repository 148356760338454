import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AuthTokenForm } from '@Components/form';
import { Anchor, Box, SliderFullPage, Text } from '@Components/ui';
import { SMS_COUNTDOWN_SECONDS } from '@Constants/configs';
import { AppError } from '@Constants/enums';
import { useAuthResendTwoFactor } from '@Hooks/auth';
import { useApiError, useCountdown } from '@Hooks/common';

import { LoginSmsCodeSlideProps } from './LoginSmsCodeSlide.props';

export const LoginSmsCodeSlide = ({
  smsToken,
  phoneSuffix,
  onSuccess,
  email
}: LoginSmsCodeSlideProps) => {
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const resendTwoFactorMutation = useAuthResendTwoFactor();
  const countdown = useCountdown(SMS_COUNTDOWN_SECONDS);

  const handleResend = useCallback(async () => {
    countdown.start();

    if (!smsToken) {
      throw new Error(AppError.NO_SMS_TOKEN_PROVIDED);
    }

    if (!email) {
      throw new Error(AppError.NO_EMAIL_PROVIDED);
    }

    try {
      await resendTwoFactorMutation.mutateAsync({
        smsToken,
        email
      });

      toast.info(t('DOMAIN.AUTHENTICATION.LOGIN.TWO_FACTOR.RESEND.SUCCESS'));
    } catch (e) {
      handleError(e);
    }
  }, [countdown, smsToken, email, resendTwoFactorMutation, t, handleError]);

  return (
    <SliderFullPage.Item showPagination>
      <SliderFullPage.Block title={t('DOMAIN.AUTHENTICATION.LOGIN.TWO_FACTOR.TITLE')}>
        <Box mt={1.5} mb={2}>
          <Text.Normal>
            {t('DOMAIN.AUTHENTICATION.LOGIN.TWO_FACTOR.CODE_SENT', {
              phoneNumberSuffix: phoneSuffix
            })}
          </Text.Normal>
        </Box>

        <Box mb={5}>
          <Text.Normal>
            {t('DOMAIN.AUTHENTICATION.LOGIN.TWO_FACTOR.RESEND.LABEL') + ' '}

            <Anchor
              onClick={handleResend}
              isDisabled={countdown.count !== 0}
              data-testid="resendToken"
            >
              {t('DOMAIN.AUTHENTICATION.LOGIN.TWO_FACTOR.RESEND.LINK')}

              {countdown.count > 0 &&
                t('DOMAIN.AUTHENTICATION.LOGIN.TWO_FACTOR.RESEND.LINK_COUNT', {
                  count: countdown.count
                })}
            </Anchor>

            {'.'}
          </Text.Normal>
        </Box>

        <AuthTokenForm onSuccess={onSuccess} smsToken={smsToken} email={email} />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
