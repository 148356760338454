import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container } from '@Components/layout';
import { Block, Box, Loader, TabGroup } from '@Components/ui';
import { useUserChallenge } from '@Hooks/userChallenge';
import { AppRoutes } from '@Navigation/Routes';

import { ChallengeItemHead, ChallengeItemInformation } from './internal/components';
import { ChallengeItemProgress } from './internal/components/ChallengeItemProgress/ChallengeItemProgress';

export const ChallengeItemScreen = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const {
    data: challenge,
    isLoading: isChallengeLoading,
    isError: isChallengeError
  } = useUserChallenge({ id: slug ?? '' });

  useEffect(() => {
    if (isChallengeError) {
      toast.error(t('DOMAIN.CHALLENGE.ERROR'));

      navigate(AppRoutes.getInsightChallenges().to);
    }
  }, [isChallengeError, navigate, t]);

  return (
    <Container>
      {isChallengeLoading && (
        <Box my={4}>
          <Loader>
            {t('COMMON.GLOBALS.LOADING_MODEL', { name: t('DOMAIN.CHALLENGE.SINGLE') })}
          </Loader>
        </Box>
      )}

      {challenge && (
        <>
          <ChallengeItemHead />

          <Box mb={4}>
            <Block>
              <TabGroup>
                <TabGroup.Child label={t('DOMAIN.CHALLENGE.DETAIL.YOUR_PROGRESS')}>
                  <ChallengeItemProgress />
                </TabGroup.Child>

                <TabGroup.Child label={t('DOMAIN.CHALLENGE.DETAIL.INFO')}>
                  <ChallengeItemInformation />
                </TabGroup.Child>
              </TabGroup>
            </Block>
          </Box>
        </>
      )}
    </Container>
  );
};
