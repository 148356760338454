import { forwardRef } from 'react';

import { BoxProps } from './Box.props';

export const Box = forwardRef(
  (
    { m, mt, mb, ml, mr, mx, my, p, pt, pb, pl, pr, px, py, children, ...props }: BoxProps,
    ref?: any
  ) => {
    const getSpacing = (spacing: number | string, properties: string[]) => {
      const style = properties.map((property) => {
        const value = Number(spacing) ? `${Number(spacing) * 8}px` : spacing;
        return [property, value];
      });

      return Object.fromEntries(style);
    };

    const style = {
      ...(m && getSpacing(m, ['margin'])),
      ...(mt && getSpacing(mt, ['marginTop'])),
      ...(mb && getSpacing(mb, ['marginBottom'])),
      ...(ml && getSpacing(ml, ['marginLeft'])),
      ...(mr && getSpacing(mr, ['marginRight'])),
      ...(mx && getSpacing(mx, ['marginLeft', 'marginRight'])),
      ...(my && getSpacing(my, ['marginTop', 'marginBottom'])),

      ...(p && getSpacing(p, ['padding'])),
      ...(pt && getSpacing(pt, ['paddingTop'])),
      ...(pb && getSpacing(pb, ['paddingBottom'])),
      ...(pl && getSpacing(pl, ['paddingLeft'])),
      ...(pr && getSpacing(pr, ['paddingRight'])),
      ...(px && getSpacing(px, ['paddingLeft', 'paddingRight'])),
      ...(py && getSpacing(py, ['paddingTop', 'paddingBottom'])),

      ...props.style
    };

    return (
      <div {...props} style={style} ref={ref}>
        {children}
      </div>
    );
  }
);
