import cn from 'classnames';

import Checkmark from '@Assets/icons/check.svg';
import { Text } from '@Components/ui';

import styles from './CheckItem.module.scss';
import { CheckItemProps } from './CheckItem.props';

export const CheckItem = ({ label, isChecked, className }: CheckItemProps) => {
  return (
    <div className={cn(styles.CheckItem, className)}>
      <Text.Bold className={styles.Label}>{label}</Text.Bold>

      {isChecked && <img className={styles.Icon} src={Checkmark} />}
    </div>
  );
};
