import { useEffect } from 'react';

import { useBoolean, useTimeouts } from '@Hooks/common';

export const useIsDoneAnimating = (items: number, isVisible: boolean, delayPerItem: number) => {
  const isDoneAnimating = useBoolean();
  const { addTimeout } = useTimeouts();

  useEffect(() => {
    if (isVisible) {
      addTimeout(() => {
        isDoneAnimating.setTrue();
      }, items * delayPerItem);
    }
  }, [items, isVisible, delayPerItem, addTimeout, isDoneAnimating]);

  return [isDoneAnimating.value];
};
