import cn from 'classnames';

import { Box, Image } from '@Components/ui';
import { PreprElementListAlignment } from '@Constants/preprTypes';
import { getImageSrc } from '@Utils/PreprUtils';

import styles from './PreprElementImage.module.scss';
import { PreprElementImageProps } from './PreprElementImage.props';

export const PreprElementImage = ({ element }: PreprElementImageProps) => {
  return (
    <Box
      mb={3}
      className={cn(styles.PreprElementImage, {
        [styles.isAlignedRight]: element.alignment === PreprElementListAlignment.RIGHT,
        [styles.isAlignedLeft]: element.alignment === PreprElementListAlignment.LEFT
      })}
    >
      <Image src={getImageSrc(element.image)} ratio="fill" />
    </Box>
  );
};
