import { Navigate, Route, Routes } from 'react-router-dom';

import { SelfTestQuestionnaireScreen, SelfTestResultsScreen } from '@Screens/SelfTest';

import { AppRoutes } from './Routes';

export const SelfTestStack = () => {
  return (
    <Routes>
      <Route path="" element={<SelfTestQuestionnaireScreen />} />

      <Route path={AppRoutes.getSelfTestResults().path} element={<SelfTestResultsScreen />} />

      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};
