import { useMemo } from 'react';

import { PreprRenderer } from '@Components/layout';
import { Box } from '@Components/ui';
import { Accordion } from '@Components/ui';

import styles from './PreprElementAccordion.module.scss';
import { PreprElementAccordionProps } from './PreprElementAccordion.props';

export const PreprElementAccordion = ({ element }: PreprElementAccordionProps) => {
  const accordionItems = useMemo(() => {
    return element.accordion.map((item, idx) => {
      return {
        ...item,
        id: String(idx),
        body: <PreprRenderer content={item.content} className={styles.Content} />
      };
    });
  }, [element]);

  return (
    <Box mb={4.5} mt={3} className={styles.PreprElementAccordion}>
      <Accordion items={accordionItems} />
    </Box>
  );
};
