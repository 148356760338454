import cn from 'classnames';
import { add, format, isAfter, isBefore, sub } from 'date-fns';
import { useCallback } from 'react';

import { ButtonIcon, Text } from '@Components/ui';
import { getToday, writtenMonth, writtenMonthYear } from '@Utils/DateUtils';

import styles from './DateSelector.module.scss';
import { DateSelectorProps } from './DateSelector.props';

export const DateSelector = ({
  selectedDate = getToday(),
  onChange,
  minDate,
  maxDate,
  className
}: DateSelectorProps) => {
  const getMonthKey = (date: Date) => {
    return format(date, 'yyyy-MM');
  };

  const selectedMonthKey = getMonthKey(selectedDate);
  const previousMonth = sub(selectedDate, { months: 1 });
  const nextMonth = add(selectedDate, { months: 1 });

  const canGoBack = !minDate || !isBefore(previousMonth, minDate);
  const canGoNext = !maxDate || !isAfter(nextMonth, maxDate);

  const handlePreviousClick = useCallback(() => {
    if (canGoBack) {
      onChange(previousMonth);
    }
  }, [canGoBack, onChange, previousMonth]);

  const handleNextClick = useCallback(() => {
    if (canGoNext) {
      onChange(nextMonth);
    }
  }, [canGoNext, onChange, nextMonth]);

  const getSelectedMonth = () => {
    const adjacentMonths = [previousMonth, selectedDate, nextMonth];

    return adjacentMonths.map((date, idx) => {
      const monthKey = getMonthKey(date);
      const isSelectedMonth = monthKey === selectedMonthKey;

      return (
        <Text.H4
          key={monthKey}
          className={cn(styles.FeaturedMonth, {
            [styles.isPrevious]: idx === 0,
            [styles.isActive]: idx === 1,
            [styles.isNext]: idx === 2
          })}
        >
          {isSelectedMonth ? writtenMonthYear(date) : writtenMonth(date)}
        </Text.H4>
      );
    });
  };

  return (
    <div className={cn(styles.DateSelector, className)}>
      <ButtonIcon
        onClick={handlePreviousClick}
        icon="chevron-left"
        data-testid="previousButton"
        isDisabled={!canGoBack}
      />

      <div className={styles.MonthsContainer}>
        <div className={styles.Dots} />

        <div
          className={cn(styles.MonthContainer, styles.PreviousMonth, {
            [styles.isDisabled]: !canGoBack
          })}
        >
          <Text.H5 onClick={handlePreviousClick} className={styles.AdjacentMonthLabel}>
            {writtenMonth(previousMonth)}
          </Text.H5>
        </div>

        <div className={styles.MonthContainer}>
          <div className={cn(styles.FeaturedMonthContainer)}>{getSelectedMonth()}</div>
        </div>

        <div
          className={cn(styles.MonthContainer, styles.NextMonth, {
            [styles.isDisabled]: !canGoNext
          })}
        >
          <Text.H5 onClick={handleNextClick} className={styles.AdjacentMonthLabel}>
            {writtenMonth(nextMonth)}
          </Text.H5>
        </div>
      </div>

      <ButtonIcon
        onClick={handleNextClick}
        icon="chevron-right"
        data-testid="nextButton"
        isDisabled={!canGoNext}
      />
    </div>
  );
};
