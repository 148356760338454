import ACTIVITY from './ACTIVITY.json';
import AUTHENTICATION from './AUTHENTICATION.json';
import CHALLENGE from './CHALLENGE.json';
import CONTENTPAGE from './CONTENTPAGE.json';
import DASHBOARD from './DASHBOARD.json';
import FAQ from './FAQ.json';
import INBOX from './INBOX.json';
import ONBOARDING from './ONBOARDING.json';
import PERSONA_QUESTIONNAIRE from './PERSONAQUESTIONNAIRE.json';
import PLATFORM from './PLATFORM.json';
import PREFERENCES from './PREFERENCES.json';
import PROFILE from './PROFILE.json';
import PROGRESS from './PROGRESS.json';
import RELATIVEDATE from './RELATIVEDATE.json';
import REPORT from './REPORT.json';
import REWARD from './REWARD.json';
import SELF_TEST_QUESTIONNAIRE from './SELFTESTQUESTIONNAIRE.json';
import THEME from './THEME.json';
import TODO from './TODO.json';
import USER from './USER.json';
import USER_ACTIVITY from './USER_ACTIVITY.json';
import YUP from './YUP.json';

export default {
  TODO,
  PROGRESS,
  REPORT,
  SELF_TEST_QUESTIONNAIRE,
  PERSONA_QUESTIONNAIRE,
  PROFILE,
  PREFERENCES,
  ONBOARDING,
  AUTHENTICATION,
  FAQ,
  THEME,
  REWARD,
  DASHBOARD,
  YUP,
  INBOX,
  USER_ACTIVITY,
  USER,
  PLATFORM,
  CHALLENGE,
  ACTIVITY,
  RELATIVEDATE,
  CONTENTPAGE
};
