import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Anchor,
  Block,
  Box,
  Button,
  Loader,
  ProgressSteps,
  StepIndicator,
  Text
} from '@Components/ui';
import { useOnboardingSelfTest } from '@Hooks/onboarding';
import { useUser } from '@Hooks/user';
import { AppRoutes, OnboardingRoutes } from '@Navigation/Routes';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';
import { ProfileStatusStep } from '@Services/User';
import { hasCompletedAPhase } from '@Utils/QuestionnaireUtils';

import styles from './ProfileStatus.module.scss';
import { ProfileStatusProps } from './ProfileStatus.props';

export const ProfileStatus = ({
  showCta,
  className,
  onSelfTestClick,
  onSelfTestSkipClick,
  canOpenNext,
  showTourdots
}: ProfileStatusProps) => {
  const { t } = useTranslation();
  const { profileStatusSteps, selfTestCompleted, hasPhasedSelfTest } = useUser();

  const { data: onboardingSelfTest, isLoading: isLoadingOnboardingSelfTest } =
    useOnboardingSelfTest();

  const hasCompletedPreferences =
    profileStatusSteps.includes(ProfileStatusStep.PERSONA) === false &&
    profileStatusSteps.includes(ProfileStatusStep.PREFERENCES) === false;

  const hasCompletedSelfTest = selfTestCompleted || hasCompletedAPhase(onboardingSelfTest);
  const hasStartedChallenge = profileStatusSteps.includes(ProfileStatusStep.CHALLENGE) === false;
  const selfTestIsOpen =
    onboardingSelfTest?.status &&
    [QuestionnaireSubmissionStatus.OPEN, QuestionnaireSubmissionStatus.DOMAIN_SELECTION].includes(
      onboardingSelfTest.status
    );
  const buttonLabel = selfTestIsOpen ? 'OPEN' : 'IN_PROGRESS';

  const regularSelfTestOption = useMemo(() => {
    return {
      title: t('COMPONENTS.PROFILE_STATUS.SELF_TEST.REGULAR.TITLE'),
      description: t('COMPONENTS.PROFILE_STATUS.SELF_TEST.REGULAR.DESCRIPTION'),
      children: (
        <span data-tourdotid={showTourdots ? 'PROFILE_STATUS_REGULAR' : undefined}>
          <Button onClick={onSelfTestClick} icon="arrow-right" data-testid="selftestButton">
            {t(
              `COMPONENTS.PROFILE_STATUS.SELF_TEST.REGULAR.BODY.BUTTON_LABEL.${buttonLabel}` as any
            )}
          </Button>

          {onSelfTestSkipClick && (
            <Anchor className={styles.Skip} onClick={onSelfTestSkipClick}>
              {t('COMPONENTS.PROFILE_STATUS.SELF_TEST.SKIP')}
            </Anchor>
          )}
        </span>
      ),
      isChecked: hasCompletedSelfTest,
      action: showCta ? t('COMPONENTS.PROFILE_STATUS.SELF_TEST.REGULAR.ACTION') : undefined
    };
  }, [
    t,
    showTourdots,
    onSelfTestClick,
    buttonLabel,
    onSelfTestSkipClick,
    hasCompletedSelfTest,
    showCta
  ]);

  const phasedSelfTestOption = useMemo(() => {
    const totalPhases = onboardingSelfTest?.totalPhases ? onboardingSelfTest.totalPhases : 0;
    const currentPhaseIndex = onboardingSelfTest?.completedPhases
      ? onboardingSelfTest.completedPhases
      : 0;

    return {
      title: t('COMPONENTS.PROFILE_STATUS.SELF_TEST.PHASED.TITLE'),
      description: t('COMPONENTS.PROFILE_STATUS.SELF_TEST.PHASED.DESCRIPTION'),
      children: (
        <Block theme="gray">
          <Box mb={2} className={styles.PhasesStepIndicator}>
            <StepIndicator current={currentPhaseIndex + 1} total={totalPhases} />
          </Box>

          <Box mb={0.5}>
            <Text.H4>
              {t('COMPONENTS.PROFILE_STATUS.SELF_TEST.PHASED.BODY.TITLE', {
                total: totalPhases,
                current: currentPhaseIndex + 1
              })}
            </Text.H4>
          </Box>

          <Box mb={2}>
            <Text.Normal>
              {t(
                `COMPONENTS.PROFILE_STATUS.SELF_TEST.PHASED.BODY.PHASES.${currentPhaseIndex}.DESCRIPTION` as any
              )}
            </Text.Normal>
          </Box>

          <span>
            <Button onClick={onSelfTestClick} icon="arrow-right">
              {t(
                `COMPONENTS.PROFILE_STATUS.SELF_TEST.PHASED.BODY.BUTTON_LABEL.${buttonLabel}` as any
              )}
            </Button>

            {onSelfTestSkipClick && (
              <Anchor className={styles.Skip} onClick={onSelfTestSkipClick}>
                {t('COMPONENTS.PROFILE_STATUS.SELF_TEST.SKIP')}
              </Anchor>
            )}
          </span>
        </Block>
      ),
      isChecked: hasCompletedSelfTest,
      action: showCta ? t('COMPONENTS.PROFILE_STATUS.SELF_TEST.PHASED.ACTION') : undefined
    };
  }, [
    onboardingSelfTest,
    t,
    onSelfTestClick,
    buttonLabel,
    onSelfTestSkipClick,
    hasCompletedSelfTest,
    showCta
  ]);

  const options = [
    {
      title: t('COMPONENTS.PROFILE_STATUS.ACCOUNT.TITLE'),
      isChecked: true
    },
    {
      title: t('COMPONENTS.PROFILE_STATUS.PREFERENCES.TITLE'),
      description: t('COMPONENTS.PROFILE_STATUS.PREFERENCES.DESCRIPTION'),
      children: (
        <Button
          to={OnboardingRoutes.getOnboardingPreferences().to}
          icon="arrow-right"
          data-testid="preferencesButton"
        >
          {t('COMPONENTS.PROFILE_STATUS.PREFERENCES.BODY.BUTTON_LABEL')}
        </Button>
      ),
      isChecked: hasCompletedPreferences,
      action: showCta ? t('COMPONENTS.PROFILE_STATUS.PREFERENCES.ACTION') : undefined
    },
    hasPhasedSelfTest ? phasedSelfTestOption : regularSelfTestOption,
    {
      title: t('COMPONENTS.PROFILE_STATUS.CHALLENGE.TITLE'),
      description: t('COMPONENTS.PROFILE_STATUS.CHALLENGE.DESCRIPTION'),
      children: (
        <Button to={AppRoutes.getInsightChallengesAdd().to} icon="arrow-right">
          {t('COMPONENTS.PROFILE_STATUS.CHALLENGE.BODY.BUTTON_LABEL')}
        </Button>
      ),
      isChecked: hasStartedChallenge
    }
  ];

  if (isLoadingOnboardingSelfTest) {
    return <Loader className={className} />;
  }

  return (
    <ProgressSteps
      className={cn(className, styles.ProfileStatus)}
      options={options}
      canOpenNext={canOpenNext}
    />
  );
};
