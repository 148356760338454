import cn from 'classnames';
import { Children, useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore, { Pagination, Virtual } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { Breakpoints } from '@Constants/enums';
import { useBoolean, useDynamicHeight } from '@Hooks/common';
import { scrollToTop } from '@Utils/WindowUtils';

import { SLIDER_FULLPAGE_SPEED, SLIDER_SLIDE_SHADOW_HEIGHT } from '../../constants';
import { SliderFullPageContext, SliderFullPageContextType } from '../../context';
import { SliderFullPageItemContext } from '../SliderFullPageItem/context';
import styles from './Slider.module.scss';
import { SliderFullPageProps } from './Slider.props';

SwiperCore.use([Pagination, Virtual]);

export const Slider = ({
  overrideTotal,
  children,
  className,
  onSwiper,
  isVirtual,
  isLoop,
  ...props
}: SliderFullPageProps) => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const [currentSlideRealIdx, setCurrentSlideRealIdx] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const slideRefs = useRef<Array<HTMLDivElement | null>>([]);
  const {
    value: isAnimating,
    setTrue: setIsAnimating,
    setFalse: setIsNotAnimating
  } = useBoolean(false);

  const sliderHeight = useDynamicHeight(
    (slideRefs.current[currentSlideRealIdx]?.firstElementChild as HTMLElement) ?? null
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleSwiper = useCallback(
    (slider: SwiperClass) => {
      setSwiper(slider);

      if (onSwiper) {
        onSwiper(slider);
      }
    },
    [onSwiper]
  );

  const handleSlideChange = useCallback(() => {
    setCurrentSlideIdx(swiper?.activeIndex || 0);
    setCurrentSlideRealIdx(swiper?.realIndex || 0);

    scrollToTop();
  }, [swiper]);

  const handlePrev = useCallback(() => {
    swiper?.slidePrev();
  }, [swiper]);

  const handleNext = useCallback(() => {
    swiper?.slideNext();
  }, [swiper]);

  const handleTo = useCallback(
    (index: number) => {
      swiper?.slideTo(index);
    },
    [swiper]
  );

  const slideNext = useCallback(
    (idx: number) => () => {
      handleTo(idx + 1);
    },
    [handleTo]
  );

  const slidePrev = useCallback(
    (idx: number) => () => {
      handleTo(idx - 1);
    },
    [handleTo]
  );

  const contextValues: SliderFullPageContextType = {
    total: overrideTotal || Children.toArray(children).length,
    current: currentSlideIdx,
    slidePrev: handlePrev,
    slideNext: handleNext,
    slideTo: handleTo,
    swiper,
    isAnimating
  };
  return (
    <SliderFullPageContext.Provider value={contextValues}>
      <div className={styles.SliderWrapper} {...props}>
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 1.2
            },
            [Breakpoints.md]: {
              slidesPerView: 1.9
            }
          }}
          slidesPerView={1.2}
          spaceBetween={0}
          onSlideChangeTransitionStart={setIsAnimating}
          onSlideChangeTransitionEnd={setIsNotAnimating}
          onSwiper={handleSwiper}
          onSlideChange={handleSlideChange}
          simulateTouch={false}
          className={cn(styles.Slider, className)}
          centeredSlides={true}
          speed={SLIDER_FULLPAGE_SPEED}
          allowTouchMove={false}
          loop={isLoop}
          style={{ height: sliderHeight + SLIDER_SLIDE_SHADOW_HEIGHT }}
          virtual={isVirtual}
        >
          {Children.toArray(children).map((item, idx) => {
            return (
              <SwiperSlide
                key={idx}
                className={styles.Slide}
                virtualIndex={isVirtual ? idx : undefined}
              >
                {({ isActive }) => {
                  return (
                    <SliderFullPageItemContext.Provider
                      value={{
                        isActive,
                        index: idx,
                        slideNext: slideNext(idx),
                        slidePrev: slidePrev(idx)
                      }}
                    >
                      <div ref={(el) => (slideRefs.current[idx] = el)}>{item}</div>
                    </SliderFullPageItemContext.Provider>
                  );
                }}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </SliderFullPageContext.Provider>
  );
};
