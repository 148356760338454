import { useCallback, useEffect, useState } from 'react';

import { useBoolean } from '../useBoolean';
import { useResizeObserver } from '../useResizeObserver';
import { keepInView } from './internal/utils';

export enum TargetAlignment {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  CENTER = 'center'
}

export enum TargetDirection {
  UP = 'up',
  RIGHT = 'right',
  DOWN = 'down',
  LEFT = 'left',
  MIDDLE = 'middle'
}

/**
 * Thing to note here is that TargetDirection is inverted.
 * Example, the element could be placed in the following places
 * around the target (t):
 *     A
 * D  (t)   B
 *     C
 *
 * if the element should be placed to the right of the target (t), in location B
 * then the direction to the target is left. So targetDirection must be set as LEFT.
 *
 *
 * @param {RefObject<HTMLElement>} targetRef reference to element we should target
 * @param {RefObject<HTMLElement>} elementRef reference to element we want to place
 * @param {TargetDirection} direction direction to target element
 * @param {TargetAlignment} alignment alignment with target element
 * @param {number} margin margin from target element
 * @param {number} alignmentOffset offset from alignment with target element
 * @param {boolean} isFixed if placement calculation should be based on a fixed or absolute scale
 * @returns
 */

export const useTargetedLocation = (
  element: HTMLElement | null,
  target: HTMLElement | null = document.body,
  direction: TargetDirection = TargetDirection.DOWN,
  alignment: TargetAlignment = TargetAlignment.CENTER,
  margin = 16,
  alignmentOffset = 0,
  isFixed = false,
  forceUpdate = false,
  shouldKeepInView = false
) => {
  const { value: hasCalculatedLocation, setTrue: setHasCalculatedLocationTrue } = useBoolean(false);

  const [correctionAmount, setCorrectionAmount] = useState({ x: 0, y: 0 });
  const [location, setLocation] = useState({ x: 0, y: 10000 });

  const updateLocation = useCallback(() => {
    if (target && element) {
      const targetRect = target.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      const targetX = targetRect.x;
      const targetY = targetRect.y;

      const targetHeight = targetRect.height;
      const targetWidth = targetRect.width;

      const elementHeight = elementRect.height;
      const elementWidth = elementRect.width;

      // ScrollOffset should be added to Y coordinates, as placement would
      // otherwise be incorrect when page is scrolled.
      // unless the calculation is based on a fixed component
      const scrollOffset = isFixed ? 0 : window.scrollY;

      const isAboveOrBelow = direction === TargetDirection.UP || direction === TargetDirection.DOWN;
      const isBeside = direction === TargetDirection.LEFT || direction === TargetDirection.RIGHT;
      const isMiddle = direction === TargetDirection.MIDDLE;

      const doUpdate = (x: number, y: number) => {
        const xOffset = isMiddle || isAboveOrBelow ? alignmentOffset : 0;
        const yOffset = isBeside ? alignmentOffset : 0;

        let newX = x + xOffset;
        let newY = y + yOffset + scrollOffset;

        if (shouldKeepInView) {
          const recalculatedPosition = keepInView(newX, newY, elementRect);

          newX = recalculatedPosition.x;
          newY = recalculatedPosition.y;

          setCorrectionAmount(recalculatedPosition.offsetChanges);
        }

        setLocation({ x: newX, y: newY });
        setHasCalculatedLocationTrue();
      };

      /**
       * Notes for the following code:
       * elementX and elementY should be seen as the coordinates of the
       * most upper-left corner of the element we want to place next to the target.
       * This is why sometimes we offset by elementHeight / 2 or elementWidth / 2, like
       * right below this comment
       */

      if (isMiddle) {
        const elementY = targetY + targetHeight / 2 - elementHeight / 2;
        const elementX = targetX + targetWidth / 2 - elementWidth / 2;

        doUpdate(elementX, elementY);
        return;
      }

      if (isAboveOrBelow) {
        let elementX = targetX; // TargetAlignment.LEFT
        let elementY = targetRect.bottom + margin; // TargetDirection.UP

        if (alignment === TargetAlignment.CENTER) {
          elementX = targetX + targetWidth / 2 - elementWidth / 2;
        }

        if (alignment === TargetAlignment.RIGHT) {
          elementX = targetRect.right - elementWidth;
        }

        if (direction === TargetDirection.DOWN) {
          elementY = targetY - elementHeight - margin;
        }

        doUpdate(elementX, elementY);
        return;
      }

      if (isBeside) {
        let elementY = targetY; // TargetAlignment.TOP
        let elementX = targetRect.right + margin; // TargetDirection.LEFT

        if (alignment === TargetAlignment.CENTER) {
          elementY = targetY + targetHeight / 2 - elementHeight / 2;
        }

        if (alignment === TargetAlignment.BOTTOM) {
          elementY = targetRect.bottom - elementHeight;
        }

        if (direction === TargetDirection.RIGHT) {
          elementX = targetX - elementWidth - margin;
        }

        doUpdate(elementX, elementY);
        return;
      }
    }
  }, [
    target,
    element,
    isFixed,
    direction,
    alignmentOffset,
    shouldKeepInView,
    setHasCalculatedLocationTrue,
    margin,
    alignment
  ]);

  useResizeObserver(updateLocation);

  useEffect(() => {
    updateLocation();
  }, [updateLocation, forceUpdate]);

  return { location, setLocation, hasCalculatedLocation, correctionAmount };
};
