import { useTranslation } from 'react-i18next';

import { Box, Text } from '@Components/ui';

import styles from './ChallengeAddHeading.module.scss';
import { ChallengeAddHeadingProps } from './ChallengeAddHeading.props';

export const ChallengeAddHeading = ({ title, intro, ...props }: ChallengeAddHeadingProps) => {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <Text.Micro className={styles.SupTitle}>{t('DOMAIN.CHALLENGE.ADD.TITLE')}</Text.Micro>

      <Box mt={1} mb={3}>
        <Text.H2>{title}</Text.H2>
      </Box>

      <Text.Large>{intro}</Text.Large>
    </div>
  );
};
