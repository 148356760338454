import { IBaseModel } from '@Constants/interfaces';

export enum MilestoneState {
  DISABLED = 'DISABLED',
  AVAILABLE = 'AVAILABLE',
  CLAIMED = 'CLAIMED'
}

export interface IMilestone extends IBaseModel {
  id: string;
  key: string;
  name: string;
  description: string;
  state: MilestoneState;
}

export interface MilestoneClaimRequest {
  id: string;
}

export type MilestoneAllResponse = IMilestone[];
