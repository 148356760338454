import { IBaseModel } from '@Constants/interfaces';

export interface IPreprPreference extends IBaseModel {
  preprId: string;
  title: string;
}

export enum GgcType {
  HEALTH = 'HEALTH',
  MOTIVATION = 'MOTIVATION',
  COMPETENCE = 'COMPETENCE'
}

export interface IGgcItem extends IPreprPreference {
  preprId: string;
  title: string;
  description: string;
  type: GgcType;
}

export type DataGetGgcResponse = IGgcItem[];

export interface ActivityType extends IPreprPreference {
  description: string;
  selected: boolean;
}

export type DataGetActivityTypesResponse = ActivityType[];

export interface ActivitySubject extends IPreprPreference {
  description?: string;
  selected: boolean;
}

export type ActivitySubjectsResponse = ActivitySubject[];
