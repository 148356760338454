import cn from 'classnames';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ActivityItemLarge } from '@Components/container';
import { Spacer } from '@Components/ui';
import { PinState } from '@Constants/enums';
import { useActivityPin } from '@Hooks/activity/useActivityPin';
import { useApiError } from '@Hooks/common';
import { IActivity } from '@Services/Activity';

import styles from './ListActivityItemLarge.module.scss';
import { ListActivityItemLargeProps } from './ListActivityItemLarge.props';

export const ListActivityItemLarge = ({
  activities,
  canPin = false,
  canClickItemToPin = false,
  onPinSuccess,
  className
}: ListActivityItemLargeProps) => {
  const { handleError } = useApiError();
  const activityPinMutation = useActivityPin();
  const { t } = useTranslation();

  const handlePinToggle = useCallback(
    (activity: IActivity) => async () => {
      if (activity.isPartOfChallenge) {
        toast.info(t('DOMAIN.ACTIVITY.PIN.IS_PART_OF_CHALLENGE'));
        return;
      }

      try {
        await activityPinMutation.mutateAsync(activity);

        if (onPinSuccess) {
          onPinSuccess();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [activityPinMutation, handleError, onPinSuccess, t]
  );

  return (
    <div className={cn(styles.ListActivityItemLarge, className)}>
      {activities.map((activity, idx) => {
        const pinState = activity.pinned ? PinState.PINNED : PinState.UNPINNED;
        const isLastChild = idx === activities.length - 1;

        return (
          <Fragment key={activity.id}>
            <ActivityItemLarge
              activity={activity}
              pinState={pinState}
              onPinClick={canPin ? handlePinToggle(activity) : undefined}
              className={cn(styles.ActivityItem, {
                [styles.isLast]: isLastChild
              })}
              canClickToPin={canClickItemToPin}
            />

            {!isLastChild && <Spacer className={styles.Spacer} />}
          </Fragment>
        );
      })}
    </div>
  );
};
