import { SortOption } from '@Components/container';
import { SortDirection } from '@Constants/enums';
import { i18n } from '@Utils/TranslationUtils';

export const sortOptions: SortOption[] = [
  {
    label: i18n.t('DOMAIN.REWARD.OVERVIEW.FILTER.SORT.ADDED_DATE'),
    value: 'publish_on',
    direction: [SortDirection.ASC, SortDirection.DESC]
  },

  {
    label: i18n.t('DOMAIN.REWARD.OVERVIEW.FILTER.SORT.MOST_VIEWED'),
    value: 'popular',
    direction: [SortDirection.DESC]
  },

  {
    label: i18n.t('DOMAIN.REWARD.OVERVIEW.FILTER.SORT.SCORE'),
    value: 'points',
    direction: [SortDirection.ASC, SortDirection.DESC]
  }
];
