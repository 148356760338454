import cn from 'classnames';

import styles from './Large.module.scss';
import { LargeProps } from './Large.props';

export const Large = ({ className, children, dangerousHtml, ...props }: LargeProps) => {
  if (!children && !dangerousHtml) return null;

  const classNames = cn(styles.Large, className);
  return (
    <p
      className={classNames}
      dangerouslySetInnerHTML={!!dangerousHtml ? { __html: dangerousHtml } : undefined}
      {...props}
    >
      {children}
    </p>
  );
};
