import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonGroup } from '@Components/ui';
import { useRewardFilters } from '@Hooks/reward';

import { RewardFilterTypeProps } from './RewardFilterType.props';

export const RewardFilterType = ({ onChange }: RewardFilterTypeProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);
  const { data: filters } = useRewardFilters();

  const types = useMemo(() => {
    return filters?.map((filter) => {
      return {
        label: filter.title
      };
    });
  }, [filters]);

  const handleChange = useCallback(
    (id: number) => {
      setValue(id);

      if (onChange && filters) {
        onChange(filters[id - 1]?.id);
      }
    },
    [onChange, filters]
  );

  return (
    <ButtonGroup
      activeIdx={value}
      buttons={[
        { label: t('DOMAIN.REWARD.OVERVIEW.FILTER.TYPE.ALL') },
        ...(types || [{ label: '...' }])
      ]}
      onChange={handleChange}
    />
  );
};
