import { useContext } from 'react';

import { AppError } from '@Constants/enums';
import { authenticationContext } from '@Context/auth';

export const useAuthStatus = () => {
  const authContext = useContext(authenticationContext);

  if (!authContext) {
    throw new Error(AppError.NO_CONTEXT_SET);
  }

  return authContext;
};
