import { flatten } from 'lodash';

import { SortDirection } from '@Constants/enums';
import { InputOption } from '@Constants/interfaces';
import { i18n } from '@Utils/TranslationUtils';

import { SortOption } from '../Sort.props';

export const getFormattedOptions = (options: SortOption[]): InputOption[] => {
  const getDirectionLabel = (direction: SortDirection) => {
    if (direction === SortDirection.ASC) {
      return i18n.t('COMMON.GLOBALS.ASCENDING');
    }

    return i18n.t('COMMON.GLOBALS.DESCENDING');
  };

  return flatten(
    options.map((option) => {
      return option.direction.map((direction) => {
        return {
          description: option.label,
          label: `${option.label} - ${getDirectionLabel(direction)}`,
          value: `${option.value}___${direction}`
        };
      });
    })
  );
};
