import { format } from 'date-fns';

import { IActivity } from '@Services/Activity';

export const getTimelineActivities = (activities: IActivity[]) => {
  return activities
    .filter((activity) => activity.completedAt)
    .sort((activityA, activityB) => {
      // keeps typescript happy, even though we've already filtered
      if (!activityA.completedAt || !activityB.completedAt) {
        return 0;
      }

      if (activityA.completedAt > activityB.completedAt) {
        return -1;
      }

      if (activityA.completedAt < activityB.completedAt) {
        return 1;
      }

      return 0;
    })
    .reduce((groups: Record<string, IActivity[]>, activity) => {
      const month = format(new Date(activity.completedAt ?? ''), 'yyyy-MM');

      return {
        ...groups,
        [month]: [...(groups[month] ?? []), activity]
      };
    }, {});
};
