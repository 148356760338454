import cn from 'classnames';

import { Icon } from '@Components/ui';

import styles from './ProfilePicture.module.scss';
import { ProfilePictureProps } from './ProfilePicture.props';

export const ProfilePicture = ({
  image,
  size = 64,
  isSmall = false,
  className
}: ProfilePictureProps) => {
  return (
    <div
      className={cn(styles.ProfilePicture, { [styles.isSmall]: isSmall }, className)}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      {image ? (
        <img src={image} className={styles.Image} />
      ) : (
        <Icon icon={['fal', 'camera']} size="xl" className={styles.Icon} />
      )}
    </div>
  );
};
