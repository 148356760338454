import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthLoginForm } from '@Components/form';
import { Anchor, Box, SliderFullPage, Text, useSliderFullPageItemContext } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';
import { LoginResponse } from '@Services/AuthService';

import { LoginCredentialsSlideProps } from './LoginCredentialsSlide.props';

export const LoginCredentialsSlide = ({ onSuccess }: LoginCredentialsSlideProps) => {
  const { t } = useTranslation();
  const { slideNext } = useSliderFullPageItemContext();

  const handleSuccess = useCallback(
    (loginResponse: LoginResponse) => {
      onSuccess(loginResponse);
      slideNext();
    },
    [slideNext, onSuccess]
  );

  return (
    <SliderFullPage.Item showPagination>
      <SliderFullPage.Head
        title={t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.HEADER.TITLE')}
        showPagination
      >
        <Text.Large>
          {t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.HEADER.DESCRIPTION_1')}

          <Anchor to={AppRoutes.getRegistration().to}>
            {t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.HEADER.ANCHOR_TEXT')}
          </Anchor>

          {t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.HEADER.DESCRIPTION_2')}
        </Text.Large>
      </SliderFullPage.Head>

      <SliderFullPage.Block title={t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.FORM.TITLE')}>
        <AuthLoginForm onSuccess={handleSuccess} />
      </SliderFullPage.Block>

      <Box ml={4}>
        <Anchor to={AppRoutes.getForgotPassword().to}>{AppRoutes.getForgotPassword().label}</Anchor>
      </Box>
    </SliderFullPage.Item>
  );
};
