import { PLATFORMS } from '@Constants/configs';
import { Platform, StorageKey } from '@Constants/enums';
import { Theme, themes } from '@Constants/themes';
import { getItem } from '@Utils/StorageUtils';
import { i18n } from '@Utils/TranslationUtils';

export const getPlatform = (): Platform => {
  const pathname = window.location.hostname;

  for (const key in PLATFORMS) {
    const values = PLATFORMS[key as Platform];

    if (values.includes(pathname)) {
      return key as Platform;
    }
  }

  // No matching URL structure found (localhost or Vercel) so returning the value stored in the localStorage...
  return getItem<Platform>(StorageKey.PLATFORM) || Platform.KOM_VERDER;
};

export const getTheme = (): Theme => {
  return themes[getPlatform()];
};

export const getCSSVariables = (theme: any) => {
  const serialize = (obj: any, prefix = '-', results: Record<string, string> = {}) => {
    if (typeof obj === 'string') {
      results[prefix] = obj;
    } else if (typeof obj === 'object') {
      for (const key in obj) {
        if (key === 'default') {
          serialize(obj[key], prefix ? `${prefix}` : key, results);
        } else {
          serialize(obj[key], prefix ? `${prefix}-${key}` : key, results);
        }
      }
    }

    return results;
  };

  return serialize(theme);
};

export const setupTheme = () => {
  const description = document.getElementById('description') as HTMLMetaElement;
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  const appleTouchIcon = document.getElementById('apple-touch-icon') as HTMLLinkElement;

  const platform = getPlatform();
  const theme = getTheme();
  const variables = getCSSVariables(theme);

  for (const key in variables) {
    document.documentElement.style.setProperty(key, variables[key]);
  }

  if (document && description) {
    document.title = i18n.t(`DOMAIN.PLATFORM.${platform}.TITLE`);
    description.content = i18n.t(`DOMAIN.PLATFORM.${platform}.DESCRIPTION`);
    favicon.href = theme.assets.faviconIco;
    appleTouchIcon.href = theme.assets.appleTouchIcon;
  }
};
