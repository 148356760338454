import cn from 'classnames';
import { forwardRef } from 'react';

import { Icon } from '@Components/ui';

import styles from './IconCircle.module.scss';
import { IconCircleProps } from './IconCircle.props';

export const IconCircle = forwardRef(
  (
    { color, iconColor, icon, size = 'sm', className, onClick, ...props }: IconCircleProps,
    ref: any
  ) => {
    const classNames = cn(styles.IconCircle, className, {
      [styles.isSizeSm]: size === 'sm',
      [styles.isSizeMd]: size === 'md'
    });

    const style = {
      backgroundColor: color
    };

    return (
      <div className={classNames} style={style} onClick={onClick} ref={ref} {...props}>
        {icon && (
          <Icon icon={['fas', icon]} className={styles.Icon} size={size} color={iconColor} />
        )}
      </div>
    );
  }
);
