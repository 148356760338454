import cn from 'classnames';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Avatar, ButtonIcon, Dropdown, MenuItem, Text } from '@Components/ui';
import { MAX_PERCENT } from '@Constants/configs';
import { useAuthLogout } from '@Hooks/auth/useAuthLogout';
import { TargetAlignment, useApiError, useBoolean } from '@Hooks/common';
import { useUser, useUserProfileProgress, useUserToggleTourdots } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';
import { Tabs } from '@Screens/Profile/Profile/internal/components/ProfileTabs/ProfileTabs.props';
import { getImageUrl } from '@Utils/ImageUtils';
import { toQueryString } from '@Utils/StringUtils';

import { ButtonNotification } from './internal/components';
import { useMenuItemsProfile } from './internal/hooks';
import styles from './ProfileMenu.module.scss';
import { ProfileMenuProps } from './ProfileMenu.props';

export const ProfileMenu = ({
  isReversed = false,
  isSmall = false,
  className,
  ...props
}: ProfileMenuProps) => {
  const { firstName, avatar, unreadMessageCount, level } = useUser();
  const { t } = useTranslation();
  const authLogoutMutation = useAuthLogout();
  const userToggleTourdotsMutation = useUserToggleTourdots();
  const { handleError } = useApiError();
  const notificationParams = toQueryString({ active: Tabs.MESSAGES });

  const {
    value: isSubmenuOpen,
    toggle: toggleSubmenuOpen,
    setFalse: setSubmenuClosed
  } = useBoolean(false);
  const submenuTarget = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const menuItems = useMenuItemsProfile();

  const profilePercent = useUserProfileProgress();

  const componentClasses = cn(
    styles.ProfileMenu,
    {
      [styles.isReversed]: isReversed,
      [styles.isSmall]: isSmall
    },
    className
  );

  const handleRestart = useCallback(async () => {
    try {
      await userToggleTourdotsMutation.mutateAsync({
        hide: false
      });

      toast.info(t('DOMAIN.FAQ.QUESTIONS.RESTART_TOUR_SUCCESS'));

      navigate(AppRoutes.getDashboard().to);
    } catch (e) {
      handleError(e);
    }
  }, [handleError, userToggleTourdotsMutation, navigate, t]);

  const handleLogout = useCallback(async () => {
    await authLogoutMutation.mutateAsync();
  }, [authLogoutMutation]);

  return (
    <>
      <div className={componentClasses} {...props}>
        <ButtonNotification
          to={AppRoutes.getMyProfile(`?${notificationParams}`).to}
          count={unreadMessageCount}
        />

        <div className={styles.Content}>
          <div
            ref={submenuTarget}
            className={styles.Profile}
            onClick={toggleSubmenuOpen}
            data-tourdotid="PROFILE"
          >
            <Text.Small className={styles.Name}>
              {t('COMPONENTS.HEADER.WELCOME', { name: firstName })}
            </Text.Small>

            <ButtonIcon
              icon="chevron-down"
              label={t('COMPONENTS.HEADER.MY_PROFILE')}
              isReversed
              data-testid="profileMenuToggle"
              className={cn(styles.Button, { [styles.isOpen]: isSubmenuOpen })}
            />

            <Dropdown
              targetRef={submenuTarget}
              alignment={TargetAlignment.RIGHT}
              isOpen={isSubmenuOpen}
              onClose={setSubmenuClosed}
              className={styles.Submenu}
            >
              {menuItems.map((menuItem, idx) => {
                return <MenuItem key={idx} {...menuItem} isOpaque className={styles.Item} />;
              })}

              <MenuItem
                onClick={handleRestart}
                label={t('DOMAIN.FAQ.QUESTIONS.RESTART_TOUR')}
                isOpaque
                className={styles.Item}
              />

              <MenuItem
                onClick={handleLogout}
                label={t('COMPONENTS.HEADER.LOGOUT')}
                isOpaque
                className={styles.Item}
                data-testid="logoutButton"
              />
            </Dropdown>
          </div>

          <Avatar
            image={avatar && getImageUrl(avatar)}
            level={level}
            className={styles.Avatar}
            total={MAX_PERCENT}
            current={profilePercent}
            isSmall={isSmall}
          />
        </div>
      </div>
    </>
  );
};
