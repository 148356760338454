import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Anchor, Button, Form, SpacingGroup } from '@Components/ui';
import { UserPopupType } from '@Services/UserPopup';
import { objectToInputOptions } from '@Utils/FormUtils';
import { percentageToSelectedAnswer } from '@Utils/QuestionnaireUtils';
import Yup from '@Utils/YupUtils';

import styles from './UserPopupForm.module.scss';
import { UserPopupFormProps, UserPopupFormValues } from './UserPopupForm.props';

export const UserPopupForm = ({ popup, onSubmit, onCancel, className }: UserPopupFormProps) => {
  const { t } = useTranslation();

  const adaptSliderStepValues = useCallback(
    ({ answer }: UserPopupFormValues) => {
      if (typeof answer === 'number') {
        const selected = percentageToSelectedAnswer(popup.answers, answer);

        return { answer: selected.id };
      }
    },
    [popup]
  );

  const handleSubmit = useCallback(
    (values: UserPopupFormValues) => {
      if (onSubmit) {
        if (popup.type === UserPopupType.SLIDER_STEP) {
          values = adaptSliderStepValues(values) ?? values;
        }

        onSubmit(values);
      }
    },
    [onSubmit, popup, adaptSliderStepValues]
  );

  const answers = useMemo(() => {
    return objectToInputOptions(popup.answers, 'text');
  }, [popup.answers]);

  const marks = useMemo(() => {
    return popup.answers.map((answer) => answer.text);
  }, [popup.answers]);

  const validationSchema = Yup.object().shape({
    answer: Yup.mixed().required()
  });

  return (
    <div className={className}>
      <Form.Form<UserPopupFormValues> onSubmit={handleSubmit} validationSchema={validationSchema}>
        {popup.type === UserPopupType.RADIO && (
          <Form.Input.Radio
            name="answer"
            data-testid="answerInput"
            options={{
              options: answers,
              isSmall: true
            }}
            className={styles.Input}
          />
        )}

        {popup.type === UserPopupType.SLIDER && (
          <Form.Input.Slider
            name="answer"
            data-testid="answerInput"
            options={{
              leftLabel: popup.scaleMinDescription,
              rightLabel: popup.scaleMaxDescription,
              isSmall: true
            }}
            className={styles.Input}
          />
        )}

        {popup.type === UserPopupType.SLIDER_STEP && (
          <Form.Input.Slider
            name="answer"
            data-testid="answerInput"
            options={{
              marks: marks,
              leftLabel: popup.scaleMinDescription,
              rightLabel: popup.scaleMaxDescription,
              isSmall: true
            }}
            className={styles.Input}
          />
        )}

        <SpacingGroup size="xl">
          <Button type="submit" icon="arrow-right" data-testid="submitButton">
            {t('DOMAIN.USER.POPUP.SUBMIT')}
          </Button>

          {onCancel && (
            <Anchor onClick={onCancel} data-testid="cancelButton">
              {t('DOMAIN.USER.POPUP.CANCEL')}
            </Anchor>
          )}
        </SpacingGroup>
      </Form.Form>
    </div>
  );
};
