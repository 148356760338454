import { useTranslation } from 'react-i18next';

import { useQuestionnaireContext } from '@Components/container/Questionnaire/internal/context';
import {
  Box,
  SliderFullPage,
  SliderNavigation,
  Text,
  useSliderFullPageContext
} from '@Components/ui';

import { QuestionnairePersonaIntroSlideProps } from './QuestionnairePersonaIntroSlide.props';

export const QuestionnairePersonaIntroSlide = ({
  currentSlideIndex,
  totalSlides
}: QuestionnairePersonaIntroSlideProps) => {
  const { t } = useTranslation();
  const { submit, isLoadingNext, isLoadingPrevious } = useQuestionnaireContext();
  const { isAnimating } = useSliderFullPageContext();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        superTitle={t('DOMAIN.PERSONA_QUESTIONNAIRE.INTRO_SLIDE.HEAD.SUPER_TITLE')}
        title={t('DOMAIN.PERSONA_QUESTIONNAIRE.INTRO_SLIDE.HEAD.TITLE')}
      />

      <SliderFullPage.Block>
        <Text.Normal>{t('DOMAIN.PERSONA_QUESTIONNAIRE.INTRO_SLIDE.BODY.DESCRIPTION')}</Text.Normal>

        <Box mt={4}>
          <SliderNavigation
            nextLabel={t('COMPONENTS.QUESTIONNAIRE.NEXT_LABEL')}
            onNext={submit}
            current={currentSlideIndex}
            total={totalSlides}
            isLoadingNext={isLoadingNext}
            isLoadingPrevious={isLoadingPrevious}
            isDisabled={isAnimating}
          />
        </Box>
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
