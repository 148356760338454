import { useMutation, useQueryClient } from 'react-query';

import { ActivityFinishStepRequest, activityService } from '@Services/Activity';

export const useActivityFinishStep = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ slug, ...config }: ActivityFinishStepRequest) => {
      return activityService.finishStep(config);
    },
    {
      onSuccess: (_, { slug }) => {
        queryClient.invalidateQueries([activityService.getEndpoint(), slug]);
      }
    }
  );
};
