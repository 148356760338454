import * as Sentry from '@sentry/react';

import { IS_LOCAL, SENTRY_DSN } from '@Constants/configs';
import { IUser } from '@Services/User';

const environment = [
  {
    key: 'production',
    value: 'staging'
  },
  {
    key: 'preview',
    value: 'preview'
  },
  {
    key: 'development',
    value: 'development'
  }
].find((env) => env.key === process.env.REACT_APP_VERCEL_ENV);

Sentry.init({
  dsn: SENTRY_DSN,
  environment: environment ? environment.value : 'production',
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.'
  ],
  enabled: !IS_LOCAL
});

export const setSentryUser = (user: IUser) => {
  Sentry.setUser({
    id: user.id,
    email: user.email
  });
};

export const setupSentry = () => {
  return !IS_LOCAL;
};

export default Sentry;
