import cn from 'classnames';
import { MutableRefObject, useCallback, useRef, useState } from 'react';

import { useBoolean, usePlayInView } from '@Hooks/common';

import { Labels, Paths, Score } from './internal/components';
import { ScoreCircleContext } from './internal/context';
import { useScoreCircleDynamicHeight } from './internal/hooks/useScoreCircleDynamicHeight';
import styles from './ScoreCircle.module.scss';
import { ScoreCircleProps } from './ScoreCircle.props';

export const ScoreCircle = ({
  scores,
  total,
  innerLabel,
  onClick,
  activeIdx,
  color,
  isDisabled,
  className
}: ScoreCircleProps) => {
  const componentRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [hoveringDomain, setHoveringDomain] = useState<number | null>(null);
  const isVisible = useBoolean(false);

  const [componentHeight] = useScoreCircleDynamicHeight(componentRef, scores);
  const [inViewRef] = usePlayInView(isVisible.setTrue);

  const handleHover = useCallback(
    (idx: number | null) => () => {
      setHoveringDomain(idx);
    },
    []
  );

  const handleClick = useCallback(
    (idx: number) => () => {
      if (onClick && !isDisabled && scores[idx].current > 0) {
        onClick(idx);
      }
    },
    [onClick, isDisabled, scores]
  );

  const contextValues = {
    scores,
    total,
    hoveringDomain,
    setHoveringDomain,
    componentRef,
    isVisible: isVisible.value,
    activeIdx,
    handleHover,
    handleClick,
    isDisabled
  };

  return (
    <ScoreCircleContext.Provider value={contextValues}>
      <div
        ref={componentRef}
        className={cn(styles.ScoreCircle, className)}
        style={{ height: componentHeight, width: componentHeight }}
      >
        <div ref={inViewRef}>
          <Paths />

          <Labels />

          <Score label={innerLabel} color={color} />
        </div>
      </div>
    </ScoreCircleContext.Provider>
  );
};
