import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';

import { SelfTestPhasedClosedBlock, SelfTestRegularClosedBlock } from './internal/components';
import { SelfTestClosedBlockProps } from './SelfTestClosedBlock.props';

export const SelfTestClosedBlock = ({ selfTest, className }: SelfTestClosedBlockProps) => {
  if (selfTest.isPhased) {
    return <SelfTestPhasedClosedBlock className={className} selfTest={selfTest} />;
  }

  if (selfTest.status === QuestionnaireSubmissionStatus.COMPLETED) {
    return <SelfTestRegularClosedBlock className={className} selfTest={selfTest} />;
  }

  return null;
};
