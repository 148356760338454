import { useTranslation } from 'react-i18next';

import { Container } from '@Components/layout';
import { Anchor, Box, Text } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

import { ActivityBlock } from './internal/components';

export const ActivityOverviewScreen = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Text.H1>{t('DOMAIN.ACTIVITY.OVERVIEW.TITLE')}</Text.H1>

      <Box mt={2} mb={7}>
        <Text.Large>
          {t('DOMAIN.ACTIVITY.OVERVIEW.DESCRIPTION')}{' '}
          <Anchor to={AppRoutes.getMyProfile().to}>{`’${AppRoutes.getMyProfile().label}’`}</Anchor>
          {'.'}
        </Text.Large>
      </Box>

      <ActivityBlock />
    </Container>
  );
};
