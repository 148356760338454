export const validateHex = (color: string) => {
  if (color.length !== 7) return false;
  if (!color.startsWith('#')) return false;

  color = color.substring(1);

  return !isNaN(parseInt(color, 16));
};

// Based on calculation found on
// https://contrastchecker.online/color-relative-luminance-calculator
export const calculateLuminosity = (color: string) => {
  if (!validateHex(color)) {
    throw new Error('calculateLuminosity: Invalid hex supplied');
  }

  color = color.substring(1);
  const rgb = parseInt(color, 16);

  let r = (rgb >> 16) & 0xff;
  let g = (rgb >> 8) & 0xff;
  let b = (rgb >> 0) & 0xff;

  r = r / 255;
  g = g / 255;
  b = b / 255;

  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const isDark = (color: string) => {
  if (!validateHex(color)) {
    throw new Error('isDark: Invalid hex supplied');
  }

  const luma = calculateLuminosity(color);

  return luma < 0.45;
};
