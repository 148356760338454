import cn from 'classnames';

import { ButtonMobileMenu, ProfileMenu } from '@Components/layout';
import { Anchor } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { useUser } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';

import styles from './HeaderDefault.module.scss';
import { HeaderDefaultProps } from './HeaderDefault.props';

export const HeaderDefault = ({ onMenuToggleClick, className }: HeaderDefaultProps) => {
  const { unreadMessageCount } = useUser();
  const { assets } = usePlatform();

  return (
    <div className={cn(styles.HeaderDefault, className)}>
      <Anchor to={AppRoutes.getDashboard().to} className={styles.Logo}>
        <img src={assets.logo.default} />
      </Anchor>

      <div className={styles.Content}>
        <ButtonMobileMenu
          onClick={onMenuToggleClick}
          count={unreadMessageCount}
          className={styles.ButtonMobileMenu}
        />

        <ProfileMenu className={styles.ProfileMenu} />
      </div>
    </div>
  );
};
