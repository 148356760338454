import { useTranslation } from 'react-i18next';

import { BackButton, PinActivity } from '@Components/container';
import { ActivityInviteForm } from '@Components/form';
import { Modal } from '@Components/layout';
import {
  Anchor,
  Block,
  Box,
  IconText,
  Image,
  Spacer,
  SpacingGroup,
  Tag,
  TagText,
  Text
} from '@Components/ui';
import { useBoolean, usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';
import { getImageSrc } from '@Utils/PreprUtils';
import { toLocaleString } from '@Utils/StringUtils';

import styles from './ActivityHeader.module.scss';
import { ActivityHeaderProps } from './ActivityHeader.props';

export const ActivityHeader = ({ activity }: ActivityHeaderProps) => {
  const { t } = useTranslation();
  const {
    value: isPopupOpen,
    setFalse: setInviteClose,
    setTrue: setInviteOpen
  } = useBoolean(false);
  const { color } = usePlatform();

  return (
    <div className={styles.ActivityHeader}>
      <div className={styles.Header}>
        <div className={styles.Navigation}>
          <BackButton to={AppRoutes.getActivities().to} />
        </div>

        <div className={styles.Activity}>
          <Box mb={0.5}>
            <Text.H1 className={styles.Title}>{activity.title}</Text.H1>
          </Box>

          {activity.challenge && (
            <Text.Normal>
              {t('DOMAIN.ACTIVITY.DETAIL.PART_OF_CHALLENGE') + ': "'}
              <Anchor to={AppRoutes.getInsightChallenge(activity.challenge.slug).to}>
                {activity.challenge.title}
              </Anchor>
              {'"'}
            </Text.Normal>
          )}
        </div>

        <Spacer top={1.5} bottom={1.5} />

        <div className={styles.Meta}>
          <SpacingGroup size="md">
            {!activity.completed && <PinActivity size="lg" activity={activity} />}

            <Tag
              size="lg"
              color={color.primary.default}
              label={toLocaleString(activity.points)}
              icon="star"
            />

            {activity.popular && (
              <Tag
                size="lg"
                color={color.font.default}
                label={t('DOMAIN.ACTIVITY.POPULAR')}
                icon="users"
              />
            )}
          </SpacingGroup>

          {activity.completed ? (
            <TagText
              tagProps={{
                size: 'xs',
                icon: 'check',
                color: color.success.default
              }}
              label={t('DOMAIN.ACTIVITY.FINISHED')}
            />
          ) : (
            <IconText color={color.gray.dark} icon="clock" isReversed>
              <Text.Normal color={color.gray.dark} className={styles.DurationLabel}>
                {activity.duration[0].body}
              </Text.Normal>
            </IconText>
          )}
        </div>
      </div>

      {!!activity.banner && (
        <Block padding="none" isElevated>
          {!!activity.banner?.[0] && <Image src={getImageSrc(activity.banner)} ratio="2:1" />}
        </Block>
      )}

      <Anchor
        icon="envelope"
        onClick={setInviteOpen}
        className={styles.Share}
        data-testid="inviteCollegueAnchor"
      >
        {t('DOMAIN.ACTIVITY.SHARE')}
      </Anchor>

      <Modal title={t('DOMAIN.ACTIVITY.SHARE')} isOpen={isPopupOpen} onClose={setInviteClose}>
        <ActivityInviteForm activity={activity} onSuccess={setInviteClose} />
      </Modal>
    </div>
  );
};
