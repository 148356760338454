import { IconName } from '@fortawesome/fontawesome-common-types';
import { useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';

import { ListMilestone } from '@Components/list';
import { useListMeasurements, usePlatform } from '@Hooks/common';
import { useMilestoneAll } from '@Hooks/milestone';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import { DashboardMilestonesProps } from './DashboardMilestones.props';
import { MILESTONES_ANIMATION_DURATION } from './internal/constants';
import { useMilestoneListData, useRowHandler } from './internal/hooks';
import { getCurrentHeight } from './internal/utils';

export const DashboardMilestones = ({ className }: DashboardMilestonesProps) => {
  const milestoneRefs = useRef<Array<HTMLDivElement>>([]);

  const { easing } = usePlatform();
  const { t } = useTranslation();

  const measurements = useListMeasurements(milestoneRefs.current);
  const { totalRows, elementsPerRow } = useMilestoneListData(measurements);
  const { currentRow, loadMore } = useRowHandler(totalRows);

  const { data: milestones } = useMilestoneAll();

  const currentHeight = getCurrentHeight(measurements, elementsPerRow, currentRow);
  const isShowingAllMedals = currentRow >= totalRows;

  const toggleLabel = isShowingAllMedals
    ? t('COMPONENTS.MEDAL.CLOSE_OVERVIEW')
    : t('COMPONENTS.MEDAL.SHOW_ALL');

  const toggleIcon: IconName = isShowingAllMedals ? 'chevron-up' : 'chevron-down';

  return (
    <>
      <DashboardBlock
        widgetKey="MILESTONES"
        footerLink={{ label: toggleLabel, icon: toggleIcon, onClick: loadMore }}
        className={className}
        data-tourdotid="MILESTONES"
      >
        <AnimateHeight
          height={currentHeight}
          duration={MILESTONES_ANIMATION_DURATION}
          easing={easing.out}
        >
          <ListMilestone refs={milestoneRefs} milestones={milestones} />
        </AnimateHeight>
      </DashboardBlock>
    </>
  );
};
