import cn from 'classnames';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { ButtonIcon, Text } from '@Components/ui';
import { useAnimatedToggle, useLockedBody } from '@Hooks/common';

import { MODAL_ANIMATION_DURATION } from './internals/constants';
import styles from './Modal.module.scss';
import { ModalProps } from './Modal.props';

export const Modal = ({
  title,
  children,
  head,
  isOpen,
  onClose,
  className,
  ...props
}: ModalProps) => {
  const [, setLocked] = useLockedBody(isOpen);
  const { isShowing } = useAnimatedToggle(isOpen, MODAL_ANIMATION_DURATION);

  useEffect(() => {
    setLocked(isOpen);
  }, [isOpen, setLocked]);

  if (isShowing) {
    return ReactDOM.createPortal(
      <div className={cn(styles.Modal, { [styles.isOpen]: isOpen }, className)} {...props}>
        <div className={styles.Overlay} onClick={onClose} />

        <div className={styles.Card}>
          {head}

          <div className={styles.Content}>
            <Text.H4 className={styles.Title}>{title}</Text.H4>

            <ButtonIcon
              icon="times"
              onClick={onClose}
              className={styles.Close}
              data-testid="modal-close"
            />

            {children}
          </div>
        </div>
      </div>,
      document.body
    );
  }

  return null;
};
