import cn from 'classnames';

import { CountNotifier, Icon } from '@Components/ui';

import styles from './ButtonMobileMenu.module.scss';
import { ButtonMobileMenuProps } from './ButtonMobileMenu.props';

export const ButtonMobileMenu = ({ onClick, count, className }: ButtonMobileMenuProps) => {
  return (
    <CountNotifier count={count} className={cn(styles.CountNotifier, className)}>
      <div onClick={onClick} className={styles.ButtonMobileMenu}>
        <Icon icon="bars" size="lg" />
      </div>
    </CountNotifier>
  );
};
