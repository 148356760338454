import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@Utils/PlatformUtils';
import { spacing } from '@Utils/ThemeUtils';

export const styles = StyleSheet.create({
  DomainBlock: {
    border: `1px solid ${getTheme().color.gray.default}`,
    borderRadius: getTheme().radius.block,
    backgroundColor: getTheme().color.gray.background,
    padding: spacing(3)
  },
  Title: {
    marginBottom: spacing(1)
  }
});
