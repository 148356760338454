import { IconName } from '@fortawesome/fontawesome-common-types';

import APIERRORS from '@Locales/nl/common/APIERRORS.json';
import { DOTS } from '@Locales/nl/components/TOURDOT.json';
import { IChallenge } from '@Services/Challenge';

import { ApiErrorCode, SortDirection } from './enums';

export interface IBaseActivity extends IBaseModel {
  title: string;
  isPartOfChallenge: boolean;
  points: number;
  challenge?: IChallenge;
  completedAt?: string;
  completed: boolean;
  isPreprActivity: boolean;
  updatedAt: string;
  startedAt: string;
}

export interface BaseInputProps {
  isError?: boolean;
  isValid?: boolean;
}

export interface InputOption {
  value: any;
  label: string;
  description?: string;
  disabled?: boolean;
}

export interface IBaseModel {
  id: string;
}

export interface OneRequest {
  id: string;
}

export interface ListRequest {
  sortBy?: string;
  sortDirection?: SortDirection;
  page?: number;
  perPage?: number;
  searchPhrase?: string;
  exclude?: string[];
  key?: string;
}

export interface ListResponse<T> {
  results: T[] | null;
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
  sortBy: string;
  sortDirection: SortDirection;
  filters: any;
  errors: any[];
}

export interface ApiError {
  code: ApiErrorCode;
  additional: string | null;
  error?: string;
}

export type ApiErrorDomain = keyof typeof APIERRORS;
export interface HandledError extends ApiError {
  domain: ApiErrorDomain;
}

export interface MenuRouteType {
  icon?: IconName;
  label: string;
  to?: string;
  href?: string;
  tourdot?: keyof typeof DOTS;
  count?: number;
  subItems?: MenuRouteType[];
}

export interface IMotivator extends IBaseModel {
  name: string;
  avatar: string;
  thumbnail: string;
  intro: string;
}

export enum Result {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
  RED = 'RED',
  NONE = 'NONE'
}

export type ThemeResultColorMap = {
  [T in Result]: string;
};

export interface IDomain extends IBaseModel {
  title: string;
  description: string;
}

export type HTMLMeasurements = {
  clientHeight: number;
  clientWidth: number;

  offsetHeight: number;
  offsetWidth: number;

  scrollHeight: number;
  scrollWidth: number;

  location: {
    x: number;
    y: number;
  };
};

export interface QuestionnaireSlideProps {
  totalSlides: number;
  currentSlideIndex: number;

  paginationPrefix?: string;

  isLoadingPrevious?: boolean;
  isLoadingNext?: boolean;

  onInfoClick?: () => void;
}
