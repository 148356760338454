import { createContext, useContext } from 'react';

import { AppError } from '@Constants/enums';

export interface TabGroupChildContextType {
  isActive: boolean;
}

export const TabGroupChildContext = createContext<TabGroupChildContextType | null>(null);

export const useTabGroupChildContext = () => {
  const contextValue = useContext(TabGroupChildContext);

  if (!contextValue) {
    throw new Error(AppError.NO_CONTEXT_SET);
  }

  return contextValue;
};
