import { useCallback, useMemo, useState } from 'react';
import SwiperClass from 'swiper/types/swiper-class';

import { SliderFullPage } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import {
  useQuestionnaireSubmissionFinish,
  useQuestionnaireSubmissionFinishPhase,
  useQuestionnaireSubmissionGoToPrevious,
  useQuestionnaireSubmissionSubmit
} from '@Hooks/questionnaireSubmission';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';

import {
  QuestionnaireFormSlide,
  QuestionnaireFormValues,
  QuestionnaireSectionIntroSlide
} from './internal/components';
import { ContentSlideType } from './internal/constants';
import { QuestionnaireProvider } from './internal/context';
import { useInfiniteSliderData } from './internal/hooks';
import { QuestionnaireSliderData } from './internal/types';
import { getInitialSlide, isDataSlide } from './internal/utils';
import { QuestionnaireProps } from './Questionnaire.props';

export const Questionnaire = ({
  className,
  initialSubmission,
  introSlide,
  outroSlide,
  onInfoClick,
  onStepFinish,
  onFinish,
  totalSlides,
  currentSlideIndex,
  title,
  phaseOutroSlide,
  paginationPrefix
}: QuestionnaireProps) => {
  const { handleError } = useApiError();
  const [currentSubmission, setCurrentSubmission] = useState(initialSubmission);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const { append, prepend, data, activeIdx } = useInfiniteSliderData<QuestionnaireSliderData>(
    getInitialSlide(initialSubmission)
  );

  const questionnaireSubmissionSubmitMutation = useQuestionnaireSubmissionSubmit();
  const questionnaireSubmissionGoToPreviousMutation = useQuestionnaireSubmissionGoToPrevious();
  const questionnaireSubmissionFinishMutation = useQuestionnaireSubmissionFinish();
  const questionnaireSubmissionFinishPhaseMutation = useQuestionnaireSubmissionFinishPhase();

  const isLoadingNext =
    questionnaireSubmissionSubmitMutation.isLoading ||
    questionnaireSubmissionFinishMutation.isLoading ||
    questionnaireSubmissionFinishPhaseMutation.isLoading;

  const isLoadingPrevious = questionnaireSubmissionGoToPreviousMutation.isLoading;

  const appendSectionIntro = useCallback(() => {
    append(ContentSlideType.SectionIntro);

    swiper?.slideNext();
  }, [append, swiper]);

  const prependSectionIntro = useCallback(() => {
    prepend(ContentSlideType.SectionIntro);

    swiper?.slidePrev();
  }, [prepend, swiper]);

  const appendCurrentSubmission = useCallback(() => {
    if (currentSubmission.showSectionIntro && data[activeIdx] !== ContentSlideType.SectionIntro) {
      appendSectionIntro();

      return;
    }

    append(currentSubmission);

    swiper?.slideNext();
  }, [activeIdx, append, appendSectionIntro, currentSubmission, data, swiper]);

  const appendOutro = useCallback(() => {
    append(ContentSlideType.QuestionnaireOutro);

    swiper?.slideNext();
  }, [append, swiper]);

  const appendPhaseOutro = useCallback(() => {
    append(ContentSlideType.PhaseOutro);

    swiper?.slideNext();
  }, [append, swiper]);

  const getNewSubmission = useCallback(
    async (answer: QuestionnaireFormValues) => {
      const params = {
        ...answer,
        questionnaireSubmission: currentSubmission
      };

      const newSubmission = await questionnaireSubmissionSubmitMutation.mutateAsync(params);

      setCurrentSubmission(newSubmission);

      return newSubmission;
    },
    [currentSubmission, questionnaireSubmissionSubmitMutation]
  );

  const appendNextSubmission = useCallback(
    async (answer: QuestionnaireFormValues) => {
      const newSubmission = await getNewSubmission(answer);

      if (newSubmission.showSectionIntro) {
        appendSectionIntro();

        return;
      }

      if (newSubmission.status === QuestionnaireSubmissionStatus.PENDING_COMPLETION) {
        appendOutro();

        return;
      }

      if (newSubmission.status === QuestionnaireSubmissionStatus.PENDING_PHASE_COMPLETION) {
        appendPhaseOutro();

        return;
      }

      append(newSubmission);

      swiper?.slideNext();
    },
    [append, appendOutro, appendPhaseOutro, appendSectionIntro, getNewSubmission, swiper]
  );

  const submit = useCallback(
    async (answer: QuestionnaireFormValues) => {
      try {
        const activeItem = data[activeIdx];

        if (isDataSlide(activeItem) === false) {
          appendCurrentSubmission();

          return;
        }

        await appendNextSubmission(answer);

        if (onStepFinish) {
          onStepFinish();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [data, activeIdx, appendNextSubmission, onStepFinish, appendCurrentSubmission, handleError]
  );

  const prependPreviousSubmission = useCallback(async () => {
    try {
      const newSubmission = await questionnaireSubmissionGoToPreviousMutation.mutateAsync({
        questionnaireSubmission: currentSubmission
      });

      prepend(newSubmission);
      setCurrentSubmission(newSubmission);

      swiper?.slidePrev();
    } catch (e) {
      handleError(e);
    }
  }, [
    handleError,
    currentSubmission,
    prepend,
    questionnaireSubmissionGoToPreviousMutation,
    swiper
  ]);

  const prependCurrentSubmission = useCallback(() => {
    prepend(currentSubmission);

    swiper?.slidePrev();
  }, [currentSubmission, prepend, swiper]);

  const goToPrevious = useCallback(async () => {
    const activeItem = data[activeIdx];

    if (isDataSlide(activeItem) === false) {
      if (activeItem === ContentSlideType.SectionIntro) {
        prependPreviousSubmission();
        return;
      }

      prependCurrentSubmission();
      return;
    }

    if (currentSubmission.showSectionIntro && data[activeIdx] !== ContentSlideType.SectionIntro) {
      prependSectionIntro();

      return;
    }

    prependPreviousSubmission();
  }, [
    currentSubmission,
    data,
    activeIdx,
    prependPreviousSubmission,
    prependCurrentSubmission,
    prependSectionIntro
  ]);

  const finish = useCallback(async () => {
    try {
      if (currentSubmission.status === QuestionnaireSubmissionStatus.PENDING_PHASE_COMPLETION) {
        await questionnaireSubmissionFinishPhaseMutation.mutateAsync(currentSubmission);
      } else {
        await questionnaireSubmissionFinishMutation.mutateAsync(currentSubmission);
      }

      if (onStepFinish) {
        onStepFinish();
      }

      if (onFinish) {
        onFinish();
      }
    } catch (e) {
      handleError(e);
    }
  }, [
    currentSubmission,
    onFinish,
    questionnaireSubmissionFinishPhaseMutation,
    questionnaireSubmissionFinishMutation,
    handleError,
    onStepFinish
  ]);

  const contextValue = useMemo(() => {
    return {
      goToPrevious,
      submit,
      finish,
      isLoadingNext,
      isLoadingPrevious
    };
  }, [goToPrevious, submit, finish, isLoadingNext, isLoadingPrevious]);

  const renderSlide = (slideIdx: number) => {
    const slideData = data[slideIdx];

    if (slideData === ContentSlideType.QuestionnaireIntro) {
      return introSlide;
    }

    if (slideData === ContentSlideType.QuestionnaireOutro) {
      return outroSlide;
    }

    if (slideData === ContentSlideType.PhaseOutro) {
      return phaseOutroSlide;
    }

    if (slideData === ContentSlideType.SectionIntro) {
      return (
        <QuestionnaireSectionIntroSlide
          totalSlides={totalSlides}
          currentSlideIndex={currentSlideIndex}
          submission={currentSubmission}
          onInfoClick={onInfoClick}
          title={title}
          isLoadingNext={isLoadingNext}
          isLoadingPrevious={isLoadingPrevious}
          paginationPrefix={paginationPrefix}
        />
      );
    }

    return (
      <QuestionnaireFormSlide
        totalSlides={totalSlides}
        currentSlideIndex={currentSlideIndex}
        questionnaireName={currentSubmission.questionnaire.title}
        submission={slideData}
        onInfoClick={onInfoClick}
        isLoadingNext={isLoadingNext}
        isLoadingPrevious={isLoadingPrevious}
        paginationPrefix={paginationPrefix}
      />
    );
  };

  return (
    <QuestionnaireProvider value={contextValue}>
      <SliderFullPage isLoop className={className} onSwiper={setSwiper}>
        {data.map((_, idx) => {
          return renderSlide(idx);
        })}
      </SliderFullPage>
    </QuestionnaireProvider>
  );
};
