import cn from 'classnames';
import { useCallback } from 'react';

import { IconCircle, ProgressBar, SpacingGroup, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './ProgressRow.module.scss';
import { ProgressRowProps } from './ProgressRow.props';

export const ProgressRow = ({
  label,
  onClick,
  description,
  children,
  total,
  current,
  color,
  isChecked,
  isSmall = false,
  isDisabled,
  className
}: ProgressRowProps) => {
  const { color: themeColor } = usePlatform();

  const classNames = cn(styles.ProgressRow, className, {
    [styles.isChecked]: isChecked,
    [styles.isDisabled]: isDisabled,
    [styles.hasClick]: !!onClick,
    [styles.hasHover]: !isSmall && !onClick,
    [styles.isSmall]: isSmall
  });

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <div className={classNames} onClick={handleClick}>
      {!!onClick && (
        <IconCircle className={styles.Checkbox} icon={isDisabled ? 'clock' : 'check'} size="md" />
      )}

      <div className={styles.Wrapper}>
        <SpacingGroup className={styles.Title}>
          <Text.Medium className={styles.Label}>{label}</Text.Medium>

          <SpacingGroup className={styles.ChildWrapper}>{!!children && children}</SpacingGroup>
        </SpacingGroup>

        {!!description && <Text.Micro className={styles.Description}>{description}</Text.Micro>}

        <ProgressBar
          total={total}
          current={current}
          color={isDisabled ? themeColor.gray.dark : color}
          className={styles.Progress}
        />
      </div>
    </div>
  );
};
