import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@Components/layout';
import { Block, Box, Loader, Text } from '@Components/ui';
import { SortDirection } from '@Constants/enums';
import { Result } from '@Constants/interfaces';
import { useOnboardingGetSelfTestQuestionnaire } from '@Hooks/onboarding';
import { useUserGetDomains } from '@Hooks/user';
import { useUserThemeGetAll } from '@Hooks/userTheme';
import { exists } from '@Utils/CommonUtils';

import { ReportDomains, ReportIntro, ReportScore } from './internal/components';

export const InsightReportScreen = () => {
  const { t } = useTranslation();
  const [activeDomainIdx, setActiveDomainIdx] = useState<number | undefined>(undefined);

  const {
    data: userDomainScores,
    isLoading: isUserDomainScoresLoading,
    isError: isUserDomainScoresError
  } = useUserGetDomains();

  const {
    data: themes,
    isLoading: isThemesLoading,
    isError: isThemesError
  } = useUserThemeGetAll({
    sortDirection: SortDirection.DESC,
    includeWithoutScore: false,
    excludeResults: [Result.NONE]
  });

  const {
    data: selfTestQuestionnaire,
    isLoading: isSelfTestQuestionnaireLoading,
    isError: isSelfTestQuestionnaireError
  } = useOnboardingGetSelfTestQuestionnaire();

  const hasResults = !!userDomainScores && !!userDomainScores.happinessScore;
  const hasPhasedSelfTest =
    !!selfTestQuestionnaire &&
    !!selfTestQuestionnaire.isPhased &&
    !!selfTestQuestionnaire.nextPhaseAt;

  const isLoading = isUserDomainScoresLoading || isSelfTestQuestionnaireLoading || isThemesLoading;
  const isError = isUserDomainScoresError || isSelfTestQuestionnaireError || isThemesError;

  const handleTabChange = useCallback((idx: number) => {
    if (idx === 0) {
      setActiveDomainIdx(undefined);

      return;
    }

    setActiveDomainIdx(idx - 1);
  }, []);

  const handleScoreClick = useCallback((idx: number) => {
    setActiveDomainIdx(idx);
  }, []);

  return (
    <Container>
      <Box mx={4}>
        <Box mb={2}>
          <Text.H1>{t('DOMAIN.REPORT.TITLE')}</Text.H1>
        </Box>

        {isLoading && (
          <Block>
            <Box my={10}>
              <Loader>{t('DOMAIN.REPORT.LOADING')}</Loader>
            </Box>
          </Block>
        )}

        {isError && (
          <Block>
            <Box my={4}>
              <Text.Error>{t('DOMAIN.REPORT.COULD_NOT_FETCH')}</Text.Error>
            </Box>
          </Block>
        )}
      </Box>

      {!!selfTestQuestionnaire && !!userDomainScores && !!themes && (
        <>
          <Box mx={4}>
            <ReportIntro
              hasPhasedSelfTest={hasPhasedSelfTest}
              hasResults={hasResults}
              nextPhaseAvailableAt={selfTestQuestionnaire.nextPhaseAt}
            />
          </Box>

          <Box mt={5}>
            <Block>
              <Text.H3>{t('DOMAIN.REPORT.BODY_TITLE')}</Text.H3>

              <ReportScore
                nextPhaseAvailableAt={selfTestQuestionnaire.nextPhaseAt}
                domainScores={userDomainScores.domainScores}
                happinessScore={userDomainScores.happinessScore}
                happinessResult={userDomainScores.happinessResult}
                onScoreClick={handleScoreClick}
                activeIdx={activeDomainIdx}
              />

              <Box mt={7}>
                <ReportDomains
                  domainScores={userDomainScores.domainScores}
                  themes={themes}
                  onTabChange={handleTabChange}
                  activeTabIdx={exists<number>(activeDomainIdx) ? activeDomainIdx + 1 : 0}
                />
              </Box>
            </Block>
          </Box>
        </>
      )}
    </Container>
  );
};
