import { SliderFullPage } from '@Components/ui';

import { ResetPasswordPasswordSlide } from './internal/ResetPasswordPasswordSlide/ResetPasswordPasswordSlide';
import { ResetPasswordSuccessSlide } from './internal/ResetPasswordSuccessSlide/ResetPasswordSuccessSlide';

export const AuthResetPasswordScreen = () => {
  return (
    <SliderFullPage>
      <ResetPasswordPasswordSlide />

      <ResetPasswordSuccessSlide />
    </SliderFullPage>
  );
};
