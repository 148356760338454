import cn from 'classnames';
import { useCallback, useRef, useState } from 'react';
import SwiperClass from 'swiper/types/swiper-class';

import { HighlightedChallenge } from '@Components/container';
import { Slider } from '@Components/ui';
import { useResizeObserver } from '@Hooks/common/useResizeObserver';

import styles from './SliderHighlightedChallenge.module.scss';
import { SliderHighlightedChallengeProps } from './SliderHighlightedChallenge.props';

export const SliderHighlightedChallenge = ({
  onChange,
  challenges = [],
  className
}: SliderHighlightedChallengeProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleResize = useCallback(() => {
    swiper?.update();
  }, [swiper]);

  useResizeObserver(handleResize, containerRef);

  const handleChange = useCallback(
    (idx: number) => {
      if (onChange) {
        onChange(idx);
      }
    },
    [onChange]
  );

  return (
    <div ref={containerRef} className={cn(styles.SliderHighlightedChallenge, className)}>
      <Slider
        slidesPerView={1}
        onChange={handleChange}
        onSwiper={setSwiper}
        className={styles.Slider}
      >
        {challenges.map((challenge) => {
          return (
            <HighlightedChallenge
              key={challenge.id}
              challenge={challenge}
              className={styles.Item}
            />
          );
        })}
      </Slider>
    </div>
  );
};
