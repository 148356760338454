import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SliderFullPage } from '@Components/ui';
import { useApiError, usePlatform } from '@Hooks/common';
import { usePersistSearchParam } from '@Hooks/common';
import { MatomoABTest, useMatomoTrackEvent } from '@Hooks/matomo';
import { AppRoutes } from '@Navigation/Routes';
import { onboardingService } from '@Services/Onboarding';
import {
  trackMatomoOnboardingAccountStep,
  trackMatomoOnboardingAccountTypeMail
} from '@Utils/MatomoUtils';

import { AuthRegistrationScreenProps } from './AuthRegistrationScreen.props';
import {
  OnboardingRegisterSlide,
  OnboardingSuccessSlide,
  OnboardingVerifyEmailSlide,
  OnboardingVerifyLicenseCodeAlternativeSlide,
  OnboardingVerifyLicenseCodePage,
  OnboardingVerifyLicenseCodeSlide,
  OnboardingVerifyPhoneSlide
} from './internal/components';

export const AuthRegistrationScreen = ({ variant = 'A' }: AuthRegistrationScreenProps) => {
  const navigate = useNavigate();
  const trackEvent = useMatomoTrackEvent();
  const { platform } = usePlatform();
  const { removePersistedSearchParam } = usePersistSearchParam('token');

  const [licenseCode, setLicenseCode] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  useEffect(() => {
    window._paq.push([
      'AbTesting::create',
      {
        name: MatomoABTest.SIGNUP + platform,
        percentage: 100,
        includedTargets: [
          {
            attribute: 'url',
            inverted: '0',
            type: 'equals_simple',
            value: `${window.location.origin}${AppRoutes.getRegistration('A').path}`
          }
        ],
        excludedTargets: [],
        startDateTime: '2022/04/22 08:00:00 UTC',
        variations: [
          {
            name: 'original',
            activate: () => {}
          },
          {
            name: 'Variation1',
            activate: (event: any) => {
              event.redirect(`${window.location.origin}${AppRoutes.getRegistration('B').path}`);
            }
          }
        ]
      }
    ]);
  }, [platform]);

  const { handleError } = useApiError();

  const handleVerifySuccess = useCallback(
    (_licenseCode: string) => {
      setLicenseCode(_licenseCode);

      trackMatomoOnboardingAccountStep(trackEvent, 'Toegangscode');
    },
    [trackEvent]
  );

  const handleRegisterSuccess = useCallback(
    (_userId: string, _email: string, _phoneNumber: string) => {
      setUserId(_userId);
      setEmail(_email);
      setPhoneNumber(_phoneNumber);

      trackMatomoOnboardingAccountStep(trackEvent, 'Maak je account aan');
      trackMatomoOnboardingAccountTypeMail(trackEvent, _email);
    },
    [trackEvent]
  );

  const handleEmailSuccess = useCallback(async () => {
    try {
      await onboardingService.updatePhone({
        phoneNumber: phoneNumber,
        userId: userId
      });

      trackMatomoOnboardingAccountStep(trackEvent, 'Verifieer jouw e-mail');
    } catch (e) {
      handleError(e);
    }
  }, [phoneNumber, userId, trackEvent, handleError]);

  const handleUpdateEmailSuccess = useCallback(
    (_email: string) => {
      setEmail(_email);

      trackMatomoOnboardingAccountTypeMail(trackEvent, _email);
    },
    [trackEvent]
  );

  const handleUpdatePhoneSuccess = useCallback((_phoneNumber: string) => {
    setPhoneNumber(_phoneNumber);
  }, []);

  const handlePhoneSuccess = useCallback(() => {
    trackMatomoOnboardingAccountStep(trackEvent, 'Verifieer jouw nummer');
  }, [trackEvent]);

  const handleSuccess = useCallback(() => {
    removePersistedSearchParam();
    navigate('/');
  }, [navigate, removePersistedSearchParam]);

  return (
    <>
      <SliderFullPage overrideTotal={4}>
        {variant === 'A' && <OnboardingVerifyLicenseCodeSlide onSuccess={handleVerifySuccess} />}

        {variant === 'B' && (
          <OnboardingVerifyLicenseCodeAlternativeSlide onSuccess={handleVerifySuccess} />
        )}

        <OnboardingRegisterSlide onSuccess={handleRegisterSuccess} licenseCode={licenseCode} />

        <OnboardingVerifyEmailSlide
          onSuccess={handleEmailSuccess}
          onUpdate={handleUpdateEmailSuccess}
          licenseCode={licenseCode}
          userId={userId}
          email={email}
        />

        <OnboardingVerifyPhoneSlide
          onUpdate={handleUpdatePhoneSuccess}
          onSuccess={handlePhoneSuccess}
          userId={userId}
          phoneNumber={phoneNumber}
        />

        <OnboardingSuccessSlide onSuccess={handleSuccess} />
      </SliderFullPage>

      {variant === 'B' && !licenseCode && <OnboardingVerifyLicenseCodePage />}
    </>
  );
};
