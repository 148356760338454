import { ListResponse } from '@Constants/interfaces';
import { dePortalenService, ResourceService } from '@Services/common';
import { toQueryString } from '@Utils/StringUtils';

import { InboxGetListRequest } from './InboxService.props';
import {
  IInboxItem,
  InboxReadMessageRequest,
  InboxReadMessageResponse
} from './InboxService.props';

export class InboxService extends ResourceService<IInboxItem> {
  getList = async (params: InboxGetListRequest) => {
    const response = await this.get<ListResponse<IInboxItem>>(
      params && `?${toQueryString(params)}`
    );

    return response.data;
  };

  readMessage = async ({ id }: InboxReadMessageRequest) => {
    const response = await this.post<InboxReadMessageResponse>(`/${id}/togglereadat`);

    return response.data;
  };
}

export const inboxService = new InboxService(dePortalenService.getAxiosInstance(), '/inbox');
