import { BaseService, dePortalenService } from '@Services/common';

import {
  IUser,
  UserGetDomainsResponse,
  UserInitiateNewPhoneRequest,
  UserInitiateNewPhoneResponse,
  UserInitiateUpdateEmailResponse,
  UserInitiateUpdatePasswordResponse,
  UserUpdateEmailRequest,
  UserUpdateMeRequest,
  UserUpdatePasswordRequest,
  UserUpdatePhoneRequest,
  UserVerifyEmailRequest,
  UserVerifyNewEmailRequest,
  UserVerifyPhoneRequest,
  UserVerifyUpdatePasswordRequest
} from './UserService.props';

export class UserService extends BaseService {
  getMe = async () => {
    const response = await this.get<IUser>('/me');

    return response.data;
  };

  updateMe = async (values: UserUpdateMeRequest) => {
    const response = await this.put<IUser>('/me', values);

    return response.data;
  };

  initiateUpdatePassword = async () => {
    const response = await this.post<UserInitiateUpdatePasswordResponse>('/me/password');

    return response.data;
  };

  verifyUpdatePassword = async (values: UserVerifyUpdatePasswordRequest) => {
    const response = await this.post<void>('/me/verify/password', values);

    return response.data;
  };

  updatePassword = async (values: UserUpdatePasswordRequest) => {
    await this.put<void>('/me/password', values);
  };

  initiateUpdatePhone = async () => {
    const response = await this.post<UserInitiateNewPhoneResponse>('/me/phonenumber');

    return response.data;
  };

  initiateNewPhone = async (values: UserInitiateNewPhoneRequest) => {
    const response = await this.post<UserInitiateNewPhoneResponse>('/me/newphonenumber', values);

    return response.data;
  };

  verifyPhone = async (values: UserVerifyPhoneRequest) => {
    const response = await this.post<UserInitiateNewPhoneResponse>(
      '/me/verify/phonenumber',
      values
    );

    return response.data;
  };

  updatePhone = async (values: UserUpdatePhoneRequest) => {
    await this.put<void>('/me/phonenumber', values);
  };

  initiateUpdateEmail = async () => {
    const response = await this.post<UserInitiateUpdateEmailResponse>('/me/email');

    return response.data;
  };

  verifyEmail = async (values: UserVerifyEmailRequest) => {
    const response = await this.post<void>('/me/verify/email', values);

    return response.data;
  };

  updateEmail = async (values: UserUpdateEmailRequest) => {
    const response = await this.post<void>('/me/newemail', values);

    return response.data;
  };

  verifyNewEmail = async (values: UserVerifyNewEmailRequest) => {
    const response = await this.put<void>('/me/email', values);

    return response.data;
  };

  getDomains = async () => {
    const response = await this.get<UserGetDomainsResponse>('/me/domains');

    return response.data;
  };

  deleteAvatar = async () => {
    await this.delete<void>('/me/avatar');
  };
}

export const userService = new UserService(dePortalenService.getAxiosInstance(), '/users');
