import { useMutation } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { UserTooltipHideRequest, userTooltipService } from '@Services/UserTooltip';
import { trackMatomoMotivatorHide } from '@Utils/MatomoUtils';

export const useUserTooltipHide = () => {
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: UserTooltipHideRequest) => {
      return userTooltipService.hide(config);
    },
    {
      onSuccess: (_, { title, opened }) => {
        trackMatomoMotivatorHide(trackEvent, title, !opened);
      }
    }
  );
};
