import cn from 'classnames';
import { useCallback } from 'react';
import { ToastProps } from 'react-toastify/dist/types';

import { ButtonIcon, Text } from '@Components/ui';
import { useBoolean, useTimeout, useTimeouts } from '@Hooks/common';
import { useSwipeToDismiss } from '@Hooks/common';

import styles from './Alert.module.scss';

export const Alert = ({ children, type, closeToast, deleteToast, className }: ToastProps) => {
  const { value: isClosed, setTrue: setClosed } = useBoolean(false);
  const { value: shouldCloseInvisibly, setTrue: setCloseInvisibly } = useBoolean(false);
  const { addTimeout } = useTimeouts();

  const handleClose = useCallback(() => {
    setClosed();
    closeToast();
    addTimeout(() => {
      deleteToast();
    }, 1500);
  }, [closeToast, deleteToast, setClosed, addTimeout]);

  const swipeToDismiss = useSwipeToDismiss({
    onDismissEnd: () => {
      setCloseInvisibly();
      handleClose();
    }
  });

  useTimeout(handleClose, 5000);

  const getClassNames = () => {
    const classes = [
      styles.Alert,
      { [styles.isClosed]: isClosed, [styles.shouldCloseInvisibly]: shouldCloseInvisibly },
      className
    ] as any;

    switch (type) {
      case 'warning':
        classes.push(styles.isWarning);
        break;
      case 'success':
        classes.push(styles.isSuccess);
        break;
      case 'error':
        classes.push(styles.isError);
        break;
      case 'info':
      default:
        classes.push(styles.isInfo);
        break;
    }

    return cn(classes);
  };

  if (!children) {
    return null;
  }

  return (
    <div className={getClassNames()} {...swipeToDismiss}>
      <div className={styles.MessageWrapper}>
        <Text.Normal className={styles.Message}>{children as string}</Text.Normal>
      </div>

      <ButtonIcon icon="times" onClick={handleClose} className={styles.Close} />
    </div>
  );
};
