import cn from 'classnames';

import { StepIndicator } from '@Components/ui';

import { useSliderFullPageContext } from '../../context';
import { useSliderFullPageItemContext } from './context';
import styles from './SliderFullPageItem.module.scss';
import { SliderFullPageItemProps } from './SliderFullPageItem.props';

export const SliderFullPageItem = ({
  children,
  showPagination = false,
  ...props
}: SliderFullPageItemProps) => {
  const { current, total } = useSliderFullPageContext();
  const { isActive, index } = useSliderFullPageItemContext();

  return (
    <div
      className={cn(styles.Item, {
        [styles.isActive]: isActive
      })}
      {...props}
    >
      {showPagination && (
        <StepIndicator
          current={index + 1}
          total={total}
          completed={current}
          className={styles.StepIndicator}
        />
      )}

      {children}
    </div>
  );
};
