import { t } from 'i18next';

import { Anchor, Box, SpacingGroup, Text } from '@Components/ui';
import { ReportDownloadButton } from '@Documents/documents/report';
import { AppRoutes } from '@Navigation/Routes';

export const ReportIntroAvailable = () => {
  return (
    <>
      <Text.Large>{t('DOMAIN.REPORT.AVAILABLE.INTRO')}</Text.Large>

      <Box mt={2}>
        <SpacingGroup size="xxl">
          <ReportDownloadButton />

          <Anchor to={AppRoutes.getInsightChallengesAdd().to}>
            {t('DOMAIN.REPORT.START_CHALLENGE')}
          </Anchor>
        </SpacingGroup>
      </Box>
    </>
  );
};
