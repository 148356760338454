import { IFaqCategory } from '@Services/Faq';

export const getSearchResults = (categories: IFaqCategory[], searchString: string) => {
  const loweredSearchString = searchString.toLowerCase();
  return categories.map((category) => {
    return {
      ...category,
      items: category.items.filter((faqItem) => {
        return (
          faqItem.title.toLowerCase().includes(loweredSearchString) ||
          JSON.stringify(faqItem.content).toLowerCase().includes(loweredSearchString)
        );
      })
    };
  });
};

export const getTotalResultCount = (categories: IFaqCategory[]) => {
  return categories.reduce((count, category) => {
    return count + category.items.length;
  }, 0);
};
