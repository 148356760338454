import cn from 'classnames';
import { useCallback, useMemo } from 'react';

import { ButtonIcon, PageNumber } from '@Components/ui';

import styles from './SliderPagination.module.scss';
import { SliderPaginationProps } from './SliderPagination.props';

export const SliderPagination = ({
  total,
  current,
  onChange,
  isDisabled,
  className
}: SliderPaginationProps) => {
  const classNames = cn(styles.SliderPagination, className, {
    [styles.isDisabled]: isDisabled
  });

  const handleClick = useCallback(
    (idx) => () => {
      if (!isDisabled) {
        onChange(idx);
      }
    },
    [onChange, isDisabled]
  );

  const steps = useMemo(() => {
    return new Array(total).fill(null).map((_, idx) => {
      return (
        <span
          key={idx}
          onClick={handleClick(idx)}
          className={cn(styles.Step, { [styles.isActive]: idx === current })}
        />
      );
    });
  }, [total, handleClick, current]);

  return (
    <div className={classNames}>
      <ButtonIcon
        icon="chevron-left"
        onClick={handleClick(current - 1)}
        isDisabled={isDisabled || current === 0}
        data-testid="SliderPaginationPrevious"
      />

      <div className={styles.Steps}>
        {total <= 10 ? steps : <PageNumber total={total} current={current} />}
      </div>

      <ButtonIcon
        icon="chevron-right"
        onClick={handleClick(current + 1)}
        isDisabled={isDisabled || current === total - 1}
        data-testid="SliderPaginationNext"
      />
    </div>
  );
};
