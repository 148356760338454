import { ThemeBlock } from '..';
import { ThemeListProps } from './ThemeList.props';
import { styles } from './ThemeList.styles';

export const ThemeList = ({ domain, themes }: ThemeListProps) => {
  return (
    <>
      {themes.map((userTheme) => {
        return (
          <ThemeBlock
            key={userTheme.id}
            domain={domain.title}
            theme={userTheme.theme.title}
            intro={userTheme.theme.description ?? ''}
            themeResult={userTheme.themeResult ?? null}
            description={userTheme.feedback}
            style={styles.ThemeBlock}
          />
        );
      })}
    </>
  );
};
