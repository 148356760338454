import { useParams } from 'react-router-dom';

import { ListActivityItemLarge } from '@Components/list';
import { Block } from '@Components/ui';

import { useNonStartedActivities } from '../../../../../hooks';
import styles from './ChallengeItemAllActivitiesBlock.module.scss';

export const ChallengeItemAllActivitiesBlock = () => {
  const { slug } = useParams<{ slug: string }>();

  const activities = useNonStartedActivities(slug ?? '');

  if (activities && activities.length > 0) {
    return (
      <Block theme="gray" className={styles.ChallengeItemAllActivitiesBlock}>
        <ListActivityItemLarge activities={activities ?? []} />
      </Block>
    );
  }

  return null;
};
