import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Loader } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './PageLoader.module.scss';
import { PageLoaderProps } from './PageLoader.props';

export const PageLoader = ({ className }: PageLoaderProps) => {
  const { background, assets } = usePlatform();
  const { t } = useTranslation();

  return (
    <div className={cn(styles.PageLoader, className)}>
      <Loader className={styles.Loader}>{t('COMMON.GLOBALS.PLEASE_WAIT')}</Loader>

      <div className={styles.Logo}>
        <img src={assets.logo.default} />
      </div>

      {background.highlight && (
        <div
          className={styles.Highlight}
          style={{
            background: background.highlight.fullscreen
          }}
        />
      )}

      {background.fade && (
        <div className={styles.Fade} style={{ background: background.fade.default }} />
      )}
    </div>
  );
};
