import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ActivityFinishModal } from '@Components/container';
import { MODAL_ANIMATION_DURATION } from '@Components/layout/Modal/internals/constants';
import { Box, CallToAction, InputLike, TagAnimated, TagText, Text } from '@Components/ui';
import { useActivityFinish } from '@Hooks/activity';
import { useApiError, useBoolean, usePlatform } from '@Hooks/common';
import { isStartedAtPastDuration, isUserActivity } from '@Utils/ActivityUtils';
import { shortDate } from '@Utils/DateUtils';
import { sleep } from '@Utils/TimeoutUtils';

import styles from './UserActivityFooter.module.scss';
import { UserActivityFooterProps } from './UserActivityFooter.props';

export const UserActivityFooter = ({ activity }: UserActivityFooterProps) => {
  const { t } = useTranslation();
  const activityFinishMutation = useActivityFinish();
  const [isPastDuration, setIsPastDuration] = useState<boolean>();
  const { color } = usePlatform();
  const { handleError } = useApiError();
  const [likeResult, setLikeResult] = useState<string>();

  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);

  const handleClose = useCallback(async () => {
    setModalClosed();
    await sleep(MODAL_ANIMATION_DURATION);
    setLikeResult(undefined);
  }, [setModalClosed]);

  const handleSubmit = useCallback(async () => {
    try {
      await activityFinishMutation.mutateAsync({
        id: activity.id,
        title: activity.title,
        like: Boolean(Number(likeResult)) || false,
        isUserActivity: true
      });

      toast.success(t('DOMAIN.USER_ACTIVITY.DETAIL.FOOTER.FINISH'));
    } catch (e) {
      handleError(e);
    } finally {
      handleClose();
    }
  }, [
    activityFinishMutation,
    handleClose,
    activity.id,
    activity.title,
    likeResult,
    t,
    handleError
  ]);

  const handleChange = useCallback(
    async (_value: string) => {
      const pastDuration = isStartedAtPastDuration(activity);

      setLikeResult(_value);
      setIsPastDuration(pastDuration);

      if (!pastDuration || (!isUserActivity(activity) && _value === '0')) {
        setModalOpen();
      } else {
        await handleSubmit();
      }
    },
    [activity, handleSubmit, setModalOpen]
  );

  return (
    <div className={styles.UserActivityFooter}>
      {!!activity.completedAt ? (
        <TagText
          tagProps={{
            color: color.success.default,
            icon: 'check',
            size: 'xs'
          }}
          label={t('DOMAIN.ACTIVITY.FINISHED_AT', {
            finishDate: shortDate(new Date(activity.completedAt))
          })}
        />
      ) : (
        <div className={styles.UserActivityFinish}>
          <Box mb={2} className={styles.CallToAction}>
            <CallToAction
              isReversed
              isVisible
              isStatic
              label={t('DOMAIN.USER_ACTIVITY.DETAIL.FOOTER.CTA')}
            />
          </Box>

          <InputLike
            value={likeResult}
            onChange={handleChange}
            likeLabel={t('DOMAIN.USER_ACTIVITY.DETAIL.FOOTER.LIKE')}
            dislikeLabel={t('DOMAIN.USER_ACTIVITY.DETAIL.FOOTER.DISLIKE')}
            data-testid="inputFinishActivity"
          >
            <div className={styles.Body}>
              <Text.H3>{t('DOMAIN.USER_ACTIVITY.DETAIL.FOOTER.TITLE')}</Text.H3>

              <div className={styles.Claim}>
                <Text.Normal>{t('DOMAIN.ACTIVITY.FINISH.CLAIM_NOW')}</Text.Normal>

                <div className={styles.Points}>
                  <TagAnimated size="lg" points={activity.points} />
                </div>
              </div>
            </div>
          </InputLike>

          <ActivityFinishModal
            isOpen={isModalOpen}
            onSubmit={handleSubmit}
            onClose={handleClose}
            isLiked={Boolean(Number(likeResult))}
            isStartedAtPastDuration={isPastDuration}
            isLoading={activityFinishMutation.isLoading}
            activity={activity}
          />
        </div>
      )}
    </div>
  );
};
