import { useMutation, useQueryClient } from 'react-query';

import { PreferenceKey, preferenceService } from '@Services/Preference';
import { UserCloseTourdotRequest, userService } from '@Services/User';

import { useUser } from './useUser';

export const useUserCloseTourdot = () => {
  const queryClient = useQueryClient();
  const { preferences } = useUser();

  return useMutation(
    (config: UserCloseTourdotRequest) => {
      const tourdots = preferences.find((preference) => preference.key === PreferenceKey.TOURDOTS);

      return preferenceService.submit({
        key: PreferenceKey.TOURDOTS,
        values: tourdots ? [...(tourdots.values || []), config.id] : [config.id]
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
