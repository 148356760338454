import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@Utils/PlatformUtils';

export const styles = StyleSheet.create({
  ThemeProgress: {
    position: 'relative',
    width: 192,
    height: 6
  },
  Track: {
    position: 'absolute',
    top: 2,
    left: 0,
    right: 0,
    height: 2,
    backgroundColor: getTheme().color.gray.light,
    borderRadius: 2
  },
  Progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: 6
  }
});
