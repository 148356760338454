import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconProps } from './Icon.props';

export const Icon = ({ className, size = 'md', style, ...props }: IconProps) => {
  const iconSize = () => {
    switch (size) {
      case 'sm':
        return 6;
      case 'lg':
        return 12;
      case 'xl':
        return 19;
      case 'xxl':
        return 54;
      default:
        return 9;
    }
  };

  const fontSizeStyle =
    typeof size !== 'undefined'
      ? {
          ...style,
          fontSize: `${iconSize()}px`
        }
      : style;

  return <FontAwesomeIcon className={className} style={fontSizeStyle} {...props} />;
};
