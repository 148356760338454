import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { RewardClaimForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { Anchor, Button, Image, Tag, Text } from '@Components/ui';
import { useApiError, useBoolean, usePlatform } from '@Hooks/common';
import { useRewardResend } from '@Hooks/reward';
import { useUser } from '@Hooks/user';
import { getDate } from '@Utils/DateUtils';
import { getImageSrc } from '@Utils/PreprUtils';
import { toLocaleString } from '@Utils/StringUtils';

import styles from './RewardSideviewClaim.module.scss';
import { RewardSideviewClaimProps } from './RewardSideviewClaim.props';

export const RewardSideviewClaim = ({ reward, onClaim, className }: RewardSideviewClaimProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();
  const { points } = useUser();
  const { handleError } = useApiError();
  const rewardResendMutation = useRewardResend();
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);

  const remainingPoints = points - reward.points;

  const isClaimed = !!reward.claimedAt;
  const isClaimable = remainingPoints >= 0;

  const handleSuccess = useCallback(async () => {
    setModalClosed();

    if (onClaim) {
      onClaim();
    }
  }, [onClaim, setModalClosed]);

  const handleResend = useCallback(async () => {
    try {
      await rewardResendMutation.mutateAsync({
        id: reward.id
      });

      toast.success(t('DOMAIN.REWARD.SIDEVIEW.RESEND.SUCCESS'));
    } catch (e) {
      handleError(e);
    }
  }, [handleError, reward.id, rewardResendMutation, t]);

  if (isClaimed) {
    return (
      <div className={cn(styles.RewardSideviewClaim, className)}>
        <Button icon="arrow-right" isDisabled className={styles.Button} data-testid="claimedButton">
          {t('DOMAIN.REWARD.SIDEVIEW.BUTTON.DISABLED', {
            count: reward.points,
            points: toLocaleString(reward.points)
          })}
        </Button>

        <Text.Normal>
          {t('DOMAIN.REWARD.SIDEVIEW.CLAIMED_ON', {
            date: getDate(new Date(reward.claimedAt))
          })}
        </Text.Normal>

        <Anchor onClick={handleResend} isDisabled={rewardResendMutation.isLoading}>
          {t('DOMAIN.REWARD.SIDEVIEW.RESEND.BUTTON')}
        </Anchor>
      </div>
    );
  }

  if (isClaimable) {
    return (
      <>
        <div className={cn(styles.RewardSideviewClaim, className)}>
          <Button
            onClick={setModalOpen}
            icon="arrow-right"
            className={cn(styles.Button, styles.hasClaim)}
            data-testid="claimableButton"
          >
            {t('DOMAIN.REWARD.SIDEVIEW.BUTTON.CLAIM')}

            <Tag
              className={styles.Points}
              color={color.primary.default}
              label={toLocaleString(reward.points)}
              icon="star"
              size="lg"
            />

            {t('DOMAIN.REWARD.SIDEVIEW.BUTTON.POINTS', {
              count: reward.points
            })}
          </Button>

          <Text.Normal>
            {t(`DOMAIN.REWARD.${remainingPoints >= 0 ? 'POINTS_LEFT' : 'POINTS_TOO_FEW'}` as any, {
              count: remainingPoints,
              points: toLocaleString(Math.abs(remainingPoints))
            })}
          </Text.Normal>
        </div>

        <Modal
          title={t('DOMAIN.REWARD.SIDEVIEW.MODAL.TITLE')}
          isOpen={isModalOpen}
          onClose={setModalClosed}
          head={<Image src={getImageSrc(reward.thumbnail)} alt={reward.title} />}
        >
          <Text.Large>
            {t('DOMAIN.REWARD.SIDEVIEW.MODAL.DESCRIPTION.BEFORE')}

            <Tag
              className={styles.Points}
              color={color.primary.default}
              label={toLocaleString(reward.points)}
              icon="star"
              size="lg"
            />

            {t('DOMAIN.REWARD.SIDEVIEW.MODAL.DESCRIPTION.AFTER', {
              title: reward.title
            })}

            {` ${reward.confirmationDescription}`}
          </Text.Large>

          <RewardClaimForm reward={reward} onSuccess={handleSuccess} onCancel={setModalClosed} />
        </Modal>
      </>
    );
  }

  return (
    <div className={cn(styles.RewardSideviewClaim, className)}>
      <Button
        icon="arrow-right"
        isDisabled
        className={styles.Button}
        data-testid="unclaimableButton"
      >
        {t('DOMAIN.REWARD.SIDEVIEW.BUTTON.DISABLED', {
          count: reward.points,
          points: toLocaleString(reward.points)
        })}
      </Button>

      <Text.Normal>
        {t('DOMAIN.REWARD.POINTS_TOO_FEW', {
          count: remainingPoints,
          points: toLocaleString(Math.abs(remainingPoints))
        })}
      </Text.Normal>
    </div>
  );
};
