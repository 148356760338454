import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SelfTestClosedBlock } from '@Components/container';
import { ListTodo } from '@Components/list';
import { LazyList, Text } from '@Components/ui';
import { useInfiniteData } from '@Hooks/common';
import { useOnboardingGetSelfTestQuestionnaire } from '@Hooks/onboarding';
import { useTodoGetArchiveList } from '@Hooks/todo';
import { QuestionnaireSubmissionStatus } from '@Services/QuestionnaireSubmission';

import { TodoListArchiveProps } from './TodoListArchive.props';

export const TodoListArchive = ({ filter, onTodoLoad, onSelfTestLoad }: TodoListArchiveProps) => {
  const { t } = useTranslation();

  const {
    data: todoPages,
    hasNextPage: hasNextTodosPage,
    fetchNextPage: fetchNextTodosPage,
    isLoading: isTodosLoading,
    isFetched: isTodosFetched
  } = useTodoGetArchiveList(filter);

  const {
    data: questionnaireSelfTest,
    isLoading: isQuestionnaireSelfTestLoading,
    isFetched: isQuestionnaireSelfTestFetched
  } = useOnboardingGetSelfTestQuestionnaire();

  const isLoading = isTodosLoading || isQuestionnaireSelfTestLoading;
  const isFetched = isTodosFetched && isQuestionnaireSelfTestFetched;

  const todos = useInfiniteData(todoPages);

  const hasSelfTestRegularDone =
    questionnaireSelfTest &&
    questionnaireSelfTest.status === QuestionnaireSubmissionStatus.COMPLETED;

  const hasPhasedDone =
    questionnaireSelfTest?.completedPhases && questionnaireSelfTest.completedPhases > 0;

  const isEmpty = !hasPhasedDone && !hasSelfTestRegularDone && (!todos || todos.length === 0);

  useEffect(() => {
    if (todoPages) {
      onTodoLoad(todoPages);
    }
  }, [todoPages, onTodoLoad]);

  useEffect(() => {
    if (questionnaireSelfTest) {
      onSelfTestLoad(questionnaireSelfTest);
    }
  }, [questionnaireSelfTest, onSelfTestLoad]);

  const handleFetchNextPage = useCallback(() => {
    if (hasNextTodosPage) {
      fetchNextTodosPage();
    }
  }, [fetchNextTodosPage, hasNextTodosPage]);

  if (isFetched && isEmpty) {
    return <Text.Large>{t('DOMAIN.TODO.ARCHIVE.EMPTY')}</Text.Large>;
  }

  return (
    <LazyList
      hasNextPage={hasNextTodosPage}
      fetchNextPage={handleFetchNextPage}
      isFetched={isFetched}
      isLoading={isLoading}
      totalItems={todoPages?.pages[0].total}
      loadingText={t('COMMON.GLOBALS.LOADING_MODEL', {
        name: t('DOMAIN.TODO.PLURAL')
      })}
    >
      {questionnaireSelfTest && todos && (
        <ListTodo todos={todos}>
          <SelfTestClosedBlock selfTest={questionnaireSelfTest} />
        </ListTodo>
      )}
    </LazyList>
  );
};
