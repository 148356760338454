import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { SMS_CODE_LENGTH } from '@Constants/configs';
import { useApiError } from '@Hooks/common';
import { useUserVerifyUpdatePassword } from '@Hooks/user';
import Yup from '@Utils/YupUtils';

import {
  UpdatePasswordVerifyTwoFactorFormProps,
  UpdatePasswordVerifyTwoFactorFormValues
} from './UpdatePasswordVerifyTwoFactorForm.props';

export const UpdatePasswordVerifyTwoFactorForm = ({
  onSuccess,
  smsToken
}: UpdatePasswordVerifyTwoFactorFormProps) => {
  const { t } = useTranslation();
  const { error, handleError } = useApiError();

  const verifyUpdatePasswordMutation = useUserVerifyUpdatePassword();

  const handleSubmit = useCallback(
    async (values: UpdatePasswordVerifyTwoFactorFormValues) => {
      try {
        const params = {
          smsToken,
          token: values.token
        };

        await verifyUpdatePasswordMutation.mutateAsync(params);

        onSuccess(params);
      } catch (e) {
        handleError(e, 'PASSWORD');
      }
    },
    [verifyUpdatePasswordMutation, smsToken, onSuccess, handleError]
  );

  const validationSchema = Yup.object().shape({
    token: Yup.string().length(SMS_CODE_LENGTH).required()
  });

  return (
    <Form.Form<UpdatePasswordVerifyTwoFactorFormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form.Input.Code
        name="token"
        data-testid="tokenInput"
        label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.TOKEN.LABEL')}
        description={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.TOKEN.DESCRIPTION')}
        options={{
          isError: !!error,
          type: 'number',
          pattern: '[0-9]*',
          inputMode: 'numeric'
        }}
      />

      <Form.Layout.Error error={error} />

      <Button type="submit" color="dark" icon="arrow-right" data-testid="submitButton">
        {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PASSWORD.VERIFY_SMS.SUBMIT_LABEL')}
      </Button>
    </Form.Form>
  );
};
