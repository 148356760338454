import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ProfileStatus } from '@Components/container';
import { Container } from '@Components/layout';
import { Block, Box, Text } from '@Components/ui';
import { useUser } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';

import { ProfileInfo, ProfileTabs } from './internal/components';
import styles from './ProfileProfileScreen.module.scss';

export const ProfileProfileScreen = () => {
  const { t } = useTranslation();
  const user = useUser();
  const navigate = useNavigate();

  const handleSelfTestClick = useCallback(() => {
    navigate(AppRoutes.getSelfTest().to);
  }, [navigate]);

  return (
    <Container className={styles.ProfileProfileScreen}>
      <Box mx={4} mb={5}>
        <Text.H1 className={styles.ProfileTitle}>
          {t('DOMAIN.PROFILE.WELCOME', { name: user.firstName })}
        </Text.H1>

        <Text.Large>{t('DOMAIN.PROFILE.INTRO')}</Text.Large>
      </Box>

      <Block>
        <Box mb={6}>
          <ProfileInfo profileInfo={user} />
        </Box>

        {!!user.profileStatusSteps.length && user.profileStatusSteps.length > 0 && (
          <Block
            theme="gray"
            padding="none"
            isElevated={false}
            className={styles.ProfileStatusContainer}
            innerClassName={styles.ProfileStatusBody}
          >
            <ProfileStatus
              showTourdots
              className={styles.ProfileStatus}
              onSelfTestClick={handleSelfTestClick}
            />
          </Block>
        )}

        <ProfileTabs />
      </Block>
    </Container>
  );
};
