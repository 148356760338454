import { useMutation, useQueryClient } from 'react-query';

import { userService } from '@Services/User';

export const useUserDeleteAvatar = () => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return userService.deleteAvatar();
    },
    {
      onSuccess: () => {
        queryClient.cancelQueries([userService.getEndpoint(), 'me']);
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
