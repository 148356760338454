import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteData } from 'react-query';

import { Block, Box, CallToAction, Spacer } from '@Components/ui';
import { ListResponse } from '@Constants/interfaces';
import { ActivityListRequest, IActivity } from '@Services/Activity';

import { ActivityFilter } from '../ActivityFilter/ActivityFilter';
import { ActivityList } from '../ActivityList/ActivityList';
import { ActivityBlockProps } from './ActivityBlock.props';

export const ActivityBlock = ({ className }: ActivityBlockProps) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<ActivityListRequest>();
  const [data, setData] = useState<InfiniteData<ListResponse<IActivity>>>();

  return (
    <>
      <Box mb={2}>
        <CallToAction label={t('DOMAIN.ACTIVITY.OVERVIEW.RECOMMENDED')} />
      </Box>

      <Block className={className}>
        <ActivityFilter onChange={setFilter} count={data?.pages[0].total} />

        <Spacer top={2} bottom={3} />

        {!!filter && <ActivityList filter={filter} onLoad={setData} />}
      </Block>
    </>
  );
};
