import cn from 'classnames';
import { isToday, isYesterday } from 'date-fns';

import { InboxItem } from '@Components/container';
import { Box, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { getDate, getRelativeDate, groupByDate } from '@Utils/DateUtils';

import styles from './ListInboxItem.module.scss';
import { ListInboxItemProps } from './ListInboxItem.props';

export const ListInboxItem = ({ messages, className }: ListInboxItemProps) => {
  const { color } = usePlatform();

  const messageGroups = groupByDate(messages, 'createdAt');

  const getDateHeader = (date: Date) => {
    if (isToday(date) || isYesterday(date)) {
      return getRelativeDate(date);
    }

    return getDate(date);
  };

  return (
    <div className={cn(styles.ListInboxItem, className)}>
      {messageGroups.map((group) => {
        return (
          <Box mb={4} key={group.date}>
            <div className={styles.Date}>
              <Text.Micro color={color.gray.dark}>{getDateHeader(new Date(group.date))}</Text.Micro>
            </div>

            {group.items.map((message) => {
              return <InboxItem key={message.id} className={styles.InboxItem} message={message} />;
            })}
          </Box>
        );
      })}
    </div>
  );
};
