import { useTranslation } from 'react-i18next';

import { Faq } from '@Components/container/Faq/Faq';
import { Block, Loader, Text } from '@Components/ui';
import { useFaqOne } from '@Hooks/faq';

import { PREPR_APP_FAQ_QUESTIONS_ID } from '../constants';

export const AppFaq = () => {
  const { t } = useTranslation();
  const faq = useFaqOne(PREPR_APP_FAQ_QUESTIONS_ID);

  return (
    <Block>
      {!!faq.data && <Faq groups={faq.data.categories} />}

      {faq.isLoading && <Loader />}

      {faq.isError && <Text.Error>{t('DOMAIN.FAQ.ERROR.LABEL')}</Text.Error>}
    </Block>
  );
};
