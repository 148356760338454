import { StyleSheet } from '@react-pdf/renderer';

import { getTheme } from '@Utils/PlatformUtils';

export const styles = StyleSheet.create({
  H4: {
    color: getTheme().color.font.default,
    fontFamily: getTheme().font.family.heading,
    fontSize: getTheme().font.h4.fontSize,
    fontWeight: 'bold'
  }
});
