import cn from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';

import { ButtonPin, Form } from '@Components/ui';
import { ButtonIcon, Text } from '@Components/ui';
import { REQUIRED_ACTIVITY_AMOUNT } from '@Constants/configs';
import { PinState } from '@Constants/enums';
import { usePlatform } from '@Hooks/common';
import Yup from '@Utils/YupUtils';

import styles from './CustomActivity.module.scss';
import { CustomActivityForm, CustomActivityProps } from './CustomActivity.props';

export const CustomActivity = ({
  isCustomActivityOpen,
  onToggleCustomActivity,
  isDisabled,
  onChange,
  className
}: CustomActivityProps) => {
  const { t } = useTranslation();
  const { easing } = usePlatform();

  const onToggleActivity = () => {
    onToggleCustomActivity();
  };

  const handleFormChanges = (values: { value?: string | undefined } | null) => {
    onChange(values?.value ?? '');
  };

  const validationSchema = Yup.object().shape({
    value: isCustomActivityOpen
      ? Yup.string().required(t('DOMAIN.ACTIVITY.CUSTOM_ERROR'))
      : Yup.string()
  });

  const deleteText = t('DOMAIN.ACTIVITY.DELETE');
  const addText =
    !isCustomActivityOpen && isDisabled
      ? t('DOMAIN.ACTIVITY.CANT_ADD', { max: REQUIRED_ACTIVITY_AMOUNT })
      : t('DOMAIN.ACTIVITY.ADD');

  return (
    <div className={cn(styles.CustomActivity, className)}>
      <div
        className={cn(styles.Buttons, { [styles.isDisabled]: isDisabled })}
        onClick={isDisabled ? undefined : onToggleActivity}
      >
        <ButtonIcon
          className={styles.Icon}
          isDisabled={isDisabled}
          icon={isCustomActivityOpen ? 'times' : 'chevron-right'}
        />

        <Text.H5>{isCustomActivityOpen ? deleteText : addText}</Text.H5>

        <ButtonPin
          pinState={isCustomActivityOpen ? PinState.PINNED : PinState.UNPINNED}
          className={styles.ButtonPin}
        />
      </div>

      <AnimateHeight height={isCustomActivityOpen ? 'auto' : 0} duration={300} easing={easing.out}>
        <Form.Form<CustomActivityForm>
          validationSchema={validationSchema}
          onChange={handleFormChanges}
          onSubmit={handleFormChanges}
          className={styles.Form}
        >
          <Form.Input.Textarea
            label={t('DOMAIN.ACTIVITY.CUSTOM_TITLE')}
            description={t('DOMAIN.ACTIVITY.CUSTOM_DESCRIPTION')}
            name="value"
            className={styles.Input}
          />
        </Form.Form>
      </AnimateHeight>
    </div>
  );
};
