import { useQuery } from 'react-query';

import { OneRequest } from '@Constants/interfaces';
import { challengeService } from '@Services/Challenge';

export const useChallengeOne = (params: OneRequest) => {
  return useQuery(
    [challengeService.getEndpoint(), params],
    () => {
      if (params.id) {
        return challengeService.one(params);
      }
    },
    {
      enabled: !!params.id
    }
  );
};
