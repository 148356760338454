import { Text } from '@react-pdf/renderer';

import { PdfTextSmallProps } from './PdfTextSmall.props';
import { styles } from './PdfTextSmall.styles';

export const Small = ({ children, style, ...props }: PdfTextSmallProps) => {
  return (
    <Text style={{ ...styles.Small, ...style }} {...props}>
      {children}
    </Text>
  );
};
