import cn from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Checkmark, SpacingGroup, Tag, Text, Thumbnail } from '@Components/ui';
import { useHover, usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';

import styles from './TimelineChallenge.module.scss';
import { TimelineChallengeProps } from './TimelineChallenge.props';

export const TimelineChallenge = memo(({ challenge, className }: TimelineChallengeProps) => {
  const { thumbnail, title, slug, completedAt } = challenge;

  const { t } = useTranslation();
  const { color } = usePlatform();
  const { isHovering, handlers } = useHover();

  const isCompleted = completedAt !== null;

  return (
    <Link
      to={AppRoutes.getInsightChallenge(slug ?? '').to}
      className={cn(styles.TimelineChallenge, className)}
      {...handlers}
    >
      <div className={styles.ThumbnailWrapper}>
        <Thumbnail thumbnail={thumbnail} alt={title} isHovering={isHovering} />

        <SpacingGroup className={styles.Status}>
          <Tag label={t('DOMAIN.CHALLENGE.SINGLE')} color={color.font.default} />

          {isCompleted && <Checkmark isLabeled />}
        </SpacingGroup>
      </div>

      <div className={styles.Content}>
        <Text.H5>{title}</Text.H5>

        <SpacingGroup className={styles.Status}>
          <Tag label={t('DOMAIN.CHALLENGE.SINGLE')} color={color.font.default} />

          {isCompleted && <Checkmark isLabeled />}
        </SpacingGroup>
      </div>
    </Link>
  );
});
