import { Box, Button } from '@Components/ui';

import { PreprElementButtonProps } from './PreprElementButton.props';

export const PreprElementButton = ({ element }: PreprElementButtonProps) => {
  return (
    <Box mb={6}>
      <Button href={element.button.url}>{element.button.body}</Button>
    </Box>
  );
};
