import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, Form } from '@Components/ui';
import { ContactType } from '@Constants/enums';
import { useApiError } from '@Hooks/common';
import { useContact } from '@Hooks/contact';
import { enumToInputOptions } from '@Utils/FormUtils';
import Yup from '@Utils/YupUtils';

import { ContactFormProps, ContactFormValues } from './ContactForm.props';

export const ContactForm = ({
  email,
  phone,
  hasCallMeBack: requestCallBack = false,
  onSuccess
}: ContactFormProps) => {
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const contactMutation = useContact();

  const handleSubmit = useCallback(
    async (values: ContactFormValues) => {
      try {
        await contactMutation.mutateAsync({ callback: requestCallBack, ...values });

        toast.success(t('DOMAIN.FAQ.FORM.SUCCES.LABEL'));

        onSuccess();
      } catch (e) {
        handleError(e);
      }
    },
    [requestCallBack, contactMutation, onSuccess, handleError, t]
  );

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(),
    email: Yup.string(),
    phone: Yup.string().phone().required(),
    question: Yup.string().max(400).required()
  });

  return (
    <Form.Form
      onSubmit={handleSubmit}
      initialValues={{
        phone: phone,
        email: email || '',
        type: ContactType.GENERAL,
        question: ''
      }}
      validationSchema={validationSchema}
    >
      <Form.Input.Select
        label={t('DOMAIN.FAQ.FORM.FIELDS.TYPE.LABEL')}
        name="type"
        options={{
          options: enumToInputOptions(
            ContactType,
            t('DOMAIN.FAQ.FORM.FIELDS.TYPE.OPTIONS', {
              returnObjects: true
            })
          )
        }}
        data-testid="typeInput"
      />

      {!!email && (
        <Form.Input.Text
          label={t('DOMAIN.FAQ.FORM.FIELDS.EMAIL.LABEL')}
          name="email"
          data-testid="emailInput"
          options={{ disabled: true }}
        />
      )}

      <Form.Input.Text
        label={t('DOMAIN.FAQ.FORM.FIELDS.PHONE.LABEL')}
        name="phone"
        data-testid="phoneInput"
      />

      <Form.Input.Textarea
        label={t('DOMAIN.FAQ.FORM.FIELDS.QUESTION.LABEL')}
        name="question"
        data-testid="questionInput"
      />

      <Button type="submit" color="dark" data-testid="submitButton">
        {t('DOMAIN.FAQ.FORM.FIELDS.SUBMIT.LABEL')}
      </Button>
    </Form.Form>
  );
};
