import { createContext, useContext } from 'react';

import { AppError } from '@Constants/enums';

export interface SliderFullPageItemContextType {
  isActive: boolean;
  index: number;
  slideNext: () => void;
  slidePrev: () => void;
}

export const SliderFullPageItemContext = createContext<SliderFullPageItemContextType | null>(null);

export const useSliderFullPageItemContext = () => {
  const contextValue = useContext(SliderFullPageItemContext);

  if (!contextValue) {
    throw new Error(AppError.NO_CONTEXT_SET);
  }

  return contextValue;
};
