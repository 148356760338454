import { useMutation } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { ContactRequest, contactService } from '@Services/Contact';
import { trackMatomoContactCallback } from '@Utils/MatomoUtils';

export const useContact = () => {
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: ContactRequest) => {
      return contactService.contact(config);
    },
    {
      onSuccess: (_, { type }) => {
        trackMatomoContactCallback(trackEvent, type);
      }
    }
  );
};
