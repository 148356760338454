import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { QueryClient, QueryClientProvider } from 'react-query';

import './App.scss';
import '@Assets/fonts/caveat/Caveat-SemiBold.ttf';

import { ToastContainer } from '@Components/layout';
import { HTTP_CODE_UNAUTHORIZED } from '@Constants/configs';
import {
  AuthenticationContext,
  AuthenticationContextConsumer,
  AuthenticationContextProvider
} from '@Context/auth';
import { Router } from '@Navigation/index';
import { dePortalenService } from '@Services/common';
import Sentry from '@Utils/SentryUtils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 3000,
      onError: (e: any) => {
        if (e.response?.status !== HTTP_CODE_UNAUTHORIZED) {
          Sentry.captureException(e);
        }
      }
    }
  }
});

export const App = () => {
  library.add(fas, far, fal);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticationContextProvider>
        <AuthenticationContextConsumer>
          {(contextValue: AuthenticationContext | null) => {
            if (contextValue) {
              dePortalenService.setAuthenticationContext(contextValue);
            }
          }}
        </AuthenticationContextConsumer>

        <Router />

        <ToastContainer />
      </AuthenticationContextProvider>
    </QueryClientProvider>
  );
};
