import { useCallback, useState } from 'react';

import { SLIDER_FULLPAGE_SPEED } from '@Components/ui/SliderFullPage/internal/constants';
import { useTimeouts } from '@Hooks/common';

export function useInfiniteSliderData<T>(initialValue?: T) {
  const [data, setData] = useState<Array<T | null>>(
    initialValue ? [initialValue, ...Array(4).fill(null)] : Array(5).fill(null)
  );
  const [activeIdx, setActiveIdx] = useState(0);
  const isLastItemActive = activeIdx >= data.length - 1;
  const isFirstItemActive = activeIdx === 0;

  const { addTimeout } = useTimeouts();

  const removeAllButIndex = useCallback((targetIdx: number) => {
    setData((oldData) => {
      return oldData.map((currentValue, currentIdx) => {
        if (targetIdx === currentIdx) {
          return currentValue;
        }

        return null;
      });
    });
  }, []);

  const insertAtIndex = useCallback((targetIdx: number, newValue: T) => {
    setData((oldData) => {
      return oldData.map((currentValue, currentIdx) => {
        if (targetIdx === currentIdx) {
          return newValue;
        }

        return currentValue;
      });
    });
  }, []);

  const append = useCallback(
    (value: T) => {
      let newActiveIdx: number;

      if (isLastItemActive) {
        newActiveIdx = 0;
      } else {
        newActiveIdx = activeIdx + 1;
      }

      insertAtIndex(newActiveIdx, value);
      setActiveIdx(newActiveIdx);

      addTimeout(() => {
        removeAllButIndex(newActiveIdx);
      }, SLIDER_FULLPAGE_SPEED);
    },
    [activeIdx, insertAtIndex, isLastItemActive, removeAllButIndex, addTimeout]
  );

  const prepend = useCallback(
    (value: T) => {
      let newActiveIdx: number;

      if (isFirstItemActive) {
        newActiveIdx = data.length - 1;
      } else {
        newActiveIdx = activeIdx - 1;
      }

      insertAtIndex(newActiveIdx, value);
      setActiveIdx(newActiveIdx);

      addTimeout(() => {
        removeAllButIndex(newActiveIdx);
      }, SLIDER_FULLPAGE_SPEED);
    },
    [isFirstItemActive, insertAtIndex, data.length, activeIdx, removeAllButIndex, addTimeout]
  );

  return {
    append,
    prepend,
    data,
    insertAtIndex,
    activeIdx
  };
}
