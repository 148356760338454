import { useTranslation } from 'react-i18next';

import { VerificationQuestionForm } from '@Components/form';
import { SliderFullPage } from '@Components/ui';

export const PersonaCheckQuestions = () => {
  const { t } = useTranslation();

  return (
    <SliderFullPage>
      <SliderFullPage.Item>
        <SliderFullPage.Head
          title={t('DOMAIN.PERSONA_QUESTIONNAIRE.VERIFICATION_QUESTION.TITLE')}
          intro={t('DOMAIN.PERSONA_QUESTIONNAIRE.VERIFICATION_QUESTION.DESCRIPTION')}
        />

        <SliderFullPage.Block>
          <VerificationQuestionForm />
        </SliderFullPage.Block>
      </SliderFullPage.Item>
    </SliderFullPage>
  );
};
