import { useInfiniteQuery } from 'react-query';

import { PAGE_SIZE } from '@Constants/configs';
import { InboxGetListRequest, inboxService } from '@Services/Inbox';

export const useInboxList = (params?: InboxGetListRequest) => {
  return useInfiniteQuery(
    [inboxService.getEndpoint(), params],
    ({ pageParam = 1 }) => {
      return inboxService.getList({
        ...params,

        page: pageParam,
        perPage: params?.perPage || PAGE_SIZE
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page + 1 <= lastPage.totalPages) {
          return lastPage.page + 1;
        }
      }
    }
  );
};
