import { Platform } from '@Constants/enums';

export const IS_LOCAL = process.env.REACT_APP_ENV === 'local';
export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

export const HTTP_CODE_UNAUTHORIZED = 401;
export const DEFAULT_LANGUAGE = 'nl';
export const PAGE_SIZE = 4;

export const PLATFORMS: Record<Platform, string[]> = {
  [Platform.ARBONED]: [
    'dev.arboned.ik.kom-verder.nl',
    'staging.arboned.ik.kom-verder.nl',
    'arboned.ik.kom-verder.nl'
  ],
  [Platform.MENSELY]: [
    'dev.mensely.ik.kom-verder.nl',
    'staging.mensely.ik.kom-verder.nl',
    'mensely.ik.kom-verder.nl'
  ],
  [Platform.KOM_VERDER]: ['dev.ik.kom-verder.nl', 'staging.ik.kom-verder.nl', 'ik.kom-verder.nl'],
  [Platform.HUMAN_CAPITAL_CARE]: [
    'dev.humancapitalcare.ik.kom-verder.nl',
    'staging.humancapitalcare.ik.kom-verder.nl',
    'humancapitalcare.ik.kom-verder.nl'
  ]
};

export const SENTRY_DSN =
  'https://4664ef23c75643afb7058b758dea06ae@o178697.ingest.sentry.io/6033905';

export const LICENSE_CODE_LENGTH = 8;
export const EMAIL_CODE_LENGTH = 6;
export const SMS_CODE_LENGTH = 6;

export const ACTIVITY_TYPE_ROADMAP_ID = 'd7b1ff10-cfb7-479b-9080-2cae62bcc429';

export const TOTAL_SELFTEST_PHASES = 4;

export const MAX_PENDING_CHALLENGES = 2;
export const REQUIRED_ACTIVITY_AMOUNT = 2;
export const NUMBER_OF_PROFILE_STATUS_STEPS = 4;

export const MAX_PERCENT = 100;

export const SMS_COUNTDOWN_SECONDS = 10;

export const MAX_HAPPINESS_SCORE = 100;

export const MOTIVATOR_TIMEOUT = 20000;

export const MAX_FIRSTNAME_LENGTH = 60;
export const MAX_SURNAME_LENGTH = 60;

export const USER_ACTIVITY_DURATION_MINUTES = 20;

export const AUTOMATIC_LOGOUT_DURATION_MINUTES = 19;
export const AUTOMATIC_LOGOUT_COUNTDOWN_MINUTES = 1;
