import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ProfileStatus } from '@Components/container';
import { Container } from '@Components/layout';
import { Box, Text } from '@Components/ui';
import {
  useOnboardingGetSelfTestQuestionnaire,
  useOnboardingSkipSelfTest
} from '@Hooks/onboarding';
import { AppRoutes } from '@Navigation/Routes';
import { QuestionnaireSubmissionSubmitSelfTestSkipType } from '@Services/QuestionnaireSubmission';

export const OnboardingOverviewScreen = () => {
  const { t } = useTranslation();
  const onboardingSkipSelfTestMutation = useOnboardingSkipSelfTest();

  const { data: selfTestQuestionnaire } = useOnboardingGetSelfTestQuestionnaire();

  const navigate = useNavigate();

  const handleSelfTestClick = useCallback(async () => {
    navigate(AppRoutes.getSelfTest().to);
  }, [navigate]);

  const handleSelfTestSkipClick = useCallback(async () => {
    if (selfTestQuestionnaire) {
      selfTestQuestionnaire.skipType = QuestionnaireSubmissionSubmitSelfTestSkipType.Skipped;
      await onboardingSkipSelfTestMutation.mutateAsync(selfTestQuestionnaire);
    }

    navigate(AppRoutes.getDashboard().to);
  }, [navigate, onboardingSkipSelfTestMutation, selfTestQuestionnaire]);

  return (
    <Container cols={4} data-testid="OnboardingOverviewScreen">
      <Box mb={2} mx={4}>
        <Text.H2>{t('DOMAIN.ONBOARDING.OVERVIEW_SCREEN.TITLE.TOP')}</Text.H2>
        <Text.H2>{t('DOMAIN.ONBOARDING.OVERVIEW_SCREEN.TITLE.BOTTOM')}</Text.H2>
      </Box>

      <Box mb={5} mx={4}>
        <Text.Large>{t('DOMAIN.ONBOARDING.OVERVIEW_SCREEN.DESCRIPTION')}</Text.Large>
      </Box>

      <ProfileStatus
        canOpenNext={false}
        showCta
        onSelfTestClick={handleSelfTestClick}
        onSelfTestSkipClick={handleSelfTestSkipClick}
      />
    </Container>
  );
};
