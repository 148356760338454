import cn from 'classnames';

import styles from './InputSliderLabels.module.scss';
import { InputSliderLabelsProps } from './InputSliderLabels.props';

export const InputSliderLabels = ({
  marks,
  leftLabel,
  rightLabel,
  isError,
  isValid,
  isDisabled,
  isSmall
}: InputSliderLabelsProps) => {
  const classNames = cn(styles.InputSliderLabels, {
    [styles.isError]: isError,
    [styles.isValid]: isValid,
    [styles.isDisabled]: isDisabled,
    [styles.isSmall]: isSmall
  });

  if (leftLabel && rightLabel) {
    return (
      <div className={classNames}>
        <span>{leftLabel}</span>

        <span>{rightLabel}</span>
      </div>
    );
  }

  if (marks) {
    return (
      <div className={classNames}>
        <span>{marks && marks[0]}</span>

        <span>{marks && marks[marks.length - 1]}</span>
      </div>
    );
  }

  return null;
};
