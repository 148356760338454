const defaultMeasurements = {
  clientHeight: 0,
  clientWidth: 0,
  offsetHeight: 0,
  offsetWidth: 0,
  scrollHeight: 0,
  scrollWidth: 0,
  location: {
    x: 0,
    y: 0
  }
};

export const getElementMeasurements = (element: HTMLElement | null) => {
  if (element) {
    return {
      clientHeight: element.clientHeight,
      clientWidth: element.clientWidth,
      offsetHeight: element.offsetHeight,
      offsetWidth: element.offsetWidth,
      scrollHeight: element.scrollHeight,
      scrollWidth: element.scrollWidth,
      location: {
        x: element.offsetLeft,
        y: element.offsetTop
      }
    };
  }

  return defaultMeasurements;
};

export const isIntersecting = (targetRect: DOMRectReadOnly, elementRect: DOMRectReadOnly) => {
  const aLeftOfB = elementRect.right < targetRect.left;
  const aRightOfB = elementRect.left > targetRect.right;

  const aAboveB = elementRect.bottom < targetRect.top;
  const aBelowB = elementRect.top > targetRect.bottom;

  return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
};
