import { IActivityFilter } from '@Services/Activity';

export interface ActivityFilterTagsFormProps {
  onChange: (values: ActivityFilterTagsFormValuesWithLabels) => void;
}

export interface ActivityFilterTagsFormValues {
  subjects?: Array<string | undefined>;
  types?: Array<string | undefined>;
  points?: Array<string | undefined>;
  durations?: Array<string | undefined>;
}

export interface ActivityFilterTagsFormValuesWithLabels {
  subjects?: IActivityFilter[];
  types?: IActivityFilter[];
  points?: string;
  durations?: IActivityFilter[];
}

export enum FilterTagsPoints {
  '_0_TO_25' = '0-25',
  '_26_TO_50' = '26-50',
  '_51_TO_75' = '51-75',
  '_76_TO_100' = '76-100',
  '_101_TO_9999' = '100'
}
