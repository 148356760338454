import { CardTodo } from '@Components/container';
import { CardList } from '@Components/list';

import { ListTodoProps } from './ListTodo.props';

export const ListTodo = ({ children, todos }: ListTodoProps) => {
  return (
    <CardList>
      {children}

      {todos.map((todo) => {
        return <CardTodo key={todo.id} todo={todo} />;
      })}
    </CardList>
  );
};
