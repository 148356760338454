import cn from 'classnames';
import { LottieOptions, useLottie } from 'lottie-react';
import { useEffect } from 'react';

import loader from '@Assets/lotties/loader.json';

import styles from './Loader.module.scss';
import { LoaderProps } from './Loader.props';

export const Loader = ({ children, className }: LoaderProps) => {
  const lottieOptions: LottieOptions = {
    animationData: loader,
    loop: true,
    style: {
      width: '64px'
    },
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: 'none'
    }
  };

  const { View, play } = useLottie(lottieOptions);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <div className={cn(styles.Loader, className)}>
      <div className={cn(styles.LottieWrapper)}>{View}</div>

      {children && <div className={styles.Children}>{children}</div>}
    </div>
  );
};
