import { InputOption } from '@Constants/interfaces';
import { ActivitySubjectsResponse } from '@Services/Data';

export const activitySubjectsToInputOptions = (types: ActivitySubjectsResponse): InputOption[] => {
  return types.map((item) => {
    return {
      value: item.preprId,
      label: item.title,
      description: item.description
    };
  });
};
