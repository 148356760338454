import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useLocationEffect = (callback: (location: string) => void) => {
  const { pathname } = useLocation();

  useEffect(() => {
    callback(pathname);
  }, [pathname, callback]);
};
