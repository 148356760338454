import { TimelineSmallGroup } from './internal/components';
import { TimelineSmallProps } from './TimelineSmall.props';

export const TimelineSmall = ({ className, timeline }: TimelineSmallProps) => {
  return (
    <div className={className}>
      {Object.entries(timeline).map(([date, items]) => {
        return <TimelineSmallGroup date={new Date(date)} items={items} key={date} />;
      })}
    </div>
  );
};
