import cn from 'classnames';
import { Controller } from 'react-hook-form';

import { FormField, InputCarousel } from '@Components/ui';
import { getFieldStateValidation } from '@Utils/FormUtils';

import styles from './FormCarousel.module.scss';
import { FormCarouselProps } from './FormCarousel.props';

export const FormCarousel = ({ options, className, ...form }: FormCarouselProps) => {
  const classNames = cn(styles.FormCarousel, className);

  return (
    <FormField {...form} className={classNames} hasController>
      <Controller
        name={form.name}
        render={({ field, fieldState }) => {
          const { isError, isValid } = getFieldStateValidation(fieldState);

          return <InputCarousel isValid={isValid} isError={isError} {...options} {...field} />;
        }}
      />
    </FormField>
  );
};
