import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingVerifyLicenseCodeForm } from '@Components/form';
import {
  Box,
  CallToAction,
  SliderFullPage,
  Text,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';
import { TargetDirection, usePersistSearchParam } from '@Hooks/common';

import styles from './OnboardingVerifyLicenseCodeAlternativeSlide.module.scss';
import { OnboardingVerifyLicenseCodeAlternativeSlideProps } from './OnboardingVerifyLicenseCodeAlternativeSlide.props';

export const OnboardingVerifyLicenseCodeAlternativeSlide = ({
  onSuccess
}: OnboardingVerifyLicenseCodeAlternativeSlideProps) => {
  const { t } = useTranslation();
  const { current } = useSliderFullPageContext();
  const { slideNext } = useSliderFullPageItemContext();
  const ref = useRef<HTMLElement | null>(null);
  const { getPersistedSearchParam } = usePersistSearchParam('token');

  const token = getPersistedSearchParam();

  const handleSuccess = useCallback(
    (licenseCode: string) => {
      onSuccess(licenseCode);
      slideNext();
    },
    [slideNext, onSuccess]
  );

  return (
    <SliderFullPage.Item showPagination>
      <SliderFullPage.Head
        title={t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE_ALTERNATIVE.HEADER.TITLE')}
        intro={t(
          'DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE_ALTERNATIVE.HEADER.DESCRIPTION'
        )}
        showPagination
      />
      <SliderFullPage.Block
        title={
          token
            ? t(
                'DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE_ALTERNATIVE.FORM.TITLE_WITH_TOKEN'
              )
            : t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE_ALTERNATIVE.FORM.TITLE')
        }
        ref={ref}
      >
        <OnboardingVerifyLicenseCodeForm onSuccess={handleSuccess} isAlternative />

        <Box mt={3}>
          <Text.Small>
            {token
              ? t(
                  'DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE_ALTERNATIVE.FORM.DESCRIPTION_WITH_TOKEN'
                )
              : t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE_ALTERNATIVE.FORM.CONTACT')}
          </Text.Small>
        </Box>

        <CallToAction
          targetRef={ref}
          isVisible={current === 0}
          direction={TargetDirection.RIGHT}
          isReversed
          isStatic
          className={styles.CTA}
        />

        <CallToAction
          targetRef={ref}
          isVisible={current === 0}
          direction={TargetDirection.LEFT}
          isReversed
          isStatic
          label={
            token
              ? t(
                  'DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE_ALTERNATIVE.FORM.CTA_WITH_TOKEN'
                )
              : t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE_ALTERNATIVE.FORM.CTA')
          }
        />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
