import cn from 'classnames';
import { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Checkmark, SpacingGroup, Tag, Text, Tooltip } from '@Components/ui';
import { useHover, usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';

import styles from './CardChallenge.module.scss';
import { CardChallengeProps } from './CardChallenge.props';

export const CardChallenge = forwardRef(
  (
    { challenge, onClick, isDisabled = false, disabledLabel, className }: CardChallengeProps,
    ref: any
  ) => {
    const cardRef = useRef(null);

    const { t } = useTranslation();
    const { color } = usePlatform();
    const { isHovering, handlers } = useHover();

    const componentClasses = cn(
      styles.CardChallenge,
      { [styles.isDisabled]: isDisabled },
      className
    );

    const link =
      onClick || isDisabled ? undefined : AppRoutes.getInsightChallenge(challenge.slug).to;

    const handleClick = () => {
      if (onClick && !isDisabled) {
        onClick(challenge);
      }
    };

    return (
      <>
        <div ref={cardRef} className={componentClasses} {...handlers}>
          <Card
            ref={ref}
            to={link}
            image={challenge.thumbnail}
            isRecommended={challenge.recommended}
            onClick={onClick && handleClick}
          >
            <Text.H4 className={styles.Title}>{challenge.title}</Text.H4>

            <SpacingGroup>
              <Tag size="md" label={t('DOMAIN.CHALLENGE.SINGLE')} color={color.font.default} />

              {challenge.completed && <Checkmark isLabeled />}
            </SpacingGroup>

            <Text.Small className={styles.Excerpt}>{challenge.excerpt}</Text.Small>
          </Card>
        </div>

        {disabledLabel && isDisabled && (
          <Tooltip label={disabledLabel} targetRef={cardRef} isVisible={isHovering} />
        )}
      </>
    );
  }
);
