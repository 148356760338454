import { useCallback } from 'react';

export const usePathUtils = () => {
  const getRatio = useCallback((value: number, total: number) => {
    const boundedValue = Math.min(Math.max(value, 0), total);
    return (boundedValue - 0) / (total - 0);
  }, []);

  const getPathRadius = (viewBoxHeight: number, strokeWidth: number) => {
    return viewBoxHeight / 2 - strokeWidth / 2;
  };

  return { getRatio, getPathRadius };
};
