import { useQuery } from 'react-query';

import { contentService } from '@Services/Content';

export const useContentGetPage = (id: string) => {
  return useQuery(
    [contentService.getEndpoint(), id],
    () => {
      return contentService.getPage({ id });
    },
    { retry: 1, retryDelay: 500 }
  );
};
