import { t } from 'i18next';

import { Box, Text } from '@Components/ui';

export const ReportIntroUnavailableRegular = () => {
  return (
    <Box mb={2}>
      <Text.Large>{t('DOMAIN.REPORT.UNAVAILABLE.REGULAR.INTRO')}</Text.Large>
    </Box>
  );
};
