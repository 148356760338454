import { forwardRef, useCallback, useMemo } from 'react';

import { Form } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { usePreferenceList, usePreferenceSubmit } from '@Hooks/preference';
import { PreferenceKey } from '@Services/Preference';
import { getInputOptions, getPreference } from '@Utils/PreferenceUtils';
import Yup from '@Utils/YupUtils';

import { Education } from './internal/constants';
import {
  PreferencesEducationFormProps,
  PreferencesEducationFormValues
} from './PreferencesEducationForm.props';

export const PreferencesEducationForm = forwardRef(
  ({ onSuccess, children, className }: PreferencesEducationFormProps, ref: any) => {
    const preferencesQuery = usePreferenceList();
    const preferenceSubmitMutation = usePreferenceSubmit();
    const { handleError } = useApiError();

    const inputOptions = useMemo(() => {
      return getInputOptions(Object.values(Education), 'EDUCATION');
    }, []);

    const handleSubmit = useCallback(
      async (values: PreferencesEducationFormValues) => {
        try {
          await preferenceSubmitMutation.mutateAsync({
            key: PreferenceKey.EDUCATION,
            values: [values.education]
          });

          onSuccess();
        } catch (e) {
          handleError(e);
        }
      },
      [onSuccess, preferenceSubmitMutation, handleError]
    );

    const initialValues = {
      education: getPreference(preferencesQuery.data || [], PreferenceKey.EDUCATION)?.[0]
    };

    const validationSchema = Yup.object().shape({
      education: Yup.string().required()
    });

    if (preferencesQuery.isFetched) {
      return (
        <Form.Form<PreferencesEducationFormValues>
          ref={ref}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          className={className}
          data-testid="educationForm"
        >
          <Form.Input.Carousel
            name="education"
            data-testid="educationInput"
            options={{
              options: inputOptions
            }}
          />

          {children}
        </Form.Form>
      );
    }

    return null;
  }
);
