import cn from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import {
  TargetAlignment,
  TargetDirection,
  useBoolean,
  useClickOutside,
  useTargetedLocation,
  useTimeouts
} from '@Hooks/common';

import styles from './Dropdown.module.scss';
import { DropdownProps } from './Dropdown.props';
import { ANIMATION_DURATION } from './internal/constants';

export const Dropdown = ({
  children,
  targetRef,
  alignment = TargetAlignment.CENTER,
  isOpen,
  onClose,
  className
}: DropdownProps) => {
  const dropdownRef = useRef(null);

  const { value: hasOpened, setTrue: setOpened, setFalse: reset } = useBoolean(false);

  const { addTimeout } = useTimeouts();

  useEffect(() => {
    if (isOpen) {
      addTimeout(setOpened, ANIMATION_DURATION);
    } else {
      reset();
    }
  }, [isOpen, addTimeout, setOpened, hasOpened, reset]);

  const { location } = useTargetedLocation(
    dropdownRef.current,
    targetRef?.current,
    TargetDirection.UP,
    alignment,
    undefined,
    undefined,
    false,
    isOpen
  );

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    reset();
  }, [onClose, reset]);

  useClickOutside(handleClose, hasOpened, dropdownRef);

  return ReactDOM.createPortal(
    <div
      ref={dropdownRef}
      className={cn(styles.Dropdown, { [styles.isOpen]: isOpen }, className)}
      style={{ left: location.x, top: location.y }}
    >
      {children}
    </div>,
    document.body
  );
};
