import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PreprRenderer } from '@Components/layout';
import { Checkmark, Image, SpacingGroup, Tag, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { usePreprTrackView } from '@Hooks/prepr';
import { useUser } from '@Hooks/user';
import { trackMatomoRewardOpen } from '@Utils/MatomoUtils';
import { toLocaleString } from '@Utils/StringUtils';

import { RewardSideviewActivities, RewardSideviewClaim } from './internal/components';
import styles from './RewardSideview.module.scss';
import { RewardSideviewProps } from './RewardSideview.props';

export const RewardSideview = ({ reward, onClose }: RewardSideviewProps) => {
  const { color } = usePlatform();
  const { points } = useUser();
  const { t } = useTranslation();
  const trackEvent = useMatomoTrackEvent();
  usePreprTrackView(reward.id);

  const remainingPoints = points - reward.points;

  const isClaimed = !!reward?.claimedAt;
  const isClaimable = remainingPoints >= 0;

  useEffect(() => {
    trackMatomoRewardOpen(trackEvent, reward.title, reward.type[0].title);
  }, [reward.title, reward.type, trackEvent]);

  return (
    <div className={styles.RewardSideview} data-testid="rewardSideview">
      <SpacingGroup className={styles.Tags}>
        {isClaimed && (
          <Checkmark size="sm" className={styles.Claimed} label={t('DOMAIN.REWARD.REDEEMED')} />
        )}

        {!isClaimed && (
          <>
            <Tag
              color={color.primary.default}
              isInverted={!isClaimable}
              size="lg"
              label={toLocaleString(reward.points)}
              icon="star"
            />

            <Text.Small className={styles.Points}>
              {t(`DOMAIN.REWARD.${isClaimable ? 'POINTS_LEFT' : 'POINTS_TOO_FEW'}` as any, {
                count: remainingPoints,
                points: toLocaleString(Math.abs(remainingPoints))
              })}
            </Text.Small>
          </>
        )}
      </SpacingGroup>

      <Image
        className={styles.Thumbnail}
        src={reward.thumbnail[0].cdnFiles[0].url}
        alt={reward.title}
      />

      <PreprRenderer className={styles.Content} content={reward.content} />

      <RewardSideviewClaim reward={reward} onClaim={onClose} />

      {!isClaimed && !isClaimable && (
        <RewardSideviewActivities reward={reward} className={styles.Activities} />
      )}
    </div>
  );
};
