import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Form } from '@Components/ui';
import Yup from '@Utils/YupUtils';

import {
  EditPhoneNewPhoneFormProps,
  EditPhoneNewPhoneFormValues
} from './EditPhoneNewPhoneForm.props';

export const EditPhoneNewPhoneForm = ({ onSuccess }: EditPhoneNewPhoneFormProps) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (values: EditPhoneNewPhoneFormValues) => {
      onSuccess(values);
    },
    [onSuccess]
  );

  const validationSchema = Yup.object().shape({
    newPhoneNumber: Yup.string().phone().required(),
    newPhoneNumberConfirmed: Yup.string()
      .phone()
      .oneOf(
        [Yup.ref('newPhoneNumber')],
        t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.NEW_PHONE_NUMBER_CONFIRMED.NO_MATCH')
      )
      .required()
  });

  return (
    <Box mt={3}>
      <Form.Form<EditPhoneNewPhoneFormValues>
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form.Input.Text
          name="newPhoneNumber"
          data-testid="newPhoneNumber"
          label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.NEW_PHONE_NUMBER.LABEL')}
          options={{
            type: 'tel'
          }}
        />

        <Form.Input.Text
          name="newPhoneNumberConfirmed"
          data-testid="newPhoneNumberConfirmed"
          label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.NEW_PHONE_NUMBER_CONFIRMED.LABEL')}
          options={{
            type: 'tel'
          }}
        />

        <Button type="submit" color="dark" icon="arrow-right" data-testid="submitButton">
          {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.UPDATE_PHONE.SUBMIT_LABEL')}
        </Button>
      </Form.Form>
    </Box>
  );
};
