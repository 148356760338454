import cn from 'classnames';
import { useRef } from 'react';
import ReactDOM from 'react-dom';

import { Text } from '@Components/ui';
import {
  TargetAlignment,
  TargetDirection,
  useAnimatedToggle,
  useTargetedLocation
} from '@Hooks/common';

import { TOOLTIP_HOVER_DURATION } from './internal/constants';
import styles from './Tooltip.module.scss';
import { TooltipProps } from './Tooltip.props';

export const Tooltip = ({
  targetRef,
  label,
  alignment = TargetAlignment.LEFT,
  isVisible = false,
  hasOffset = false,
  className,
  style,
  ...props
}: TooltipProps) => {
  const tooltipRef = useRef(null);
  const { isShowing } = useAnimatedToggle(isVisible, TOOLTIP_HOVER_DURATION);

  const getOffset = () => {
    if (alignment === TargetAlignment.LEFT) {
      return -8;
    }

    if (alignment === TargetAlignment.RIGHT) {
      return 8;
    }
  };

  const { location, correctionAmount } = useTargetedLocation(
    tooltipRef.current,
    targetRef?.current,
    TargetDirection.DOWN,
    alignment,
    8,
    hasOffset ? getOffset() : 0,
    false,
    isVisible,
    true
  );

  const getClasses = () => {
    const classes = [styles.Tooltip, { [styles.isVisible]: isVisible }, className];

    switch (alignment) {
      case 'left':
        classes.push(styles.isLeft);
        break;
      case 'right':
        classes.push(styles.isRight);
        break;
      default:
        classes.push(styles.isCentered);
    }

    return cn(classes);
  };

  const getPointerAlignment = () => {
    const pointerAlignment: { left: string | number; right: string | number } = {
      left: 'auto',
      right: 'auto'
    };

    if (correctionAmount.x < 0) {
      pointerAlignment.left = correctionAmount.x * -1 + 12;
    }

    if (correctionAmount.x > 0) {
      pointerAlignment.right = correctionAmount.x + 12;
    }

    if (correctionAmount.x === 0) {
      return {}; // return nothing and let the scss take over
    }

    return pointerAlignment;
  };

  if (isShowing) {
    return ReactDOM.createPortal(
      <div
        ref={tooltipRef}
        className={getClasses()}
        style={{ ...style, left: location.x, top: location.y }}
        {...props}
      >
        <Text.Normal className={styles.Label}>{label}</Text.Normal>

        <span className={styles.Pointer} style={{ ...getPointerAlignment() }} />
      </div>,
      document.body
    );
  }

  return null;
};
