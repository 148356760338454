import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AuthResetPasswordForm } from '@Components/form';
import { SliderFullPage, useSliderFullPageItemContext } from '@Components/ui';
import { usePersistSearchParam } from '@Hooks/common';

export const ResetPasswordPasswordSlide = () => {
  const { t } = useTranslation();
  const { slideNext } = useSliderFullPageItemContext();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const { getPersistedSearchParam } = usePersistSearchParam('token');
  const token = getPersistedSearchParam();

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        title={t('DOMAIN.AUTHENTICATION.RESET_PASSWORD.PASSWORD.HEADER.TITLE')}
        intro={t('DOMAIN.AUTHENTICATION.RESET_PASSWORD.PASSWORD.HEADER.DESCRIPTION')}
      />

      <SliderFullPage.Block title={t('DOMAIN.AUTHENTICATION.RESET_PASSWORD.PASSWORD.FORM.TITLE')}>
        {token && email && (
          <AuthResetPasswordForm token={token} email={email} onSuccess={slideNext} />
        )}
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
