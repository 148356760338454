import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CallToAction, InputCheckbox } from '@Components/ui';
import { Block, Box, Button, Text } from '@Components/ui';
import { TargetDirection, useBoolean } from '@Hooks/common';

import { PreprElementRoadmapFinishStepProps } from './PreprElementRoadmapFinishStep.props';

export const PreprElementRoadmapFinishStep = ({
  item,
  onFinish,
  isLoading,
  nextStepNumber
}: PreprElementRoadmapFinishStepProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const inputName = `hasFinished_${nextStepNumber - 1}`;
  const { value: isAccepted, setValue: setAccepted } = useBoolean(false);

  const handleSubmit = useCallback(() => {
    if (isAccepted) {
      onFinish();
    }
  }, [isAccepted, onFinish]);

  const handleToggle = useCallback(
    (values: string[]) => {
      setAccepted(Boolean(values[0]));
    },
    [setAccepted]
  );

  return (
    <>
      <Box mb={3}>
        <CallToAction
          direction={TargetDirection.DOWN}
          isReversed
          label={t('DOMAIN.ACTIVITY.ROADMAP.FINISH_STEP.CTA')}
        />
      </Box>

      <Block ref={ref} theme="gray">
        <Box mb={item.complete ? 0.5 : 3}>
          <Text.H4>{t('DOMAIN.ACTIVITY.ROADMAP.FINISH_STEP.TITLE')}</Text.H4>
        </Box>

        {item.complete && (
          <Box mb={3}>
            <Text.Normal>{item.complete}</Text.Normal>
          </Box>
        )}

        <InputCheckbox
          name={inputName}
          value={isAccepted ? ['true'] : undefined}
          onChange={handleToggle}
          options={[
            { value: 'true', label: t('DOMAIN.ACTIVITY.ROADMAP.FINISH_STEP.CHECKMARK_LABEL') }
          ]}
        />

        <Box mt={2}>
          <Button
            onClick={handleSubmit}
            isDisabled={!isAccepted}
            icon="arrow-right"
            data-testid="finishStep"
            isLoading={isLoading}
          >
            {t('DOMAIN.ACTIVITY.ROADMAP.FINISH_STEP.NEXT_LABEL', { nextStep: nextStepNumber })}
          </Button>
        </Box>
      </Block>
    </>
  );
};
