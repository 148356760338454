import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { StorageKey } from '@Constants/enums';
import { getItem, removeItem, setItem } from '@Utils/StorageUtils';

export const useAuthRedirect = (pathname?: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname) {
      setItem(StorageKey.REDIRECT, pathname);
    }
  }, [pathname]);

  const hasRedirect = useCallback(() => {
    return !!getItem<string>(StorageKey.REDIRECT);
  }, []);

  const redirect = useCallback(() => {
    const item = getItem<string>(StorageKey.REDIRECT) ?? '/';

    navigate(item);
    removeItem(StorageKey.REDIRECT);
  }, [navigate]);

  return { redirect, hasRedirect };
};
