import { IMotivator } from '@Constants/interfaces';
import {
  QuestionnaireSubmissionSubmitPersonaResponse,
  QuestionnaireSubmissionSubmitSelfTestResponse
} from '@Services/QuestionnaireSubmission';

export interface OnboardingVerifyRequest {
  licenseCode: string;
  reason?: string;
}

export interface OnboardingVerifyResponse {
  isValid: boolean;
}

export interface OnboardingRegisterRequest {
  licenseCode: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  securityQuestionId: string;
  securityQuestionAnswer: string;
}
export interface OnboardingRegisterResponse {
  userId: string;
}

export interface OnboardingValidateEmailRequest {
  licenseCode: string;
  email: string;
}

export enum OnboardingValidateEmailReason {
  EMAIL_IN_USE = 'EMAIL_IN_USE',
  EMAIL_INVALID_DOMAIN = 'EMAIL_INVALID_DOMAIN'
}

export interface OnboardingValidateEmailResponse {
  isValid: boolean;
  reason?: OnboardingValidateEmailReason;
}

export interface OnboardingUpdateEmailRequest {
  userId: string;
  email: string;
}

export interface OnboardingResendEmailRequest {
  userId: string;
}

export interface OnboardingVerifyEmailRequest {
  userId: string;
  token: string;
}

export interface OnboardingUpdatePhoneRequest {
  userId: string;
  phoneNumber: string;
}

export interface OnboardingVerifyPhoneRequest {
  userId: string;
  phoneNumber: string;
  token: string;
}

export type OnboardingGetPersonaQuestionnaireResponse =
  QuestionnaireSubmissionSubmitPersonaResponse;

export type OnboardingGetSelfTestQuestionnaireResponse =
  QuestionnaireSubmissionSubmitSelfTestResponse;

export interface PersonaVerificationQuestionAnswer {
  id: string;
  text: string;
}

export type OnboardingGetPersonaVerificationQuestionResponse = PersonaVerificationQuestionAnswer[];

export interface OnboardingSubmitPersonaVerificationQuestionRequest {
  id: string;
}
export type OnboardingSubmitPersonaVerificationQuestionResponse = IMotivator;

export interface OnboardingSelfTestDomain {
  id: string;
  title: string;
  description: string;
}

export type OnboardingGetSelfTestDomainsResponse = OnboardingSelfTestDomain[];

export interface OnboardingSubmitSelfTestDomainsRequest {
  domains: string[];
}
