import { useMutation } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { ActivityInviteRequest, activityService } from '@Services/Activity';
import { trackMatomoActivityShare } from '@Utils/MatomoUtils';

export const useActivityInvite = () => {
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: ActivityInviteRequest) => {
      return activityService.invite(config);
    },
    {
      onSuccess: (_, { title, type }) => {
        trackMatomoActivityShare(trackEvent, title, type);
      }
    }
  );
};
