import { useMutation, useQueryClient } from 'react-query';

import { useMatomoTrackEvent } from '@Hooks/matomo';
import { todoService } from '@Services/Todo';
import { userService } from '@Services/User';
import { UserActivityCreateRequest, userActivityService } from '@Services/UserActivity';
import { trackMatomoTodoCreate } from '@Utils/MatomoUtils';

export const useUserActivityCreate = () => {
  const queryClient = useQueryClient();
  const trackEvent = useMatomoTrackEvent();

  return useMutation(
    (config: UserActivityCreateRequest) => {
      return userActivityService.create(config);
    },
    {
      onSuccess: (_, { customTitle, description, expectation }) => {
        trackMatomoTodoCreate(trackEvent, customTitle, description, expectation);

        queryClient.invalidateQueries([todoService.getEndpoint()]);
        queryClient.invalidateQueries([userService.getEndpoint(), 'me']);
      }
    }
  );
};
