import { omitBy } from 'lodash';
import { InfiniteData } from 'react-query';

import { ListResponse } from '@Constants/interfaces';

export const removeEmptyOrUndefinedFromObject = (values: any) => {
  return omitBy(values, (value) => !value || (Array.isArray(value) && value.length === 0));
};

export const removeUndefinedFromArray = <T>(values: Array<T | undefined>): T[] => {
  const filtered = values.filter((value) => value !== undefined) as T[];

  return filtered;
};

export const exists = <T>(value?: any): value is T => {
  return typeof value !== 'undefined' && value !== null;
};

export const listHasResults = (data?: ListResponse<any>) => {
  if (data && data.results && data.results.length > 0) {
    return true;
  }

  return false;
};

export const paginatedListHasResults = (data?: InfiniteData<ListResponse<any>>) => {
  if (data && data.pages && data.pages.length > 0 && listHasResults(data.pages[0])) {
    return true;
  }

  return false;
};
