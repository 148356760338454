import cn from 'classnames';
import { LottieOptions, useLottie } from 'lottie-react';

import highlightAnimation from '@Assets/lotties/highlight.json';
import { usePlayInView } from '@Hooks/common';

import styles from './Highlight.module.scss';
import { HighlightProps } from './Highlight.props';

export const Highlight = ({ children, className }: HighlightProps) => {
  const lottieOptions: LottieOptions = {
    animationData: highlightAnimation,
    loop: false,
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: 'none'
    }
  };

  const { View, play } = useLottie(lottieOptions);
  const [inViewRef] = usePlayInView(play);

  if (!children) return null;

  return (
    <span className={cn(styles.Highlight, className)}>
      <span ref={inViewRef} className={styles.LottieWrapper}>
        {View}
      </span>

      {children}
    </span>
  );
};
