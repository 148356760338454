import { useCallback, useState } from 'react';

import { ChallengeAddSideview } from '@Components/container';
import { ButtonCancel, Container } from '@Components/layout';
import { SliderFullPage } from '@Components/ui';
import { useBoolean } from '@Hooks/common';
import { useUser } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';
import { IChallenge } from '@Services/Challenge';
import { IUserTheme } from '@Services/UserTheme';

import { ChallengeAddChallengeSlide, ChallengeAddThemeSlide } from './internal/components';

export const ChallengeAddScreen = () => {
  const { isFirstTime } = useUser();
  const [selectedTheme, setSelectedTheme] = useState<IUserTheme>();
  const [selectedChallenge, setSelectedChallenge] = useState<IChallenge>();
  const {
    value: isSideviewOpen,
    setTrue: setSideviewOpen,
    setFalse: setSideviewClosed
  } = useBoolean(false);

  const handleSelectChallenge = useCallback(
    (challenge: IChallenge) => {
      setSelectedChallenge(challenge);
      setSideviewOpen();
    },
    [setSideviewOpen]
  );

  return (
    <>
      <ButtonCancel
        to={isFirstTime ? AppRoutes.getDashboard().to : AppRoutes.getInsightChallenges().to}
      />

      <Container cols={10}>
        <SliderFullPage>
          <ChallengeAddThemeSlide selectedTheme={selectedTheme} onSelectTheme={setSelectedTheme} />

          <ChallengeAddChallengeSlide
            selectedTheme={selectedTheme}
            onSelectChallenge={handleSelectChallenge}
          />
        </SliderFullPage>

        {selectedChallenge && (
          <ChallengeAddSideview
            slug={selectedChallenge.slug}
            isOpen={isSideviewOpen}
            onClose={setSideviewClosed}
          />
        )}
      </Container>
    </>
  );
};
