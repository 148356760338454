import cn from 'classnames';
import { forwardRef } from 'react';

import { Icon } from '@Components/ui';

import { BAR_ANIMATION_LENGTH } from '../../constants';
import { useIsDoneAnimating } from '../../hooks';
import styles from './Dots.module.scss';
import { DotsProps } from './Dots.props';

export const Dots = forwardRef(({ total, current, isVisible }: DotsProps, ref?: any) => {
  const [isDoneAnimating] = useIsDoneAnimating(1, isVisible, BAR_ANIMATION_LENGTH);

  return (
    <div className={styles.Dots}>
      {Array(total)
        .fill(null)
        .map((_, idx) => {
          const isChecked = idx + 1 <= current;
          const isCurrent = idx + 1 === current;
          const isLast = idx + 1 === total;
          const animationOffset = (BAR_ANIMATION_LENGTH / (total + 2)) * 2;
          const dotRef = isCurrent || (current === 0 && idx === 0) ? ref : undefined;

          let delay = idx * (BAR_ANIMATION_LENGTH / total) - animationOffset;

          if (isDoneAnimating) {
            delay = 0;
          }

          return (
            <div ref={dotRef} key={idx} className={styles.DotWrapper}>
              <div
                className={cn(styles.Dot, {
                  [styles.isChecked]: isVisible && isChecked,
                  [styles.isLast]: isLast
                })}
                style={{ transitionDelay: `${delay}ms` }}
              >
                <Icon icon={['far', 'check']} className={styles.Icon} />
              </div>

              <div
                className={cn(styles.Fade, {
                  [styles.isChecked]: isVisible && isChecked
                })}
                style={{ transitionDelay: `${delay}ms` }}
              />
            </div>
          );
        })}
    </div>
  );
});
