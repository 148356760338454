import { CardActivity } from '@Components/container';
import { Slider } from '@Components/ui';

import { SliderCardActivityProps } from './SliderCardActivity.props';

export const SliderCardActivity = ({ activities, className }: SliderCardActivityProps) => {
  return (
    <Slider className={className}>
      {activities.map((item) => {
        return <CardActivity key={item.id} activity={item} />;
      })}
    </Slider>
  );
};
