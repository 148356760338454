import cn from 'classnames';
import { Controller } from 'react-hook-form';

import { FormField, InputSelect } from '@Components/ui';
import { getFieldStateValidation } from '@Utils/FormUtils';

import styles from './FormSelect.module.scss';
import { FormSelectProps } from './FormSelect.props';

export const FormSelect = ({ options, className, ...form }: FormSelectProps) => {
  const classNames = cn(styles.FormSelect, className);

  return (
    <FormField {...form} className={classNames} hasController>
      <Controller
        name={form.name}
        render={({ field, fieldState }) => {
          const { isValid, isError } = getFieldStateValidation(fieldState);

          field.value = options.options.find((c) => c.value === field.value);

          return <InputSelect isValid={isValid} isError={isError} {...options} {...field} />;
        }}
      />
    </FormField>
  );
};
