import cn from 'classnames';
import { useEffect } from 'react';

import { Text } from '@Components/ui';
import { useBoolean } from '@Hooks/common';

import styles from './CountNotifier.module.scss';
import { CountNotifierProps } from './CountNotifier.props';

export const CountNotifier = ({
  children,
  count,
  isAlwaysVisible = false,
  hasCount = true,
  className
}: CountNotifierProps) => {
  const isVisible = useBoolean(false);

  const moreThanZero = count && count > 0;

  useEffect(() => {
    isVisible.setValue(moreThanZero || isAlwaysVisible);
  }, [moreThanZero, isAlwaysVisible, isVisible]);

  const getNumber = () => {
    if (hasCount && moreThanZero) {
      return count;
    }

    if (!hasCount || isAlwaysVisible) {
      return undefined;
    }

    return 1;
  };

  return (
    <div className={cn(styles.CountNotifier, className)}>
      <div className={cn(styles.Notifier, { [styles.isShown]: isVisible.value })}>
        <Text.Micro className={styles.Text}>{getNumber()}</Text.Micro>
      </div>

      {children}
    </div>
  );
};
