import cn from 'classnames';

import { FormField, InputText } from '@Components/ui';

import styles from './FormText.module.scss';
import { FormTextProps } from './FormText.props';

export const FormText = ({ options, className, ...form }: FormTextProps) => {
  const classNames = cn(styles.FormText, className);

  return (
    <FormField {...form} className={classNames}>
      <InputText {...options} />
    </FormField>
  );
};
