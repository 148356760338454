import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserActivityForm } from '@Components/form';
import { ButtonCancel, Container } from '@Components/layout';
import { AppRoutes } from '@Navigation/Routes';

export const TodoAddScreen = () => {
  const navigate = useNavigate();

  const handleSuccess = useCallback(
    (_id: string) => {
      navigate(AppRoutes.getTodoUserActivity(_id).to);
    },
    [navigate]
  );

  return (
    <>
      <Container cols={10}>
        <UserActivityForm onSuccess={handleSuccess} />
      </Container>

      <ButtonCancel to={AppRoutes.getTodo().to} />
    </>
  );
};
