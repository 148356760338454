import { Navigate, Route, Routes } from 'react-router-dom';

import { TodoAddScreen, TodoItemScreen, TodoOverviewScreen, TodoUpdateScreen } from '@Screens/Todo';

import { AppRoutes } from './Routes';

export const TodoStack = () => {
  return (
    <Routes>
      <Route path="" element={<TodoOverviewScreen />} />

      <Route path={AppRoutes.getTodoUserActivity('').path} element={<TodoItemScreen />} />

      <Route path={AppRoutes.getTodoUserActivityAdd().path} element={<TodoAddScreen />} />

      <Route path={AppRoutes.getTodoUserActivityUpdate('').path} element={<TodoUpdateScreen />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
