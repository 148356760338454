import { IBaseModel, ListRequest, ListResponse, OneRequest } from '@Constants/interfaces';
import { toQueryString } from '@Utils/StringUtils';

import { BaseService } from '../BaseService/BaseService';

export class ResourceService<T extends IBaseModel> extends BaseService {
  list = async (params: ListRequest = {}) => {
    const response = await this.get<ListResponse<T>>('?' + toQueryString(params));

    return response.data;
  };

  one = async ({ id }: OneRequest) => {
    const response = await this.get<T>(`/${id}`);

    return response.data;
  };
}
