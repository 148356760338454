import { Box, Text } from '@Components/ui';
import { PreprElementContentType } from '@Constants/preprTypes';

import { PreprElementContentProps } from './PreprElementContent.props';

export const PreprElementContent = ({ element }: PreprElementContentProps) => {
  if (element.type === PreprElementContentType.INTRO) {
    return (
      <Box mb={3}>
        <Text.Intro dangerousHtml={element.content} />
      </Box>
    );
  }

  if (element.type === PreprElementContentType.SMALL) {
    return (
      <Box mb={6}>
        <Text.Small dangerousHtml={element.content} />
      </Box>
    );
  }

  return (
    <Box mb={3}>
      <Text.Large dangerousHtml={element.content} />
    </Box>
  );
};
