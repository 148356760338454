import cn from 'classnames';
import AnimateHeight from 'react-animate-height';

import { usePlatform } from '@Hooks/common';

import { SubmenuItem } from '../SubmenuItem/SubmenuItem';
import styles from './Submenu.module.scss';
import { SubmenuProps } from './Submenu.props';

export const Submenu = ({ items, isCollapsed = false, className }: SubmenuProps) => {
  const { easing } = usePlatform();

  const classNames = cn(styles.Submenu, className);

  return (
    <div className={classNames}>
      <AnimateHeight height={isCollapsed ? 0 : 'auto'} duration={500} easing={easing.out}>
        {items.map((item, idx) => {
          return <SubmenuItem key={idx} isCollapsed={isCollapsed} {...item} />;
        })}
      </AnimateHeight>
    </div>
  );
};
