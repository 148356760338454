import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Preferences } from '@Components/container';
import { Container } from '@Components/layout';
import { AppRoutes } from '@Navigation/Routes';
import { Tabs } from '@Screens/Profile/Profile/internal/components/ProfileTabs/ProfileTabs.props';
import { toQueryString } from '@Utils/StringUtils';

export const ProfilePreferenceScreen = () => {
  const navigate = useNavigate();
  const params = {
    active: Tabs.PREFERENCES
  };
  const queryString = toQueryString(params);

  const onCancel = useCallback(() => {
    navigate(AppRoutes.getMyProfile(`?${queryString}`).to);
  }, [navigate, queryString]);

  const onFinish = useCallback(() => {
    navigate(AppRoutes.getMyProfile(`?${queryString}`).to);
  }, [navigate, queryString]);

  return (
    <Container cols={10}>
      <Preferences onFinish={onFinish} onCancel={onCancel} />
    </Container>
  );
};
