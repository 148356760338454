import cn from 'classnames';
import { useCallback, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';

import { Block, ButtonIcon, Icon, Text } from '@Components/ui';
import { useBoolean, usePlatform } from '@Hooks/common';
import { sleep } from '@Utils/TimeoutUtils';

import styles from './BoardBlock.module.scss';
import { BoardBlockProps } from './BoardBlock.props';
import { CLOSING_DURATION, CLOSING_HEIGHT_DELAY } from './internal/constants';

export const BoardBlock = ({
  title,
  children,
  footerLink,
  animateOnOpen,
  onClose,
  motivator,
  className,
  innerClassName,
  'data-tourdotid': dataTourdotId,
  ...props
}: BoardBlockProps) => {
  // Both useBoolean's are required because the delays are different/need to be the opposite
  const { value: isClosed, setTrue: close, setFalse: open } = useBoolean(animateOnOpen);
  const { value: isOpened, setTrue: setOpenenedTrue } = useBoolean(!animateOnOpen);

  const { easing } = usePlatform();

  const handleClose = useCallback(async () => {
    if (onClose) {
      close();

      await sleep(CLOSING_DURATION + CLOSING_HEIGHT_DELAY);

      onClose();
    }
  }, [close, onClose]);

  const handleOpen = useCallback(async () => {
    open();

    await sleep(CLOSING_DURATION);

    setOpenenedTrue();
  }, [open, setOpenenedTrue]);

  useEffect(() => {
    if (animateOnOpen) {
      handleOpen();
    }
  }, [animateOnOpen, handleOpen]);

  const componentClasses = cn(
    styles.BoardBlock,
    {
      [styles.isOpened]: isOpened,
      [styles.isClosed]: isClosed,
      [styles.canClose]: !!onClose
    },
    className
  );

  return (
    <AnimateHeight
      height={isClosed ? 0 : 'auto'}
      duration={CLOSING_DURATION}
      delay={isOpened ? CLOSING_HEIGHT_DELAY : 0}
      easing={easing.out}
    >
      <Block
        className={componentClasses}
        innerClassName={innerClassName}
        footer={motivator}
        {...props}
      >
        <div className={styles.Close} onClick={handleClose}>
          <Icon icon="times" size="md" />
        </div>

        <Text.H3 className={styles.Title} data-tourdotid={dataTourdotId}>
          {title}
        </Text.H3>

        {children}

        {!!footerLink && (
          <div className={styles.Footer}>
            <ButtonIcon
              data-testid="boardBlockClose"
              to={footerLink.to}
              label={footerLink?.label}
              icon={footerLink.icon ?? 'chevron-right'}
              onClick={footerLink.onClick}
            />
          </div>
        )}
      </Block>
    </AnimateHeight>
  );
};
