import { useTranslation } from 'react-i18next';

import { ListHighlightedChallenge } from '@Components/list';
import { Box, Loader, TabGroup, Text } from '@Components/ui';
import { MAX_PENDING_CHALLENGES } from '@Constants/configs';
import { useUserChallengeArchive, useUserChallengeList } from '@Hooks/userChallenge';

import { StartChallenge } from '../StartChallenge/StartChallenge';

export const ChallengeArchive = () => {
  const { t } = useTranslation();
  const activeQuery = useUserChallengeList();
  const archiveQuery = useUserChallengeArchive();

  return (
    <>
      <Box mb={2}>
        <Text.H3>{t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.ARCHIVE_TITLE')}</Text.H3>
      </Box>

      <TabGroup>
        <TabGroup.Child
          label={t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.CURRENT_CHALLENGES', {
            count: activeQuery.data?.total ?? 0,
            total: MAX_PENDING_CHALLENGES
          })}
        >
          {activeQuery.isFetched && activeQuery.data ? (
            <>
              {activeQuery.data.total > 0 && activeQuery.data.results && (
                <Box mb={3}>
                  <ListHighlightedChallenge challenges={activeQuery.data.results} />
                </Box>
              )}

              {activeQuery.data.total < MAX_PENDING_CHALLENGES && <StartChallenge />}
            </>
          ) : (
            <Loader />
          )}
        </TabGroup.Child>

        <TabGroup.Child
          label={t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.COMPLETED_CHALLENGES', {
            count: archiveQuery.data?.total ?? 0
          })}
          isDisabled={archiveQuery.data ? archiveQuery.data.total === 0 : true}
        >
          {archiveQuery.data && archiveQuery.data.total > 0 && archiveQuery.data.results && (
            <Box mb={3}>
              <ListHighlightedChallenge challenges={archiveQuery.data.results} />
            </Box>
          )}

          {activeQuery.isFetched &&
            activeQuery.data &&
            activeQuery.data.total < MAX_PENDING_CHALLENGES && <StartChallenge />}
        </TabGroup.Child>
      </TabGroup>
    </>
  );
};
