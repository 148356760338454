import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@Components/ui';

import styles from './ReadingTime.module.scss';
import { ReadingTimeProps } from './ReadingTime.props';

const MINS_IN_YEAR = 60 * 24 * 365;
const MINS_IN_MONTH = 60 * 24 * 30;
const MINS_IN_WEEKS = 60 * 24 * 7;
const MINS_IN_DAYS = 60 * 24;
const MINS_IN_HOURS = 60;

enum TimeScales {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}

const scales = {
  [TimeScales.MINUTES]: 1,
  [TimeScales.HOURS]: MINS_IN_HOURS,
  [TimeScales.DAYS]: MINS_IN_DAYS,
  [TimeScales.WEEKS]: MINS_IN_WEEKS * 2,
  [TimeScales.MONTHS]: MINS_IN_MONTH,
  [TimeScales.YEARS]: MINS_IN_YEAR
};

export const ReadingTime = ({ minutes, isLong = false, className }: ReadingTimeProps) => {
  const { t } = useTranslation();

  if (!minutes || minutes === 0) return null;

  const getScale = () => {
    switch (true) {
      case minutes >= MINS_IN_YEAR:
        return TimeScales.YEARS;
      case minutes >= MINS_IN_MONTH:
        return TimeScales.MONTHS;
      case minutes >= MINS_IN_WEEKS * 2:
        return TimeScales.WEEKS;
      case minutes >= MINS_IN_DAYS:
        return TimeScales.DAYS;
      case minutes >= MINS_IN_HOURS:
        return TimeScales.HOURS;
      default:
        return TimeScales.MINUTES;
    }
  };

  const getLabel = () => {
    const scale = getScale();

    const time = Math.round(minutes / scales[scale]);
    const scaleLabel = isLong
      ? time === 1
        ? t(`COMPONENTS.READING_TIME.${scale}.LONG.SINGULAR`)
        : t(`COMPONENTS.READING_TIME.${scale}.LONG.PLURAL`)
      : t(`COMPONENTS.READING_TIME.${scale}.SHORT`);

    let label = `${time} ${scaleLabel}`;

    if (isLong) {
      label = `${label} ${t('COMPONENTS.READING_TIME.LABEL')}`;
    }

    return label;
  };

  return (
    <div className={cn(styles.ReadingTime, className)}>
      <Text.Small className={styles.Label}>{getLabel()}</Text.Small>

      {isLong && <Icon icon={['fas', 'clock']} size="lg" className={styles.Icon} />}
    </div>
  );
};
