import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ListProgressTheme } from '@Components/list';
import {
  Box,
  SliderFullPage,
  SliderNavigation,
  useSliderFullPageContext,
  useSliderFullPageItemContext
} from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';

import { SelfTestResultsPositiveSlideProps } from './SelfTestResultsPositiveSlide.props';

export const SelfTestResultsPositiveSlide = ({
  userThemes,
  isOnlySlide
}: SelfTestResultsPositiveSlideProps) => {
  const { total } = useSliderFullPageContext();
  const { index, slideNext } = useSliderFullPageItemContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNext = useCallback(() => {
    if (!isOnlySlide) {
      slideNext();
    } else {
      navigate(AppRoutes.getInsightChallengesAdd().to);
    }
  }, [isOnlySlide, slideNext, navigate]);

  return (
    <SliderFullPage.Item>
      <SliderFullPage.Head
        superTitle={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.NAME')}
        title={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.POSITIVE.TITLE')}
        intro={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.POSITIVE.DESCRIPTION')}
        isLarge
      />

      <SliderFullPage.Block
        title={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.POSITIVE.RESULT_TITLE')}
        isLarge
      >
        {!!userThemes && (
          <Box mb={3}>
            <ListProgressTheme items={userThemes} hasLegend />
          </Box>
        )}

        <SliderNavigation
          current={index + 1}
          total={total}
          onNext={handleNext}
          nextLabel={t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.POSITIVE.PROCEED')}
        />
      </SliderFullPage.Block>
    </SliderFullPage.Item>
  );
};
