import { Container } from '@Components/layout';

import { InsightProgressBody, InsightProgressHead } from './internal/components';

export const InsightProgressScreen = () => {
  return (
    <Container>
      <InsightProgressHead />

      <InsightProgressBody />
    </Container>
  );
};
