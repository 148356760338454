import cn from 'classnames';
import { useMemo } from 'react';

import { Icon, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';

import styles from './Tag.module.scss';
import { TagProps } from './Tag.props';

export const Tag = ({ label, icon, color, isInverted, size = 'md', className }: TagProps) => {
  const platform = usePlatform();

  const colorStyles = useMemo(() => {
    return {
      color: isInverted ? color : platform.color.frame.default,
      borderColor: isInverted ? color : undefined
    };
  }, [isInverted, color, platform]);

  const tagStyles = useMemo(() => {
    return {
      backgroundColor: isInverted ? platform.color.frame.default : color,
      color: isInverted ? color : platform.color.frame.default
    };
  }, [isInverted, color, platform]);

  const classNames = cn([
    styles.Tag,
    styles[size],
    {
      [styles.isInverted]: isInverted,
      [styles.isExtraSmall]: size === 'xs',
      [styles.isSmall]: size === 'sm',
      [styles.isMedium]: size === 'md',
      [styles.isLarge]: size === 'lg'
    },
    className
  ]);

  const getLabel = () => {
    if (size === 'lg') {
      return (
        <Text.Small className={styles.Label} style={colorStyles}>
          {label}
        </Text.Small>
      );
    }

    return (
      <Text.Micro className={styles.Label} style={colorStyles}>
        {label}
      </Text.Micro>
    );
  };

  return (
    <span className={classNames} style={tagStyles}>
      {!!icon && (
        <Icon
          icon={['fas', icon]}
          size={size === 'lg' ? 'lg' : 'sm'}
          className={styles.Icon}
          style={colorStyles}
        />
      )}

      {!!label && getLabel()}
    </span>
  );
};
