import { IBaseModel } from './interfaces';

export enum PreprElementButtonType {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary'
}

export enum PreprElementContentType {
  SMALL = 'Small',
  NORMAL = 'Normal',
  INTRO = 'Intro'
}

export enum PreprElementListType {
  UNORDERED = 'Unordered',
  ORDERED = 'Ordered',
  CHECKBOX = 'Checkbox'
}

export interface IPreprTag {
  body: string;
  slug: string;
}

export interface IPreprResource {
  body: string;
  url: string;
}

export interface IPreprCdnFile {
  url: string;
}

export interface IPreprImage {
  cdnFiles: IPreprCdnFile[];
}

export interface IPreprAudio {
  name: string;
  cdn_files: IPreprCdnFile[];
}

export interface IPreprPublicationAccordion extends IBaseModel {
  title: string;
  content: IPreprContent;
}

export interface IPreprPublicationRoadmap extends IBaseModel {
  title: string;
  complete: string;
  preview: string;
  content: IPreprContent;
}

export interface IPreprElementH3 extends IPreprBaseElement {
  title: string;
  typeName: 'H3';
}

export interface IPreprElementLineBreak extends IPreprBaseElement {
  typeName: 'LineBreak';
}

export interface IPreprElementH4 extends IPreprBaseElement {
  title: string;
  typeName: 'H4';
}
export interface IPreprElementAccordion extends IPreprBaseElement {
  accordion: IPreprPublicationAccordion[];
  typeName: 'Accordion';
}
export interface IPreprElementButton extends IPreprBaseElement {
  button: IPreprResource;
  type: PreprElementButtonType;
  typeName: 'Button';
}

export interface IPreprElementEvernote extends IPreprBaseElement {
  button: IPreprResource;
  typeName: 'Evernote';
}

export interface IPreprElementContent extends IPreprBaseElement {
  content: string;
  type: PreprElementContentType;
  typeName: 'Content';
}

export enum PreprElementListAlignment {
  RIGHT = 'Right',
  LEFT = 'Left'
}

export interface IPreprElementImage extends IPreprBaseElement {
  image: IPreprImage[];
  alignment?: PreprElementListAlignment;
  typeName: 'Image';
}

export interface IPreprElementList extends IPreprBaseElement {
  list: string;
  type: PreprElementListType;
  typeName: 'List';
}

export interface IPreprElementPodcast extends IPreprBaseElement {
  podcast: IPreprAudio[];
  typeName: 'Podcast';
}

export interface IPreprElementRoadmap extends IPreprBaseElement {
  roadmap: IPreprPublicationRoadmap[];
  typeName: 'Roadmap';
}

export interface IPreprElementTip extends IPreprBaseElement {
  title: string;
  description: string;
  button?: IPreprResource;
  typeName: 'Tip';
}

export type IPreprElement =
  | IPreprElementH3
  | IPreprElementH4
  | IPreprElementLineBreak
  | IPreprElementAccordion
  | IPreprElementButton
  | IPreprElementContent
  | IPreprElementEvernote
  | IPreprElementImage
  | IPreprElementList
  | IPreprElementPodcast
  | IPreprElementRoadmap
  | IPreprElementTip;

export type IPreprContent = IPreprElement[];

export interface IPreprBaseElement extends IBaseModel {
  typeName: string;
}
