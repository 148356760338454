import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Anchor, Box, Button, Form, SpacingGroup, Text } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useRewardClaim } from '@Hooks/reward';
import Yup from '@Utils/YupUtils';

import styles from './RewardClaimForm.module.scss';
import { RewardClaimFormProps, RewardClaimFormValues } from './RewardClaimForm.props';

export const RewardClaimForm = ({
  reward,
  onSuccess,
  onCancel,
  className
}: RewardClaimFormProps) => {
  const { t } = useTranslation();
  const { handleError } = useApiError();
  const rewardClaimMutation = useRewardClaim();

  const handleSubmit = useCallback(
    async (values: RewardClaimFormValues) => {
      try {
        await rewardClaimMutation.mutateAsync({
          id: reward.id,
          title: reward.title,
          category: reward.type[0].title,
          ...(reward.physical ? values : undefined)
        });

        toast.success(t('DOMAIN.REWARD.CLAIM.FORM.SUCCESS'));

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, onSuccess, reward, rewardClaimMutation, t]
  );

  const validationSchema = Yup.object().shape({
    address: Yup.string().required(),
    postalcode: Yup.string().required(),
    city: Yup.string().required()
  });

  return (
    <Form.Form<RewardClaimFormValues>
      validationSchema={reward.physical ? validationSchema : undefined}
      onSubmit={handleSubmit}
      className={cn(styles.RewardClaimForm, className)}
    >
      <Box mt={3}>
        {reward.physical && (
          <>
            <Text.Large className={styles.Title}>{t('DOMAIN.REWARD.CLAIM.FORM.TITLE')}</Text.Large>

            <Form.Input.Text
              name="address"
              data-testid="addressInput"
              label={t('DOMAIN.REWARD.CLAIM.FORM.FIELDS.ADDRESS.LABEL')}
            />

            <Form.Input.Text
              name="postalcode"
              data-testid="postalcodeInput"
              label={t('DOMAIN.REWARD.CLAIM.FORM.FIELDS.POSTALCODE.LABEL')}
            />

            <Form.Input.Text
              name="city"
              data-testid="cityInput"
              label={t('DOMAIN.REWARD.CLAIM.FORM.FIELDS.CITY.LABEL')}
            />
          </>
        )}

        <SpacingGroup size="xxl">
          <Button
            icon="arrow-right"
            data-testid="claimButton"
            isLoading={rewardClaimMutation.isLoading}
          >
            {t('DOMAIN.REWARD.CLAIM.FORM.SUBMIT')}
          </Button>

          {onCancel && <Anchor onClick={onCancel}>{t('DOMAIN.REWARD.CLAIM.FORM.CANCEL')}</Anchor>}
        </SpacingGroup>
      </Box>
    </Form.Form>
  );
};
