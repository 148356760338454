import { useTranslation } from 'react-i18next';

import { Author } from '@Components/ui';
import { AppRoutes } from '@Navigation/Routes';
import { getImageSrc } from '@Utils/PreprUtils';

import { AuthorActivityProps } from './AuthorActivity.props';

export const AuthorActivity = ({ activity, className }: AuthorActivityProps) => {
  const { t } = useTranslation();
  const author = activity.author?.[0];

  if (author) {
    return (
      <Author
        image={getImageSrc(author.avatar)}
        name={author.name}
        description={author.function}
        className={className}
        buttonLabel={t('COMPONENTS.AUTHOR_ACTIVITY.BUTTON_LABEL')}
        to={AppRoutes.getSupport().to}
      />
    );
  }

  return null;
};
