import cn from 'classnames';
import { Link } from 'react-router-dom';

import { useHover } from '@Hooks/common';

import { ButtonIcon, Text } from '..';
import styles from './Cta.module.scss';
import { CtaProps } from './Cta.props';

export const Cta = ({
  title,
  subtitle,
  to,
  collapsedIcon = 'chevron-right',
  isCollapsed = false,
  className
}: CtaProps) => {
  const { isHovering, handlers } = useHover();

  return (
    <Link
      to={to}
      className={cn(styles.Cta, { [styles.isCollapsed]: isCollapsed }, className)}
      {...handlers}
    >
      {isCollapsed && <ButtonIcon icon={collapsedIcon} className={styles.CollapsedIcon} />}

      <div className={styles.Content}>
        <Text.H5>{title}</Text.H5>

        <Text.Small>{subtitle}</Text.Small>
      </div>

      <ButtonIcon isHovering={isHovering} icon="chevron-right" />
    </Link>
  );
};
