import cn from 'classnames';

import { Anchor } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { AppRoutes } from '@Navigation/Routes';

import styles from './HeaderFullscreen.module.scss';
import { HeaderFullscreenProps } from './HeaderFullscreen.props';

export const HeaderFullscreen = ({ className }: HeaderFullscreenProps) => {
  const { assets } = usePlatform();

  return (
    <div className={cn(styles.HeaderFullscreen, className)}>
      <Anchor to={AppRoutes.getDashboard().to} className={styles.Logo}>
        <img src={assets.logo.default} />
      </Anchor>
    </div>
  );
};
