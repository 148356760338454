import cn from 'classnames';
import { forwardRef } from 'react';

import { useScrolling } from '@Hooks/common';

import styles from './Header.module.scss';
import { HeaderProps } from './Header.props';
import { HeaderDefault, HeaderFullscreen } from './internal/components';

export const Header = forwardRef(
  ({ onMenuToggleClick, isFullscreen = false, className }: HeaderProps, ref: any) => {
    const { isScrolling } = useScrolling();

    const componentClasses = cn(
      styles.Header,
      {
        [styles.isScrolling]: isScrolling,
        [styles.isFullscreen]: isFullscreen
      },
      className
    );

    return (
      <div ref={ref} className={componentClasses} data-testid="header">
        <HeaderDefault onMenuToggleClick={onMenuToggleClick} className={styles.HeaderDefault} />

        <HeaderFullscreen className={styles.HeaderFullscreen} />
      </div>
    );
  }
);
