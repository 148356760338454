import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ProfileUpdateEmailPreferencesForm } from '@Components/form';
import { Box, Text } from '@Components/ui';

import { ProfilePreferencesSummary } from './internal/components';

export const ProfileEditPreferencesDataTab = () => {
  const { t } = useTranslation();

  const handleUpdateEmailPreferencesSuccess = useCallback(() => {
    toast.success(t('DOMAIN.PROFILE.UPDATE_EMAIL_REFERENCES.SUCCESS'));
  }, [t]);

  return (
    <div>
      <Box mb={2}>
        <Text.H4>{t('DOMAIN.PROFILE.PREFERENCES.LABEL')}</Text.H4>
      </Box>

      <ProfilePreferencesSummary />

      <Box mt={8} mb={2}>
        <Text.H4>{t('DOMAIN.PROFILE.PREFERENCES.MAIL.LABEL')}</Text.H4>
        <Text.Large>{t('DOMAIN.PROFILE.PREFERENCES.MAIL.DESCRIPTION')}</Text.Large>
      </Box>

      <ProfileUpdateEmailPreferencesForm onSuccess={handleUpdateEmailPreferencesSuccess} />
    </div>
  );
};
