import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { useApiError } from '@Hooks/common';
import { useUser, useUserUpdateMe } from '@Hooks/user';
import { ProfileReminder } from '@Services/User';

import {
  ProfileUpdateEmailPreferencesFormProps,
  ProfileUpdateEmailPreferencesFormValues
} from './ProfileUpdateEmailPreferencesForm.props';

export const ProfileUpdateEmailPreferencesForm = ({
  onSuccess
}: ProfileUpdateEmailPreferencesFormProps) => {
  const { t } = useTranslation();
  const user = useUser();
  const { handleError } = useApiError();
  const userUpdateMeMutation = useUserUpdateMe();

  const handleSubmit = useCallback(
    async (values: ProfileUpdateEmailPreferencesFormValues) => {
      try {
        await userUpdateMeMutation.mutateAsync({
          ...user,
          receiveReminders: values.reminders.includes(ProfileReminder.REMINDERS)
        });

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [userUpdateMeMutation, user, onSuccess, handleError]
  );

  const initialValues = {
    reminders: [ProfileReminder.FUNCTIONAL, user.receiveReminders ? ProfileReminder.REMINDERS : '']
  };

  const reminderOptions = [
    {
      label: t('DOMAIN.PROFILE.UPDATE_EMAIL_REFERENCES.FUNCTIONAL.TITLE'),
      description: t('DOMAIN.PROFILE.UPDATE_EMAIL_REFERENCES.FUNCTIONAL.DESCRIPTION'),
      disabled: true,
      value: ProfileReminder.FUNCTIONAL
    },

    {
      label: t('DOMAIN.PROFILE.UPDATE_EMAIL_REFERENCES.REMINDERS.TITLE'),
      description: t('DOMAIN.PROFILE.UPDATE_EMAIL_REFERENCES.REMINDERS.DESCRIPTION'),
      value: ProfileReminder.REMINDERS
    }
  ];

  return (
    <Form.Form<ProfileUpdateEmailPreferencesFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Input.Toggle
        name="reminders"
        data-testid="remindersInput"
        options={{
          options: reminderOptions
        }}
      />

      <Button type="submit" icon="arrow-right" data-testid="submitButton">
        {t('DOMAIN.PROFILE.UPDATE_EMAIL_REFERENCES.CONFIRM')}
      </Button>
    </Form.Form>
  );
};
