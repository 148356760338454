import { useCallback } from 'react';

import { useBoolean } from '.';

export const useExplosion = (canExplode = true, afterExplosion?: () => void) => {
  const { value: shouldExplode, setValue: setShouldExplode } = useBoolean(false);
  const { value: hasExploded, setValue: setHasExploded } = useBoolean(false);

  const explode = useCallback(() => {
    if (canExplode) {
      setShouldExplode(true);

      setTimeout(() => {
        setHasExploded(true);
      }, 1000);

      if (afterExplosion) {
        setTimeout(() => {
          afterExplosion();
        }, 1500);
      }
    }
  }, [canExplode, setShouldExplode, setHasExploded, afterExplosion]);

  const setValues = useCallback(
    (_shouldExplode = false, _hasExploded = false) => {
      setShouldExplode(_shouldExplode);
      setHasExploded(_hasExploded);
    },
    [setShouldExplode, setHasExploded]
  );

  const reset = useCallback(() => {
    setShouldExplode(false);
    setHasExploded(false);
  }, [setShouldExplode, setHasExploded]);

  return { shouldExplode, hasExploded, explode, setValues, reset };
};
