import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import registerThumbnail from '@Assets/images/register-thumbnail.jpeg';
import { Footer } from '@Components/layout';
import {
  Accordion,
  Block,
  BlockCta,
  Box,
  Button,
  Highlight,
  IAccordionItem,
  Image,
  List,
  Text
} from '@Components/ui';
import { usePersistSearchParam } from '@Hooks/common';
import { scrollToTop } from '@Utils/WindowUtils';

import styles from './OnboardingVerifyLicenseCodePage.module.scss';

export const OnboardingVerifyLicenseCodePage = () => {
  const { t } = useTranslation();
  const { getPersistedSearchParam } = usePersistSearchParam('token');
  const token = getPersistedSearchParam();

  const handleSignup = useCallback(() => {
    scrollToTop();
  }, []);

  return (
    <div className={styles.OnboardingVerifyLicenseCodePage}>
      <div className={styles.Steps}>
        <Text.H1>
          {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.STEPS.PRE_TITLE')}{' '}
          <Highlight>
            {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.STEPS.HIGHLIGHT_TITLE')}
          </Highlight>{' '}
          {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.STEPS.POST_TITLE')}
        </Text.H1>

        <Box mt={4} mb={3}>
          <List.Stepped>
            {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.STEPS.LIST', {
              returnObjects: true
            }).map((item, idx) => {
              return (
                <Text.Large key={idx}>
                  <strong>{item.TITLE}</strong> {item.DESCRIPTION}
                </Text.Large>
              );
            })}
          </List.Stepped>
        </Box>

        <Button icon="long-arrow-right" onClick={handleSignup}>
          {t('COMMON.NAVIGATION.REGISTER.LABEL')}
        </Button>
      </div>

      <div className={styles.Image}>
        <Image src={registerThumbnail} ratio="2:1" isElevated />
      </div>

      <div className={styles.Faq}>
        <Text.H1 className={styles.Title}>
          {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.FAQ.TITLE')}
        </Text.H1>

        <Block>
          <Text.H3 className={styles.AccordionTitle}>
            {t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.FAQ.ACCORDION_TITLE')}
          </Text.H3>

          <Accordion
            items={t(
              token
                ? 'DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.FAQ.ACCORDION_TOKEN'
                : 'DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.FAQ.ACCORDION',
              {
                returnObjects: true
              }
            ).map((item, idx) => {
              return {
                id: String(idx),
                title: item.TITLE,
                body: <Text.Large>{item.DESCRIPTION}</Text.Large>
              } as IAccordionItem;
            })}
          />
        </Block>
      </div>

      <div className={styles.Cta}>
        <BlockCta>
          <Text.H2>
            {token
              ? t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.CTA.TITLE_TOKEN')
              : t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.CTA.TITLE')}
          </Text.H2>

          <Box my={3}>
            <Text.Large>
              {token
                ? t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.CTA.DESCRIPTION_TOKEN')
                : t('DOMAIN.ONBOARDING.REGISTRATION.VERIFY_LICENSE_CODE.PAGE.CTA.DESCRIPTION')}
            </Text.Large>
          </Box>

          <Button icon="long-arrow-right" onClick={handleSignup}>
            {t('COMMON.NAVIGATION.REGISTER.LABEL')}
          </Button>
        </BlockCta>
      </div>

      <Footer className={styles.Footer} />
    </div>
  );
};
