import { useTranslation } from 'react-i18next';

import { Box, Text } from '@Components/ui';
import { getAudioSrc } from '@Utils/PreprUtils';

import styles from './PreprElementPodcast.module.scss';
import { PreprElementPodcastProps } from './PreprElementPodcast.props';

export const PreprElementPodcast = ({ element }: PreprElementPodcastProps) => {
  const { podcast } = element;

  const { t } = useTranslation();

  return (
    <Box mb={3} className={styles.PreprElementPodcast}>
      <Text.H4 className={styles.Title}>
        {t('DOMAIN.ACTIVITY.PODCAST.TITLE', { name: podcast?.[0].name })}
      </Text.H4>

      <audio src={getAudioSrc(podcast)} controls />
    </Box>
  );
};
