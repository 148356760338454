import cn from 'classnames';
import { forwardRef } from 'react';

import styles from './InputText.module.scss';
import { InputTextProps } from './InputText.props';

export const InputText = forwardRef(
  ({ className, isError, isValid, showValid, ...props }: InputTextProps, ref?: any) => {
    const classNames = cn(styles.InputText, className, {
      [styles.isError]: isError,
      [styles.isValid]: isValid && !isError && showValid
    });

    return <input type="text" className={classNames} {...props} ref={ref} enterKeyHint="send" />;
  }
);
