import cn from 'classnames';
import { useRef } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

import { Text } from '@Components/ui';

import styles from './SubmenuItem.module.scss';
import { SubmenuItemProps } from './SubmenuItem.props';

export const SubmenuItem = ({ label, to = '', tourdot, isCollapsed = false }: SubmenuItemProps) => {
  const resolved = useResolvedPath(to);
  const isActive = useMatch({ path: resolved.pathname, end: false });
  const ref = useRef<HTMLAnchorElement | null>(null);

  const componentClasses = cn(styles.SubmenuItem, {
    [styles.isActive]: isActive,
    [styles.isCollapsed]: isCollapsed
  });

  return (
    <div className={styles.SubmenuItemWrapper}>
      <Link className={componentClasses} to={to} ref={ref}>
        <Text.H5 className={styles.Label}>{label}</Text.H5>
        <div data-tourdotid={tourdot} />
      </Link>
    </div>
  );
};
