import { useTranslation } from 'react-i18next';

import { ListProgressTheme } from '@Components/list';
import { Box, TabGroup, Text } from '@Components/ui';
import { DomainScore as DomainScoreType } from '@Services/User';
import { IUserTheme } from '@Services/UserTheme';

import { DomainScore } from './internal/components';
import { ReportDomainsProps } from './ReportDomains.props';

export const ReportDomains = ({
  domainScores,
  themes,
  onTabChange,
  activeTabIdx
}: ReportDomainsProps) => {
  const { t } = useTranslation();

  const hasThemes = themes && themes.length > 0;

  const getThemesForDomain = (_themes: IUserTheme[], domainScore: DomainScoreType) => {
    return _themes.filter((theme) => theme.theme.domain.id === domainScore.domain.id);
  };

  return (
    <TabGroup onChange={onTabChange} activeIdx={activeTabIdx}>
      <TabGroup.Child label={t('DOMAIN.REPORT.DOMAINS.ALL_THEMES.LABEL')}>
        <Box mb={hasThemes ? 3 : 0}>
          <Text.Large>{t('DOMAIN.REPORT.DOMAINS.ALL_THEMES.DESCRIPTION')}</Text.Large>
        </Box>

        {hasThemes && <ListProgressTheme hasLegend items={themes} />}
      </TabGroup.Child>

      {domainScores.map((domainScore) => {
        return (
          <TabGroup.Child
            key={domainScore.domain.id}
            label={domainScore.domain.title}
            isDisabled={!domainScore.result}
          >
            <DomainScore
              domainScore={domainScore}
              themes={getThemesForDomain(themes, domainScore)}
            />
          </TabGroup.Child>
        );
      })}
    </TabGroup>
  );
};
