import { getMonth } from 'date-fns';
import { getYear } from 'date-fns/esm';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loader, Text, TimelineSmall } from '@Components/ui';
import { useInsightTimeline } from '@Hooks/insight';
import { AppRoutes } from '@Navigation/Routes';
import { TimelineItemType } from '@Services/Insight';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import { DashboardProgressProps } from './DashboardProgress.props';

export const DashboardProgress = ({ className }: DashboardProgressProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: timeline,
    isSuccess: isTimelineSuccess,
    isLoading: isTimelineLoading,
    isError: isTimelineError
  } = useInsightTimeline({
    perPage: 3,
    month: getMonth(new Date()) + 1,
    year: getYear(new Date()),
    excludeTypes: [TimelineItemType.MILESTONE, TimelineItemType.REWARD],
    excludeIncomplete: true
  });

  const handleNavigate = useCallback(() => {
    navigate(AppRoutes.getInsightProgress().to);
  }, [navigate]);

  const footerLink = {
    label: t('DOMAIN.DASHBOARD.BLOCKS.PROGRESS.READ_MORE'),
    onClick: handleNavigate
  };

  return (
    <>
      <DashboardBlock
        widgetKey="PROGRESS"
        footerLink={footerLink}
        className={className}
        data-tourdotid="PROGRESS"
      >
        {timeline && isTimelineSuccess && (
          <>
            {Object.keys(timeline).length > 0 ? (
              <TimelineSmall timeline={timeline} />
            ) : (
              <Text.Normal>{t('DOMAIN.DASHBOARD.BLOCKS.PROGRESS.NONE')}</Text.Normal>
            )}
          </>
        )}

        {isTimelineError && <Text.Error>{t('DOMAIN.DASHBOARD.BLOCKS.PROGRESS.ERROR')}</Text.Error>}

        {isTimelineLoading && <Loader>{t('DOMAIN.DASHBOARD.BLOCKS.PROGRESS.LOADING')}</Loader>}
      </DashboardBlock>
    </>
  );
};
