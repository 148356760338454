import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Highlight, Image, SliderFullPage } from '@Components/ui';
import { useUser } from '@Hooks/user';
import { OnboardingRoutes } from '@Navigation/Routes';

import styles from './OnboardingPreferenceMotivator.module.scss';

export const OnboardingPreferenceMotivator = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { motivator } = useUser();

  const handleFinish = useCallback(() => {
    navigate(OnboardingRoutes.getOnboarding().to);
  }, [navigate]);

  return (
    <SliderFullPage className={styles.OnboardingPreferenceMotivator}>
      <SliderFullPage.Item>
        <SliderFullPage.Head
          title={
            <>
              {t('DOMAIN.PREFERENCES.MOTIVATOR.HEAD.TITLE')}{' '}
              <Highlight>{motivator?.name}</Highlight>
            </>
          }
          intro={motivator?.intro}
          superTitle={t('DOMAIN.PREFERENCES.MOTIVATOR.HEAD.SUPER_TITLE')}
        />

        <SliderFullPage.Block>
          <Image ratio="16:9" src={motivator?.thumbnail} className={styles.Image} />

          <Box mt={5} className={styles.Footer}>
            <Button onClick={handleFinish} icon="arrow-right" data-testid="motivatorNext">
              {t('DOMAIN.PREFERENCES.MOTIVATOR.BODY.FINISH_LABEL')}
            </Button>
          </Box>
        </SliderFullPage.Block>
      </SliderFullPage.Item>
    </SliderFullPage>
  );
};
