import { IActivityType } from '@Services/Activity';

export enum TimelineItemType {
  ACTIVITY = 'ACTIVITY',
  CUSTOM_ACTIVITY = 'CUSTOM_ACTIVITY',
  CHALLENGE = 'CHALLENGE',
  REWARD = 'REWARD',
  MILESTONE = 'MILESTONE'
}

export interface ITimelineItem {
  id: string;
  preprId: string;
  slug: string | null;
  key: string | null;
  type: TimelineItemType;
  activityType: IActivityType[];
  title: string;
  excerpt: string;
  duration: string | null;
  points: number | null;
  thumbnail: string;
  createdAt: string;
  completedAt: string | null;
}

export interface ITimeline {
  [key: string]: ITimelineItem[];
}

export interface IInsightOverviewItem {
  value: number;
  max: number;
  delta: number;
}

export interface IInsightOverview {
  challenges: IInsightOverviewItem;
  activities: IInsightOverviewItem;
  points: IInsightOverviewItem;
  milestones: IInsightOverviewItem;
}

export interface TimelineRequest {
  month?: number;
  year?: number;
  perPage?: number;
  excludeTypes?: TimelineItemType[];
  excludeIncomplete?: boolean;
}

export interface InsightOverviewRequest {
  month?: number;
  year?: number;
}

export type TimelineResponse = ITimeline;

export type InsightOverviewResponse = IInsightOverview;
