import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Anchor,
  Box,
  EditableAvatar,
  ProgressBar,
  SpacingGroup,
  TagText,
  Text
} from '@Components/ui';
import { MAX_PERCENT } from '@Constants/configs';
import { useBoolean, usePlatform } from '@Hooks/common';
import { useUserProfileProgress } from '@Hooks/user';
import { AppRoutes } from '@Navigation/Routes';
import { getImageUrl } from '@Utils/ImageUtils';

import { ChangeNameModal, DeleteAvatarModal } from './internal/components';
import styles from './ProfileInfo.module.scss';
import { ProfileInfoProps } from './ProfileInfo.props';

export const ProfileInfo = ({ profileInfo }: ProfileInfoProps) => {
  const {
    value: isChangeNameModalOpen,
    setTrue: setChangeNameModalOpen,
    setFalse: setChangeNameModalClosed
  } = useBoolean(false);

  const {
    value: isDeleteAvatarModalOpen,
    setTrue: setDeleteAvatarModalOpen,
    setFalse: setDeleteAvatarModalClosed
  } = useBoolean(false);

  const { t } = useTranslation();
  const { color } = usePlatform();
  const navigate = useNavigate();

  const profilePercent = useUserProfileProgress();

  const handleAvatarClick = useCallback(async () => {
    if (profileInfo.avatar) {
      setDeleteAvatarModalOpen();
    } else {
      navigate(AppRoutes.getMyProfileEditProfilePicture().to);
    }
  }, [navigate, profileInfo.avatar, setDeleteAvatarModalOpen]);

  return (
    <div className={styles.ProfileInfo}>
      <Box mr={4}>
        <EditableAvatar
          tooltipText={t('DOMAIN.PROFILE.CHANGE_AVATAR.LABEL')}
          imageUri={profileInfo.avatar && getImageUrl(profileInfo.avatar)}
          onClick={handleAvatarClick}
        />
      </Box>

      <div className={styles.Information}>
        <SpacingGroup size="lg">
          <Text.H4>{`${profileInfo.firstName} ${profileInfo.lastName}`}</Text.H4>

          <Anchor onClick={setChangeNameModalOpen}>{t('DOMAIN.PROFILE.CHANGE_NAME.LABEL')}</Anchor>
        </SpacingGroup>

        <Box mt={0.5}>
          {profilePercent === MAX_PERCENT ? (
            <TagText
              label={t('DOMAIN.PROFILE.COMPLETED')}
              tagProps={{ color: color.success.default, icon: 'check', size: 'xs' }}
            />
          ) : (
            <ProgressBar
              width={120}
              total={MAX_PERCENT}
              current={profilePercent}
              color={color.success.default}
            />
          )}
        </Box>

        <ChangeNameModal
          initialValues={profileInfo}
          isOpen={isChangeNameModalOpen}
          onClose={setChangeNameModalClosed}
        />

        <DeleteAvatarModal isOpen={isDeleteAvatarModalOpen} onClose={setDeleteAvatarModalClosed} />
      </div>
    </div>
  );
};
