import { MouseEvent, useCallback } from 'react';

import { ButtonIcon, IconCircle, ProgressRow, Text } from '@Components/ui';
import { Result } from '@Constants/interfaces';
import { useBoolean, usePlatform } from '@Hooks/common';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { useResultColorMap } from '@Hooks/userTheme';
import { trackMatomoOtherNegativeThemeResult } from '@Utils/MatomoUtils';
import { getPercentageForResult } from '@Utils/ResultUtils';

import { ProgressThemeModal } from './internal/components';
import { getChallengeInfoText } from './internal/utils';
import styles from './ProgressTheme.module.scss';
import { ProgressThemeProps } from './ProgressTheme.props';

export const ProgressTheme = ({
  onClick,
  theme,
  className,
  isChecked,
  isSmall = false
}: ProgressThemeProps) => {
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);
  const { getColorForResult } = useResultColorMap();
  const { color } = usePlatform();
  const trackEvent = useMatomoTrackEvent();

  const challengeInfoText = getChallengeInfoText(theme);
  const isDisabled = theme.themeResult === Result.NONE || theme.themeResult === null;

  const handleRowClick = useCallback(
    (id: string) => () => {
      if (onClick) {
        onClick(id);
      }
    },
    [onClick]
  );

  const handleInfoClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      trackMatomoOtherNegativeThemeResult(trackEvent, theme);

      setModalOpen();
    },
    [setModalOpen, theme, trackEvent]
  );

  return (
    <>
      {!!theme.theme.description && (
        <ProgressThemeModal theme={theme} isOpen={isModalOpen} onClose={setModalClosed} />
      )}

      <ProgressRow
        total={100}
        current={getPercentageForResult(theme.themeResult)}
        color={getColorForResult(theme.themeResult)}
        label={theme.theme.title}
        onClick={onClick && handleRowClick(theme.theme.id)}
        isDisabled={isDisabled}
        isChecked={isChecked}
        isSmall={isSmall}
        className={className}
      >
        {!!theme.theme.description && <ButtonIcon icon="info" onClick={handleInfoClick} />}

        {theme.recommended && <IconCircle icon="flag" color={color.primary.default} />}

        {!!challengeInfoText && !isSmall && (
          <Text.Micro className={styles.InfoText}>{challengeInfoText}</Text.Micro>
        )}
      </ProgressRow>
    </>
  );
};
