import cn from 'classnames';

import styles from './Medium.module.scss';
import { MediumProps } from './Medium.props';

export const Medium = ({ className, children, dangerousHtml, color, ...props }: MediumProps) => {
  if (!children && !dangerousHtml) return null;

  const classNames = cn(styles.Medium, className);

  const inlineStyle = {
    color
  };

  return (
    <p
      className={classNames}
      dangerouslySetInnerHTML={!!dangerousHtml ? { __html: dangerousHtml } : undefined}
      style={inlineStyle}
      {...props}
    >
      {children}
    </p>
  );
};
