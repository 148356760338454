import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Celebration } from '@Components/container';
import { Modal } from '@Components/layout';
import { Anchor, Box, Button, SpacingGroup, Text } from '@Components/ui';
import { useChallengeOne } from '@Hooks/challenge';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { AppRoutes } from '@Navigation/Routes';
import { trackMatomoChallengeFinish } from '@Utils/MatomoUtils';

import { ChallengeFinishedModalProps } from './ChallengeFinishedModal.props';

export const ChallengeFinishedModal = ({ slug, isOpen, onClose }: ChallengeFinishedModalProps) => {
  const { data: challenge } = useChallengeOne({ id: slug ?? '' });
  const trackEvent = useMatomoTrackEvent();
  const { t } = useTranslation();

  useEffect(() => {
    trackMatomoChallengeFinish(trackEvent, challenge?.title || '', challenge?.theme.title || '');
  }, [challenge?.title, challenge?.theme, trackEvent]);

  return (
    <Modal
      title={t('DOMAIN.CHALLENGE.FINISH.TITLE', { title: challenge?.title })}
      head={<Celebration shouldExplode={isOpen} />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Text.Large>{t('DOMAIN.CHALLENGE.FINISH.DESCRIPTION')}</Text.Large>

      <Box mt={3}>
        <SpacingGroup size="xxl">
          <Button to={AppRoutes.getInsightChallenge(slug ?? '').to} color="dark" icon="arrow-right">
            {t('DOMAIN.CHALLENGE.FINISH.GO_TO_CHALLENGE')}
          </Button>

          <Anchor to={AppRoutes.getInsightChallengesAdd().to}>
            {t('DOMAIN.CHALLENGE.FINISH.CHOOSE_NEW')}
          </Anchor>
        </SpacingGroup>
      </Box>
    </Modal>
  );
};
