import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@Components/layout';
import { InputField, InputText } from '@Components/ui';
import { useBoolean } from '@Hooks/common';
import { UserVerifyUpdatePasswordRequest } from '@Services/User';

import {
  UpdatePasswordRequestSmsStep,
  UpdatePasswordUpdateForm,
  UpdatePasswordVerifyTwoFactorStep
} from './internal/components';
import { ProfileUpdatePasswordFormProps } from './ProfileUpdatePasswordForm.props';

export const ProfileUpdatePasswordForm = ({ phoneNumber }: ProfileUpdatePasswordFormProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);
  const [twoFactor, setTwoFactor] = useState({ smsToken: '', token: '' });

  const handleCancel = useCallback(() => {
    setModalClosed();
    setCurrentStep(0);
  }, [setModalClosed]);

  const handleNextStep = useCallback(() => {
    setCurrentStep((oldStep) => oldStep + 1);
  }, []);

  const handleFinish = useCallback(() => {
    handleCancel();
  }, [handleCancel]);

  const handleVerifySmsSucces = useCallback(
    (values: UserVerifyUpdatePasswordRequest) => {
      setTwoFactor(values);

      handleNextStep();
    },

    [handleNextStep]
  );

  const stepComponents = [
    <UpdatePasswordRequestSmsStep
      phoneNumber={phoneNumber}
      onSuccess={handleNextStep}
      onCancel={handleCancel}
    />,

    <UpdatePasswordVerifyTwoFactorStep
      phoneNumber={phoneNumber}
      onSuccess={handleVerifySmsSucces}
    />,

    <UpdatePasswordUpdateForm
      smsToken={twoFactor.smsToken}
      token={twoFactor.token}
      onSuccess={handleFinish}
    />
  ];

  const currentStepComponent = stepComponents[currentStep];

  return (
    <>
      <InputField
        name="password"
        data-testid="changePasswordInput"
        onEditClick={setModalOpen}
        label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.PASSWORD.LABEL')}
      >
        <InputText value={'********'} disabled />
      </InputField>

      <Modal
        isOpen={isModalOpen}
        title={t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PASSWORD.TITLE')}
        onClose={handleCancel}
      >
        {currentStepComponent}
      </Modal>
    </>
  );
};
