import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ChallengeShareForm } from '@Components/form/ChallengeShareForm/ChallengeShareForm';
import { Modal } from '@Components/layout';
import { Anchor, Box, Button, ButtonIcon, SpacingGroup, Text } from '@Components/ui';
import { useBoolean, usePlatform } from '@Hooks/common';
import { useUserChallenge } from '@Hooks/userChallenge';

export const ChallengeItemShareButton = () => {
  const { slug } = useParams<{ slug: string }>();
  const { value: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false);

  const { t } = useTranslation();
  const { color } = usePlatform();
  const { data: challenge } = useUserChallenge({ id: slug ?? '' });

  const handleSuccess = useCallback(() => {
    toast.success(t('DOMAIN.CHALLENGE.SHARE.SUCCESS'));

    closeModal();
  }, [closeModal, t]);

  return (
    <>
      <ButtonIcon
        icon="envelope"
        label={t('DOMAIN.CHALLENGE.SHARE.TITLE')}
        iconBackgroundColor="transparent"
        iconColor={color.font.default}
        onClick={openModal}
        data-testid="ChallengeItemShareOpenButton"
      />

      <Modal title={t('DOMAIN.CHALLENGE.SHARE.TITLE')} isOpen={isModalOpen} onClose={closeModal}>
        <Text.Large>{t('DOMAIN.CHALLENGE.SHARE.DESCRIPTION')}</Text.Large>

        <Box mt={2}>
          <ChallengeShareForm challengeId={challenge?.id ?? ''} onSuccess={handleSuccess}>
            <Box mt={3}>
              <SpacingGroup size="xxl">
                <Button type="submit" color="dark" data-testid="shareButton">
                  {t('DOMAIN.CHALLENGE.SHARE.SEND')}
                </Button>

                <Anchor onClick={closeModal}>{t('COMMON.GLOBALS.CANCEL')}</Anchor>
              </SpacingGroup>
            </Box>
          </ChallengeShareForm>
        </Box>
      </Modal>
    </>
  );
};
