import { useCallback, useEffect, useState } from 'react';

export const useTimeout = (callback: () => void, ms: number) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      callback();
    }, ms);
    return () => clearTimeout(timeout);
  }, [callback, ms]);
};

export const useTimeouts = () => {
  const [timeouts, setTimeouts] = useState<NodeJS.Timeout[]>([]);

  useEffect(() => {
    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  }, [timeouts]);

  const addTimeout = useCallback((callback: () => void, ms: number) => {
    const timeout = setTimeout(() => {
      callback();
    }, ms);

    setTimeouts((current) => [...current, timeout]);
  }, []);

  return {
    addTimeout
  };
};
