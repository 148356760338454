import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { SMS_CODE_LENGTH } from '@Constants/configs';
import { useApiError } from '@Hooks/common';
import { useUserVerifyEmail } from '@Hooks/user';
import Yup from '@Utils/YupUtils';

import {
  UpdateEmailVerifyTwoFactorFormProps,
  UpdateEmailVerifyTwoFactorFormValues
} from './UpdateEmailVerifyTwoFactorForm.props';

export const UpdateEmailVerifyTwoFactorForm = ({
  onSuccess,
  smsToken
}: UpdateEmailVerifyTwoFactorFormProps) => {
  const { t } = useTranslation();
  const { error, handleError } = useApiError();

  const userVerifyEmailMutation = useUserVerifyEmail();

  const handleSubmit = useCallback(
    async (values: UpdateEmailVerifyTwoFactorFormValues) => {
      try {
        const params = {
          smsToken,
          ...values
        };

        await userVerifyEmailMutation.mutateAsync(params);

        onSuccess(params);
      } catch (e) {
        handleError(e, 'EMAIL');
      }
    },
    [userVerifyEmailMutation, smsToken, onSuccess, handleError]
  );

  const validationSchema = Yup.object().shape({
    token: Yup.string().length(SMS_CODE_LENGTH).required()
  });

  return (
    <Form.Form<UpdateEmailVerifyTwoFactorFormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form.Input.Code
        name="token"
        data-testid="tokenInput"
        label={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.TOKEN.LABEL')}
        description={t('DOMAIN.PROFILE.LOGIN_DATA.FORM.FIELDS.TOKEN.DESCRIPTION')}
        options={{ type: 'number', pattern: '[0-9]*', inputMode: 'numeric' }}
      />

      <Form.Layout.Error error={error} />

      <Button type="submit" color="dark" icon="arrow-right" data-testid="submitButton">
        {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_EMAIL.VERIFY_SMS.SUBMIT_LABEL')}
      </Button>
    </Form.Form>
  );
};
