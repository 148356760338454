import { ButtonCancel } from '@Components/layout';
import { SliderFullPage } from '@Components/ui';

import {
  PreferencesActivitySubjectSlide,
  PreferencesActivityTypeSlide,
  PreferencesEducationSlide,
  PreferencesGgcSlide,
  PreferencesIntroSlide,
  PreferencesProfessionSlide,
  PreferencesSupportTypesSlide
} from './internal/components';
import { PreferencesProps } from './Preferences.props';

export const Preferences = ({ onFinish, onCancel, className }: PreferencesProps) => {
  return (
    <>
      {!!onCancel && <ButtonCancel onClick={onCancel} />}

      <SliderFullPage className={className}>
        <PreferencesIntroSlide />

        <PreferencesGgcSlide />

        <PreferencesActivitySubjectSlide />

        <PreferencesActivityTypeSlide />

        <PreferencesSupportTypesSlide />

        <PreferencesEducationSlide />

        <PreferencesProfessionSlide onSuccess={onFinish} />
      </SliderFullPage>
    </>
  );
};
