import { OneRequest } from '@Constants/interfaces';
import { BaseService, dePortalenService } from '@Services/common';

import { IContentPage } from './ContentService.props';

export class ContentService extends BaseService {
  getPage = async ({ id }: OneRequest) => {
    const response = await this.get<IContentPage>(`/${id}`);

    return response.data;
  };
}

export const contentService = new ContentService(dePortalenService.getAxiosInstance(), '/content');
