import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ListActivityItem } from '@Components/list';
import { markupToHtml } from '@Utils/StringUtils';

import { TimelineChallengeActionTitle } from './internal/components';
import { getTimelineActivities } from './internal/utils';
import styles from './TimelineChallenge.module.scss';
import { TimelineChallengeProps } from './TimelineChallenge.props';

export const TimelineChallenge = ({ challenge, className }: TimelineChallengeProps) => {
  const { t } = useTranslation();

  const activitiesByMonth = getTimelineActivities(challenge.activities);

  return (
    <div className={cn(styles.TimelineChallenge, className)}>
      {Object.entries(activitiesByMonth).map(([month, activities]) => {
        return (
          <div key={month} className={styles.Month}>
            <TimelineChallengeActionTitle
              title={markupToHtml(t('COMPONENTS.TIMELINE_CHALLENGE.YOU_HAVE_FINISHED'))}
              date={new Date(month)}
              className={styles.Title}
              isMonthBased
            />

            <ListActivityItem activities={activities} />
          </div>
        );
      })}

      <TimelineChallengeActionTitle
        title={markupToHtml(
          t('COMPONENTS.TIMELINE_CHALLENGE.YOU_HAVE_STARTED_WITH', { name: challenge.title })
        )}
        date={new Date(challenge.startedAt)}
      />
    </div>
  );
};
