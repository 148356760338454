import { useCallback } from 'react';

import { useBoolean } from './useBoolean';

export const useHover = () => {
  const {
    setTrue: setIsHovering,
    setFalse: setIsNotHovering,
    value: isHovering
  } = useBoolean(false);

  const mouseEnterHandler = useCallback(() => {
    setIsHovering();
  }, [setIsHovering]);

  const mouseLeaveHandler = useCallback(() => {
    setIsNotHovering();
  }, [setIsNotHovering]);

  const handlers = {
    onMouseEnter: mouseEnterHandler,
    onMouseLeave: mouseLeaveHandler
  };

  return {
    handlers,
    isHovering
  };
};
