import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ActivityStack,
  InsightStack,
  ProfileStack,
  SelfTestStack,
  TodoStack
} from '@Navigation/index';
import {
  AppContentPageScreen,
  AppDashboardScreen,
  AppFaqScreen,
  AppRewardScreen,
  AppSupportScreen
} from '@Screens/App';

import { AppRoutes } from './Routes';

export const AppStack = () => {
  return (
    <Routes>
      <Route path={AppRoutes.getDashboard().path} element={<AppDashboardScreen />} />

      <Route path={AppRoutes.getTodo().path} element={<TodoStack />} />

      <Route path={AppRoutes.getInsight().path} element={<InsightStack />} />

      <Route path={AppRoutes.getActivities().path} element={<ActivityStack />} />

      <Route path={AppRoutes.getRewards().path} element={<AppRewardScreen />} />

      <Route path={AppRoutes.getSupport().path} element={<AppSupportScreen />} />

      <Route path={AppRoutes.getMyProfile().path} element={<ProfileStack />} />

      <Route path={AppRoutes.getSelfTest().path} element={<SelfTestStack />} />

      <Route path={AppRoutes.getFaq().path} element={<AppFaqScreen />} />

      <Route path={AppRoutes.getContentPage('').path} element={<AppContentPageScreen />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
