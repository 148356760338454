import cn from 'classnames';
import { useCallback, useState } from 'react';

import { FaqItem } from '@Components/container';
import { useMatomoTrackEvent } from '@Hooks/matomo';
import { IFaqItem } from '@Services/Faq';
import { trackMatomoFaqCategory, trackMatomoFaqQuestion } from '@Utils/MatomoUtils';

import styles from './ListFaqItem.module.scss';
import { ListFaqItemProps } from './ListFaqItem.props';

export const ListFaqItem = ({ searchString, category, className }: ListFaqItemProps) => {
  const [activeIds, setActiveIds] = useState<string[]>([]);
  const trackEvent = useMatomoTrackEvent();

  const closeItem = useCallback((id: string) => {
    setActiveIds((ids) => {
      return ids.filter((activeId) => {
        return activeId !== id;
      });
    });
  }, []);

  const openItem = useCallback((id: string) => {
    setActiveIds((ids) => {
      return [...ids, id];
    });
  }, []);

  const handleToggle = useCallback(
    (item: IFaqItem) => () => {
      if (activeIds.includes(item.id)) {
        closeItem(item.id);
      } else {
        trackMatomoFaqQuestion(trackEvent, item.title);
        trackMatomoFaqCategory(trackEvent, category.title);

        openItem(item.id);
      }
    },
    [activeIds, closeItem, trackEvent, category.title, openItem]
  );

  return (
    <div className={cn(styles.ListFaqItem, className)}>
      {category.items.map((item) => {
        return (
          <FaqItem
            key={item.id}
            item={item}
            highlightedText={searchString}
            isOpen={activeIds.includes(item.id)}
            onToggle={handleToggle(item)}
          />
        );
      })}
    </div>
  );
};
