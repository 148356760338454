import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Counter, Text } from '@Components/ui';
import { useUser } from '@Hooks/user';
import { markupToHtml, toLocaleString } from '@Utils/StringUtils';

import styles from './RewardPoints.module.scss';
import { RewardPointsProps } from './RewardPoints.props';

export const RewardPoints = ({ className }: RewardPointsProps) => {
  const { t } = useTranslation();
  const { points, allTimePoints } = useUser();

  return (
    <div className={cn(styles.RewardPoints, className)}>
      <Text.H1 className={styles.Title}>{t('DOMAIN.REWARD.OVERVIEW.ACTIVE_POINTS')}</Text.H1>

      <Counter className={styles.Counter} count={points} />

      <Text.Small
        dangerousHtml={markupToHtml(
          t('DOMAIN.REWARD.OVERVIEW.TOTAL_POINTS', {
            count: allTimePoints,
            points: toLocaleString(allTimePoints)
          })
        )}
      />
    </div>
  );
};
