import { useMutation } from 'react-query';

import { useAuthLogout } from '@Hooks/auth';
import { userService, UserVerifyNewEmailRequest } from '@Services/User';

export const useUserVerifyNewEmail = () => {
  const logoutMutation = useAuthLogout();

  return useMutation(
    (config: UserVerifyNewEmailRequest) => {
      return userService.verifyNewEmail(config);
    },

    {
      onSuccess: async () => {
        await logoutMutation.mutateAsync();
      }
    }
  );
};
