import { createContext, useState } from 'react';

import { getIsRefreshStillValid } from '@Utils/StorageUtils';

import {
  AuthenticationContext,
  AuthenticationContextConsumerProps,
  AuthenticationContextProviderProps
} from './AuthenticationContext.props';

export const authenticationContext = createContext<AuthenticationContext | null>(null);

export const AuthenticationContextProvider = ({ children }: AuthenticationContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(getIsRefreshStillValid());

  const contextValue = {
    isAuthenticated: isAuthenticated,
    setIsAuthenticated
  };

  return (
    <authenticationContext.Provider value={contextValue}>{children}</authenticationContext.Provider>
  );
};
export const AuthenticationContextConsumer = ({ children }: AuthenticationContextConsumerProps) => {
  return (
    <authenticationContext.Consumer>
      {(context) => {
        return children(context);
      }}
    </authenticationContext.Consumer>
  );
};
