import cn from 'classnames';
import { useCallback, useState } from 'react';
import SwiperCore, { Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { SliderPagination } from '@Components/ui';
import { Breakpoints } from '@Constants/enums';

import styles from './Slider.module.scss';
import { SliderProps } from './Slider.props';

SwiperCore.use([Pagination]);

export const Slider = ({
  children,
  slidesPerView = 2,
  onChange,
  className,
  onSwiper
}: SliderProps) => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [totalBullets, setTotalBullets] = useState(0);

  const handlePaginationChange = useCallback(
    (idx: number) => {
      if (swiper) {
        swiper.slideTo(idx);
      }
    },
    [swiper]
  );

  const handleSlideChange = useCallback(() => {
    setCurrentSlideIdx(swiper?.activeIndex || 0);

    if (onChange) {
      onChange(swiper?.activeIndex || 0);
    }
  }, [swiper, onChange]);

  const handlePaginationRender = (slider: SwiperClass) => {
    setTotalBullets(slider.pagination?.bullets?.length || 0);
  };

  const handleSwiper = (slider: SwiperClass) => {
    setTotalBullets(slider.pagination?.bullets?.length || 0);
    setSwiper(slider);

    if (onSwiper) {
      onSwiper(slider);
    }
  };

  return (
    <Swiper
      breakpoints={{
        0: {
          slidesPerView: 1
        },
        [Breakpoints.sm]: {
          slidesPerView: slidesPerView
        }
      }}
      spaceBetween={30}
      onPaginationRender={handlePaginationRender}
      onSwiper={handleSwiper}
      pagination={{ clickable: true, type: 'bullets' }}
      onSlideChange={handleSlideChange}
      className={cn(styles.Slider, className)}
    >
      {children.map((item, idx) => {
        return <SwiperSlide key={idx}>{item}</SwiperSlide>;
      })}

      <div className={styles.Footer}>
        <SliderPagination
          total={totalBullets}
          current={currentSlideIdx}
          onChange={handlePaginationChange}
        />
      </div>
    </Swiper>
  );
};
