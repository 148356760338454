import cn from 'classnames';

import { CheckItem } from '@Components/ui';

import styles from './ListCheckItem.module.scss';
import { ListCheckItemProps } from './ListCheckItem.props';

export const ListCheckItem = ({ items = [], className }: ListCheckItemProps) => {
  return (
    <div className={cn(styles.ListCheckItem, className)}>
      {items.map((item, idx) => {
        return <CheckItem key={idx} {...item} className={styles.CheckItem} />;
      })}
    </div>
  );
};
