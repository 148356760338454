import cn from 'classnames';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Tooltip } from '@Components/ui';
import { TargetAlignment, usePlatform } from '@Hooks/common';
import { exists } from '@Utils/CommonUtils';

import { ANIMATION_DELAY } from '../../constants';
import { useScoreCircleContext } from '../../context';
import { useScoreCircleDynamicSpacing } from '../../hooks';
import styles from './Labels.module.scss';

export const Labels = () => {
  const { scores, hoveringDomain, isVisible, handleHover, handleClick, isDisabled } =
    useScoreCircleContext();

  const { t } = useTranslation();
  const { font } = usePlatform();
  const [baseSpacing] = useScoreCircleDynamicSpacing();
  const hoveringDomainRef = useRef(null);

  const hoveringScore = exists(hoveringDomain) ? scores[hoveringDomain ?? 0] : null;

  const getLabels = useMemo(() => {
    return scores?.map((score, idx) => {
      const delay = ANIMATION_DELAY * (scores.length - idx);
      const margin = baseSpacing / 2 - parseInt(font.small.fontSize.split('px')[0]);
      const isFirst = idx === 0;
      const isHovering = idx === hoveringDomain;

      const ref = isHovering ? hoveringDomainRef : undefined;

      return (
        <div ref={ref} key={score.name}>
          <Text.Small
            className={cn(styles.Label, { [styles.isVisible]: isVisible })}
            onMouseEnter={handleHover(idx)}
            onMouseLeave={handleHover(null)}
            onClick={handleClick && handleClick(idx)}
            style={{
              marginTop: isFirst ? 0 : margin,
              marginBottom: margin,
              transitionDelay: `${delay}ms`
            }}
          >
            {score.name}
          </Text.Small>
        </div>
      );
    });
  }, [
    scores,
    font,
    handleHover,
    handleClick,
    isVisible,
    hoveringDomain,
    hoveringDomainRef,
    baseSpacing
  ]);

  return (
    <div className={styles.DomainLabels} style={{ zIndex: scores?.length }}>
      {getLabels}
      {!isDisabled && (
        <Tooltip
          targetRef={hoveringDomainRef}
          isVisible={hoveringDomain !== null}
          label={
            hoveringScore && hoveringScore?.current === 0
              ? t('COMPONENTS.SCORE_CIRCLE.NOT_YET_AVAILABLE')
              : t('COMPONENTS.SCORE_CIRCLE.MORE_DETAILS')
          }
          alignment={TargetAlignment.RIGHT}
          className={styles.Tooltip}
          style={{ zIndex: scores?.length }}
        />
      )}
    </div>
  );
};
