import { differenceInMinutes } from 'date-fns';

import userActivityThumbnail from '@Assets/images/custom-activity-thumbnail.jpg';
import { USER_ACTIVITY_DURATION_MINUTES } from '@Constants/configs';
import { AppError } from '@Constants/enums';
import { IPreprElementRoadmap } from '@Constants/preprTypes';
import { AppRoutes } from '@Navigation/Routes';
import { IActivity } from '@Services/Activity';
import { IUserActivity } from '@Services/UserActivity';

import { getToday } from './DateUtils';
import { getImageSrc } from './PreprUtils';

export const getActivityRoadmap = (activity: IActivity) => {
  const roadmapElement = activity.content.find((element) => element.typeName === 'Roadmap');

  if (!roadmapElement) {
    throw new Error(AppError.ACTIVITY_HAS_NO_ROADMAP);
  }

  return (roadmapElement as IPreprElementRoadmap).roadmap;
};

export const isRoadmapActivity = (activity: IActivity) => {
  return activity.content.some((element) => element.typeName === 'Roadmap');
};

export const isRoadmapFinalStep = (activity: IActivity) => {
  const roadmap = getActivityRoadmap(activity);

  return activity.completedSteps?.length === roadmap.length - 1;
};

export const isUserActivity = (activity: IActivity | IUserActivity): activity is IUserActivity => {
  return (
    !activity.isPreprActivity &&
    'expectation' in activity === true &&
    'description' in activity === true
  );
};

export const getActivityThumbnailSrc = (activity: IActivity | IUserActivity) => {
  if (isUserActivity(activity)) {
    return userActivityThumbnail;
  }

  return getImageSrc(activity.thumbnail);
};

export const getActivityTo = (activity: IActivity) => {
  if (isUserActivity(activity)) {
    return AppRoutes.getTodoUserActivity(activity.id).to;
  }

  return AppRoutes.getActivity(activity.slug).to;
};

export const isConfigured = (activity: IUserActivity) => {
  return !!activity.description && !!activity.expectation;
};

export const isStartedAtPastDuration = (activity: IActivity | IUserActivity) => {
  const minutesAgo = differenceInMinutes(getToday(), new Date(activity.startedAt));

  if (isUserActivity(activity)) {
    return minutesAgo >= USER_ACTIVITY_DURATION_MINUTES;
  } else {
    return minutesAgo >= activity.type[0].duration;
  }
};
