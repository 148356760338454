import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Text } from '@Components/ui';
import { isDark } from '@Utils/ColorUtils';
import { toLocaleString } from '@Utils/StringUtils';

import { useScoreCircleContext } from '../../context';
import { useScoreCircleDynamicHeight, useScoreCircleDynamicSpacing } from '../../hooks';
import styles from './Score.module.scss';
import { ScoreProps } from './Score.props';

export const Score = ({ color, label }: ScoreProps) => {
  const { scores, total, componentRef } = useScoreCircleContext();
  const [baseSpacing] = useScoreCircleDynamicSpacing();
  const [componentHeight] = useScoreCircleDynamicHeight(componentRef, scores);

  const { t } = useTranslation();

  const spacing = scores.length * baseSpacing;
  const width = componentHeight - spacing;
  const isTooSmall = width < 80;
  const showScore = !isTooSmall && !label;
  const isBackgroundDark = isDark(color);

  return (
    <>
      <div
        className={styles.Score}
        style={{
          zIndex: scores?.length,
          height: width,
          width,
          backgroundColor: color
        }}
      >
        <div className={cn(styles.Inner, { [styles.isDark]: isBackgroundDark })}>
          {showScore && total && (
            <Text.Small className={styles.Label}>
              {t('COMPONENTS.SCORE_CIRCLE.MY_SCORE')}
            </Text.Small>
          )}

          {showScore && !total && (
            <Text.Normal className={styles.Label}>
              {t('COMPONENTS.SCORE_CIRCLE.NOT_AVAILABLE')}
            </Text.Normal>
          )}

          {label && <Text.Normal className={styles.Label}>{label}</Text.Normal>}

          {total?.current !== undefined && (
            <Text.H2 className={styles.Current}>{toLocaleString(total.current)}</Text.H2>
          )}

          {total?.total !== undefined && (
            <Text.Micro className={styles.Total}>{`/${total.total}`}</Text.Micro>
          )}
        </div>
      </div>
    </>
  );
};
