import { Slugs } from '@Navigation/Routes';

import { i18n } from './TranslationUtils';

export const getLink = (...params: Array<string | number>): string => {
  return params.reduce((url: string, param: string | number) => {
    if (param in Slugs) {
      const slug = i18n.t(`COMMON.NAVIGATION.${param}.SLUG`);

      if (slug.length > 0 || params.length === 1) {
        return url + `/${slug}`;
      }
    } else if (param) {
      return url + `/${param}`;
    }

    return url;
  }, '');
};

export const getLinkLabel = (slug: Slugs) => {
  return i18n.t(`COMMON.NAVIGATION.${slug}.LABEL`);
};
