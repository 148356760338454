import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Modal } from '@Components/layout';
import { Box, Button, Form, InputField, InputText, Text } from '@Components/ui';
import { useApiError, useBoolean } from '@Hooks/common';
import { useOnboardingUpdatePhone } from '@Hooks/onboarding';
import Yup from '@Utils/YupUtils';

import {
  OnboardingUpdatePhoneFormProps,
  OnboardingUpdatePhoneFormValues
} from './OnboardingUpdatePhoneForm.props';

export const OnboardingUpdatePhoneForm = ({
  onSuccess,
  userId,
  phoneNumber
}: OnboardingUpdatePhoneFormProps) => {
  const { t } = useTranslation();
  const { value: isModalOpen, setTrue: setModalOpen, setFalse: setModalClosed } = useBoolean(false);
  const { handleError } = useApiError();
  const onboardingUpdatePhoneMutation = useOnboardingUpdatePhone();

  const handleSubmit = useCallback(
    async (values: OnboardingUpdatePhoneFormValues) => {
      try {
        await onboardingUpdatePhoneMutation.mutateAsync({
          userId: userId,
          ...values
        });

        toast.success(t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_PHONE.FORM.SUCCESS'));

        onSuccess(values.phoneNumber);
        setModalClosed();
      } catch (e) {
        handleError(e);
      }
    },
    [t, onSuccess, handleError, setModalClosed, onboardingUpdatePhoneMutation, userId]
  );

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().phone().required()
  });

  return (
    <>
      <InputField
        name="placeholder"
        data-testid="changePhoneInput"
        onEditClick={setModalOpen}
        label={t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_PHONE.FORM.FIELDS.PHONE.LABEL')}
      >
        <InputText value={phoneNumber} disabled />
      </InputField>

      <Modal
        isOpen={isModalOpen}
        title={t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_PHONE.POPUP.TITLE')}
        onClose={setModalClosed}
      >
        <Text.Large
          dangerousHtml={t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_PHONE.POPUP.DESCRIPTION', {
            phoneNumber: phoneNumber
          })}
        />

        <Box mt={3}>
          <Form.Form onSubmit={handleSubmit} validationSchema={validationSchema}>
            <Form.Input.Text
              name="phoneNumber"
              data-testid="updatePhoneInput"
              label={t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_PHONE.FORM.FIELDS.PHONE.LABEL')}
              description={t(
                'DOMAIN.ONBOARDING.REGISTRATION.UPDATE_PHONE.FORM.FIELDS.PHONE.DESCRIPTION'
              )}
              options={{
                type: 'tel'
              }}
            />

            <Button type="submit" color="dark" data-testid="updatePhoneSubmit">
              {t('DOMAIN.ONBOARDING.REGISTRATION.UPDATE_PHONE.FORM.SUBMIT')}
            </Button>
          </Form.Form>
        </Box>
      </Modal>
    </>
  );
};
