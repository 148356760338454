import { useCallback } from 'react';

import { MessageItem } from '@Components/ui';
import { useInboxReadMessage } from '@Hooks/inbox';

import { InboxItemProps } from './InboxItem.props';

export const InboxItem = ({ message, className }: InboxItemProps) => {
  const readMessageMutation = useInboxReadMessage();

  const handleItemRead = useCallback(
    (id: string) => async () => {
      return readMessageMutation.mutateAsync({ id });
    },
    [readMessageMutation]
  );

  return (
    <MessageItem
      className={className}
      title={message.subject}
      message={message.content}
      isRead={!!message.readAt}
      onRead={handleItemRead(message.id)}
    />
  );
};
