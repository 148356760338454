import { useTranslation } from 'react-i18next';

import { Container } from '@Components/layout';
import { Box, Loader, SliderFullPage, Text } from '@Components/ui';
import { SortDirection } from '@Constants/enums';
import { Result } from '@Constants/interfaces';
import { useUserThemeGetAll } from '@Hooks/userTheme';

import {
  SelfTestResultsNegativeSlide,
  SelfTestResultsPositiveSlide,
  SelfTestResultsUrgentSlide
} from './internal/components';

export const SelfTestResults = () => {
  const { t } = useTranslation();

  const {
    data: userThemesPositive,
    isLoading: isUserThemesPositiveLoading,
    isError: isUserThemesPositiveError
  } = useUserThemeGetAll({
    sortDirection: SortDirection.DESC,
    excludeResults: [Result.RED, Result.ORANGE, Result.NONE]
  });

  const {
    data: userThemesNegative,
    isLoading: isUserThemesNegativeLoading,
    isError: isUserThemesNegativeError
  } = useUserThemeGetAll({
    sortDirection: SortDirection.ASC,
    excludeResults: [Result.GREEN, Result.YELLOW, Result.NONE]
  });

  const {
    data: userThemesUrgent,
    isLoading: isUserThemesUrgentLoading,
    isError: isUserThemesUrgentError
  } = useUserThemeGetAll({
    sortDirection: SortDirection.ASC,
    exclusivelyUrgent: true
  });

  const isLoading =
    isUserThemesPositiveLoading || isUserThemesNegativeLoading || isUserThemesUrgentLoading;
  const isError = isUserThemesPositiveError || isUserThemesNegativeError || isUserThemesUrgentError;

  const hasPositiveResults = !!userThemesPositive && userThemesPositive.length > 0;
  const hasNegativeResults = !!userThemesNegative && userThemesNegative.length > 0;
  const hasUrgentResults = !!userThemesUrgent && userThemesUrgent.length > 0;

  return (
    <Container cols={10}>
      {isLoading && (
        <Box my={4}>
          <Loader>{t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.LOADING')}</Loader>
        </Box>
      )}

      {isError && (
        <Box my={4}>
          <Text.Error>{t('DOMAIN.SELF_TEST_QUESTIONNAIRE.RESULTS.COULD_NOT_FETCH')}</Text.Error>
        </Box>
      )}

      {(hasPositiveResults || hasNegativeResults || hasUrgentResults) && (
        <SliderFullPage>
          {hasPositiveResults && (
            <SelfTestResultsPositiveSlide
              userThemes={userThemesPositive}
              isOnlySlide={!hasNegativeResults}
            />
          )}

          {hasNegativeResults && (
            <SelfTestResultsNegativeSlide
              userThemes={userThemesNegative}
              hasPreviousSlide={hasPositiveResults}
              isFinalSlide={!hasUrgentResults}
            />
          )}

          {hasUrgentResults && (
            <SelfTestResultsUrgentSlide
              userThemes={userThemesUrgent}
              hasPreviousSlide={hasPositiveResults || hasNegativeResults}
            />
          )}
        </SliderFullPage>
      )}
    </Container>
  );
};
