import { BaseService, dePortalenService } from '@Services/common';
import { toQueryString } from '@Utils/StringUtils';

import {
  InsightOverviewRequest,
  InsightOverviewResponse,
  TimelineRequest,
  TimelineResponse
} from './InsightService.props';

export class InsightService extends BaseService {
  getTimeline = async (params: TimelineRequest) => {
    const response = await this.get<TimelineResponse>(`/timeline?${toQueryString(params)}`);

    return response.data;
  };

  getOverview = async (params: InsightOverviewRequest) => {
    const response = await this.get<InsightOverviewResponse>(`/overview?${toQueryString(params)}`);

    return response.data;
  };
}

export const insightService = new InsightService(dePortalenService.getAxiosInstance(), '/insight');
