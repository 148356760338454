import { CardReward } from '@Components/container';
import { CardList } from '@Components/list';

import { ListCardRewardProps } from './ListCardReward.props';

export const ListCardReward = ({ rewards = [], onClick, className }: ListCardRewardProps) => {
  return (
    <CardList className={className} data-testid="listCardReward">
      {rewards.map((reward) => {
        return <CardReward key={reward.id} reward={reward} onClick={onClick} />;
      })}
    </CardList>
  );
};
