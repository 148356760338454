import { BaseService, dePortalenService } from '@Services/common';

import {
  UserActivityCreateRequest,
  UserActivityCreateResponse,
  UserActivityOneRequest,
  UserActivityOneResponse,
  UserActivityUpdateRequest,
  UserActivityUpdateResponse
} from './UserActivityService.props';

export class UserActivityService extends BaseService {
  one = async ({ id }: UserActivityOneRequest) => {
    const response = await this.get<UserActivityOneResponse>(`/${id}/custom`);

    return response.data;
  };

  create = async (config: UserActivityCreateRequest) => {
    const response = await this.post<UserActivityCreateResponse>('/custom', config);

    return response.data;
  };

  update = async ({ id, ...config }: UserActivityUpdateRequest) => {
    const response = await this.put<UserActivityUpdateResponse>(`/${id}/custom`, config);

    return response.data;
  };
}

export const userActivityService = new UserActivityService(
  dePortalenService.getAxiosInstance(),
  '/useractivities'
);
