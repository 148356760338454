import cn from 'classnames';

import { Box, Button, Image } from '@Components/ui';

import { Block } from '../Block/Block';
import { Text } from '../Text';
import styles from './BlockTip.module.scss';
import { BlockTipProps } from './BlockTip.props';

export const BlockTip = ({ title, children, imageUri, button, className }: BlockTipProps) => {
  return (
    <Block
      theme="gray"
      padding="sm"
      className={cn(styles.BlockTip, className)}
      innerClassName={styles.Body}
    >
      <div>
        <Text.Large className={styles.Title}>
          <strong>{title}</strong>
        </Text.Large>

        {children}

        {button && (
          <Box mt={2}>
            <Button href={button.href} color="dark">
              {button.label}
            </Button>
          </Box>
        )}
      </div>

      {imageUri && <Image className={styles.Image} ratio="none" src={imageUri} />}
    </Block>
  );
};
