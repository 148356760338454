import { StyleSheet } from '@react-pdf/renderer';

import { spacing } from '@Utils/ThemeUtils';

export const styles = StyleSheet.create({
  LegendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacing(1)
  },
  LegendLabel: {
    marginLeft: spacing(3)
  }
});
