import { Font as PdfFont } from '@react-pdf/renderer';

import appleTouchIconHcc from '@Assets/images/apple-touch-icon-hcc.png';
import faviconHccIco from '@Assets/images/favicon-hcc.ico';
import logoHccPng from '@Assets/images/logo-hcc.png';
import logoHccFooter from '@Assets/images/logo-hcc-footer.svg';
import { Theme } from '@Constants/themes';

export const HUMAN_CAPITAL_CARE: Theme = {
  background: {
    fade: {
      default: 'none'
    },
    highlight: {
      default: 'none',
      fullscreen: 'none'
    }
  },

  color: {
    primary: {
      light: '#004e99',
      default: '#004587'
    },
    secondary: {
      light: '#00a2bb',
      default: '#0094aa',
      dark: '#008ca1',
      opaque: '#0094aa30'
    },
    tertiary: {
      default: '#c1ce4b',
      dark: '#bdca3f',
      color: '#fff',
      opaque: '#c1ce4b30'
    },
    background: {
      default: '#f2f5f9',
      dark: '#eaf2fd'
    },
    font: {
      light: '#354264',
      default: '#3e3e3e',
      transparant: 'rgba(33,41,62,.64)'
    },

    frame: { default: '#ffffff' },

    gray: {
      default: '#C4C4C4',
      dark: '#A7A4A4',
      light: '#f5f5f5',
      background: '#f9f9f9'
    },
    white: {
      default: '#fff',
      transparant: 'rgba(255, 255, 255, 0.24)'
    },
    black: {
      default: '#000'
    },

    red: { default: '#e61818' },
    orange: { default: '#ff9300' },
    yellow: { default: '#ffe066' },
    green: { default: '#47c869', light: '#7aec98' },

    error: { default: '#e61818', light: '#fef6f6', transparant: '#e61818bb' },
    warning: { default: '#ff9300' },
    success: { default: '#c1ce4b', light: '#f9fae9' },
    info: { default: '#8ec8cc' }
  },

  shadow: {
    light: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
    small: '0 2px 4px 0 rgba(0, 0, 0, 0.24)',
    medium: '10px 7px 16px 0 rgba(0, 0, 0, 0.19)',
    mediumCentered: '0 7px 16px 0 rgba(0, 0, 0, 0.19)',
    mediumLeft: '-10px 0 16px 0 rgba(0, 0, 0, 0.19)',
    large: '16px 32px 40px 0 rgba(0, 0, 0, 0.16)'
  },

  font: {
    family: {
      base: 'bligh',
      heading: 'bligh',
      handwritten: 'Caveat'
    },

    h1: {
      color: '#004587',
      fontFamily: 'bligh',
      fontWeight: 'bold',
      fontSize: '42px',
      lineHeight: '1.14'
    },

    h2: {
      color: '#004587',
      fontFamily: 'bligh',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '1.14'
    },

    h3: {
      color: '#3e3e3e',
      fontFamily: 'bligh',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '1.33'
    },

    h4: {
      color: '#3e3e3e',
      fontFamily: 'bligh',
      fontWeight: 'bold',
      fontSize: '17px',
      lineHeight: '1.12'
    },

    h5: {
      color: '#3e3e3e',
      fontFamily: 'bligh',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '1.23'
    },

    intro: {
      color: '#3e3e3e',
      fontFamily: 'questa-slab',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '1.71'
    },

    large: {
      color: '#3e3e3e',
      fontFamily: 'bligh',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '1.71'
    },

    normal: {
      color: '#3e3e3e',
      fontFamily: 'bligh',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '1.46'
    },

    medium: {
      color: '#3e3e3e',
      fontFamily: 'bligh',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '1.46'
    },

    bold: {
      color: '#3e3e3e',
      fontFamily: 'bligh',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '1.46'
    },

    small: {
      color: '#3e3e3e',
      fontFamily: 'bligh',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '1.45'
    },

    micro: {
      color: '#bfbebe',
      fontFamily: 'bligh',
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '1'
    },

    handwritten: {
      color: '#0094aa',
      fontFamily: 'Caveat',
      fontWeight: '600',
      fontSize: '25px',
      lineHeight: '0.96'
    }
  },

  radius: {
    tag: '100px',
    tagExtraSmall: '100px',
    buttonGroup: '8px',
    alert: '16px',
    buttonIcon: '100px',
    inputRadio: '8px',
    inputText: '2px',
    inputLike: '8px',
    counter: '4.6px',
    countNotifier: '100px',
    toolTip: '3px',
    button: '8px',
    avatar: '22px',
    avatarSmall: '14px',
    medal: '8px',
    dropdown: '8px',
    inboxItem: '8px',
    motivator: '8px',
    accordion: '8px',
    modal: '8px',
    block: '8px',
    blockElevated: '8px',
    card: '3px',
    progressTheme: '8px',
    imageResize: '8px',
    thumbnailTextRow: '3px',
    selectActivityBlock: '8px',
    sidebarCta: '4px'
  },

  assets: {
    logo: require('@Assets/images/logo-hcc.svg'),
    logoPng: logoHccPng,
    logoFooter: logoHccFooter,
    faviconIco: faviconHccIco,
    appleTouchIcon: appleTouchIconHcc,
    icon: require('@Assets/images/logo-small-hcc.svg')
  },

  strings: {
    phone: '06 8349 3748',
    mail: 'support-humancapitalcare@kom-verder.nl',
    address: 'Science Park Eindhoven 5127',
    postcalCode: '5692 ED Son',
    websiteUrl: 'https://humancapitalcare.kom-verder.nl/',
    privacyUrl: 'https://humancapitalcare.kom-verder.nl/privacy-statement/',
    personalUrl: 'https://humancapitalcare.kom-verder.nl/gegevensverwerking/',
    termsUrl: 'https://humancapitalcare.kom-verder.nl/gebruiksvoorwaarden/'
  },

  easing: {
    out: 'cubic-bezier(0.2, 0.4, 0, 1)',
    in: 'cubic-bezier(1, 0, 0.8, 0.6)',
    inOut: 'cubic-bezier(0.66, 0.07, 0.33, 0.94)'
  },

  spacing: {
    base: 8,
    tagTransformY: 'none'
  }
};

PdfFont.register({
  family: 'questa-slab',
  fonts: [
    {
      src: 'https://use.typekit.net/af/0e484e/00000000000000007735af6a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/798b78/00000000000000007735af62/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3',
      fontWeight: 'medium',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/e8d92c/00000000000000007735af5c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      fontWeight: 'normal',
      fontStyle: 'normal'
    }
  ]
});

PdfFont.register({
  family: 'bligh',
  fonts: [
    {
      src: 'https://use.typekit.net/af/e97120/000000000000000000017697/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    {
      src: 'https://use.typekit.net/af/e9c3aa/000000000000000000017699/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      fontWeight: 'normal',
      fontStyle: 'normal'
    }
  ]
});
