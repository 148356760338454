import { useTranslation } from 'react-i18next';

import { SelfTestDomainSelectionForm } from '@Components/form';
import { Block, Box, Text } from '@Components/ui';
import { markupToHtml } from '@Utils/StringUtils';

import styles from './SelfTestDomainSelection.module.scss';

export const SelfTestDomainSelection = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.SelfTestDomainSelection}>
      <div className={styles.Inner}>
        <Box mx={4} mb={5}>
          <Text.H2 className={styles.Title}>{t('DOMAIN.SELF_TEST_QUESTIONNAIRE.NAME')}</Text.H2>

          <Text.Large className={styles.Intro}>
            {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.ADAPTIVE.DOMAIN_SELECTION.INTRO')}
          </Text.Large>

          <Text.Large
            dangerousHtml={markupToHtml(
              t('DOMAIN.SELF_TEST_QUESTIONNAIRE.ADAPTIVE.DOMAIN_SELECTION.CTA')
            )}
          />
        </Box>

        <Block isElevated>
          <SelfTestDomainSelectionForm />
        </Block>
      </div>
    </div>
  );
};
