import cn from 'classnames';
import {
  ChangeEvent,
  createRef,
  forwardRef,
  KeyboardEvent,
  RefObject,
  useEffect,
  useRef,
  useState
} from 'react';

import { InputText } from '@Components/ui';

import styles from './InputCode.module.scss';
import { InputCodeProps } from './InputCode.props';

const BACKSPACE_KEY = 'Backspace';
const LEFT_ARROW_KEY = 'ArrowLeft';
const UP_ARROW_KEY = 'ArrowUp';
const RIGHT_ARROW_KEY = 'ArrowRight';
const DOWN_ARROW_KEY = 'ArrowDown';

export const InputCode = forwardRef(
  (
    {
      name,
      value,
      length = 6,
      isDisabled,
      placeholder,
      onBlur,
      onChange,
      onComplete,
      className,
      ...props
    }: InputCodeProps,
    ref?: any
  ) => {
    const [values, setValues] = useState<string[]>(value ? value.split('') : []);
    const refs = useRef<RefObject<HTMLInputElement>[]>([]).current;

    useEffect(() => {
      if (values && values.length) {
        const newValues = [];

        for (let i = 0; i < length; i++) {
          newValues.push(values[i] || '');
        }

        setValues(newValues);
      } else {
        setValues(Array(length).fill(''));
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (onChange) {
        onChange(values.join(''));
      }

      if (onComplete && values.length >= length) {
        onComplete(values.join(''));
      }
    }, [length, onChange, onComplete, values]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value.toUpperCase();

      if (newValue === '') {
        return;
      }

      const newValues = [...values];
      const index = parseInt(String(e.target.dataset.id));
      let next;

      if (newValue.length > 1) {
        let nextIndex = newValue.length + index - 1;

        if (nextIndex >= length) {
          nextIndex = length - 1;
        }

        next = refs[nextIndex];

        const split = newValue.split('');
        split.forEach((item: string, idx: number) => {
          const cursor = index + idx;
          if (cursor < length) {
            newValues[cursor] = item;
          }
        });
      } else {
        let nextIndex = index + 1;

        if (nextIndex >= length) {
          nextIndex = length - 1;
        }

        next = refs[nextIndex];

        newValues[index] = newValue;
      }

      if (next && next.current) {
        next.current.focus();
        next.current.select();
      }

      setValues(newValues);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      const index = parseInt(String(e.currentTarget.dataset.id));

      const prevIndex = index - 1;
      const nextIndex = index + 1;

      const prev = refs[prevIndex];
      const next = refs[nextIndex];

      switch (e.key) {
        case BACKSPACE_KEY:
          e.preventDefault();
          const newValues = [...values];

          if (values[index]) {
            newValues[index] = '';

            setValues(newValues);
          } else if (prev) {
            newValues[prevIndex] = '';

            if (prev && prev.current) {
              prev.current.focus();
            }

            setValues(newValues);
          }
          break;
        case LEFT_ARROW_KEY:
          e.preventDefault();

          if (prev && prev.current) {
            prev.current.focus();
          }
          break;
        case RIGHT_ARROW_KEY:
          e.preventDefault();

          if (next && next.current) {
            next.current.focus();
          }
          break;
        case UP_ARROW_KEY:
        case DOWN_ARROW_KEY:
          e.preventDefault();
          break;
      }
    };

    const handleBlur = () => {
      if (onBlur) {
        setTimeout(() => {
          const isCode = document.activeElement?.classList.contains('InputCode');
          if (!isCode) {
            onBlur();
          }
        }, 0);
      }
    };

    return (
      <div className={cn(styles.InputCode, className)} ref={ref}>
        {values.map((char, index) => {
          const inputRef = createRef<HTMLInputElement>();
          refs[index] = inputRef;

          return (
            <InputText
              className={cn(styles.Input, 'InputCode')}
              id={index === 0 && name ? name : undefined}
              name={name}
              key={`InputCode-${index}`}
              data-id={index}
              value={char}
              ref={refs[index]}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              disabled={isDisabled}
              placeholder={placeholder && placeholder.split('')[index]}
              showValid
              {...props}
            />
          );
        })}
      </div>
    );
  }
);
