import { useTranslation } from 'react-i18next';

import {
  ProfileUpdateEmailForm,
  ProfileUpdatePasswordForm,
  ProfileUpdatePhoneForm
} from '@Components/form';
import { Text } from '@Components/ui';
import { useUser } from '@Hooks/user';

import styles from './ProfileEditLoginDataTab.module.scss';

export const ProfileEditLoginDataTab = () => {
  const { email, phoneNumber } = useUser();
  const { t } = useTranslation();

  return (
    <>
      <Text.Large className={styles.Description}>
        {t('DOMAIN.PROFILE.LOGIN_DATA.DESCRIPTION')}
      </Text.Large>

      <ProfileUpdateEmailForm phoneNumber={phoneNumber} email={email} />

      <ProfileUpdatePhoneForm phoneNumber={phoneNumber} />

      <ProfileUpdatePasswordForm phoneNumber={phoneNumber} />
    </>
  );
};
