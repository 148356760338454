import { useCallback, useEffect } from 'react';
import { useInViewEffect } from 'react-hook-inview';

import { isIntersecting } from '@Utils/ElementUtils';

import { useBoolean } from './useBoolean';

export const usePlayInView = (play: () => void): [(node: Element | null) => void, () => void] => {
  const { value: hasPlayed, setTrue: setHasPlayed, setFalse: setHasntPlayed } = useBoolean(false);
  const { value: isVisible, setTrue: setVisible } = useBoolean(false);

  useEffect(() => {
    if (isVisible && !hasPlayed) {
      const timeout = setTimeout(() => {
        play();
        setHasPlayed();
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [isVisible, play, hasPlayed, setHasPlayed]);

  const ref = useInViewEffect(
    ([entry], observer) => {
      if (entry.rootBounds && isIntersecting(entry.rootBounds, entry.boundingClientRect)) {
        setVisible();

        observer.unobserve(entry.target);
      }
    },
    { threshold: 1 }
  );

  const reset = useCallback(() => {
    setHasntPlayed();
  }, [setHasntPlayed]);

  return [ref, reset];
};
