import cn from 'classnames';

import { Text } from '@Components/ui';
import { getApiErrorMessage } from '@Utils/ErrorUtils';

import styles from './FormError.module.scss';
import { FormErrorProps } from './FormError.props';

export const FormError = ({ error, className }: FormErrorProps) => {
  if (!error) {
    return null;
  }

  return (
    <Text.Error className={cn(styles.FormError, className)}>{getApiErrorMessage(error)}</Text.Error>
  );
};
