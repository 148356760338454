import { useTranslation } from 'react-i18next';

import { Loader, ProgressCircle, Text } from '@Components/ui';
import { usePlatform } from '@Hooks/common';
import { useConfigurationAll } from '@Hooks/configuration';
import { useUser } from '@Hooks/user';
import { toLocaleString } from '@Utils/StringUtils';

import { DashboardBlock } from '../DashboardBlock/DashboardBlock';
import styles from './DashboardPoints.module.scss';
import { DashboardPointsProps } from './DashboardPoints.props';

export const DashboardPoints = ({ className }: DashboardPointsProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();
  const { points, allTimePoints } = useUser();

  const {
    data: configuration,
    isSuccess: isConfigurationSuccess,
    isLoading: isConfigurationLoading,
    isError: isConfigurationError
  } = useConfigurationAll();

  return (
    <DashboardBlock widgetKey="POINTS" className={className} data-tourdotid="POINTS">
      {configuration && isConfigurationSuccess && (
        <ProgressCircle
          className={styles.Circle}
          label={toLocaleString(points)}
          subLabel={toLocaleString(allTimePoints)}
          total={configuration.totalScore}
          values={[
            {
              color: color.success.light,
              value: allTimePoints
            },
            {
              color: color.success.default,
              value: points
            }
          ]}
        />
      )}

      {isConfigurationError && <Text.Error>{t('DOMAIN.DASHBOARD.BLOCKS.POINTS.ERROR')}</Text.Error>}

      {isConfigurationLoading && <Loader>{t('DOMAIN.DASHBOARD.BLOCKS.POINTS.LOADING')}</Loader>}
    </DashboardBlock>
  );
};
