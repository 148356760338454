import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CardChallenge } from '@Components/container';
import { CardList } from '@Components/list';
import { CallToAction } from '@Components/ui';
import { TargetAlignment, TargetDirection } from '@Hooks/common';
import { isOdd } from '@Utils/NumberUtils';

import { ListCardChallengeProps } from './ListCardChallenge.props';

export const ListCardChallenge = ({
  challenges = [],
  onChallengeClick,
  shouldShowRecommendedCta = false,
  className
}: ListCardChallengeProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const recommendedIndex = challenges.findIndex((challenge) => challenge.recommended);

  const { t } = useTranslation();

  return (
    <>
      <CardList className={className} data-testid="ListCardChallengeList">
        {challenges.map((item, idx) => {
          const challengeRef = idx === recommendedIndex ? ref : undefined;
          const isDisabled = item.completed || item.started;

          return (
            <CardChallenge
              ref={challengeRef}
              key={item.id}
              challenge={item}
              onClick={onChallengeClick}
              isDisabled={isDisabled}
              disabledLabel={t('DOMAIN.CHALLENGE.STARTED_OR_COMPLETED')}
            />
          );
        })}
      </CardList>

      {recommendedIndex >= 0 && (
        <CallToAction
          targetRef={ref}
          direction={isOdd(recommendedIndex) ? TargetDirection.LEFT : TargetDirection.RIGHT}
          alignment={TargetAlignment.TOP}
          label={t('DOMAIN.CHALLENGE.RECOMMENDED')}
          isReversed
          isVisible={recommendedIndex >= 0 && shouldShowRecommendedCta}
          updateOnShow
          isStatic
        />
      )}
    </>
  );
};
