import { useCallback } from 'react';
import { InfiniteData, useQueryClient } from 'react-query';

import { ListResponse } from '@Constants/interfaces';
import { IActivity } from '@Services/Activity';

export const useUpdateActivityData = () => {
  const queryClient = useQueryClient();

  const updateActivityData = useCallback(
    (activity: IActivity, newData: Partial<IActivity>) => {
      queryClient.setQueryData<InfiniteData<ListResponse<IActivity>> | undefined>(
        ['activityListRandom'],
        (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              pages:
                oldData.pages?.map((page: any) => {
                  return {
                    ...page,
                    results:
                      page?.results?.map((oldActivity: IActivity) => {
                        if (oldActivity.id === activity.id) {
                          return {
                            ...oldActivity,
                            ...newData
                          };
                        }

                        return oldActivity;
                      }) ?? []
                  };
                }) ?? []
            };
          }

          return oldData;
        }
      );
    },
    [queryClient]
  );

  return updateActivityData;
};
