import { AppError } from '@Constants/enums';
import {
  ActivitySubjectsResponse,
  DataGetActivityTypesResponse,
  DataGetGgcResponse,
  IPreprPreference
} from '@Services/Data';
import { PreferenceKey, PreferenceResponse } from '@Services/Preference';
import { getPreference } from '@Utils/PreferenceUtils';
import { i18n } from '@Utils/TranslationUtils';

const getPreprPreference = <T extends IPreprPreference>(ggcData: T[], id: string): T => {
  const ggcItem = ggcData.find((item) => {
    return item.preprId === id;
  });

  if (!ggcItem) {
    throw new Error(AppError.GGC_ITEM_NOT_FOUND);
  }

  return ggcItem;
};

export const getGgcAnswer = (ggcData: DataGetGgcResponse, preferences: PreferenceResponse) => {
  const healthAnswerId = getPreference(preferences, PreferenceKey.GGC_HEALTH);
  const motivationAnswerId = getPreference(preferences, PreferenceKey.GGC_MOTIVATION);
  const competenceAnswerId = getPreference(preferences, PreferenceKey.GGC_COMPETENCE);

  if (!healthAnswerId) {
    throw new Error(AppError.NO_GGC_HEALTH_PREFERENCE);
  }

  if (!motivationAnswerId) {
    throw new Error(AppError.NO_GGC_MOTIVATION_PREFERENCE);
  }

  if (!competenceAnswerId) {
    throw new Error(AppError.NO_GGC_COMPETENCE_PREFERENCE);
  }

  const healthAnswerDescription = getPreprPreference(ggcData, healthAnswerId[0]);
  const motivationAnswerDescription = getPreprPreference(ggcData, motivationAnswerId[0]);
  const competenceAnswerDescription = getPreprPreference(ggcData, competenceAnswerId[0]);

  return [
    healthAnswerDescription.description,
    motivationAnswerDescription.description,
    competenceAnswerDescription.description
  ];
};

export const getActivitySubjectsAnswer = (
  ggcData: ActivitySubjectsResponse,
  preferences: PreferenceResponse
) => {
  const answerIds = getPreference(preferences, PreferenceKey.ACTIVITY_SUBJECTS);

  if (!answerIds) {
    throw new Error(AppError.NO_ACTIVITY_SUBJECTS_PREFERENCE);
  }

  return answerIds.map((item) => {
    return getPreprPreference(ggcData, item).title;
  });
};

export const getActivityTypesAnswer = (
  ggcData: DataGetActivityTypesResponse,
  preferences: PreferenceResponse
) => {
  const answerIds = getPreference(preferences, PreferenceKey.ACTIVITY_TYPES);

  if (!answerIds) {
    throw new Error(AppError.NO_ACTIVITY_TYPES_PREFERENCE);
  }

  return answerIds.map((item) => {
    return getPreprPreference(ggcData, item).title;
  });
};

export const getPreferenceLabels = (preference: PreferenceKey, answers: string[]) => {
  return answers.map((item) => {
    return i18n.t(`DOMAIN.PREFERENCES.${preference}.FORM.FIELDS.${item}.LABEL`);
  });
};
