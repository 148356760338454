import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Form } from '@Components/ui';
import { InputOption } from '@Constants/interfaces';
import { useApiError } from '@Hooks/common';
import {
  useOnboardingGetSelfTestDomains,
  useOnboardingSubmitSelfTestDomains
} from '@Hooks/onboarding';
import Yup from '@Utils/YupUtils';

import {
  SelfTestDomainSelectionFormProps,
  SelfTestDomainSelectionFormValues
} from './SelfTestDomainSelectionForm.props';

export const SelfTestDomainSelectionForm = ({ onSuccess }: SelfTestDomainSelectionFormProps) => {
  const { t } = useTranslation();

  const { data: domains } = useOnboardingGetSelfTestDomains();
  const onboardingSelfTestSubmitDomainsMutation = useOnboardingSubmitSelfTestDomains();
  const { handleError } = useApiError();

  const handleSubmit = useCallback(
    async (values: SelfTestDomainSelectionFormValues) => {
      try {
        await onboardingSelfTestSubmitDomainsMutation.mutateAsync(values);

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, onSuccess, onboardingSelfTestSubmitDomainsMutation]
  );

  const domainsOptions: InputOption[] | undefined = useMemo(() => {
    return domains?.map((domain) => {
      return {
        value: domain.id,
        label: domain.title
      };
    });
  }, [domains]);

  const initialValues = useMemo(() => {
    return { domains: domains?.map((domain) => domain.id) };
  }, [domains]);

  const validationSchema = Yup.object().shape({
    domains: Yup.array(Yup.string().required()).min(1).required()
  });

  if (domainsOptions) {
    return (
      <Form.Form<SelfTestDomainSelectionFormValues>
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        <Box mb={4}>
          <Form.Input.Toggle
            name="domains"
            options={{ options: domainsOptions }}
            data-testid="domainsInput"
          />
        </Box>

        <Button icon="arrow-right" data-testid="domainsSubmit">
          {t('DOMAIN.SELF_TEST_QUESTIONNAIRE.ADAPTIVE.DOMAIN_SELECTION.SUBMIT')}
        </Button>
      </Form.Form>
    );
  }

  return null;
};
