import cn from 'classnames';
import { Children } from 'react';

import { Icon, Text } from '@Components/ui';

import styles from './Checked.module.scss';
import { CheckedListProps } from './Checked.props';

export const Checked = ({ title, children, className }: CheckedListProps) => {
  return (
    <ul className={cn(styles.Checked, className)}>
      {!!title && <Text.H4 className={styles.Title}>{title}</Text.H4>}

      {Children.toArray(children).map((child, idx) => {
        return (
          <li key={idx} className={styles.ListItem}>
            <Icon icon={['fas', 'check-square']} className={styles.Icon} />

            <div className={styles.Children}>{child}</div>
          </li>
        );
      })}
    </ul>
  );
};
