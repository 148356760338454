import { Container } from '@Components/layout';

import { AppSupport, AppSupportHeader } from './internal';

export const AppSupportScreen = () => {
  return (
    <Container>
      <AppSupportHeader />

      <AppSupport />
    </Container>
  );
};
