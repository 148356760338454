import cn from 'classnames';

import styles from './Container.module.scss';
import { ContainerProps } from './Container.props';

export const Container = ({ cols = 8, children, className, ...props }: ContainerProps) => {
  const classNames = cn(styles.Container, className, {
    [styles.has4Cols]: cols === 4,
    [styles.has6Cols]: cols === 6,
    [styles.has8Cols]: cols === 8,
    [styles.has10Cols]: cols === 10
  });

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};
