import { Text } from '@react-pdf/renderer';

import { PdfTextH4Props } from './PdfTextH4.props';
import { styles } from './PdfTextH4.styles';

export const H4 = ({ children, style, ...props }: PdfTextH4Props) => {
  return (
    <Text style={{ ...styles.H4, ...style }} {...props}>
      {children}
    </Text>
  );
};
