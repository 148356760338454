import cn from 'classnames';
import { forwardRef, MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Icon, Text } from '@Components/ui';

import styles from './ButtonIcon.module.scss';
import { ButtonIconProps } from './ButtonIcon.props';

export const ButtonIcon = forwardRef(
  (
    {
      label,
      icon,
      to,
      onClick,
      iconColor,
      iconBackgroundColor,
      isReversed = false,
      isDisabled = false,
      isHovering = false,
      className,
      ...props
    }: ButtonIconProps,
    ref: any
  ) => {
    const classNames = cn([
      styles.ButtonIcon,
      {
        [styles.isDisabled]: isDisabled,
        [styles.isHovering]: isHovering,
        [styles.isReversed]: isReversed
      },
      className
    ]);

    const handleClick = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        if (!isDisabled && onClick) {
          onClick(e);
        }
      },
      [isDisabled, onClick]
    );

    const iconContainerStyle = iconBackgroundColor
      ? {
          backgroundColor: iconBackgroundColor
        }
      : undefined;

    const getInner = () => {
      return (
        <>
          {!!icon && (
            <span className={styles.Icon} style={iconContainerStyle}>
              <Icon icon={['fas', icon]} color={iconColor} />
            </span>
          )}

          {!!label && <Text.H5 className={styles.Label}>{label}</Text.H5>}
        </>
      );
    };

    const getLinkElement = () => {
      if (!to) {
        return null;
      }

      return (
        <Link ref={ref} to={to} className={classNames} {...props}>
          {getInner()}
        </Link>
      );
    };

    const getDefaultElement = () => {
      return (
        <div ref={ref} onClick={handleClick} className={classNames} {...props}>
          {getInner()}
        </div>
      );
    };

    return to ? getLinkElement() : getDefaultElement();
  }
);
