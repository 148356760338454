import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Anchor, Box, Text } from '@Components/ui';
import { SMS_COUNTDOWN_SECONDS } from '@Constants/configs';
import { useApiError, useCountdown } from '@Hooks/common';
import { useUserInitiateNewPhone } from '@Hooks/user';
import { markupToHtml } from '@Utils/StringUtils';

import { EditPhoneVerifyUpdateForm } from '../../forms';
import styles from './EditPhoneUpdateStep.module.scss';
import { EditPhoneUpdateStepProps } from './EditPhoneUpdateStep.props';

export const EditPhoneUpdateStep = ({ newPhoneNumber, onSuccess }: EditPhoneUpdateStepProps) => {
  const { handleError } = useApiError();
  const { t } = useTranslation();
  const { start: startCountdown, count } = useCountdown(SMS_COUNTDOWN_SECONDS);
  const [smsToken, setSmsToken] = useState('');

  const initiateUpdatePhoneMutation = useUserInitiateNewPhone();

  const sendTwoFactorSms = useCallback(async () => {
    try {
      const response = await initiateUpdatePhoneMutation.mutateAsync({
        newPhoneNumber,
        newPhoneNumberConfirmed: newPhoneNumber
      });

      setSmsToken(response.smsToken);
    } catch (e) {
      handleError(e);
    }
  }, [initiateUpdatePhoneMutation, newPhoneNumber, handleError]);

  const resendTwoFactorSms = useCallback(async () => {
    startCountdown();
    sendTwoFactorSms();

    toast.success(t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.VERIFY_SMS.SEND_SMS_CODE'));
  }, [sendTwoFactorSms, startCountdown, t]);

  useEffect(() => {
    sendTwoFactorSms();

    /*
    Necessary because executing initiateUpdatePhoneMutation 
    creates a new instance of itself
    */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Text.Large
        className={styles.Inline}
        dangerousHtml={markupToHtml(
          t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.VERIFY_SMS.DESCRIPTION', {
            phoneNumber: newPhoneNumber
          })
        )}
      />

      <Text.Large>
        <Anchor onClick={resendTwoFactorSms} isDisabled={count !== 0} data-testid="resendToken">
          {t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.VERIFY_SMS.RE_SEND')}
          {count > 0 &&
            t('DOMAIN.PROFILE.LOGIN_DATA.EDIT_PHONE.VERIFY_SMS.RE_SEND_COUNT', {
              count: count
            })}
        </Anchor>
        {'.'}
      </Text.Large>

      <Box mt={3}>
        <EditPhoneVerifyUpdateForm
          onSuccess={onSuccess}
          smsToken={smsToken}
          newPhoneNumber={newPhoneNumber}
        />
      </Box>
    </>
  );
};
