import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PreprRenderer } from '@Components/layout';
import { Text } from '@Components/ui';
import { useActivityFinishStep } from '@Hooks/activity';
import { useApiError, usePlatform } from '@Hooks/common';
import { getDate } from '@Utils/DateUtils';

import { AccordionSteps } from './internal/components/AccordionSteps/AccordionSteps';
import { AccordionStepsItem } from './internal/components/AccordionSteps/AccordionSteps.props';
import { AccordionStepsItemState } from './internal/components/AccordionStepsItem/AccordionStepsItem.props';
import { PreprElementRoadmapFinishStep } from './internal/components/PreprElementRoadmapFinishStep/PreprElementRoadmapFinishStep';
import styles from './PreprElementRoadmap.module.scss';
import { PreprElementRoadmapProps } from './PreprElementRoadmap.props';

export const PreprElementRoadmap = ({ activity, element }: PreprElementRoadmapProps) => {
  const { t } = useTranslation();
  const { color } = usePlatform();
  const activityFinishStepMutation = useActivityFinishStep();
  const { handleError } = useApiError();

  const handleFinish = useCallback(
    (id: string, stepId: string) => async () => {
      try {
        await activityFinishStepMutation.mutateAsync({
          id: id,
          stepId: stepId,
          slug: activity.slug
        });
      } catch (e) {
        handleError(e);
      }
    },
    [activity.slug, activityFinishStepMutation, handleError]
  );

  const getRoadmapFinishStepComponent = useCallback(
    (item, currentStep) => {
      return (
        <PreprElementRoadmapFinishStep
          item={item}
          nextStepNumber={currentStep + 1}
          onFinish={handleFinish(activity.id, item.id)}
          isLoading={activityFinishStepMutation.isLoading}
        />
      );
    },
    [activity, activityFinishStepMutation, handleFinish]
  );

  const accordionItems = useMemo(() => {
    let hasActiveStep = false;

    return element.roadmap.map((item, idx) => {
      const currentStep = idx + 1;
      const isFinished =
        !!activity.completedSteps &&
        activity.completedSteps.some((completedStep) => completedStep.id === item.id);
      const createdAt = activity.completedSteps && activity.completedSteps[idx - 1]?.completedAt;

      const isLastStep = currentStep >= element.roadmap.length;

      const getRoadmapStepState = () => {
        if (!!activity.completedSteps?.find((completedStep) => completedStep.id === item.id)) {
          return AccordionStepsItemState.SUCCESS;
        }

        if (!hasActiveStep) {
          hasActiveStep = true;

          return AccordionStepsItemState.DEFAULT;
        }

        return AccordionStepsItemState.DISABLED;
      };

      const stepState = getRoadmapStepState();

      return {
        id: item.id,
        title: t('DOMAIN.ACTIVITY.ROADMAP.STEP_TITLE', {
          step: currentStep,
          title: item.title
        }),

        body: (
          <>
            {createdAt && (
              <Text.Micro color={color.gray.dark} className={styles.StartedOn}>
                {t('DOMAIN.ACTIVITY.ROADMAP.STARTED_ON', {
                  date: getDate(new Date(createdAt))
                })}
              </Text.Micro>
            )}

            <PreprRenderer content={item.content} />

            {!isFinished && (
              <div className={styles.Finish}>
                {!isLastStep && getRoadmapFinishStepComponent(item, currentStep)}
              </div>
            )}
          </>
        ),
        popup: {
          title: t('DOMAIN.ACTIVITY.ROADMAP.STEP', {
            step: currentStep
          }),
          body: item.preview
        },
        state: stepState,
        tooltipText:
          stepState === AccordionStepsItemState.DISABLED
            ? t('DOMAIN.ACTIVITY.ROADMAP.PREVIEW_TOOLTIP', { step: currentStep })
            : undefined
      } as AccordionStepsItem;
    });
  }, [element.roadmap, activity, t, color.gray.dark, getRoadmapFinishStepComponent]);

  return (
    <div className={styles.PreprElementRoadmap}>
      <AccordionSteps items={accordionItems} />
    </div>
  );
};
