import { useTranslation } from 'react-i18next';

import { Container } from '@Components/layout';
import { Box, Text } from '@Components/ui';

import { ChallengeArchive } from './internal/components';

export const ChallengeOverviewScreen = () => {
  const { t } = useTranslation();

  return (
    <Container cols={6}>
      <Box mb={2}>
        <Text.H1>{t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.TITLE')}</Text.H1>
      </Box>

      <Box mb={5}>
        <Text.Large>{t('DOMAIN.CHALLENGE.OVERVIEW_PAGE.DESCRIPTION')}</Text.Large>
      </Box>

      <Box mb={1}>
        <ChallengeArchive />
      </Box>
    </Container>
  );
};
